import { styled } from "@mui/material/styles";
import {
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails as AccordionDetailsBase,
  Typography,
  IconButton as IconButtonBase,
  Grid,
  Paper as PaperBase,
  Box as BoxBase,
} from "@mui/material";
import FormatedValue from "components/formatted-value/formatted-value";
import React from "react";

const Box = styled(BoxBase)(({ theme }) => ({
  backgroundColor: theme.accordionBody.backgroundColor,
}));

const AccordionStyled = styled(AccordionBase)({
  borderWidth: 0,
});

const Accordion = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof AccordionBase>>((props, ref) => (
  <AccordionStyled ref={ref} {...props} />
));

const AccordionSummary = styled(AccordionSummaryBase)({
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    borderTopWidth: 0,
  },
});

const AccordionDetails = styled(AccordionDetailsBase)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
}));

const Label = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const LabelSubtitle = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: theme.accordionBody.subtitle.fontSize,
  fontWeight: theme.accordionBody.subtitle.fontWeight,
  color: theme.label.color,
}));

const LabelTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontWeight: theme.accordionBody.subtitle.fontWeight,
  fontSize: theme.accordionBody.title.fontSize,
}));

const Paper = styled(PaperBase)({
  justifyContent: "left",
  borderRadius: 0,
});

const LabelButtonText = styled(Label)({
  fontSize: "0.8rem",
  textAlign: "center",
});

const IconButton = styled(IconButtonBase)({
  justifyContent: "flex-end",
  alignSelf: "flex-end",
});

export default {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  LabelSubtitle,
  LabelButtonText,
  FormatedValue,
  LabelTitle,
  IconButton,
  Grid,
  Paper,
  Box,
};
