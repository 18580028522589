import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraCorporateReturnPartnershipFragment } from "graphql/hasura/types-and-hooks";

export type CorporateReturnPartnershipSchema = Omit<
  GraCorporateReturnPartnershipFragment,
  "__typename" | "Tin"
>;

export const CORPORATE_RETURN_PARTNERSHIP_SCHEMA_MAP: Record<
  keyof CorporateReturnPartnershipSchema,
  "string" | "number" | "Date"
> = {
  Interest: "number",
  Partnername: "string",
  Partnershipid: "string",
  Partnerstatus: "string",
  Returnid: "number",
  Salary: "number",
  Sharebalance: "number",
  Shareratio: "number",
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayertin: "number",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnPartnershipSchema
    | AccessorFn<CorporateReturnPartnershipSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CORPORATE_RETURN_PARTNERSHIP_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<CorporateReturnPartnershipSchema>[] = [
  {
    header: "Partnership ID",
    accessorKey: "Partnershipid",
    align: "center",
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "center",
    format: "string",
  },
  {
    header: "Partner Name",
    accessorKey: "Partnername",
    align: "left",
    format: "string",
  },
  {
    header: "Partner Status",
    accessorKey: "Partnerstatus",
    align: "center",
    format: "string",
  },
  {
    header: "Salary",
    accessorKey: "Salary",
    align: "right",
    format: "number",
  },
  {
    header: "Interest",
    accessorKey: "Interest",
    align: "right",
    format: "number",
  },
  {
    header: "Share Ratio",
    accessorKey: "Shareratio",
    align: "right",
    format: "number",
  },
  {
    header: "Share Balance",
    accessorKey: "Sharebalance",
    align: "right",
    format: "number",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
