// Dependencies
import React from "react";

// GraphQL
import {
  useGetCurrentUserProfileQuery,
  useUpdateCurrentUserProfileMutation,
  useUpdateCurrentUserProfileOrganisationMutation,
  useUpdateCurrentUserProfileDepartmentMutation,
  UserData,
} from "graphql/types-and-hooks";

// Components
import { Alert, AlertProps } from "components/alert/alert.component";
import UserForm from "components/user-form/user-form.container";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import SC from "./my-profile.styles";
import { SnackbarCloseReason } from "@mui/material";

const MyProfilePage: React.FC = () => {
  const {
    data,
    loading: getUserProfileLoading,
    error,
  } = useGetCurrentUserProfileQuery();

  // @ts-ignore
  const initialUserData = data?.GetCurrentUserProfile as UserData;

  const errorHandler = useAppErrorHandler(error);

  const [updateUserData, { loading: updateUserDataLoading }] =
    useUpdateCurrentUserProfileMutation();
  const [updateUserOrganisation, { loading: updateUserOrganisationLoading }] =
    useUpdateCurrentUserProfileOrganisationMutation();
  const [updateUserDepartment, { loading: updateUserDepartmentLoading }] =
    useUpdateCurrentUserProfileDepartmentMutation();

  const loading =
    getUserProfileLoading ||
    updateUserDataLoading ||
    updateUserDepartmentLoading ||
    updateUserOrganisationLoading;

  const [updateStatus, setUpdateStatus] = React.useState<AlertProps>();

  const handlerUserFormOnSubmit = React.useCallback(
    async (userData: UserData) => {
      try {
        const initialOrganisationId = initialUserData?.organisation?.id;
        const initialDepartmentId = initialUserData?.department?.id;

        if (initialOrganisationId !== userData?.organisation?.id) {
          await updateUserOrganisation({
            variables: {
              organisationID: userData?.organisation?.id,
            },
          });
        }

        if (initialDepartmentId !== userData?.department?.id) {
          await updateUserDepartment({
            variables: {
              departmentID: userData?.department?.id,
            },
          });
        }

        await updateUserData({
          variables: {
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            profilePicture: userData?.profilePicture,
            mobilePhone: userData?.mobilePhone,
            workAddress: userData?.work?.address,
            workPhone: userData?.work?.phone,
          },
        });

        setUpdateStatus({
          message: "Your profile has been updated successfully ",
          severity: "success",
        });
      } catch (err) {
        errorHandler(err as Error);
      }
    },
    [
      initialUserData,
      updateUserData,
      updateUserOrganisation,
      updateUserDepartment,
      errorHandler,
    ],
  );

  const handleCloseSnack = React.useCallback(
    (
      event?: React.SyntheticEvent<any, Event> | Event,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setUpdateStatus(undefined);
    },
    [],
  );

  return (
    <SC.MyProfilePage component="main">
      <SC.Snackbar
        open={!!updateStatus}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={updateStatus?.severity}
          message={updateStatus?.message}
        />
      </SC.Snackbar>

      <ProgressIndicator open={loading} />

      <UserForm
        initialUserData={initialUserData}
        isProfile
        initialEditable={false}
        onSubmit={handlerUserFormOnSubmit}
        onCancel={() => {}}
        onClickResendEmail={() => {}}
      />
    </SC.MyProfilePage>
  );
};
export default MyProfilePage;
