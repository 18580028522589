import { useState, useMemo } from "react";

const useTablesLoaded = <T extends string>(
  initialTables: Record<T, boolean>,
) => {
  const [tablesLoaded, setTablesLoaded] = useState(initialTables);

  const renderTable = useMemo(() => {
    return Object.fromEntries(
      Object.keys(tablesLoaded).map((key) => [
        key,
        () =>
          setTablesLoaded((prev) => ({
            ...prev,
            [key]: true,
          })),
      ]),
    ) as Record<keyof typeof tablesLoaded, () => void>;
  }, [tablesLoaded]);

  return { tablesLoaded, renderTable };
};

export default useTablesLoaded;
