import { TableColumn } from "components/table/table.component";
import {
  RegRatesFragment,
  Rtcs_Gm_Dev_Ph_Bcc_RegRates,
} from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type RegRatesSchema = Omit<RegRatesFragment, "__typename">;

export interface RegRatesTableFilters {
  address: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof RegRatesTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Bcc_RegRates>
> = {
  address: ["Address"],
  firstName: ["Currentowner", "Particulars"],
  phoneNumber: ["Contactno"],
  surname: ["Currentowner", "Particulars"],
};

export const RegRatesSchemaMap: Record<
  keyof RegRatesSchema,
  "string" | "number"
> = {
  Uuid: "string",
  Date: "string",
  Contactno: "string",
  Address: "string",
  Currentowner: "string",
  Demandnoteno: "string",
  Lot: "string",
  Particulars: "string",
  Plandate: "string",
  Plandetails: "string",
  Rem: "string",
  Sm: "string",
  Wr: "string",
  Year: "string",
  Current1: "number",
  Current2: "number",
  Current3: "number",
  Detailsofpayment1: "string",
  Totalpaytodate1: "string",
  Detailsofpayment2: "string",
  Totalpaytodate2: "string",
  Detailsofpayment3: "string",
  Totalpaytodate3: "string",
  Total1: "number",
  Total2: "number",
  Total3: "number",
  Amountdue: "number",
  Arrears1: "number",
  Arrears2: "number",
  Arrears3: "number",
  Balance: "number",
};

export const getAccessorType = (
  accessor: keyof RegRatesSchema | AccessorFn<RegRatesSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return RegRatesSchemaMap[accessor];
};

export const columns: TableColumn<RegRatesSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "center",
    format: "string",
  },
  {
    header: "Date",
    accessorKey: "Date",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Contact No.",
    accessorKey: "Contactno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Current Owner",
    accessorKey: "Currentowner",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Current 1",
    accessorKey: "Current1",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Current 2",
    accessorKey: "Current2",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Current 3",
    accessorKey: "Current3",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Demand Note No.",
    accessorKey: "Demandnoteno",
    align: "left",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Lot",
    accessorKey: "Lot",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Particulars",
    accessorKey: "Particulars",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Plan Date",
    accessorKey: "Plandate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Plan Details",
    accessorKey: "Plandetails",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Rem",
    accessorKey: "Rem",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Sm",
    accessorKey: "Sm",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Wr",
    accessorKey: "Wr",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Year",
    accessorKey: "Year",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Total Pay To date 1",
    accessorKey: "Totalpaytodate1",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Details Of Payment 1",
    accessorKey: "Detailsofpayment1",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Total Pay To Date 2",
    accessorKey: "Totalpaytodate2",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Details Of Payment 2",
    accessorKey: "Detailsofpayment2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Total Pay To Date 3",
    accessorKey: "Totalpaytodate3",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Details Of Payment 3",
    accessorKey: "Detailsofpayment3",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Total 1",
    accessorKey: "Total1",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Total 2",
    accessorKey: "Total2",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Total 3",
    accessorKey: "Total3",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Amount Due",
    accessorKey: "Amountdue",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Arrears 1",
    accessorKey: "Arrears1",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Arrears 2",
    accessorKey: "Arrears2",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Arrears 3",
    accessorKey: "Arrears3",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Balance",
    accessorKey: "Balance",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
];
