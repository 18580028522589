// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { CorporateReturnExemptionFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type CorporateReturnExemptionSchema = Omit<
  CorporateReturnExemptionFragment,
  "__typename" | "Tin"
>;

// @ts-ignore
export const CorporateReturnExemptionSchemaMap: Record<
  keyof CorporateReturnExemptionSchema,
  "string" | "number"
> = {
  Amount: "number",
  Basis: "number",
  Taxliability: "number",
  Taxrate: "number",
  Incometype: "string",
  Exemptionid: "string",
  Returnid: "string",
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayertin: "number",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnExemptionSchema
    | AccessorFn<CorporateReturnExemptionSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CorporateReturnExemptionSchemaMap[accessor];
};

export const columns: TableColumn<CorporateReturnExemptionSchema>[] = [
  {
    header: "Exemption ID",
    accessorKey: "Exemptionid",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Income Type",
    accessorKey: "Incometype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Amount",
    accessorKey: "Amount",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Tax Rate",
    accessorKey: "Taxrate",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Basis",
    accessorKey: "Basis",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Taxliability",
    accessorKey: "Taxliability",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
