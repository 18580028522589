/* eslint-disable react/jsx-props-no-spreading */

// Dependencies
import React from "react";
import { ClickAwayListener, Popper, MenuList } from "@mui/material";
import { Link } from "react-router-dom";

// Graphql
import {
  useGetCurrentUserProfileQuery,
  UserData,
} from "graphql/types-and-hooks";
import auth from "graphql/authentication";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Assets
import { useAppErrorHandler } from "errors/app.errors";
import SC from "./user-menu.styles";

const UserMenu: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const {
    data,
    loading: getUserProfileLoading,
    error,
  } = useGetCurrentUserProfileQuery();

  // @ts-ignore
  const initialUserData = data?.GetCurrentUserProfile as UserData;

  useAppErrorHandler(error);

  const handleOpenMenu = (): void => {
    setOpen(true);
  };

  const handleCloseMenu = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogOut = () => {
    auth.logOut();
  };

  return (
    <SC.UserMenu>
      <ProgressIndicator open={getUserProfileLoading} />
      <SC.ButtonIcon
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        {initialUserData?.profilePicture ? (
          <SC.UserAvatar src={initialUserData?.profilePicture} />
        ) : (
          <SC.DefaultAvatarIcon />
        )}
      </SC.ButtonIcon>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <SC.TransitionAnimation
            {...TransitionProps}
            isActive={placement === "bottom-end"}
          >
            <SC.MenuChildContainer>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <SC.Option
                    onClick={handleCloseMenu}
                    component={Link}
                    to="/my-profile"
                  >
                    My Profile
                  </SC.Option>
                  <SC.Option
                    onClick={handleLogOut}
                    component={Link}
                    to="/login"
                  >
                    Log out
                  </SC.Option>
                  <SC.Option
                    onClick={handleCloseMenu}
                    component={Link}
                    to="/undefined2"
                  >
                    Provide Feedback
                  </SC.Option>
                </MenuList>
              </ClickAwayListener>
            </SC.MenuChildContainer>
          </SC.TransitionAnimation>
        )}
      </Popper>
    </SC.UserMenu>
  );
};

export default UserMenu;
