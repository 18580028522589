import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  Gbos2024PopulationAndHousingCensusFragment,
  Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type Gbos2024PopulationAndHousingCensusSchema = Omit<
  Gbos2024PopulationAndHousingCensusFragment,
  "__typename"
>;

export interface Gbos2024PopulationAndHousingCensusTableFilters {
  address: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof Gbos2024PopulationAndHousingCensusTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>
> = {
  address: ["Districtname", "Wardname", "Lganame"],
  firstName: ["HHhHeadName", "Compoundowner", "Headofcompound", "HHhHeadName"],
  phoneNumber: [{ accessorName: "HHeadPhone", type: "number" }],
  surname: ["HHhHeadName", "Compoundowner", "Headofcompound", "HHhHeadName"],
};
export const COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP: Record<
  keyof Gbos2024PopulationAndHousingCensusSchema,
  "string" | "number" | "Date"
> = {
  Compoundowner: "string",
  CookingFuel: "string",
  Districtname: "string",
  FeelEviction: "string",
  FloorMaterial: "string",
  FreqSolidWasteCollection: "string",
  HEaCode: "number",
  HHeadPhone: "number",
  HHhHeadName: "string",
  HHhNumber: "number",
  HStrNumber: "number",
  Hdistrict: "number",
  Headofcompound: "string",
  Hlga: "number",
  Hsettlement: "string",
  Hward: "number",
  Latitude: "number",
  Lganame: "string",
  LiqWasteFromEconomic: "string",
  LiqWasteFromHhchores: "string",
  LiqWasteFromHumanConve: "string",
  Longitude: "number",
  NumOfFemale: "number",
  NumOfMale: "number",
  OccupancyStatus: "string",
  PayCompoundRate: "string",
  PlaceForBathing: "string",
  PlaceForCooking: "string",
  RoofMaterial: "string",
  SolidWasteDisposal: "string",
  SourceDrinkingWater: "string",
  SourceLighting: "string",
  SuitableForResidence: "string",
  TitleDeed: "string",
  ToiletFacility: "string",
  TotalNum: "number",
  Uuid: "string",
  WallsMaterial: "string",
  Wardname: "string",
};

export const getAccessorType = (
  accessor:
    | keyof Gbos2024PopulationAndHousingCensusSchema
    | AccessorFn<Gbos2024PopulationAndHousingCensusSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<Gbos2024PopulationAndHousingCensusSchema>[] =
  [
    {
      header: "UUID",
      accessorKey: "Uuid",
      align: "center",
      format: "string",
    },
    {
      header: "LGA Name",
      accessorKey: "Lganame",
      align: "left",
      format: "string",
    },
    {
      header: "District Name",
      accessorKey: "Districtname",
      align: "left",
      format: "string",
    },
    {
      header: "Ward Name",
      accessorKey: "Wardname",
      align: "left",
      format: "string",
    },
    {
      header: "H Ea Code",
      accessorKey: "HEaCode",
      align: "center",
      format: "string",
    },
    {
      header: "H LGA",
      accessorKey: "Hlga",
      align: "right",
      format: "number",
    },
    {
      header: "H District",
      accessorKey: "Hdistrict",
      align: "right",
      format: "number",
    },
    {
      header: "H Ward",
      accessorKey: "Hward",
      align: "right",
      format: "number",
    },
    {
      header: "H Settlement",
      accessorKey: "Hsettlement",
      align: "center",
      format: "string",
    },
    {
      header: "H Str Number",
      accessorKey: "HStrNumber",
      align: "right",
      format: "number",
    },
    {
      header: "H HH Number",
      accessorKey: "HHhNumber",
      align: "right",
      format: "number",
    },
    {
      header: "Compound Owner",
      accessorKey: "Compoundowner",
      align: "left",
      format: "string",
    },
    {
      header: "Head Of Compound",
      accessorKey: "Headofcompound",
      align: "left",
      format: "string",
    },
    {
      header: "H HH Head Name",
      accessorKey: "HHhHeadName",
      align: "left",
      format: "string",
    },
    {
      header: "H Head Phone",
      accessorKey: "HHeadPhone",
      align: "center",
      format: "string",
    },
    {
      header: "Latitude",
      accessorKey: "Latitude",
      align: "left",
      format: "string",
    },
    {
      header: "Longitude",
      accessorKey: "Longitude",
      align: "left",
      format: "string",
    },
    {
      header: "Num Of Male",
      accessorKey: "NumOfMale",
      align: "right",
      format: "number",
    },
    {
      header: "Num Of Female",
      accessorKey: "NumOfFemale",
      align: "right",
      format: "number",
    },
    {
      header: "Total Num",
      accessorKey: "TotalNum",
      align: "right",
      format: "number",
    },
    {
      header: "Occupancy Status",
      accessorKey: "OccupancyStatus",
      align: "center",
      format: "string",
    },
    {
      header: "Source Lighting",
      accessorKey: "SourceLighting",
      align: "center",
      format: "string",
    },
    {
      header: "Place For Cooking",
      accessorKey: "PlaceForCooking",
      align: "center",
      format: "string",
    },
    {
      header: "Cooking Fuel",
      accessorKey: "CookingFuel",
      align: "center",
      format: "string",
    },
    {
      header: "Place For Bathing",
      accessorKey: "PlaceForBathing",
      align: "center",
      format: "string",
    },
    {
      header: "Toilet Facility",
      accessorKey: "ToiletFacility",
      align: "center",
      format: "string",
    },
    {
      header: "Source Drinking Water",
      accessorKey: "SourceDrinkingWater",
      align: "center",
      format: "string",
    },
    {
      header: "Solid Waste Disposal",
      accessorKey: "SolidWasteDisposal",
      align: "center",
      format: "string",
    },
    {
      header: "Freq Solid Waste Collection",
      accessorKey: "FreqSolidWasteCollection",
      align: "center",
      format: "string",
    },
    {
      header: "Liq Waste From Human Conve",
      accessorKey: "LiqWasteFromHumanConve",
      align: "center",
      format: "string",
    },
    {
      header: "Liq Waste From HH Chores",
      accessorKey: "LiqWasteFromHhchores",
      align: "center",
      format: "string",
    },
    {
      header: "Liq Waste From Economic",
      accessorKey: "LiqWasteFromEconomic",
      align: "center",
      format: "string",
    },
    {
      header: "Title Deed",
      accessorKey: "TitleDeed",
      align: "center",
      format: "string",
    },
    {
      header: "Pay Compound Rate",
      accessorKey: "PayCompoundRate",
      align: "center",
      format: "string",
    },
    {
      header: "Suitable For Residence",
      accessorKey: "SuitableForResidence",
      align: "center",
      format: "string",
    },
    {
      header: "Feel Eviction",
      accessorKey: "FeelEviction",
      align: "center",
      format: "string",
    },
    {
      header: "Walls Material",
      accessorKey: "WallsMaterial",
      align: "center",
      format: "string",
    },
    {
      header: "Roof Material",
      accessorKey: "RoofMaterial",
      align: "center",
      format: "string",
    },
    {
      header: "Floor Material",
      accessorKey: "FloorMaterial",
      align: "center",
      format: "string",
    },
  ];
