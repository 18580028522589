// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { Gbos2024PopulationAndHousingCensusTable } from "../2024-population-and-housing-census-table/2024-population-and-housing-census-table.component";

// Assets
import SC from "./2024-population-and-housing-census.styles";

const Gbos2024PopulationAndHousingCensus: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <Gbos2024PopulationAndHousingCensusTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default Gbos2024PopulationAndHousingCensus;
