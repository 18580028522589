// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { NationalIDAndResidentialPermitRegistrationsFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type NationalIDAndResidentialPermitRegistrationsSchema = Omit<
  NationalIDAndResidentialPermitRegistrationsFragment,
  "__typename" | "Tin"
>;

// @ts-ignore
export const NationalIDAndResidentialPermitRegistrationsSchemaMap: Record<
  keyof NationalIDAndResidentialPermitRegistrationsSchema,
  "string" | "number"
> = {
  Address: "string",
  Cardnumbers: "number",
  Dateofbirth: "string",
  Driverlicencereceiptnumber: "string",
  Expirydate: "string",
  Fathersfirstname: "string",
  Fatherslastname: "string",
  Firstname: "string",
  Height: "number",
  Issuedate: "string",
  Lastname: "string",
  Mothersfirstname: "string",
  Motherslastname: "string",
  Nin: "string",
  Placeofbirth: "string",
  Proffesion: "string",
  Region: "string",
};

export const getAccessorType = (
  accessor:
    | keyof NationalIDAndResidentialPermitRegistrationsSchema
    | AccessorFn<NationalIDAndResidentialPermitRegistrationsSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NationalIDAndResidentialPermitRegistrationsSchemaMap[accessor];
};

export const columns: TableColumn<NationalIDAndResidentialPermitRegistrationsSchema>[] = 
  [
    {
      header: "NIN",
      accessorKey: "Nin",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "First Name",
      accessorKey: "Firstname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Last Name",
      accessorKey: "Lastname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Address",
      accessorKey: "Address",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Region",
      accessorKey: "Region",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Date of Birth",
      accessorKey: "Dateofbirth",
      align: "left",
      meta: { sort: false },
      format: "date",
    },
    {
      header: "Place of Birth",
      accessorKey: "Placeofbirth",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Father's Last Name",
      accessorKey: "Fatherslastname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Father's First Name",
      accessorKey: "Fathersfirstname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Mother's Last Name",
      accessorKey: "Motherslastname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Mother's First Name",
      accessorKey: "Mothersfirstname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Height",
      accessorKey: "Height",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Occupation",
      accessorKey: "Proffesion",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Issue Date",
      accessorKey: "Issuedate",
      align: "left",
      meta: { sort: false },
      format: "date",
    },
    {
      header: "Expiry Date",
      accessorKey: "Expirydate",
      align: "left",
      meta: { sort: false },
      format: "date",
    },
    {
      header: "Card Number",
      accessorKey: "Cardnumbers",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Driver Licence Receipt Number",
      accessorKey: "Driverlicencereceiptnumber",
      align: "left",
      meta: { sort: false },
    },
  ];
