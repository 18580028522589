// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { PaymentRentalFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type PaymentRentalSchema = Omit<PaymentRentalFragment, "__typename">;

// @ts-ignore
export const PaymentRentalSchemaMap: Record<
  keyof PaymentRentalSchema,
  "string" | "number"
> = {
  Birthdate: "string",
  Centername: "string",
  Email: "string",
  Empemail: "string",
  Fname: "string",
  Lname: "string",
  Paidamount: "number",
  Paymentdate: "string",
  Phoneno: "string",
  Phoneno2: "string",
  Physarea: "string",
  Regtypecode: "string",
  Taxcode: "string",
  Taxname: "string",
  Taxoffice: "string",
  Tin: "string",
};

export const getAccessorType = (
  accessor:
    | keyof PaymentRentalSchema
    | AccessorFn<PaymentRentalSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return PaymentRentalSchemaMap[accessor];
};

export const columns: TableColumn<PaymentRentalSchema>[] = [
  {
    header: "Tin",
    accessorKey: "Tin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "First Name",
    accessorKey: "Fname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Last Name",
    accessorKey: "Lname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Reg Type Code",
    accessorKey: "Regtypecode",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Office",
    accessorKey: "Taxoffice",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Center Name",
    accessorKey: "Centername",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Physical Area",
    accessorKey: "Physarea",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Birth Date",
    accessorKey: "Birthdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Phone No.",
    accessorKey: "Phoneno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone No.2",
    accessorKey: "Phoneno2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Empemail",
    accessorKey: "Empemail",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Code",
    accessorKey: "Taxcode",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Name",
    accessorKey: "Taxname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Paid Amount",
    accessorKey: "Paidamount",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Payment Date",
    accessorKey: "Paymentdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
];
