import React from "react";

// Components
import { Gbos2022CommercialRentalRatesSurveyTable } from "pages/reports/gbos/2022-commercial-rental-rates-survey-table/2022-commercial-rental-rates-survey-table.component";
import { Gbos2024PopulationAndHousingCensusTable } from "pages/reports/gbos/2024-population-and-housing-census-table/2024-population-and-housing-census-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface GbosTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

const GbosTab: React.FC<GbosTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      propertyRates: false,
    });
    const reportSections = [
      {
        title: "Gambia Bureau of Statistics",
        accordions: [
          {
            content: (
              <Gbos2022CommercialRentalRatesSurveyTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                onLoaded={renderTable.propertyRates}
                persistenceId="e1f5c2a4-2f31-4cd0-bb8e-d93dd095d0e9"
              />
            ),
            subtitle: "2022 Commercial Rental Rates Survey",
          },
          {
            shouldRenderBody: tablesLoaded.propertyRates,
            content: (
              <Gbos2024PopulationAndHousingCensusTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="3880c110-a7ef-49a9-a82c-10b9d8247271"
              />
            ),
            subtitle: "2024 Population and Housing Census",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);

export default GbosTab;
