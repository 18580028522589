import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { MobileCarrierSubscriberRegistrationsFragment } from "graphql/hasura/types-and-hooks";

export type MobileCarrierSubscriberRegistrationsSchema = Omit<
  MobileCarrierSubscriberRegistrationsFragment,
  "__typename"
>;

export const INDIVIDUAL_RETURNS_SCHEMA_MAP: Record<
  keyof MobileCarrierSubscriberRegistrationsSchema,
  "string" | "number"
> = {
  Idno: "string",
  Idtype: "string",
  Name: "string",
  Lastname: "string",
  Dob: "string",
  Gender: "string",
  Mobile: "string",
  Carrier: "string",
  Registrationdate: "string",
};

export const getAccessorType = (
  accessor:
    | keyof MobileCarrierSubscriberRegistrationsSchema
    | AccessorFn<MobileCarrierSubscriberRegistrationsSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_RETURNS_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<MobileCarrierSubscriberRegistrationsSchema>[] =
  [
    {
      header: "ID No.",
      accessorKey: "Idno",
      align: "left",
      format: "string",
    },
    {
      header: "ID Type",
      accessorKey: "Idtype",
      align: "left",
      format: "string",
    },
    {
      header: "Name",
      accessorKey: "Name",
      align: "left",
      format: "string",
    },
    {
      header: "Last Name",
      accessorKey: "Lastname",
      align: "left",
      format: "string",
    },
    {
      header: "Date Of Birth",
      accessorKey: "Dob",
      align: "left",
      format: "date",
    },
    {
      header: "Gender",
      accessorKey: "Gender",
      align: "left",
      format: "string",
    },
    {
      header: "Mobile",
      accessorKey: "Mobile",
      align: "left",
      format: "string",
    },
    {
      header: "Carrier",
      accessorKey: "Carrier",
      align: "left",
      format: "string",
    },
    {
      header: "Registrationdate",
      accessorKey: "Registrationdate",
      align: "left",
      format: "date",
    },
  ];
