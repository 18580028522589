import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraCorporateReturnLossFragment } from "graphql/hasura/types-and-hooks";

export type CorporateReturnLossSchema = Omit<
  GraCorporateReturnLossFragment,
  "__typename" | "Tin"
>;

export const CORPORATE_RETURN_LOSS_SCHEMA_MAP: Record<
  keyof CorporateReturnLossSchema,
  "string" | "number" | "Date"
> = {
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayertin: "number",
  Lossid: "string",
  Lossmade: "number",
  Lossoutstanding: "number",
  Losssetoff: "number",
  Lossyear: "number",
  Returnid: "number",
  Yearleft: "number",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnLossSchema
    | AccessorFn<CorporateReturnLossSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CORPORATE_RETURN_LOSS_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<CorporateReturnLossSchema>[] = [
  {
    header: "Loss ID",
    accessorKey: "Lossid",
    align: "left",
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "left",
    format: "string",
  },
  {
    header: "Loss Year",
    accessorKey: "Lossyear",
    align: "left",
    format: "string",
  },
  {
    header: "Loss Made",
    accessorKey: "Lossmade",
    align: "right",
    format: "number",
  },
  {
    header: "Loss Set Off",
    accessorKey: "Losssetoff",
    align: "right",
    format: "number",
  },
  {
    header: "Loss Outstanding",
    accessorKey: "Lossoutstanding",
    align: "right",
    format: "number",
  },
  {
    header: "Year Left",
    accessorKey: "Yearleft",
    align: "right",
    format: "number",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
