import React from "react";

// Component
import ExpandedMoreIcon from "@mui/icons-material/ExpandMore";

// Styles
import SC from "./accordion.styles";

export interface AccordionProps {
  defaultExpanded?: boolean;
  displayTitle?: boolean;
  content?: JSX.Element;
  title?: string;
  subtitle?: string;
  customerId?: string;
}

export const Accordion = ({
  content,
  displayTitle = false,
  defaultExpanded = false,
  title = "",
  subtitle = "",
  customerId = "",
}: AccordionProps): JSX.Element => {
  const ref = React.createRef<HTMLDivElement>();
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  React.useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <SC.Paper elevation={0}>
      {displayTitle ? (
        <SC.Box py={1}>
          <SC.LabelTitle variant="subtitle2">
            {customerId ? `${title} - ${customerId}` : `${title}`}
          </SC.LabelTitle>
        </SC.Box>
      ) : undefined}
      <SC.Accordion ref={ref} square elevation={0} expanded={expanded}>
        <SC.AccordionSummary
          expandIcon={<ExpandedMoreIcon color="disabled" />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={onExpand}
        >
          <SC.LabelSubtitle variant="subtitle2">{subtitle}</SC.LabelSubtitle>
        </SC.AccordionSummary>
        <SC.AccordionDetails>{content}</SC.AccordionDetails>
      </SC.Accordion>
    </SC.Paper>
  );
};

export default Accordion;
