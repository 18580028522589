/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Dependencies
// toGeoJSON global function available like a static asset 'togeojson.js' on index.html
import _ from "lodash";
import { CapitalizeFirstCharacter } from "../../../utils/string";

// Utils
import {
  ascendingAlphabeticalOrder,
  containsHtmlTags,
} from "../../shared/utils/data";

const convertToHtmlEmail = (emailStr) =>
  emailStr && !containsHtmlTags(emailStr)
    ? _.reduce(
        _.split(emailStr, ","),
        (emailList, email) => {
          const cleanEmail = email.trim();
          const separator = emailList ? ", " : "";

          return `${emailList}${separator}<a href="mailto:${cleanEmail}">${cleanEmail}</a>`;
        },
        "",
      )
    : emailStr;

const isValueEmpty = (value) => {
  if (
    value &&
    value !== null &&
    value !== undefined &&
    value !== "None" &&
    value !== "unknown" &&
    value !== "" &&
    value !== " " &&
    value !== "0" &&
    value !== 0 &&
    value !== "UNKNOWN" &&
    value !== "No"
  ) {
    return value;
  }
  return false;
};

export const convertKmlDataLayerToJson = (kmlData, userLocation) => {
  const kmlXML = new DOMParser().parseFromString(kmlData, "application/xml");
  const dataLayer = window.toGeoJSON.kml(kmlXML, { styles: true });
  const featureOrderByName = (featureA, featureB) => {
    return ascendingAlphabeticalOrder(
      featureA.properties.name,
      featureB.properties.name,
    );
  };
  const featureOrderByDistance = (featureA, featureB) => {
    return ascendingAlphabeticalOrder(
      featureA.distanceFromUser,
      featureB.distanceFromUser,
    );
  };
  const aTagRegExp = /<a/;
  const httpRegExp = RegExp("[hH][tT]{2}[pP]://");
  const newFeatures = [];

  dataLayer.features.forEach((feature, index) => {
    const { properties, geometry } = feature;
    const { icon, Website, Email } = properties;

    if (
      geometry?.type === "Point" &&
      geometry.coordinates.length === 2 &&
      geometry.coordinates[0] &&
      geometry.coordinates[1]
    ) {
      const newFeature = {
        ...feature,
        // Assign an id property equals to the index on the array
        id: index.toString(),
        properties: {
          ...properties,
          // Make sure that https:// is being used
          icon: icon ? icon.replace(httpRegExp, "https://") : icon,
          // Open up the website link on a new tab in the browser
          Website: Website
            ? Website.replace(aTagRegExp, '<a target="_blank"')
            : Website,
          Email: convertToHtmlEmail(Email),
        },
        position: {
          lng: geometry.coordinates[0],
          lat: geometry.coordinates[1],
        },
      };

      // Compute the distance from the user location
      if (userLocation) {
        newFeature.distanceFromUser = Number.parseInt(
          window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(newFeature.position),
            new window.google.maps.LatLng({
              lat: userLocation.latitude,
              lng: userLocation.longitude,
            }),
          ),
          10,
        );
      }

      newFeatures.push(newFeature);
    } else {
      console.log("***data*** Feature w/ invalid coordinates: ", feature); // eslint-disable-line no-console
    }
  });

  dataLayer.features = newFeatures.sort(
    userLocation ? featureOrderByDistance : featureOrderByName,
  );

  return dataLayer;
};

function isFloat(n) {
  if (!n) {
    return false;
  }
  return !Number.isNaN(n % 1) && n % 1 !== 0;
}

export const sortPropertiesByAphaNumeric = (properties) => {
  const newProperties = [...properties];
  const sortByAphaNumeric = (a, b) => {
    const keyA = a.Uuid || a.UUID;
    const keyB = b.Uuid || b.UUID;

    if (!keyA || !keyB) {
      throw new Error(
        "One or more objects are missing the Uuid/UUID property.",
      );
    }

    return keyA.localeCompare(keyB, "en", { numeric: true });
  };
  return newProperties.sort(sortByAphaNumeric);
};

export const ServerDataToLayer = (properties) => {
  const currentLayer = {};
  const newFeatures = [];
  const newProperties = sortPropertiesByAphaNumeric(properties);
  newProperties.map((property, index) => {
    if (
      (_.isEmpty(property.Longitude) &&
        _.isEmpty(property.Latitude) &&
        isFloat(property.Longitude),
      isFloat(property.Latitude))
    ) {
      const newFeature = {
        // Assign an id property equals to the index on the array
        type: "Feature",
        id: index.toString(),
        properties: {
          cluster: false,
          Rnid: property.Uuid || property.UUID,
          name: CapitalizeFirstCharacter(property.Buildingcentroidgps || ""),
          type: property.PropertyTypeId,
          housenumber: isValueEmpty(property.Settlement)
            ? CapitalizeFirstCharacter(property.Settlement)
            : false,
          plotnumber: isValueEmpty(property.Pluscode1)
            ? `Plot ${CapitalizeFirstCharacter(property.Pluscode1)},`
            : false,
          address: isValueEmpty(property.Street)
            ? `${CapitalizeFirstCharacter(property.Street)},`
            : false,
          blocknumber: isValueEmpty(property.BuildingArea)
            ? `${CapitalizeFirstCharacter(property.BuildingArea)},`
            : false,
          propertyId: property.Uuid || property.UUID,
        },
        position: {
          lng: parseFloat(property.Longitude),
          lat: parseFloat(property.Latitude),
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(property.Longitude),
            parseFloat(property.Latitude),
          ],
        },
      };
      newFeatures.push(newFeature);
    }
  });
  currentLayer.features = newFeatures;
  return currentLayer;
};

export const DataToLayer = (propertiesArr, userLocation) => {
  const aTagRegExp = /<a/;
  const httpRegExp = RegExp("[hH][tT]{2}[pP]://");
  const newFeatures = [];
  const currentLayer = {};

  const featureOrderByName = (featureA, featureB) => {
    return ascendingAlphabeticalOrder(
      featureA.properties.name,
      featureB.properties.name,
    );
  };
  const featureOrderByDistance = (featureA, featureB) => {
    return ascendingAlphabeticalOrder(
      featureA.distanceFromUser,
      featureB.distanceFromUser,
    );
  };

  propertiesArr.forEach((feature, index) => {
    const { properties, geometry } = feature;
    const { icon, Website, Email } = properties;

    if (
      geometry?.type === "Point" &&
      geometry.coordinates.length === 2 &&
      geometry.coordinates[0] &&
      geometry.coordinates[1]
    ) {
      const newFeature = {
        ...feature,
        // Assign an id property equals to the index on the array
        id: index.toString(),
        properties: {
          ...properties,
          // Make sure that https:// is being used
          icon: icon ? icon.replace(httpRegExp, "https://") : icon,
          // Open up the website link on a new tab in the browser
          Website: Website
            ? Website.replace(aTagRegExp, '<a target="_blank"')
            : Website,
          Email: convertToHtmlEmail(Email),
        },
        position: {
          lng: geometry.coordinates[0],
          lat: geometry.coordinates[1],
        },
      };
      // Compute the distance from the user location
      if (userLocation) {
        newFeature.distanceFromUser = Number.parseInt(
          window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(newFeature.position),
            new window.google.maps.LatLng({
              lat: userLocation.latitude,
              lng: userLocation.longitude,
            }),
          ),
          10,
        );
      }

      newFeatures.push(newFeature);
    } else {
      console.log("***data*** Feature w/ invalid coordinates: ", feature); // eslint-disable-line no-console
    }
  });

  currentLayer.features = newFeatures.sort(
    userLocation ? featureOrderByDistance : featureOrderByName,
  );

  return currentLayer;
};
