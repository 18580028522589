import { styled } from "@mui/material/styles";
import { Box as BoxBase, Typography as TypographyBase } from "@mui/material";

const Box = styled(BoxBase)({
  maxWidth: "inherit",
});

const BoxInner = styled(BoxBase)({
  paddingTop: 20,
  paddingBottom: 100,
});

const AccordionBox = styled(BoxBase)({
  paddingTop: 8,
});

const LabelTitle = styled(TypographyBase)(({ theme }) => ({
  fontWeight: theme.accordionBody.subtitle.fontWeight,
  fontSize: theme.accordionBody.title.fontSize,
  paddingTop: 8,
  textAlign: "left",
}));

export default {
  Box,
  BoxInner,
  LabelTitle,
  AccordionBox,
};
