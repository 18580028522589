// Dependencies
import { Box, Snackbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import MapTableContainerBase from "components/map-table-container/map-table-container.component";
import SwitchViewsBase from "components/switch-views/switch-views.component";
import MapContainerBase from "components/map-container/map-container.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

const MapContainer = MapContainerBase;

const MapTableContainer = MapTableContainerBase;
const SwitchViews = SwitchViewsBase;

export default {
  Box,
  Snackbar,
  Title,
  MapTableContainer,
  SwitchViews,
  MapContainer,
  ProgressIndicator,
};
