import React, { useCallback, useState } from "react";

// Components
import ExpandAllButton from "components/expand-all-button/expnad-all-button.component";

// styles
import Accordion, {
  AccordionProps,
} from "components/accordion/accordion.component";
import SC from "./accordion-list.styles";

interface Section {
  title: string;
  accordions: (AccordionProps & { shouldRenderBody?: boolean })[];
}

export interface AccordionListProps {
  sections: Section[];
  defaultExpanded?: boolean;
}

const AccordionList: React.FC<AccordionListProps> = ({
  sections,
  defaultExpanded = false,
}: AccordionListProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const handleExpandedAll = useCallback(
    (newExpanded: boolean) => setExpanded(newExpanded),
    [],
  );

  return (
    <SC.Box>
      <ExpandAllButton
        defaultExpanded={expanded}
        onExpandAll={handleExpandedAll}
      />
      <SC.BoxInner>
        {sections.map(({ accordions, title }, sectionIndex) => (
          <div key={sectionIndex}>
            <SC.LabelTitle variant="subtitle2">{title}</SC.LabelTitle>
            {accordions.map(
              ({ shouldRenderBody, content, subtitle }, index) => (
                <SC.AccordionBox key={`${sectionIndex}-${index}`}>
                  <Accordion
                    key={`${sectionIndex}-${index}`}
                    defaultExpanded={
                      (shouldRenderBody === undefined || shouldRenderBody) &&
                      expanded
                    }
                    content={
                      shouldRenderBody === undefined || shouldRenderBody
                        ? content
                        : undefined
                    }
                    subtitle={subtitle}
                  />
                </SC.AccordionBox>
              ),
            )}
          </div>
        ))}
      </SC.BoxInner>
    </SC.Box>
  );
};

export default AccordionList;
