/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import { Container as ContainerBase, ContainerProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  maxHeight?: number;
}

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  "::-webkit-scrollbar": {
    width: "0px",
    background: "transparent" /* make scrollbar transparent */,
  },
  padding: "0px",
  display: "flex",
  flexDirection: "column",
  // maxHeight: maxHeight ? `${maxHeight}px` : "inherit", TODO: removed to fix the scroll issue
}));

const PageContent = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => (
    <ContainerStyled {...props} ref={ref} disableGutters={true} />
  ),
);

const Page = PageContent;

export default {
  Page,
  PageContent,
};
