// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { CorporateReturnFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type CorporateReturnSchema = Omit<
  CorporateReturnFragment,
  "__typename" | "Tin"
>;

// @ts-ignore
export const CorporateReturnSchemaMap: Record<
  keyof CorporateReturnSchema,
  "string" | "number"
> = {
  Accural: "number",
  Additionalallowance: "number",
  Allowancebroughtforward: "number",
  Allowanceutilised: "number",
  Businessincome: "number",
  Capitalallowance: "number",
  Cashbank: "number",
  Closingstock: "number",
  Costofsale: "number",
  Creditor: "number",
  Debtor: "number",
  Depreciation: "number",
  Exemptincome: "number",
  Exporter: "number",
  Fixedasset: "number",
  Foreignexchangeloss: "number",
  Foreigntaxcredit: "number",
  Fringebenefittax: "number",
  Grossprofit: "number",
  Importer: "number",
  Instalmentpayment: "number",
  Interestexpense: "number",
  Longtermliability: "number",
  Lossbroughtforward: "number",
  Openingstock: "number",
  Operatingexpense: "number",
  Otherasset: "number",
  Othercurrentasset: "number",
  Othercurrentliability: "number",
  Otherdeduction: "number",
  Otherexpense: "number",
  Otherincome: "number",
  Otherliability: "number",
  Overpayment: "number",
  Propertyincome: "number",
  Purchase: "number",
  Returngroup: "number",
  Returnid: "string",
  Salarywage: "number",
  Sharehholderfund: "number",
  Sicexemption: "number",
  Stock: "number",
  Tax1stschedule: "number",
  Totalasset: "number",
  Totalliability: "number",
  Turnovertax: "number",
  Unallowablededuction: "number",
  Unutilisedallowance: "number",
  Withholdingtaxcredit: "number",
  Yearallowance: "number",
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayertin: "number",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnSchema
    | AccessorFn<CorporateReturnSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CorporateReturnSchemaMap[accessor];
};

export const columns: TableColumn<CorporateReturnSchema>[] = [
  {
    header: "ReturnID",
    accessorKey: "Returnid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Stock",
    accessorKey: "Stock",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Cashbank",
    accessorKey: "Cashbank",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Debtor",
    accessorKey: "Debtor",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Current Asset",
    accessorKey: "Othercurrentasset",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Asset",
    accessorKey: "Otherasset",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Fixed Asset",
    accessorKey: "Fixedasset",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Total Asset",
    accessorKey: "Totalasset",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Creditor",
    accessorKey: "Creditor",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Accural",
    accessorKey: "Accural",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Current Liability",
    accessorKey: "Othercurrentliability",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Long Term Liability",
    accessorKey: "Longtermliability",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Liability",
    accessorKey: "Otherliability",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Sharehholder Fund",
    accessorKey: "Sharehholderfund",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Total Liability",
    accessorKey: "Totalliability",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Business Income",
    accessorKey: "Businessincome",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Opening Stock",
    accessorKey: "Openingstock",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Purchase",
    accessorKey: "Purchase",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Closing Stock",
    accessorKey: "Closingstock",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Cost Of Sale",
    accessorKey: "Costofsale",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Property Income",
    accessorKey: "Propertyincome",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Income",
    accessorKey: "Otherincome",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Operating Expense",
    accessorKey: "Operatingexpense",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Salary Wage",
    accessorKey: "Salarywage",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Foreign Exchange Loss",
    accessorKey: "Foreignexchangeloss",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Interest Expense",
    accessorKey: "Interestexpense",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Depreciation",
    accessorKey: "Depreciation",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Expense",
    accessorKey: "Otherexpense",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Unallowable Deduction",
    accessorKey: "Unallowablededuction",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Exempt Income",
    accessorKey: "Exemptincome",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Capital Allowance",
    accessorKey: "Capitalallowance",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Foreign Tax Credit",
    accessorKey: "Foreigntaxcredit",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Other Deduction",
    accessorKey: "Otherdeduction",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Loss Brought Forward",
    accessorKey: "Lossbroughtforward",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Tax 1st Schedule",
    accessorKey: "Tax1stschedule",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Turn Overtax",
    accessorKey: "Turnovertax",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Over Payment",
    accessorKey: "Overpayment",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Withholding Taxcredit",
    accessorKey: "Withholdingtaxcredit",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Instalment Payment",
    accessorKey: "Instalmentpayment",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Year Allowance",
    accessorKey: "Yearallowance",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Additional Allowance",
    accessorKey: "Additionalallowance",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Allowance Brought Forward",
    accessorKey: "Allowancebroughtforward",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Allowance Utilised",
    accessorKey: "Allowanceutilised",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Unutilised Allowance",
    accessorKey: "Unutilisedallowance",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Importer",
    accessorKey: "Importer",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Exporter",
    accessorKey: "Exporter",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Sicexemption",
    accessorKey: "Sicexemption",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Gross Profit",
    accessorKey: "Grossprofit",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Return Group",
    accessorKey: "Returngroup",
    align: "right",
    meta: { sort: false },
    format: "number",
  },
];
