// Dependencies
import React from "react";

// Components
import { PropertyRatesTable } from "../property-rates-table/property-rates-table.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Assets
import SC from "./property-rates.styles";

const PropertyRates: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <PropertyRatesTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default PropertyRates;
