// Dependencies
import React from "react";

// Components
import { TradeLicenseRegisterTable } from "../trade-license-register-table/trade-license-register-table.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Assets
import SC from "./trade-license-register.styles";

const TradeLicenseRegister: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <TradeLicenseRegisterTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default TradeLicenseRegister;
