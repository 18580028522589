import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  Gbos2022CommercialRentalRatesSurveyFragment,
  Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type Gbos2022CommercialRentalRatesSurveySchema = Omit<
  Gbos2022CommercialRentalRatesSurveyFragment,
  "__typename"
>;

export interface Gbos2022CommercialRentalRatesSurveyTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof Gbos2022CommercialRentalRatesSurveyTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>
> = {
  address: [
    "Address",
    "AddressOfLandlordagency",
    "AddressOfLandlordagent",
    "Lga",
  ],
  businessName: ["NameOfBusiness"],
  firstName: [
    "NameOfLandlordagent",
    "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
  ],
  idNumber: [{ accessorName: "TinOfLandlordagent", type: "number" }],
  phoneNumber: [
    { accessorName: "BusinessTelephone", type: "number" },
    { accessorName: "ContactOfLandlordagent", type: "number" },
    { accessorName: "ContactPersonsTel", type: "number" },
    { accessorName: "SecondContact", type: "number" },
    { accessorName: "SecondTelOfContactPerson", type: "number" },
    { accessorName: "ThirdContact", type: "number" },
    { accessorName: "TelOfLandlordagent", type: "number" },
  ],
  surname: [
    "NameOfLandlordagent",
    "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
  ],
};

export const COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP: Record<
  keyof Gbos2022CommercialRentalRatesSurveySchema,
  "string" | "number" | "Date"
> = {
  Address: "string",
  AddressOfLandlordagency: "string",
  AddressOfLandlordagent: "string",
  AmountPaid: "string",
  AmountPaidPerPeriod: "number",
  AnyMore: "string",
  BusinessActivitytype: "string",
  BusinessTelephone: "number",
  ContactOfLandlordagent: "number",
  ContactPersonsTel: "number",
  CurrencyUnit: "string",
  DurationInMonths: "number",
  FrequencyOfPayment: "string",
  IndividualId: "number",
  Lga: "string",
  NameOfBusiness: "string",
  NameOfLandlordagent: "string",
  NameOfLandlordagentForOtherBusinessPropertyElsewhere: "string",
  OtherBusinessPropertyRentedElsewhere: "number",
  OtherBusinessPropertyRentedElsewhereStatus: "string",
  OtherSpecifiedFrequencyPaymentArrangement: "string",
  OwnerOccupiedEstimatedValueOfRent: "string",
  PaymentFrequency: "string",
  PositionOfRespondentcontactPerson: "string",
  PropertyOwneradministrator: "string",
  RentPeriodArrangement: "string",
  SecondContact: "number",
  SecondTelOfContactPerson: "number",
  TelOfLandlordagent: "number",
  ThirdContact: "number",
  TinOfLandlordagent: "number",
  Uuid: "string",
};

export const getAccessorType = (
  accessor:
    | keyof Gbos2022CommercialRentalRatesSurveySchema
    | AccessorFn<Gbos2022CommercialRentalRatesSurveySchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<Gbos2022CommercialRentalRatesSurveySchema>[] =
  [
    {
      header: "UUID",
      accessorKey: "Uuid",
      align: "center",
      format: "string",
    },
    {
      header: "Individual ID",
      accessorKey: "IndividualId",
      align: "center",
      format: "string",
    },
    {
      header: "LGA",
      accessorKey: "Lga",
      align: "center",
      format: "string",
    },
    {
      header: "Name Of Business",
      accessorKey: "NameOfBusiness",
      align: "left",
      format: "string",
    },
    {
      header: "Business Telephone",
      accessorKey: "BusinessTelephone",
      align: "right",
      format: "number",
    },
    {
      header: "Address",
      accessorKey: "Address",
      align: "left",
      format: "string",
    },
    {
      header: "Position Of Respondent/Contact Person",
      accessorKey: "PositionOfRespondentcontactPerson",
      align: "center",
      format: "string",
    },
    {
      header: "Contact Person's Tel.",
      accessorKey: "ContactPersonsTel",
      align: "center",
      format: "string",
    },
    {
      header: "Second Tel. Of Contact Person",
      accessorKey: "SecondTelOfContactPerson",
      align: "center",
      format: "string",
    },
    {
      header: "Business Activity/Type",
      accessorKey: "BusinessActivitytype",
      align: "center",
      format: "string",
    },
    {
      header: "Rent Period Arrangement",
      accessorKey: "RentPeriodArrangement",
      align: "center",
      format: "string",
    },
    {
      header: "Property Owner/Administrator",
      accessorKey: "PropertyOwneradministrator",
      align: "center",
      format: "string",
    },
    {
      header: "Name Of Landlord/Agent",
      accessorKey: "NameOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Address Of Landlord/Agency",
      accessorKey: "AddressOfLandlordagency",
      align: "left",
      format: "string",
    },
    {
      header: "Contact Of Landlord/Agent",
      accessorKey: "ContactOfLandlordagent",
      align: "center",
      format: "string",
    },
    {
      header: "Second Contact",
      accessorKey: "SecondContact",
      align: "center",
      format: "string",
    },
    {
      header: "Third Contact",
      accessorKey: "ThirdContact",
      align: "center",
      format: "string",
    },
    {
      header: "Frequency Of Payment",
      accessorKey: "FrequencyOfPayment",
      align: "center",
      format: "string",
    },
    {
      header: "Other Specified Frequency Payment Arrangement",
      accessorKey: "OtherSpecifiedFrequencyPaymentArrangement",
      align: "center",
      format: "string",
    },
    {
      header: "Amount Paid Per Period",
      accessorKey: "AmountPaidPerPeriod",
      align: "right",
      format: "number",
    },
    {
      header: "Currency Unit",
      accessorKey: "CurrencyUnit",
      align: "center",
      format: "string",
    },
    {
      header: "Other Business Property Rented Elsewhere?",
      accessorKey: "OtherBusinessPropertyRentedElsewhereStatus",
      align: "center",
      format: "string",
    },
    {
      header: "Owner Occupied Estimated Value Of Rent",
      accessorKey: "OwnerOccupiedEstimatedValueOfRent",
      align: "center",
      format: "string",
    },
    {
      header: "Other Business Property Rented Elsewhere",
      accessorKey: "OtherBusinessPropertyRentedElsewhere",
      align: "right",
      format: "number",
    },
    {
      header: "Name Of Landlord/Agent For Other Business Property Elsewhere",
      accessorKey: "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
      align: "left",
      format: "string",
    },
    {
      header: "TIN Of Landlord/Agent",
      accessorKey: "TinOfLandlordagent",
      align: "center",
      format: "string",
    },
    {
      header: "Address Of Landlord/Agent",
      accessorKey: "AddressOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Tel. Of Landlord/Agent",
      accessorKey: "TelOfLandlordagent",
      align: "center",
      format: "string",
    },
    {
      header: "Amount Paid",
      accessorKey: "AmountPaid",
      align: "right",
      format: "number",
    },
    {
      header: "Payment Frequency",
      accessorKey: "PaymentFrequency",
      align: "center",
      format: "string",
    },
    {
      header: "Duration In Months",
      accessorKey: "DurationInMonths",
      align: "right",
      format: "number",
    },
    {
      header: "Any More",
      accessorKey: "AnyMore",
      align: "center",
      format: "string",
    },
  ];
