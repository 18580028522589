import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraCorporateReturnPropertyRentFragment } from "graphql/hasura/types-and-hooks";

export type CorporateReturnPropertyRentSchema = Omit<
  GraCorporateReturnPropertyRentFragment,
  "__typename" | "Tin"
>;

export const CORPORATE_RETURN_PROPERTY_RENT_SCHEMA_MAP: Record<
  keyof CorporateReturnPropertyRentSchema,
  "string" | "number" | "Date"
> = {
  Annualrent: "number",
  Annualrentpaid: "number",
  Commercialused: "string",
  Landlordname: "string",
  Landlordtin: "string",
  Propertyaddress: "string",
  Rentid: "string",
  Residentialused: "string",
  Returnid: "number",
  Telephone: "string",
  Tenancystartdate: "Date",
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayertin: "number",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnPropertyRentSchema
    | AccessorFn<CorporateReturnPropertyRentSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CORPORATE_RETURN_PROPERTY_RENT_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<CorporateReturnPropertyRentSchema>[] = [
  {
    header: "Rent ID",
    accessorKey: "Rentid",
    align: "center",
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "center",
    format: "string",
  },
  {
    header: "Landlord Name",
    accessorKey: "Landlordname",
    align: "left",
    format: "string",
  },
  {
    header: "Property Address",
    accessorKey: "Propertyaddress",
    align: "left",
    format: "string",
  },
  {
    header: "Landlord TIN",
    accessorKey: "Landlordtin",
    align: "center",
    format: "string",
  },
  {
    header: "Tenancy Start Date",
    accessorKey: "Tenancystartdate",
    align: "center",
    format: "date",
  },
  {
    header: "Annual Rent",
    accessorKey: "Annualrent",
    align: "right",
    format: "number",
  },
  {
    header: "Annual Rent Paid",
    accessorKey: "Annualrentpaid",
    align: "right",
    format: "number",
  },
  {
    header: "Commercial Used",
    accessorKey: "Commercialused",
    align: "center",
    format: "string",
  },
  {
    header: "Residential Used",
    accessorKey: "Residentialused",
    align: "center",
    format: "string",
  },
  {
    header: "Telephone",
    accessorKey: "Telephone",
    align: "center",
    format: "string",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
