import { TableColumn } from "components/table/table.component";
import {
  Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister,
  TradeLicenseRegisterFragment,
} from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type TradeLicenseRegisterSchema = Omit<
  TradeLicenseRegisterFragment,
  "__typename"
>;
export interface TradeLicenseRegisterTableFilters {
  address: string;
  businessName: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof TradeLicenseRegisterTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>
> = {
  address: ["Address"],
  businessName: ["Name"],
};

export const TradeLicenseRegisterSchemaMap: Record<
  keyof TradeLicenseRegisterSchema,
  "string" | "number"
> = {
  Uuid: "string",
  Address: "string",
  Arrears: "string",
  Balance: "string",
  Certificateno: "string",
  Currentyear: "string",
  Id: "string",
  Invdate: "string",
  Invid: "string",
  Invno: "string",
  Licencetype: "string",
  Name: "string",
  Receiptdetails: "string",
  Totalinvoiceamount: "number",
  Totalreceipttodate: "string",
  Wrno: "string",
  Year: "string",
};

export const getAccessorType = (
  accessor:
    | keyof TradeLicenseRegisterSchema
    | AccessorFn<TradeLicenseRegisterSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return TradeLicenseRegisterSchemaMap[accessor];
};

export const columns: TableColumn<TradeLicenseRegisterSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "center",
    format: "string",
  },
  {
    header: "Invdate",
    accessorKey: "Invdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Invno",
    accessorKey: "Invno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Invid",
    accessorKey: "Invid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Id",
    accessorKey: "Id",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Currentyear",
    accessorKey: "Currentyear",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Certificateno",
    accessorKey: "Certificateno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Licencetype",
    accessorKey: "Licencetype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Name",
    accessorKey: "Name",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Arrears",
    accessorKey: "Arrears",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Receiptdetails",
    accessorKey: "Receiptdetails",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Totalinvoiceamount",
    accessorKey: "Totalinvoiceamount",
    align: "left",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Totalreceipttodate",
    accessorKey: "Totalreceipttodate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Balance",
    accessorKey: "Balance",
    align: "left",
    meta: { sort: false },
    format: "number",
  },
  {
    header: "Wrno",
    accessorKey: "Wrno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Year",
    accessorKey: "Year",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
