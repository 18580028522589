import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  float8: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  _nlike?: InputMaybe<Scalars['String']['input']>;
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "rtcs_gm_dev.PH_SYS_Users" */
  delete_rtcs_gm_dev_PH_SYS_Users?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  delete_rtcs_gm_dev_PH_africa360view_Africa360View?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  delete_rtcs_gm_dev_PH_bcc_RegRates?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  delete_rtcs_gm_dev_PH_bcc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  delete_rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  delete_rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  delete_rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  delete_rtcs_gm_dev_PH_gra_CommericalResidentialTax?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnDirector?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnExemption?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnLoss?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnPartnership?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnRentReceived?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  delete_rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  delete_rtcs_gm_dev_PH_gra_IndividualReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  delete_rtcs_gm_dev_PH_gra_IndividualReturnExemption?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  delete_rtcs_gm_dev_PH_gra_IndividualReturnLoan?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  delete_rtcs_gm_dev_PH_gra_IndividualReturnLoss?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  delete_rtcs_gm_dev_PH_gra_IndividualReturnRent?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  delete_rtcs_gm_dev_PH_gra_IndividualReturnRentPaid?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  delete_rtcs_gm_dev_PH_gra_PaymentRental?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  delete_rtcs_gm_dev_PH_gra_RegAssociation?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  delete_rtcs_gm_dev_PH_gra_RentalOfficeData?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_Request" */
  delete_rtcs_gm_dev_PH_gra_Request?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  delete_rtcs_gm_dev_PH_gra_ReturnError?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  delete_rtcs_gm_dev_PH_gra_ReturnsBase?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  delete_rtcs_gm_dev_PH_gra_TaxPayer?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  delete_rtcs_gm_dev_PH_gra_VATReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  delete_rtcs_gm_dev_PH_gra_WithholdingReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  delete_rtcs_gm_dev_PH_gra_WithholdingReturnDetail?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  delete_rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  delete_rtcs_gm_dev_PH_kmc_PropertyRatesPayers?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  delete_rtcs_gm_dev_PH_kmc_PropertyRatesRegister?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  delete_rtcs_gm_dev_PH_kmc_RatesDatabaseExtract?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  delete_rtcs_gm_dev_PH_kmc_RatesPropertyData?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  delete_rtcs_gm_dev_PH_kmc_RatesReceiptsData?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  delete_rtcs_gm_dev_PH_kmc_TradeLicense?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  delete_rtcs_gm_dev_PH_kmc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  delete_rtcs_gm_dev_PH_nawec_AllCustomers?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  delete_rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  delete_rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  delete_rtcs_gm_dev_PH_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  delete_rtcs_gm_dev_PH_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.SYS_Users" */
  delete_rtcs_gm_dev_SYS_Users?: Maybe<Rtcs_Gm_Dev_Sys_Users_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.africa360view_Africa360View" */
  delete_rtcs_gm_dev_africa360view_Africa360View?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.aggregate_result" */
  delete_rtcs_gm_dev_aggregate_result?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.aggregate_result_txt" */
  delete_rtcs_gm_dev_aggregate_result_txt?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.bcc_RegRates" */
  delete_rtcs_gm_dev_bcc_RegRates?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  delete_rtcs_gm_dev_bcc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  delete_rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  delete_rtcs_gm_dev_gbos_2024PopulationAndHousingCensus?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  delete_rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  delete_rtcs_gm_dev_gra_CommericalResidentialTax?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturn" */
  delete_rtcs_gm_dev_gra_CorporateReturn?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  delete_rtcs_gm_dev_gra_CorporateReturnDirector?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  delete_rtcs_gm_dev_gra_CorporateReturnExemption?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  delete_rtcs_gm_dev_gra_CorporateReturnLoss?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  delete_rtcs_gm_dev_gra_CorporateReturnPartnership?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  delete_rtcs_gm_dev_gra_CorporateReturnPropertyRent?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  delete_rtcs_gm_dev_gra_CorporateReturnRentReceived?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  delete_rtcs_gm_dev_gra_CorporateReturnRentRecieved?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_IndividualReturn" */
  delete_rtcs_gm_dev_gra_IndividualReturn?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  delete_rtcs_gm_dev_gra_IndividualReturnExemption?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  delete_rtcs_gm_dev_gra_IndividualReturnLoan?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  delete_rtcs_gm_dev_gra_IndividualReturnLoss?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  delete_rtcs_gm_dev_gra_IndividualReturnRent?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  delete_rtcs_gm_dev_gra_IndividualReturnRentPaid?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_PaymentRental" */
  delete_rtcs_gm_dev_gra_PaymentRental?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_RegAssociation" */
  delete_rtcs_gm_dev_gra_RegAssociation?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  delete_rtcs_gm_dev_gra_RentalOfficeData?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_Request" */
  delete_rtcs_gm_dev_gra_Request?: Maybe<Rtcs_Gm_Dev_Gra_Request_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_ReturnError" */
  delete_rtcs_gm_dev_gra_ReturnError?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_ReturnsBase" */
  delete_rtcs_gm_dev_gra_ReturnsBase?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_TaxPayer" */
  delete_rtcs_gm_dev_gra_TaxPayer?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_VATReturn" */
  delete_rtcs_gm_dev_gra_VATReturn?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  delete_rtcs_gm_dev_gra_WithholdingReturn?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  delete_rtcs_gm_dev_gra_WithholdingReturnDetail?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  delete_rtcs_gm_dev_groc_MOJMonthlyRegistrations?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  delete_rtcs_gm_dev_kmc_PropertyRatesPayers?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  delete_rtcs_gm_dev_kmc_PropertyRatesRegister?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  delete_rtcs_gm_dev_kmc_RatesDatabaseExtract?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  delete_rtcs_gm_dev_kmc_RatesPropertyData?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  delete_rtcs_gm_dev_kmc_RatesReceiptsData?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_TradeLicense" */
  delete_rtcs_gm_dev_kmc_TradeLicense?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  delete_rtcs_gm_dev_kmc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.nawec_AllCustomers" */
  delete_rtcs_gm_dev_nawec_AllCustomers?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  delete_rtcs_gm_dev_nawec_WaterAndElectricityCustomers?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  delete_rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.rtcs_Country" */
  delete_rtcs_gm_dev_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  delete_rtcs_gm_dev_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_SYS_Users" */
  insert_rtcs_gm_dev_PH_SYS_Users?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_SYS_Users" */
  insert_rtcs_gm_dev_PH_SYS_Users_one?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** insert data into the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  insert_rtcs_gm_dev_PH_africa360view_Africa360View?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  insert_rtcs_gm_dev_PH_africa360view_Africa360View_one?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
  /** insert data into the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  insert_rtcs_gm_dev_PH_bcc_RegRates?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  insert_rtcs_gm_dev_PH_bcc_RegRates_one?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
  /** insert data into the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_PH_bcc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_PH_bcc_TradeLicenseRegister_one?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
  /** insert data into the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  insert_rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  insert_rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_one?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
  /** insert data into the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  insert_rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  insert_rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_one?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
  /** insert data into the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  insert_rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  insert_rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_one?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  insert_rtcs_gm_dev_PH_gra_CommericalResidentialTax?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  insert_rtcs_gm_dev_PH_gra_CommericalResidentialTax_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnDirector?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnDirector_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnExemption?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnExemption_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnLoss?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnLoss_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnPartnership?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnPartnership_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnRentReceived?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  insert_rtcs_gm_dev_PH_gra_CorporateReturn_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnExemption?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnExemption_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnLoan?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnLoan_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnLoss?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnLoss_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnRent?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnRentPaid?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturnRent_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  insert_rtcs_gm_dev_PH_gra_IndividualReturn_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  insert_rtcs_gm_dev_PH_gra_PaymentRental?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  insert_rtcs_gm_dev_PH_gra_PaymentRental_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  insert_rtcs_gm_dev_PH_gra_RegAssociation?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  insert_rtcs_gm_dev_PH_gra_RegAssociation_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  insert_rtcs_gm_dev_PH_gra_RentalOfficeData?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  insert_rtcs_gm_dev_PH_gra_RentalOfficeData_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_Request" */
  insert_rtcs_gm_dev_PH_gra_Request?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_Request" */
  insert_rtcs_gm_dev_PH_gra_Request_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  insert_rtcs_gm_dev_PH_gra_ReturnError?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  insert_rtcs_gm_dev_PH_gra_ReturnError_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  insert_rtcs_gm_dev_PH_gra_ReturnsBase?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  insert_rtcs_gm_dev_PH_gra_ReturnsBase_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  insert_rtcs_gm_dev_PH_gra_TaxPayer?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  insert_rtcs_gm_dev_PH_gra_TaxPayer_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  insert_rtcs_gm_dev_PH_gra_VATReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  insert_rtcs_gm_dev_PH_gra_VATReturn_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  insert_rtcs_gm_dev_PH_gra_WithholdingReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  insert_rtcs_gm_dev_PH_gra_WithholdingReturnDetail?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  insert_rtcs_gm_dev_PH_gra_WithholdingReturnDetail_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  insert_rtcs_gm_dev_PH_gra_WithholdingReturn_one?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
  /** insert data into the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  insert_rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  insert_rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_one?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  insert_rtcs_gm_dev_PH_kmc_PropertyRatesPayers?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  insert_rtcs_gm_dev_PH_kmc_PropertyRatesPayers_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  insert_rtcs_gm_dev_PH_kmc_PropertyRatesRegister?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  insert_rtcs_gm_dev_PH_kmc_PropertyRatesRegister_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  insert_rtcs_gm_dev_PH_kmc_RatesDatabaseExtract?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  insert_rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  insert_rtcs_gm_dev_PH_kmc_RatesPropertyData?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  insert_rtcs_gm_dev_PH_kmc_RatesPropertyData_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  insert_rtcs_gm_dev_PH_kmc_RatesReceiptsData?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  insert_rtcs_gm_dev_PH_kmc_RatesReceiptsData_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  insert_rtcs_gm_dev_PH_kmc_TradeLicense?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_PH_kmc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_PH_kmc_TradeLicenseRegister_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  insert_rtcs_gm_dev_PH_kmc_TradeLicense_one?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
  /** insert data into the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  insert_rtcs_gm_dev_PH_nawec_AllCustomers?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  insert_rtcs_gm_dev_PH_nawec_AllCustomers_one?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
  /** insert data into the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  insert_rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  insert_rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_one?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
  /** insert data into the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  insert_rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  insert_rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_one?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
  /** insert data into the table: "rtcs_gm_dev.PH_rtcs_Country" */
  insert_rtcs_gm_dev_PH_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_rtcs_Country" */
  insert_rtcs_gm_dev_PH_rtcs_Country_one?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** insert data into the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_PH_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_PH_streetview_StreetViewAnalysis_one?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** insert data into the table: "rtcs_gm_dev.SYS_Users" */
  insert_rtcs_gm_dev_SYS_Users?: Maybe<Rtcs_Gm_Dev_Sys_Users_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.SYS_Users" */
  insert_rtcs_gm_dev_SYS_Users_one?: Maybe<Rtcs_Gm_Dev_Sys_Users>;
  /** insert data into the table: "rtcs_gm_dev.africa360view_Africa360View" */
  insert_rtcs_gm_dev_africa360view_Africa360View?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.africa360view_Africa360View" */
  insert_rtcs_gm_dev_africa360view_Africa360View_one?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View>;
  /** insert data into the table: "rtcs_gm_dev.aggregate_result" */
  insert_rtcs_gm_dev_aggregate_result?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.aggregate_result" */
  insert_rtcs_gm_dev_aggregate_result_one?: Maybe<Rtcs_Gm_Dev_Aggregate_Result>;
  /** insert data into the table: "rtcs_gm_dev.aggregate_result_txt" */
  insert_rtcs_gm_dev_aggregate_result_txt?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.aggregate_result_txt" */
  insert_rtcs_gm_dev_aggregate_result_txt_one?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** insert data into the table: "rtcs_gm_dev.bcc_RegRates" */
  insert_rtcs_gm_dev_bcc_RegRates?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.bcc_RegRates" */
  insert_rtcs_gm_dev_bcc_RegRates_one?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates>;
  /** insert data into the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_bcc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_bcc_TradeLicenseRegister_one?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister>;
  /** insert data into the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  insert_rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  insert_rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_one?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey>;
  /** insert data into the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  insert_rtcs_gm_dev_gbos_2024PopulationAndHousingCensus?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  insert_rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_one?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus>;
  /** insert data into the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  insert_rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  insert_rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_one?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** insert data into the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  insert_rtcs_gm_dev_gra_CommericalResidentialTax?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  insert_rtcs_gm_dev_gra_CommericalResidentialTax_one?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturn" */
  insert_rtcs_gm_dev_gra_CorporateReturn?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  insert_rtcs_gm_dev_gra_CorporateReturnDirector?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  insert_rtcs_gm_dev_gra_CorporateReturnDirector_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  insert_rtcs_gm_dev_gra_CorporateReturnExemption?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  insert_rtcs_gm_dev_gra_CorporateReturnExemption_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  insert_rtcs_gm_dev_gra_CorporateReturnLoss?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  insert_rtcs_gm_dev_gra_CorporateReturnLoss_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  insert_rtcs_gm_dev_gra_CorporateReturnPartnership?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  insert_rtcs_gm_dev_gra_CorporateReturnPartnership_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  insert_rtcs_gm_dev_gra_CorporateReturnPropertyRent?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  insert_rtcs_gm_dev_gra_CorporateReturnPropertyRent_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  insert_rtcs_gm_dev_gra_CorporateReturnRentReceived?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  insert_rtcs_gm_dev_gra_CorporateReturnRentReceived_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived>;
  /** insert data into the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  insert_rtcs_gm_dev_gra_CorporateReturnRentRecieved?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  insert_rtcs_gm_dev_gra_CorporateReturnRentRecieved_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_CorporateReturn" */
  insert_rtcs_gm_dev_gra_CorporateReturn_one?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn>;
  /** insert data into the table: "rtcs_gm_dev.gra_IndividualReturn" */
  insert_rtcs_gm_dev_gra_IndividualReturn?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  insert_rtcs_gm_dev_gra_IndividualReturnExemption?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  insert_rtcs_gm_dev_gra_IndividualReturnExemption_one?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption>;
  /** insert data into the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  insert_rtcs_gm_dev_gra_IndividualReturnLoan?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  insert_rtcs_gm_dev_gra_IndividualReturnLoan_one?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan>;
  /** insert data into the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  insert_rtcs_gm_dev_gra_IndividualReturnLoss?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  insert_rtcs_gm_dev_gra_IndividualReturnLoss_one?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss>;
  /** insert data into the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  insert_rtcs_gm_dev_gra_IndividualReturnRent?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  insert_rtcs_gm_dev_gra_IndividualReturnRentPaid?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  insert_rtcs_gm_dev_gra_IndividualReturnRentPaid_one?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  insert_rtcs_gm_dev_gra_IndividualReturnRent_one?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_IndividualReturn" */
  insert_rtcs_gm_dev_gra_IndividualReturn_one?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn>;
  /** insert data into the table: "rtcs_gm_dev.gra_PaymentRental" */
  insert_rtcs_gm_dev_gra_PaymentRental?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_PaymentRental" */
  insert_rtcs_gm_dev_gra_PaymentRental_one?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental>;
  /** insert data into the table: "rtcs_gm_dev.gra_RegAssociation" */
  insert_rtcs_gm_dev_gra_RegAssociation?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_RegAssociation" */
  insert_rtcs_gm_dev_gra_RegAssociation_one?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation>;
  /** insert data into the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  insert_rtcs_gm_dev_gra_RentalOfficeData?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  insert_rtcs_gm_dev_gra_RentalOfficeData_one?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData>;
  /** insert data into the table: "rtcs_gm_dev.gra_Request" */
  insert_rtcs_gm_dev_gra_Request?: Maybe<Rtcs_Gm_Dev_Gra_Request_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_Request" */
  insert_rtcs_gm_dev_gra_Request_one?: Maybe<Rtcs_Gm_Dev_Gra_Request>;
  /** insert data into the table: "rtcs_gm_dev.gra_ReturnError" */
  insert_rtcs_gm_dev_gra_ReturnError?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_ReturnError" */
  insert_rtcs_gm_dev_gra_ReturnError_one?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError>;
  /** insert data into the table: "rtcs_gm_dev.gra_ReturnsBase" */
  insert_rtcs_gm_dev_gra_ReturnsBase?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_ReturnsBase" */
  insert_rtcs_gm_dev_gra_ReturnsBase_one?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase>;
  /** insert data into the table: "rtcs_gm_dev.gra_TaxPayer" */
  insert_rtcs_gm_dev_gra_TaxPayer?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_TaxPayer" */
  insert_rtcs_gm_dev_gra_TaxPayer_one?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer>;
  /** insert data into the table: "rtcs_gm_dev.gra_VATReturn" */
  insert_rtcs_gm_dev_gra_VATReturn?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_VATReturn" */
  insert_rtcs_gm_dev_gra_VATReturn_one?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn>;
  /** insert data into the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  insert_rtcs_gm_dev_gra_WithholdingReturn?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  insert_rtcs_gm_dev_gra_WithholdingReturnDetail?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  insert_rtcs_gm_dev_gra_WithholdingReturnDetail_one?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail>;
  /** insert a single row into the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  insert_rtcs_gm_dev_gra_WithholdingReturn_one?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn>;
  /** insert data into the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  insert_rtcs_gm_dev_groc_MOJMonthlyRegistrations?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  insert_rtcs_gm_dev_groc_MOJMonthlyRegistrations_one?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations>;
  /** insert data into the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  insert_rtcs_gm_dev_kmc_PropertyRatesPayers?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  insert_rtcs_gm_dev_kmc_PropertyRatesPayers_one?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers>;
  /** insert data into the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  insert_rtcs_gm_dev_kmc_PropertyRatesRegister?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  insert_rtcs_gm_dev_kmc_PropertyRatesRegister_one?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister>;
  /** insert data into the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  insert_rtcs_gm_dev_kmc_RatesDatabaseExtract?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  insert_rtcs_gm_dev_kmc_RatesDatabaseExtract_one?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract>;
  /** insert data into the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  insert_rtcs_gm_dev_kmc_RatesPropertyData?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  insert_rtcs_gm_dev_kmc_RatesPropertyData_one?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData>;
  /** insert data into the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  insert_rtcs_gm_dev_kmc_RatesReceiptsData?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  insert_rtcs_gm_dev_kmc_RatesReceiptsData_one?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData>;
  /** insert data into the table: "rtcs_gm_dev.kmc_TradeLicense" */
  insert_rtcs_gm_dev_kmc_TradeLicense?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_kmc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  insert_rtcs_gm_dev_kmc_TradeLicenseRegister_one?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister>;
  /** insert a single row into the table: "rtcs_gm_dev.kmc_TradeLicense" */
  insert_rtcs_gm_dev_kmc_TradeLicense_one?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense>;
  /** insert data into the table: "rtcs_gm_dev.nawec_AllCustomers" */
  insert_rtcs_gm_dev_nawec_AllCustomers?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.nawec_AllCustomers" */
  insert_rtcs_gm_dev_nawec_AllCustomers_one?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers>;
  /** insert data into the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  insert_rtcs_gm_dev_nawec_WaterAndElectricityCustomers?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  insert_rtcs_gm_dev_nawec_WaterAndElectricityCustomers_one?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers>;
  /** insert data into the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  insert_rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  insert_rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_one?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations>;
  /** insert data into the table: "rtcs_gm_dev.rtcs_Country" */
  insert_rtcs_gm_dev_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.rtcs_Country" */
  insert_rtcs_gm_dev_rtcs_Country_one?: Maybe<Rtcs_Gm_Dev_Rtcs_Country>;
  /** insert data into the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_streetview_StreetViewAnalysis_one?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
  /** update data of the table: "rtcs_gm_dev.PH_SYS_Users" */
  update_rtcs_gm_dev_PH_SYS_Users?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  update_rtcs_gm_dev_PH_africa360view_Africa360View?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  update_rtcs_gm_dev_PH_bcc_RegRates?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  update_rtcs_gm_dev_PH_bcc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  update_rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  update_rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  update_rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  update_rtcs_gm_dev_PH_gra_CommericalResidentialTax?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  update_rtcs_gm_dev_PH_gra_CorporateReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnDirector?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnExemption?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnLoss?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnPartnership?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnRentReceived?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  update_rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  update_rtcs_gm_dev_PH_gra_IndividualReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  update_rtcs_gm_dev_PH_gra_IndividualReturnExemption?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  update_rtcs_gm_dev_PH_gra_IndividualReturnLoan?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  update_rtcs_gm_dev_PH_gra_IndividualReturnLoss?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  update_rtcs_gm_dev_PH_gra_IndividualReturnRent?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  update_rtcs_gm_dev_PH_gra_IndividualReturnRentPaid?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  update_rtcs_gm_dev_PH_gra_PaymentRental?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  update_rtcs_gm_dev_PH_gra_RegAssociation?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  update_rtcs_gm_dev_PH_gra_RentalOfficeData?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_Request" */
  update_rtcs_gm_dev_PH_gra_Request?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  update_rtcs_gm_dev_PH_gra_ReturnError?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  update_rtcs_gm_dev_PH_gra_ReturnsBase?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  update_rtcs_gm_dev_PH_gra_TaxPayer?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  update_rtcs_gm_dev_PH_gra_VATReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  update_rtcs_gm_dev_PH_gra_WithholdingReturn?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  update_rtcs_gm_dev_PH_gra_WithholdingReturnDetail?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  update_rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  update_rtcs_gm_dev_PH_kmc_PropertyRatesPayers?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  update_rtcs_gm_dev_PH_kmc_PropertyRatesRegister?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  update_rtcs_gm_dev_PH_kmc_RatesDatabaseExtract?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  update_rtcs_gm_dev_PH_kmc_RatesPropertyData?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  update_rtcs_gm_dev_PH_kmc_RatesReceiptsData?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  update_rtcs_gm_dev_PH_kmc_TradeLicense?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  update_rtcs_gm_dev_PH_kmc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  update_rtcs_gm_dev_PH_nawec_AllCustomers?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  update_rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  update_rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_rtcs_Country" */
  update_rtcs_gm_dev_PH_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  update_rtcs_gm_dev_PH_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.SYS_Users" */
  update_rtcs_gm_dev_SYS_Users?: Maybe<Rtcs_Gm_Dev_Sys_Users_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.africa360view_Africa360View" */
  update_rtcs_gm_dev_africa360view_Africa360View?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.aggregate_result" */
  update_rtcs_gm_dev_aggregate_result?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.aggregate_result_txt" */
  update_rtcs_gm_dev_aggregate_result_txt?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.bcc_RegRates" */
  update_rtcs_gm_dev_bcc_RegRates?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  update_rtcs_gm_dev_bcc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  update_rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  update_rtcs_gm_dev_gbos_2024PopulationAndHousingCensus?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  update_rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  update_rtcs_gm_dev_gra_CommericalResidentialTax?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturn" */
  update_rtcs_gm_dev_gra_CorporateReturn?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  update_rtcs_gm_dev_gra_CorporateReturnDirector?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  update_rtcs_gm_dev_gra_CorporateReturnExemption?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  update_rtcs_gm_dev_gra_CorporateReturnLoss?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  update_rtcs_gm_dev_gra_CorporateReturnPartnership?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  update_rtcs_gm_dev_gra_CorporateReturnPropertyRent?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  update_rtcs_gm_dev_gra_CorporateReturnRentReceived?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  update_rtcs_gm_dev_gra_CorporateReturnRentRecieved?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_IndividualReturn" */
  update_rtcs_gm_dev_gra_IndividualReturn?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  update_rtcs_gm_dev_gra_IndividualReturnExemption?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  update_rtcs_gm_dev_gra_IndividualReturnLoan?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  update_rtcs_gm_dev_gra_IndividualReturnLoss?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  update_rtcs_gm_dev_gra_IndividualReturnRent?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  update_rtcs_gm_dev_gra_IndividualReturnRentPaid?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_PaymentRental" */
  update_rtcs_gm_dev_gra_PaymentRental?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_RegAssociation" */
  update_rtcs_gm_dev_gra_RegAssociation?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  update_rtcs_gm_dev_gra_RentalOfficeData?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_Request" */
  update_rtcs_gm_dev_gra_Request?: Maybe<Rtcs_Gm_Dev_Gra_Request_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_ReturnError" */
  update_rtcs_gm_dev_gra_ReturnError?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_ReturnsBase" */
  update_rtcs_gm_dev_gra_ReturnsBase?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_TaxPayer" */
  update_rtcs_gm_dev_gra_TaxPayer?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_VATReturn" */
  update_rtcs_gm_dev_gra_VATReturn?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  update_rtcs_gm_dev_gra_WithholdingReturn?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  update_rtcs_gm_dev_gra_WithholdingReturnDetail?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  update_rtcs_gm_dev_groc_MOJMonthlyRegistrations?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  update_rtcs_gm_dev_kmc_PropertyRatesPayers?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  update_rtcs_gm_dev_kmc_PropertyRatesRegister?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  update_rtcs_gm_dev_kmc_RatesDatabaseExtract?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  update_rtcs_gm_dev_kmc_RatesPropertyData?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  update_rtcs_gm_dev_kmc_RatesReceiptsData?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_TradeLicense" */
  update_rtcs_gm_dev_kmc_TradeLicense?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  update_rtcs_gm_dev_kmc_TradeLicenseRegister?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.nawec_AllCustomers" */
  update_rtcs_gm_dev_nawec_AllCustomers?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  update_rtcs_gm_dev_nawec_WaterAndElectricityCustomers?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  update_rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.rtcs_Country" */
  update_rtcs_gm_dev_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  update_rtcs_gm_dev_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response>;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  where: Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewArgs = {
  where: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Bcc_RegRatesArgs = {
  where: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegisterArgs = {
  where: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurveyArgs = {
  where: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensusArgs = {
  where: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  where: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTaxArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirectorArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemptionArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLossArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnershipArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRentArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceivedArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecievedArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemptionArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoanArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLossArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaidArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_PaymentRentalArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_RegAssociationArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_RentalOfficeDataArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_RequestArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_ReturnErrorArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_ReturnsBaseArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_TaxPayerArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_VatReturnArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetailArgs = {
  where: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrationsArgs = {
  where: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayersArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtractArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyDataArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsDataArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegisterArgs = {
  where: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Nawec_AllCustomersArgs = {
  where: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomersArgs = {
  where: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  where: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  where: Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  where: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Sys_UsersArgs = {
  where: Rtcs_Gm_Dev_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Africa360view_Africa360ViewArgs = {
  where: Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Aggregate_ResultArgs = {
  where: Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  where: Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Bcc_RegRatesArgs = {
  where: Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Bcc_TradeLicenseRegisterArgs = {
  where: Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurveyArgs = {
  where: Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensusArgs = {
  where: Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  where: Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CommericalResidentialTaxArgs = {
  where: Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnDirectorArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnExemptionArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnLossArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnPartnershipArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRentArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnRentReceivedArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_CorporateReturnRentRecievedArgs = {
  where: Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_IndividualReturnArgs = {
  where: Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_IndividualReturnExemptionArgs = {
  where: Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_IndividualReturnLoanArgs = {
  where: Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_IndividualReturnLossArgs = {
  where: Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_IndividualReturnRentArgs = {
  where: Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_IndividualReturnRentPaidArgs = {
  where: Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_PaymentRentalArgs = {
  where: Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_RegAssociationArgs = {
  where: Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_RentalOfficeDataArgs = {
  where: Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_RequestArgs = {
  where: Rtcs_Gm_Dev_Gra_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_ReturnErrorArgs = {
  where: Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_ReturnsBaseArgs = {
  where: Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_TaxPayerArgs = {
  where: Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_VatReturnArgs = {
  where: Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_WithholdingReturnArgs = {
  where: Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Gra_WithholdingReturnDetailArgs = {
  where: Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Groc_MojMonthlyRegistrationsArgs = {
  where: Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_PropertyRatesPayersArgs = {
  where: Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_PropertyRatesRegisterArgs = {
  where: Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_RatesDatabaseExtractArgs = {
  where: Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_RatesPropertyDataArgs = {
  where: Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_RatesReceiptsDataArgs = {
  where: Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_TradeLicenseArgs = {
  where: Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Kmc_TradeLicenseRegisterArgs = {
  where: Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Nawec_AllCustomersArgs = {
  where: Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomersArgs = {
  where: Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  where: Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Rtcs_CountryArgs = {
  where: Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  where: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Sys_Users_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Bcc_RegRatesArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Bcc_RegRates_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegisterArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurveyArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensusArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTaxArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirectorArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemptionArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLossArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnershipArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRentArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceivedArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecievedArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemptionArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoanArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLossArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaidArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_PaymentRentalArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_PaymentRental_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_RegAssociationArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_RegAssociation_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_RentalOfficeDataArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_RequestArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_Request_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_Request_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_Request_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_ReturnErrorArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_ReturnError_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_ReturnsBaseArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_TaxPayerArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_TaxPayer_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_VatReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_VatReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetailArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrationsArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayersArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtractArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyDataArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsDataArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegisterArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Nawec_AllCustomersArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomersArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Rtcs_Country_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Sys_UsersArgs = {
  objects: Array<Rtcs_Gm_Dev_Sys_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Sys_Users_OneArgs = {
  object: Rtcs_Gm_Dev_Sys_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Africa360view_Africa360ViewArgs = {
  objects: Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Africa360view_Africa360View_OneArgs = {
  object: Rtcs_Gm_Dev_Africa360view_Africa360View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_ResultArgs = {
  objects: Array<Rtcs_Gm_Dev_Aggregate_Result_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_Result_OneArgs = {
  object: Rtcs_Gm_Dev_Aggregate_Result_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  objects: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_Result_Txt_OneArgs = {
  object: Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Bcc_RegRatesArgs = {
  objects: Array<Rtcs_Gm_Dev_Bcc_RegRates_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Bcc_RegRates_OneArgs = {
  object: Rtcs_Gm_Dev_Bcc_RegRates_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Bcc_TradeLicenseRegisterArgs = {
  objects: Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_OneArgs = {
  object: Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurveyArgs = {
  objects: Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_OneArgs = {
  object: Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensusArgs = {
  objects: Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_OneArgs = {
  object: Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  objects: Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_OneArgs = {
  object: Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CommericalResidentialTaxArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CommericalResidentialTax_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnDirectorArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnDirector_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnExemptionArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnExemption_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnLossArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnLoss_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnPartnershipArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRentArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnRentReceivedArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnRentRecievedArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_CorporateReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_CorporateReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnExemptionArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnExemption_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnLoanArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnLoan_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnLossArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnLoss_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnRentArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnRentPaidArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturnRent_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_IndividualReturnRent_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_IndividualReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_IndividualReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_PaymentRentalArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_PaymentRental_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_PaymentRental_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_PaymentRental_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_RegAssociationArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_RegAssociation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_RegAssociation_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_RegAssociation_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_RentalOfficeDataArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_RentalOfficeData_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_RentalOfficeData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_RequestArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_Request_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_Request_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_Request_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_ReturnErrorArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_ReturnError_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_ReturnError_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_ReturnError_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_ReturnsBaseArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_ReturnsBase_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_ReturnsBase_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_TaxPayerArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_TaxPayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_TaxPayer_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_TaxPayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_VatReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_VatReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_VatReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_VatReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_WithholdingReturnArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_WithholdingReturnDetailArgs = {
  objects: Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Gra_WithholdingReturn_OneArgs = {
  object: Rtcs_Gm_Dev_Gra_WithholdingReturn_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Groc_MojMonthlyRegistrationsArgs = {
  objects: Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_OneArgs = {
  object: Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_PropertyRatesPayersArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_PropertyRatesRegisterArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_RatesDatabaseExtractArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_RatesPropertyDataArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_RatesPropertyData_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_RatesPropertyData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_RatesReceiptsDataArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_RatesReceiptsData_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_TradeLicenseArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_TradeLicenseRegisterArgs = {
  objects: Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Kmc_TradeLicense_OneArgs = {
  object: Rtcs_Gm_Dev_Kmc_TradeLicense_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Nawec_AllCustomersArgs = {
  objects: Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Nawec_AllCustomers_OneArgs = {
  object: Rtcs_Gm_Dev_Nawec_AllCustomers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomersArgs = {
  objects: Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_OneArgs = {
  object: Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  objects: Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_OneArgs = {
  object: Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Rtcs_CountryArgs = {
  objects: Array<Rtcs_Gm_Dev_Rtcs_Country_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Rtcs_Country_OneArgs = {
  object: Rtcs_Gm_Dev_Rtcs_Country_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  objects: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_OneArgs = {
  object: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Bcc_RegRatesArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegisterArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurveyArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensusArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTaxArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirectorArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemptionArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLossArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnershipArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRentArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceivedArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecievedArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemptionArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoanArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLossArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaidArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_PaymentRentalArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_RegAssociationArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_RentalOfficeDataArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_RequestArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_ReturnErrorArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_ReturnsBaseArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_TaxPayerArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_VatReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetailArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrationsArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtractArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyDataArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsDataArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegisterArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Nawec_AllCustomersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Sys_UsersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Set_Input>;
  where: Rtcs_Gm_Dev_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Africa360view_Africa360ViewArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Set_Input>;
  where: Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Aggregate_ResultArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Set_Input>;
  where: Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  _set?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Set_Input>;
  where: Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Bcc_RegRatesArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Set_Input>;
  where: Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Bcc_TradeLicenseRegisterArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Set_Input>;
  where: Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurveyArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Set_Input>;
  where: Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensusArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Set_Input>;
  where: Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Set_Input>;
  where: Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CommericalResidentialTaxArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnDirectorArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnExemptionArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnLossArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnPartnershipArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRentArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnRentReceivedArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_CorporateReturnRentRecievedArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_IndividualReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_IndividualReturnExemptionArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_IndividualReturnLoanArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_IndividualReturnLossArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_IndividualReturnRentArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_IndividualReturnRentPaidArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_PaymentRentalArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_RegAssociationArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_RentalOfficeDataArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_RequestArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_ReturnErrorArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_ReturnsBaseArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_TaxPayerArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_VatReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_WithholdingReturnArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Gra_WithholdingReturnDetailArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Set_Input>;
  where: Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Groc_MojMonthlyRegistrationsArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Set_Input>;
  where: Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_PropertyRatesPayersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_PropertyRatesRegisterArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_RatesDatabaseExtractArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_RatesPropertyDataArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_RatesReceiptsDataArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_TradeLicenseArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Kmc_TradeLicenseRegisterArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Set_Input>;
  where: Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Nawec_AllCustomersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Set_Input>;
  where: Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Set_Input>;
  where: Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Set_Input>;
  where: Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Rtcs_CountryArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Set_Input>;
  where: Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Set_Input>;
  where: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "rtcs_gm_dev.FnSYS_Users" which returns "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** execute function "rtcs_gm_dev.FnSYS_Users" and query aggregates on result of table type "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View" which returns "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_Fnafrica360view_Africa360View: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View" and query aggregates on result of table type "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregate: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates" which returns "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_Fnbcc_RegRates: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates" and query aggregates on result of table type "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregate: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister" which returns "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister" and query aggregates on result of table type "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey" which returns "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey" and query aggregates on result of table type "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus" which returns "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus" and query aggregates on result of table type "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations" which returns "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations" and query aggregates on result of table type "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax" which returns "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregate: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn" which returns "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_Fngra_CorporateReturn: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector" which returns "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption" which returns "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss" which returns "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership" which returns "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent" which returns "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived" which returns "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved" which returns "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn" which returns "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_Fngra_IndividualReturn: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption" which returns "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan" which returns "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss" which returns "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent" which returns "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_Fngra_IndividualReturnRent: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid" which returns "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental" which returns "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_Fngra_PaymentRental: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregate: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation" which returns "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_Fngra_RegAssociation: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregate: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData" which returns "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_Fngra_RentalOfficeData: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregate: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_Request" which returns "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_Fngra_Request: Array<Rtcs_Gm_Dev_Ph_Gra_Request>;
  /** execute function "rtcs_gm_dev.Fngra_Request" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_Fngra_Request_aggregate: Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_Request_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_Request_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_Request_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_Request_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError" which returns "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_Fngra_ReturnError: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_Fngra_ReturnError_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase" which returns "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_Fngra_ReturnsBase: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer" which returns "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_Fngra_TaxPayer: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregate: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn" which returns "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_Fngra_VATReturn: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_Fngra_VATReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn" which returns "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_Fngra_WithholdingReturn: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail" which returns "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations" which returns "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations" and query aggregates on result of table type "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers" which returns "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister" which returns "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract" which returns "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData" which returns "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData" which returns "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense" which returns "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_Fnkmc_TradeLicense: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister" which returns "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers" which returns "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_Fnnawec_AllCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers" and query aggregates on result of table type "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers" which returns "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers" and query aggregates on result of table type "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations" which returns "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations" and query aggregates on result of table type "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" which returns "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" and query aggregates on result of table type "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" which returns "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" and query aggregates on result of table type "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_PH_africa360view_Africa360View: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_PH_africa360view_Africa360View_aggregate: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_PH_bcc_RegRates: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_PH_bcc_RegRates_aggregate: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_bcc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_bcc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_PH_gra_CommericalResidentialTax: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_PH_gra_CommericalResidentialTax_aggregate: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_PH_gra_CorporateReturn: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_PH_gra_CorporateReturnDirector: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_PH_gra_CorporateReturnDirector_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_PH_gra_CorporateReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_PH_gra_CorporateReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_PH_gra_CorporateReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_PH_gra_CorporateReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_PH_gra_CorporateReturnPartnership: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_PH_gra_CorporateReturnPartnership_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentReceived: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_PH_gra_CorporateReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_PH_gra_IndividualReturn: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_PH_gra_IndividualReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_PH_gra_IndividualReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoan: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoan_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_PH_gra_IndividualReturnRent: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_PH_gra_IndividualReturnRentPaid: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_PH_gra_IndividualReturnRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_PH_gra_IndividualReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_PH_gra_PaymentRental: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_PH_gra_PaymentRental_aggregate: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_PH_gra_RegAssociation: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_PH_gra_RegAssociation_aggregate: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_PH_gra_RentalOfficeData: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_PH_gra_RentalOfficeData_aggregate: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_PH_gra_Request: Array<Rtcs_Gm_Dev_Ph_Gra_Request>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_PH_gra_Request_aggregate: Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_PH_gra_ReturnError: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_PH_gra_ReturnError_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_PH_gra_ReturnsBase: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_PH_gra_ReturnsBase_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_PH_gra_TaxPayer: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_PH_gra_TaxPayer_aggregate: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_PH_gra_VATReturn: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_PH_gra_VATReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_PH_gra_WithholdingReturn: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_PH_gra_WithholdingReturnDetail: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_PH_gra_WithholdingReturnDetail_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_PH_gra_WithholdingReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_PH_kmc_PropertyRatesPayers: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_PH_kmc_PropertyRatesPayers_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_PH_kmc_PropertyRatesRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_PH_kmc_PropertyRatesRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_PH_kmc_RatesDatabaseExtract: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_PH_kmc_RatesPropertyData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_PH_kmc_RatesPropertyData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_PH_kmc_RatesReceiptsData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_PH_kmc_RatesReceiptsData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_PH_kmc_TradeLicense: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_kmc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_kmc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_PH_kmc_TradeLicense_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_PH_nawec_AllCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_PH_nawec_AllCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users: Array<Rtcs_Gm_Dev_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users_aggregate: Rtcs_Gm_Dev_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.africa360view_Africa360View" */
  rtcs_gm_dev_africa360view_Africa360View: Array<Rtcs_Gm_Dev_Africa360view_Africa360View>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.africa360view_Africa360View" */
  rtcs_gm_dev_africa360view_Africa360View_aggregate: Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.bcc_RegRates" */
  rtcs_gm_dev_bcc_RegRates: Array<Rtcs_Gm_Dev_Bcc_RegRates>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.bcc_RegRates" */
  rtcs_gm_dev_bcc_RegRates_aggregate: Rtcs_Gm_Dev_Bcc_RegRates_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  rtcs_gm_dev_bcc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  rtcs_gm_dev_bcc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey: Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_aggregate: Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_gbos_2024PopulationAndHousingCensus: Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_aggregate: Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations: Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_aggregate: Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  rtcs_gm_dev_gra_CommericalResidentialTax: Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  rtcs_gm_dev_gra_CommericalResidentialTax_aggregate: Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturn" */
  rtcs_gm_dev_gra_CorporateReturn: Array<Rtcs_Gm_Dev_Gra_CorporateReturn>;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  rtcs_gm_dev_gra_CorporateReturnDirector: Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  rtcs_gm_dev_gra_CorporateReturnDirector_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  rtcs_gm_dev_gra_CorporateReturnExemption: Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  rtcs_gm_dev_gra_CorporateReturnExemption_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  rtcs_gm_dev_gra_CorporateReturnLoss: Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  rtcs_gm_dev_gra_CorporateReturnLoss_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  rtcs_gm_dev_gra_CorporateReturnPartnership: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  rtcs_gm_dev_gra_CorporateReturnPartnership_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_gra_CorporateReturnPropertyRent: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_gra_CorporateReturnPropertyRent_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_gra_CorporateReturnRentReceived: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_gra_CorporateReturnRentReceived_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_gra_CorporateReturnRentRecieved: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_gra_CorporateReturnRentRecieved_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturn" */
  rtcs_gm_dev_gra_CorporateReturn_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturn" */
  rtcs_gm_dev_gra_IndividualReturn: Array<Rtcs_Gm_Dev_Gra_IndividualReturn>;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  rtcs_gm_dev_gra_IndividualReturnExemption: Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  rtcs_gm_dev_gra_IndividualReturnExemption_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  rtcs_gm_dev_gra_IndividualReturnLoan: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  rtcs_gm_dev_gra_IndividualReturnLoan_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  rtcs_gm_dev_gra_IndividualReturnLoss: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  rtcs_gm_dev_gra_IndividualReturnLoss_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  rtcs_gm_dev_gra_IndividualReturnRent: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent>;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_gra_IndividualReturnRentPaid: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_gra_IndividualReturnRentPaid_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  rtcs_gm_dev_gra_IndividualReturnRent_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturn" */
  rtcs_gm_dev_gra_IndividualReturn_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_PaymentRental" */
  rtcs_gm_dev_gra_PaymentRental: Array<Rtcs_Gm_Dev_Gra_PaymentRental>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_PaymentRental" */
  rtcs_gm_dev_gra_PaymentRental_aggregate: Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_RegAssociation" */
  rtcs_gm_dev_gra_RegAssociation: Array<Rtcs_Gm_Dev_Gra_RegAssociation>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_RegAssociation" */
  rtcs_gm_dev_gra_RegAssociation_aggregate: Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  rtcs_gm_dev_gra_RentalOfficeData: Array<Rtcs_Gm_Dev_Gra_RentalOfficeData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  rtcs_gm_dev_gra_RentalOfficeData_aggregate: Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_Request" */
  rtcs_gm_dev_gra_Request: Array<Rtcs_Gm_Dev_Gra_Request>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_Request" */
  rtcs_gm_dev_gra_Request_aggregate: Rtcs_Gm_Dev_Gra_Request_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_ReturnError" */
  rtcs_gm_dev_gra_ReturnError: Array<Rtcs_Gm_Dev_Gra_ReturnError>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_ReturnError" */
  rtcs_gm_dev_gra_ReturnError_aggregate: Rtcs_Gm_Dev_Gra_ReturnError_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_ReturnsBase" */
  rtcs_gm_dev_gra_ReturnsBase: Array<Rtcs_Gm_Dev_Gra_ReturnsBase>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_ReturnsBase" */
  rtcs_gm_dev_gra_ReturnsBase_aggregate: Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_TaxPayer" */
  rtcs_gm_dev_gra_TaxPayer: Array<Rtcs_Gm_Dev_Gra_TaxPayer>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_TaxPayer" */
  rtcs_gm_dev_gra_TaxPayer_aggregate: Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_VATReturn" */
  rtcs_gm_dev_gra_VATReturn: Array<Rtcs_Gm_Dev_Gra_VatReturn>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_VATReturn" */
  rtcs_gm_dev_gra_VATReturn_aggregate: Rtcs_Gm_Dev_Gra_VatReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  rtcs_gm_dev_gra_WithholdingReturn: Array<Rtcs_Gm_Dev_Gra_WithholdingReturn>;
  /** fetch data from the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  rtcs_gm_dev_gra_WithholdingReturnDetail: Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  rtcs_gm_dev_gra_WithholdingReturnDetail_aggregate: Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  rtcs_gm_dev_gra_WithholdingReturn_aggregate: Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_groc_MOJMonthlyRegistrations: Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_groc_MOJMonthlyRegistrations_aggregate: Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  rtcs_gm_dev_kmc_PropertyRatesPayers: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  rtcs_gm_dev_kmc_PropertyRatesPayers_aggregate: Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  rtcs_gm_dev_kmc_PropertyRatesRegister: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  rtcs_gm_dev_kmc_PropertyRatesRegister_aggregate: Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_kmc_RatesDatabaseExtract: Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_kmc_RatesDatabaseExtract_aggregate: Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  rtcs_gm_dev_kmc_RatesPropertyData: Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  rtcs_gm_dev_kmc_RatesPropertyData_aggregate: Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  rtcs_gm_dev_kmc_RatesReceiptsData: Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  rtcs_gm_dev_kmc_RatesReceiptsData_aggregate: Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_TradeLicense" */
  rtcs_gm_dev_kmc_TradeLicense: Array<Rtcs_Gm_Dev_Kmc_TradeLicense>;
  /** fetch data from the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  rtcs_gm_dev_kmc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  rtcs_gm_dev_kmc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_TradeLicense" */
  rtcs_gm_dev_kmc_TradeLicense_aggregate: Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.nawec_AllCustomers" */
  rtcs_gm_dev_nawec_AllCustomers: Array<Rtcs_Gm_Dev_Nawec_AllCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.nawec_AllCustomers" */
  rtcs_gm_dev_nawec_AllCustomers_aggregate: Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_nawec_WaterAndElectricityCustomers: Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_nawec_WaterAndElectricityCustomers_aggregate: Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations: Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_aggregate: Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country: Array<Rtcs_Gm_Dev_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country_aggregate: Rtcs_Gm_Dev_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_UsersArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnafrica360view_Africa360ViewArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_RegRatesArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_RegRates_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_RegRates_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_RegRates_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegisterArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurveyArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensusArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrationsArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTaxArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirectorArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemptionArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnLossArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnershipArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRentArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceivedArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecievedArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemptionArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoanArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLossArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaidArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_PaymentRentalArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_PaymentRental_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_PaymentRental_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_PaymentRental_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RegAssociationArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RegAssociation_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RegAssociation_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RegAssociation_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RentalOfficeDataArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_RequestArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_Request_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_Request_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_Request_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_Request_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_Request_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnErrorArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnError_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnError_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnError_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnsBaseArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnsBase_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnsBase_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnsBase_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_TaxPayerArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_TaxPayer_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_TaxPayer_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_TaxPayer_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_VatReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_VatReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_VatReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_VatReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetailArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrationsArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayersArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegisterArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtractArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyDataArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsDataArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegisterArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_AllCustomersArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomersArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrationsArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_CountryArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysisArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Africa360view_Africa360ViewArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Africa360view_Africa360View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Bcc_RegRatesArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Bcc_RegRates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurveyArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensusArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CommericalResidentialTaxArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnDirectorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnershipArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceivedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecievedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLoanArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaidArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_PaymentRentalArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_PaymentRental_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_RegAssociationArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_RegAssociation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_RentalOfficeDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_RentalOfficeData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_RequestArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_ReturnErrorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_ReturnError_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_ReturnsBaseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_ReturnsBase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_TaxPayerArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_TaxPayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_VatReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_VatReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetailArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtractArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_RatesPropertyDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_RatesReceiptsDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicenseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Nawec_AllCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Nawec_AllCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Africa360view_Africa360ViewArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Africa360view_Africa360View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_ResultArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_Result_Txt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Bcc_RegRatesArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Bcc_RegRates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Bcc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Bcc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurveyArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensusArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CommericalResidentialTaxArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CommericalResidentialTax_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnDirectorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnDirector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnPartnershipArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnPartnership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnPropertyRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnRentReceivedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnRentReceived_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnRentRecievedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_CorporateReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnLoanArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnLoan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnRentPaidArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnRentPaid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturnRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_IndividualReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_PaymentRentalArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_PaymentRental_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_RegAssociationArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_RegAssociation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_RentalOfficeDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_RentalOfficeData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_RequestArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_ReturnErrorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_ReturnError_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_ReturnsBaseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_ReturnsBase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_TaxPayerArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_TaxPayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_VatReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_VatReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_WithholdingReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_WithholdingReturnDetailArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_WithholdingReturnDetail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Gra_WithholdingReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Groc_MojMonthlyRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Groc_MojMonthlyRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_PropertyRatesPayersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_PropertyRatesPayers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_PropertyRatesRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_PropertyRatesRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_RatesDatabaseExtractArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_RatesDatabaseExtract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_RatesPropertyDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_RatesPropertyData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_RatesReceiptsDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_RatesReceiptsData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_TradeLicenseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Kmc_TradeLicense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Nawec_AllCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Nawec_AllCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Nawec_WaterAndElectricityCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};

export type Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_FnSys_Users_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnbcc_RegRates_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_CorporateReturn_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_IndividualReturn_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_PaymentRental_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_RegAssociation_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_RentalOfficeData_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_Request_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_Request_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_Request_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_ReturnError_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_ReturnsBase_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_TaxPayer_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_VatReturn_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngra_WithholdingReturn_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnkmc_TradeLicense_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnnawec_AllCustomers_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnrtcs_Country_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users';
  Active?: Maybe<Scalars['Boolean']['output']>;
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmailVerified?: Maybe<Scalars['Boolean']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
};

/** aggregate fields of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_avg_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_SYS_Users". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp = {
  Active?: InputMaybe<Boolean_Comparison_Exp>;
  CreationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  EmailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  GroupName?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  LoginAttemptCounter?: InputMaybe<Float8_Comparison_Exp>;
  MobilePhone?: InputMaybe<String_Comparison_Exp>;
  Password?: InputMaybe<String_Comparison_Exp>;
  PasswordModificationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ProfilePicture?: InputMaybe<String_Comparison_Exp>;
  Updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  VisibleLevel?: InputMaybe<String_Comparison_Exp>;
  WorkAddress?: InputMaybe<String_Comparison_Exp>;
  WorkPhone?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Inc_Input = {
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_max_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Max_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_min_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Min_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Order_By = {
  Active?: InputMaybe<Order_By>;
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmailVerified?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_SYS_Users" */
export enum Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column {
  /** column name */
  Active = 'Active',
  /** column name */
  CreationDate = 'CreationDate',
  /** column name */
  Email = 'Email',
  /** column name */
  EmailVerified = 'EmailVerified',
  /** column name */
  File = 'File',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  GroupName = 'GroupName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LoginAttemptCounter = 'LoginAttemptCounter',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  Password = 'Password',
  /** column name */
  PasswordModificationDate = 'PasswordModificationDate',
  /** column name */
  ProfilePicture = 'ProfilePicture',
  /** column name */
  Updated = 'Updated',
  /** column name */
  VisibleLevel = 'VisibleLevel',
  /** column name */
  WorkAddress = 'WorkAddress',
  /** column name */
  WorkPhone = 'WorkPhone'
}

/** input type for updating data in table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Set_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_stddev_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_stddev_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_stddev_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_sum_fields';
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_var_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_var_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_variance_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
};

/** aggregate fields of "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_avg_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Avg_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_africa360view_Africa360View". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp = {
  Buildingaream2?: InputMaybe<Float8_Comparison_Exp>;
  Buildingcentroidgps?: InputMaybe<String_Comparison_Exp>;
  Buildingtype?: InputMaybe<String_Comparison_Exp>;
  Businessname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  Numberofstoreys?: InputMaybe<Bigint_Comparison_Exp>;
  Phone1?: InputMaybe<String_Comparison_Exp>;
  Phone2?: InputMaybe<Float8_Comparison_Exp>;
  Pluscode1?: InputMaybe<String_Comparison_Exp>;
  Pluscode2?: InputMaybe<String_Comparison_Exp>;
  Roofmaterial?: InputMaybe<String_Comparison_Exp>;
  Settlement?: InputMaybe<String_Comparison_Exp>;
  Street?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  Wallmaterial?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Inc_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Insert_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_max_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Max_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_min_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Min_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export enum Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column {
  /** column name */
  Buildingaream2 = 'Buildingaream2',
  /** column name */
  Buildingcentroidgps = 'Buildingcentroidgps',
  /** column name */
  Buildingtype = 'Buildingtype',
  /** column name */
  Businessname = 'Businessname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line = 'Line',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  Numberofstoreys = 'Numberofstoreys',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  Pluscode1 = 'Pluscode1',
  /** column name */
  Pluscode2 = 'Pluscode2',
  /** column name */
  Roofmaterial = 'Roofmaterial',
  /** column name */
  Settlement = 'Settlement',
  /** column name */
  Street = 'Street',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  Wallmaterial = 'Wallmaterial',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Set_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_stddev_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_stddev_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_stddev_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Stddev_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_sum_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Sum_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_var_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_var_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Var_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View_variance_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Variance_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates';
  Address?: Maybe<Scalars['String']['output']>;
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears1?: Maybe<Scalars['String']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Arrears3?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Contactno?: Maybe<Scalars['String']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Current3?: Maybe<Scalars['String']['output']>;
  Currentowner?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  Demandnoteno?: Maybe<Scalars['String']['output']>;
  Detailsofpayment1?: Maybe<Scalars['String']['output']>;
  Detailsofpayment2?: Maybe<Scalars['String']['output']>;
  Detailsofpayment3?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lot?: Maybe<Scalars['String']['output']>;
  Particulars?: Maybe<Scalars['String']['output']>;
  Plandate?: Maybe<Scalars['timestamptz']['output']>;
  Plandetails?: Maybe<Scalars['String']['output']>;
  Rem?: Maybe<Scalars['String']['output']>;
  Sm?: Maybe<Scalars['String']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Total2?: Maybe<Scalars['String']['output']>;
  Total3?: Maybe<Scalars['String']['output']>;
  Totalpaytodate1?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate2?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate3?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wr?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
};

/** aggregate fields of "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_avg_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Avg_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_bcc_RegRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Amountdue?: InputMaybe<Float8_Comparison_Exp>;
  Arrears1?: InputMaybe<String_Comparison_Exp>;
  Arrears2?: InputMaybe<Float8_Comparison_Exp>;
  Arrears3?: InputMaybe<String_Comparison_Exp>;
  Balance?: InputMaybe<String_Comparison_Exp>;
  Contactno?: InputMaybe<String_Comparison_Exp>;
  Current1?: InputMaybe<Float8_Comparison_Exp>;
  Current2?: InputMaybe<Float8_Comparison_Exp>;
  Current3?: InputMaybe<String_Comparison_Exp>;
  Currentowner?: InputMaybe<String_Comparison_Exp>;
  Date?: InputMaybe<Timestamptz_Comparison_Exp>;
  Demandnoteno?: InputMaybe<String_Comparison_Exp>;
  Detailsofpayment1?: InputMaybe<String_Comparison_Exp>;
  Detailsofpayment2?: InputMaybe<String_Comparison_Exp>;
  Detailsofpayment3?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lot?: InputMaybe<String_Comparison_Exp>;
  Particulars?: InputMaybe<String_Comparison_Exp>;
  Plandate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Plandetails?: InputMaybe<String_Comparison_Exp>;
  Rem?: InputMaybe<String_Comparison_Exp>;
  Sm?: InputMaybe<String_Comparison_Exp>;
  Total1?: InputMaybe<Float8_Comparison_Exp>;
  Total2?: InputMaybe<String_Comparison_Exp>;
  Total3?: InputMaybe<String_Comparison_Exp>;
  Totalpaytodate1?: InputMaybe<Timestamptz_Comparison_Exp>;
  Totalpaytodate2?: InputMaybe<Timestamptz_Comparison_Exp>;
  Totalpaytodate3?: InputMaybe<Timestamptz_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Wr?: InputMaybe<String_Comparison_Exp>;
  Year?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Inc_Input = {
  Amountdue?: InputMaybe<Scalars['float8']['input']>;
  Arrears2?: InputMaybe<Scalars['float8']['input']>;
  Current1?: InputMaybe<Scalars['float8']['input']>;
  Current2?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Total1?: InputMaybe<Scalars['float8']['input']>;
  Year?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Amountdue?: InputMaybe<Scalars['float8']['input']>;
  Arrears1?: InputMaybe<Scalars['String']['input']>;
  Arrears2?: InputMaybe<Scalars['float8']['input']>;
  Arrears3?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Contactno?: InputMaybe<Scalars['String']['input']>;
  Current1?: InputMaybe<Scalars['float8']['input']>;
  Current2?: InputMaybe<Scalars['float8']['input']>;
  Current3?: InputMaybe<Scalars['String']['input']>;
  Currentowner?: InputMaybe<Scalars['String']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  Demandnoteno?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment1?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment2?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment3?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lot?: InputMaybe<Scalars['String']['input']>;
  Particulars?: InputMaybe<Scalars['String']['input']>;
  Plandate?: InputMaybe<Scalars['timestamptz']['input']>;
  Plandetails?: InputMaybe<Scalars['String']['input']>;
  Rem?: InputMaybe<Scalars['String']['input']>;
  Sm?: InputMaybe<Scalars['String']['input']>;
  Total1?: InputMaybe<Scalars['float8']['input']>;
  Total2?: InputMaybe<Scalars['String']['input']>;
  Total3?: InputMaybe<Scalars['String']['input']>;
  Totalpaytodate1?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate2?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate3?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wr?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears1?: Maybe<Scalars['String']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Arrears3?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Contactno?: Maybe<Scalars['String']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Current3?: Maybe<Scalars['String']['output']>;
  Currentowner?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  Demandnoteno?: Maybe<Scalars['String']['output']>;
  Detailsofpayment1?: Maybe<Scalars['String']['output']>;
  Detailsofpayment2?: Maybe<Scalars['String']['output']>;
  Detailsofpayment3?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lot?: Maybe<Scalars['String']['output']>;
  Particulars?: Maybe<Scalars['String']['output']>;
  Plandate?: Maybe<Scalars['timestamptz']['output']>;
  Plandetails?: Maybe<Scalars['String']['output']>;
  Rem?: Maybe<Scalars['String']['output']>;
  Sm?: Maybe<Scalars['String']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Total2?: Maybe<Scalars['String']['output']>;
  Total3?: Maybe<Scalars['String']['output']>;
  Totalpaytodate1?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate2?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate3?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wr?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Amountdue?: InputMaybe<Order_By>;
  Arrears1?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Arrears3?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Contactno?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Current3?: InputMaybe<Order_By>;
  Currentowner?: InputMaybe<Order_By>;
  Date?: InputMaybe<Order_By>;
  Demandnoteno?: InputMaybe<Order_By>;
  Detailsofpayment1?: InputMaybe<Order_By>;
  Detailsofpayment2?: InputMaybe<Order_By>;
  Detailsofpayment3?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lot?: InputMaybe<Order_By>;
  Particulars?: InputMaybe<Order_By>;
  Plandate?: InputMaybe<Order_By>;
  Plandetails?: InputMaybe<Order_By>;
  Rem?: InputMaybe<Order_By>;
  Sm?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Total2?: InputMaybe<Order_By>;
  Total3?: InputMaybe<Order_By>;
  Totalpaytodate1?: InputMaybe<Order_By>;
  Totalpaytodate2?: InputMaybe<Order_By>;
  Totalpaytodate3?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wr?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears1?: Maybe<Scalars['String']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Arrears3?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Contactno?: Maybe<Scalars['String']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Current3?: Maybe<Scalars['String']['output']>;
  Currentowner?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  Demandnoteno?: Maybe<Scalars['String']['output']>;
  Detailsofpayment1?: Maybe<Scalars['String']['output']>;
  Detailsofpayment2?: Maybe<Scalars['String']['output']>;
  Detailsofpayment3?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lot?: Maybe<Scalars['String']['output']>;
  Particulars?: Maybe<Scalars['String']['output']>;
  Plandate?: Maybe<Scalars['timestamptz']['output']>;
  Plandetails?: Maybe<Scalars['String']['output']>;
  Rem?: Maybe<Scalars['String']['output']>;
  Sm?: Maybe<Scalars['String']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Total2?: Maybe<Scalars['String']['output']>;
  Total3?: Maybe<Scalars['String']['output']>;
  Totalpaytodate1?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate2?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate3?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wr?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Amountdue?: InputMaybe<Order_By>;
  Arrears1?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Arrears3?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Contactno?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Current3?: InputMaybe<Order_By>;
  Currentowner?: InputMaybe<Order_By>;
  Date?: InputMaybe<Order_By>;
  Demandnoteno?: InputMaybe<Order_By>;
  Detailsofpayment1?: InputMaybe<Order_By>;
  Detailsofpayment2?: InputMaybe<Order_By>;
  Detailsofpayment3?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lot?: InputMaybe<Order_By>;
  Particulars?: InputMaybe<Order_By>;
  Plandate?: InputMaybe<Order_By>;
  Plandetails?: InputMaybe<Order_By>;
  Rem?: InputMaybe<Order_By>;
  Sm?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Total2?: InputMaybe<Order_By>;
  Total3?: InputMaybe<Order_By>;
  Totalpaytodate1?: InputMaybe<Order_By>;
  Totalpaytodate2?: InputMaybe<Order_By>;
  Totalpaytodate3?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wr?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By = {
  Address?: InputMaybe<Order_By>;
  Amountdue?: InputMaybe<Order_By>;
  Arrears1?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Arrears3?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Contactno?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Current3?: InputMaybe<Order_By>;
  Currentowner?: InputMaybe<Order_By>;
  Date?: InputMaybe<Order_By>;
  Demandnoteno?: InputMaybe<Order_By>;
  Detailsofpayment1?: InputMaybe<Order_By>;
  Detailsofpayment2?: InputMaybe<Order_By>;
  Detailsofpayment3?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lot?: InputMaybe<Order_By>;
  Particulars?: InputMaybe<Order_By>;
  Plandate?: InputMaybe<Order_By>;
  Plandetails?: InputMaybe<Order_By>;
  Rem?: InputMaybe<Order_By>;
  Sm?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Total2?: InputMaybe<Order_By>;
  Total3?: InputMaybe<Order_By>;
  Totalpaytodate1?: InputMaybe<Order_By>;
  Totalpaytodate2?: InputMaybe<Order_By>;
  Totalpaytodate3?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wr?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export enum Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Amountdue = 'Amountdue',
  /** column name */
  Arrears1 = 'Arrears1',
  /** column name */
  Arrears2 = 'Arrears2',
  /** column name */
  Arrears3 = 'Arrears3',
  /** column name */
  Balance = 'Balance',
  /** column name */
  Contactno = 'Contactno',
  /** column name */
  Current1 = 'Current1',
  /** column name */
  Current2 = 'Current2',
  /** column name */
  Current3 = 'Current3',
  /** column name */
  Currentowner = 'Currentowner',
  /** column name */
  Date = 'Date',
  /** column name */
  Demandnoteno = 'Demandnoteno',
  /** column name */
  Detailsofpayment1 = 'Detailsofpayment1',
  /** column name */
  Detailsofpayment2 = 'Detailsofpayment2',
  /** column name */
  Detailsofpayment3 = 'Detailsofpayment3',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lot = 'Lot',
  /** column name */
  Particulars = 'Particulars',
  /** column name */
  Plandate = 'Plandate',
  /** column name */
  Plandetails = 'Plandetails',
  /** column name */
  Rem = 'Rem',
  /** column name */
  Sm = 'Sm',
  /** column name */
  Total1 = 'Total1',
  /** column name */
  Total2 = 'Total2',
  /** column name */
  Total3 = 'Total3',
  /** column name */
  Totalpaytodate1 = 'Totalpaytodate1',
  /** column name */
  Totalpaytodate2 = 'Totalpaytodate2',
  /** column name */
  Totalpaytodate3 = 'Totalpaytodate3',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Wr = 'Wr',
  /** column name */
  Year = 'Year'
}

/** input type for updating data in table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Amountdue?: InputMaybe<Scalars['float8']['input']>;
  Arrears1?: InputMaybe<Scalars['String']['input']>;
  Arrears2?: InputMaybe<Scalars['float8']['input']>;
  Arrears3?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Contactno?: InputMaybe<Scalars['String']['input']>;
  Current1?: InputMaybe<Scalars['float8']['input']>;
  Current2?: InputMaybe<Scalars['float8']['input']>;
  Current3?: InputMaybe<Scalars['String']['input']>;
  Currentowner?: InputMaybe<Scalars['String']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  Demandnoteno?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment1?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment2?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment3?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lot?: InputMaybe<Scalars['String']['input']>;
  Particulars?: InputMaybe<Scalars['String']['input']>;
  Plandate?: InputMaybe<Scalars['timestamptz']['input']>;
  Plandetails?: InputMaybe<Scalars['String']['input']>;
  Rem?: InputMaybe<Scalars['String']['input']>;
  Sm?: InputMaybe<Scalars['String']['input']>;
  Total1?: InputMaybe<Scalars['float8']['input']>;
  Total2?: InputMaybe<Scalars['String']['input']>;
  Total3?: InputMaybe<Scalars['String']['input']>;
  Totalpaytodate1?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate2?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate3?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wr?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_stddev_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_stddev_pop_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Pop_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_stddev_samp_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Stddev_Samp_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_sum_fields';
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Sum_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_var_pop_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Pop_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_var_samp_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Var_Samp_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_RegRates_variance_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_bcc_RegRates" */
export type Rtcs_Gm_Dev_Ph_Bcc_RegRates_Variance_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister';
  Address?: Maybe<Scalars['String']['output']>;
  Arrears?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Certificateno?: Maybe<Scalars['String']['output']>;
  Currentyear?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Invdate?: Maybe<Scalars['timestamptz']['output']>;
  Invid?: Maybe<Scalars['String']['output']>;
  Invno?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Licencetype?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Receiptdetails?: Maybe<Scalars['String']['output']>;
  Totalinvoiceamount?: Maybe<Scalars['String']['output']>;
  Totalreceipttodate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wrno?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
};

/** aggregate fields of "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Arrears?: InputMaybe<String_Comparison_Exp>;
  Balance?: InputMaybe<String_Comparison_Exp>;
  Certificateno?: InputMaybe<String_Comparison_Exp>;
  Currentyear?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<String_Comparison_Exp>;
  Invdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Invid?: InputMaybe<String_Comparison_Exp>;
  Invno?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Licencetype?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Receiptdetails?: InputMaybe<String_Comparison_Exp>;
  Totalinvoiceamount?: InputMaybe<String_Comparison_Exp>;
  Totalreceipttodate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Wrno?: InputMaybe<String_Comparison_Exp>;
  Year?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Arrears?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Certificateno?: InputMaybe<Scalars['String']['input']>;
  Currentyear?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Invdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Invid?: InputMaybe<Scalars['String']['input']>;
  Invno?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Licencetype?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Receiptdetails?: InputMaybe<Scalars['String']['input']>;
  Totalinvoiceamount?: InputMaybe<Scalars['String']['input']>;
  Totalreceipttodate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wrno?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Arrears?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Certificateno?: Maybe<Scalars['String']['output']>;
  Currentyear?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Invdate?: Maybe<Scalars['timestamptz']['output']>;
  Invid?: Maybe<Scalars['String']['output']>;
  Invno?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Licencetype?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Receiptdetails?: Maybe<Scalars['String']['output']>;
  Totalinvoiceamount?: Maybe<Scalars['String']['output']>;
  Totalreceipttodate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wrno?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Arrears?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Certificateno?: InputMaybe<Order_By>;
  Currentyear?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Invdate?: InputMaybe<Order_By>;
  Invid?: InputMaybe<Order_By>;
  Invno?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Licencetype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Receiptdetails?: InputMaybe<Order_By>;
  Totalinvoiceamount?: InputMaybe<Order_By>;
  Totalreceipttodate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wrno?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Arrears?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Certificateno?: Maybe<Scalars['String']['output']>;
  Currentyear?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Invdate?: Maybe<Scalars['timestamptz']['output']>;
  Invid?: Maybe<Scalars['String']['output']>;
  Invno?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Licencetype?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Receiptdetails?: Maybe<Scalars['String']['output']>;
  Totalinvoiceamount?: Maybe<Scalars['String']['output']>;
  Totalreceipttodate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wrno?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Arrears?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Certificateno?: InputMaybe<Order_By>;
  Currentyear?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Invdate?: InputMaybe<Order_By>;
  Invid?: InputMaybe<Order_By>;
  Invno?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Licencetype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Receiptdetails?: InputMaybe<Order_By>;
  Totalinvoiceamount?: InputMaybe<Order_By>;
  Totalreceipttodate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wrno?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By = {
  Address?: InputMaybe<Order_By>;
  Arrears?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Certificateno?: InputMaybe<Order_By>;
  Currentyear?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Invdate?: InputMaybe<Order_By>;
  Invid?: InputMaybe<Order_By>;
  Invno?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Licencetype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Receiptdetails?: InputMaybe<Order_By>;
  Totalinvoiceamount?: InputMaybe<Order_By>;
  Totalreceipttodate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wrno?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export enum Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Arrears = 'Arrears',
  /** column name */
  Balance = 'Balance',
  /** column name */
  Certificateno = 'Certificateno',
  /** column name */
  Currentyear = 'Currentyear',
  /** column name */
  File = 'File',
  /** column name */
  Id = 'Id',
  /** column name */
  Invdate = 'Invdate',
  /** column name */
  Invid = 'Invid',
  /** column name */
  Invno = 'Invno',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Licencetype = 'Licencetype',
  /** column name */
  Line = 'Line',
  /** column name */
  Name = 'Name',
  /** column name */
  Receiptdetails = 'Receiptdetails',
  /** column name */
  Totalinvoiceamount = 'Totalinvoiceamount',
  /** column name */
  Totalreceipttodate = 'Totalreceipttodate',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Wrno = 'Wrno',
  /** column name */
  Year = 'Year'
}

/** input type for updating data in table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Arrears?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Certificateno?: InputMaybe<Scalars['String']['input']>;
  Currentyear?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Invdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Invid?: InputMaybe<Scalars['String']['input']>;
  Invno?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Licencetype?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Receiptdetails?: InputMaybe<Scalars['String']['input']>;
  Totalinvoiceamount?: InputMaybe<Scalars['String']['input']>;
  Totalreceipttodate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wrno?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey';
  Address?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagency?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagent?: Maybe<Scalars['String']['output']>;
  AmountPaid?: Maybe<Scalars['String']['output']>;
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  AnyMore?: Maybe<Scalars['String']['output']>;
  BusinessActivitytype?: Maybe<Scalars['String']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  CurrencyUnit?: Maybe<Scalars['String']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FrequencyOfPayment?: Maybe<Scalars['String']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lga?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusiness?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagent?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: Maybe<Scalars['String']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhereStatus?: Maybe<Scalars['String']['output']>;
  OtherSpecifiedFrequencyPaymentArrangement?: Maybe<Scalars['String']['output']>;
  OwnerOccupiedEstimatedValueOfRent?: Maybe<Scalars['String']['output']>;
  PaymentFrequency?: Maybe<Scalars['String']['output']>;
  PositionOfRespondentcontactPerson?: Maybe<Scalars['String']['output']>;
  PropertyOwneradministrator?: Maybe<Scalars['String']['output']>;
  RentPeriodArrangement?: Maybe<Scalars['String']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_avg_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Avg_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  AddressOfLandlordagency?: InputMaybe<String_Comparison_Exp>;
  AddressOfLandlordagent?: InputMaybe<String_Comparison_Exp>;
  AmountPaid?: InputMaybe<String_Comparison_Exp>;
  AmountPaidPerPeriod?: InputMaybe<Bigint_Comparison_Exp>;
  AnyMore?: InputMaybe<String_Comparison_Exp>;
  BusinessActivitytype?: InputMaybe<String_Comparison_Exp>;
  BusinessTelephone?: InputMaybe<Float8_Comparison_Exp>;
  ContactOfLandlordagent?: InputMaybe<Float8_Comparison_Exp>;
  ContactPersonsTel?: InputMaybe<Float8_Comparison_Exp>;
  CurrencyUnit?: InputMaybe<String_Comparison_Exp>;
  DurationInMonths?: InputMaybe<Bigint_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FrequencyOfPayment?: InputMaybe<String_Comparison_Exp>;
  IndividualId?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lga?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  NameOfBusiness?: InputMaybe<String_Comparison_Exp>;
  NameOfLandlordagent?: InputMaybe<String_Comparison_Exp>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<String_Comparison_Exp>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Bigint_Comparison_Exp>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<String_Comparison_Exp>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<String_Comparison_Exp>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<String_Comparison_Exp>;
  PaymentFrequency?: InputMaybe<String_Comparison_Exp>;
  PositionOfRespondentcontactPerson?: InputMaybe<String_Comparison_Exp>;
  PropertyOwneradministrator?: InputMaybe<String_Comparison_Exp>;
  RentPeriodArrangement?: InputMaybe<String_Comparison_Exp>;
  SecondContact?: InputMaybe<Float8_Comparison_Exp>;
  SecondTelOfContactPerson?: InputMaybe<Float8_Comparison_Exp>;
  TelOfLandlordagent?: InputMaybe<Float8_Comparison_Exp>;
  ThirdContact?: InputMaybe<Float8_Comparison_Exp>;
  TinOfLandlordagent?: InputMaybe<Float8_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Inc_Input = {
  AmountPaidPerPeriod?: InputMaybe<Scalars['bigint']['input']>;
  BusinessTelephone?: InputMaybe<Scalars['float8']['input']>;
  ContactOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ContactPersonsTel?: InputMaybe<Scalars['float8']['input']>;
  DurationInMonths?: InputMaybe<Scalars['bigint']['input']>;
  IndividualId?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Scalars['bigint']['input']>;
  SecondContact?: InputMaybe<Scalars['float8']['input']>;
  SecondTelOfContactPerson?: InputMaybe<Scalars['float8']['input']>;
  TelOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ThirdContact?: InputMaybe<Scalars['float8']['input']>;
  TinOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagency?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  AmountPaid?: InputMaybe<Scalars['String']['input']>;
  AmountPaidPerPeriod?: InputMaybe<Scalars['bigint']['input']>;
  AnyMore?: InputMaybe<Scalars['String']['input']>;
  BusinessActivitytype?: InputMaybe<Scalars['String']['input']>;
  BusinessTelephone?: InputMaybe<Scalars['float8']['input']>;
  ContactOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ContactPersonsTel?: InputMaybe<Scalars['float8']['input']>;
  CurrencyUnit?: InputMaybe<Scalars['String']['input']>;
  DurationInMonths?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FrequencyOfPayment?: InputMaybe<Scalars['String']['input']>;
  IndividualId?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lga?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusiness?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Scalars['String']['input']>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Scalars['bigint']['input']>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Scalars['String']['input']>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Scalars['String']['input']>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Scalars['String']['input']>;
  PaymentFrequency?: InputMaybe<Scalars['String']['input']>;
  PositionOfRespondentcontactPerson?: InputMaybe<Scalars['String']['input']>;
  PropertyOwneradministrator?: InputMaybe<Scalars['String']['input']>;
  RentPeriodArrangement?: InputMaybe<Scalars['String']['input']>;
  SecondContact?: InputMaybe<Scalars['float8']['input']>;
  SecondTelOfContactPerson?: InputMaybe<Scalars['float8']['input']>;
  TelOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ThirdContact?: InputMaybe<Scalars['float8']['input']>;
  TinOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagency?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagent?: Maybe<Scalars['String']['output']>;
  AmountPaid?: Maybe<Scalars['String']['output']>;
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  AnyMore?: Maybe<Scalars['String']['output']>;
  BusinessActivitytype?: Maybe<Scalars['String']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  CurrencyUnit?: Maybe<Scalars['String']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FrequencyOfPayment?: Maybe<Scalars['String']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lga?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusiness?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagent?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: Maybe<Scalars['String']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhereStatus?: Maybe<Scalars['String']['output']>;
  OtherSpecifiedFrequencyPaymentArrangement?: Maybe<Scalars['String']['output']>;
  OwnerOccupiedEstimatedValueOfRent?: Maybe<Scalars['String']['output']>;
  PaymentFrequency?: Maybe<Scalars['String']['output']>;
  PositionOfRespondentcontactPerson?: Maybe<Scalars['String']['output']>;
  PropertyOwneradministrator?: Maybe<Scalars['String']['output']>;
  RentPeriodArrangement?: Maybe<Scalars['String']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  AddressOfLandlordagency?: InputMaybe<Order_By>;
  AddressOfLandlordagent?: InputMaybe<Order_By>;
  AmountPaid?: InputMaybe<Order_By>;
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  AnyMore?: InputMaybe<Order_By>;
  BusinessActivitytype?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  CurrencyUnit?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FrequencyOfPayment?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lga?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusiness?: InputMaybe<Order_By>;
  NameOfLandlordagent?: InputMaybe<Order_By>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Order_By>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Order_By>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Order_By>;
  PaymentFrequency?: InputMaybe<Order_By>;
  PositionOfRespondentcontactPerson?: InputMaybe<Order_By>;
  PropertyOwneradministrator?: InputMaybe<Order_By>;
  RentPeriodArrangement?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagency?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagent?: Maybe<Scalars['String']['output']>;
  AmountPaid?: Maybe<Scalars['String']['output']>;
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  AnyMore?: Maybe<Scalars['String']['output']>;
  BusinessActivitytype?: Maybe<Scalars['String']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  CurrencyUnit?: Maybe<Scalars['String']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FrequencyOfPayment?: Maybe<Scalars['String']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lga?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusiness?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagent?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: Maybe<Scalars['String']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhereStatus?: Maybe<Scalars['String']['output']>;
  OtherSpecifiedFrequencyPaymentArrangement?: Maybe<Scalars['String']['output']>;
  OwnerOccupiedEstimatedValueOfRent?: Maybe<Scalars['String']['output']>;
  PaymentFrequency?: Maybe<Scalars['String']['output']>;
  PositionOfRespondentcontactPerson?: Maybe<Scalars['String']['output']>;
  PropertyOwneradministrator?: Maybe<Scalars['String']['output']>;
  RentPeriodArrangement?: Maybe<Scalars['String']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  AddressOfLandlordagency?: InputMaybe<Order_By>;
  AddressOfLandlordagent?: InputMaybe<Order_By>;
  AmountPaid?: InputMaybe<Order_By>;
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  AnyMore?: InputMaybe<Order_By>;
  BusinessActivitytype?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  CurrencyUnit?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FrequencyOfPayment?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lga?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusiness?: InputMaybe<Order_By>;
  NameOfLandlordagent?: InputMaybe<Order_By>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Order_By>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Order_By>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Order_By>;
  PaymentFrequency?: InputMaybe<Order_By>;
  PositionOfRespondentcontactPerson?: InputMaybe<Order_By>;
  PropertyOwneradministrator?: InputMaybe<Order_By>;
  RentPeriodArrangement?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By = {
  Address?: InputMaybe<Order_By>;
  AddressOfLandlordagency?: InputMaybe<Order_By>;
  AddressOfLandlordagent?: InputMaybe<Order_By>;
  AmountPaid?: InputMaybe<Order_By>;
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  AnyMore?: InputMaybe<Order_By>;
  BusinessActivitytype?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  CurrencyUnit?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FrequencyOfPayment?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lga?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusiness?: InputMaybe<Order_By>;
  NameOfLandlordagent?: InputMaybe<Order_By>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Order_By>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Order_By>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Order_By>;
  PaymentFrequency?: InputMaybe<Order_By>;
  PositionOfRespondentcontactPerson?: InputMaybe<Order_By>;
  PropertyOwneradministrator?: InputMaybe<Order_By>;
  RentPeriodArrangement?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export enum Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  AddressOfLandlordagency = 'AddressOfLandlordagency',
  /** column name */
  AddressOfLandlordagent = 'AddressOfLandlordagent',
  /** column name */
  AmountPaid = 'AmountPaid',
  /** column name */
  AmountPaidPerPeriod = 'AmountPaidPerPeriod',
  /** column name */
  AnyMore = 'AnyMore',
  /** column name */
  BusinessActivitytype = 'BusinessActivitytype',
  /** column name */
  BusinessTelephone = 'BusinessTelephone',
  /** column name */
  ContactOfLandlordagent = 'ContactOfLandlordagent',
  /** column name */
  ContactPersonsTel = 'ContactPersonsTel',
  /** column name */
  CurrencyUnit = 'CurrencyUnit',
  /** column name */
  DurationInMonths = 'DurationInMonths',
  /** column name */
  File = 'File',
  /** column name */
  FrequencyOfPayment = 'FrequencyOfPayment',
  /** column name */
  IndividualId = 'IndividualId',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lga = 'Lga',
  /** column name */
  Line = 'Line',
  /** column name */
  NameOfBusiness = 'NameOfBusiness',
  /** column name */
  NameOfLandlordagent = 'NameOfLandlordagent',
  /** column name */
  NameOfLandlordagentForOtherBusinessPropertyElsewhere = 'NameOfLandlordagentForOtherBusinessPropertyElsewhere',
  /** column name */
  OtherBusinessPropertyRentedElsewhere = 'OtherBusinessPropertyRentedElsewhere',
  /** column name */
  OtherBusinessPropertyRentedElsewhereStatus = 'OtherBusinessPropertyRentedElsewhereStatus',
  /** column name */
  OtherSpecifiedFrequencyPaymentArrangement = 'OtherSpecifiedFrequencyPaymentArrangement',
  /** column name */
  OwnerOccupiedEstimatedValueOfRent = 'OwnerOccupiedEstimatedValueOfRent',
  /** column name */
  PaymentFrequency = 'PaymentFrequency',
  /** column name */
  PositionOfRespondentcontactPerson = 'PositionOfRespondentcontactPerson',
  /** column name */
  PropertyOwneradministrator = 'PropertyOwneradministrator',
  /** column name */
  RentPeriodArrangement = 'RentPeriodArrangement',
  /** column name */
  SecondContact = 'SecondContact',
  /** column name */
  SecondTelOfContactPerson = 'SecondTelOfContactPerson',
  /** column name */
  TelOfLandlordagent = 'TelOfLandlordagent',
  /** column name */
  ThirdContact = 'ThirdContact',
  /** column name */
  TinOfLandlordagent = 'TinOfLandlordagent',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagency?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  AmountPaid?: InputMaybe<Scalars['String']['input']>;
  AmountPaidPerPeriod?: InputMaybe<Scalars['bigint']['input']>;
  AnyMore?: InputMaybe<Scalars['String']['input']>;
  BusinessActivitytype?: InputMaybe<Scalars['String']['input']>;
  BusinessTelephone?: InputMaybe<Scalars['float8']['input']>;
  ContactOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ContactPersonsTel?: InputMaybe<Scalars['float8']['input']>;
  CurrencyUnit?: InputMaybe<Scalars['String']['input']>;
  DurationInMonths?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FrequencyOfPayment?: InputMaybe<Scalars['String']['input']>;
  IndividualId?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lga?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusiness?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Scalars['String']['input']>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Scalars['bigint']['input']>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Scalars['String']['input']>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Scalars['String']['input']>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Scalars['String']['input']>;
  PaymentFrequency?: InputMaybe<Scalars['String']['input']>;
  PositionOfRespondentcontactPerson?: InputMaybe<Scalars['String']['input']>;
  PropertyOwneradministrator?: InputMaybe<Scalars['String']['input']>;
  RentPeriodArrangement?: InputMaybe<Scalars['String']['input']>;
  SecondContact?: InputMaybe<Scalars['float8']['input']>;
  SecondTelOfContactPerson?: InputMaybe<Scalars['float8']['input']>;
  TelOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ThirdContact?: InputMaybe<Scalars['float8']['input']>;
  TinOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_stddev_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_stddev_pop_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_stddev_samp_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_sum_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Sum_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_var_pop_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_var_samp_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_variance_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Variance_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus';
  Compoundowner?: Maybe<Scalars['String']['output']>;
  CookingFuel?: Maybe<Scalars['String']['output']>;
  Districtname?: Maybe<Scalars['String']['output']>;
  FeelEviction?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FloorMaterial?: Maybe<Scalars['String']['output']>;
  FreqSolidWasteCollection?: Maybe<Scalars['String']['output']>;
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhHeadName?: Maybe<Scalars['String']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Headofcompound?: Maybe<Scalars['String']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hsettlement?: Maybe<Scalars['String']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Lganame?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  LiqWasteFromEconomic?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHhchores?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHumanConve?: Maybe<Scalars['String']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  PayCompoundRate?: Maybe<Scalars['String']['output']>;
  PlaceForBathing?: Maybe<Scalars['String']['output']>;
  PlaceForCooking?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  SolidWasteDisposal?: Maybe<Scalars['String']['output']>;
  SourceDrinkingWater?: Maybe<Scalars['String']['output']>;
  SourceLighting?: Maybe<Scalars['String']['output']>;
  SuitableForResidence?: Maybe<Scalars['String']['output']>;
  TitleDeed?: Maybe<Scalars['String']['output']>;
  ToiletFacility?: Maybe<Scalars['String']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WallsMaterial?: Maybe<Scalars['String']['output']>;
  Wardname?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_avg_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Avg_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp = {
  Compoundowner?: InputMaybe<String_Comparison_Exp>;
  CookingFuel?: InputMaybe<String_Comparison_Exp>;
  Districtname?: InputMaybe<String_Comparison_Exp>;
  FeelEviction?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FloorMaterial?: InputMaybe<String_Comparison_Exp>;
  FreqSolidWasteCollection?: InputMaybe<String_Comparison_Exp>;
  HEaCode?: InputMaybe<Bigint_Comparison_Exp>;
  HHeadPhone?: InputMaybe<Bigint_Comparison_Exp>;
  HHhHeadName?: InputMaybe<String_Comparison_Exp>;
  HHhNumber?: InputMaybe<Bigint_Comparison_Exp>;
  HStrNumber?: InputMaybe<Bigint_Comparison_Exp>;
  Hdistrict?: InputMaybe<Bigint_Comparison_Exp>;
  Headofcompound?: InputMaybe<String_Comparison_Exp>;
  Hlga?: InputMaybe<Bigint_Comparison_Exp>;
  Hsettlement?: InputMaybe<String_Comparison_Exp>;
  Hward?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Lganame?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  LiqWasteFromEconomic?: InputMaybe<String_Comparison_Exp>;
  LiqWasteFromHhchores?: InputMaybe<String_Comparison_Exp>;
  LiqWasteFromHumanConve?: InputMaybe<String_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  NumOfFemale?: InputMaybe<Bigint_Comparison_Exp>;
  NumOfMale?: InputMaybe<Bigint_Comparison_Exp>;
  OccupancyStatus?: InputMaybe<String_Comparison_Exp>;
  PayCompoundRate?: InputMaybe<String_Comparison_Exp>;
  PlaceForBathing?: InputMaybe<String_Comparison_Exp>;
  PlaceForCooking?: InputMaybe<String_Comparison_Exp>;
  RoofMaterial?: InputMaybe<String_Comparison_Exp>;
  SolidWasteDisposal?: InputMaybe<String_Comparison_Exp>;
  SourceDrinkingWater?: InputMaybe<String_Comparison_Exp>;
  SourceLighting?: InputMaybe<String_Comparison_Exp>;
  SuitableForResidence?: InputMaybe<String_Comparison_Exp>;
  TitleDeed?: InputMaybe<String_Comparison_Exp>;
  ToiletFacility?: InputMaybe<String_Comparison_Exp>;
  TotalNum?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WallsMaterial?: InputMaybe<String_Comparison_Exp>;
  Wardname?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Inc_Input = {
  HEaCode?: InputMaybe<Scalars['bigint']['input']>;
  HHeadPhone?: InputMaybe<Scalars['bigint']['input']>;
  HHhNumber?: InputMaybe<Scalars['bigint']['input']>;
  HStrNumber?: InputMaybe<Scalars['bigint']['input']>;
  Hdistrict?: InputMaybe<Scalars['bigint']['input']>;
  Hlga?: InputMaybe<Scalars['bigint']['input']>;
  Hward?: InputMaybe<Scalars['bigint']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumOfFemale?: InputMaybe<Scalars['bigint']['input']>;
  NumOfMale?: InputMaybe<Scalars['bigint']['input']>;
  TotalNum?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Insert_Input = {
  Compoundowner?: InputMaybe<Scalars['String']['input']>;
  CookingFuel?: InputMaybe<Scalars['String']['input']>;
  Districtname?: InputMaybe<Scalars['String']['input']>;
  FeelEviction?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FloorMaterial?: InputMaybe<Scalars['String']['input']>;
  FreqSolidWasteCollection?: InputMaybe<Scalars['String']['input']>;
  HEaCode?: InputMaybe<Scalars['bigint']['input']>;
  HHeadPhone?: InputMaybe<Scalars['bigint']['input']>;
  HHhHeadName?: InputMaybe<Scalars['String']['input']>;
  HHhNumber?: InputMaybe<Scalars['bigint']['input']>;
  HStrNumber?: InputMaybe<Scalars['bigint']['input']>;
  Hdistrict?: InputMaybe<Scalars['bigint']['input']>;
  Headofcompound?: InputMaybe<Scalars['String']['input']>;
  Hlga?: InputMaybe<Scalars['bigint']['input']>;
  Hsettlement?: InputMaybe<Scalars['String']['input']>;
  Hward?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Lganame?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  LiqWasteFromEconomic?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHhchores?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHumanConve?: InputMaybe<Scalars['String']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumOfFemale?: InputMaybe<Scalars['bigint']['input']>;
  NumOfMale?: InputMaybe<Scalars['bigint']['input']>;
  OccupancyStatus?: InputMaybe<Scalars['String']['input']>;
  PayCompoundRate?: InputMaybe<Scalars['String']['input']>;
  PlaceForBathing?: InputMaybe<Scalars['String']['input']>;
  PlaceForCooking?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  SolidWasteDisposal?: InputMaybe<Scalars['String']['input']>;
  SourceDrinkingWater?: InputMaybe<Scalars['String']['input']>;
  SourceLighting?: InputMaybe<Scalars['String']['input']>;
  SuitableForResidence?: InputMaybe<Scalars['String']['input']>;
  TitleDeed?: InputMaybe<Scalars['String']['input']>;
  ToiletFacility?: InputMaybe<Scalars['String']['input']>;
  TotalNum?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WallsMaterial?: InputMaybe<Scalars['String']['input']>;
  Wardname?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_max_fields';
  Compoundowner?: Maybe<Scalars['String']['output']>;
  CookingFuel?: Maybe<Scalars['String']['output']>;
  Districtname?: Maybe<Scalars['String']['output']>;
  FeelEviction?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FloorMaterial?: Maybe<Scalars['String']['output']>;
  FreqSolidWasteCollection?: Maybe<Scalars['String']['output']>;
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhHeadName?: Maybe<Scalars['String']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Headofcompound?: Maybe<Scalars['String']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hsettlement?: Maybe<Scalars['String']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Lganame?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  LiqWasteFromEconomic?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHhchores?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHumanConve?: Maybe<Scalars['String']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  PayCompoundRate?: Maybe<Scalars['String']['output']>;
  PlaceForBathing?: Maybe<Scalars['String']['output']>;
  PlaceForCooking?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  SolidWasteDisposal?: Maybe<Scalars['String']['output']>;
  SourceDrinkingWater?: Maybe<Scalars['String']['output']>;
  SourceLighting?: Maybe<Scalars['String']['output']>;
  SuitableForResidence?: Maybe<Scalars['String']['output']>;
  TitleDeed?: Maybe<Scalars['String']['output']>;
  ToiletFacility?: Maybe<Scalars['String']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WallsMaterial?: Maybe<Scalars['String']['output']>;
  Wardname?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Max_Order_By = {
  Compoundowner?: InputMaybe<Order_By>;
  CookingFuel?: InputMaybe<Order_By>;
  Districtname?: InputMaybe<Order_By>;
  FeelEviction?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FloorMaterial?: InputMaybe<Order_By>;
  FreqSolidWasteCollection?: InputMaybe<Order_By>;
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhHeadName?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Headofcompound?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hsettlement?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Lganame?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  LiqWasteFromEconomic?: InputMaybe<Order_By>;
  LiqWasteFromHhchores?: InputMaybe<Order_By>;
  LiqWasteFromHumanConve?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  OccupancyStatus?: InputMaybe<Order_By>;
  PayCompoundRate?: InputMaybe<Order_By>;
  PlaceForBathing?: InputMaybe<Order_By>;
  PlaceForCooking?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  SolidWasteDisposal?: InputMaybe<Order_By>;
  SourceDrinkingWater?: InputMaybe<Order_By>;
  SourceLighting?: InputMaybe<Order_By>;
  SuitableForResidence?: InputMaybe<Order_By>;
  TitleDeed?: InputMaybe<Order_By>;
  ToiletFacility?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WallsMaterial?: InputMaybe<Order_By>;
  Wardname?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_min_fields';
  Compoundowner?: Maybe<Scalars['String']['output']>;
  CookingFuel?: Maybe<Scalars['String']['output']>;
  Districtname?: Maybe<Scalars['String']['output']>;
  FeelEviction?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FloorMaterial?: Maybe<Scalars['String']['output']>;
  FreqSolidWasteCollection?: Maybe<Scalars['String']['output']>;
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhHeadName?: Maybe<Scalars['String']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Headofcompound?: Maybe<Scalars['String']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hsettlement?: Maybe<Scalars['String']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Lganame?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  LiqWasteFromEconomic?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHhchores?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHumanConve?: Maybe<Scalars['String']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  PayCompoundRate?: Maybe<Scalars['String']['output']>;
  PlaceForBathing?: Maybe<Scalars['String']['output']>;
  PlaceForCooking?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  SolidWasteDisposal?: Maybe<Scalars['String']['output']>;
  SourceDrinkingWater?: Maybe<Scalars['String']['output']>;
  SourceLighting?: Maybe<Scalars['String']['output']>;
  SuitableForResidence?: Maybe<Scalars['String']['output']>;
  TitleDeed?: Maybe<Scalars['String']['output']>;
  ToiletFacility?: Maybe<Scalars['String']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WallsMaterial?: Maybe<Scalars['String']['output']>;
  Wardname?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Min_Order_By = {
  Compoundowner?: InputMaybe<Order_By>;
  CookingFuel?: InputMaybe<Order_By>;
  Districtname?: InputMaybe<Order_By>;
  FeelEviction?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FloorMaterial?: InputMaybe<Order_By>;
  FreqSolidWasteCollection?: InputMaybe<Order_By>;
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhHeadName?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Headofcompound?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hsettlement?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Lganame?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  LiqWasteFromEconomic?: InputMaybe<Order_By>;
  LiqWasteFromHhchores?: InputMaybe<Order_By>;
  LiqWasteFromHumanConve?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  OccupancyStatus?: InputMaybe<Order_By>;
  PayCompoundRate?: InputMaybe<Order_By>;
  PlaceForBathing?: InputMaybe<Order_By>;
  PlaceForCooking?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  SolidWasteDisposal?: InputMaybe<Order_By>;
  SourceDrinkingWater?: InputMaybe<Order_By>;
  SourceLighting?: InputMaybe<Order_By>;
  SuitableForResidence?: InputMaybe<Order_By>;
  TitleDeed?: InputMaybe<Order_By>;
  ToiletFacility?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WallsMaterial?: InputMaybe<Order_By>;
  Wardname?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By = {
  Compoundowner?: InputMaybe<Order_By>;
  CookingFuel?: InputMaybe<Order_By>;
  Districtname?: InputMaybe<Order_By>;
  FeelEviction?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FloorMaterial?: InputMaybe<Order_By>;
  FreqSolidWasteCollection?: InputMaybe<Order_By>;
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhHeadName?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Headofcompound?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hsettlement?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Lganame?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  LiqWasteFromEconomic?: InputMaybe<Order_By>;
  LiqWasteFromHhchores?: InputMaybe<Order_By>;
  LiqWasteFromHumanConve?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  OccupancyStatus?: InputMaybe<Order_By>;
  PayCompoundRate?: InputMaybe<Order_By>;
  PlaceForBathing?: InputMaybe<Order_By>;
  PlaceForCooking?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  SolidWasteDisposal?: InputMaybe<Order_By>;
  SourceDrinkingWater?: InputMaybe<Order_By>;
  SourceLighting?: InputMaybe<Order_By>;
  SuitableForResidence?: InputMaybe<Order_By>;
  TitleDeed?: InputMaybe<Order_By>;
  ToiletFacility?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WallsMaterial?: InputMaybe<Order_By>;
  Wardname?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export enum Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column {
  /** column name */
  Compoundowner = 'Compoundowner',
  /** column name */
  CookingFuel = 'CookingFuel',
  /** column name */
  Districtname = 'Districtname',
  /** column name */
  FeelEviction = 'FeelEviction',
  /** column name */
  File = 'File',
  /** column name */
  FloorMaterial = 'FloorMaterial',
  /** column name */
  FreqSolidWasteCollection = 'FreqSolidWasteCollection',
  /** column name */
  HEaCode = 'HEaCode',
  /** column name */
  HHeadPhone = 'HHeadPhone',
  /** column name */
  HHhHeadName = 'HHhHeadName',
  /** column name */
  HHhNumber = 'HHhNumber',
  /** column name */
  HStrNumber = 'HStrNumber',
  /** column name */
  Hdistrict = 'Hdistrict',
  /** column name */
  Headofcompound = 'Headofcompound',
  /** column name */
  Hlga = 'Hlga',
  /** column name */
  Hsettlement = 'Hsettlement',
  /** column name */
  Hward = 'Hward',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Lganame = 'Lganame',
  /** column name */
  Line = 'Line',
  /** column name */
  LiqWasteFromEconomic = 'LiqWasteFromEconomic',
  /** column name */
  LiqWasteFromHhchores = 'LiqWasteFromHhchores',
  /** column name */
  LiqWasteFromHumanConve = 'LiqWasteFromHumanConve',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  NumOfFemale = 'NumOfFemale',
  /** column name */
  NumOfMale = 'NumOfMale',
  /** column name */
  OccupancyStatus = 'OccupancyStatus',
  /** column name */
  PayCompoundRate = 'PayCompoundRate',
  /** column name */
  PlaceForBathing = 'PlaceForBathing',
  /** column name */
  PlaceForCooking = 'PlaceForCooking',
  /** column name */
  RoofMaterial = 'RoofMaterial',
  /** column name */
  SolidWasteDisposal = 'SolidWasteDisposal',
  /** column name */
  SourceDrinkingWater = 'SourceDrinkingWater',
  /** column name */
  SourceLighting = 'SourceLighting',
  /** column name */
  SuitableForResidence = 'SuitableForResidence',
  /** column name */
  TitleDeed = 'TitleDeed',
  /** column name */
  ToiletFacility = 'ToiletFacility',
  /** column name */
  TotalNum = 'TotalNum',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WallsMaterial = 'WallsMaterial',
  /** column name */
  Wardname = 'Wardname',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Set_Input = {
  Compoundowner?: InputMaybe<Scalars['String']['input']>;
  CookingFuel?: InputMaybe<Scalars['String']['input']>;
  Districtname?: InputMaybe<Scalars['String']['input']>;
  FeelEviction?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FloorMaterial?: InputMaybe<Scalars['String']['input']>;
  FreqSolidWasteCollection?: InputMaybe<Scalars['String']['input']>;
  HEaCode?: InputMaybe<Scalars['bigint']['input']>;
  HHeadPhone?: InputMaybe<Scalars['bigint']['input']>;
  HHhHeadName?: InputMaybe<Scalars['String']['input']>;
  HHhNumber?: InputMaybe<Scalars['bigint']['input']>;
  HStrNumber?: InputMaybe<Scalars['bigint']['input']>;
  Hdistrict?: InputMaybe<Scalars['bigint']['input']>;
  Headofcompound?: InputMaybe<Scalars['String']['input']>;
  Hlga?: InputMaybe<Scalars['bigint']['input']>;
  Hsettlement?: InputMaybe<Scalars['String']['input']>;
  Hward?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Lganame?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  LiqWasteFromEconomic?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHhchores?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHumanConve?: InputMaybe<Scalars['String']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumOfFemale?: InputMaybe<Scalars['bigint']['input']>;
  NumOfMale?: InputMaybe<Scalars['bigint']['input']>;
  OccupancyStatus?: InputMaybe<Scalars['String']['input']>;
  PayCompoundRate?: InputMaybe<Scalars['String']['input']>;
  PlaceForBathing?: InputMaybe<Scalars['String']['input']>;
  PlaceForCooking?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  SolidWasteDisposal?: InputMaybe<Scalars['String']['input']>;
  SourceDrinkingWater?: InputMaybe<Scalars['String']['input']>;
  SourceLighting?: InputMaybe<Scalars['String']['input']>;
  SuitableForResidence?: InputMaybe<Scalars['String']['input']>;
  TitleDeed?: InputMaybe<Scalars['String']['input']>;
  ToiletFacility?: InputMaybe<Scalars['String']['input']>;
  TotalNum?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WallsMaterial?: InputMaybe<Scalars['String']['input']>;
  Wardname?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_stddev_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_stddev_pop_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_stddev_samp_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_sum_fields';
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Sum_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_var_pop_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Pop_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_var_samp_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Var_Samp_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_variance_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Variance_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations';
  Active?: Maybe<Scalars['bigint']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Address1?: Maybe<Scalars['String']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dateofbirth?: Maybe<Scalars['String']['output']>;
  Dateofbirthandplaceofbirth?: Maybe<Scalars['String']['output']>;
  Dateupdate?: Maybe<Scalars['timestamptz']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd496?: Maybe<Scalars['String']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd498?: Maybe<Scalars['String']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd658?: Maybe<Scalars['String']['output']>;
  Dd1159?: Maybe<Scalars['String']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1163?: Maybe<Scalars['String']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1166?: Maybe<Scalars['String']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1169?: Maybe<Scalars['String']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1376?: Maybe<Scalars['timestamptz']['output']>;
  Dd1377?: Maybe<Scalars['String']['output']>;
  Dd1466?: Maybe<Scalars['String']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  Driverlicencereceiptnumber?: Maybe<Scalars['String']['output']>;
  Expirydate?: Maybe<Scalars['timestamptz']['output']>;
  Fathersfirstname?: Maybe<Scalars['String']['output']>;
  Fatherslastname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Firstname?: Maybe<Scalars['String']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Issuedate?: Maybe<Scalars['timestamptz']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mothersfirstname?: Maybe<Scalars['String']['output']>;
  Motherslastname?: Maybe<Scalars['String']['output']>;
  Nin?: Maybe<Scalars['String']['output']>;
  Occupation?: Maybe<Scalars['String']['output']>;
  Placeofbirth?: Maybe<Scalars['String']['output']>;
  Proffesion?: Maybe<Scalars['String']['output']>;
  Receiptno?: Maybe<Scalars['String']['output']>;
  Region?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_avg_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp = {
  Active?: InputMaybe<Bigint_Comparison_Exp>;
  Address?: InputMaybe<String_Comparison_Exp>;
  Address1?: InputMaybe<String_Comparison_Exp>;
  Cardnumbers?: InputMaybe<Float8_Comparison_Exp>;
  Dateofbirth?: InputMaybe<String_Comparison_Exp>;
  Dateofbirthandplaceofbirth?: InputMaybe<String_Comparison_Exp>;
  Dateupdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Dd151?: InputMaybe<Bigint_Comparison_Exp>;
  Dd168?: InputMaybe<Bigint_Comparison_Exp>;
  Dd381?: InputMaybe<Bigint_Comparison_Exp>;
  Dd493?: InputMaybe<Bigint_Comparison_Exp>;
  Dd495?: InputMaybe<Bigint_Comparison_Exp>;
  Dd496?: InputMaybe<String_Comparison_Exp>;
  Dd497?: InputMaybe<Bigint_Comparison_Exp>;
  Dd498?: InputMaybe<String_Comparison_Exp>;
  Dd547?: InputMaybe<Bigint_Comparison_Exp>;
  Dd658?: InputMaybe<String_Comparison_Exp>;
  Dd1159?: InputMaybe<String_Comparison_Exp>;
  Dd1162?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1163?: InputMaybe<String_Comparison_Exp>;
  Dd1165?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1166?: InputMaybe<String_Comparison_Exp>;
  Dd1168?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1169?: InputMaybe<String_Comparison_Exp>;
  Dd1171?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1376?: InputMaybe<Timestamptz_Comparison_Exp>;
  Dd1377?: InputMaybe<String_Comparison_Exp>;
  Dd1466?: InputMaybe<String_Comparison_Exp>;
  Dd1782?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1783?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1784?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1785?: InputMaybe<Bigint_Comparison_Exp>;
  Driverlicencereceiptnumber?: InputMaybe<String_Comparison_Exp>;
  Expirydate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Fathersfirstname?: InputMaybe<String_Comparison_Exp>;
  Fatherslastname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Firstname?: InputMaybe<String_Comparison_Exp>;
  FkStation?: InputMaybe<Float8_Comparison_Exp>;
  FkUtilisateur?: InputMaybe<Float8_Comparison_Exp>;
  Height?: InputMaybe<Bigint_Comparison_Exp>;
  IdEnreg?: InputMaybe<Bigint_Comparison_Exp>;
  Issuedate?: InputMaybe<Timestamptz_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lastname?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Mothersfirstname?: InputMaybe<String_Comparison_Exp>;
  Motherslastname?: InputMaybe<String_Comparison_Exp>;
  Nin?: InputMaybe<String_Comparison_Exp>;
  Occupation?: InputMaybe<String_Comparison_Exp>;
  Placeofbirth?: InputMaybe<String_Comparison_Exp>;
  Proffesion?: InputMaybe<String_Comparison_Exp>;
  Receiptno?: InputMaybe<String_Comparison_Exp>;
  Region?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Inc_Input = {
  Active?: InputMaybe<Scalars['bigint']['input']>;
  Cardnumbers?: InputMaybe<Scalars['float8']['input']>;
  Dd151?: InputMaybe<Scalars['bigint']['input']>;
  Dd168?: InputMaybe<Scalars['bigint']['input']>;
  Dd381?: InputMaybe<Scalars['bigint']['input']>;
  Dd493?: InputMaybe<Scalars['bigint']['input']>;
  Dd495?: InputMaybe<Scalars['bigint']['input']>;
  Dd497?: InputMaybe<Scalars['bigint']['input']>;
  Dd547?: InputMaybe<Scalars['bigint']['input']>;
  Dd1162?: InputMaybe<Scalars['bigint']['input']>;
  Dd1165?: InputMaybe<Scalars['bigint']['input']>;
  Dd1168?: InputMaybe<Scalars['bigint']['input']>;
  Dd1171?: InputMaybe<Scalars['bigint']['input']>;
  Dd1782?: InputMaybe<Scalars['bigint']['input']>;
  Dd1783?: InputMaybe<Scalars['bigint']['input']>;
  Dd1784?: InputMaybe<Scalars['bigint']['input']>;
  Dd1785?: InputMaybe<Scalars['bigint']['input']>;
  FkStation?: InputMaybe<Scalars['float8']['input']>;
  FkUtilisateur?: InputMaybe<Scalars['float8']['input']>;
  Height?: InputMaybe<Scalars['bigint']['input']>;
  IdEnreg?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input = {
  Active?: InputMaybe<Scalars['bigint']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Address1?: InputMaybe<Scalars['String']['input']>;
  Cardnumbers?: InputMaybe<Scalars['float8']['input']>;
  Dateofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateofbirthandplaceofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateupdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd151?: InputMaybe<Scalars['bigint']['input']>;
  Dd168?: InputMaybe<Scalars['bigint']['input']>;
  Dd381?: InputMaybe<Scalars['bigint']['input']>;
  Dd493?: InputMaybe<Scalars['bigint']['input']>;
  Dd495?: InputMaybe<Scalars['bigint']['input']>;
  Dd496?: InputMaybe<Scalars['String']['input']>;
  Dd497?: InputMaybe<Scalars['bigint']['input']>;
  Dd498?: InputMaybe<Scalars['String']['input']>;
  Dd547?: InputMaybe<Scalars['bigint']['input']>;
  Dd658?: InputMaybe<Scalars['String']['input']>;
  Dd1159?: InputMaybe<Scalars['String']['input']>;
  Dd1162?: InputMaybe<Scalars['bigint']['input']>;
  Dd1163?: InputMaybe<Scalars['String']['input']>;
  Dd1165?: InputMaybe<Scalars['bigint']['input']>;
  Dd1166?: InputMaybe<Scalars['String']['input']>;
  Dd1168?: InputMaybe<Scalars['bigint']['input']>;
  Dd1169?: InputMaybe<Scalars['String']['input']>;
  Dd1171?: InputMaybe<Scalars['bigint']['input']>;
  Dd1376?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd1377?: InputMaybe<Scalars['String']['input']>;
  Dd1466?: InputMaybe<Scalars['String']['input']>;
  Dd1782?: InputMaybe<Scalars['bigint']['input']>;
  Dd1783?: InputMaybe<Scalars['bigint']['input']>;
  Dd1784?: InputMaybe<Scalars['bigint']['input']>;
  Dd1785?: InputMaybe<Scalars['bigint']['input']>;
  Driverlicencereceiptnumber?: InputMaybe<Scalars['String']['input']>;
  Expirydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Fathersfirstname?: InputMaybe<Scalars['String']['input']>;
  Fatherslastname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Firstname?: InputMaybe<Scalars['String']['input']>;
  FkStation?: InputMaybe<Scalars['float8']['input']>;
  FkUtilisateur?: InputMaybe<Scalars['float8']['input']>;
  Height?: InputMaybe<Scalars['bigint']['input']>;
  IdEnreg?: InputMaybe<Scalars['bigint']['input']>;
  Issuedate?: InputMaybe<Scalars['timestamptz']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mothersfirstname?: InputMaybe<Scalars['String']['input']>;
  Motherslastname?: InputMaybe<Scalars['String']['input']>;
  Nin?: InputMaybe<Scalars['String']['input']>;
  Occupation?: InputMaybe<Scalars['String']['input']>;
  Placeofbirth?: InputMaybe<Scalars['String']['input']>;
  Proffesion?: InputMaybe<Scalars['String']['input']>;
  Receiptno?: InputMaybe<Scalars['String']['input']>;
  Region?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_max_fields';
  Active?: Maybe<Scalars['bigint']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Address1?: Maybe<Scalars['String']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dateofbirth?: Maybe<Scalars['String']['output']>;
  Dateofbirthandplaceofbirth?: Maybe<Scalars['String']['output']>;
  Dateupdate?: Maybe<Scalars['timestamptz']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd496?: Maybe<Scalars['String']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd498?: Maybe<Scalars['String']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd658?: Maybe<Scalars['String']['output']>;
  Dd1159?: Maybe<Scalars['String']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1163?: Maybe<Scalars['String']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1166?: Maybe<Scalars['String']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1169?: Maybe<Scalars['String']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1376?: Maybe<Scalars['timestamptz']['output']>;
  Dd1377?: Maybe<Scalars['String']['output']>;
  Dd1466?: Maybe<Scalars['String']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  Driverlicencereceiptnumber?: Maybe<Scalars['String']['output']>;
  Expirydate?: Maybe<Scalars['timestamptz']['output']>;
  Fathersfirstname?: Maybe<Scalars['String']['output']>;
  Fatherslastname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Firstname?: Maybe<Scalars['String']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Issuedate?: Maybe<Scalars['timestamptz']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mothersfirstname?: Maybe<Scalars['String']['output']>;
  Motherslastname?: Maybe<Scalars['String']['output']>;
  Nin?: Maybe<Scalars['String']['output']>;
  Occupation?: Maybe<Scalars['String']['output']>;
  Placeofbirth?: Maybe<Scalars['String']['output']>;
  Proffesion?: Maybe<Scalars['String']['output']>;
  Receiptno?: Maybe<Scalars['String']['output']>;
  Region?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Max_Order_By = {
  Active?: InputMaybe<Order_By>;
  Address?: InputMaybe<Order_By>;
  Address1?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dateofbirth?: InputMaybe<Order_By>;
  Dateofbirthandplaceofbirth?: InputMaybe<Order_By>;
  Dateupdate?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd496?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd498?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd658?: InputMaybe<Order_By>;
  Dd1159?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1163?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1166?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1169?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1376?: InputMaybe<Order_By>;
  Dd1377?: InputMaybe<Order_By>;
  Dd1466?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  Driverlicencereceiptnumber?: InputMaybe<Order_By>;
  Expirydate?: InputMaybe<Order_By>;
  Fathersfirstname?: InputMaybe<Order_By>;
  Fatherslastname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Firstname?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Issuedate?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mothersfirstname?: InputMaybe<Order_By>;
  Motherslastname?: InputMaybe<Order_By>;
  Nin?: InputMaybe<Order_By>;
  Occupation?: InputMaybe<Order_By>;
  Placeofbirth?: InputMaybe<Order_By>;
  Proffesion?: InputMaybe<Order_By>;
  Receiptno?: InputMaybe<Order_By>;
  Region?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_min_fields';
  Active?: Maybe<Scalars['bigint']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Address1?: Maybe<Scalars['String']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dateofbirth?: Maybe<Scalars['String']['output']>;
  Dateofbirthandplaceofbirth?: Maybe<Scalars['String']['output']>;
  Dateupdate?: Maybe<Scalars['timestamptz']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd496?: Maybe<Scalars['String']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd498?: Maybe<Scalars['String']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd658?: Maybe<Scalars['String']['output']>;
  Dd1159?: Maybe<Scalars['String']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1163?: Maybe<Scalars['String']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1166?: Maybe<Scalars['String']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1169?: Maybe<Scalars['String']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1376?: Maybe<Scalars['timestamptz']['output']>;
  Dd1377?: Maybe<Scalars['String']['output']>;
  Dd1466?: Maybe<Scalars['String']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  Driverlicencereceiptnumber?: Maybe<Scalars['String']['output']>;
  Expirydate?: Maybe<Scalars['timestamptz']['output']>;
  Fathersfirstname?: Maybe<Scalars['String']['output']>;
  Fatherslastname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Firstname?: Maybe<Scalars['String']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Issuedate?: Maybe<Scalars['timestamptz']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mothersfirstname?: Maybe<Scalars['String']['output']>;
  Motherslastname?: Maybe<Scalars['String']['output']>;
  Nin?: Maybe<Scalars['String']['output']>;
  Occupation?: Maybe<Scalars['String']['output']>;
  Placeofbirth?: Maybe<Scalars['String']['output']>;
  Proffesion?: Maybe<Scalars['String']['output']>;
  Receiptno?: Maybe<Scalars['String']['output']>;
  Region?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Min_Order_By = {
  Active?: InputMaybe<Order_By>;
  Address?: InputMaybe<Order_By>;
  Address1?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dateofbirth?: InputMaybe<Order_By>;
  Dateofbirthandplaceofbirth?: InputMaybe<Order_By>;
  Dateupdate?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd496?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd498?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd658?: InputMaybe<Order_By>;
  Dd1159?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1163?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1166?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1169?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1376?: InputMaybe<Order_By>;
  Dd1377?: InputMaybe<Order_By>;
  Dd1466?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  Driverlicencereceiptnumber?: InputMaybe<Order_By>;
  Expirydate?: InputMaybe<Order_By>;
  Fathersfirstname?: InputMaybe<Order_By>;
  Fatherslastname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Firstname?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Issuedate?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mothersfirstname?: InputMaybe<Order_By>;
  Motherslastname?: InputMaybe<Order_By>;
  Nin?: InputMaybe<Order_By>;
  Occupation?: InputMaybe<Order_By>;
  Placeofbirth?: InputMaybe<Order_By>;
  Proffesion?: InputMaybe<Order_By>;
  Receiptno?: InputMaybe<Order_By>;
  Region?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By = {
  Active?: InputMaybe<Order_By>;
  Address?: InputMaybe<Order_By>;
  Address1?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dateofbirth?: InputMaybe<Order_By>;
  Dateofbirthandplaceofbirth?: InputMaybe<Order_By>;
  Dateupdate?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd496?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd498?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd658?: InputMaybe<Order_By>;
  Dd1159?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1163?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1166?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1169?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1376?: InputMaybe<Order_By>;
  Dd1377?: InputMaybe<Order_By>;
  Dd1466?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  Driverlicencereceiptnumber?: InputMaybe<Order_By>;
  Expirydate?: InputMaybe<Order_By>;
  Fathersfirstname?: InputMaybe<Order_By>;
  Fatherslastname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Firstname?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Issuedate?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mothersfirstname?: InputMaybe<Order_By>;
  Motherslastname?: InputMaybe<Order_By>;
  Nin?: InputMaybe<Order_By>;
  Occupation?: InputMaybe<Order_By>;
  Placeofbirth?: InputMaybe<Order_By>;
  Proffesion?: InputMaybe<Order_By>;
  Receiptno?: InputMaybe<Order_By>;
  Region?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export enum Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column {
  /** column name */
  Active = 'Active',
  /** column name */
  Address = 'Address',
  /** column name */
  Address1 = 'Address1',
  /** column name */
  Cardnumbers = 'Cardnumbers',
  /** column name */
  Dateofbirth = 'Dateofbirth',
  /** column name */
  Dateofbirthandplaceofbirth = 'Dateofbirthandplaceofbirth',
  /** column name */
  Dateupdate = 'Dateupdate',
  /** column name */
  Dd151 = 'Dd151',
  /** column name */
  Dd168 = 'Dd168',
  /** column name */
  Dd381 = 'Dd381',
  /** column name */
  Dd493 = 'Dd493',
  /** column name */
  Dd495 = 'Dd495',
  /** column name */
  Dd496 = 'Dd496',
  /** column name */
  Dd497 = 'Dd497',
  /** column name */
  Dd498 = 'Dd498',
  /** column name */
  Dd547 = 'Dd547',
  /** column name */
  Dd658 = 'Dd658',
  /** column name */
  Dd1159 = 'Dd1159',
  /** column name */
  Dd1162 = 'Dd1162',
  /** column name */
  Dd1163 = 'Dd1163',
  /** column name */
  Dd1165 = 'Dd1165',
  /** column name */
  Dd1166 = 'Dd1166',
  /** column name */
  Dd1168 = 'Dd1168',
  /** column name */
  Dd1169 = 'Dd1169',
  /** column name */
  Dd1171 = 'Dd1171',
  /** column name */
  Dd1376 = 'Dd1376',
  /** column name */
  Dd1377 = 'Dd1377',
  /** column name */
  Dd1466 = 'Dd1466',
  /** column name */
  Dd1782 = 'Dd1782',
  /** column name */
  Dd1783 = 'Dd1783',
  /** column name */
  Dd1784 = 'Dd1784',
  /** column name */
  Dd1785 = 'Dd1785',
  /** column name */
  Driverlicencereceiptnumber = 'Driverlicencereceiptnumber',
  /** column name */
  Expirydate = 'Expirydate',
  /** column name */
  Fathersfirstname = 'Fathersfirstname',
  /** column name */
  Fatherslastname = 'Fatherslastname',
  /** column name */
  File = 'File',
  /** column name */
  Firstname = 'Firstname',
  /** column name */
  FkStation = 'FkStation',
  /** column name */
  FkUtilisateur = 'FkUtilisateur',
  /** column name */
  Height = 'Height',
  /** column name */
  IdEnreg = 'IdEnreg',
  /** column name */
  Issuedate = 'Issuedate',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lastname = 'Lastname',
  /** column name */
  Line = 'Line',
  /** column name */
  Mothersfirstname = 'Mothersfirstname',
  /** column name */
  Motherslastname = 'Motherslastname',
  /** column name */
  Nin = 'Nin',
  /** column name */
  Occupation = 'Occupation',
  /** column name */
  Placeofbirth = 'Placeofbirth',
  /** column name */
  Proffesion = 'Proffesion',
  /** column name */
  Receiptno = 'Receiptno',
  /** column name */
  Region = 'Region',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Set_Input = {
  Active?: InputMaybe<Scalars['bigint']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Address1?: InputMaybe<Scalars['String']['input']>;
  Cardnumbers?: InputMaybe<Scalars['float8']['input']>;
  Dateofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateofbirthandplaceofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateupdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd151?: InputMaybe<Scalars['bigint']['input']>;
  Dd168?: InputMaybe<Scalars['bigint']['input']>;
  Dd381?: InputMaybe<Scalars['bigint']['input']>;
  Dd493?: InputMaybe<Scalars['bigint']['input']>;
  Dd495?: InputMaybe<Scalars['bigint']['input']>;
  Dd496?: InputMaybe<Scalars['String']['input']>;
  Dd497?: InputMaybe<Scalars['bigint']['input']>;
  Dd498?: InputMaybe<Scalars['String']['input']>;
  Dd547?: InputMaybe<Scalars['bigint']['input']>;
  Dd658?: InputMaybe<Scalars['String']['input']>;
  Dd1159?: InputMaybe<Scalars['String']['input']>;
  Dd1162?: InputMaybe<Scalars['bigint']['input']>;
  Dd1163?: InputMaybe<Scalars['String']['input']>;
  Dd1165?: InputMaybe<Scalars['bigint']['input']>;
  Dd1166?: InputMaybe<Scalars['String']['input']>;
  Dd1168?: InputMaybe<Scalars['bigint']['input']>;
  Dd1169?: InputMaybe<Scalars['String']['input']>;
  Dd1171?: InputMaybe<Scalars['bigint']['input']>;
  Dd1376?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd1377?: InputMaybe<Scalars['String']['input']>;
  Dd1466?: InputMaybe<Scalars['String']['input']>;
  Dd1782?: InputMaybe<Scalars['bigint']['input']>;
  Dd1783?: InputMaybe<Scalars['bigint']['input']>;
  Dd1784?: InputMaybe<Scalars['bigint']['input']>;
  Dd1785?: InputMaybe<Scalars['bigint']['input']>;
  Driverlicencereceiptnumber?: InputMaybe<Scalars['String']['input']>;
  Expirydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Fathersfirstname?: InputMaybe<Scalars['String']['input']>;
  Fatherslastname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Firstname?: InputMaybe<Scalars['String']['input']>;
  FkStation?: InputMaybe<Scalars['float8']['input']>;
  FkUtilisateur?: InputMaybe<Scalars['float8']['input']>;
  Height?: InputMaybe<Scalars['bigint']['input']>;
  IdEnreg?: InputMaybe<Scalars['bigint']['input']>;
  Issuedate?: InputMaybe<Scalars['timestamptz']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mothersfirstname?: InputMaybe<Scalars['String']['input']>;
  Motherslastname?: InputMaybe<Scalars['String']['input']>;
  Nin?: InputMaybe<Scalars['String']['input']>;
  Occupation?: InputMaybe<Scalars['String']['input']>;
  Placeofbirth?: InputMaybe<Scalars['String']['input']>;
  Proffesion?: InputMaybe<Scalars['String']['input']>;
  Receiptno?: InputMaybe<Scalars['String']['input']>;
  Region?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_stddev_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_stddev_pop_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_stddev_samp_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_sum_fields';
  Active?: Maybe<Scalars['bigint']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_var_pop_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_var_samp_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_variance_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax';
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CommericalResidentialTax". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  Fname?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lname?: InputMaybe<String_Comparison_Exp>;
  Paidamount?: InputMaybe<Float8_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxmonth?: InputMaybe<Bigint_Comparison_Exp>;
  Taxname?: InputMaybe<String_Comparison_Exp>;
  Taxyear?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By = {
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export enum Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  Fname = 'Fname',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lname = 'Lname',
  /** column name */
  Paidamount = 'Paidamount',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxmonth = 'Taxmonth',
  /** column name */
  Taxname = 'Taxname',
  /** column name */
  Taxyear = 'Taxyear',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directorname?: Maybe<Scalars['String']['output']>;
  Directorremunerationid?: Maybe<Scalars['String']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_avg_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Avg_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnDirector". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp = {
  Directorfee?: InputMaybe<Float8_Comparison_Exp>;
  Directorname?: InputMaybe<String_Comparison_Exp>;
  Directorremunerationid?: InputMaybe<String_Comparison_Exp>;
  Directortin?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Otherallowance?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Salary?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Total?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Inc_Input = {
  Directorfee?: InputMaybe<Scalars['float8']['input']>;
  Directortin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Otherallowance?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Total?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Insert_Input = {
  Directorfee?: InputMaybe<Scalars['float8']['input']>;
  Directorname?: InputMaybe<Scalars['String']['input']>;
  Directorremunerationid?: InputMaybe<Scalars['String']['input']>;
  Directortin?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Otherallowance?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Total?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_max_fields';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directorname?: Maybe<Scalars['String']['output']>;
  Directorremunerationid?: Maybe<Scalars['String']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Max_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directorname?: InputMaybe<Order_By>;
  Directorremunerationid?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_min_fields';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directorname?: Maybe<Scalars['String']['output']>;
  Directorremunerationid?: Maybe<Scalars['String']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Min_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directorname?: InputMaybe<Order_By>;
  Directorremunerationid?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directorname?: InputMaybe<Order_By>;
  Directorremunerationid?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column {
  /** column name */
  Directorfee = 'Directorfee',
  /** column name */
  Directorname = 'Directorname',
  /** column name */
  Directorremunerationid = 'Directorremunerationid',
  /** column name */
  Directortin = 'Directortin',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Otherallowance = 'Otherallowance',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Salary = 'Salary',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Total = 'Total',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Set_Input = {
  Directorfee?: InputMaybe<Scalars['float8']['input']>;
  Directorname?: InputMaybe<Scalars['String']['input']>;
  Directorremunerationid?: InputMaybe<Scalars['String']['input']>;
  Directortin?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Otherallowance?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Total?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_stddev_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_stddev_pop_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Pop_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_stddev_samp_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Stddev_Samp_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_sum_fields';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Sum_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_var_pop_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Pop_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_var_samp_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Var_Samp_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector_variance_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Variance_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_avg_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Avg_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnExemption". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp = {
  Amount?: InputMaybe<Float8_Comparison_Exp>;
  Basis?: InputMaybe<Float8_Comparison_Exp>;
  Exemptionid?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Incometype?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Taxliability?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxrate?: InputMaybe<Float8_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Inc_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['float8']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Taxrate?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Insert_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['float8']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Taxrate?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_max_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Max_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_min_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Min_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  Basis = 'Basis',
  /** column name */
  Exemptionid = 'Exemptionid',
  /** column name */
  File = 'File',
  /** column name */
  Incometype = 'Incometype',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxliability = 'Taxliability',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Taxrate = 'Taxrate',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Set_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['float8']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Taxrate?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_stddev_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_stddev_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_stddev_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Stddev_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_sum_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Sum_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_var_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_var_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Var_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption_variance_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Variance_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnLoss". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lossid?: InputMaybe<String_Comparison_Exp>;
  Lossmade?: InputMaybe<Float8_Comparison_Exp>;
  Lossoutstanding?: InputMaybe<Float8_Comparison_Exp>;
  Losssetoff?: InputMaybe<Float8_Comparison_Exp>;
  Lossyear?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Yearleft?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['float8']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['float8']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lossid = 'Lossid',
  /** column name */
  Lossmade = 'Lossmade',
  /** column name */
  Lossoutstanding = 'Lossoutstanding',
  /** column name */
  Losssetoff = 'Losssetoff',
  /** column name */
  Lossyear = 'Lossyear',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Yearleft = 'Yearleft'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['float8']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership';
  File?: Maybe<Scalars['String']['output']>;
  Interest?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Partnername?: Maybe<Scalars['String']['output']>;
  Partnershipid?: Maybe<Scalars['String']['output']>;
  Partnerstatus?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_avg_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Avg_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  Interest?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Partnername?: InputMaybe<String_Comparison_Exp>;
  Partnershipid?: InputMaybe<String_Comparison_Exp>;
  Partnerstatus?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Salary?: InputMaybe<Bigint_Comparison_Exp>;
  Sharebalance?: InputMaybe<Float8_Comparison_Exp>;
  Shareratio?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Inc_Input = {
  Interest?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['bigint']['input']>;
  Sharebalance?: InputMaybe<Scalars['float8']['input']>;
  Shareratio?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Interest?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Partnername?: InputMaybe<Scalars['String']['input']>;
  Partnershipid?: InputMaybe<Scalars['String']['input']>;
  Partnerstatus?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['bigint']['input']>;
  Sharebalance?: InputMaybe<Scalars['float8']['input']>;
  Shareratio?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  Interest?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Partnername?: Maybe<Scalars['String']['output']>;
  Partnershipid?: Maybe<Scalars['String']['output']>;
  Partnerstatus?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  Interest?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Partnername?: InputMaybe<Order_By>;
  Partnershipid?: InputMaybe<Order_By>;
  Partnerstatus?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  Interest?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Partnername?: Maybe<Scalars['String']['output']>;
  Partnershipid?: Maybe<Scalars['String']['output']>;
  Partnerstatus?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  Interest?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Partnername?: InputMaybe<Order_By>;
  Partnershipid?: InputMaybe<Order_By>;
  Partnerstatus?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By = {
  File?: InputMaybe<Order_By>;
  Interest?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Partnername?: InputMaybe<Order_By>;
  Partnershipid?: InputMaybe<Order_By>;
  Partnerstatus?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  Interest = 'Interest',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Partnername = 'Partnername',
  /** column name */
  Partnershipid = 'Partnershipid',
  /** column name */
  Partnerstatus = 'Partnerstatus',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Salary = 'Salary',
  /** column name */
  Sharebalance = 'Sharebalance',
  /** column name */
  Shareratio = 'Shareratio',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Interest?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Partnername?: InputMaybe<Scalars['String']['input']>;
  Partnershipid?: InputMaybe<Scalars['String']['input']>;
  Partnerstatus?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['bigint']['input']>;
  Sharebalance?: InputMaybe<Scalars['float8']['input']>;
  Shareratio?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_stddev_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_stddev_pop_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Pop_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_stddev_samp_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Stddev_Samp_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_sum_fields';
  Interest?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Sum_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_var_pop_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Pop_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_var_samp_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Var_Samp_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership_variance_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Variance_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp = {
  Annualrent?: InputMaybe<Float8_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Float8_Comparison_Exp>;
  Commercialused?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  Tenancystartdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_max_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_min_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  Tenancystartdate = 'Tenancystartdate',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Set_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_sum_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp = {
  Annualrent?: InputMaybe<Bigint_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Bigint_Comparison_Exp>;
  Commercialused?: InputMaybe<Bigint_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['bigint']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['bigint']['input']>;
  Landlordtin?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Residentialused?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['bigint']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_max_fields';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_min_fields';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Set_Input = {
  Annualrent?: InputMaybe<Scalars['bigint']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_sum_fields';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp = {
  Annualrent?: InputMaybe<Float8_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Float8_Comparison_Exp>;
  Commercialused?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  Tenancystartdate?: InputMaybe<String_Comparison_Exp>;
  Tin?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['float8']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Residentialused?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_max_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_min_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  Tenancystartdate = 'Tenancystartdate',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Set_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_sum_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_avg_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Avg_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_CorporateReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp = {
  Accural?: InputMaybe<Float8_Comparison_Exp>;
  Additionalallowance?: InputMaybe<Bigint_Comparison_Exp>;
  Allowancebroughtforward?: InputMaybe<Float8_Comparison_Exp>;
  Allowanceutilised?: InputMaybe<Float8_Comparison_Exp>;
  Businessincome?: InputMaybe<Float8_Comparison_Exp>;
  Capitalallowance?: InputMaybe<Float8_Comparison_Exp>;
  Cashbank?: InputMaybe<Float8_Comparison_Exp>;
  Closingstock?: InputMaybe<Float8_Comparison_Exp>;
  Costofsale?: InputMaybe<Float8_Comparison_Exp>;
  Creditor?: InputMaybe<Float8_Comparison_Exp>;
  Debtor?: InputMaybe<Float8_Comparison_Exp>;
  Depreciation?: InputMaybe<Float8_Comparison_Exp>;
  Exemptincome?: InputMaybe<Float8_Comparison_Exp>;
  Exporter?: InputMaybe<Bigint_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Fixedasset?: InputMaybe<Float8_Comparison_Exp>;
  Foreignexchangeloss?: InputMaybe<Float8_Comparison_Exp>;
  Foreigntaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  Fringebenefittax?: InputMaybe<Float8_Comparison_Exp>;
  Grossprofit?: InputMaybe<Float8_Comparison_Exp>;
  Importer?: InputMaybe<Bigint_Comparison_Exp>;
  Instalmentpayment?: InputMaybe<Float8_Comparison_Exp>;
  Interestexpense?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longtermliability?: InputMaybe<Float8_Comparison_Exp>;
  Lossbroughtforward?: InputMaybe<Float8_Comparison_Exp>;
  Openingstock?: InputMaybe<Float8_Comparison_Exp>;
  Operatingexpense?: InputMaybe<Float8_Comparison_Exp>;
  Otherasset?: InputMaybe<Float8_Comparison_Exp>;
  Othercurrentasset?: InputMaybe<Float8_Comparison_Exp>;
  Othercurrentliability?: InputMaybe<Float8_Comparison_Exp>;
  Otherdeduction?: InputMaybe<Float8_Comparison_Exp>;
  Otherexpense?: InputMaybe<Float8_Comparison_Exp>;
  Otherincome?: InputMaybe<Float8_Comparison_Exp>;
  Otherliability?: InputMaybe<Float8_Comparison_Exp>;
  Overpayment?: InputMaybe<Float8_Comparison_Exp>;
  Propertyincome?: InputMaybe<Float8_Comparison_Exp>;
  Purchase?: InputMaybe<Float8_Comparison_Exp>;
  Returngroup?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Salarywage?: InputMaybe<Float8_Comparison_Exp>;
  Sharehholderfund?: InputMaybe<Float8_Comparison_Exp>;
  Sicexemption?: InputMaybe<Float8_Comparison_Exp>;
  Stock?: InputMaybe<Float8_Comparison_Exp>;
  Tax1stschedule?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Totalasset?: InputMaybe<Float8_Comparison_Exp>;
  Totalliability?: InputMaybe<Float8_Comparison_Exp>;
  Turnovertax?: InputMaybe<Float8_Comparison_Exp>;
  Unallowablededuction?: InputMaybe<Float8_Comparison_Exp>;
  Unutilisedallowance?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Withholdingtaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  Yearallowance?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Inc_Input = {
  Accural?: InputMaybe<Scalars['float8']['input']>;
  Additionalallowance?: InputMaybe<Scalars['bigint']['input']>;
  Allowancebroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Allowanceutilised?: InputMaybe<Scalars['float8']['input']>;
  Businessincome?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Cashbank?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Creditor?: InputMaybe<Scalars['float8']['input']>;
  Debtor?: InputMaybe<Scalars['float8']['input']>;
  Depreciation?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['bigint']['input']>;
  Fixedasset?: InputMaybe<Scalars['float8']['input']>;
  Foreignexchangeloss?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossprofit?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['bigint']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Interestexpense?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longtermliability?: InputMaybe<Scalars['float8']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Operatingexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentliability?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Otherliability?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Propertyincome?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['bigint']['input']>;
  Salarywage?: InputMaybe<Scalars['float8']['input']>;
  Sharehholderfund?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Stock?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totalasset?: InputMaybe<Scalars['float8']['input']>;
  Totalliability?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowablededuction?: InputMaybe<Scalars['float8']['input']>;
  Unutilisedallowance?: InputMaybe<Scalars['float8']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Yearallowance?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Insert_Input = {
  Accural?: InputMaybe<Scalars['float8']['input']>;
  Additionalallowance?: InputMaybe<Scalars['bigint']['input']>;
  Allowancebroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Allowanceutilised?: InputMaybe<Scalars['float8']['input']>;
  Businessincome?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Cashbank?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Creditor?: InputMaybe<Scalars['float8']['input']>;
  Debtor?: InputMaybe<Scalars['float8']['input']>;
  Depreciation?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fixedasset?: InputMaybe<Scalars['float8']['input']>;
  Foreignexchangeloss?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossprofit?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['bigint']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Interestexpense?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longtermliability?: InputMaybe<Scalars['float8']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Operatingexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentliability?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Otherliability?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Propertyincome?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Salarywage?: InputMaybe<Scalars['float8']['input']>;
  Sharehholderfund?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Stock?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totalasset?: InputMaybe<Scalars['float8']['input']>;
  Totalliability?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowablededuction?: InputMaybe<Scalars['float8']['input']>;
  Unutilisedallowance?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Yearallowance?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_max_fields';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Max_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_min_fields';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Min_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export enum Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column {
  /** column name */
  Accural = 'Accural',
  /** column name */
  Additionalallowance = 'Additionalallowance',
  /** column name */
  Allowancebroughtforward = 'Allowancebroughtforward',
  /** column name */
  Allowanceutilised = 'Allowanceutilised',
  /** column name */
  Businessincome = 'Businessincome',
  /** column name */
  Capitalallowance = 'Capitalallowance',
  /** column name */
  Cashbank = 'Cashbank',
  /** column name */
  Closingstock = 'Closingstock',
  /** column name */
  Costofsale = 'Costofsale',
  /** column name */
  Creditor = 'Creditor',
  /** column name */
  Debtor = 'Debtor',
  /** column name */
  Depreciation = 'Depreciation',
  /** column name */
  Exemptincome = 'Exemptincome',
  /** column name */
  Exporter = 'Exporter',
  /** column name */
  File = 'File',
  /** column name */
  Fixedasset = 'Fixedasset',
  /** column name */
  Foreignexchangeloss = 'Foreignexchangeloss',
  /** column name */
  Foreigntaxcredit = 'Foreigntaxcredit',
  /** column name */
  Fringebenefittax = 'Fringebenefittax',
  /** column name */
  Grossprofit = 'Grossprofit',
  /** column name */
  Importer = 'Importer',
  /** column name */
  Instalmentpayment = 'Instalmentpayment',
  /** column name */
  Interestexpense = 'Interestexpense',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Longtermliability = 'Longtermliability',
  /** column name */
  Lossbroughtforward = 'Lossbroughtforward',
  /** column name */
  Openingstock = 'Openingstock',
  /** column name */
  Operatingexpense = 'Operatingexpense',
  /** column name */
  Otherasset = 'Otherasset',
  /** column name */
  Othercurrentasset = 'Othercurrentasset',
  /** column name */
  Othercurrentliability = 'Othercurrentliability',
  /** column name */
  Otherdeduction = 'Otherdeduction',
  /** column name */
  Otherexpense = 'Otherexpense',
  /** column name */
  Otherincome = 'Otherincome',
  /** column name */
  Otherliability = 'Otherliability',
  /** column name */
  Overpayment = 'Overpayment',
  /** column name */
  Propertyincome = 'Propertyincome',
  /** column name */
  Purchase = 'Purchase',
  /** column name */
  Returngroup = 'Returngroup',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Salarywage = 'Salarywage',
  /** column name */
  Sharehholderfund = 'Sharehholderfund',
  /** column name */
  Sicexemption = 'Sicexemption',
  /** column name */
  Stock = 'Stock',
  /** column name */
  Tax1stschedule = 'Tax1stschedule',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Totalasset = 'Totalasset',
  /** column name */
  Totalliability = 'Totalliability',
  /** column name */
  Turnovertax = 'Turnovertax',
  /** column name */
  Unallowablededuction = 'Unallowablededuction',
  /** column name */
  Unutilisedallowance = 'Unutilisedallowance',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Withholdingtaxcredit = 'Withholdingtaxcredit',
  /** column name */
  Yearallowance = 'Yearallowance'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Set_Input = {
  Accural?: InputMaybe<Scalars['float8']['input']>;
  Additionalallowance?: InputMaybe<Scalars['bigint']['input']>;
  Allowancebroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Allowanceutilised?: InputMaybe<Scalars['float8']['input']>;
  Businessincome?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Cashbank?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Creditor?: InputMaybe<Scalars['float8']['input']>;
  Debtor?: InputMaybe<Scalars['float8']['input']>;
  Depreciation?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fixedasset?: InputMaybe<Scalars['float8']['input']>;
  Foreignexchangeloss?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossprofit?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['bigint']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Interestexpense?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longtermliability?: InputMaybe<Scalars['float8']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Operatingexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentliability?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Otherliability?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Propertyincome?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Salarywage?: InputMaybe<Scalars['float8']['input']>;
  Sharehholderfund?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Stock?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totalasset?: InputMaybe<Scalars['float8']['input']>;
  Totalliability?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowablededuction?: InputMaybe<Scalars['float8']['input']>;
  Unutilisedallowance?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Yearallowance?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_stddev_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_stddev_pop_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Pop_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_stddev_samp_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Stddev_Samp_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_sum_fields';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Sum_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_var_pop_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Pop_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_var_samp_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Var_Samp_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn_variance_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Variance_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn';
  Accountingmethod?: Maybe<Scalars['String']['output']>;
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Businessactivity?: Maybe<Scalars['String']['output']>;
  Businessaddress?: Maybe<Scalars['String']['output']>;
  Businessnature?: Maybe<Scalars['String']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employeraddress?: Maybe<Scalars['String']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Employertin1?: Maybe<Scalars['String']['output']>;
  Employertin2?: Maybe<Scalars['String']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Positionheld?: Maybe<Scalars['String']['output']>;
  Principalbusiness?: Maybe<Scalars['String']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['String']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_avg_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Avg_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_IndividualReturnExemption". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp = {
  Amount?: InputMaybe<Float8_Comparison_Exp>;
  Basis?: InputMaybe<String_Comparison_Exp>;
  Exemptionid?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Incometype?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxliability?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Taxrate?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Inc_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Taxrate?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Insert_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['String']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Taxrate?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_max_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['String']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Max_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_min_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['String']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Min_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export enum Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  Basis = 'Basis',
  /** column name */
  Exemptionid = 'Exemptionid',
  /** column name */
  File = 'File',
  /** column name */
  Incometype = 'Incometype',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxliability = 'Taxliability',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Taxrate = 'Taxrate',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Set_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['String']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Taxrate?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_stddev_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_stddev_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_stddev_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Stddev_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_sum_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Sum_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_var_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_var_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Var_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption_variance_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Variance_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lendernameaddress?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Loanid?: Maybe<Scalars['String']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanused?: Maybe<Scalars['String']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_IndividualReturnLoan". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lendernameaddress?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Loanid?: InputMaybe<String_Comparison_Exp>;
  Loaninterest?: InputMaybe<Float8_Comparison_Exp>;
  Loanused?: InputMaybe<String_Comparison_Exp>;
  Loanvalue?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Loaninterest?: InputMaybe<Scalars['float8']['input']>;
  Loanvalue?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lendernameaddress?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Loanid?: InputMaybe<Scalars['String']['input']>;
  Loaninterest?: InputMaybe<Scalars['float8']['input']>;
  Loanused?: InputMaybe<Scalars['String']['input']>;
  Loanvalue?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lendernameaddress?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Loanid?: Maybe<Scalars['String']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanused?: Maybe<Scalars['String']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lendernameaddress?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Loanid?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanused?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lendernameaddress?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Loanid?: Maybe<Scalars['String']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanused?: Maybe<Scalars['String']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lendernameaddress?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Loanid?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanused?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lendernameaddress?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Loanid?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanused?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export enum Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lendernameaddress = 'Lendernameaddress',
  /** column name */
  Line = 'Line',
  /** column name */
  Loanid = 'Loanid',
  /** column name */
  Loaninterest = 'Loaninterest',
  /** column name */
  Loanused = 'Loanused',
  /** column name */
  Loanvalue = 'Loanvalue',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lendernameaddress?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Loanid?: InputMaybe<Scalars['String']['input']>;
  Loaninterest?: InputMaybe<Scalars['float8']['input']>;
  Loanused?: InputMaybe<Scalars['String']['input']>;
  Loanvalue?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoan_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_IndividualReturnLoss". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lossid?: InputMaybe<String_Comparison_Exp>;
  Lossmade?: InputMaybe<Float8_Comparison_Exp>;
  Lossoutstanding?: InputMaybe<Float8_Comparison_Exp>;
  Losssetoff?: InputMaybe<Bigint_Comparison_Exp>;
  Lossyear?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Yearleft?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['bigint']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['bigint']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export enum Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lossid = 'Lossid',
  /** column name */
  Lossmade = 'Lossmade',
  /** column name */
  Lossoutstanding = 'Lossoutstanding',
  /** column name */
  Losssetoff = 'Losssetoff',
  /** column name */
  Lossyear = 'Lossyear',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Yearleft = 'Yearleft'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['bigint']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnLoss_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Propertyused?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp = {
  Annualrent?: InputMaybe<Float8_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Float8_Comparison_Exp>;
  Commercialused?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  Tenancystartdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_max_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_min_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export enum Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  Tenancystartdate = 'Tenancystartdate',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Set_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_sum_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_avg_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Avg_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_IndividualReturnRent". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp = {
  Alloweddeduction?: InputMaybe<Bigint_Comparison_Exp>;
  Commercialused?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Grossrent?: InputMaybe<Bigint_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Netincome?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Propertycost?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyused?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Inc_Input = {
  Alloweddeduction?: InputMaybe<Scalars['bigint']['input']>;
  Grossrent?: InputMaybe<Scalars['bigint']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['bigint']['input']>;
  Propertycost?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Insert_Input = {
  Alloweddeduction?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grossrent?: InputMaybe<Scalars['bigint']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Propertycost?: InputMaybe<Scalars['bigint']['input']>;
  Propertyused?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_max_fields';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Propertyused?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Max_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Propertyused?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_min_fields';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Propertyused?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Min_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Propertyused?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Propertyused?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export enum Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column {
  /** column name */
  Alloweddeduction = 'Alloweddeduction',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Grossrent = 'Grossrent',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Netincome = 'Netincome',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Propertycost = 'Propertycost',
  /** column name */
  Propertyused = 'Propertyused',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Set_Input = {
  Alloweddeduction?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grossrent?: InputMaybe<Scalars['bigint']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Propertycost?: InputMaybe<Scalars['bigint']['input']>;
  Propertyused?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_stddev_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_stddev_pop_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Pop_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_stddev_samp_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Stddev_Samp_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_sum_fields';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Sum_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_var_pop_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Pop_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_var_samp_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Var_Samp_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnRent_variance_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Variance_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_avg_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Avg_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_IndividualReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp = {
  Accountingmethod?: InputMaybe<String_Comparison_Exp>;
  Allowance?: InputMaybe<Float8_Comparison_Exp>;
  Bonus?: InputMaybe<Float8_Comparison_Exp>;
  Businessactivity?: InputMaybe<String_Comparison_Exp>;
  Businessaddress?: InputMaybe<String_Comparison_Exp>;
  Businessnature?: InputMaybe<String_Comparison_Exp>;
  Capitalallowance?: InputMaybe<Float8_Comparison_Exp>;
  Closingstock?: InputMaybe<Float8_Comparison_Exp>;
  Commercialrent?: InputMaybe<Float8_Comparison_Exp>;
  Commission?: InputMaybe<Float8_Comparison_Exp>;
  Costofsale?: InputMaybe<Float8_Comparison_Exp>;
  Directtaxpaid?: InputMaybe<Float8_Comparison_Exp>;
  Employeraddress?: InputMaybe<String_Comparison_Exp>;
  Employertin?: InputMaybe<Float8_Comparison_Exp>;
  Employertin1?: InputMaybe<String_Comparison_Exp>;
  Employertin2?: InputMaybe<String_Comparison_Exp>;
  Exemptincome?: InputMaybe<Float8_Comparison_Exp>;
  Exporter?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Foreigntaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  Fringebenefit?: InputMaybe<Float8_Comparison_Exp>;
  Fringebenefittax?: InputMaybe<Float8_Comparison_Exp>;
  Grossemolument?: InputMaybe<Float8_Comparison_Exp>;
  Importer?: InputMaybe<Float8_Comparison_Exp>;
  Instalmentpayment?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lossbroughtforward?: InputMaybe<Float8_Comparison_Exp>;
  Netbusinessincome?: InputMaybe<Float8_Comparison_Exp>;
  Openingstock?: InputMaybe<Float8_Comparison_Exp>;
  Other?: InputMaybe<Float8_Comparison_Exp>;
  Otherdeduction?: InputMaybe<Float8_Comparison_Exp>;
  Otherexepense?: InputMaybe<Float8_Comparison_Exp>;
  Otherincome?: InputMaybe<Float8_Comparison_Exp>;
  Overpayment?: InputMaybe<Float8_Comparison_Exp>;
  Overtime?: InputMaybe<Float8_Comparison_Exp>;
  Pension?: InputMaybe<Float8_Comparison_Exp>;
  Positionheld?: InputMaybe<String_Comparison_Exp>;
  Principalbusiness?: InputMaybe<String_Comparison_Exp>;
  Purchase?: InputMaybe<Float8_Comparison_Exp>;
  Returngroup?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Salarywages?: InputMaybe<Float8_Comparison_Exp>;
  Sicexemption?: InputMaybe<Float8_Comparison_Exp>;
  Subtotal?: InputMaybe<Float8_Comparison_Exp>;
  Subtotal1?: InputMaybe<Float8_Comparison_Exp>;
  Tax1stschedule?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Totalnetincome?: InputMaybe<Float8_Comparison_Exp>;
  Turnover?: InputMaybe<Float8_Comparison_Exp>;
  Turnovertax?: InputMaybe<Float8_Comparison_Exp>;
  Unallowwablededuction?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Withholdingtaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Inc_Input = {
  Allowance?: InputMaybe<Scalars['float8']['input']>;
  Bonus?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Commercialrent?: InputMaybe<Scalars['float8']['input']>;
  Commission?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Directtaxpaid?: InputMaybe<Scalars['float8']['input']>;
  Employertin?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossemolument?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['float8']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Netbusinessincome?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Other?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexepense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Overtime?: InputMaybe<Scalars['float8']['input']>;
  Pension?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['float8']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Salarywages?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Subtotal?: InputMaybe<Scalars['float8']['input']>;
  Subtotal1?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalnetincome?: InputMaybe<Scalars['float8']['input']>;
  Turnover?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowwablededuction?: InputMaybe<Scalars['float8']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Insert_Input = {
  Accountingmethod?: InputMaybe<Scalars['String']['input']>;
  Allowance?: InputMaybe<Scalars['float8']['input']>;
  Bonus?: InputMaybe<Scalars['float8']['input']>;
  Businessactivity?: InputMaybe<Scalars['String']['input']>;
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  Businessnature?: InputMaybe<Scalars['String']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Commercialrent?: InputMaybe<Scalars['float8']['input']>;
  Commission?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Directtaxpaid?: InputMaybe<Scalars['float8']['input']>;
  Employeraddress?: InputMaybe<Scalars['String']['input']>;
  Employertin?: InputMaybe<Scalars['float8']['input']>;
  Employertin1?: InputMaybe<Scalars['String']['input']>;
  Employertin2?: InputMaybe<Scalars['String']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossemolument?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['float8']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Netbusinessincome?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Other?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexepense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Overtime?: InputMaybe<Scalars['float8']['input']>;
  Pension?: InputMaybe<Scalars['float8']['input']>;
  Positionheld?: InputMaybe<Scalars['String']['input']>;
  Principalbusiness?: InputMaybe<Scalars['String']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Salarywages?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Subtotal?: InputMaybe<Scalars['float8']['input']>;
  Subtotal1?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalnetincome?: InputMaybe<Scalars['float8']['input']>;
  Turnover?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowwablededuction?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_max_fields';
  Accountingmethod?: Maybe<Scalars['String']['output']>;
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Businessactivity?: Maybe<Scalars['String']['output']>;
  Businessaddress?: Maybe<Scalars['String']['output']>;
  Businessnature?: Maybe<Scalars['String']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employeraddress?: Maybe<Scalars['String']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Employertin1?: Maybe<Scalars['String']['output']>;
  Employertin2?: Maybe<Scalars['String']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Positionheld?: Maybe<Scalars['String']['output']>;
  Principalbusiness?: Maybe<Scalars['String']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Max_Order_By = {
  Accountingmethod?: InputMaybe<Order_By>;
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Businessactivity?: InputMaybe<Order_By>;
  Businessaddress?: InputMaybe<Order_By>;
  Businessnature?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employeraddress?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Employertin1?: InputMaybe<Order_By>;
  Employertin2?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Positionheld?: InputMaybe<Order_By>;
  Principalbusiness?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_min_fields';
  Accountingmethod?: Maybe<Scalars['String']['output']>;
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Businessactivity?: Maybe<Scalars['String']['output']>;
  Businessaddress?: Maybe<Scalars['String']['output']>;
  Businessnature?: Maybe<Scalars['String']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employeraddress?: Maybe<Scalars['String']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Employertin1?: Maybe<Scalars['String']['output']>;
  Employertin2?: Maybe<Scalars['String']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Positionheld?: Maybe<Scalars['String']['output']>;
  Principalbusiness?: Maybe<Scalars['String']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Min_Order_By = {
  Accountingmethod?: InputMaybe<Order_By>;
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Businessactivity?: InputMaybe<Order_By>;
  Businessaddress?: InputMaybe<Order_By>;
  Businessnature?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employeraddress?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Employertin1?: InputMaybe<Order_By>;
  Employertin2?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Positionheld?: InputMaybe<Order_By>;
  Principalbusiness?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By = {
  Accountingmethod?: InputMaybe<Order_By>;
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Businessactivity?: InputMaybe<Order_By>;
  Businessaddress?: InputMaybe<Order_By>;
  Businessnature?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employeraddress?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Employertin1?: InputMaybe<Order_By>;
  Employertin2?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Positionheld?: InputMaybe<Order_By>;
  Principalbusiness?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export enum Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column {
  /** column name */
  Accountingmethod = 'Accountingmethod',
  /** column name */
  Allowance = 'Allowance',
  /** column name */
  Bonus = 'Bonus',
  /** column name */
  Businessactivity = 'Businessactivity',
  /** column name */
  Businessaddress = 'Businessaddress',
  /** column name */
  Businessnature = 'Businessnature',
  /** column name */
  Capitalallowance = 'Capitalallowance',
  /** column name */
  Closingstock = 'Closingstock',
  /** column name */
  Commercialrent = 'Commercialrent',
  /** column name */
  Commission = 'Commission',
  /** column name */
  Costofsale = 'Costofsale',
  /** column name */
  Directtaxpaid = 'Directtaxpaid',
  /** column name */
  Employeraddress = 'Employeraddress',
  /** column name */
  Employertin = 'Employertin',
  /** column name */
  Employertin1 = 'Employertin1',
  /** column name */
  Employertin2 = 'Employertin2',
  /** column name */
  Exemptincome = 'Exemptincome',
  /** column name */
  Exporter = 'Exporter',
  /** column name */
  File = 'File',
  /** column name */
  Foreigntaxcredit = 'Foreigntaxcredit',
  /** column name */
  Fringebenefit = 'Fringebenefit',
  /** column name */
  Fringebenefittax = 'Fringebenefittax',
  /** column name */
  Grossemolument = 'Grossemolument',
  /** column name */
  Importer = 'Importer',
  /** column name */
  Instalmentpayment = 'Instalmentpayment',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lossbroughtforward = 'Lossbroughtforward',
  /** column name */
  Netbusinessincome = 'Netbusinessincome',
  /** column name */
  Openingstock = 'Openingstock',
  /** column name */
  Other = 'Other',
  /** column name */
  Otherdeduction = 'Otherdeduction',
  /** column name */
  Otherexepense = 'Otherexepense',
  /** column name */
  Otherincome = 'Otherincome',
  /** column name */
  Overpayment = 'Overpayment',
  /** column name */
  Overtime = 'Overtime',
  /** column name */
  Pension = 'Pension',
  /** column name */
  Positionheld = 'Positionheld',
  /** column name */
  Principalbusiness = 'Principalbusiness',
  /** column name */
  Purchase = 'Purchase',
  /** column name */
  Returngroup = 'Returngroup',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Salarywages = 'Salarywages',
  /** column name */
  Sicexemption = 'Sicexemption',
  /** column name */
  Subtotal = 'Subtotal',
  /** column name */
  Subtotal1 = 'Subtotal1',
  /** column name */
  Tax1stschedule = 'Tax1stschedule',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Totalnetincome = 'Totalnetincome',
  /** column name */
  Turnover = 'Turnover',
  /** column name */
  Turnovertax = 'Turnovertax',
  /** column name */
  Unallowwablededuction = 'Unallowwablededuction',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Withholdingtaxcredit = 'Withholdingtaxcredit'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Set_Input = {
  Accountingmethod?: InputMaybe<Scalars['String']['input']>;
  Allowance?: InputMaybe<Scalars['float8']['input']>;
  Bonus?: InputMaybe<Scalars['float8']['input']>;
  Businessactivity?: InputMaybe<Scalars['String']['input']>;
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  Businessnature?: InputMaybe<Scalars['String']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Commercialrent?: InputMaybe<Scalars['float8']['input']>;
  Commission?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Directtaxpaid?: InputMaybe<Scalars['float8']['input']>;
  Employeraddress?: InputMaybe<Scalars['String']['input']>;
  Employertin?: InputMaybe<Scalars['float8']['input']>;
  Employertin1?: InputMaybe<Scalars['String']['input']>;
  Employertin2?: InputMaybe<Scalars['String']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossemolument?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['float8']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Netbusinessincome?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Other?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexepense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Overtime?: InputMaybe<Scalars['float8']['input']>;
  Pension?: InputMaybe<Scalars['float8']['input']>;
  Positionheld?: InputMaybe<Scalars['String']['input']>;
  Principalbusiness?: InputMaybe<Scalars['String']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Salarywages?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Subtotal?: InputMaybe<Scalars['float8']['input']>;
  Subtotal1?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalnetincome?: InputMaybe<Scalars['float8']['input']>;
  Turnover?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowwablededuction?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_stddev_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_stddev_pop_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Pop_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_stddev_samp_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Stddev_Samp_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_sum_fields';
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Sum_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_var_pop_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Pop_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_var_samp_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Var_Samp_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn_variance_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Variance_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental';
  Birthdate?: Maybe<Scalars['timestamptz']['output']>;
  Centername?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Empemail?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Paymentdate?: Maybe<Scalars['timestamptz']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Phoneno2?: Maybe<Scalars['String']['output']>;
  Physarea?: Maybe<Scalars['String']['output']>;
  Regtypecode?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_PaymentRental". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp = {
  Birthdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Centername?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  Empemail?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Fname?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lname?: InputMaybe<String_Comparison_Exp>;
  Paidamount?: InputMaybe<Float8_Comparison_Exp>;
  Paymentdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Phoneno?: InputMaybe<String_Comparison_Exp>;
  Phoneno2?: InputMaybe<String_Comparison_Exp>;
  Physarea?: InputMaybe<String_Comparison_Exp>;
  Regtypecode?: InputMaybe<String_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxname?: InputMaybe<String_Comparison_Exp>;
  Taxoffice?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxoffice?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Insert_Input = {
  Birthdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Centername?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Empemail?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Paymentdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Phoneno2?: InputMaybe<Scalars['String']['input']>;
  Physarea?: InputMaybe<Scalars['String']['input']>;
  Regtypecode?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxoffice?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_max_fields';
  Birthdate?: Maybe<Scalars['timestamptz']['output']>;
  Centername?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Empemail?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Paymentdate?: Maybe<Scalars['timestamptz']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Phoneno2?: Maybe<Scalars['String']['output']>;
  Physarea?: Maybe<Scalars['String']['output']>;
  Regtypecode?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Max_Order_By = {
  Birthdate?: InputMaybe<Order_By>;
  Centername?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Empemail?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Paymentdate?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Phoneno2?: InputMaybe<Order_By>;
  Physarea?: InputMaybe<Order_By>;
  Regtypecode?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_min_fields';
  Birthdate?: Maybe<Scalars['timestamptz']['output']>;
  Centername?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Empemail?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Paymentdate?: Maybe<Scalars['timestamptz']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Phoneno2?: Maybe<Scalars['String']['output']>;
  Physarea?: Maybe<Scalars['String']['output']>;
  Regtypecode?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Min_Order_By = {
  Birthdate?: InputMaybe<Order_By>;
  Centername?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Empemail?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Paymentdate?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Phoneno2?: InputMaybe<Order_By>;
  Physarea?: InputMaybe<Order_By>;
  Regtypecode?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By = {
  Birthdate?: InputMaybe<Order_By>;
  Centername?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Empemail?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Paymentdate?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Phoneno2?: InputMaybe<Order_By>;
  Physarea?: InputMaybe<Order_By>;
  Regtypecode?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export enum Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column {
  /** column name */
  Birthdate = 'Birthdate',
  /** column name */
  Centername = 'Centername',
  /** column name */
  Email = 'Email',
  /** column name */
  Empemail = 'Empemail',
  /** column name */
  File = 'File',
  /** column name */
  Fname = 'Fname',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lname = 'Lname',
  /** column name */
  Paidamount = 'Paidamount',
  /** column name */
  Paymentdate = 'Paymentdate',
  /** column name */
  Phoneno = 'Phoneno',
  /** column name */
  Phoneno2 = 'Phoneno2',
  /** column name */
  Physarea = 'Physarea',
  /** column name */
  Regtypecode = 'Regtypecode',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxname = 'Taxname',
  /** column name */
  Taxoffice = 'Taxoffice',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Set_Input = {
  Birthdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Centername?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Empemail?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Paymentdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Phoneno2?: InputMaybe<Scalars['String']['input']>;
  Physarea?: InputMaybe<Scalars['String']['input']>;
  Regtypecode?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxoffice?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_PaymentRental" */
export type Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation';
  Associateaddress?: Maybe<Scalars['String']['output']>;
  Associateid?: Maybe<Scalars['String']['output']>;
  Associatename?: Maybe<Scalars['String']['output']>;
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Assoctin?: Maybe<Scalars['String']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_avg_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Avg_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_RegAssociation". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp = {
  Associateaddress?: InputMaybe<String_Comparison_Exp>;
  Associateid?: InputMaybe<String_Comparison_Exp>;
  Associatename?: InputMaybe<String_Comparison_Exp>;
  Associaterelation?: InputMaybe<Bigint_Comparison_Exp>;
  Assoctin?: InputMaybe<String_Comparison_Exp>;
  Delflag?: InputMaybe<Bigint_Comparison_Exp>;
  Designation?: InputMaybe<String_Comparison_Exp>;
  Din?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Noshare?: InputMaybe<Float8_Comparison_Exp>;
  Profitshare?: InputMaybe<Bigint_Comparison_Exp>;
  Shareheld?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerid?: InputMaybe<Float8_Comparison_Exp>;
  Tin?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Inc_Input = {
  Associaterelation?: InputMaybe<Scalars['bigint']['input']>;
  Delflag?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Noshare?: InputMaybe<Scalars['float8']['input']>;
  Profitshare?: InputMaybe<Scalars['bigint']['input']>;
  Shareheld?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerid?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Insert_Input = {
  Associateaddress?: InputMaybe<Scalars['String']['input']>;
  Associateid?: InputMaybe<Scalars['String']['input']>;
  Associatename?: InputMaybe<Scalars['String']['input']>;
  Associaterelation?: InputMaybe<Scalars['bigint']['input']>;
  Assoctin?: InputMaybe<Scalars['String']['input']>;
  Delflag?: InputMaybe<Scalars['bigint']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Noshare?: InputMaybe<Scalars['float8']['input']>;
  Profitshare?: InputMaybe<Scalars['bigint']['input']>;
  Shareheld?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerid?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_max_fields';
  Associateaddress?: Maybe<Scalars['String']['output']>;
  Associateid?: Maybe<Scalars['String']['output']>;
  Associatename?: Maybe<Scalars['String']['output']>;
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Assoctin?: Maybe<Scalars['String']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Max_Order_By = {
  Associateaddress?: InputMaybe<Order_By>;
  Associateid?: InputMaybe<Order_By>;
  Associatename?: InputMaybe<Order_By>;
  Associaterelation?: InputMaybe<Order_By>;
  Assoctin?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_min_fields';
  Associateaddress?: Maybe<Scalars['String']['output']>;
  Associateid?: Maybe<Scalars['String']['output']>;
  Associatename?: Maybe<Scalars['String']['output']>;
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Assoctin?: Maybe<Scalars['String']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Min_Order_By = {
  Associateaddress?: InputMaybe<Order_By>;
  Associateid?: InputMaybe<Order_By>;
  Associatename?: InputMaybe<Order_By>;
  Associaterelation?: InputMaybe<Order_By>;
  Assoctin?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By = {
  Associateaddress?: InputMaybe<Order_By>;
  Associateid?: InputMaybe<Order_By>;
  Associatename?: InputMaybe<Order_By>;
  Associaterelation?: InputMaybe<Order_By>;
  Assoctin?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export enum Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column {
  /** column name */
  Associateaddress = 'Associateaddress',
  /** column name */
  Associateid = 'Associateid',
  /** column name */
  Associatename = 'Associatename',
  /** column name */
  Associaterelation = 'Associaterelation',
  /** column name */
  Assoctin = 'Assoctin',
  /** column name */
  Delflag = 'Delflag',
  /** column name */
  Designation = 'Designation',
  /** column name */
  Din = 'Din',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Noshare = 'Noshare',
  /** column name */
  Profitshare = 'Profitshare',
  /** column name */
  Shareheld = 'Shareheld',
  /** column name */
  Taxpayerid = 'Taxpayerid',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Set_Input = {
  Associateaddress?: InputMaybe<Scalars['String']['input']>;
  Associateid?: InputMaybe<Scalars['String']['input']>;
  Associatename?: InputMaybe<Scalars['String']['input']>;
  Associaterelation?: InputMaybe<Scalars['bigint']['input']>;
  Assoctin?: InputMaybe<Scalars['String']['input']>;
  Delflag?: InputMaybe<Scalars['bigint']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Noshare?: InputMaybe<Scalars['float8']['input']>;
  Profitshare?: InputMaybe<Scalars['bigint']['input']>;
  Shareheld?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerid?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_stddev_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_stddev_pop_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Pop_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_stddev_samp_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Stddev_Samp_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_sum_fields';
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Sum_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_var_pop_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Pop_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_var_samp_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Var_Samp_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RegAssociation_variance_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_RegAssociation" */
export type Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Variance_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData';
  Address?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Property?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxtype?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_RentalOfficeData". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Contact?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Location?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  No?: InputMaybe<Float8_Comparison_Exp>;
  Property?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Taxtype?: InputMaybe<String_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  No?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Contact?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  No?: InputMaybe<Scalars['float8']['input']>;
  Property?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxtype?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Property?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxtype?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Contact?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Property?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxtype?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Property?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxtype?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Contact?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Property?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxtype?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By = {
  Address?: InputMaybe<Order_By>;
  Contact?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Property?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxtype?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export enum Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Contact = 'Contact',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Location = 'Location',
  /** column name */
  Name = 'Name',
  /** column name */
  No = 'No',
  /** column name */
  Property = 'Property',
  /** column name */
  Status = 'Status',
  /** column name */
  Taxtype = 'Taxtype',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Contact?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  No?: InputMaybe<Scalars['float8']['input']>;
  Property?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxtype?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_RentalOfficeData_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request';
  Appcapacity?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['bigint']['output']>;
  Approvedby?: Maybe<Scalars['String']['output']>;
  Approveddate?: Maybe<Scalars['timestamptz']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Datereceived?: Maybe<Scalars['timestamptz']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declarantdate?: Maybe<Scalars['timestamptz']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Endperiod?: Maybe<Scalars['timestamptz']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Financialapprovedby?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationduedate?: Maybe<Scalars['timestamptz']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Reason?: Maybe<Scalars['String']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requestclass?: Maybe<Scalars['String']['output']>;
  Requestid?: Maybe<Scalars['String']['output']>;
  Requestreason?: Maybe<Scalars['String']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Startperiod?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Supportingdocument?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_Request>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_Request_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_Request_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_avg_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Avg_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_Request". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp = {
  Appcapacity?: InputMaybe<String_Comparison_Exp>;
  Approved?: InputMaybe<Bigint_Comparison_Exp>;
  Approvedby?: InputMaybe<String_Comparison_Exp>;
  Approveddate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Automaticapproval?: InputMaybe<Bigint_Comparison_Exp>;
  Datereceived?: InputMaybe<Timestamptz_Comparison_Exp>;
  Declarant?: InputMaybe<String_Comparison_Exp>;
  Declarantdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Designation?: InputMaybe<String_Comparison_Exp>;
  Din?: InputMaybe<String_Comparison_Exp>;
  Endperiod?: InputMaybe<Timestamptz_Comparison_Exp>;
  Entrydate?: InputMaybe<Timestamptz_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Financialapprovedby?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Notapproved?: InputMaybe<Bigint_Comparison_Exp>;
  Obligationduedate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Obligationid?: InputMaybe<Bigint_Comparison_Exp>;
  Officer?: InputMaybe<String_Comparison_Exp>;
  Reason?: InputMaybe<String_Comparison_Exp>;
  Refunableamount?: InputMaybe<Bigint_Comparison_Exp>;
  Requestamount?: InputMaybe<Float8_Comparison_Exp>;
  Requestclass?: InputMaybe<String_Comparison_Exp>;
  Requestid?: InputMaybe<String_Comparison_Exp>;
  Requestreason?: InputMaybe<String_Comparison_Exp>;
  Requesttype?: InputMaybe<Bigint_Comparison_Exp>;
  Startperiod?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Supportingdocument?: InputMaybe<String_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxmonth?: InputMaybe<Bigint_Comparison_Exp>;
  Taxyear?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Userid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Inc_Input = {
  Approved?: InputMaybe<Scalars['bigint']['input']>;
  Automaticapproval?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Notapproved?: InputMaybe<Scalars['bigint']['input']>;
  Obligationid?: InputMaybe<Scalars['bigint']['input']>;
  Refunableamount?: InputMaybe<Scalars['bigint']['input']>;
  Requestamount?: InputMaybe<Scalars['float8']['input']>;
  Requesttype?: InputMaybe<Scalars['bigint']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Insert_Input = {
  Appcapacity?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['bigint']['input']>;
  Approvedby?: InputMaybe<Scalars['String']['input']>;
  Approveddate?: InputMaybe<Scalars['timestamptz']['input']>;
  Automaticapproval?: InputMaybe<Scalars['bigint']['input']>;
  Datereceived?: InputMaybe<Scalars['timestamptz']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declarantdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Endperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Financialapprovedby?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Notapproved?: InputMaybe<Scalars['bigint']['input']>;
  Obligationduedate?: InputMaybe<Scalars['timestamptz']['input']>;
  Obligationid?: InputMaybe<Scalars['bigint']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Reason?: InputMaybe<Scalars['String']['input']>;
  Refunableamount?: InputMaybe<Scalars['bigint']['input']>;
  Requestamount?: InputMaybe<Scalars['float8']['input']>;
  Requestclass?: InputMaybe<Scalars['String']['input']>;
  Requestid?: InputMaybe<Scalars['String']['input']>;
  Requestreason?: InputMaybe<Scalars['String']['input']>;
  Requesttype?: InputMaybe<Scalars['bigint']['input']>;
  Startperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Supportingdocument?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_max_fields';
  Appcapacity?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['bigint']['output']>;
  Approvedby?: Maybe<Scalars['String']['output']>;
  Approveddate?: Maybe<Scalars['timestamptz']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Datereceived?: Maybe<Scalars['timestamptz']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declarantdate?: Maybe<Scalars['timestamptz']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Endperiod?: Maybe<Scalars['timestamptz']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Financialapprovedby?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationduedate?: Maybe<Scalars['timestamptz']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Reason?: Maybe<Scalars['String']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requestclass?: Maybe<Scalars['String']['output']>;
  Requestid?: Maybe<Scalars['String']['output']>;
  Requestreason?: Maybe<Scalars['String']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Startperiod?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Supportingdocument?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Max_Order_By = {
  Appcapacity?: InputMaybe<Order_By>;
  Approved?: InputMaybe<Order_By>;
  Approvedby?: InputMaybe<Order_By>;
  Approveddate?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Datereceived?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declarantdate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Endperiod?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Financialapprovedby?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationduedate?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Reason?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requestclass?: InputMaybe<Order_By>;
  Requestid?: InputMaybe<Order_By>;
  Requestreason?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Startperiod?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Supportingdocument?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_min_fields';
  Appcapacity?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['bigint']['output']>;
  Approvedby?: Maybe<Scalars['String']['output']>;
  Approveddate?: Maybe<Scalars['timestamptz']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Datereceived?: Maybe<Scalars['timestamptz']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declarantdate?: Maybe<Scalars['timestamptz']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Endperiod?: Maybe<Scalars['timestamptz']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Financialapprovedby?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationduedate?: Maybe<Scalars['timestamptz']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Reason?: Maybe<Scalars['String']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requestclass?: Maybe<Scalars['String']['output']>;
  Requestid?: Maybe<Scalars['String']['output']>;
  Requestreason?: Maybe<Scalars['String']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Startperiod?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Supportingdocument?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Min_Order_By = {
  Appcapacity?: InputMaybe<Order_By>;
  Approved?: InputMaybe<Order_By>;
  Approvedby?: InputMaybe<Order_By>;
  Approveddate?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Datereceived?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declarantdate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Endperiod?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Financialapprovedby?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationduedate?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Reason?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requestclass?: InputMaybe<Order_By>;
  Requestid?: InputMaybe<Order_By>;
  Requestreason?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Startperiod?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Supportingdocument?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_Request>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_Request_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Order_By = {
  Appcapacity?: InputMaybe<Order_By>;
  Approved?: InputMaybe<Order_By>;
  Approvedby?: InputMaybe<Order_By>;
  Approveddate?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Datereceived?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declarantdate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Endperiod?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Financialapprovedby?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationduedate?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Reason?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requestclass?: InputMaybe<Order_By>;
  Requestid?: InputMaybe<Order_By>;
  Requestreason?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Startperiod?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Supportingdocument?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_Request" */
export enum Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column {
  /** column name */
  Appcapacity = 'Appcapacity',
  /** column name */
  Approved = 'Approved',
  /** column name */
  Approvedby = 'Approvedby',
  /** column name */
  Approveddate = 'Approveddate',
  /** column name */
  Automaticapproval = 'Automaticapproval',
  /** column name */
  Datereceived = 'Datereceived',
  /** column name */
  Declarant = 'Declarant',
  /** column name */
  Declarantdate = 'Declarantdate',
  /** column name */
  Designation = 'Designation',
  /** column name */
  Din = 'Din',
  /** column name */
  Endperiod = 'Endperiod',
  /** column name */
  Entrydate = 'Entrydate',
  /** column name */
  File = 'File',
  /** column name */
  Financialapprovedby = 'Financialapprovedby',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Notapproved = 'Notapproved',
  /** column name */
  Obligationduedate = 'Obligationduedate',
  /** column name */
  Obligationid = 'Obligationid',
  /** column name */
  Officer = 'Officer',
  /** column name */
  Reason = 'Reason',
  /** column name */
  Refunableamount = 'Refunableamount',
  /** column name */
  Requestamount = 'Requestamount',
  /** column name */
  Requestclass = 'Requestclass',
  /** column name */
  Requestid = 'Requestid',
  /** column name */
  Requestreason = 'Requestreason',
  /** column name */
  Requesttype = 'Requesttype',
  /** column name */
  Startperiod = 'Startperiod',
  /** column name */
  Status = 'Status',
  /** column name */
  Supportingdocument = 'Supportingdocument',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxmonth = 'Taxmonth',
  /** column name */
  Taxyear = 'Taxyear',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Userid = 'Userid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Set_Input = {
  Appcapacity?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['bigint']['input']>;
  Approvedby?: InputMaybe<Scalars['String']['input']>;
  Approveddate?: InputMaybe<Scalars['timestamptz']['input']>;
  Automaticapproval?: InputMaybe<Scalars['bigint']['input']>;
  Datereceived?: InputMaybe<Scalars['timestamptz']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declarantdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Endperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Financialapprovedby?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Notapproved?: InputMaybe<Scalars['bigint']['input']>;
  Obligationduedate?: InputMaybe<Scalars['timestamptz']['input']>;
  Obligationid?: InputMaybe<Scalars['bigint']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Reason?: InputMaybe<Scalars['String']['input']>;
  Refunableamount?: InputMaybe<Scalars['bigint']['input']>;
  Requestamount?: InputMaybe<Scalars['float8']['input']>;
  Requestclass?: InputMaybe<Scalars['String']['input']>;
  Requestid?: InputMaybe<Scalars['String']['input']>;
  Requestreason?: InputMaybe<Scalars['String']['input']>;
  Requesttype?: InputMaybe<Scalars['bigint']['input']>;
  Startperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Supportingdocument?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_stddev_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_stddev_pop_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Pop_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_stddev_samp_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Stddev_Samp_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_sum_fields';
  Approved?: Maybe<Scalars['bigint']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Sum_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_var_pop_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Var_Pop_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_var_samp_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Var_Samp_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_Request_variance_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_Request" */
export type Rtcs_Gm_Dev_Ph_Gra_Request_Variance_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError';
  Calculatedvalue?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  Errname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Keyinvalue?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnerrorid?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Timestamp?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_ReturnError". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp = {
  Calculatedvalue?: InputMaybe<String_Comparison_Exp>;
  Entrydate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Errname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Keyinvalue?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnerrorid?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Insert_Input = {
  Calculatedvalue?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Errname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Keyinvalue?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnerrorid?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_max_fields';
  Calculatedvalue?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  Errname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Keyinvalue?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnerrorid?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Timestamp?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Max_Order_By = {
  Calculatedvalue?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  Errname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Keyinvalue?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnerrorid?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Timestamp?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_min_fields';
  Calculatedvalue?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  Errname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Keyinvalue?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnerrorid?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Timestamp?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Min_Order_By = {
  Calculatedvalue?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  Errname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Keyinvalue?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnerrorid?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Timestamp?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By = {
  Calculatedvalue?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  Errname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Keyinvalue?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnerrorid?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Timestamp?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export enum Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column {
  /** column name */
  Calculatedvalue = 'Calculatedvalue',
  /** column name */
  Entrydate = 'Entrydate',
  /** column name */
  Errname = 'Errname',
  /** column name */
  File = 'File',
  /** column name */
  Keyinvalue = 'Keyinvalue',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnerrorid = 'Returnerrorid',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Timestamp = 'Timestamp',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Set_Input = {
  Calculatedvalue?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Errname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Keyinvalue?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnerrorid?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnError_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_ReturnError" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnError_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Assessableamunt?: Maybe<Scalars['String']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Chargeableamount?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declaredate?: Maybe<Scalars['String']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Duedate?: Maybe<Scalars['String']['output']>;
  Enddate?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['String']['output']>;
  ErrorStatus?: Maybe<Scalars['String']['output']>;
  Expenses?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['String']['output']>;
  Nettaxdue?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Returnfiledate?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Specialfilingreason?: Maybe<Scalars['String']['output']>;
  Startdate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxdue?: Maybe<Scalars['String']['output']>;
  Taxliability?: Maybe<Scalars['String']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_avg_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Avg_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_ReturnsBase". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp = {
  Ammendmentid?: InputMaybe<Float8_Comparison_Exp>;
  Assessableamunt?: InputMaybe<String_Comparison_Exp>;
  Audit?: InputMaybe<Bigint_Comparison_Exp>;
  Auditrequest?: InputMaybe<Bigint_Comparison_Exp>;
  Chargeableamount?: InputMaybe<String_Comparison_Exp>;
  Declarant?: InputMaybe<String_Comparison_Exp>;
  Declaredate?: InputMaybe<String_Comparison_Exp>;
  Designation?: InputMaybe<String_Comparison_Exp>;
  Din?: InputMaybe<String_Comparison_Exp>;
  Duedate?: InputMaybe<String_Comparison_Exp>;
  Enddate?: InputMaybe<String_Comparison_Exp>;
  Entrydate?: InputMaybe<String_Comparison_Exp>;
  ErrorStatus?: InputMaybe<String_Comparison_Exp>;
  Expenses?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Filereturn?: InputMaybe<Bigint_Comparison_Exp>;
  Grossincome?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Netincome?: InputMaybe<String_Comparison_Exp>;
  Nettaxdue?: InputMaybe<String_Comparison_Exp>;
  Officer?: InputMaybe<String_Comparison_Exp>;
  Returnfiledate?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Sector?: InputMaybe<String_Comparison_Exp>;
  Specialfiling?: InputMaybe<Bigint_Comparison_Exp>;
  Specialfilingreason?: InputMaybe<String_Comparison_Exp>;
  Startdate?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxdue?: InputMaybe<String_Comparison_Exp>;
  Taxliability?: InputMaybe<String_Comparison_Exp>;
  Taxmonth?: InputMaybe<Bigint_Comparison_Exp>;
  Taxyear?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Totaldeduction?: InputMaybe<Float8_Comparison_Exp>;
  Totalexemption?: InputMaybe<Float8_Comparison_Exp>;
  Userid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Inc_Input = {
  Ammendmentid?: InputMaybe<Scalars['float8']['input']>;
  Audit?: InputMaybe<Scalars['bigint']['input']>;
  Auditrequest?: InputMaybe<Scalars['bigint']['input']>;
  Filereturn?: InputMaybe<Scalars['bigint']['input']>;
  Grossincome?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Specialfiling?: InputMaybe<Scalars['bigint']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totaldeduction?: InputMaybe<Scalars['float8']['input']>;
  Totalexemption?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Insert_Input = {
  Ammendmentid?: InputMaybe<Scalars['float8']['input']>;
  Assessableamunt?: InputMaybe<Scalars['String']['input']>;
  Audit?: InputMaybe<Scalars['bigint']['input']>;
  Auditrequest?: InputMaybe<Scalars['bigint']['input']>;
  Chargeableamount?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declaredate?: InputMaybe<Scalars['String']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Duedate?: InputMaybe<Scalars['String']['input']>;
  Enddate?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['String']['input']>;
  ErrorStatus?: InputMaybe<Scalars['String']['input']>;
  Expenses?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Filereturn?: InputMaybe<Scalars['bigint']['input']>;
  Grossincome?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['String']['input']>;
  Nettaxdue?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Returnfiledate?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Specialfiling?: InputMaybe<Scalars['bigint']['input']>;
  Specialfilingreason?: InputMaybe<Scalars['String']['input']>;
  Startdate?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxdue?: InputMaybe<Scalars['String']['input']>;
  Taxliability?: InputMaybe<Scalars['String']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totaldeduction?: InputMaybe<Scalars['float8']['input']>;
  Totalexemption?: InputMaybe<Scalars['float8']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_max_fields';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Assessableamunt?: Maybe<Scalars['String']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Chargeableamount?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declaredate?: Maybe<Scalars['String']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Duedate?: Maybe<Scalars['String']['output']>;
  Enddate?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['String']['output']>;
  ErrorStatus?: Maybe<Scalars['String']['output']>;
  Expenses?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['String']['output']>;
  Nettaxdue?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Returnfiledate?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Specialfilingreason?: Maybe<Scalars['String']['output']>;
  Startdate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxdue?: Maybe<Scalars['String']['output']>;
  Taxliability?: Maybe<Scalars['String']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Max_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Assessableamunt?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Chargeableamount?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declaredate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Duedate?: InputMaybe<Order_By>;
  Enddate?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  ErrorStatus?: InputMaybe<Order_By>;
  Expenses?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Nettaxdue?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Returnfiledate?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Specialfilingreason?: InputMaybe<Order_By>;
  Startdate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxdue?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_min_fields';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Assessableamunt?: Maybe<Scalars['String']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Chargeableamount?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declaredate?: Maybe<Scalars['String']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Duedate?: Maybe<Scalars['String']['output']>;
  Enddate?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['String']['output']>;
  ErrorStatus?: Maybe<Scalars['String']['output']>;
  Expenses?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['String']['output']>;
  Nettaxdue?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Returnfiledate?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Specialfilingreason?: Maybe<Scalars['String']['output']>;
  Startdate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxdue?: Maybe<Scalars['String']['output']>;
  Taxliability?: Maybe<Scalars['String']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Min_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Assessableamunt?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Chargeableamount?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declaredate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Duedate?: InputMaybe<Order_By>;
  Enddate?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  ErrorStatus?: InputMaybe<Order_By>;
  Expenses?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Nettaxdue?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Returnfiledate?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Specialfilingreason?: InputMaybe<Order_By>;
  Startdate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxdue?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Assessableamunt?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Chargeableamount?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declaredate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Duedate?: InputMaybe<Order_By>;
  Enddate?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  ErrorStatus?: InputMaybe<Order_By>;
  Expenses?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Nettaxdue?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Returnfiledate?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Specialfilingreason?: InputMaybe<Order_By>;
  Startdate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxdue?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export enum Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column {
  /** column name */
  Ammendmentid = 'Ammendmentid',
  /** column name */
  Assessableamunt = 'Assessableamunt',
  /** column name */
  Audit = 'Audit',
  /** column name */
  Auditrequest = 'Auditrequest',
  /** column name */
  Chargeableamount = 'Chargeableamount',
  /** column name */
  Declarant = 'Declarant',
  /** column name */
  Declaredate = 'Declaredate',
  /** column name */
  Designation = 'Designation',
  /** column name */
  Din = 'Din',
  /** column name */
  Duedate = 'Duedate',
  /** column name */
  Enddate = 'Enddate',
  /** column name */
  Entrydate = 'Entrydate',
  /** column name */
  ErrorStatus = 'ErrorStatus',
  /** column name */
  Expenses = 'Expenses',
  /** column name */
  File = 'File',
  /** column name */
  Filereturn = 'Filereturn',
  /** column name */
  Grossincome = 'Grossincome',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Netincome = 'Netincome',
  /** column name */
  Nettaxdue = 'Nettaxdue',
  /** column name */
  Officer = 'Officer',
  /** column name */
  Returnfiledate = 'Returnfiledate',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Sector = 'Sector',
  /** column name */
  Specialfiling = 'Specialfiling',
  /** column name */
  Specialfilingreason = 'Specialfilingreason',
  /** column name */
  Startdate = 'Startdate',
  /** column name */
  Status = 'Status',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxdue = 'Taxdue',
  /** column name */
  Taxliability = 'Taxliability',
  /** column name */
  Taxmonth = 'Taxmonth',
  /** column name */
  Taxyear = 'Taxyear',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Totaldeduction = 'Totaldeduction',
  /** column name */
  Totalexemption = 'Totalexemption',
  /** column name */
  Userid = 'Userid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Set_Input = {
  Ammendmentid?: InputMaybe<Scalars['float8']['input']>;
  Assessableamunt?: InputMaybe<Scalars['String']['input']>;
  Audit?: InputMaybe<Scalars['bigint']['input']>;
  Auditrequest?: InputMaybe<Scalars['bigint']['input']>;
  Chargeableamount?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declaredate?: InputMaybe<Scalars['String']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Duedate?: InputMaybe<Scalars['String']['input']>;
  Enddate?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['String']['input']>;
  ErrorStatus?: InputMaybe<Scalars['String']['input']>;
  Expenses?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Filereturn?: InputMaybe<Scalars['bigint']['input']>;
  Grossincome?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['String']['input']>;
  Nettaxdue?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Returnfiledate?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Specialfiling?: InputMaybe<Scalars['bigint']['input']>;
  Specialfilingreason?: InputMaybe<Scalars['String']['input']>;
  Startdate?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxdue?: InputMaybe<Scalars['String']['input']>;
  Taxliability?: InputMaybe<Scalars['String']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totaldeduction?: InputMaybe<Scalars['float8']['input']>;
  Totalexemption?: InputMaybe<Scalars['float8']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_stddev_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_stddev_pop_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Pop_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_stddev_samp_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Stddev_Samp_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_sum_fields';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Sum_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_var_pop_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Pop_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_var_samp_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Var_Samp_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_ReturnsBase_variance_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Variance_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer';
  AgAddress?: Maybe<Scalars['String']['output']>;
  AgName?: Maybe<Scalars['String']['output']>;
  AgPhoneNo?: Maybe<Scalars['String']['output']>;
  AgTIN?: Maybe<Scalars['String']['output']>;
  AppCapacity?: Maybe<Scalars['String']['output']>;
  ApprovedBy?: Maybe<Scalars['String']['output']>;
  ApprovedDate?: Maybe<Scalars['String']['output']>;
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  BlockName?: Maybe<Scalars['String']['output']>;
  BusStartDate?: Maybe<Scalars['String']['output']>;
  BusinessCode?: Maybe<Scalars['String']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DIN?: Maybe<Scalars['String']['output']>;
  DecCapacity?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  DeclareDate?: Maybe<Scalars['String']['output']>;
  DelDate?: Maybe<Scalars['String']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmpAddress?: Maybe<Scalars['String']['output']>;
  EmpEMail?: Maybe<Scalars['String']['output']>;
  EmpFax?: Maybe<Scalars['String']['output']>;
  EmpMobile?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo1?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo2?: Maybe<Scalars['String']['output']>;
  EmpTIN?: Maybe<Scalars['String']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  EntryDate?: Maybe<Scalars['String']['output']>;
  ExemptAuth?: Maybe<Scalars['String']['output']>;
  FName?: Maybe<Scalars['String']['output']>;
  FaxNo?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  ImportNo?: Maybe<Scalars['String']['output']>;
  IncomeTax?: Maybe<Scalars['String']['output']>;
  LName?: Maybe<Scalars['String']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  LastDocNum?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastchanged?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MFName?: Maybe<Scalars['String']['output']>;
  MLName?: Maybe<Scalars['String']['output']>;
  MName?: Maybe<Scalars['String']['output']>;
  Marital?: Maybe<Scalars['String']['output']>;
  NIN?: Maybe<Scalars['String']['output']>;
  NINIncorpCert?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Others?: Maybe<Scalars['String']['output']>;
  PassportNo?: Maybe<Scalars['String']['output']>;
  PayeeTax?: Maybe<Scalars['String']['output']>;
  PayerType?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  PhoneNo2?: Maybe<Scalars['String']['output']>;
  PhysArea?: Maybe<Scalars['String']['output']>;
  PhysPlotNo?: Maybe<Scalars['String']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PhysStreet?: Maybe<Scalars['String']['output']>;
  PhysTown?: Maybe<Scalars['String']['output']>;
  PostalBox?: Maybe<Scalars['String']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  PostalTown?: Maybe<Scalars['String']['output']>;
  PrevIncFileNo?: Maybe<Scalars['String']['output']>;
  PrevName?: Maybe<Scalars['String']['output']>;
  PrevPayeNo?: Maybe<Scalars['String']['output']>;
  ReMark?: Maybe<Scalars['String']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  RegDate?: Maybe<Scalars['String']['output']>;
  RegStatus?: Maybe<Scalars['String']['output']>;
  RegTypeCode?: Maybe<Scalars['String']['output']>;
  StampDate?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  TINNotApproval?: Maybe<Scalars['String']['output']>;
  TaxPayerID?: Maybe<Scalars['String']['output']>;
  TradingName?: Maybe<Scalars['String']['output']>;
  TurnOver?: Maybe<Scalars['String']['output']>;
  UserID?: Maybe<Scalars['String']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_avg_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Avg_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_TaxPayer". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp = {
  AgAddress?: InputMaybe<String_Comparison_Exp>;
  AgName?: InputMaybe<String_Comparison_Exp>;
  AgPhoneNo?: InputMaybe<String_Comparison_Exp>;
  AgTIN?: InputMaybe<String_Comparison_Exp>;
  AppCapacity?: InputMaybe<String_Comparison_Exp>;
  ApprovedBy?: InputMaybe<String_Comparison_Exp>;
  ApprovedDate?: InputMaybe<String_Comparison_Exp>;
  AuditFlag?: InputMaybe<Bigint_Comparison_Exp>;
  BirthDate?: InputMaybe<String_Comparison_Exp>;
  BlockName?: InputMaybe<String_Comparison_Exp>;
  BusStartDate?: InputMaybe<String_Comparison_Exp>;
  BusinessCode?: InputMaybe<String_Comparison_Exp>;
  ChkApproved?: InputMaybe<Bigint_Comparison_Exp>;
  ChkCitizen?: InputMaybe<Bigint_Comparison_Exp>;
  ChkExempt?: InputMaybe<Bigint_Comparison_Exp>;
  ChkIdCard?: InputMaybe<Bigint_Comparison_Exp>;
  ChkNotApproved?: InputMaybe<Bigint_Comparison_Exp>;
  ChkPassPort?: InputMaybe<Bigint_Comparison_Exp>;
  ChkRegist?: InputMaybe<Bigint_Comparison_Exp>;
  ChkcertInCorp?: InputMaybe<Bigint_Comparison_Exp>;
  Country?: InputMaybe<String_Comparison_Exp>;
  CreatedDate?: InputMaybe<String_Comparison_Exp>;
  DIN?: InputMaybe<String_Comparison_Exp>;
  DecCapacity?: InputMaybe<String_Comparison_Exp>;
  Declarant?: InputMaybe<String_Comparison_Exp>;
  DeclareDate?: InputMaybe<String_Comparison_Exp>;
  DelDate?: InputMaybe<String_Comparison_Exp>;
  DelFlag?: InputMaybe<Bigint_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  EmpAddress?: InputMaybe<String_Comparison_Exp>;
  EmpEMail?: InputMaybe<String_Comparison_Exp>;
  EmpFax?: InputMaybe<String_Comparison_Exp>;
  EmpMobile?: InputMaybe<String_Comparison_Exp>;
  EmpPhoneNo1?: InputMaybe<String_Comparison_Exp>;
  EmpPhoneNo2?: InputMaybe<String_Comparison_Exp>;
  EmpTIN?: InputMaybe<String_Comparison_Exp>;
  EmpType?: InputMaybe<Bigint_Comparison_Exp>;
  EndDate?: InputMaybe<String_Comparison_Exp>;
  Enforce?: InputMaybe<Bigint_Comparison_Exp>;
  EntryDate?: InputMaybe<String_Comparison_Exp>;
  ExemptAuth?: InputMaybe<String_Comparison_Exp>;
  FName?: InputMaybe<String_Comparison_Exp>;
  FaxNo?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  ForceReg?: InputMaybe<Bigint_Comparison_Exp>;
  Gender?: InputMaybe<String_Comparison_Exp>;
  ImportNo?: InputMaybe<String_Comparison_Exp>;
  IncomeTax?: InputMaybe<String_Comparison_Exp>;
  LName?: InputMaybe<String_Comparison_Exp>;
  LargTaxPayer?: InputMaybe<Bigint_Comparison_Exp>;
  LargeTaxPayerStatus?: InputMaybe<Bigint_Comparison_Exp>;
  LastDocNum?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lastchanged?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  MFName?: InputMaybe<String_Comparison_Exp>;
  MLName?: InputMaybe<String_Comparison_Exp>;
  MName?: InputMaybe<String_Comparison_Exp>;
  Marital?: InputMaybe<String_Comparison_Exp>;
  NIN?: InputMaybe<String_Comparison_Exp>;
  NINIncorpCert?: InputMaybe<String_Comparison_Exp>;
  Officer?: InputMaybe<String_Comparison_Exp>;
  Others?: InputMaybe<String_Comparison_Exp>;
  PassportNo?: InputMaybe<String_Comparison_Exp>;
  PayeeTax?: InputMaybe<String_Comparison_Exp>;
  PayerType?: InputMaybe<String_Comparison_Exp>;
  PhoneNo?: InputMaybe<String_Comparison_Exp>;
  PhoneNo2?: InputMaybe<String_Comparison_Exp>;
  PhysArea?: InputMaybe<String_Comparison_Exp>;
  PhysPlotNo?: InputMaybe<String_Comparison_Exp>;
  PhysProv?: InputMaybe<Bigint_Comparison_Exp>;
  PhysStreet?: InputMaybe<String_Comparison_Exp>;
  PhysTown?: InputMaybe<String_Comparison_Exp>;
  PostalBox?: InputMaybe<String_Comparison_Exp>;
  PostalProv?: InputMaybe<Bigint_Comparison_Exp>;
  PostalTown?: InputMaybe<String_Comparison_Exp>;
  PrevIncFileNo?: InputMaybe<String_Comparison_Exp>;
  PrevName?: InputMaybe<String_Comparison_Exp>;
  PrevPayeNo?: InputMaybe<String_Comparison_Exp>;
  ReMark?: InputMaybe<String_Comparison_Exp>;
  RefusalCode?: InputMaybe<Float8_Comparison_Exp>;
  RegCenterCode?: InputMaybe<Bigint_Comparison_Exp>;
  RegDate?: InputMaybe<String_Comparison_Exp>;
  RegStatus?: InputMaybe<String_Comparison_Exp>;
  RegTypeCode?: InputMaybe<String_Comparison_Exp>;
  StampDate?: InputMaybe<String_Comparison_Exp>;
  StartDate?: InputMaybe<String_Comparison_Exp>;
  TIN?: InputMaybe<Bigint_Comparison_Exp>;
  TINApproval?: InputMaybe<Bigint_Comparison_Exp>;
  TINCertCount?: InputMaybe<Bigint_Comparison_Exp>;
  TINNotApproval?: InputMaybe<String_Comparison_Exp>;
  TaxPayerID?: InputMaybe<String_Comparison_Exp>;
  TradingName?: InputMaybe<String_Comparison_Exp>;
  TurnOver?: InputMaybe<String_Comparison_Exp>;
  UserID?: InputMaybe<String_Comparison_Exp>;
  VATTurnOver?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>>>;
  chkBirthCert?: InputMaybe<Bigint_Comparison_Exp>;
  chkEmpExpat?: InputMaybe<Bigint_Comparison_Exp>;
  chkEmploy?: InputMaybe<Bigint_Comparison_Exp>;
  chkOtherBranch?: InputMaybe<Bigint_Comparison_Exp>;
  chkSpecialTaxPeriod?: InputMaybe<Bigint_Comparison_Exp>;
  chkTaxExempt?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Inc_Input = {
  AuditFlag?: InputMaybe<Scalars['bigint']['input']>;
  ChkApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkCitizen?: InputMaybe<Scalars['bigint']['input']>;
  ChkExempt?: InputMaybe<Scalars['bigint']['input']>;
  ChkIdCard?: InputMaybe<Scalars['bigint']['input']>;
  ChkNotApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkPassPort?: InputMaybe<Scalars['bigint']['input']>;
  ChkRegist?: InputMaybe<Scalars['bigint']['input']>;
  ChkcertInCorp?: InputMaybe<Scalars['bigint']['input']>;
  DelFlag?: InputMaybe<Scalars['bigint']['input']>;
  EmpType?: InputMaybe<Scalars['bigint']['input']>;
  Enforce?: InputMaybe<Scalars['bigint']['input']>;
  ForceReg?: InputMaybe<Scalars['bigint']['input']>;
  LargTaxPayer?: InputMaybe<Scalars['bigint']['input']>;
  LargeTaxPayerStatus?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  PhysProv?: InputMaybe<Scalars['bigint']['input']>;
  PostalProv?: InputMaybe<Scalars['bigint']['input']>;
  RefusalCode?: InputMaybe<Scalars['float8']['input']>;
  RegCenterCode?: InputMaybe<Scalars['bigint']['input']>;
  TIN?: InputMaybe<Scalars['bigint']['input']>;
  TINApproval?: InputMaybe<Scalars['bigint']['input']>;
  TINCertCount?: InputMaybe<Scalars['bigint']['input']>;
  VATTurnOver?: InputMaybe<Scalars['bigint']['input']>;
  chkBirthCert?: InputMaybe<Scalars['bigint']['input']>;
  chkEmpExpat?: InputMaybe<Scalars['bigint']['input']>;
  chkEmploy?: InputMaybe<Scalars['bigint']['input']>;
  chkOtherBranch?: InputMaybe<Scalars['bigint']['input']>;
  chkSpecialTaxPeriod?: InputMaybe<Scalars['bigint']['input']>;
  chkTaxExempt?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Insert_Input = {
  AgAddress?: InputMaybe<Scalars['String']['input']>;
  AgName?: InputMaybe<Scalars['String']['input']>;
  AgPhoneNo?: InputMaybe<Scalars['String']['input']>;
  AgTIN?: InputMaybe<Scalars['String']['input']>;
  AppCapacity?: InputMaybe<Scalars['String']['input']>;
  ApprovedBy?: InputMaybe<Scalars['String']['input']>;
  ApprovedDate?: InputMaybe<Scalars['String']['input']>;
  AuditFlag?: InputMaybe<Scalars['bigint']['input']>;
  BirthDate?: InputMaybe<Scalars['String']['input']>;
  BlockName?: InputMaybe<Scalars['String']['input']>;
  BusStartDate?: InputMaybe<Scalars['String']['input']>;
  BusinessCode?: InputMaybe<Scalars['String']['input']>;
  ChkApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkCitizen?: InputMaybe<Scalars['bigint']['input']>;
  ChkExempt?: InputMaybe<Scalars['bigint']['input']>;
  ChkIdCard?: InputMaybe<Scalars['bigint']['input']>;
  ChkNotApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkPassPort?: InputMaybe<Scalars['bigint']['input']>;
  ChkRegist?: InputMaybe<Scalars['bigint']['input']>;
  ChkcertInCorp?: InputMaybe<Scalars['bigint']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedDate?: InputMaybe<Scalars['String']['input']>;
  DIN?: InputMaybe<Scalars['String']['input']>;
  DecCapacity?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  DeclareDate?: InputMaybe<Scalars['String']['input']>;
  DelDate?: InputMaybe<Scalars['String']['input']>;
  DelFlag?: InputMaybe<Scalars['bigint']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmpAddress?: InputMaybe<Scalars['String']['input']>;
  EmpEMail?: InputMaybe<Scalars['String']['input']>;
  EmpFax?: InputMaybe<Scalars['String']['input']>;
  EmpMobile?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo1?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo2?: InputMaybe<Scalars['String']['input']>;
  EmpTIN?: InputMaybe<Scalars['String']['input']>;
  EmpType?: InputMaybe<Scalars['bigint']['input']>;
  EndDate?: InputMaybe<Scalars['String']['input']>;
  Enforce?: InputMaybe<Scalars['bigint']['input']>;
  EntryDate?: InputMaybe<Scalars['String']['input']>;
  ExemptAuth?: InputMaybe<Scalars['String']['input']>;
  FName?: InputMaybe<Scalars['String']['input']>;
  FaxNo?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  ForceReg?: InputMaybe<Scalars['bigint']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  ImportNo?: InputMaybe<Scalars['String']['input']>;
  IncomeTax?: InputMaybe<Scalars['String']['input']>;
  LName?: InputMaybe<Scalars['String']['input']>;
  LargTaxPayer?: InputMaybe<Scalars['bigint']['input']>;
  LargeTaxPayerStatus?: InputMaybe<Scalars['bigint']['input']>;
  LastDocNum?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastchanged?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MFName?: InputMaybe<Scalars['String']['input']>;
  MLName?: InputMaybe<Scalars['String']['input']>;
  MName?: InputMaybe<Scalars['String']['input']>;
  Marital?: InputMaybe<Scalars['String']['input']>;
  NIN?: InputMaybe<Scalars['String']['input']>;
  NINIncorpCert?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Others?: InputMaybe<Scalars['String']['input']>;
  PassportNo?: InputMaybe<Scalars['String']['input']>;
  PayeeTax?: InputMaybe<Scalars['String']['input']>;
  PayerType?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  PhoneNo2?: InputMaybe<Scalars['String']['input']>;
  PhysArea?: InputMaybe<Scalars['String']['input']>;
  PhysPlotNo?: InputMaybe<Scalars['String']['input']>;
  PhysProv?: InputMaybe<Scalars['bigint']['input']>;
  PhysStreet?: InputMaybe<Scalars['String']['input']>;
  PhysTown?: InputMaybe<Scalars['String']['input']>;
  PostalBox?: InputMaybe<Scalars['String']['input']>;
  PostalProv?: InputMaybe<Scalars['bigint']['input']>;
  PostalTown?: InputMaybe<Scalars['String']['input']>;
  PrevIncFileNo?: InputMaybe<Scalars['String']['input']>;
  PrevName?: InputMaybe<Scalars['String']['input']>;
  PrevPayeNo?: InputMaybe<Scalars['String']['input']>;
  ReMark?: InputMaybe<Scalars['String']['input']>;
  RefusalCode?: InputMaybe<Scalars['float8']['input']>;
  RegCenterCode?: InputMaybe<Scalars['bigint']['input']>;
  RegDate?: InputMaybe<Scalars['String']['input']>;
  RegStatus?: InputMaybe<Scalars['String']['input']>;
  RegTypeCode?: InputMaybe<Scalars['String']['input']>;
  StampDate?: InputMaybe<Scalars['String']['input']>;
  StartDate?: InputMaybe<Scalars['String']['input']>;
  TIN?: InputMaybe<Scalars['bigint']['input']>;
  TINApproval?: InputMaybe<Scalars['bigint']['input']>;
  TINCertCount?: InputMaybe<Scalars['bigint']['input']>;
  TINNotApproval?: InputMaybe<Scalars['String']['input']>;
  TaxPayerID?: InputMaybe<Scalars['String']['input']>;
  TradingName?: InputMaybe<Scalars['String']['input']>;
  TurnOver?: InputMaybe<Scalars['String']['input']>;
  UserID?: InputMaybe<Scalars['String']['input']>;
  VATTurnOver?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  chkBirthCert?: InputMaybe<Scalars['bigint']['input']>;
  chkEmpExpat?: InputMaybe<Scalars['bigint']['input']>;
  chkEmploy?: InputMaybe<Scalars['bigint']['input']>;
  chkOtherBranch?: InputMaybe<Scalars['bigint']['input']>;
  chkSpecialTaxPeriod?: InputMaybe<Scalars['bigint']['input']>;
  chkTaxExempt?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_max_fields';
  AgAddress?: Maybe<Scalars['String']['output']>;
  AgName?: Maybe<Scalars['String']['output']>;
  AgPhoneNo?: Maybe<Scalars['String']['output']>;
  AgTIN?: Maybe<Scalars['String']['output']>;
  AppCapacity?: Maybe<Scalars['String']['output']>;
  ApprovedBy?: Maybe<Scalars['String']['output']>;
  ApprovedDate?: Maybe<Scalars['String']['output']>;
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  BlockName?: Maybe<Scalars['String']['output']>;
  BusStartDate?: Maybe<Scalars['String']['output']>;
  BusinessCode?: Maybe<Scalars['String']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DIN?: Maybe<Scalars['String']['output']>;
  DecCapacity?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  DeclareDate?: Maybe<Scalars['String']['output']>;
  DelDate?: Maybe<Scalars['String']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmpAddress?: Maybe<Scalars['String']['output']>;
  EmpEMail?: Maybe<Scalars['String']['output']>;
  EmpFax?: Maybe<Scalars['String']['output']>;
  EmpMobile?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo1?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo2?: Maybe<Scalars['String']['output']>;
  EmpTIN?: Maybe<Scalars['String']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  EntryDate?: Maybe<Scalars['String']['output']>;
  ExemptAuth?: Maybe<Scalars['String']['output']>;
  FName?: Maybe<Scalars['String']['output']>;
  FaxNo?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  ImportNo?: Maybe<Scalars['String']['output']>;
  IncomeTax?: Maybe<Scalars['String']['output']>;
  LName?: Maybe<Scalars['String']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  LastDocNum?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastchanged?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MFName?: Maybe<Scalars['String']['output']>;
  MLName?: Maybe<Scalars['String']['output']>;
  MName?: Maybe<Scalars['String']['output']>;
  Marital?: Maybe<Scalars['String']['output']>;
  NIN?: Maybe<Scalars['String']['output']>;
  NINIncorpCert?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Others?: Maybe<Scalars['String']['output']>;
  PassportNo?: Maybe<Scalars['String']['output']>;
  PayeeTax?: Maybe<Scalars['String']['output']>;
  PayerType?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  PhoneNo2?: Maybe<Scalars['String']['output']>;
  PhysArea?: Maybe<Scalars['String']['output']>;
  PhysPlotNo?: Maybe<Scalars['String']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PhysStreet?: Maybe<Scalars['String']['output']>;
  PhysTown?: Maybe<Scalars['String']['output']>;
  PostalBox?: Maybe<Scalars['String']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  PostalTown?: Maybe<Scalars['String']['output']>;
  PrevIncFileNo?: Maybe<Scalars['String']['output']>;
  PrevName?: Maybe<Scalars['String']['output']>;
  PrevPayeNo?: Maybe<Scalars['String']['output']>;
  ReMark?: Maybe<Scalars['String']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  RegDate?: Maybe<Scalars['String']['output']>;
  RegStatus?: Maybe<Scalars['String']['output']>;
  RegTypeCode?: Maybe<Scalars['String']['output']>;
  StampDate?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  TINNotApproval?: Maybe<Scalars['String']['output']>;
  TaxPayerID?: Maybe<Scalars['String']['output']>;
  TradingName?: Maybe<Scalars['String']['output']>;
  TurnOver?: Maybe<Scalars['String']['output']>;
  UserID?: Maybe<Scalars['String']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Max_Order_By = {
  AgAddress?: InputMaybe<Order_By>;
  AgName?: InputMaybe<Order_By>;
  AgPhoneNo?: InputMaybe<Order_By>;
  AgTIN?: InputMaybe<Order_By>;
  AppCapacity?: InputMaybe<Order_By>;
  ApprovedBy?: InputMaybe<Order_By>;
  ApprovedDate?: InputMaybe<Order_By>;
  AuditFlag?: InputMaybe<Order_By>;
  BirthDate?: InputMaybe<Order_By>;
  BlockName?: InputMaybe<Order_By>;
  BusStartDate?: InputMaybe<Order_By>;
  BusinessCode?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedDate?: InputMaybe<Order_By>;
  DIN?: InputMaybe<Order_By>;
  DecCapacity?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  DeclareDate?: InputMaybe<Order_By>;
  DelDate?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmpAddress?: InputMaybe<Order_By>;
  EmpEMail?: InputMaybe<Order_By>;
  EmpFax?: InputMaybe<Order_By>;
  EmpMobile?: InputMaybe<Order_By>;
  EmpPhoneNo1?: InputMaybe<Order_By>;
  EmpPhoneNo2?: InputMaybe<Order_By>;
  EmpTIN?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  EntryDate?: InputMaybe<Order_By>;
  ExemptAuth?: InputMaybe<Order_By>;
  FName?: InputMaybe<Order_By>;
  FaxNo?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  ImportNo?: InputMaybe<Order_By>;
  IncomeTax?: InputMaybe<Order_By>;
  LName?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  LastDocNum?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastchanged?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MFName?: InputMaybe<Order_By>;
  MLName?: InputMaybe<Order_By>;
  MName?: InputMaybe<Order_By>;
  Marital?: InputMaybe<Order_By>;
  NIN?: InputMaybe<Order_By>;
  NINIncorpCert?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Others?: InputMaybe<Order_By>;
  PassportNo?: InputMaybe<Order_By>;
  PayeeTax?: InputMaybe<Order_By>;
  PayerType?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  PhoneNo2?: InputMaybe<Order_By>;
  PhysArea?: InputMaybe<Order_By>;
  PhysPlotNo?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PhysStreet?: InputMaybe<Order_By>;
  PhysTown?: InputMaybe<Order_By>;
  PostalBox?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  PostalTown?: InputMaybe<Order_By>;
  PrevIncFileNo?: InputMaybe<Order_By>;
  PrevName?: InputMaybe<Order_By>;
  PrevPayeNo?: InputMaybe<Order_By>;
  ReMark?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  RegDate?: InputMaybe<Order_By>;
  RegStatus?: InputMaybe<Order_By>;
  RegTypeCode?: InputMaybe<Order_By>;
  StampDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  TINNotApproval?: InputMaybe<Order_By>;
  TaxPayerID?: InputMaybe<Order_By>;
  TradingName?: InputMaybe<Order_By>;
  TurnOver?: InputMaybe<Order_By>;
  UserID?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_min_fields';
  AgAddress?: Maybe<Scalars['String']['output']>;
  AgName?: Maybe<Scalars['String']['output']>;
  AgPhoneNo?: Maybe<Scalars['String']['output']>;
  AgTIN?: Maybe<Scalars['String']['output']>;
  AppCapacity?: Maybe<Scalars['String']['output']>;
  ApprovedBy?: Maybe<Scalars['String']['output']>;
  ApprovedDate?: Maybe<Scalars['String']['output']>;
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  BlockName?: Maybe<Scalars['String']['output']>;
  BusStartDate?: Maybe<Scalars['String']['output']>;
  BusinessCode?: Maybe<Scalars['String']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DIN?: Maybe<Scalars['String']['output']>;
  DecCapacity?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  DeclareDate?: Maybe<Scalars['String']['output']>;
  DelDate?: Maybe<Scalars['String']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmpAddress?: Maybe<Scalars['String']['output']>;
  EmpEMail?: Maybe<Scalars['String']['output']>;
  EmpFax?: Maybe<Scalars['String']['output']>;
  EmpMobile?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo1?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo2?: Maybe<Scalars['String']['output']>;
  EmpTIN?: Maybe<Scalars['String']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  EntryDate?: Maybe<Scalars['String']['output']>;
  ExemptAuth?: Maybe<Scalars['String']['output']>;
  FName?: Maybe<Scalars['String']['output']>;
  FaxNo?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  ImportNo?: Maybe<Scalars['String']['output']>;
  IncomeTax?: Maybe<Scalars['String']['output']>;
  LName?: Maybe<Scalars['String']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  LastDocNum?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastchanged?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MFName?: Maybe<Scalars['String']['output']>;
  MLName?: Maybe<Scalars['String']['output']>;
  MName?: Maybe<Scalars['String']['output']>;
  Marital?: Maybe<Scalars['String']['output']>;
  NIN?: Maybe<Scalars['String']['output']>;
  NINIncorpCert?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Others?: Maybe<Scalars['String']['output']>;
  PassportNo?: Maybe<Scalars['String']['output']>;
  PayeeTax?: Maybe<Scalars['String']['output']>;
  PayerType?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  PhoneNo2?: Maybe<Scalars['String']['output']>;
  PhysArea?: Maybe<Scalars['String']['output']>;
  PhysPlotNo?: Maybe<Scalars['String']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PhysStreet?: Maybe<Scalars['String']['output']>;
  PhysTown?: Maybe<Scalars['String']['output']>;
  PostalBox?: Maybe<Scalars['String']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  PostalTown?: Maybe<Scalars['String']['output']>;
  PrevIncFileNo?: Maybe<Scalars['String']['output']>;
  PrevName?: Maybe<Scalars['String']['output']>;
  PrevPayeNo?: Maybe<Scalars['String']['output']>;
  ReMark?: Maybe<Scalars['String']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  RegDate?: Maybe<Scalars['String']['output']>;
  RegStatus?: Maybe<Scalars['String']['output']>;
  RegTypeCode?: Maybe<Scalars['String']['output']>;
  StampDate?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  TINNotApproval?: Maybe<Scalars['String']['output']>;
  TaxPayerID?: Maybe<Scalars['String']['output']>;
  TradingName?: Maybe<Scalars['String']['output']>;
  TurnOver?: Maybe<Scalars['String']['output']>;
  UserID?: Maybe<Scalars['String']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Min_Order_By = {
  AgAddress?: InputMaybe<Order_By>;
  AgName?: InputMaybe<Order_By>;
  AgPhoneNo?: InputMaybe<Order_By>;
  AgTIN?: InputMaybe<Order_By>;
  AppCapacity?: InputMaybe<Order_By>;
  ApprovedBy?: InputMaybe<Order_By>;
  ApprovedDate?: InputMaybe<Order_By>;
  AuditFlag?: InputMaybe<Order_By>;
  BirthDate?: InputMaybe<Order_By>;
  BlockName?: InputMaybe<Order_By>;
  BusStartDate?: InputMaybe<Order_By>;
  BusinessCode?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedDate?: InputMaybe<Order_By>;
  DIN?: InputMaybe<Order_By>;
  DecCapacity?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  DeclareDate?: InputMaybe<Order_By>;
  DelDate?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmpAddress?: InputMaybe<Order_By>;
  EmpEMail?: InputMaybe<Order_By>;
  EmpFax?: InputMaybe<Order_By>;
  EmpMobile?: InputMaybe<Order_By>;
  EmpPhoneNo1?: InputMaybe<Order_By>;
  EmpPhoneNo2?: InputMaybe<Order_By>;
  EmpTIN?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  EntryDate?: InputMaybe<Order_By>;
  ExemptAuth?: InputMaybe<Order_By>;
  FName?: InputMaybe<Order_By>;
  FaxNo?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  ImportNo?: InputMaybe<Order_By>;
  IncomeTax?: InputMaybe<Order_By>;
  LName?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  LastDocNum?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastchanged?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MFName?: InputMaybe<Order_By>;
  MLName?: InputMaybe<Order_By>;
  MName?: InputMaybe<Order_By>;
  Marital?: InputMaybe<Order_By>;
  NIN?: InputMaybe<Order_By>;
  NINIncorpCert?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Others?: InputMaybe<Order_By>;
  PassportNo?: InputMaybe<Order_By>;
  PayeeTax?: InputMaybe<Order_By>;
  PayerType?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  PhoneNo2?: InputMaybe<Order_By>;
  PhysArea?: InputMaybe<Order_By>;
  PhysPlotNo?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PhysStreet?: InputMaybe<Order_By>;
  PhysTown?: InputMaybe<Order_By>;
  PostalBox?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  PostalTown?: InputMaybe<Order_By>;
  PrevIncFileNo?: InputMaybe<Order_By>;
  PrevName?: InputMaybe<Order_By>;
  PrevPayeNo?: InputMaybe<Order_By>;
  ReMark?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  RegDate?: InputMaybe<Order_By>;
  RegStatus?: InputMaybe<Order_By>;
  RegTypeCode?: InputMaybe<Order_By>;
  StampDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  TINNotApproval?: InputMaybe<Order_By>;
  TaxPayerID?: InputMaybe<Order_By>;
  TradingName?: InputMaybe<Order_By>;
  TurnOver?: InputMaybe<Order_By>;
  UserID?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By = {
  AgAddress?: InputMaybe<Order_By>;
  AgName?: InputMaybe<Order_By>;
  AgPhoneNo?: InputMaybe<Order_By>;
  AgTIN?: InputMaybe<Order_By>;
  AppCapacity?: InputMaybe<Order_By>;
  ApprovedBy?: InputMaybe<Order_By>;
  ApprovedDate?: InputMaybe<Order_By>;
  AuditFlag?: InputMaybe<Order_By>;
  BirthDate?: InputMaybe<Order_By>;
  BlockName?: InputMaybe<Order_By>;
  BusStartDate?: InputMaybe<Order_By>;
  BusinessCode?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedDate?: InputMaybe<Order_By>;
  DIN?: InputMaybe<Order_By>;
  DecCapacity?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  DeclareDate?: InputMaybe<Order_By>;
  DelDate?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmpAddress?: InputMaybe<Order_By>;
  EmpEMail?: InputMaybe<Order_By>;
  EmpFax?: InputMaybe<Order_By>;
  EmpMobile?: InputMaybe<Order_By>;
  EmpPhoneNo1?: InputMaybe<Order_By>;
  EmpPhoneNo2?: InputMaybe<Order_By>;
  EmpTIN?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  EntryDate?: InputMaybe<Order_By>;
  ExemptAuth?: InputMaybe<Order_By>;
  FName?: InputMaybe<Order_By>;
  FaxNo?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  ImportNo?: InputMaybe<Order_By>;
  IncomeTax?: InputMaybe<Order_By>;
  LName?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  LastDocNum?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastchanged?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MFName?: InputMaybe<Order_By>;
  MLName?: InputMaybe<Order_By>;
  MName?: InputMaybe<Order_By>;
  Marital?: InputMaybe<Order_By>;
  NIN?: InputMaybe<Order_By>;
  NINIncorpCert?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Others?: InputMaybe<Order_By>;
  PassportNo?: InputMaybe<Order_By>;
  PayeeTax?: InputMaybe<Order_By>;
  PayerType?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  PhoneNo2?: InputMaybe<Order_By>;
  PhysArea?: InputMaybe<Order_By>;
  PhysPlotNo?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PhysStreet?: InputMaybe<Order_By>;
  PhysTown?: InputMaybe<Order_By>;
  PostalBox?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  PostalTown?: InputMaybe<Order_By>;
  PrevIncFileNo?: InputMaybe<Order_By>;
  PrevName?: InputMaybe<Order_By>;
  PrevPayeNo?: InputMaybe<Order_By>;
  ReMark?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  RegDate?: InputMaybe<Order_By>;
  RegStatus?: InputMaybe<Order_By>;
  RegTypeCode?: InputMaybe<Order_By>;
  StampDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  TINNotApproval?: InputMaybe<Order_By>;
  TaxPayerID?: InputMaybe<Order_By>;
  TradingName?: InputMaybe<Order_By>;
  TurnOver?: InputMaybe<Order_By>;
  UserID?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export enum Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column {
  /** column name */
  AgAddress = 'AgAddress',
  /** column name */
  AgName = 'AgName',
  /** column name */
  AgPhoneNo = 'AgPhoneNo',
  /** column name */
  AgTin = 'AgTIN',
  /** column name */
  AppCapacity = 'AppCapacity',
  /** column name */
  ApprovedBy = 'ApprovedBy',
  /** column name */
  ApprovedDate = 'ApprovedDate',
  /** column name */
  AuditFlag = 'AuditFlag',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  BlockName = 'BlockName',
  /** column name */
  BusStartDate = 'BusStartDate',
  /** column name */
  BusinessCode = 'BusinessCode',
  /** column name */
  ChkApproved = 'ChkApproved',
  /** column name */
  ChkCitizen = 'ChkCitizen',
  /** column name */
  ChkExempt = 'ChkExempt',
  /** column name */
  ChkIdCard = 'ChkIdCard',
  /** column name */
  ChkNotApproved = 'ChkNotApproved',
  /** column name */
  ChkPassPort = 'ChkPassPort',
  /** column name */
  ChkRegist = 'ChkRegist',
  /** column name */
  ChkcertInCorp = 'ChkcertInCorp',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Din = 'DIN',
  /** column name */
  DecCapacity = 'DecCapacity',
  /** column name */
  Declarant = 'Declarant',
  /** column name */
  DeclareDate = 'DeclareDate',
  /** column name */
  DelDate = 'DelDate',
  /** column name */
  DelFlag = 'DelFlag',
  /** column name */
  Email = 'Email',
  /** column name */
  EmpAddress = 'EmpAddress',
  /** column name */
  EmpEMail = 'EmpEMail',
  /** column name */
  EmpFax = 'EmpFax',
  /** column name */
  EmpMobile = 'EmpMobile',
  /** column name */
  EmpPhoneNo1 = 'EmpPhoneNo1',
  /** column name */
  EmpPhoneNo2 = 'EmpPhoneNo2',
  /** column name */
  EmpTin = 'EmpTIN',
  /** column name */
  EmpType = 'EmpType',
  /** column name */
  EndDate = 'EndDate',
  /** column name */
  Enforce = 'Enforce',
  /** column name */
  EntryDate = 'EntryDate',
  /** column name */
  ExemptAuth = 'ExemptAuth',
  /** column name */
  FName = 'FName',
  /** column name */
  FaxNo = 'FaxNo',
  /** column name */
  File = 'File',
  /** column name */
  ForceReg = 'ForceReg',
  /** column name */
  Gender = 'Gender',
  /** column name */
  ImportNo = 'ImportNo',
  /** column name */
  IncomeTax = 'IncomeTax',
  /** column name */
  LName = 'LName',
  /** column name */
  LargTaxPayer = 'LargTaxPayer',
  /** column name */
  LargeTaxPayerStatus = 'LargeTaxPayerStatus',
  /** column name */
  LastDocNum = 'LastDocNum',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lastchanged = 'Lastchanged',
  /** column name */
  Line = 'Line',
  /** column name */
  MfName = 'MFName',
  /** column name */
  MlName = 'MLName',
  /** column name */
  MName = 'MName',
  /** column name */
  Marital = 'Marital',
  /** column name */
  Nin = 'NIN',
  /** column name */
  NinIncorpCert = 'NINIncorpCert',
  /** column name */
  Officer = 'Officer',
  /** column name */
  Others = 'Others',
  /** column name */
  PassportNo = 'PassportNo',
  /** column name */
  PayeeTax = 'PayeeTax',
  /** column name */
  PayerType = 'PayerType',
  /** column name */
  PhoneNo = 'PhoneNo',
  /** column name */
  PhoneNo2 = 'PhoneNo2',
  /** column name */
  PhysArea = 'PhysArea',
  /** column name */
  PhysPlotNo = 'PhysPlotNo',
  /** column name */
  PhysProv = 'PhysProv',
  /** column name */
  PhysStreet = 'PhysStreet',
  /** column name */
  PhysTown = 'PhysTown',
  /** column name */
  PostalBox = 'PostalBox',
  /** column name */
  PostalProv = 'PostalProv',
  /** column name */
  PostalTown = 'PostalTown',
  /** column name */
  PrevIncFileNo = 'PrevIncFileNo',
  /** column name */
  PrevName = 'PrevName',
  /** column name */
  PrevPayeNo = 'PrevPayeNo',
  /** column name */
  ReMark = 'ReMark',
  /** column name */
  RefusalCode = 'RefusalCode',
  /** column name */
  RegCenterCode = 'RegCenterCode',
  /** column name */
  RegDate = 'RegDate',
  /** column name */
  RegStatus = 'RegStatus',
  /** column name */
  RegTypeCode = 'RegTypeCode',
  /** column name */
  StampDate = 'StampDate',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Tin = 'TIN',
  /** column name */
  TinApproval = 'TINApproval',
  /** column name */
  TinCertCount = 'TINCertCount',
  /** column name */
  TinNotApproval = 'TINNotApproval',
  /** column name */
  TaxPayerId = 'TaxPayerID',
  /** column name */
  TradingName = 'TradingName',
  /** column name */
  TurnOver = 'TurnOver',
  /** column name */
  UserId = 'UserID',
  /** column name */
  VatTurnOver = 'VATTurnOver',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  ChkBirthCert = 'chkBirthCert',
  /** column name */
  ChkEmpExpat = 'chkEmpExpat',
  /** column name */
  ChkEmploy = 'chkEmploy',
  /** column name */
  ChkOtherBranch = 'chkOtherBranch',
  /** column name */
  ChkSpecialTaxPeriod = 'chkSpecialTaxPeriod',
  /** column name */
  ChkTaxExempt = 'chkTaxExempt'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Set_Input = {
  AgAddress?: InputMaybe<Scalars['String']['input']>;
  AgName?: InputMaybe<Scalars['String']['input']>;
  AgPhoneNo?: InputMaybe<Scalars['String']['input']>;
  AgTIN?: InputMaybe<Scalars['String']['input']>;
  AppCapacity?: InputMaybe<Scalars['String']['input']>;
  ApprovedBy?: InputMaybe<Scalars['String']['input']>;
  ApprovedDate?: InputMaybe<Scalars['String']['input']>;
  AuditFlag?: InputMaybe<Scalars['bigint']['input']>;
  BirthDate?: InputMaybe<Scalars['String']['input']>;
  BlockName?: InputMaybe<Scalars['String']['input']>;
  BusStartDate?: InputMaybe<Scalars['String']['input']>;
  BusinessCode?: InputMaybe<Scalars['String']['input']>;
  ChkApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkCitizen?: InputMaybe<Scalars['bigint']['input']>;
  ChkExempt?: InputMaybe<Scalars['bigint']['input']>;
  ChkIdCard?: InputMaybe<Scalars['bigint']['input']>;
  ChkNotApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkPassPort?: InputMaybe<Scalars['bigint']['input']>;
  ChkRegist?: InputMaybe<Scalars['bigint']['input']>;
  ChkcertInCorp?: InputMaybe<Scalars['bigint']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedDate?: InputMaybe<Scalars['String']['input']>;
  DIN?: InputMaybe<Scalars['String']['input']>;
  DecCapacity?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  DeclareDate?: InputMaybe<Scalars['String']['input']>;
  DelDate?: InputMaybe<Scalars['String']['input']>;
  DelFlag?: InputMaybe<Scalars['bigint']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmpAddress?: InputMaybe<Scalars['String']['input']>;
  EmpEMail?: InputMaybe<Scalars['String']['input']>;
  EmpFax?: InputMaybe<Scalars['String']['input']>;
  EmpMobile?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo1?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo2?: InputMaybe<Scalars['String']['input']>;
  EmpTIN?: InputMaybe<Scalars['String']['input']>;
  EmpType?: InputMaybe<Scalars['bigint']['input']>;
  EndDate?: InputMaybe<Scalars['String']['input']>;
  Enforce?: InputMaybe<Scalars['bigint']['input']>;
  EntryDate?: InputMaybe<Scalars['String']['input']>;
  ExemptAuth?: InputMaybe<Scalars['String']['input']>;
  FName?: InputMaybe<Scalars['String']['input']>;
  FaxNo?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  ForceReg?: InputMaybe<Scalars['bigint']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  ImportNo?: InputMaybe<Scalars['String']['input']>;
  IncomeTax?: InputMaybe<Scalars['String']['input']>;
  LName?: InputMaybe<Scalars['String']['input']>;
  LargTaxPayer?: InputMaybe<Scalars['bigint']['input']>;
  LargeTaxPayerStatus?: InputMaybe<Scalars['bigint']['input']>;
  LastDocNum?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastchanged?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MFName?: InputMaybe<Scalars['String']['input']>;
  MLName?: InputMaybe<Scalars['String']['input']>;
  MName?: InputMaybe<Scalars['String']['input']>;
  Marital?: InputMaybe<Scalars['String']['input']>;
  NIN?: InputMaybe<Scalars['String']['input']>;
  NINIncorpCert?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Others?: InputMaybe<Scalars['String']['input']>;
  PassportNo?: InputMaybe<Scalars['String']['input']>;
  PayeeTax?: InputMaybe<Scalars['String']['input']>;
  PayerType?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  PhoneNo2?: InputMaybe<Scalars['String']['input']>;
  PhysArea?: InputMaybe<Scalars['String']['input']>;
  PhysPlotNo?: InputMaybe<Scalars['String']['input']>;
  PhysProv?: InputMaybe<Scalars['bigint']['input']>;
  PhysStreet?: InputMaybe<Scalars['String']['input']>;
  PhysTown?: InputMaybe<Scalars['String']['input']>;
  PostalBox?: InputMaybe<Scalars['String']['input']>;
  PostalProv?: InputMaybe<Scalars['bigint']['input']>;
  PostalTown?: InputMaybe<Scalars['String']['input']>;
  PrevIncFileNo?: InputMaybe<Scalars['String']['input']>;
  PrevName?: InputMaybe<Scalars['String']['input']>;
  PrevPayeNo?: InputMaybe<Scalars['String']['input']>;
  ReMark?: InputMaybe<Scalars['String']['input']>;
  RefusalCode?: InputMaybe<Scalars['float8']['input']>;
  RegCenterCode?: InputMaybe<Scalars['bigint']['input']>;
  RegDate?: InputMaybe<Scalars['String']['input']>;
  RegStatus?: InputMaybe<Scalars['String']['input']>;
  RegTypeCode?: InputMaybe<Scalars['String']['input']>;
  StampDate?: InputMaybe<Scalars['String']['input']>;
  StartDate?: InputMaybe<Scalars['String']['input']>;
  TIN?: InputMaybe<Scalars['bigint']['input']>;
  TINApproval?: InputMaybe<Scalars['bigint']['input']>;
  TINCertCount?: InputMaybe<Scalars['bigint']['input']>;
  TINNotApproval?: InputMaybe<Scalars['String']['input']>;
  TaxPayerID?: InputMaybe<Scalars['String']['input']>;
  TradingName?: InputMaybe<Scalars['String']['input']>;
  TurnOver?: InputMaybe<Scalars['String']['input']>;
  UserID?: InputMaybe<Scalars['String']['input']>;
  VATTurnOver?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  chkBirthCert?: InputMaybe<Scalars['bigint']['input']>;
  chkEmpExpat?: InputMaybe<Scalars['bigint']['input']>;
  chkEmploy?: InputMaybe<Scalars['bigint']['input']>;
  chkOtherBranch?: InputMaybe<Scalars['bigint']['input']>;
  chkSpecialTaxPeriod?: InputMaybe<Scalars['bigint']['input']>;
  chkTaxExempt?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_stddev_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_stddev_pop_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Pop_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_stddev_samp_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Stddev_Samp_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_sum_fields';
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Sum_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_var_pop_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Pop_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_var_samp_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Var_Samp_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_TaxPayer_variance_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_TaxPayer" */
export type Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Variance_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_avg_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Avg_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_VATReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp = {
  Adjustment?: InputMaybe<Float8_Comparison_Exp>;
  Creditbalance?: InputMaybe<Bigint_Comparison_Exp>;
  Domesticpurchase?: InputMaybe<Float8_Comparison_Exp>;
  Domesticrate?: InputMaybe<Bigint_Comparison_Exp>;
  Domesticvatamount?: InputMaybe<Float8_Comparison_Exp>;
  Exemptedimport?: InputMaybe<Float8_Comparison_Exp>;
  Exemptedsales?: InputMaybe<Float8_Comparison_Exp>;
  Exports?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Import?: InputMaybe<Float8_Comparison_Exp>;
  Importrate?: InputMaybe<Bigint_Comparison_Exp>;
  Importvatamount?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Nilreturn?: InputMaybe<Bigint_Comparison_Exp>;
  Otherzerorated?: InputMaybe<Bigint_Comparison_Exp>;
  Purchasetax?: InputMaybe<Float8_Comparison_Exp>;
  Refundclaim?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Taxablerate?: InputMaybe<Bigint_Comparison_Exp>;
  Taxablesales?: InputMaybe<Float8_Comparison_Exp>;
  Taxablevatamount?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Totalpurchase?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Zeroratedimport?: InputMaybe<Bigint_Comparison_Exp>;
  Zeroratedother?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Inc_Input = {
  Adjustment?: InputMaybe<Scalars['float8']['input']>;
  Creditbalance?: InputMaybe<Scalars['bigint']['input']>;
  Domesticpurchase?: InputMaybe<Scalars['float8']['input']>;
  Domesticrate?: InputMaybe<Scalars['bigint']['input']>;
  Domesticvatamount?: InputMaybe<Scalars['float8']['input']>;
  Exemptedimport?: InputMaybe<Scalars['float8']['input']>;
  Exemptedsales?: InputMaybe<Scalars['float8']['input']>;
  Exports?: InputMaybe<Scalars['float8']['input']>;
  Import?: InputMaybe<Scalars['float8']['input']>;
  Importrate?: InputMaybe<Scalars['bigint']['input']>;
  Importvatamount?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nilreturn?: InputMaybe<Scalars['bigint']['input']>;
  Otherzerorated?: InputMaybe<Scalars['bigint']['input']>;
  Purchasetax?: InputMaybe<Scalars['float8']['input']>;
  Refundclaim?: InputMaybe<Scalars['float8']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxablerate?: InputMaybe<Scalars['bigint']['input']>;
  Taxablesales?: InputMaybe<Scalars['float8']['input']>;
  Taxablevatamount?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalpurchase?: InputMaybe<Scalars['float8']['input']>;
  Zeroratedimport?: InputMaybe<Scalars['bigint']['input']>;
  Zeroratedother?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Insert_Input = {
  Adjustment?: InputMaybe<Scalars['float8']['input']>;
  Creditbalance?: InputMaybe<Scalars['bigint']['input']>;
  Domesticpurchase?: InputMaybe<Scalars['float8']['input']>;
  Domesticrate?: InputMaybe<Scalars['bigint']['input']>;
  Domesticvatamount?: InputMaybe<Scalars['float8']['input']>;
  Exemptedimport?: InputMaybe<Scalars['float8']['input']>;
  Exemptedsales?: InputMaybe<Scalars['float8']['input']>;
  Exports?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Import?: InputMaybe<Scalars['float8']['input']>;
  Importrate?: InputMaybe<Scalars['bigint']['input']>;
  Importvatamount?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nilreturn?: InputMaybe<Scalars['bigint']['input']>;
  Otherzerorated?: InputMaybe<Scalars['bigint']['input']>;
  Purchasetax?: InputMaybe<Scalars['float8']['input']>;
  Refundclaim?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxablerate?: InputMaybe<Scalars['bigint']['input']>;
  Taxablesales?: InputMaybe<Scalars['float8']['input']>;
  Taxablevatamount?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalpurchase?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Zeroratedimport?: InputMaybe<Scalars['bigint']['input']>;
  Zeroratedother?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_max_fields';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Max_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_min_fields';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Min_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export enum Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column {
  /** column name */
  Adjustment = 'Adjustment',
  /** column name */
  Creditbalance = 'Creditbalance',
  /** column name */
  Domesticpurchase = 'Domesticpurchase',
  /** column name */
  Domesticrate = 'Domesticrate',
  /** column name */
  Domesticvatamount = 'Domesticvatamount',
  /** column name */
  Exemptedimport = 'Exemptedimport',
  /** column name */
  Exemptedsales = 'Exemptedsales',
  /** column name */
  Exports = 'Exports',
  /** column name */
  File = 'File',
  /** column name */
  Import = 'Import',
  /** column name */
  Importrate = 'Importrate',
  /** column name */
  Importvatamount = 'Importvatamount',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Nilreturn = 'Nilreturn',
  /** column name */
  Otherzerorated = 'Otherzerorated',
  /** column name */
  Purchasetax = 'Purchasetax',
  /** column name */
  Refundclaim = 'Refundclaim',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxablerate = 'Taxablerate',
  /** column name */
  Taxablesales = 'Taxablesales',
  /** column name */
  Taxablevatamount = 'Taxablevatamount',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Totalpurchase = 'Totalpurchase',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Zeroratedimport = 'Zeroratedimport',
  /** column name */
  Zeroratedother = 'Zeroratedother'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Set_Input = {
  Adjustment?: InputMaybe<Scalars['float8']['input']>;
  Creditbalance?: InputMaybe<Scalars['bigint']['input']>;
  Domesticpurchase?: InputMaybe<Scalars['float8']['input']>;
  Domesticrate?: InputMaybe<Scalars['bigint']['input']>;
  Domesticvatamount?: InputMaybe<Scalars['float8']['input']>;
  Exemptedimport?: InputMaybe<Scalars['float8']['input']>;
  Exemptedsales?: InputMaybe<Scalars['float8']['input']>;
  Exports?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Import?: InputMaybe<Scalars['float8']['input']>;
  Importrate?: InputMaybe<Scalars['bigint']['input']>;
  Importvatamount?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nilreturn?: InputMaybe<Scalars['bigint']['input']>;
  Otherzerorated?: InputMaybe<Scalars['bigint']['input']>;
  Purchasetax?: InputMaybe<Scalars['float8']['input']>;
  Refundclaim?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxablerate?: InputMaybe<Scalars['bigint']['input']>;
  Taxablesales?: InputMaybe<Scalars['float8']['input']>;
  Taxablevatamount?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalpurchase?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Zeroratedimport?: InputMaybe<Scalars['bigint']['input']>;
  Zeroratedother?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_stddev_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_stddev_pop_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Pop_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_stddev_samp_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Stddev_Samp_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_sum_fields';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Sum_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_var_pop_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Pop_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_var_samp_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Var_Samp_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_VATReturn_variance_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_VATReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_VatReturn_Variance_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Chequeno?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail';
  Businessaddress?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingid?: Maybe<Scalars['String']['output']>;
  Withholdingname?: Maybe<Scalars['String']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_avg_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Avg_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp = {
  Businessaddress?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Grosstransaction?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Transactiontype?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Withheldamount?: InputMaybe<Float8_Comparison_Exp>;
  Withholdingid?: InputMaybe<String_Comparison_Exp>;
  Withholdingname?: InputMaybe<String_Comparison_Exp>;
  Withholdingrate?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Inc_Input = {
  Grosstransaction?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Transactiontype?: InputMaybe<Scalars['bigint']['input']>;
  Withheldamount?: InputMaybe<Scalars['float8']['input']>;
  Withholdingrate?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Insert_Input = {
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grosstransaction?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Transactiontype?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withheldamount?: InputMaybe<Scalars['float8']['input']>;
  Withholdingid?: InputMaybe<Scalars['String']['input']>;
  Withholdingname?: InputMaybe<Scalars['String']['input']>;
  Withholdingrate?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_max_fields';
  Businessaddress?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingid?: Maybe<Scalars['String']['output']>;
  Withholdingname?: Maybe<Scalars['String']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Max_Order_By = {
  Businessaddress?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grosstransaction?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingid?: InputMaybe<Order_By>;
  Withholdingname?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_min_fields';
  Businessaddress?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingid?: Maybe<Scalars['String']['output']>;
  Withholdingname?: Maybe<Scalars['String']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Min_Order_By = {
  Businessaddress?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grosstransaction?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingid?: InputMaybe<Order_By>;
  Withholdingname?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By = {
  Businessaddress?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grosstransaction?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingid?: InputMaybe<Order_By>;
  Withholdingname?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export enum Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column {
  /** column name */
  Businessaddress = 'Businessaddress',
  /** column name */
  File = 'File',
  /** column name */
  Grosstransaction = 'Grosstransaction',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Transactiontype = 'Transactiontype',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Withheldamount = 'Withheldamount',
  /** column name */
  Withholdingid = 'Withholdingid',
  /** column name */
  Withholdingname = 'Withholdingname',
  /** column name */
  Withholdingrate = 'Withholdingrate'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Set_Input = {
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grosstransaction?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Transactiontype?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withheldamount?: InputMaybe<Scalars['float8']['input']>;
  Withholdingid?: InputMaybe<Scalars['String']['input']>;
  Withholdingname?: InputMaybe<Scalars['String']['input']>;
  Withholdingrate?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_stddev_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_stddev_pop_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Pop_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_stddev_samp_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Stddev_Samp_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_sum_fields';
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Sum_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_var_pop_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Pop_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_var_samp_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Var_Samp_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturnDetail_variance_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Variance_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
};

/** aggregate fields of "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_avg_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Avg_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_gra_WithholdingReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp = {
  Amountpaid?: InputMaybe<Float8_Comparison_Exp>;
  Chequeno?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Transactioncount?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Inc_Input = {
  Amountpaid?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Transactioncount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Insert_Input = {
  Amountpaid?: InputMaybe<Scalars['float8']['input']>;
  Chequeno?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Transactioncount?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_max_fields';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Chequeno?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Max_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Chequeno?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_min_fields';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Chequeno?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Min_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Chequeno?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Chequeno?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export enum Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column {
  /** column name */
  Amountpaid = 'Amountpaid',
  /** column name */
  Chequeno = 'Chequeno',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Transactioncount = 'Transactioncount',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Set_Input = {
  Amountpaid?: InputMaybe<Scalars['float8']['input']>;
  Chequeno?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Transactioncount?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_stddev_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_stddev_pop_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Pop_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_stddev_samp_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Stddev_Samp_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_sum_fields';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Sum_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_var_pop_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Pop_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_var_samp_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Var_Samp_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_gra_WithholdingReturn_variance_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Variance_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations';
  Address?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  Businesstype?: Maybe<Scalars['String']['output']>;
  Dateofregistration?: Maybe<Scalars['timestamptz']['output']>;
  Extractcriteria?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Srntin?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
};

/** aggregate fields of "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Businessname?: InputMaybe<String_Comparison_Exp>;
  Businesstype?: InputMaybe<String_Comparison_Exp>;
  Dateofregistration?: InputMaybe<Timestamptz_Comparison_Exp>;
  Extractcriteria?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Mobile?: InputMaybe<String_Comparison_Exp>;
  Srntin?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  Businesstype?: InputMaybe<Scalars['String']['input']>;
  Dateofregistration?: InputMaybe<Scalars['timestamptz']['input']>;
  Extractcriteria?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Srntin?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  Businesstype?: Maybe<Scalars['String']['output']>;
  Dateofregistration?: Maybe<Scalars['timestamptz']['output']>;
  Extractcriteria?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Srntin?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  Businesstype?: InputMaybe<Order_By>;
  Dateofregistration?: InputMaybe<Order_By>;
  Extractcriteria?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Srntin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  Businesstype?: Maybe<Scalars['String']['output']>;
  Dateofregistration?: Maybe<Scalars['timestamptz']['output']>;
  Extractcriteria?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Srntin?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  Businesstype?: InputMaybe<Order_By>;
  Dateofregistration?: InputMaybe<Order_By>;
  Extractcriteria?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Srntin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By = {
  Address?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  Businesstype?: InputMaybe<Order_By>;
  Dateofregistration?: InputMaybe<Order_By>;
  Extractcriteria?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Srntin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export enum Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Businessname = 'Businessname',
  /** column name */
  Businesstype = 'Businesstype',
  /** column name */
  Dateofregistration = 'Dateofregistration',
  /** column name */
  Extractcriteria = 'Extractcriteria',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Mobile = 'Mobile',
  /** column name */
  Srntin = 'Srntin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  Businesstype?: InputMaybe<Scalars['String']['input']>;
  Dateofregistration?: InputMaybe<Scalars['timestamptz']['input']>;
  Extractcriteria?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Srntin?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Ownername?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Telephoneno?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Ownername?: InputMaybe<String_Comparison_Exp>;
  Propertyno?: InputMaybe<String_Comparison_Exp>;
  Telephoneno?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Ownername?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Telephoneno?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Ownername?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Telephoneno?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Ownername?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Telephoneno?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Ownername?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Telephoneno?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Ownername?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Telephoneno?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Ownername?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Telephoneno?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export enum Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Ownername = 'Ownername',
  /** column name */
  Propertyno = 'Propertyno',
  /** column name */
  Telephoneno = 'Telephoneno',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Ownername?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Telephoneno?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesPayers_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister';
  Address?: Maybe<Scalars['String']['output']>;
  Areaname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Opendate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Subareaname?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Telno?: Maybe<Scalars['String']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Areaname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Opendate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Owner?: InputMaybe<String_Comparison_Exp>;
  Propertyno?: InputMaybe<String_Comparison_Exp>;
  Propertyvalue?: InputMaybe<Float8_Comparison_Exp>;
  Rate1?: InputMaybe<Float8_Comparison_Exp>;
  Rate2?: InputMaybe<Float8_Comparison_Exp>;
  Ratename?: InputMaybe<String_Comparison_Exp>;
  Rentaltax?: InputMaybe<Bigint_Comparison_Exp>;
  Subareaname?: InputMaybe<String_Comparison_Exp>;
  Tax1?: InputMaybe<Float8_Comparison_Exp>;
  Tax2?: InputMaybe<Float8_Comparison_Exp>;
  Telno?: InputMaybe<String_Comparison_Exp>;
  Totaltax?: InputMaybe<Float8_Comparison_Exp>;
  Value1?: InputMaybe<Bigint_Comparison_Exp>;
  Value2?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyvalue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Rentaltax?: InputMaybe<Scalars['bigint']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  Totaltax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Areaname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Opendate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Propertyvalue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  Rentaltax?: InputMaybe<Scalars['bigint']['input']>;
  Subareaname?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  Telno?: InputMaybe<Scalars['String']['input']>;
  Totaltax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Areaname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Opendate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Subareaname?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Telno?: Maybe<Scalars['String']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Areaname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Opendate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Subareaname?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Telno?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Areaname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Opendate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Subareaname?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Telno?: Maybe<Scalars['String']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Areaname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Opendate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Subareaname?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Telno?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By = {
  Address?: InputMaybe<Order_By>;
  Areaname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Opendate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Subareaname?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Telno?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export enum Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Areaname = 'Areaname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Opendate = 'Opendate',
  /** column name */
  Owner = 'Owner',
  /** column name */
  Propertyno = 'Propertyno',
  /** column name */
  Propertyvalue = 'Propertyvalue',
  /** column name */
  Rate1 = 'Rate1',
  /** column name */
  Rate2 = 'Rate2',
  /** column name */
  Ratename = 'Ratename',
  /** column name */
  Rentaltax = 'Rentaltax',
  /** column name */
  Subareaname = 'Subareaname',
  /** column name */
  Tax1 = 'Tax1',
  /** column name */
  Tax2 = 'Tax2',
  /** column name */
  Telno = 'Telno',
  /** column name */
  Totaltax = 'Totaltax',
  /** column name */
  Value1 = 'Value1',
  /** column name */
  Value2 = 'Value2',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Areaname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Opendate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Propertyvalue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  Rentaltax?: InputMaybe<Scalars['bigint']['input']>;
  Subareaname?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  Telno?: InputMaybe<Scalars['String']['input']>;
  Totaltax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_PropertyRatesRegister_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract';
  Address?: Maybe<Scalars['String']['output']>;
  AreaName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OpenDate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  SubAreaName?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TelNo?: Maybe<Scalars['String']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  AreaName?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  OpenDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Owner?: InputMaybe<String_Comparison_Exp>;
  PropertyNo?: InputMaybe<String_Comparison_Exp>;
  PropertyValue?: InputMaybe<Float8_Comparison_Exp>;
  Rate1?: InputMaybe<Float8_Comparison_Exp>;
  Rate2?: InputMaybe<Float8_Comparison_Exp>;
  Ratename?: InputMaybe<String_Comparison_Exp>;
  RentalTax?: InputMaybe<Bigint_Comparison_Exp>;
  SubAreaName?: InputMaybe<String_Comparison_Exp>;
  Tax1?: InputMaybe<Float8_Comparison_Exp>;
  Tax2?: InputMaybe<Float8_Comparison_Exp>;
  TelNo?: InputMaybe<String_Comparison_Exp>;
  TotalTax?: InputMaybe<Float8_Comparison_Exp>;
  Value1?: InputMaybe<Bigint_Comparison_Exp>;
  Value2?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  PropertyValue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  RentalTax?: InputMaybe<Scalars['bigint']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  TotalTax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AreaName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OpenDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  PropertyValue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  RentalTax?: InputMaybe<Scalars['bigint']['input']>;
  SubAreaName?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  TelNo?: InputMaybe<Scalars['String']['input']>;
  TotalTax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AreaName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OpenDate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  SubAreaName?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TelNo?: Maybe<Scalars['String']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  AreaName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OpenDate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  SubAreaName?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TelNo?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AreaName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OpenDate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  SubAreaName?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TelNo?: Maybe<Scalars['String']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  AreaName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OpenDate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  SubAreaName?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TelNo?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By = {
  Address?: InputMaybe<Order_By>;
  AreaName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OpenDate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  SubAreaName?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TelNo?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export enum Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  AreaName = 'AreaName',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  OpenDate = 'OpenDate',
  /** column name */
  Owner = 'Owner',
  /** column name */
  PropertyNo = 'PropertyNo',
  /** column name */
  PropertyValue = 'PropertyValue',
  /** column name */
  Rate1 = 'Rate1',
  /** column name */
  Rate2 = 'Rate2',
  /** column name */
  Ratename = 'Ratename',
  /** column name */
  RentalTax = 'RentalTax',
  /** column name */
  SubAreaName = 'SubAreaName',
  /** column name */
  Tax1 = 'Tax1',
  /** column name */
  Tax2 = 'Tax2',
  /** column name */
  TelNo = 'TelNo',
  /** column name */
  TotalTax = 'TotalTax',
  /** column name */
  Value1 = 'Value1',
  /** column name */
  Value2 = 'Value2',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AreaName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OpenDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  PropertyValue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  RentalTax?: InputMaybe<Scalars['bigint']['input']>;
  SubAreaName?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  TelNo?: InputMaybe<Scalars['String']['input']>;
  TotalTax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData';
  Category?: Maybe<Scalars['String']['output']>;
  DataFieldDescription?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_RatesPropertyData". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp = {
  Category?: InputMaybe<String_Comparison_Exp>;
  DataFieldDescription?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  IsAvailable?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Required?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  SourceFieldName?: InputMaybe<String_Comparison_Exp>;
  SourceTableName?: InputMaybe<String_Comparison_Exp>;
  Subject?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Insert_Input = {
  Category?: InputMaybe<Scalars['String']['input']>;
  DataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_max_fields';
  Category?: Maybe<Scalars['String']['output']>;
  DataFieldDescription?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Max_Order_By = {
  Category?: InputMaybe<Order_By>;
  DataFieldDescription?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_min_fields';
  Category?: Maybe<Scalars['String']['output']>;
  DataFieldDescription?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Min_Order_By = {
  Category?: InputMaybe<Order_By>;
  DataFieldDescription?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By = {
  Category?: InputMaybe<Order_By>;
  DataFieldDescription?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export enum Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column {
  /** column name */
  Category = 'Category',
  /** column name */
  DataFieldDescription = 'DataFieldDescription',
  /** column name */
  File = 'File',
  /** column name */
  IsAvailable = 'IsAvailable',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Required = 'Required',
  /** column name */
  Source = 'Source',
  /** column name */
  SourceFieldName = 'SourceFieldName',
  /** column name */
  SourceTableName = 'SourceTableName',
  /** column name */
  Subject = 'Subject',
  /** column name */
  Type = 'Type',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Set_Input = {
  Category?: InputMaybe<Scalars['String']['input']>;
  DataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesPropertyData_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OwnerName?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  TelephoneNo?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_RatesReceiptsData". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  OwnerName?: InputMaybe<String_Comparison_Exp>;
  PropertyNo?: InputMaybe<String_Comparison_Exp>;
  TelephoneNo?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OwnerName?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  TelephoneNo?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OwnerName?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  TelephoneNo?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OwnerName?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  TelephoneNo?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OwnerName?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  TelephoneNo?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OwnerName?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  TelephoneNo?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OwnerName?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  TelephoneNo?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export enum Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  OwnerName = 'OwnerName',
  /** column name */
  PropertyNo = 'PropertyNo',
  /** column name */
  TelephoneNo = 'TelephoneNo',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OwnerName?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  TelephoneNo?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_RatesReceiptsData_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense';
  Address?: Maybe<Scalars['String']['output']>;
  ClassCategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusinessDataFieldDescription?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister';
  Address?: Maybe<Scalars['String']['output']>;
  Classcategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nameofbusinessdatafielddescription?: Maybe<Scalars['String']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Classcategory?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Nameofbusinessdatafielddescription?: InputMaybe<String_Comparison_Exp>;
  Phoneno?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  Subject?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Classcategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nameofbusinessdatafielddescription?: InputMaybe<Scalars['String']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Classcategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nameofbusinessdatafielddescription?: Maybe<Scalars['String']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Classcategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nameofbusinessdatafielddescription?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Classcategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nameofbusinessdatafielddescription?: Maybe<Scalars['String']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Classcategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nameofbusinessdatafielddescription?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By = {
  Address?: InputMaybe<Order_By>;
  Classcategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nameofbusinessdatafielddescription?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export enum Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Classcategory = 'Classcategory',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Nameofbusinessdatafielddescription = 'Nameofbusinessdatafielddescription',
  /** column name */
  Phoneno = 'Phoneno',
  /** column name */
  Source = 'Source',
  /** column name */
  Subject = 'Subject',
  /** column name */
  Type = 'Type',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Classcategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nameofbusinessdatafielddescription?: InputMaybe<Scalars['String']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicenseRegister_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
};

/** aggregate fields of "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_kmc_TradeLicense". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  ClassCategory?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  IsAvailable?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  NameOfBusinessDataFieldDescription?: InputMaybe<String_Comparison_Exp>;
  PhoneNo?: InputMaybe<String_Comparison_Exp>;
  Required?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  SourceFieldName?: InputMaybe<String_Comparison_Exp>;
  SourceTableName?: InputMaybe<String_Comparison_Exp>;
  Subject?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  ClassCategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  ClassCategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusinessDataFieldDescription?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  ClassCategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  ClassCategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusinessDataFieldDescription?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  ClassCategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By = {
  Address?: InputMaybe<Order_By>;
  ClassCategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export enum Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  ClassCategory = 'ClassCategory',
  /** column name */
  File = 'File',
  /** column name */
  IsAvailable = 'IsAvailable',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  NameOfBusinessDataFieldDescription = 'NameOfBusinessDataFieldDescription',
  /** column name */
  PhoneNo = 'PhoneNo',
  /** column name */
  Required = 'Required',
  /** column name */
  Source = 'Source',
  /** column name */
  SourceFieldName = 'SourceFieldName',
  /** column name */
  SourceTableName = 'SourceTableName',
  /** column name */
  Subject = 'Subject',
  /** column name */
  Type = 'Type',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  ClassCategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_kmc_TradeLicense_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers';
  AccountBalance?: Maybe<Scalars['String']['output']>;
  AccountBranch?: Maybe<Scalars['String']['output']>;
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  AccountNoSector?: Maybe<Scalars['String']['output']>;
  AccountRegion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  AddressReference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  CollectionFormType?: Maybe<Scalars['String']['output']>;
  CommercialOffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  ContractAddress?: Maybe<Scalars['String']['output']>;
  ContractReference?: Maybe<Scalars['String']['output']>;
  ContractStatus?: Maybe<Scalars['String']['output']>;
  CreditBalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  DocumentNo?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MeterMake?: Maybe<Scalars['String']['output']>;
  MeterNumber?: Maybe<Scalars['String']['output']>;
  MeterType?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  PoleNumber?: Maybe<Scalars['String']['output']>;
  PremiseType?: Maybe<Scalars['String']['output']>;
  RemoteControl?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  ServiceRegistrationDate?: Maybe<Scalars['String']['output']>;
  ServiceTerminationDate?: Maybe<Scalars['String']['output']>;
  ServicerateType?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SupplyType?: Maybe<Scalars['String']['output']>;
  SupplyZone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
};

/** aggregate fields of "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_avg_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Avg_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_nawec_AllCustomers". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp = {
  AccountBalance?: InputMaybe<String_Comparison_Exp>;
  AccountBranch?: InputMaybe<String_Comparison_Exp>;
  AccountNo?: InputMaybe<Bigint_Comparison_Exp>;
  AccountNoSector?: InputMaybe<String_Comparison_Exp>;
  AccountRegion?: InputMaybe<String_Comparison_Exp>;
  Activity?: InputMaybe<String_Comparison_Exp>;
  AddressReference?: InputMaybe<String_Comparison_Exp>;
  Amr?: InputMaybe<String_Comparison_Exp>;
  CollectionFormType?: InputMaybe<String_Comparison_Exp>;
  CommercialOffice?: InputMaybe<String_Comparison_Exp>;
  Company?: InputMaybe<String_Comparison_Exp>;
  ContractAddress?: InputMaybe<String_Comparison_Exp>;
  ContractReference?: InputMaybe<String_Comparison_Exp>;
  ContractStatus?: InputMaybe<String_Comparison_Exp>;
  CreditBalance?: InputMaybe<String_Comparison_Exp>;
  Customer?: InputMaybe<String_Comparison_Exp>;
  CustomerType?: InputMaybe<String_Comparison_Exp>;
  DocumentNo?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  GroupCode?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  MeterMake?: InputMaybe<String_Comparison_Exp>;
  MeterNumber?: InputMaybe<String_Comparison_Exp>;
  MeterType?: InputMaybe<String_Comparison_Exp>;
  Multiplier?: InputMaybe<Bigint_Comparison_Exp>;
  Niss?: InputMaybe<String_Comparison_Exp>;
  Phase?: InputMaybe<String_Comparison_Exp>;
  PoleNumber?: InputMaybe<String_Comparison_Exp>;
  PremiseType?: InputMaybe<String_Comparison_Exp>;
  RemoteControl?: InputMaybe<String_Comparison_Exp>;
  Sector?: InputMaybe<String_Comparison_Exp>;
  Segment?: InputMaybe<String_Comparison_Exp>;
  ServiceRegistrationDate?: InputMaybe<String_Comparison_Exp>;
  ServiceTerminationDate?: InputMaybe<String_Comparison_Exp>;
  ServicerateType?: InputMaybe<String_Comparison_Exp>;
  Sms?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  SupplyType?: InputMaybe<String_Comparison_Exp>;
  SupplyZone?: InputMaybe<String_Comparison_Exp>;
  Tariff?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Inc_Input = {
  AccountNo?: InputMaybe<Scalars['bigint']['input']>;
  GroupCode?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Insert_Input = {
  AccountBalance?: InputMaybe<Scalars['String']['input']>;
  AccountBranch?: InputMaybe<Scalars['String']['input']>;
  AccountNo?: InputMaybe<Scalars['bigint']['input']>;
  AccountNoSector?: InputMaybe<Scalars['String']['input']>;
  AccountRegion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  AddressReference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  CollectionFormType?: InputMaybe<Scalars['String']['input']>;
  CommercialOffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  ContractAddress?: InputMaybe<Scalars['String']['input']>;
  ContractReference?: InputMaybe<Scalars['String']['input']>;
  ContractStatus?: InputMaybe<Scalars['String']['input']>;
  CreditBalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  CustomerType?: InputMaybe<Scalars['String']['input']>;
  DocumentNo?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  GroupCode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MeterMake?: InputMaybe<Scalars['String']['input']>;
  MeterNumber?: InputMaybe<Scalars['String']['input']>;
  MeterType?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  PoleNumber?: InputMaybe<Scalars['String']['input']>;
  PremiseType?: InputMaybe<Scalars['String']['input']>;
  RemoteControl?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  ServiceRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  ServiceTerminationDate?: InputMaybe<Scalars['String']['input']>;
  ServicerateType?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SupplyType?: InputMaybe<Scalars['String']['input']>;
  SupplyZone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_max_fields';
  AccountBalance?: Maybe<Scalars['String']['output']>;
  AccountBranch?: Maybe<Scalars['String']['output']>;
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  AccountNoSector?: Maybe<Scalars['String']['output']>;
  AccountRegion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  AddressReference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  CollectionFormType?: Maybe<Scalars['String']['output']>;
  CommercialOffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  ContractAddress?: Maybe<Scalars['String']['output']>;
  ContractReference?: Maybe<Scalars['String']['output']>;
  ContractStatus?: Maybe<Scalars['String']['output']>;
  CreditBalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  DocumentNo?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MeterMake?: Maybe<Scalars['String']['output']>;
  MeterNumber?: Maybe<Scalars['String']['output']>;
  MeterType?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  PoleNumber?: Maybe<Scalars['String']['output']>;
  PremiseType?: Maybe<Scalars['String']['output']>;
  RemoteControl?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  ServiceRegistrationDate?: Maybe<Scalars['String']['output']>;
  ServiceTerminationDate?: Maybe<Scalars['String']['output']>;
  ServicerateType?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SupplyType?: Maybe<Scalars['String']['output']>;
  SupplyZone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Max_Order_By = {
  AccountBalance?: InputMaybe<Order_By>;
  AccountBranch?: InputMaybe<Order_By>;
  AccountNo?: InputMaybe<Order_By>;
  AccountNoSector?: InputMaybe<Order_By>;
  AccountRegion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  AddressReference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  CollectionFormType?: InputMaybe<Order_By>;
  CommercialOffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  ContractAddress?: InputMaybe<Order_By>;
  ContractReference?: InputMaybe<Order_By>;
  ContractStatus?: InputMaybe<Order_By>;
  CreditBalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  DocumentNo?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MeterMake?: InputMaybe<Order_By>;
  MeterNumber?: InputMaybe<Order_By>;
  MeterType?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  PoleNumber?: InputMaybe<Order_By>;
  PremiseType?: InputMaybe<Order_By>;
  RemoteControl?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  ServiceRegistrationDate?: InputMaybe<Order_By>;
  ServiceTerminationDate?: InputMaybe<Order_By>;
  ServicerateType?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SupplyType?: InputMaybe<Order_By>;
  SupplyZone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_min_fields';
  AccountBalance?: Maybe<Scalars['String']['output']>;
  AccountBranch?: Maybe<Scalars['String']['output']>;
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  AccountNoSector?: Maybe<Scalars['String']['output']>;
  AccountRegion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  AddressReference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  CollectionFormType?: Maybe<Scalars['String']['output']>;
  CommercialOffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  ContractAddress?: Maybe<Scalars['String']['output']>;
  ContractReference?: Maybe<Scalars['String']['output']>;
  ContractStatus?: Maybe<Scalars['String']['output']>;
  CreditBalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  DocumentNo?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MeterMake?: Maybe<Scalars['String']['output']>;
  MeterNumber?: Maybe<Scalars['String']['output']>;
  MeterType?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  PoleNumber?: Maybe<Scalars['String']['output']>;
  PremiseType?: Maybe<Scalars['String']['output']>;
  RemoteControl?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  ServiceRegistrationDate?: Maybe<Scalars['String']['output']>;
  ServiceTerminationDate?: Maybe<Scalars['String']['output']>;
  ServicerateType?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SupplyType?: Maybe<Scalars['String']['output']>;
  SupplyZone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Min_Order_By = {
  AccountBalance?: InputMaybe<Order_By>;
  AccountBranch?: InputMaybe<Order_By>;
  AccountNo?: InputMaybe<Order_By>;
  AccountNoSector?: InputMaybe<Order_By>;
  AccountRegion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  AddressReference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  CollectionFormType?: InputMaybe<Order_By>;
  CommercialOffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  ContractAddress?: InputMaybe<Order_By>;
  ContractReference?: InputMaybe<Order_By>;
  ContractStatus?: InputMaybe<Order_By>;
  CreditBalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  DocumentNo?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MeterMake?: InputMaybe<Order_By>;
  MeterNumber?: InputMaybe<Order_By>;
  MeterType?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  PoleNumber?: InputMaybe<Order_By>;
  PremiseType?: InputMaybe<Order_By>;
  RemoteControl?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  ServiceRegistrationDate?: InputMaybe<Order_By>;
  ServiceTerminationDate?: InputMaybe<Order_By>;
  ServicerateType?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SupplyType?: InputMaybe<Order_By>;
  SupplyZone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By = {
  AccountBalance?: InputMaybe<Order_By>;
  AccountBranch?: InputMaybe<Order_By>;
  AccountNo?: InputMaybe<Order_By>;
  AccountNoSector?: InputMaybe<Order_By>;
  AccountRegion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  AddressReference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  CollectionFormType?: InputMaybe<Order_By>;
  CommercialOffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  ContractAddress?: InputMaybe<Order_By>;
  ContractReference?: InputMaybe<Order_By>;
  ContractStatus?: InputMaybe<Order_By>;
  CreditBalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  DocumentNo?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MeterMake?: InputMaybe<Order_By>;
  MeterNumber?: InputMaybe<Order_By>;
  MeterType?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  PoleNumber?: InputMaybe<Order_By>;
  PremiseType?: InputMaybe<Order_By>;
  RemoteControl?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  ServiceRegistrationDate?: InputMaybe<Order_By>;
  ServiceTerminationDate?: InputMaybe<Order_By>;
  ServicerateType?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SupplyType?: InputMaybe<Order_By>;
  SupplyZone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export enum Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column {
  /** column name */
  AccountBalance = 'AccountBalance',
  /** column name */
  AccountBranch = 'AccountBranch',
  /** column name */
  AccountNo = 'AccountNo',
  /** column name */
  AccountNoSector = 'AccountNoSector',
  /** column name */
  AccountRegion = 'AccountRegion',
  /** column name */
  Activity = 'Activity',
  /** column name */
  AddressReference = 'AddressReference',
  /** column name */
  Amr = 'Amr',
  /** column name */
  CollectionFormType = 'CollectionFormType',
  /** column name */
  CommercialOffice = 'CommercialOffice',
  /** column name */
  Company = 'Company',
  /** column name */
  ContractAddress = 'ContractAddress',
  /** column name */
  ContractReference = 'ContractReference',
  /** column name */
  ContractStatus = 'ContractStatus',
  /** column name */
  CreditBalance = 'CreditBalance',
  /** column name */
  Customer = 'Customer',
  /** column name */
  CustomerType = 'CustomerType',
  /** column name */
  DocumentNo = 'DocumentNo',
  /** column name */
  Email = 'Email',
  /** column name */
  File = 'File',
  /** column name */
  GroupCode = 'GroupCode',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  MeterMake = 'MeterMake',
  /** column name */
  MeterNumber = 'MeterNumber',
  /** column name */
  MeterType = 'MeterType',
  /** column name */
  Multiplier = 'Multiplier',
  /** column name */
  Niss = 'Niss',
  /** column name */
  Phase = 'Phase',
  /** column name */
  PoleNumber = 'PoleNumber',
  /** column name */
  PremiseType = 'PremiseType',
  /** column name */
  RemoteControl = 'RemoteControl',
  /** column name */
  Sector = 'Sector',
  /** column name */
  Segment = 'Segment',
  /** column name */
  ServiceRegistrationDate = 'ServiceRegistrationDate',
  /** column name */
  ServiceTerminationDate = 'ServiceTerminationDate',
  /** column name */
  ServicerateType = 'ServicerateType',
  /** column name */
  Sms = 'Sms',
  /** column name */
  Source = 'Source',
  /** column name */
  SupplyType = 'SupplyType',
  /** column name */
  SupplyZone = 'SupplyZone',
  /** column name */
  Tariff = 'Tariff',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Set_Input = {
  AccountBalance?: InputMaybe<Scalars['String']['input']>;
  AccountBranch?: InputMaybe<Scalars['String']['input']>;
  AccountNo?: InputMaybe<Scalars['bigint']['input']>;
  AccountNoSector?: InputMaybe<Scalars['String']['input']>;
  AccountRegion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  AddressReference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  CollectionFormType?: InputMaybe<Scalars['String']['input']>;
  CommercialOffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  ContractAddress?: InputMaybe<Scalars['String']['input']>;
  ContractReference?: InputMaybe<Scalars['String']['input']>;
  ContractStatus?: InputMaybe<Scalars['String']['input']>;
  CreditBalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  CustomerType?: InputMaybe<Scalars['String']['input']>;
  DocumentNo?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  GroupCode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MeterMake?: InputMaybe<Scalars['String']['input']>;
  MeterNumber?: InputMaybe<Scalars['String']['input']>;
  MeterType?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  PoleNumber?: InputMaybe<Scalars['String']['input']>;
  PremiseType?: InputMaybe<Scalars['String']['input']>;
  RemoteControl?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  ServiceRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  ServiceTerminationDate?: InputMaybe<Scalars['String']['input']>;
  ServicerateType?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SupplyType?: InputMaybe<Scalars['String']['input']>;
  SupplyZone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_stddev_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_stddev_pop_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Pop_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_stddev_samp_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Stddev_Samp_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_sum_fields';
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Sum_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_var_pop_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Pop_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_var_samp_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Var_Samp_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_AllCustomers_variance_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Variance_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers';
  Accountbalance?: Maybe<Scalars['String']['output']>;
  Accountbranch?: Maybe<Scalars['String']['output']>;
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Accountnosector?: Maybe<Scalars['String']['output']>;
  Accountregion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  Addressreference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  Collectionformtype?: Maybe<Scalars['String']['output']>;
  Commercialoffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  Contractaddress?: Maybe<Scalars['String']['output']>;
  Contractreference?: Maybe<Scalars['String']['output']>;
  Contractstatus?: Maybe<Scalars['String']['output']>;
  Creditbalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  Customertype?: Maybe<Scalars['String']['output']>;
  Documentno?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Metermake?: Maybe<Scalars['String']['output']>;
  Meternumber?: Maybe<Scalars['String']['output']>;
  Metertype?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  Polenumber?: Maybe<Scalars['String']['output']>;
  Premisetype?: Maybe<Scalars['String']['output']>;
  Remotecontrol?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  Serviceratetype?: Maybe<Scalars['String']['output']>;
  Serviceregistrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Serviceterminationdate?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Supplytype?: Maybe<Scalars['String']['output']>;
  Supplyzone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
};

/** aggregate fields of "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_avg_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Avg_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp = {
  Accountbalance?: InputMaybe<String_Comparison_Exp>;
  Accountbranch?: InputMaybe<String_Comparison_Exp>;
  Accountno?: InputMaybe<Bigint_Comparison_Exp>;
  Accountnosector?: InputMaybe<String_Comparison_Exp>;
  Accountregion?: InputMaybe<String_Comparison_Exp>;
  Activity?: InputMaybe<String_Comparison_Exp>;
  Addressreference?: InputMaybe<String_Comparison_Exp>;
  Amr?: InputMaybe<String_Comparison_Exp>;
  Collectionformtype?: InputMaybe<String_Comparison_Exp>;
  Commercialoffice?: InputMaybe<String_Comparison_Exp>;
  Company?: InputMaybe<String_Comparison_Exp>;
  Contractaddress?: InputMaybe<String_Comparison_Exp>;
  Contractreference?: InputMaybe<String_Comparison_Exp>;
  Contractstatus?: InputMaybe<String_Comparison_Exp>;
  Creditbalance?: InputMaybe<String_Comparison_Exp>;
  Customer?: InputMaybe<String_Comparison_Exp>;
  Customertype?: InputMaybe<String_Comparison_Exp>;
  Documentno?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Groupcode?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Metermake?: InputMaybe<String_Comparison_Exp>;
  Meternumber?: InputMaybe<String_Comparison_Exp>;
  Metertype?: InputMaybe<String_Comparison_Exp>;
  Multiplier?: InputMaybe<Bigint_Comparison_Exp>;
  Niss?: InputMaybe<String_Comparison_Exp>;
  Phase?: InputMaybe<String_Comparison_Exp>;
  Polenumber?: InputMaybe<String_Comparison_Exp>;
  Premisetype?: InputMaybe<String_Comparison_Exp>;
  Remotecontrol?: InputMaybe<String_Comparison_Exp>;
  Sector?: InputMaybe<String_Comparison_Exp>;
  Segment?: InputMaybe<String_Comparison_Exp>;
  Serviceratetype?: InputMaybe<String_Comparison_Exp>;
  Serviceregistrationdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Serviceterminationdate?: InputMaybe<String_Comparison_Exp>;
  Sms?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  Supplytype?: InputMaybe<String_Comparison_Exp>;
  Supplyzone?: InputMaybe<String_Comparison_Exp>;
  Tariff?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Inc_Input = {
  Accountno?: InputMaybe<Scalars['bigint']['input']>;
  Groupcode?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Insert_Input = {
  Accountbalance?: InputMaybe<Scalars['String']['input']>;
  Accountbranch?: InputMaybe<Scalars['String']['input']>;
  Accountno?: InputMaybe<Scalars['bigint']['input']>;
  Accountnosector?: InputMaybe<Scalars['String']['input']>;
  Accountregion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  Addressreference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  Collectionformtype?: InputMaybe<Scalars['String']['input']>;
  Commercialoffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  Contractaddress?: InputMaybe<Scalars['String']['input']>;
  Contractreference?: InputMaybe<Scalars['String']['input']>;
  Contractstatus?: InputMaybe<Scalars['String']['input']>;
  Creditbalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  Customertype?: InputMaybe<Scalars['String']['input']>;
  Documentno?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Groupcode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Metermake?: InputMaybe<Scalars['String']['input']>;
  Meternumber?: InputMaybe<Scalars['String']['input']>;
  Metertype?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  Polenumber?: InputMaybe<Scalars['String']['input']>;
  Premisetype?: InputMaybe<Scalars['String']['input']>;
  Remotecontrol?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  Serviceratetype?: InputMaybe<Scalars['String']['input']>;
  Serviceregistrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Serviceterminationdate?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Supplytype?: InputMaybe<Scalars['String']['input']>;
  Supplyzone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_max_fields';
  Accountbalance?: Maybe<Scalars['String']['output']>;
  Accountbranch?: Maybe<Scalars['String']['output']>;
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Accountnosector?: Maybe<Scalars['String']['output']>;
  Accountregion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  Addressreference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  Collectionformtype?: Maybe<Scalars['String']['output']>;
  Commercialoffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  Contractaddress?: Maybe<Scalars['String']['output']>;
  Contractreference?: Maybe<Scalars['String']['output']>;
  Contractstatus?: Maybe<Scalars['String']['output']>;
  Creditbalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  Customertype?: Maybe<Scalars['String']['output']>;
  Documentno?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Metermake?: Maybe<Scalars['String']['output']>;
  Meternumber?: Maybe<Scalars['String']['output']>;
  Metertype?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  Polenumber?: Maybe<Scalars['String']['output']>;
  Premisetype?: Maybe<Scalars['String']['output']>;
  Remotecontrol?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  Serviceratetype?: Maybe<Scalars['String']['output']>;
  Serviceregistrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Serviceterminationdate?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Supplytype?: Maybe<Scalars['String']['output']>;
  Supplyzone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Max_Order_By = {
  Accountbalance?: InputMaybe<Order_By>;
  Accountbranch?: InputMaybe<Order_By>;
  Accountno?: InputMaybe<Order_By>;
  Accountnosector?: InputMaybe<Order_By>;
  Accountregion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  Addressreference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  Collectionformtype?: InputMaybe<Order_By>;
  Commercialoffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  Contractaddress?: InputMaybe<Order_By>;
  Contractreference?: InputMaybe<Order_By>;
  Contractstatus?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  Customertype?: InputMaybe<Order_By>;
  Documentno?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Metermake?: InputMaybe<Order_By>;
  Meternumber?: InputMaybe<Order_By>;
  Metertype?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  Polenumber?: InputMaybe<Order_By>;
  Premisetype?: InputMaybe<Order_By>;
  Remotecontrol?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  Serviceratetype?: InputMaybe<Order_By>;
  Serviceregistrationdate?: InputMaybe<Order_By>;
  Serviceterminationdate?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Supplytype?: InputMaybe<Order_By>;
  Supplyzone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_min_fields';
  Accountbalance?: Maybe<Scalars['String']['output']>;
  Accountbranch?: Maybe<Scalars['String']['output']>;
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Accountnosector?: Maybe<Scalars['String']['output']>;
  Accountregion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  Addressreference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  Collectionformtype?: Maybe<Scalars['String']['output']>;
  Commercialoffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  Contractaddress?: Maybe<Scalars['String']['output']>;
  Contractreference?: Maybe<Scalars['String']['output']>;
  Contractstatus?: Maybe<Scalars['String']['output']>;
  Creditbalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  Customertype?: Maybe<Scalars['String']['output']>;
  Documentno?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Metermake?: Maybe<Scalars['String']['output']>;
  Meternumber?: Maybe<Scalars['String']['output']>;
  Metertype?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  Polenumber?: Maybe<Scalars['String']['output']>;
  Premisetype?: Maybe<Scalars['String']['output']>;
  Remotecontrol?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  Serviceratetype?: Maybe<Scalars['String']['output']>;
  Serviceregistrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Serviceterminationdate?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Supplytype?: Maybe<Scalars['String']['output']>;
  Supplyzone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Min_Order_By = {
  Accountbalance?: InputMaybe<Order_By>;
  Accountbranch?: InputMaybe<Order_By>;
  Accountno?: InputMaybe<Order_By>;
  Accountnosector?: InputMaybe<Order_By>;
  Accountregion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  Addressreference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  Collectionformtype?: InputMaybe<Order_By>;
  Commercialoffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  Contractaddress?: InputMaybe<Order_By>;
  Contractreference?: InputMaybe<Order_By>;
  Contractstatus?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  Customertype?: InputMaybe<Order_By>;
  Documentno?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Metermake?: InputMaybe<Order_By>;
  Meternumber?: InputMaybe<Order_By>;
  Metertype?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  Polenumber?: InputMaybe<Order_By>;
  Premisetype?: InputMaybe<Order_By>;
  Remotecontrol?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  Serviceratetype?: InputMaybe<Order_By>;
  Serviceregistrationdate?: InputMaybe<Order_By>;
  Serviceterminationdate?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Supplytype?: InputMaybe<Order_By>;
  Supplyzone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By = {
  Accountbalance?: InputMaybe<Order_By>;
  Accountbranch?: InputMaybe<Order_By>;
  Accountno?: InputMaybe<Order_By>;
  Accountnosector?: InputMaybe<Order_By>;
  Accountregion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  Addressreference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  Collectionformtype?: InputMaybe<Order_By>;
  Commercialoffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  Contractaddress?: InputMaybe<Order_By>;
  Contractreference?: InputMaybe<Order_By>;
  Contractstatus?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  Customertype?: InputMaybe<Order_By>;
  Documentno?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Metermake?: InputMaybe<Order_By>;
  Meternumber?: InputMaybe<Order_By>;
  Metertype?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  Polenumber?: InputMaybe<Order_By>;
  Premisetype?: InputMaybe<Order_By>;
  Remotecontrol?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  Serviceratetype?: InputMaybe<Order_By>;
  Serviceregistrationdate?: InputMaybe<Order_By>;
  Serviceterminationdate?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Supplytype?: InputMaybe<Order_By>;
  Supplyzone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export enum Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column {
  /** column name */
  Accountbalance = 'Accountbalance',
  /** column name */
  Accountbranch = 'Accountbranch',
  /** column name */
  Accountno = 'Accountno',
  /** column name */
  Accountnosector = 'Accountnosector',
  /** column name */
  Accountregion = 'Accountregion',
  /** column name */
  Activity = 'Activity',
  /** column name */
  Addressreference = 'Addressreference',
  /** column name */
  Amr = 'Amr',
  /** column name */
  Collectionformtype = 'Collectionformtype',
  /** column name */
  Commercialoffice = 'Commercialoffice',
  /** column name */
  Company = 'Company',
  /** column name */
  Contractaddress = 'Contractaddress',
  /** column name */
  Contractreference = 'Contractreference',
  /** column name */
  Contractstatus = 'Contractstatus',
  /** column name */
  Creditbalance = 'Creditbalance',
  /** column name */
  Customer = 'Customer',
  /** column name */
  Customertype = 'Customertype',
  /** column name */
  Documentno = 'Documentno',
  /** column name */
  Email = 'Email',
  /** column name */
  File = 'File',
  /** column name */
  Groupcode = 'Groupcode',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Metermake = 'Metermake',
  /** column name */
  Meternumber = 'Meternumber',
  /** column name */
  Metertype = 'Metertype',
  /** column name */
  Multiplier = 'Multiplier',
  /** column name */
  Niss = 'Niss',
  /** column name */
  Phase = 'Phase',
  /** column name */
  Polenumber = 'Polenumber',
  /** column name */
  Premisetype = 'Premisetype',
  /** column name */
  Remotecontrol = 'Remotecontrol',
  /** column name */
  Sector = 'Sector',
  /** column name */
  Segment = 'Segment',
  /** column name */
  Serviceratetype = 'Serviceratetype',
  /** column name */
  Serviceregistrationdate = 'Serviceregistrationdate',
  /** column name */
  Serviceterminationdate = 'Serviceterminationdate',
  /** column name */
  Sms = 'Sms',
  /** column name */
  Source = 'Source',
  /** column name */
  Supplytype = 'Supplytype',
  /** column name */
  Supplyzone = 'Supplyzone',
  /** column name */
  Tariff = 'Tariff',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Set_Input = {
  Accountbalance?: InputMaybe<Scalars['String']['input']>;
  Accountbranch?: InputMaybe<Scalars['String']['input']>;
  Accountno?: InputMaybe<Scalars['bigint']['input']>;
  Accountnosector?: InputMaybe<Scalars['String']['input']>;
  Accountregion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  Addressreference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  Collectionformtype?: InputMaybe<Scalars['String']['input']>;
  Commercialoffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  Contractaddress?: InputMaybe<Scalars['String']['input']>;
  Contractreference?: InputMaybe<Scalars['String']['input']>;
  Contractstatus?: InputMaybe<Scalars['String']['input']>;
  Creditbalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  Customertype?: InputMaybe<Scalars['String']['input']>;
  Documentno?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Groupcode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Metermake?: InputMaybe<Scalars['String']['input']>;
  Meternumber?: InputMaybe<Scalars['String']['input']>;
  Metertype?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  Polenumber?: InputMaybe<Scalars['String']['input']>;
  Premisetype?: InputMaybe<Scalars['String']['input']>;
  Remotecontrol?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  Serviceratetype?: InputMaybe<Scalars['String']['input']>;
  Serviceregistrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Serviceterminationdate?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Supplytype?: InputMaybe<Scalars['String']['input']>;
  Supplyzone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_stddev_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_stddev_pop_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_stddev_samp_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_sum_fields';
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Sum_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_var_pop_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Pop_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_var_samp_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Var_Samp_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_variance_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Variance_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations';
  Carrier?: Maybe<Scalars['String']['output']>;
  Dob?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  Idno?: Maybe<Scalars['String']['output']>;
  Idtype?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Registrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
};

/** aggregate fields of "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp = {
  Carrier?: InputMaybe<String_Comparison_Exp>;
  Dob?: InputMaybe<Timestamptz_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Gender?: InputMaybe<String_Comparison_Exp>;
  Idno?: InputMaybe<String_Comparison_Exp>;
  Idtype?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lastname?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Mobile?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Registrationdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Insert_Input = {
  Carrier?: InputMaybe<Scalars['String']['input']>;
  Dob?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  Idno?: InputMaybe<Scalars['String']['input']>;
  Idtype?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Registrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_max_fields';
  Carrier?: Maybe<Scalars['String']['output']>;
  Dob?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  Idno?: Maybe<Scalars['String']['output']>;
  Idtype?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Registrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Max_Order_By = {
  Carrier?: InputMaybe<Order_By>;
  Dob?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  Idno?: InputMaybe<Order_By>;
  Idtype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Registrationdate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_min_fields';
  Carrier?: Maybe<Scalars['String']['output']>;
  Dob?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  Idno?: Maybe<Scalars['String']['output']>;
  Idtype?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Registrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Min_Order_By = {
  Carrier?: InputMaybe<Order_By>;
  Dob?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  Idno?: InputMaybe<Order_By>;
  Idtype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Registrationdate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By = {
  Carrier?: InputMaybe<Order_By>;
  Dob?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  Idno?: InputMaybe<Order_By>;
  Idtype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Registrationdate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export enum Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column {
  /** column name */
  Carrier = 'Carrier',
  /** column name */
  Dob = 'Dob',
  /** column name */
  File = 'File',
  /** column name */
  Gender = 'Gender',
  /** column name */
  Idno = 'Idno',
  /** column name */
  Idtype = 'Idtype',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lastname = 'Lastname',
  /** column name */
  Line = 'Line',
  /** column name */
  Mobile = 'Mobile',
  /** column name */
  Name = 'Name',
  /** column name */
  Registrationdate = 'Registrationdate',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Set_Input = {
  Carrier?: InputMaybe<Scalars['String']['input']>;
  Dob?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  Idno?: InputMaybe<Scalars['String']['input']>;
  Idtype?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Registrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
};

/** aggregate fields of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_rtcs_Country". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp = {
  Countrycode?: InputMaybe<String_Comparison_Exp>;
  Countryname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_max_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_min_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export enum Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column {
  /** column name */
  Countrycode = 'Countrycode',
  /** column name */
  Countryname = 'Countryname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Set_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  BuildingCentroidGps?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  PlusCode1?: Maybe<Scalars['String']['output']>;
  PlusCode2?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WallMaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
};

/** aggregate fields of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_avg_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp = {
  BuildingAreaM2?: InputMaybe<Float8_Comparison_Exp>;
  BuildingCentroidGps?: InputMaybe<String_Comparison_Exp>;
  BuildingType?: InputMaybe<String_Comparison_Exp>;
  BusinessName?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  NumberOfStoreys?: InputMaybe<Bigint_Comparison_Exp>;
  Phone1?: InputMaybe<String_Comparison_Exp>;
  Phone2?: InputMaybe<Float8_Comparison_Exp>;
  PlusCode1?: InputMaybe<String_Comparison_Exp>;
  PlusCode2?: InputMaybe<String_Comparison_Exp>;
  RoofMaterial?: InputMaybe<String_Comparison_Exp>;
  Settlement?: InputMaybe<String_Comparison_Exp>;
  Street?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WallMaterial?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Inc_Input = {
  BuildingAreaM2?: InputMaybe<Scalars['float8']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumberOfStoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input = {
  BuildingAreaM2?: InputMaybe<Scalars['float8']['input']>;
  BuildingCentroidGps?: InputMaybe<Scalars['String']['input']>;
  BuildingType?: InputMaybe<Scalars['String']['input']>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumberOfStoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  PlusCode1?: InputMaybe<Scalars['String']['input']>;
  PlusCode2?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WallMaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_max_fields';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  BuildingCentroidGps?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  PlusCode1?: Maybe<Scalars['String']['output']>;
  PlusCode2?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WallMaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  BuildingCentroidGps?: InputMaybe<Order_By>;
  BuildingType?: InputMaybe<Order_By>;
  BusinessName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  PlusCode1?: InputMaybe<Order_By>;
  PlusCode2?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WallMaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_min_fields';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  BuildingCentroidGps?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  PlusCode1?: Maybe<Scalars['String']['output']>;
  PlusCode2?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WallMaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  BuildingCentroidGps?: InputMaybe<Order_By>;
  BuildingType?: InputMaybe<Order_By>;
  BusinessName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  PlusCode1?: InputMaybe<Order_By>;
  PlusCode2?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WallMaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  BuildingCentroidGps?: InputMaybe<Order_By>;
  BuildingType?: InputMaybe<Order_By>;
  BusinessName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  PlusCode1?: InputMaybe<Order_By>;
  PlusCode2?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WallMaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export enum Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column {
  /** column name */
  BuildingAreaM2 = 'BuildingAreaM2',
  /** column name */
  BuildingCentroidGps = 'BuildingCentroidGps',
  /** column name */
  BuildingType = 'BuildingType',
  /** column name */
  BusinessName = 'BusinessName',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line = 'Line',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  NumberOfStoreys = 'NumberOfStoreys',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  PlusCode1 = 'PlusCode1',
  /** column name */
  PlusCode2 = 'PlusCode2',
  /** column name */
  RoofMaterial = 'RoofMaterial',
  /** column name */
  Settlement = 'Settlement',
  /** column name */
  Street = 'Street',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WallMaterial = 'WallMaterial',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Set_Input = {
  BuildingAreaM2?: InputMaybe<Scalars['float8']['input']>;
  BuildingCentroidGps?: InputMaybe<Scalars['String']['input']>;
  BuildingType?: InputMaybe<Scalars['String']['input']>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumberOfStoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  PlusCode1?: InputMaybe<Scalars['String']['input']>;
  PlusCode2?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WallMaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_stddev_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_stddev_pop_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_stddev_samp_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_sum_fields';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_var_pop_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_var_samp_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_variance_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users = {
  __typename?: 'rtcs_gm_dev_SYS_Users';
  Active?: Maybe<Scalars['Boolean']['output']>;
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmailVerified?: Maybe<Scalars['Boolean']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate = {
  __typename?: 'rtcs_gm_dev_SYS_Users_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Sys_Users_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Sys_Users>;
};

/** aggregate fields of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Sys_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Sys_Users_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Sys_Users_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Sys_Users_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Sys_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Sys_Users_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Sys_Users_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Sys_Users_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Sys_Users_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_avg_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Avg_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.SYS_Users". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Sys_Users_Bool_Exp = {
  Active?: InputMaybe<Boolean_Comparison_Exp>;
  CreationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  EmailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  GroupName?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  LoginAttemptCounter?: InputMaybe<Float8_Comparison_Exp>;
  MobilePhone?: InputMaybe<String_Comparison_Exp>;
  Password?: InputMaybe<String_Comparison_Exp>;
  PasswordModificationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ProfilePicture?: InputMaybe<String_Comparison_Exp>;
  Updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  VisibleLevel?: InputMaybe<String_Comparison_Exp>;
  WorkAddress?: InputMaybe<String_Comparison_Exp>;
  WorkPhone?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Inc_Input = {
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Insert_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Sys_Users_Max_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_max_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Max_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Sys_Users_Min_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_min_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Min_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_SYS_Users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Sys_Users>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Sys_Users_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Order_By = {
  Active?: InputMaybe<Order_By>;
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmailVerified?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.SYS_Users" */
export enum Rtcs_Gm_Dev_Sys_Users_Select_Column {
  /** column name */
  Active = 'Active',
  /** column name */
  CreationDate = 'CreationDate',
  /** column name */
  Email = 'Email',
  /** column name */
  EmailVerified = 'EmailVerified',
  /** column name */
  File = 'File',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  GroupName = 'GroupName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LoginAttemptCounter = 'LoginAttemptCounter',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  Password = 'Password',
  /** column name */
  PasswordModificationDate = 'PasswordModificationDate',
  /** column name */
  ProfilePicture = 'ProfilePicture',
  /** column name */
  Updated = 'Updated',
  /** column name */
  VisibleLevel = 'VisibleLevel',
  /** column name */
  WorkAddress = 'WorkAddress',
  /** column name */
  WorkPhone = 'WorkPhone'
}

/** input type for updating data in table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Set_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_stddev_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_stddev_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_stddev_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Sys_Users_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_sum_fields';
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Sum_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Sys_Users_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_var_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Var_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Sys_Users_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_var_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Var_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Sys_Users_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_variance_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Variance_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Africa360view_Africa360View>;
};

/** aggregate fields of "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_avg_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Avg_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.africa360view_Africa360View". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp = {
  Buildingaream2?: InputMaybe<Float8_Comparison_Exp>;
  Buildingcentroidgps?: InputMaybe<String_Comparison_Exp>;
  Buildingtype?: InputMaybe<String_Comparison_Exp>;
  Businessname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  Numberofstoreys?: InputMaybe<Bigint_Comparison_Exp>;
  Phone1?: InputMaybe<String_Comparison_Exp>;
  Phone2?: InputMaybe<Float8_Comparison_Exp>;
  Pluscode1?: InputMaybe<String_Comparison_Exp>;
  Pluscode2?: InputMaybe<String_Comparison_Exp>;
  Roofmaterial?: InputMaybe<String_Comparison_Exp>;
  Settlement?: InputMaybe<String_Comparison_Exp>;
  Street?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  Wallmaterial?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Inc_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Insert_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Max_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_max_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Max_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Min_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_min_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Min_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Africa360view_Africa360View>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Africa360view_Africa360View_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export enum Rtcs_Gm_Dev_Africa360view_Africa360View_Select_Column {
  /** column name */
  Buildingaream2 = 'Buildingaream2',
  /** column name */
  Buildingcentroidgps = 'Buildingcentroidgps',
  /** column name */
  Buildingtype = 'Buildingtype',
  /** column name */
  Businessname = 'Businessname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line = 'Line',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  Numberofstoreys = 'Numberofstoreys',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  Pluscode1 = 'Pluscode1',
  /** column name */
  Pluscode2 = 'Pluscode2',
  /** column name */
  Roofmaterial = 'Roofmaterial',
  /** column name */
  Settlement = 'Settlement',
  /** column name */
  Street = 'Street',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  Wallmaterial = 'Wallmaterial',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Set_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_stddev_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_stddev_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_stddev_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Stddev_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_sum_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['bigint']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Sum_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_var_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_var_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Var_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_africa360view_Africa360View_variance_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.africa360view_Africa360View" */
export type Rtcs_Gm_Dev_Africa360view_Africa360View_Variance_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result = {
  __typename?: 'rtcs_gm_dev_aggregate_result';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate = {
  __typename?: 'rtcs_gm_dev_aggregate_result_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Aggregate_Result>;
};

/** aggregate fields of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Aggregate_Result_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.aggregate_result". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>>>;
  operation?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Inc_Input = {
  value?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Insert_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Max_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_max_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Max_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Min_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_min_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Min_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_aggregate_result_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Aggregate_Result>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Aggregate_Result_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.aggregate_result" */
export enum Rtcs_Gm_Dev_Aggregate_Result_Select_Column {
  /** column name */
  Operation = 'operation',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Set_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_sum_fields';
  value?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
};

/** aggregate fields of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_aggregate_fields';
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.aggregate_result_txt". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>>>;
  operation?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_max_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_min_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.aggregate_result_txt" */
export enum Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column {
  /** column name */
  Operation = 'operation',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Set_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates';
  Address?: Maybe<Scalars['String']['output']>;
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears1?: Maybe<Scalars['String']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Arrears3?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Contactno?: Maybe<Scalars['String']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Current3?: Maybe<Scalars['String']['output']>;
  Currentowner?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  Demandnoteno?: Maybe<Scalars['String']['output']>;
  Detailsofpayment1?: Maybe<Scalars['String']['output']>;
  Detailsofpayment2?: Maybe<Scalars['String']['output']>;
  Detailsofpayment3?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lot?: Maybe<Scalars['String']['output']>;
  Particulars?: Maybe<Scalars['String']['output']>;
  Plandate?: Maybe<Scalars['timestamptz']['output']>;
  Plandetails?: Maybe<Scalars['String']['output']>;
  Rem?: Maybe<Scalars['String']['output']>;
  Sm?: Maybe<Scalars['String']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Total2?: Maybe<Scalars['String']['output']>;
  Total3?: Maybe<Scalars['String']['output']>;
  Totalpaytodate1?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate2?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate3?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wr?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Aggregate = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Bcc_RegRates>;
};

/** aggregate fields of "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Bcc_RegRates_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Bcc_RegRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_avg_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Avg_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.bcc_RegRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Amountdue?: InputMaybe<Float8_Comparison_Exp>;
  Arrears1?: InputMaybe<String_Comparison_Exp>;
  Arrears2?: InputMaybe<Float8_Comparison_Exp>;
  Arrears3?: InputMaybe<String_Comparison_Exp>;
  Balance?: InputMaybe<String_Comparison_Exp>;
  Contactno?: InputMaybe<String_Comparison_Exp>;
  Current1?: InputMaybe<Float8_Comparison_Exp>;
  Current2?: InputMaybe<Float8_Comparison_Exp>;
  Current3?: InputMaybe<String_Comparison_Exp>;
  Currentowner?: InputMaybe<String_Comparison_Exp>;
  Date?: InputMaybe<Timestamptz_Comparison_Exp>;
  Demandnoteno?: InputMaybe<String_Comparison_Exp>;
  Detailsofpayment1?: InputMaybe<String_Comparison_Exp>;
  Detailsofpayment2?: InputMaybe<String_Comparison_Exp>;
  Detailsofpayment3?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lot?: InputMaybe<String_Comparison_Exp>;
  Particulars?: InputMaybe<String_Comparison_Exp>;
  Plandate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Plandetails?: InputMaybe<String_Comparison_Exp>;
  Rem?: InputMaybe<String_Comparison_Exp>;
  Sm?: InputMaybe<String_Comparison_Exp>;
  Total1?: InputMaybe<Float8_Comparison_Exp>;
  Total2?: InputMaybe<String_Comparison_Exp>;
  Total3?: InputMaybe<String_Comparison_Exp>;
  Totalpaytodate1?: InputMaybe<Timestamptz_Comparison_Exp>;
  Totalpaytodate2?: InputMaybe<Timestamptz_Comparison_Exp>;
  Totalpaytodate3?: InputMaybe<Timestamptz_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Wr?: InputMaybe<String_Comparison_Exp>;
  Year?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Inc_Input = {
  Amountdue?: InputMaybe<Scalars['float8']['input']>;
  Arrears2?: InputMaybe<Scalars['float8']['input']>;
  Current1?: InputMaybe<Scalars['float8']['input']>;
  Current2?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Total1?: InputMaybe<Scalars['float8']['input']>;
  Year?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Amountdue?: InputMaybe<Scalars['float8']['input']>;
  Arrears1?: InputMaybe<Scalars['String']['input']>;
  Arrears2?: InputMaybe<Scalars['float8']['input']>;
  Arrears3?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Contactno?: InputMaybe<Scalars['String']['input']>;
  Current1?: InputMaybe<Scalars['float8']['input']>;
  Current2?: InputMaybe<Scalars['float8']['input']>;
  Current3?: InputMaybe<Scalars['String']['input']>;
  Currentowner?: InputMaybe<Scalars['String']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  Demandnoteno?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment1?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment2?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment3?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lot?: InputMaybe<Scalars['String']['input']>;
  Particulars?: InputMaybe<Scalars['String']['input']>;
  Plandate?: InputMaybe<Scalars['timestamptz']['input']>;
  Plandetails?: InputMaybe<Scalars['String']['input']>;
  Rem?: InputMaybe<Scalars['String']['input']>;
  Sm?: InputMaybe<Scalars['String']['input']>;
  Total1?: InputMaybe<Scalars['float8']['input']>;
  Total2?: InputMaybe<Scalars['String']['input']>;
  Total3?: InputMaybe<Scalars['String']['input']>;
  Totalpaytodate1?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate2?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate3?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wr?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Max_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears1?: Maybe<Scalars['String']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Arrears3?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Contactno?: Maybe<Scalars['String']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Current3?: Maybe<Scalars['String']['output']>;
  Currentowner?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  Demandnoteno?: Maybe<Scalars['String']['output']>;
  Detailsofpayment1?: Maybe<Scalars['String']['output']>;
  Detailsofpayment2?: Maybe<Scalars['String']['output']>;
  Detailsofpayment3?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lot?: Maybe<Scalars['String']['output']>;
  Particulars?: Maybe<Scalars['String']['output']>;
  Plandate?: Maybe<Scalars['timestamptz']['output']>;
  Plandetails?: Maybe<Scalars['String']['output']>;
  Rem?: Maybe<Scalars['String']['output']>;
  Sm?: Maybe<Scalars['String']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Total2?: Maybe<Scalars['String']['output']>;
  Total3?: Maybe<Scalars['String']['output']>;
  Totalpaytodate1?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate2?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate3?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wr?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Amountdue?: InputMaybe<Order_By>;
  Arrears1?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Arrears3?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Contactno?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Current3?: InputMaybe<Order_By>;
  Currentowner?: InputMaybe<Order_By>;
  Date?: InputMaybe<Order_By>;
  Demandnoteno?: InputMaybe<Order_By>;
  Detailsofpayment1?: InputMaybe<Order_By>;
  Detailsofpayment2?: InputMaybe<Order_By>;
  Detailsofpayment3?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lot?: InputMaybe<Order_By>;
  Particulars?: InputMaybe<Order_By>;
  Plandate?: InputMaybe<Order_By>;
  Plandetails?: InputMaybe<Order_By>;
  Rem?: InputMaybe<Order_By>;
  Sm?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Total2?: InputMaybe<Order_By>;
  Total3?: InputMaybe<Order_By>;
  Totalpaytodate1?: InputMaybe<Order_By>;
  Totalpaytodate2?: InputMaybe<Order_By>;
  Totalpaytodate3?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wr?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Min_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears1?: Maybe<Scalars['String']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Arrears3?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Contactno?: Maybe<Scalars['String']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Current3?: Maybe<Scalars['String']['output']>;
  Currentowner?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  Demandnoteno?: Maybe<Scalars['String']['output']>;
  Detailsofpayment1?: Maybe<Scalars['String']['output']>;
  Detailsofpayment2?: Maybe<Scalars['String']['output']>;
  Detailsofpayment3?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lot?: Maybe<Scalars['String']['output']>;
  Particulars?: Maybe<Scalars['String']['output']>;
  Plandate?: Maybe<Scalars['timestamptz']['output']>;
  Plandetails?: Maybe<Scalars['String']['output']>;
  Rem?: Maybe<Scalars['String']['output']>;
  Sm?: Maybe<Scalars['String']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Total2?: Maybe<Scalars['String']['output']>;
  Total3?: Maybe<Scalars['String']['output']>;
  Totalpaytodate1?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate2?: Maybe<Scalars['timestamptz']['output']>;
  Totalpaytodate3?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wr?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Amountdue?: InputMaybe<Order_By>;
  Arrears1?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Arrears3?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Contactno?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Current3?: InputMaybe<Order_By>;
  Currentowner?: InputMaybe<Order_By>;
  Date?: InputMaybe<Order_By>;
  Demandnoteno?: InputMaybe<Order_By>;
  Detailsofpayment1?: InputMaybe<Order_By>;
  Detailsofpayment2?: InputMaybe<Order_By>;
  Detailsofpayment3?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lot?: InputMaybe<Order_By>;
  Particulars?: InputMaybe<Order_By>;
  Plandate?: InputMaybe<Order_By>;
  Plandetails?: InputMaybe<Order_By>;
  Rem?: InputMaybe<Order_By>;
  Sm?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Total2?: InputMaybe<Order_By>;
  Total3?: InputMaybe<Order_By>;
  Totalpaytodate1?: InputMaybe<Order_By>;
  Totalpaytodate2?: InputMaybe<Order_By>;
  Totalpaytodate3?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wr?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Bcc_RegRates>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Bcc_RegRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Order_By = {
  Address?: InputMaybe<Order_By>;
  Amountdue?: InputMaybe<Order_By>;
  Arrears1?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Arrears3?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Contactno?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Current3?: InputMaybe<Order_By>;
  Currentowner?: InputMaybe<Order_By>;
  Date?: InputMaybe<Order_By>;
  Demandnoteno?: InputMaybe<Order_By>;
  Detailsofpayment1?: InputMaybe<Order_By>;
  Detailsofpayment2?: InputMaybe<Order_By>;
  Detailsofpayment3?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lot?: InputMaybe<Order_By>;
  Particulars?: InputMaybe<Order_By>;
  Plandate?: InputMaybe<Order_By>;
  Plandetails?: InputMaybe<Order_By>;
  Rem?: InputMaybe<Order_By>;
  Sm?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Total2?: InputMaybe<Order_By>;
  Total3?: InputMaybe<Order_By>;
  Totalpaytodate1?: InputMaybe<Order_By>;
  Totalpaytodate2?: InputMaybe<Order_By>;
  Totalpaytodate3?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wr?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.bcc_RegRates" */
export enum Rtcs_Gm_Dev_Bcc_RegRates_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Amountdue = 'Amountdue',
  /** column name */
  Arrears1 = 'Arrears1',
  /** column name */
  Arrears2 = 'Arrears2',
  /** column name */
  Arrears3 = 'Arrears3',
  /** column name */
  Balance = 'Balance',
  /** column name */
  Contactno = 'Contactno',
  /** column name */
  Current1 = 'Current1',
  /** column name */
  Current2 = 'Current2',
  /** column name */
  Current3 = 'Current3',
  /** column name */
  Currentowner = 'Currentowner',
  /** column name */
  Date = 'Date',
  /** column name */
  Demandnoteno = 'Demandnoteno',
  /** column name */
  Detailsofpayment1 = 'Detailsofpayment1',
  /** column name */
  Detailsofpayment2 = 'Detailsofpayment2',
  /** column name */
  Detailsofpayment3 = 'Detailsofpayment3',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lot = 'Lot',
  /** column name */
  Particulars = 'Particulars',
  /** column name */
  Plandate = 'Plandate',
  /** column name */
  Plandetails = 'Plandetails',
  /** column name */
  Rem = 'Rem',
  /** column name */
  Sm = 'Sm',
  /** column name */
  Total1 = 'Total1',
  /** column name */
  Total2 = 'Total2',
  /** column name */
  Total3 = 'Total3',
  /** column name */
  Totalpaytodate1 = 'Totalpaytodate1',
  /** column name */
  Totalpaytodate2 = 'Totalpaytodate2',
  /** column name */
  Totalpaytodate3 = 'Totalpaytodate3',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Wr = 'Wr',
  /** column name */
  Year = 'Year'
}

/** input type for updating data in table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Amountdue?: InputMaybe<Scalars['float8']['input']>;
  Arrears1?: InputMaybe<Scalars['String']['input']>;
  Arrears2?: InputMaybe<Scalars['float8']['input']>;
  Arrears3?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Contactno?: InputMaybe<Scalars['String']['input']>;
  Current1?: InputMaybe<Scalars['float8']['input']>;
  Current2?: InputMaybe<Scalars['float8']['input']>;
  Current3?: InputMaybe<Scalars['String']['input']>;
  Currentowner?: InputMaybe<Scalars['String']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  Demandnoteno?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment1?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment2?: InputMaybe<Scalars['String']['input']>;
  Detailsofpayment3?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lot?: InputMaybe<Scalars['String']['input']>;
  Particulars?: InputMaybe<Scalars['String']['input']>;
  Plandate?: InputMaybe<Scalars['timestamptz']['input']>;
  Plandetails?: InputMaybe<Scalars['String']['input']>;
  Rem?: InputMaybe<Scalars['String']['input']>;
  Sm?: InputMaybe<Scalars['String']['input']>;
  Total1?: InputMaybe<Scalars['float8']['input']>;
  Total2?: InputMaybe<Scalars['String']['input']>;
  Total3?: InputMaybe<Scalars['String']['input']>;
  Totalpaytodate1?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate2?: InputMaybe<Scalars['timestamptz']['input']>;
  Totalpaytodate3?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wr?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_stddev_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_stddev_pop_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Pop_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_stddev_samp_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Stddev_Samp_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_sum_fields';
  Amountdue?: Maybe<Scalars['float8']['output']>;
  Arrears2?: Maybe<Scalars['float8']['output']>;
  Current1?: Maybe<Scalars['float8']['output']>;
  Current2?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Total1?: Maybe<Scalars['float8']['output']>;
  Year?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Sum_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_var_pop_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Var_Pop_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_var_samp_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Var_Samp_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Bcc_RegRates_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_RegRates_variance_fields';
  Amountdue?: Maybe<Scalars['Float']['output']>;
  Arrears2?: Maybe<Scalars['Float']['output']>;
  Current1?: Maybe<Scalars['Float']['output']>;
  Current2?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Total1?: Maybe<Scalars['Float']['output']>;
  Year?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.bcc_RegRates" */
export type Rtcs_Gm_Dev_Bcc_RegRates_Variance_Order_By = {
  Amountdue?: InputMaybe<Order_By>;
  Arrears2?: InputMaybe<Order_By>;
  Current1?: InputMaybe<Order_By>;
  Current2?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Total1?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister';
  Address?: Maybe<Scalars['String']['output']>;
  Arrears?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Certificateno?: Maybe<Scalars['String']['output']>;
  Currentyear?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Invdate?: Maybe<Scalars['timestamptz']['output']>;
  Invid?: Maybe<Scalars['String']['output']>;
  Invno?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Licencetype?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Receiptdetails?: Maybe<Scalars['String']['output']>;
  Totalinvoiceamount?: Maybe<Scalars['String']['output']>;
  Totalreceipttodate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wrno?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister>;
};

/** aggregate fields of "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.bcc_TradeLicenseRegister". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Arrears?: InputMaybe<String_Comparison_Exp>;
  Balance?: InputMaybe<String_Comparison_Exp>;
  Certificateno?: InputMaybe<String_Comparison_Exp>;
  Currentyear?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<String_Comparison_Exp>;
  Invdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Invid?: InputMaybe<String_Comparison_Exp>;
  Invno?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Licencetype?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Receiptdetails?: InputMaybe<String_Comparison_Exp>;
  Totalinvoiceamount?: InputMaybe<String_Comparison_Exp>;
  Totalreceipttodate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Wrno?: InputMaybe<String_Comparison_Exp>;
  Year?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Arrears?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Certificateno?: InputMaybe<Scalars['String']['input']>;
  Currentyear?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Invdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Invid?: InputMaybe<Scalars['String']['input']>;
  Invno?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Licencetype?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Receiptdetails?: InputMaybe<Scalars['String']['input']>;
  Totalinvoiceamount?: InputMaybe<Scalars['String']['input']>;
  Totalreceipttodate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wrno?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Max_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Arrears?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Certificateno?: Maybe<Scalars['String']['output']>;
  Currentyear?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Invdate?: Maybe<Scalars['timestamptz']['output']>;
  Invid?: Maybe<Scalars['String']['output']>;
  Invno?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Licencetype?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Receiptdetails?: Maybe<Scalars['String']['output']>;
  Totalinvoiceamount?: Maybe<Scalars['String']['output']>;
  Totalreceipttodate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wrno?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Arrears?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Certificateno?: InputMaybe<Order_By>;
  Currentyear?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Invdate?: InputMaybe<Order_By>;
  Invid?: InputMaybe<Order_By>;
  Invno?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Licencetype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Receiptdetails?: InputMaybe<Order_By>;
  Totalinvoiceamount?: InputMaybe<Order_By>;
  Totalreceipttodate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wrno?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Min_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Arrears?: Maybe<Scalars['String']['output']>;
  Balance?: Maybe<Scalars['String']['output']>;
  Certificateno?: Maybe<Scalars['String']['output']>;
  Currentyear?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Invdate?: Maybe<Scalars['timestamptz']['output']>;
  Invid?: Maybe<Scalars['String']['output']>;
  Invno?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Licencetype?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Receiptdetails?: Maybe<Scalars['String']['output']>;
  Totalinvoiceamount?: Maybe<Scalars['String']['output']>;
  Totalreceipttodate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Wrno?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Arrears?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Certificateno?: InputMaybe<Order_By>;
  Currentyear?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Invdate?: InputMaybe<Order_By>;
  Invid?: InputMaybe<Order_By>;
  Invno?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Licencetype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Receiptdetails?: InputMaybe<Order_By>;
  Totalinvoiceamount?: InputMaybe<Order_By>;
  Totalreceipttodate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wrno?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Order_By = {
  Address?: InputMaybe<Order_By>;
  Arrears?: InputMaybe<Order_By>;
  Balance?: InputMaybe<Order_By>;
  Certificateno?: InputMaybe<Order_By>;
  Currentyear?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Invdate?: InputMaybe<Order_By>;
  Invid?: InputMaybe<Order_By>;
  Invno?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Licencetype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Receiptdetails?: InputMaybe<Order_By>;
  Totalinvoiceamount?: InputMaybe<Order_By>;
  Totalreceipttodate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Wrno?: InputMaybe<Order_By>;
  Year?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export enum Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Arrears = 'Arrears',
  /** column name */
  Balance = 'Balance',
  /** column name */
  Certificateno = 'Certificateno',
  /** column name */
  Currentyear = 'Currentyear',
  /** column name */
  File = 'File',
  /** column name */
  Id = 'Id',
  /** column name */
  Invdate = 'Invdate',
  /** column name */
  Invid = 'Invid',
  /** column name */
  Invno = 'Invno',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Licencetype = 'Licencetype',
  /** column name */
  Line = 'Line',
  /** column name */
  Name = 'Name',
  /** column name */
  Receiptdetails = 'Receiptdetails',
  /** column name */
  Totalinvoiceamount = 'Totalinvoiceamount',
  /** column name */
  Totalreceipttodate = 'Totalreceipttodate',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Wrno = 'Wrno',
  /** column name */
  Year = 'Year'
}

/** input type for updating data in table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Arrears?: InputMaybe<Scalars['String']['input']>;
  Balance?: InputMaybe<Scalars['String']['input']>;
  Certificateno?: InputMaybe<Scalars['String']['input']>;
  Currentyear?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Invdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Invid?: InputMaybe<Scalars['String']['input']>;
  Invno?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Licencetype?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Receiptdetails?: InputMaybe<Scalars['String']['input']>;
  Totalinvoiceamount?: InputMaybe<Scalars['String']['input']>;
  Totalreceipttodate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Wrno?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_bcc_TradeLicenseRegister_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.bcc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey';
  Address?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagency?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagent?: Maybe<Scalars['String']['output']>;
  AmountPaid?: Maybe<Scalars['String']['output']>;
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  AnyMore?: Maybe<Scalars['String']['output']>;
  BusinessActivitytype?: Maybe<Scalars['String']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  CurrencyUnit?: Maybe<Scalars['String']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FrequencyOfPayment?: Maybe<Scalars['String']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lga?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusiness?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagent?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: Maybe<Scalars['String']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhereStatus?: Maybe<Scalars['String']['output']>;
  OtherSpecifiedFrequencyPaymentArrangement?: Maybe<Scalars['String']['output']>;
  OwnerOccupiedEstimatedValueOfRent?: Maybe<Scalars['String']['output']>;
  PaymentFrequency?: Maybe<Scalars['String']['output']>;
  PositionOfRespondentcontactPerson?: Maybe<Scalars['String']['output']>;
  PropertyOwneradministrator?: Maybe<Scalars['String']['output']>;
  RentPeriodArrangement?: Maybe<Scalars['String']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey>;
};

/** aggregate fields of "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_avg_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Avg_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  AddressOfLandlordagency?: InputMaybe<String_Comparison_Exp>;
  AddressOfLandlordagent?: InputMaybe<String_Comparison_Exp>;
  AmountPaid?: InputMaybe<String_Comparison_Exp>;
  AmountPaidPerPeriod?: InputMaybe<Bigint_Comparison_Exp>;
  AnyMore?: InputMaybe<String_Comparison_Exp>;
  BusinessActivitytype?: InputMaybe<String_Comparison_Exp>;
  BusinessTelephone?: InputMaybe<Float8_Comparison_Exp>;
  ContactOfLandlordagent?: InputMaybe<Float8_Comparison_Exp>;
  ContactPersonsTel?: InputMaybe<Float8_Comparison_Exp>;
  CurrencyUnit?: InputMaybe<String_Comparison_Exp>;
  DurationInMonths?: InputMaybe<Bigint_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FrequencyOfPayment?: InputMaybe<String_Comparison_Exp>;
  IndividualId?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lga?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  NameOfBusiness?: InputMaybe<String_Comparison_Exp>;
  NameOfLandlordagent?: InputMaybe<String_Comparison_Exp>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<String_Comparison_Exp>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Bigint_Comparison_Exp>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<String_Comparison_Exp>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<String_Comparison_Exp>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<String_Comparison_Exp>;
  PaymentFrequency?: InputMaybe<String_Comparison_Exp>;
  PositionOfRespondentcontactPerson?: InputMaybe<String_Comparison_Exp>;
  PropertyOwneradministrator?: InputMaybe<String_Comparison_Exp>;
  RentPeriodArrangement?: InputMaybe<String_Comparison_Exp>;
  SecondContact?: InputMaybe<Float8_Comparison_Exp>;
  SecondTelOfContactPerson?: InputMaybe<Float8_Comparison_Exp>;
  TelOfLandlordagent?: InputMaybe<Float8_Comparison_Exp>;
  ThirdContact?: InputMaybe<Float8_Comparison_Exp>;
  TinOfLandlordagent?: InputMaybe<Float8_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Inc_Input = {
  AmountPaidPerPeriod?: InputMaybe<Scalars['bigint']['input']>;
  BusinessTelephone?: InputMaybe<Scalars['float8']['input']>;
  ContactOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ContactPersonsTel?: InputMaybe<Scalars['float8']['input']>;
  DurationInMonths?: InputMaybe<Scalars['bigint']['input']>;
  IndividualId?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Scalars['bigint']['input']>;
  SecondContact?: InputMaybe<Scalars['float8']['input']>;
  SecondTelOfContactPerson?: InputMaybe<Scalars['float8']['input']>;
  TelOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ThirdContact?: InputMaybe<Scalars['float8']['input']>;
  TinOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagency?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  AmountPaid?: InputMaybe<Scalars['String']['input']>;
  AmountPaidPerPeriod?: InputMaybe<Scalars['bigint']['input']>;
  AnyMore?: InputMaybe<Scalars['String']['input']>;
  BusinessActivitytype?: InputMaybe<Scalars['String']['input']>;
  BusinessTelephone?: InputMaybe<Scalars['float8']['input']>;
  ContactOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ContactPersonsTel?: InputMaybe<Scalars['float8']['input']>;
  CurrencyUnit?: InputMaybe<Scalars['String']['input']>;
  DurationInMonths?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FrequencyOfPayment?: InputMaybe<Scalars['String']['input']>;
  IndividualId?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lga?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusiness?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Scalars['String']['input']>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Scalars['bigint']['input']>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Scalars['String']['input']>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Scalars['String']['input']>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Scalars['String']['input']>;
  PaymentFrequency?: InputMaybe<Scalars['String']['input']>;
  PositionOfRespondentcontactPerson?: InputMaybe<Scalars['String']['input']>;
  PropertyOwneradministrator?: InputMaybe<Scalars['String']['input']>;
  RentPeriodArrangement?: InputMaybe<Scalars['String']['input']>;
  SecondContact?: InputMaybe<Scalars['float8']['input']>;
  SecondTelOfContactPerson?: InputMaybe<Scalars['float8']['input']>;
  TelOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ThirdContact?: InputMaybe<Scalars['float8']['input']>;
  TinOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagency?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagent?: Maybe<Scalars['String']['output']>;
  AmountPaid?: Maybe<Scalars['String']['output']>;
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  AnyMore?: Maybe<Scalars['String']['output']>;
  BusinessActivitytype?: Maybe<Scalars['String']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  CurrencyUnit?: Maybe<Scalars['String']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FrequencyOfPayment?: Maybe<Scalars['String']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lga?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusiness?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagent?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: Maybe<Scalars['String']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhereStatus?: Maybe<Scalars['String']['output']>;
  OtherSpecifiedFrequencyPaymentArrangement?: Maybe<Scalars['String']['output']>;
  OwnerOccupiedEstimatedValueOfRent?: Maybe<Scalars['String']['output']>;
  PaymentFrequency?: Maybe<Scalars['String']['output']>;
  PositionOfRespondentcontactPerson?: Maybe<Scalars['String']['output']>;
  PropertyOwneradministrator?: Maybe<Scalars['String']['output']>;
  RentPeriodArrangement?: Maybe<Scalars['String']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  AddressOfLandlordagency?: InputMaybe<Order_By>;
  AddressOfLandlordagent?: InputMaybe<Order_By>;
  AmountPaid?: InputMaybe<Order_By>;
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  AnyMore?: InputMaybe<Order_By>;
  BusinessActivitytype?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  CurrencyUnit?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FrequencyOfPayment?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lga?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusiness?: InputMaybe<Order_By>;
  NameOfLandlordagent?: InputMaybe<Order_By>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Order_By>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Order_By>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Order_By>;
  PaymentFrequency?: InputMaybe<Order_By>;
  PositionOfRespondentcontactPerson?: InputMaybe<Order_By>;
  PropertyOwneradministrator?: InputMaybe<Order_By>;
  RentPeriodArrangement?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagency?: Maybe<Scalars['String']['output']>;
  AddressOfLandlordagent?: Maybe<Scalars['String']['output']>;
  AmountPaid?: Maybe<Scalars['String']['output']>;
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  AnyMore?: Maybe<Scalars['String']['output']>;
  BusinessActivitytype?: Maybe<Scalars['String']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  CurrencyUnit?: Maybe<Scalars['String']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FrequencyOfPayment?: Maybe<Scalars['String']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lga?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusiness?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagent?: Maybe<Scalars['String']['output']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: Maybe<Scalars['String']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhereStatus?: Maybe<Scalars['String']['output']>;
  OtherSpecifiedFrequencyPaymentArrangement?: Maybe<Scalars['String']['output']>;
  OwnerOccupiedEstimatedValueOfRent?: Maybe<Scalars['String']['output']>;
  PaymentFrequency?: Maybe<Scalars['String']['output']>;
  PositionOfRespondentcontactPerson?: Maybe<Scalars['String']['output']>;
  PropertyOwneradministrator?: Maybe<Scalars['String']['output']>;
  RentPeriodArrangement?: Maybe<Scalars['String']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  AddressOfLandlordagency?: InputMaybe<Order_By>;
  AddressOfLandlordagent?: InputMaybe<Order_By>;
  AmountPaid?: InputMaybe<Order_By>;
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  AnyMore?: InputMaybe<Order_By>;
  BusinessActivitytype?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  CurrencyUnit?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FrequencyOfPayment?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lga?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusiness?: InputMaybe<Order_By>;
  NameOfLandlordagent?: InputMaybe<Order_By>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Order_By>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Order_By>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Order_By>;
  PaymentFrequency?: InputMaybe<Order_By>;
  PositionOfRespondentcontactPerson?: InputMaybe<Order_By>;
  PropertyOwneradministrator?: InputMaybe<Order_By>;
  RentPeriodArrangement?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Order_By = {
  Address?: InputMaybe<Order_By>;
  AddressOfLandlordagency?: InputMaybe<Order_By>;
  AddressOfLandlordagent?: InputMaybe<Order_By>;
  AmountPaid?: InputMaybe<Order_By>;
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  AnyMore?: InputMaybe<Order_By>;
  BusinessActivitytype?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  CurrencyUnit?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FrequencyOfPayment?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lga?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusiness?: InputMaybe<Order_By>;
  NameOfLandlordagent?: InputMaybe<Order_By>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Order_By>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Order_By>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Order_By>;
  PaymentFrequency?: InputMaybe<Order_By>;
  PositionOfRespondentcontactPerson?: InputMaybe<Order_By>;
  PropertyOwneradministrator?: InputMaybe<Order_By>;
  RentPeriodArrangement?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export enum Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  AddressOfLandlordagency = 'AddressOfLandlordagency',
  /** column name */
  AddressOfLandlordagent = 'AddressOfLandlordagent',
  /** column name */
  AmountPaid = 'AmountPaid',
  /** column name */
  AmountPaidPerPeriod = 'AmountPaidPerPeriod',
  /** column name */
  AnyMore = 'AnyMore',
  /** column name */
  BusinessActivitytype = 'BusinessActivitytype',
  /** column name */
  BusinessTelephone = 'BusinessTelephone',
  /** column name */
  ContactOfLandlordagent = 'ContactOfLandlordagent',
  /** column name */
  ContactPersonsTel = 'ContactPersonsTel',
  /** column name */
  CurrencyUnit = 'CurrencyUnit',
  /** column name */
  DurationInMonths = 'DurationInMonths',
  /** column name */
  File = 'File',
  /** column name */
  FrequencyOfPayment = 'FrequencyOfPayment',
  /** column name */
  IndividualId = 'IndividualId',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lga = 'Lga',
  /** column name */
  Line = 'Line',
  /** column name */
  NameOfBusiness = 'NameOfBusiness',
  /** column name */
  NameOfLandlordagent = 'NameOfLandlordagent',
  /** column name */
  NameOfLandlordagentForOtherBusinessPropertyElsewhere = 'NameOfLandlordagentForOtherBusinessPropertyElsewhere',
  /** column name */
  OtherBusinessPropertyRentedElsewhere = 'OtherBusinessPropertyRentedElsewhere',
  /** column name */
  OtherBusinessPropertyRentedElsewhereStatus = 'OtherBusinessPropertyRentedElsewhereStatus',
  /** column name */
  OtherSpecifiedFrequencyPaymentArrangement = 'OtherSpecifiedFrequencyPaymentArrangement',
  /** column name */
  OwnerOccupiedEstimatedValueOfRent = 'OwnerOccupiedEstimatedValueOfRent',
  /** column name */
  PaymentFrequency = 'PaymentFrequency',
  /** column name */
  PositionOfRespondentcontactPerson = 'PositionOfRespondentcontactPerson',
  /** column name */
  PropertyOwneradministrator = 'PropertyOwneradministrator',
  /** column name */
  RentPeriodArrangement = 'RentPeriodArrangement',
  /** column name */
  SecondContact = 'SecondContact',
  /** column name */
  SecondTelOfContactPerson = 'SecondTelOfContactPerson',
  /** column name */
  TelOfLandlordagent = 'TelOfLandlordagent',
  /** column name */
  ThirdContact = 'ThirdContact',
  /** column name */
  TinOfLandlordagent = 'TinOfLandlordagent',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagency?: InputMaybe<Scalars['String']['input']>;
  AddressOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  AmountPaid?: InputMaybe<Scalars['String']['input']>;
  AmountPaidPerPeriod?: InputMaybe<Scalars['bigint']['input']>;
  AnyMore?: InputMaybe<Scalars['String']['input']>;
  BusinessActivitytype?: InputMaybe<Scalars['String']['input']>;
  BusinessTelephone?: InputMaybe<Scalars['float8']['input']>;
  ContactOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ContactPersonsTel?: InputMaybe<Scalars['float8']['input']>;
  CurrencyUnit?: InputMaybe<Scalars['String']['input']>;
  DurationInMonths?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FrequencyOfPayment?: InputMaybe<Scalars['String']['input']>;
  IndividualId?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lga?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusiness?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagent?: InputMaybe<Scalars['String']['input']>;
  NameOfLandlordagentForOtherBusinessPropertyElsewhere?: InputMaybe<Scalars['String']['input']>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Scalars['bigint']['input']>;
  OtherBusinessPropertyRentedElsewhereStatus?: InputMaybe<Scalars['String']['input']>;
  OtherSpecifiedFrequencyPaymentArrangement?: InputMaybe<Scalars['String']['input']>;
  OwnerOccupiedEstimatedValueOfRent?: InputMaybe<Scalars['String']['input']>;
  PaymentFrequency?: InputMaybe<Scalars['String']['input']>;
  PositionOfRespondentcontactPerson?: InputMaybe<Scalars['String']['input']>;
  PropertyOwneradministrator?: InputMaybe<Scalars['String']['input']>;
  RentPeriodArrangement?: InputMaybe<Scalars['String']['input']>;
  SecondContact?: InputMaybe<Scalars['float8']['input']>;
  SecondTelOfContactPerson?: InputMaybe<Scalars['float8']['input']>;
  TelOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  ThirdContact?: InputMaybe<Scalars['float8']['input']>;
  TinOfLandlordagent?: InputMaybe<Scalars['float8']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_stddev_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_stddev_pop_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Pop_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_stddev_samp_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Stddev_Samp_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_sum_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['bigint']['output']>;
  BusinessTelephone?: Maybe<Scalars['float8']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ContactPersonsTel?: Maybe<Scalars['float8']['output']>;
  DurationInMonths?: Maybe<Scalars['bigint']['output']>;
  IndividualId?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['bigint']['output']>;
  SecondContact?: Maybe<Scalars['float8']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['float8']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['float8']['output']>;
  ThirdContact?: Maybe<Scalars['float8']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Sum_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_var_pop_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Pop_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_var_samp_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Var_Samp_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_variance_fields';
  AmountPaidPerPeriod?: Maybe<Scalars['Float']['output']>;
  BusinessTelephone?: Maybe<Scalars['Float']['output']>;
  ContactOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ContactPersonsTel?: Maybe<Scalars['Float']['output']>;
  DurationInMonths?: Maybe<Scalars['Float']['output']>;
  IndividualId?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  OtherBusinessPropertyRentedElsewhere?: Maybe<Scalars['Float']['output']>;
  SecondContact?: Maybe<Scalars['Float']['output']>;
  SecondTelOfContactPerson?: Maybe<Scalars['Float']['output']>;
  TelOfLandlordagent?: Maybe<Scalars['Float']['output']>;
  ThirdContact?: Maybe<Scalars['Float']['output']>;
  TinOfLandlordagent?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
export type Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Variance_Order_By = {
  AmountPaidPerPeriod?: InputMaybe<Order_By>;
  BusinessTelephone?: InputMaybe<Order_By>;
  ContactOfLandlordagent?: InputMaybe<Order_By>;
  ContactPersonsTel?: InputMaybe<Order_By>;
  DurationInMonths?: InputMaybe<Order_By>;
  IndividualId?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OtherBusinessPropertyRentedElsewhere?: InputMaybe<Order_By>;
  SecondContact?: InputMaybe<Order_By>;
  SecondTelOfContactPerson?: InputMaybe<Order_By>;
  TelOfLandlordagent?: InputMaybe<Order_By>;
  ThirdContact?: InputMaybe<Order_By>;
  TinOfLandlordagent?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus';
  Compoundowner?: Maybe<Scalars['String']['output']>;
  CookingFuel?: Maybe<Scalars['String']['output']>;
  Districtname?: Maybe<Scalars['String']['output']>;
  FeelEviction?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FloorMaterial?: Maybe<Scalars['String']['output']>;
  FreqSolidWasteCollection?: Maybe<Scalars['String']['output']>;
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhHeadName?: Maybe<Scalars['String']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Headofcompound?: Maybe<Scalars['String']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hsettlement?: Maybe<Scalars['String']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Lganame?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  LiqWasteFromEconomic?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHhchores?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHumanConve?: Maybe<Scalars['String']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  PayCompoundRate?: Maybe<Scalars['String']['output']>;
  PlaceForBathing?: Maybe<Scalars['String']['output']>;
  PlaceForCooking?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  SolidWasteDisposal?: Maybe<Scalars['String']['output']>;
  SourceDrinkingWater?: Maybe<Scalars['String']['output']>;
  SourceLighting?: Maybe<Scalars['String']['output']>;
  SuitableForResidence?: Maybe<Scalars['String']['output']>;
  TitleDeed?: Maybe<Scalars['String']['output']>;
  ToiletFacility?: Maybe<Scalars['String']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WallsMaterial?: Maybe<Scalars['String']['output']>;
  Wardname?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus>;
};

/** aggregate fields of "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_avg_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Avg_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp = {
  Compoundowner?: InputMaybe<String_Comparison_Exp>;
  CookingFuel?: InputMaybe<String_Comparison_Exp>;
  Districtname?: InputMaybe<String_Comparison_Exp>;
  FeelEviction?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FloorMaterial?: InputMaybe<String_Comparison_Exp>;
  FreqSolidWasteCollection?: InputMaybe<String_Comparison_Exp>;
  HEaCode?: InputMaybe<Bigint_Comparison_Exp>;
  HHeadPhone?: InputMaybe<Bigint_Comparison_Exp>;
  HHhHeadName?: InputMaybe<String_Comparison_Exp>;
  HHhNumber?: InputMaybe<Bigint_Comparison_Exp>;
  HStrNumber?: InputMaybe<Bigint_Comparison_Exp>;
  Hdistrict?: InputMaybe<Bigint_Comparison_Exp>;
  Headofcompound?: InputMaybe<String_Comparison_Exp>;
  Hlga?: InputMaybe<Bigint_Comparison_Exp>;
  Hsettlement?: InputMaybe<String_Comparison_Exp>;
  Hward?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Lganame?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  LiqWasteFromEconomic?: InputMaybe<String_Comparison_Exp>;
  LiqWasteFromHhchores?: InputMaybe<String_Comparison_Exp>;
  LiqWasteFromHumanConve?: InputMaybe<String_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  NumOfFemale?: InputMaybe<Bigint_Comparison_Exp>;
  NumOfMale?: InputMaybe<Bigint_Comparison_Exp>;
  OccupancyStatus?: InputMaybe<String_Comparison_Exp>;
  PayCompoundRate?: InputMaybe<String_Comparison_Exp>;
  PlaceForBathing?: InputMaybe<String_Comparison_Exp>;
  PlaceForCooking?: InputMaybe<String_Comparison_Exp>;
  RoofMaterial?: InputMaybe<String_Comparison_Exp>;
  SolidWasteDisposal?: InputMaybe<String_Comparison_Exp>;
  SourceDrinkingWater?: InputMaybe<String_Comparison_Exp>;
  SourceLighting?: InputMaybe<String_Comparison_Exp>;
  SuitableForResidence?: InputMaybe<String_Comparison_Exp>;
  TitleDeed?: InputMaybe<String_Comparison_Exp>;
  ToiletFacility?: InputMaybe<String_Comparison_Exp>;
  TotalNum?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WallsMaterial?: InputMaybe<String_Comparison_Exp>;
  Wardname?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Inc_Input = {
  HEaCode?: InputMaybe<Scalars['bigint']['input']>;
  HHeadPhone?: InputMaybe<Scalars['bigint']['input']>;
  HHhNumber?: InputMaybe<Scalars['bigint']['input']>;
  HStrNumber?: InputMaybe<Scalars['bigint']['input']>;
  Hdistrict?: InputMaybe<Scalars['bigint']['input']>;
  Hlga?: InputMaybe<Scalars['bigint']['input']>;
  Hward?: InputMaybe<Scalars['bigint']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumOfFemale?: InputMaybe<Scalars['bigint']['input']>;
  NumOfMale?: InputMaybe<Scalars['bigint']['input']>;
  TotalNum?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Insert_Input = {
  Compoundowner?: InputMaybe<Scalars['String']['input']>;
  CookingFuel?: InputMaybe<Scalars['String']['input']>;
  Districtname?: InputMaybe<Scalars['String']['input']>;
  FeelEviction?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FloorMaterial?: InputMaybe<Scalars['String']['input']>;
  FreqSolidWasteCollection?: InputMaybe<Scalars['String']['input']>;
  HEaCode?: InputMaybe<Scalars['bigint']['input']>;
  HHeadPhone?: InputMaybe<Scalars['bigint']['input']>;
  HHhHeadName?: InputMaybe<Scalars['String']['input']>;
  HHhNumber?: InputMaybe<Scalars['bigint']['input']>;
  HStrNumber?: InputMaybe<Scalars['bigint']['input']>;
  Hdistrict?: InputMaybe<Scalars['bigint']['input']>;
  Headofcompound?: InputMaybe<Scalars['String']['input']>;
  Hlga?: InputMaybe<Scalars['bigint']['input']>;
  Hsettlement?: InputMaybe<Scalars['String']['input']>;
  Hward?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Lganame?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  LiqWasteFromEconomic?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHhchores?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHumanConve?: InputMaybe<Scalars['String']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumOfFemale?: InputMaybe<Scalars['bigint']['input']>;
  NumOfMale?: InputMaybe<Scalars['bigint']['input']>;
  OccupancyStatus?: InputMaybe<Scalars['String']['input']>;
  PayCompoundRate?: InputMaybe<Scalars['String']['input']>;
  PlaceForBathing?: InputMaybe<Scalars['String']['input']>;
  PlaceForCooking?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  SolidWasteDisposal?: InputMaybe<Scalars['String']['input']>;
  SourceDrinkingWater?: InputMaybe<Scalars['String']['input']>;
  SourceLighting?: InputMaybe<Scalars['String']['input']>;
  SuitableForResidence?: InputMaybe<Scalars['String']['input']>;
  TitleDeed?: InputMaybe<Scalars['String']['input']>;
  ToiletFacility?: InputMaybe<Scalars['String']['input']>;
  TotalNum?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WallsMaterial?: InputMaybe<Scalars['String']['input']>;
  Wardname?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_max_fields';
  Compoundowner?: Maybe<Scalars['String']['output']>;
  CookingFuel?: Maybe<Scalars['String']['output']>;
  Districtname?: Maybe<Scalars['String']['output']>;
  FeelEviction?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FloorMaterial?: Maybe<Scalars['String']['output']>;
  FreqSolidWasteCollection?: Maybe<Scalars['String']['output']>;
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhHeadName?: Maybe<Scalars['String']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Headofcompound?: Maybe<Scalars['String']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hsettlement?: Maybe<Scalars['String']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Lganame?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  LiqWasteFromEconomic?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHhchores?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHumanConve?: Maybe<Scalars['String']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  PayCompoundRate?: Maybe<Scalars['String']['output']>;
  PlaceForBathing?: Maybe<Scalars['String']['output']>;
  PlaceForCooking?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  SolidWasteDisposal?: Maybe<Scalars['String']['output']>;
  SourceDrinkingWater?: Maybe<Scalars['String']['output']>;
  SourceLighting?: Maybe<Scalars['String']['output']>;
  SuitableForResidence?: Maybe<Scalars['String']['output']>;
  TitleDeed?: Maybe<Scalars['String']['output']>;
  ToiletFacility?: Maybe<Scalars['String']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WallsMaterial?: Maybe<Scalars['String']['output']>;
  Wardname?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Max_Order_By = {
  Compoundowner?: InputMaybe<Order_By>;
  CookingFuel?: InputMaybe<Order_By>;
  Districtname?: InputMaybe<Order_By>;
  FeelEviction?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FloorMaterial?: InputMaybe<Order_By>;
  FreqSolidWasteCollection?: InputMaybe<Order_By>;
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhHeadName?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Headofcompound?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hsettlement?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Lganame?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  LiqWasteFromEconomic?: InputMaybe<Order_By>;
  LiqWasteFromHhchores?: InputMaybe<Order_By>;
  LiqWasteFromHumanConve?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  OccupancyStatus?: InputMaybe<Order_By>;
  PayCompoundRate?: InputMaybe<Order_By>;
  PlaceForBathing?: InputMaybe<Order_By>;
  PlaceForCooking?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  SolidWasteDisposal?: InputMaybe<Order_By>;
  SourceDrinkingWater?: InputMaybe<Order_By>;
  SourceLighting?: InputMaybe<Order_By>;
  SuitableForResidence?: InputMaybe<Order_By>;
  TitleDeed?: InputMaybe<Order_By>;
  ToiletFacility?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WallsMaterial?: InputMaybe<Order_By>;
  Wardname?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_min_fields';
  Compoundowner?: Maybe<Scalars['String']['output']>;
  CookingFuel?: Maybe<Scalars['String']['output']>;
  Districtname?: Maybe<Scalars['String']['output']>;
  FeelEviction?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FloorMaterial?: Maybe<Scalars['String']['output']>;
  FreqSolidWasteCollection?: Maybe<Scalars['String']['output']>;
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhHeadName?: Maybe<Scalars['String']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Headofcompound?: Maybe<Scalars['String']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hsettlement?: Maybe<Scalars['String']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Lganame?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  LiqWasteFromEconomic?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHhchores?: Maybe<Scalars['String']['output']>;
  LiqWasteFromHumanConve?: Maybe<Scalars['String']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  PayCompoundRate?: Maybe<Scalars['String']['output']>;
  PlaceForBathing?: Maybe<Scalars['String']['output']>;
  PlaceForCooking?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  SolidWasteDisposal?: Maybe<Scalars['String']['output']>;
  SourceDrinkingWater?: Maybe<Scalars['String']['output']>;
  SourceLighting?: Maybe<Scalars['String']['output']>;
  SuitableForResidence?: Maybe<Scalars['String']['output']>;
  TitleDeed?: Maybe<Scalars['String']['output']>;
  ToiletFacility?: Maybe<Scalars['String']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WallsMaterial?: Maybe<Scalars['String']['output']>;
  Wardname?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Min_Order_By = {
  Compoundowner?: InputMaybe<Order_By>;
  CookingFuel?: InputMaybe<Order_By>;
  Districtname?: InputMaybe<Order_By>;
  FeelEviction?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FloorMaterial?: InputMaybe<Order_By>;
  FreqSolidWasteCollection?: InputMaybe<Order_By>;
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhHeadName?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Headofcompound?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hsettlement?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Lganame?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  LiqWasteFromEconomic?: InputMaybe<Order_By>;
  LiqWasteFromHhchores?: InputMaybe<Order_By>;
  LiqWasteFromHumanConve?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  OccupancyStatus?: InputMaybe<Order_By>;
  PayCompoundRate?: InputMaybe<Order_By>;
  PlaceForBathing?: InputMaybe<Order_By>;
  PlaceForCooking?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  SolidWasteDisposal?: InputMaybe<Order_By>;
  SourceDrinkingWater?: InputMaybe<Order_By>;
  SourceLighting?: InputMaybe<Order_By>;
  SuitableForResidence?: InputMaybe<Order_By>;
  TitleDeed?: InputMaybe<Order_By>;
  ToiletFacility?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WallsMaterial?: InputMaybe<Order_By>;
  Wardname?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Order_By = {
  Compoundowner?: InputMaybe<Order_By>;
  CookingFuel?: InputMaybe<Order_By>;
  Districtname?: InputMaybe<Order_By>;
  FeelEviction?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FloorMaterial?: InputMaybe<Order_By>;
  FreqSolidWasteCollection?: InputMaybe<Order_By>;
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhHeadName?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Headofcompound?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hsettlement?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Lganame?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  LiqWasteFromEconomic?: InputMaybe<Order_By>;
  LiqWasteFromHhchores?: InputMaybe<Order_By>;
  LiqWasteFromHumanConve?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  OccupancyStatus?: InputMaybe<Order_By>;
  PayCompoundRate?: InputMaybe<Order_By>;
  PlaceForBathing?: InputMaybe<Order_By>;
  PlaceForCooking?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  SolidWasteDisposal?: InputMaybe<Order_By>;
  SourceDrinkingWater?: InputMaybe<Order_By>;
  SourceLighting?: InputMaybe<Order_By>;
  SuitableForResidence?: InputMaybe<Order_By>;
  TitleDeed?: InputMaybe<Order_By>;
  ToiletFacility?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WallsMaterial?: InputMaybe<Order_By>;
  Wardname?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export enum Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Select_Column {
  /** column name */
  Compoundowner = 'Compoundowner',
  /** column name */
  CookingFuel = 'CookingFuel',
  /** column name */
  Districtname = 'Districtname',
  /** column name */
  FeelEviction = 'FeelEviction',
  /** column name */
  File = 'File',
  /** column name */
  FloorMaterial = 'FloorMaterial',
  /** column name */
  FreqSolidWasteCollection = 'FreqSolidWasteCollection',
  /** column name */
  HEaCode = 'HEaCode',
  /** column name */
  HHeadPhone = 'HHeadPhone',
  /** column name */
  HHhHeadName = 'HHhHeadName',
  /** column name */
  HHhNumber = 'HHhNumber',
  /** column name */
  HStrNumber = 'HStrNumber',
  /** column name */
  Hdistrict = 'Hdistrict',
  /** column name */
  Headofcompound = 'Headofcompound',
  /** column name */
  Hlga = 'Hlga',
  /** column name */
  Hsettlement = 'Hsettlement',
  /** column name */
  Hward = 'Hward',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Lganame = 'Lganame',
  /** column name */
  Line = 'Line',
  /** column name */
  LiqWasteFromEconomic = 'LiqWasteFromEconomic',
  /** column name */
  LiqWasteFromHhchores = 'LiqWasteFromHhchores',
  /** column name */
  LiqWasteFromHumanConve = 'LiqWasteFromHumanConve',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  NumOfFemale = 'NumOfFemale',
  /** column name */
  NumOfMale = 'NumOfMale',
  /** column name */
  OccupancyStatus = 'OccupancyStatus',
  /** column name */
  PayCompoundRate = 'PayCompoundRate',
  /** column name */
  PlaceForBathing = 'PlaceForBathing',
  /** column name */
  PlaceForCooking = 'PlaceForCooking',
  /** column name */
  RoofMaterial = 'RoofMaterial',
  /** column name */
  SolidWasteDisposal = 'SolidWasteDisposal',
  /** column name */
  SourceDrinkingWater = 'SourceDrinkingWater',
  /** column name */
  SourceLighting = 'SourceLighting',
  /** column name */
  SuitableForResidence = 'SuitableForResidence',
  /** column name */
  TitleDeed = 'TitleDeed',
  /** column name */
  ToiletFacility = 'ToiletFacility',
  /** column name */
  TotalNum = 'TotalNum',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WallsMaterial = 'WallsMaterial',
  /** column name */
  Wardname = 'Wardname',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Set_Input = {
  Compoundowner?: InputMaybe<Scalars['String']['input']>;
  CookingFuel?: InputMaybe<Scalars['String']['input']>;
  Districtname?: InputMaybe<Scalars['String']['input']>;
  FeelEviction?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FloorMaterial?: InputMaybe<Scalars['String']['input']>;
  FreqSolidWasteCollection?: InputMaybe<Scalars['String']['input']>;
  HEaCode?: InputMaybe<Scalars['bigint']['input']>;
  HHeadPhone?: InputMaybe<Scalars['bigint']['input']>;
  HHhHeadName?: InputMaybe<Scalars['String']['input']>;
  HHhNumber?: InputMaybe<Scalars['bigint']['input']>;
  HStrNumber?: InputMaybe<Scalars['bigint']['input']>;
  Hdistrict?: InputMaybe<Scalars['bigint']['input']>;
  Headofcompound?: InputMaybe<Scalars['String']['input']>;
  Hlga?: InputMaybe<Scalars['bigint']['input']>;
  Hsettlement?: InputMaybe<Scalars['String']['input']>;
  Hward?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Lganame?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  LiqWasteFromEconomic?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHhchores?: InputMaybe<Scalars['String']['input']>;
  LiqWasteFromHumanConve?: InputMaybe<Scalars['String']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumOfFemale?: InputMaybe<Scalars['bigint']['input']>;
  NumOfMale?: InputMaybe<Scalars['bigint']['input']>;
  OccupancyStatus?: InputMaybe<Scalars['String']['input']>;
  PayCompoundRate?: InputMaybe<Scalars['String']['input']>;
  PlaceForBathing?: InputMaybe<Scalars['String']['input']>;
  PlaceForCooking?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  SolidWasteDisposal?: InputMaybe<Scalars['String']['input']>;
  SourceDrinkingWater?: InputMaybe<Scalars['String']['input']>;
  SourceLighting?: InputMaybe<Scalars['String']['input']>;
  SuitableForResidence?: InputMaybe<Scalars['String']['input']>;
  TitleDeed?: InputMaybe<Scalars['String']['input']>;
  ToiletFacility?: InputMaybe<Scalars['String']['input']>;
  TotalNum?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WallsMaterial?: InputMaybe<Scalars['String']['input']>;
  Wardname?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_stddev_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_stddev_pop_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Pop_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_stddev_samp_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Stddev_Samp_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_sum_fields';
  HEaCode?: Maybe<Scalars['bigint']['output']>;
  HHeadPhone?: Maybe<Scalars['bigint']['output']>;
  HHhNumber?: Maybe<Scalars['bigint']['output']>;
  HStrNumber?: Maybe<Scalars['bigint']['output']>;
  Hdistrict?: Maybe<Scalars['bigint']['output']>;
  Hlga?: Maybe<Scalars['bigint']['output']>;
  Hward?: Maybe<Scalars['bigint']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumOfFemale?: Maybe<Scalars['bigint']['output']>;
  NumOfMale?: Maybe<Scalars['bigint']['output']>;
  TotalNum?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Sum_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_var_pop_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Pop_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_var_samp_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Var_Samp_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_variance_fields';
  HEaCode?: Maybe<Scalars['Float']['output']>;
  HHeadPhone?: Maybe<Scalars['Float']['output']>;
  HHhNumber?: Maybe<Scalars['Float']['output']>;
  HStrNumber?: Maybe<Scalars['Float']['output']>;
  Hdistrict?: Maybe<Scalars['Float']['output']>;
  Hlga?: Maybe<Scalars['Float']['output']>;
  Hward?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumOfFemale?: Maybe<Scalars['Float']['output']>;
  NumOfMale?: Maybe<Scalars['Float']['output']>;
  TotalNum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
export type Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Variance_Order_By = {
  HEaCode?: InputMaybe<Order_By>;
  HHeadPhone?: InputMaybe<Order_By>;
  HHhNumber?: InputMaybe<Order_By>;
  HStrNumber?: InputMaybe<Order_By>;
  Hdistrict?: InputMaybe<Order_By>;
  Hlga?: InputMaybe<Order_By>;
  Hward?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumOfFemale?: InputMaybe<Order_By>;
  NumOfMale?: InputMaybe<Order_By>;
  TotalNum?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations';
  Active?: Maybe<Scalars['bigint']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Address1?: Maybe<Scalars['String']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dateofbirth?: Maybe<Scalars['String']['output']>;
  Dateofbirthandplaceofbirth?: Maybe<Scalars['String']['output']>;
  Dateupdate?: Maybe<Scalars['timestamptz']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd496?: Maybe<Scalars['String']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd498?: Maybe<Scalars['String']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd658?: Maybe<Scalars['String']['output']>;
  Dd1159?: Maybe<Scalars['String']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1163?: Maybe<Scalars['String']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1166?: Maybe<Scalars['String']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1169?: Maybe<Scalars['String']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1376?: Maybe<Scalars['timestamptz']['output']>;
  Dd1377?: Maybe<Scalars['String']['output']>;
  Dd1466?: Maybe<Scalars['String']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  Driverlicencereceiptnumber?: Maybe<Scalars['String']['output']>;
  Expirydate?: Maybe<Scalars['timestamptz']['output']>;
  Fathersfirstname?: Maybe<Scalars['String']['output']>;
  Fatherslastname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Firstname?: Maybe<Scalars['String']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Issuedate?: Maybe<Scalars['timestamptz']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mothersfirstname?: Maybe<Scalars['String']['output']>;
  Motherslastname?: Maybe<Scalars['String']['output']>;
  Nin?: Maybe<Scalars['String']['output']>;
  Occupation?: Maybe<Scalars['String']['output']>;
  Placeofbirth?: Maybe<Scalars['String']['output']>;
  Proffesion?: Maybe<Scalars['String']['output']>;
  Receiptno?: Maybe<Scalars['String']['output']>;
  Region?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations>;
};

/** aggregate fields of "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_avg_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Avg_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp = {
  Active?: InputMaybe<Bigint_Comparison_Exp>;
  Address?: InputMaybe<String_Comparison_Exp>;
  Address1?: InputMaybe<String_Comparison_Exp>;
  Cardnumbers?: InputMaybe<Float8_Comparison_Exp>;
  Dateofbirth?: InputMaybe<String_Comparison_Exp>;
  Dateofbirthandplaceofbirth?: InputMaybe<String_Comparison_Exp>;
  Dateupdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Dd151?: InputMaybe<Bigint_Comparison_Exp>;
  Dd168?: InputMaybe<Bigint_Comparison_Exp>;
  Dd381?: InputMaybe<Bigint_Comparison_Exp>;
  Dd493?: InputMaybe<Bigint_Comparison_Exp>;
  Dd495?: InputMaybe<Bigint_Comparison_Exp>;
  Dd496?: InputMaybe<String_Comparison_Exp>;
  Dd497?: InputMaybe<Bigint_Comparison_Exp>;
  Dd498?: InputMaybe<String_Comparison_Exp>;
  Dd547?: InputMaybe<Bigint_Comparison_Exp>;
  Dd658?: InputMaybe<String_Comparison_Exp>;
  Dd1159?: InputMaybe<String_Comparison_Exp>;
  Dd1162?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1163?: InputMaybe<String_Comparison_Exp>;
  Dd1165?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1166?: InputMaybe<String_Comparison_Exp>;
  Dd1168?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1169?: InputMaybe<String_Comparison_Exp>;
  Dd1171?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1376?: InputMaybe<Timestamptz_Comparison_Exp>;
  Dd1377?: InputMaybe<String_Comparison_Exp>;
  Dd1466?: InputMaybe<String_Comparison_Exp>;
  Dd1782?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1783?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1784?: InputMaybe<Bigint_Comparison_Exp>;
  Dd1785?: InputMaybe<Bigint_Comparison_Exp>;
  Driverlicencereceiptnumber?: InputMaybe<String_Comparison_Exp>;
  Expirydate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Fathersfirstname?: InputMaybe<String_Comparison_Exp>;
  Fatherslastname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Firstname?: InputMaybe<String_Comparison_Exp>;
  FkStation?: InputMaybe<Float8_Comparison_Exp>;
  FkUtilisateur?: InputMaybe<Float8_Comparison_Exp>;
  Height?: InputMaybe<Bigint_Comparison_Exp>;
  IdEnreg?: InputMaybe<Bigint_Comparison_Exp>;
  Issuedate?: InputMaybe<Timestamptz_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lastname?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Mothersfirstname?: InputMaybe<String_Comparison_Exp>;
  Motherslastname?: InputMaybe<String_Comparison_Exp>;
  Nin?: InputMaybe<String_Comparison_Exp>;
  Occupation?: InputMaybe<String_Comparison_Exp>;
  Placeofbirth?: InputMaybe<String_Comparison_Exp>;
  Proffesion?: InputMaybe<String_Comparison_Exp>;
  Receiptno?: InputMaybe<String_Comparison_Exp>;
  Region?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Inc_Input = {
  Active?: InputMaybe<Scalars['bigint']['input']>;
  Cardnumbers?: InputMaybe<Scalars['float8']['input']>;
  Dd151?: InputMaybe<Scalars['bigint']['input']>;
  Dd168?: InputMaybe<Scalars['bigint']['input']>;
  Dd381?: InputMaybe<Scalars['bigint']['input']>;
  Dd493?: InputMaybe<Scalars['bigint']['input']>;
  Dd495?: InputMaybe<Scalars['bigint']['input']>;
  Dd497?: InputMaybe<Scalars['bigint']['input']>;
  Dd547?: InputMaybe<Scalars['bigint']['input']>;
  Dd1162?: InputMaybe<Scalars['bigint']['input']>;
  Dd1165?: InputMaybe<Scalars['bigint']['input']>;
  Dd1168?: InputMaybe<Scalars['bigint']['input']>;
  Dd1171?: InputMaybe<Scalars['bigint']['input']>;
  Dd1782?: InputMaybe<Scalars['bigint']['input']>;
  Dd1783?: InputMaybe<Scalars['bigint']['input']>;
  Dd1784?: InputMaybe<Scalars['bigint']['input']>;
  Dd1785?: InputMaybe<Scalars['bigint']['input']>;
  FkStation?: InputMaybe<Scalars['float8']['input']>;
  FkUtilisateur?: InputMaybe<Scalars['float8']['input']>;
  Height?: InputMaybe<Scalars['bigint']['input']>;
  IdEnreg?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input = {
  Active?: InputMaybe<Scalars['bigint']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Address1?: InputMaybe<Scalars['String']['input']>;
  Cardnumbers?: InputMaybe<Scalars['float8']['input']>;
  Dateofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateofbirthandplaceofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateupdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd151?: InputMaybe<Scalars['bigint']['input']>;
  Dd168?: InputMaybe<Scalars['bigint']['input']>;
  Dd381?: InputMaybe<Scalars['bigint']['input']>;
  Dd493?: InputMaybe<Scalars['bigint']['input']>;
  Dd495?: InputMaybe<Scalars['bigint']['input']>;
  Dd496?: InputMaybe<Scalars['String']['input']>;
  Dd497?: InputMaybe<Scalars['bigint']['input']>;
  Dd498?: InputMaybe<Scalars['String']['input']>;
  Dd547?: InputMaybe<Scalars['bigint']['input']>;
  Dd658?: InputMaybe<Scalars['String']['input']>;
  Dd1159?: InputMaybe<Scalars['String']['input']>;
  Dd1162?: InputMaybe<Scalars['bigint']['input']>;
  Dd1163?: InputMaybe<Scalars['String']['input']>;
  Dd1165?: InputMaybe<Scalars['bigint']['input']>;
  Dd1166?: InputMaybe<Scalars['String']['input']>;
  Dd1168?: InputMaybe<Scalars['bigint']['input']>;
  Dd1169?: InputMaybe<Scalars['String']['input']>;
  Dd1171?: InputMaybe<Scalars['bigint']['input']>;
  Dd1376?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd1377?: InputMaybe<Scalars['String']['input']>;
  Dd1466?: InputMaybe<Scalars['String']['input']>;
  Dd1782?: InputMaybe<Scalars['bigint']['input']>;
  Dd1783?: InputMaybe<Scalars['bigint']['input']>;
  Dd1784?: InputMaybe<Scalars['bigint']['input']>;
  Dd1785?: InputMaybe<Scalars['bigint']['input']>;
  Driverlicencereceiptnumber?: InputMaybe<Scalars['String']['input']>;
  Expirydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Fathersfirstname?: InputMaybe<Scalars['String']['input']>;
  Fatherslastname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Firstname?: InputMaybe<Scalars['String']['input']>;
  FkStation?: InputMaybe<Scalars['float8']['input']>;
  FkUtilisateur?: InputMaybe<Scalars['float8']['input']>;
  Height?: InputMaybe<Scalars['bigint']['input']>;
  IdEnreg?: InputMaybe<Scalars['bigint']['input']>;
  Issuedate?: InputMaybe<Scalars['timestamptz']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mothersfirstname?: InputMaybe<Scalars['String']['input']>;
  Motherslastname?: InputMaybe<Scalars['String']['input']>;
  Nin?: InputMaybe<Scalars['String']['input']>;
  Occupation?: InputMaybe<Scalars['String']['input']>;
  Placeofbirth?: InputMaybe<Scalars['String']['input']>;
  Proffesion?: InputMaybe<Scalars['String']['input']>;
  Receiptno?: InputMaybe<Scalars['String']['input']>;
  Region?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_max_fields';
  Active?: Maybe<Scalars['bigint']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Address1?: Maybe<Scalars['String']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dateofbirth?: Maybe<Scalars['String']['output']>;
  Dateofbirthandplaceofbirth?: Maybe<Scalars['String']['output']>;
  Dateupdate?: Maybe<Scalars['timestamptz']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd496?: Maybe<Scalars['String']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd498?: Maybe<Scalars['String']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd658?: Maybe<Scalars['String']['output']>;
  Dd1159?: Maybe<Scalars['String']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1163?: Maybe<Scalars['String']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1166?: Maybe<Scalars['String']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1169?: Maybe<Scalars['String']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1376?: Maybe<Scalars['timestamptz']['output']>;
  Dd1377?: Maybe<Scalars['String']['output']>;
  Dd1466?: Maybe<Scalars['String']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  Driverlicencereceiptnumber?: Maybe<Scalars['String']['output']>;
  Expirydate?: Maybe<Scalars['timestamptz']['output']>;
  Fathersfirstname?: Maybe<Scalars['String']['output']>;
  Fatherslastname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Firstname?: Maybe<Scalars['String']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Issuedate?: Maybe<Scalars['timestamptz']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mothersfirstname?: Maybe<Scalars['String']['output']>;
  Motherslastname?: Maybe<Scalars['String']['output']>;
  Nin?: Maybe<Scalars['String']['output']>;
  Occupation?: Maybe<Scalars['String']['output']>;
  Placeofbirth?: Maybe<Scalars['String']['output']>;
  Proffesion?: Maybe<Scalars['String']['output']>;
  Receiptno?: Maybe<Scalars['String']['output']>;
  Region?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Max_Order_By = {
  Active?: InputMaybe<Order_By>;
  Address?: InputMaybe<Order_By>;
  Address1?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dateofbirth?: InputMaybe<Order_By>;
  Dateofbirthandplaceofbirth?: InputMaybe<Order_By>;
  Dateupdate?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd496?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd498?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd658?: InputMaybe<Order_By>;
  Dd1159?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1163?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1166?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1169?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1376?: InputMaybe<Order_By>;
  Dd1377?: InputMaybe<Order_By>;
  Dd1466?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  Driverlicencereceiptnumber?: InputMaybe<Order_By>;
  Expirydate?: InputMaybe<Order_By>;
  Fathersfirstname?: InputMaybe<Order_By>;
  Fatherslastname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Firstname?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Issuedate?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mothersfirstname?: InputMaybe<Order_By>;
  Motherslastname?: InputMaybe<Order_By>;
  Nin?: InputMaybe<Order_By>;
  Occupation?: InputMaybe<Order_By>;
  Placeofbirth?: InputMaybe<Order_By>;
  Proffesion?: InputMaybe<Order_By>;
  Receiptno?: InputMaybe<Order_By>;
  Region?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_min_fields';
  Active?: Maybe<Scalars['bigint']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Address1?: Maybe<Scalars['String']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dateofbirth?: Maybe<Scalars['String']['output']>;
  Dateofbirthandplaceofbirth?: Maybe<Scalars['String']['output']>;
  Dateupdate?: Maybe<Scalars['timestamptz']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd496?: Maybe<Scalars['String']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd498?: Maybe<Scalars['String']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd658?: Maybe<Scalars['String']['output']>;
  Dd1159?: Maybe<Scalars['String']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1163?: Maybe<Scalars['String']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1166?: Maybe<Scalars['String']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1169?: Maybe<Scalars['String']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1376?: Maybe<Scalars['timestamptz']['output']>;
  Dd1377?: Maybe<Scalars['String']['output']>;
  Dd1466?: Maybe<Scalars['String']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  Driverlicencereceiptnumber?: Maybe<Scalars['String']['output']>;
  Expirydate?: Maybe<Scalars['timestamptz']['output']>;
  Fathersfirstname?: Maybe<Scalars['String']['output']>;
  Fatherslastname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Firstname?: Maybe<Scalars['String']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Issuedate?: Maybe<Scalars['timestamptz']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mothersfirstname?: Maybe<Scalars['String']['output']>;
  Motherslastname?: Maybe<Scalars['String']['output']>;
  Nin?: Maybe<Scalars['String']['output']>;
  Occupation?: Maybe<Scalars['String']['output']>;
  Placeofbirth?: Maybe<Scalars['String']['output']>;
  Proffesion?: Maybe<Scalars['String']['output']>;
  Receiptno?: Maybe<Scalars['String']['output']>;
  Region?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Min_Order_By = {
  Active?: InputMaybe<Order_By>;
  Address?: InputMaybe<Order_By>;
  Address1?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dateofbirth?: InputMaybe<Order_By>;
  Dateofbirthandplaceofbirth?: InputMaybe<Order_By>;
  Dateupdate?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd496?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd498?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd658?: InputMaybe<Order_By>;
  Dd1159?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1163?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1166?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1169?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1376?: InputMaybe<Order_By>;
  Dd1377?: InputMaybe<Order_By>;
  Dd1466?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  Driverlicencereceiptnumber?: InputMaybe<Order_By>;
  Expirydate?: InputMaybe<Order_By>;
  Fathersfirstname?: InputMaybe<Order_By>;
  Fatherslastname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Firstname?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Issuedate?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mothersfirstname?: InputMaybe<Order_By>;
  Motherslastname?: InputMaybe<Order_By>;
  Nin?: InputMaybe<Order_By>;
  Occupation?: InputMaybe<Order_By>;
  Placeofbirth?: InputMaybe<Order_By>;
  Proffesion?: InputMaybe<Order_By>;
  Receiptno?: InputMaybe<Order_By>;
  Region?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Order_By = {
  Active?: InputMaybe<Order_By>;
  Address?: InputMaybe<Order_By>;
  Address1?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dateofbirth?: InputMaybe<Order_By>;
  Dateofbirthandplaceofbirth?: InputMaybe<Order_By>;
  Dateupdate?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd496?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd498?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd658?: InputMaybe<Order_By>;
  Dd1159?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1163?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1166?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1169?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1376?: InputMaybe<Order_By>;
  Dd1377?: InputMaybe<Order_By>;
  Dd1466?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  Driverlicencereceiptnumber?: InputMaybe<Order_By>;
  Expirydate?: InputMaybe<Order_By>;
  Fathersfirstname?: InputMaybe<Order_By>;
  Fatherslastname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Firstname?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Issuedate?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mothersfirstname?: InputMaybe<Order_By>;
  Motherslastname?: InputMaybe<Order_By>;
  Nin?: InputMaybe<Order_By>;
  Occupation?: InputMaybe<Order_By>;
  Placeofbirth?: InputMaybe<Order_By>;
  Proffesion?: InputMaybe<Order_By>;
  Receiptno?: InputMaybe<Order_By>;
  Region?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export enum Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column {
  /** column name */
  Active = 'Active',
  /** column name */
  Address = 'Address',
  /** column name */
  Address1 = 'Address1',
  /** column name */
  Cardnumbers = 'Cardnumbers',
  /** column name */
  Dateofbirth = 'Dateofbirth',
  /** column name */
  Dateofbirthandplaceofbirth = 'Dateofbirthandplaceofbirth',
  /** column name */
  Dateupdate = 'Dateupdate',
  /** column name */
  Dd151 = 'Dd151',
  /** column name */
  Dd168 = 'Dd168',
  /** column name */
  Dd381 = 'Dd381',
  /** column name */
  Dd493 = 'Dd493',
  /** column name */
  Dd495 = 'Dd495',
  /** column name */
  Dd496 = 'Dd496',
  /** column name */
  Dd497 = 'Dd497',
  /** column name */
  Dd498 = 'Dd498',
  /** column name */
  Dd547 = 'Dd547',
  /** column name */
  Dd658 = 'Dd658',
  /** column name */
  Dd1159 = 'Dd1159',
  /** column name */
  Dd1162 = 'Dd1162',
  /** column name */
  Dd1163 = 'Dd1163',
  /** column name */
  Dd1165 = 'Dd1165',
  /** column name */
  Dd1166 = 'Dd1166',
  /** column name */
  Dd1168 = 'Dd1168',
  /** column name */
  Dd1169 = 'Dd1169',
  /** column name */
  Dd1171 = 'Dd1171',
  /** column name */
  Dd1376 = 'Dd1376',
  /** column name */
  Dd1377 = 'Dd1377',
  /** column name */
  Dd1466 = 'Dd1466',
  /** column name */
  Dd1782 = 'Dd1782',
  /** column name */
  Dd1783 = 'Dd1783',
  /** column name */
  Dd1784 = 'Dd1784',
  /** column name */
  Dd1785 = 'Dd1785',
  /** column name */
  Driverlicencereceiptnumber = 'Driverlicencereceiptnumber',
  /** column name */
  Expirydate = 'Expirydate',
  /** column name */
  Fathersfirstname = 'Fathersfirstname',
  /** column name */
  Fatherslastname = 'Fatherslastname',
  /** column name */
  File = 'File',
  /** column name */
  Firstname = 'Firstname',
  /** column name */
  FkStation = 'FkStation',
  /** column name */
  FkUtilisateur = 'FkUtilisateur',
  /** column name */
  Height = 'Height',
  /** column name */
  IdEnreg = 'IdEnreg',
  /** column name */
  Issuedate = 'Issuedate',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lastname = 'Lastname',
  /** column name */
  Line = 'Line',
  /** column name */
  Mothersfirstname = 'Mothersfirstname',
  /** column name */
  Motherslastname = 'Motherslastname',
  /** column name */
  Nin = 'Nin',
  /** column name */
  Occupation = 'Occupation',
  /** column name */
  Placeofbirth = 'Placeofbirth',
  /** column name */
  Proffesion = 'Proffesion',
  /** column name */
  Receiptno = 'Receiptno',
  /** column name */
  Region = 'Region',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Set_Input = {
  Active?: InputMaybe<Scalars['bigint']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Address1?: InputMaybe<Scalars['String']['input']>;
  Cardnumbers?: InputMaybe<Scalars['float8']['input']>;
  Dateofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateofbirthandplaceofbirth?: InputMaybe<Scalars['String']['input']>;
  Dateupdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd151?: InputMaybe<Scalars['bigint']['input']>;
  Dd168?: InputMaybe<Scalars['bigint']['input']>;
  Dd381?: InputMaybe<Scalars['bigint']['input']>;
  Dd493?: InputMaybe<Scalars['bigint']['input']>;
  Dd495?: InputMaybe<Scalars['bigint']['input']>;
  Dd496?: InputMaybe<Scalars['String']['input']>;
  Dd497?: InputMaybe<Scalars['bigint']['input']>;
  Dd498?: InputMaybe<Scalars['String']['input']>;
  Dd547?: InputMaybe<Scalars['bigint']['input']>;
  Dd658?: InputMaybe<Scalars['String']['input']>;
  Dd1159?: InputMaybe<Scalars['String']['input']>;
  Dd1162?: InputMaybe<Scalars['bigint']['input']>;
  Dd1163?: InputMaybe<Scalars['String']['input']>;
  Dd1165?: InputMaybe<Scalars['bigint']['input']>;
  Dd1166?: InputMaybe<Scalars['String']['input']>;
  Dd1168?: InputMaybe<Scalars['bigint']['input']>;
  Dd1169?: InputMaybe<Scalars['String']['input']>;
  Dd1171?: InputMaybe<Scalars['bigint']['input']>;
  Dd1376?: InputMaybe<Scalars['timestamptz']['input']>;
  Dd1377?: InputMaybe<Scalars['String']['input']>;
  Dd1466?: InputMaybe<Scalars['String']['input']>;
  Dd1782?: InputMaybe<Scalars['bigint']['input']>;
  Dd1783?: InputMaybe<Scalars['bigint']['input']>;
  Dd1784?: InputMaybe<Scalars['bigint']['input']>;
  Dd1785?: InputMaybe<Scalars['bigint']['input']>;
  Driverlicencereceiptnumber?: InputMaybe<Scalars['String']['input']>;
  Expirydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Fathersfirstname?: InputMaybe<Scalars['String']['input']>;
  Fatherslastname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Firstname?: InputMaybe<Scalars['String']['input']>;
  FkStation?: InputMaybe<Scalars['float8']['input']>;
  FkUtilisateur?: InputMaybe<Scalars['float8']['input']>;
  Height?: InputMaybe<Scalars['bigint']['input']>;
  IdEnreg?: InputMaybe<Scalars['bigint']['input']>;
  Issuedate?: InputMaybe<Scalars['timestamptz']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mothersfirstname?: InputMaybe<Scalars['String']['input']>;
  Motherslastname?: InputMaybe<Scalars['String']['input']>;
  Nin?: InputMaybe<Scalars['String']['input']>;
  Occupation?: InputMaybe<Scalars['String']['input']>;
  Placeofbirth?: InputMaybe<Scalars['String']['input']>;
  Proffesion?: InputMaybe<Scalars['String']['input']>;
  Receiptno?: InputMaybe<Scalars['String']['input']>;
  Region?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_stddev_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_stddev_pop_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Pop_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_stddev_samp_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Stddev_Samp_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_sum_fields';
  Active?: Maybe<Scalars['bigint']['output']>;
  Cardnumbers?: Maybe<Scalars['float8']['output']>;
  Dd151?: Maybe<Scalars['bigint']['output']>;
  Dd168?: Maybe<Scalars['bigint']['output']>;
  Dd381?: Maybe<Scalars['bigint']['output']>;
  Dd493?: Maybe<Scalars['bigint']['output']>;
  Dd495?: Maybe<Scalars['bigint']['output']>;
  Dd497?: Maybe<Scalars['bigint']['output']>;
  Dd547?: Maybe<Scalars['bigint']['output']>;
  Dd1162?: Maybe<Scalars['bigint']['output']>;
  Dd1165?: Maybe<Scalars['bigint']['output']>;
  Dd1168?: Maybe<Scalars['bigint']['output']>;
  Dd1171?: Maybe<Scalars['bigint']['output']>;
  Dd1782?: Maybe<Scalars['bigint']['output']>;
  Dd1783?: Maybe<Scalars['bigint']['output']>;
  Dd1784?: Maybe<Scalars['bigint']['output']>;
  Dd1785?: Maybe<Scalars['bigint']['output']>;
  FkStation?: Maybe<Scalars['float8']['output']>;
  FkUtilisateur?: Maybe<Scalars['float8']['output']>;
  Height?: Maybe<Scalars['bigint']['output']>;
  IdEnreg?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Sum_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_var_pop_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Pop_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_var_samp_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Var_Samp_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_variance_fields';
  Active?: Maybe<Scalars['Float']['output']>;
  Cardnumbers?: Maybe<Scalars['Float']['output']>;
  Dd151?: Maybe<Scalars['Float']['output']>;
  Dd168?: Maybe<Scalars['Float']['output']>;
  Dd381?: Maybe<Scalars['Float']['output']>;
  Dd493?: Maybe<Scalars['Float']['output']>;
  Dd495?: Maybe<Scalars['Float']['output']>;
  Dd497?: Maybe<Scalars['Float']['output']>;
  Dd547?: Maybe<Scalars['Float']['output']>;
  Dd1162?: Maybe<Scalars['Float']['output']>;
  Dd1165?: Maybe<Scalars['Float']['output']>;
  Dd1168?: Maybe<Scalars['Float']['output']>;
  Dd1171?: Maybe<Scalars['Float']['output']>;
  Dd1782?: Maybe<Scalars['Float']['output']>;
  Dd1783?: Maybe<Scalars['Float']['output']>;
  Dd1784?: Maybe<Scalars['Float']['output']>;
  Dd1785?: Maybe<Scalars['Float']['output']>;
  FkStation?: Maybe<Scalars['Float']['output']>;
  FkUtilisateur?: Maybe<Scalars['Float']['output']>;
  Height?: Maybe<Scalars['Float']['output']>;
  IdEnreg?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
export type Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Variance_Order_By = {
  Active?: InputMaybe<Order_By>;
  Cardnumbers?: InputMaybe<Order_By>;
  Dd151?: InputMaybe<Order_By>;
  Dd168?: InputMaybe<Order_By>;
  Dd381?: InputMaybe<Order_By>;
  Dd493?: InputMaybe<Order_By>;
  Dd495?: InputMaybe<Order_By>;
  Dd497?: InputMaybe<Order_By>;
  Dd547?: InputMaybe<Order_By>;
  Dd1162?: InputMaybe<Order_By>;
  Dd1165?: InputMaybe<Order_By>;
  Dd1168?: InputMaybe<Order_By>;
  Dd1171?: InputMaybe<Order_By>;
  Dd1782?: InputMaybe<Order_By>;
  Dd1783?: InputMaybe<Order_By>;
  Dd1784?: InputMaybe<Order_By>;
  Dd1785?: InputMaybe<Order_By>;
  FkStation?: InputMaybe<Order_By>;
  FkUtilisateur?: InputMaybe<Order_By>;
  Height?: InputMaybe<Order_By>;
  IdEnreg?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax';
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CommericalResidentialTax". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  Fname?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lname?: InputMaybe<String_Comparison_Exp>;
  Paidamount?: InputMaybe<Float8_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxmonth?: InputMaybe<Bigint_Comparison_Exp>;
  Taxname?: InputMaybe<String_Comparison_Exp>;
  Taxyear?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Order_By = {
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export enum Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  Fname = 'Fname',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lname = 'Lname',
  /** column name */
  Paidamount = 'Paidamount',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxmonth = 'Taxmonth',
  /** column name */
  Taxname = 'Taxname',
  /** column name */
  Taxyear = 'Taxyear',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CommericalResidentialTax_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CommericalResidentialTax" */
export type Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directorname?: Maybe<Scalars['String']['output']>;
  Directorremunerationid?: Maybe<Scalars['String']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_avg_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Avg_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnDirector". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp = {
  Directorfee?: InputMaybe<Float8_Comparison_Exp>;
  Directorname?: InputMaybe<String_Comparison_Exp>;
  Directorremunerationid?: InputMaybe<String_Comparison_Exp>;
  Directortin?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Otherallowance?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Salary?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Total?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Inc_Input = {
  Directorfee?: InputMaybe<Scalars['float8']['input']>;
  Directortin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Otherallowance?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Total?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Insert_Input = {
  Directorfee?: InputMaybe<Scalars['float8']['input']>;
  Directorname?: InputMaybe<Scalars['String']['input']>;
  Directorremunerationid?: InputMaybe<Scalars['String']['input']>;
  Directortin?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Otherallowance?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Total?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_max_fields';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directorname?: Maybe<Scalars['String']['output']>;
  Directorremunerationid?: Maybe<Scalars['String']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Max_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directorname?: InputMaybe<Order_By>;
  Directorremunerationid?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_min_fields';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directorname?: Maybe<Scalars['String']['output']>;
  Directorremunerationid?: Maybe<Scalars['String']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Min_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directorname?: InputMaybe<Order_By>;
  Directorremunerationid?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directorname?: InputMaybe<Order_By>;
  Directorremunerationid?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Select_Column {
  /** column name */
  Directorfee = 'Directorfee',
  /** column name */
  Directorname = 'Directorname',
  /** column name */
  Directorremunerationid = 'Directorremunerationid',
  /** column name */
  Directortin = 'Directortin',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Otherallowance = 'Otherallowance',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Salary = 'Salary',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Total = 'Total',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Set_Input = {
  Directorfee?: InputMaybe<Scalars['float8']['input']>;
  Directorname?: InputMaybe<Scalars['String']['input']>;
  Directorremunerationid?: InputMaybe<Scalars['String']['input']>;
  Directortin?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Otherallowance?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Total?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_stddev_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_stddev_pop_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Pop_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_stddev_samp_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Stddev_Samp_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_sum_fields';
  Directorfee?: Maybe<Scalars['float8']['output']>;
  Directortin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Otherallowance?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Total?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Sum_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_var_pop_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Pop_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_var_samp_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Var_Samp_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnDirector_variance_fields';
  Directorfee?: Maybe<Scalars['Float']['output']>;
  Directortin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Otherallowance?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Total?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnDirector" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Variance_Order_By = {
  Directorfee?: InputMaybe<Order_By>;
  Directortin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Otherallowance?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Total?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_avg_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Avg_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnExemption". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp = {
  Amount?: InputMaybe<Float8_Comparison_Exp>;
  Basis?: InputMaybe<Float8_Comparison_Exp>;
  Exemptionid?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Incometype?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Taxliability?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxrate?: InputMaybe<Float8_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Inc_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['float8']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Taxrate?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Insert_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['float8']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Taxrate?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_max_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Max_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_min_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Min_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Select_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  Basis = 'Basis',
  /** column name */
  Exemptionid = 'Exemptionid',
  /** column name */
  File = 'File',
  /** column name */
  Incometype = 'Incometype',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxliability = 'Taxliability',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Taxrate = 'Taxrate',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Set_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['float8']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Taxrate?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_stddev_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_stddev_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_stddev_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Stddev_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_sum_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['float8']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Taxrate?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Sum_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_var_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_var_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Var_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnExemption_variance_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Basis?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnExemption" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Variance_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnLoss". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lossid?: InputMaybe<String_Comparison_Exp>;
  Lossmade?: InputMaybe<Float8_Comparison_Exp>;
  Lossoutstanding?: InputMaybe<Float8_Comparison_Exp>;
  Losssetoff?: InputMaybe<Float8_Comparison_Exp>;
  Lossyear?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Yearleft?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['float8']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['float8']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lossid = 'Lossid',
  /** column name */
  Lossmade = 'Lossmade',
  /** column name */
  Lossoutstanding = 'Lossoutstanding',
  /** column name */
  Losssetoff = 'Losssetoff',
  /** column name */
  Lossyear = 'Lossyear',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Yearleft = 'Yearleft'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['float8']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['float8']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnLoss_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnLoss" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership';
  File?: Maybe<Scalars['String']['output']>;
  Interest?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Partnername?: Maybe<Scalars['String']['output']>;
  Partnershipid?: Maybe<Scalars['String']['output']>;
  Partnerstatus?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_avg_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Avg_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnPartnership". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  Interest?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Partnername?: InputMaybe<String_Comparison_Exp>;
  Partnershipid?: InputMaybe<String_Comparison_Exp>;
  Partnerstatus?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Salary?: InputMaybe<Bigint_Comparison_Exp>;
  Sharebalance?: InputMaybe<Float8_Comparison_Exp>;
  Shareratio?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Inc_Input = {
  Interest?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['bigint']['input']>;
  Sharebalance?: InputMaybe<Scalars['float8']['input']>;
  Shareratio?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Interest?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Partnername?: InputMaybe<Scalars['String']['input']>;
  Partnershipid?: InputMaybe<Scalars['String']['input']>;
  Partnerstatus?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['bigint']['input']>;
  Sharebalance?: InputMaybe<Scalars['float8']['input']>;
  Shareratio?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  Interest?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Partnername?: Maybe<Scalars['String']['output']>;
  Partnershipid?: Maybe<Scalars['String']['output']>;
  Partnerstatus?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  Interest?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Partnername?: InputMaybe<Order_By>;
  Partnershipid?: InputMaybe<Order_By>;
  Partnerstatus?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  Interest?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Partnername?: Maybe<Scalars['String']['output']>;
  Partnershipid?: Maybe<Scalars['String']['output']>;
  Partnerstatus?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  Interest?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Partnername?: InputMaybe<Order_By>;
  Partnershipid?: InputMaybe<Order_By>;
  Partnerstatus?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Order_By = {
  File?: InputMaybe<Order_By>;
  Interest?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Partnername?: InputMaybe<Order_By>;
  Partnershipid?: InputMaybe<Order_By>;
  Partnerstatus?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  Interest = 'Interest',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Partnername = 'Partnername',
  /** column name */
  Partnershipid = 'Partnershipid',
  /** column name */
  Partnerstatus = 'Partnerstatus',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Salary = 'Salary',
  /** column name */
  Sharebalance = 'Sharebalance',
  /** column name */
  Shareratio = 'Shareratio',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  Interest?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Partnername?: InputMaybe<Scalars['String']['input']>;
  Partnershipid?: InputMaybe<Scalars['String']['input']>;
  Partnerstatus?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Salary?: InputMaybe<Scalars['bigint']['input']>;
  Sharebalance?: InputMaybe<Scalars['float8']['input']>;
  Shareratio?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_stddev_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_stddev_pop_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Pop_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_stddev_samp_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Stddev_Samp_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_sum_fields';
  Interest?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Salary?: Maybe<Scalars['bigint']['output']>;
  Sharebalance?: Maybe<Scalars['float8']['output']>;
  Shareratio?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Sum_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_var_pop_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Pop_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_var_samp_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Var_Samp_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPartnership_variance_fields';
  Interest?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Salary?: Maybe<Scalars['Float']['output']>;
  Sharebalance?: Maybe<Scalars['Float']['output']>;
  Shareratio?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnPartnership" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Variance_Order_By = {
  Interest?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salary?: InputMaybe<Order_By>;
  Sharebalance?: InputMaybe<Order_By>;
  Shareratio?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnPropertyRent". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp = {
  Annualrent?: InputMaybe<Float8_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Float8_Comparison_Exp>;
  Commercialused?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  Tenancystartdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_max_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_min_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  Tenancystartdate = 'Tenancystartdate',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Set_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_sum_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnPropertyRent_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnRentReceived". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp = {
  Annualrent?: InputMaybe<Bigint_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Bigint_Comparison_Exp>;
  Commercialused?: InputMaybe<Bigint_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['bigint']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['bigint']['input']>;
  Landlordtin?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Residentialused?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['bigint']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_max_fields';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_min_fields';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Set_Input = {
  Annualrent?: InputMaybe<Scalars['bigint']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_sum_fields';
  Annualrent?: Maybe<Scalars['bigint']['output']>;
  Annualrentpaid?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['bigint']['output']>;
  Landlordtin?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Residentialused?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentReceived_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturnRentRecieved". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp = {
  Annualrent?: InputMaybe<Float8_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Float8_Comparison_Exp>;
  Commercialused?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  Tenancystartdate?: InputMaybe<String_Comparison_Exp>;
  Tin?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['float8']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Residentialused?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_max_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_min_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  Tenancystartdate = 'Tenancystartdate',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Set_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_sum_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['float8']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Residentialused?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturnRentRecieved_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Commercialused?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Residentialused?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
export type Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_CorporateReturn>;
};

/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_avg_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Avg_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_CorporateReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp = {
  Accural?: InputMaybe<Float8_Comparison_Exp>;
  Additionalallowance?: InputMaybe<Bigint_Comparison_Exp>;
  Allowancebroughtforward?: InputMaybe<Float8_Comparison_Exp>;
  Allowanceutilised?: InputMaybe<Float8_Comparison_Exp>;
  Businessincome?: InputMaybe<Float8_Comparison_Exp>;
  Capitalallowance?: InputMaybe<Float8_Comparison_Exp>;
  Cashbank?: InputMaybe<Float8_Comparison_Exp>;
  Closingstock?: InputMaybe<Float8_Comparison_Exp>;
  Costofsale?: InputMaybe<Float8_Comparison_Exp>;
  Creditor?: InputMaybe<Float8_Comparison_Exp>;
  Debtor?: InputMaybe<Float8_Comparison_Exp>;
  Depreciation?: InputMaybe<Float8_Comparison_Exp>;
  Exemptincome?: InputMaybe<Float8_Comparison_Exp>;
  Exporter?: InputMaybe<Bigint_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Fixedasset?: InputMaybe<Float8_Comparison_Exp>;
  Foreignexchangeloss?: InputMaybe<Float8_Comparison_Exp>;
  Foreigntaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  Fringebenefittax?: InputMaybe<Float8_Comparison_Exp>;
  Grossprofit?: InputMaybe<Float8_Comparison_Exp>;
  Importer?: InputMaybe<Bigint_Comparison_Exp>;
  Instalmentpayment?: InputMaybe<Float8_Comparison_Exp>;
  Interestexpense?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longtermliability?: InputMaybe<Float8_Comparison_Exp>;
  Lossbroughtforward?: InputMaybe<Float8_Comparison_Exp>;
  Openingstock?: InputMaybe<Float8_Comparison_Exp>;
  Operatingexpense?: InputMaybe<Float8_Comparison_Exp>;
  Otherasset?: InputMaybe<Float8_Comparison_Exp>;
  Othercurrentasset?: InputMaybe<Float8_Comparison_Exp>;
  Othercurrentliability?: InputMaybe<Float8_Comparison_Exp>;
  Otherdeduction?: InputMaybe<Float8_Comparison_Exp>;
  Otherexpense?: InputMaybe<Float8_Comparison_Exp>;
  Otherincome?: InputMaybe<Float8_Comparison_Exp>;
  Otherliability?: InputMaybe<Float8_Comparison_Exp>;
  Overpayment?: InputMaybe<Float8_Comparison_Exp>;
  Propertyincome?: InputMaybe<Float8_Comparison_Exp>;
  Purchase?: InputMaybe<Float8_Comparison_Exp>;
  Returngroup?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Salarywage?: InputMaybe<Float8_Comparison_Exp>;
  Sharehholderfund?: InputMaybe<Float8_Comparison_Exp>;
  Sicexemption?: InputMaybe<Float8_Comparison_Exp>;
  Stock?: InputMaybe<Float8_Comparison_Exp>;
  Tax1stschedule?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Totalasset?: InputMaybe<Float8_Comparison_Exp>;
  Totalliability?: InputMaybe<Float8_Comparison_Exp>;
  Turnovertax?: InputMaybe<Float8_Comparison_Exp>;
  Unallowablededuction?: InputMaybe<Float8_Comparison_Exp>;
  Unutilisedallowance?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Withholdingtaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  Yearallowance?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Inc_Input = {
  Accural?: InputMaybe<Scalars['float8']['input']>;
  Additionalallowance?: InputMaybe<Scalars['bigint']['input']>;
  Allowancebroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Allowanceutilised?: InputMaybe<Scalars['float8']['input']>;
  Businessincome?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Cashbank?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Creditor?: InputMaybe<Scalars['float8']['input']>;
  Debtor?: InputMaybe<Scalars['float8']['input']>;
  Depreciation?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['bigint']['input']>;
  Fixedasset?: InputMaybe<Scalars['float8']['input']>;
  Foreignexchangeloss?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossprofit?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['bigint']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Interestexpense?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longtermliability?: InputMaybe<Scalars['float8']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Operatingexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentliability?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Otherliability?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Propertyincome?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['bigint']['input']>;
  Salarywage?: InputMaybe<Scalars['float8']['input']>;
  Sharehholderfund?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Stock?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totalasset?: InputMaybe<Scalars['float8']['input']>;
  Totalliability?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowablededuction?: InputMaybe<Scalars['float8']['input']>;
  Unutilisedallowance?: InputMaybe<Scalars['float8']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Yearallowance?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Insert_Input = {
  Accural?: InputMaybe<Scalars['float8']['input']>;
  Additionalallowance?: InputMaybe<Scalars['bigint']['input']>;
  Allowancebroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Allowanceutilised?: InputMaybe<Scalars['float8']['input']>;
  Businessincome?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Cashbank?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Creditor?: InputMaybe<Scalars['float8']['input']>;
  Debtor?: InputMaybe<Scalars['float8']['input']>;
  Depreciation?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fixedasset?: InputMaybe<Scalars['float8']['input']>;
  Foreignexchangeloss?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossprofit?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['bigint']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Interestexpense?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longtermliability?: InputMaybe<Scalars['float8']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Operatingexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentliability?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Otherliability?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Propertyincome?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Salarywage?: InputMaybe<Scalars['float8']['input']>;
  Sharehholderfund?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Stock?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totalasset?: InputMaybe<Scalars['float8']['input']>;
  Totalliability?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowablededuction?: InputMaybe<Scalars['float8']['input']>;
  Unutilisedallowance?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Yearallowance?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_max_fields';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Max_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_min_fields';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Min_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_CorporateReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_CorporateReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export enum Rtcs_Gm_Dev_Gra_CorporateReturn_Select_Column {
  /** column name */
  Accural = 'Accural',
  /** column name */
  Additionalallowance = 'Additionalallowance',
  /** column name */
  Allowancebroughtforward = 'Allowancebroughtforward',
  /** column name */
  Allowanceutilised = 'Allowanceutilised',
  /** column name */
  Businessincome = 'Businessincome',
  /** column name */
  Capitalallowance = 'Capitalallowance',
  /** column name */
  Cashbank = 'Cashbank',
  /** column name */
  Closingstock = 'Closingstock',
  /** column name */
  Costofsale = 'Costofsale',
  /** column name */
  Creditor = 'Creditor',
  /** column name */
  Debtor = 'Debtor',
  /** column name */
  Depreciation = 'Depreciation',
  /** column name */
  Exemptincome = 'Exemptincome',
  /** column name */
  Exporter = 'Exporter',
  /** column name */
  File = 'File',
  /** column name */
  Fixedasset = 'Fixedasset',
  /** column name */
  Foreignexchangeloss = 'Foreignexchangeloss',
  /** column name */
  Foreigntaxcredit = 'Foreigntaxcredit',
  /** column name */
  Fringebenefittax = 'Fringebenefittax',
  /** column name */
  Grossprofit = 'Grossprofit',
  /** column name */
  Importer = 'Importer',
  /** column name */
  Instalmentpayment = 'Instalmentpayment',
  /** column name */
  Interestexpense = 'Interestexpense',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Longtermliability = 'Longtermliability',
  /** column name */
  Lossbroughtforward = 'Lossbroughtforward',
  /** column name */
  Openingstock = 'Openingstock',
  /** column name */
  Operatingexpense = 'Operatingexpense',
  /** column name */
  Otherasset = 'Otherasset',
  /** column name */
  Othercurrentasset = 'Othercurrentasset',
  /** column name */
  Othercurrentliability = 'Othercurrentliability',
  /** column name */
  Otherdeduction = 'Otherdeduction',
  /** column name */
  Otherexpense = 'Otherexpense',
  /** column name */
  Otherincome = 'Otherincome',
  /** column name */
  Otherliability = 'Otherliability',
  /** column name */
  Overpayment = 'Overpayment',
  /** column name */
  Propertyincome = 'Propertyincome',
  /** column name */
  Purchase = 'Purchase',
  /** column name */
  Returngroup = 'Returngroup',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Salarywage = 'Salarywage',
  /** column name */
  Sharehholderfund = 'Sharehholderfund',
  /** column name */
  Sicexemption = 'Sicexemption',
  /** column name */
  Stock = 'Stock',
  /** column name */
  Tax1stschedule = 'Tax1stschedule',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Totalasset = 'Totalasset',
  /** column name */
  Totalliability = 'Totalliability',
  /** column name */
  Turnovertax = 'Turnovertax',
  /** column name */
  Unallowablededuction = 'Unallowablededuction',
  /** column name */
  Unutilisedallowance = 'Unutilisedallowance',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Withholdingtaxcredit = 'Withholdingtaxcredit',
  /** column name */
  Yearallowance = 'Yearallowance'
}

/** input type for updating data in table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Set_Input = {
  Accural?: InputMaybe<Scalars['float8']['input']>;
  Additionalallowance?: InputMaybe<Scalars['bigint']['input']>;
  Allowancebroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Allowanceutilised?: InputMaybe<Scalars['float8']['input']>;
  Businessincome?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Cashbank?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Creditor?: InputMaybe<Scalars['float8']['input']>;
  Debtor?: InputMaybe<Scalars['float8']['input']>;
  Depreciation?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['bigint']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fixedasset?: InputMaybe<Scalars['float8']['input']>;
  Foreignexchangeloss?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossprofit?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['bigint']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Interestexpense?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longtermliability?: InputMaybe<Scalars['float8']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Operatingexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentasset?: InputMaybe<Scalars['float8']['input']>;
  Othercurrentliability?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexpense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Otherliability?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Propertyincome?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Salarywage?: InputMaybe<Scalars['float8']['input']>;
  Sharehholderfund?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Stock?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totalasset?: InputMaybe<Scalars['float8']['input']>;
  Totalliability?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowablededuction?: InputMaybe<Scalars['float8']['input']>;
  Unutilisedallowance?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Yearallowance?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_stddev_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_stddev_pop_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Pop_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_stddev_samp_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Stddev_Samp_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_sum_fields';
  Accural?: Maybe<Scalars['float8']['output']>;
  Additionalallowance?: Maybe<Scalars['bigint']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['float8']['output']>;
  Allowanceutilised?: Maybe<Scalars['float8']['output']>;
  Businessincome?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Cashbank?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Creditor?: Maybe<Scalars['float8']['output']>;
  Debtor?: Maybe<Scalars['float8']['output']>;
  Depreciation?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['bigint']['output']>;
  Fixedasset?: Maybe<Scalars['float8']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossprofit?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['bigint']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Interestexpense?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longtermliability?: Maybe<Scalars['float8']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Operatingexpense?: Maybe<Scalars['float8']['output']>;
  Otherasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentasset?: Maybe<Scalars['float8']['output']>;
  Othercurrentliability?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexpense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Otherliability?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Propertyincome?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['bigint']['output']>;
  Salarywage?: Maybe<Scalars['float8']['output']>;
  Sharehholderfund?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Stock?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totalasset?: Maybe<Scalars['float8']['output']>;
  Totalliability?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowablededuction?: Maybe<Scalars['float8']['output']>;
  Unutilisedallowance?: Maybe<Scalars['float8']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
  Yearallowance?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Sum_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_var_pop_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Pop_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_var_samp_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Var_Samp_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_CorporateReturn_variance_fields';
  Accural?: Maybe<Scalars['Float']['output']>;
  Additionalallowance?: Maybe<Scalars['Float']['output']>;
  Allowancebroughtforward?: Maybe<Scalars['Float']['output']>;
  Allowanceutilised?: Maybe<Scalars['Float']['output']>;
  Businessincome?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Cashbank?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Creditor?: Maybe<Scalars['Float']['output']>;
  Debtor?: Maybe<Scalars['Float']['output']>;
  Depreciation?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Fixedasset?: Maybe<Scalars['Float']['output']>;
  Foreignexchangeloss?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossprofit?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Interestexpense?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longtermliability?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Operatingexpense?: Maybe<Scalars['Float']['output']>;
  Otherasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentasset?: Maybe<Scalars['Float']['output']>;
  Othercurrentliability?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexpense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Otherliability?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Propertyincome?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Salarywage?: Maybe<Scalars['Float']['output']>;
  Sharehholderfund?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Stock?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totalasset?: Maybe<Scalars['Float']['output']>;
  Totalliability?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowablededuction?: Maybe<Scalars['Float']['output']>;
  Unutilisedallowance?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
  Yearallowance?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_CorporateReturn" */
export type Rtcs_Gm_Dev_Gra_CorporateReturn_Variance_Order_By = {
  Accural?: InputMaybe<Order_By>;
  Additionalallowance?: InputMaybe<Order_By>;
  Allowancebroughtforward?: InputMaybe<Order_By>;
  Allowanceutilised?: InputMaybe<Order_By>;
  Businessincome?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Cashbank?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Creditor?: InputMaybe<Order_By>;
  Debtor?: InputMaybe<Order_By>;
  Depreciation?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Fixedasset?: InputMaybe<Order_By>;
  Foreignexchangeloss?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossprofit?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Interestexpense?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longtermliability?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Operatingexpense?: InputMaybe<Order_By>;
  Otherasset?: InputMaybe<Order_By>;
  Othercurrentasset?: InputMaybe<Order_By>;
  Othercurrentliability?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexpense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Otherliability?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Propertyincome?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Salarywage?: InputMaybe<Order_By>;
  Sharehholderfund?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Stock?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totalasset?: InputMaybe<Order_By>;
  Totalliability?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowablededuction?: InputMaybe<Order_By>;
  Unutilisedallowance?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
  Yearallowance?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn';
  Accountingmethod?: Maybe<Scalars['String']['output']>;
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Businessactivity?: Maybe<Scalars['String']['output']>;
  Businessaddress?: Maybe<Scalars['String']['output']>;
  Businessnature?: Maybe<Scalars['String']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employeraddress?: Maybe<Scalars['String']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Employertin1?: Maybe<Scalars['String']['output']>;
  Employertin2?: Maybe<Scalars['String']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Positionheld?: Maybe<Scalars['String']['output']>;
  Principalbusiness?: Maybe<Scalars['String']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['String']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption>;
};

/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_avg_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Avg_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_IndividualReturnExemption". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp = {
  Amount?: InputMaybe<Float8_Comparison_Exp>;
  Basis?: InputMaybe<String_Comparison_Exp>;
  Exemptionid?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Incometype?: InputMaybe<Bigint_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxliability?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Taxrate?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Inc_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Taxrate?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Insert_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['String']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Taxrate?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_max_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['String']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Max_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_min_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Basis?: Maybe<Scalars['String']['output']>;
  Exemptionid?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Min_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Basis?: InputMaybe<Order_By>;
  Exemptionid?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export enum Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Select_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  Basis = 'Basis',
  /** column name */
  Exemptionid = 'Exemptionid',
  /** column name */
  File = 'File',
  /** column name */
  Incometype = 'Incometype',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxliability = 'Taxliability',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Taxrate = 'Taxrate',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Set_Input = {
  Amount?: InputMaybe<Scalars['float8']['input']>;
  Basis?: InputMaybe<Scalars['String']['input']>;
  Exemptionid?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Incometype?: InputMaybe<Scalars['bigint']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxliability?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Taxrate?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_stddev_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_stddev_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_stddev_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Stddev_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_sum_fields';
  Amount?: Maybe<Scalars['float8']['output']>;
  Incometype?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxliability?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Taxrate?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Sum_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_var_pop_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_var_samp_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Var_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnExemption_variance_fields';
  Amount?: Maybe<Scalars['Float']['output']>;
  Incometype?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxliability?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Taxrate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_IndividualReturnExemption" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Variance_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Incometype?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Taxrate?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lendernameaddress?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Loanid?: Maybe<Scalars['String']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanused?: Maybe<Scalars['String']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan>;
};

/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_IndividualReturnLoan". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lendernameaddress?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Loanid?: InputMaybe<String_Comparison_Exp>;
  Loaninterest?: InputMaybe<Float8_Comparison_Exp>;
  Loanused?: InputMaybe<String_Comparison_Exp>;
  Loanvalue?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Loaninterest?: InputMaybe<Scalars['float8']['input']>;
  Loanvalue?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lendernameaddress?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Loanid?: InputMaybe<Scalars['String']['input']>;
  Loaninterest?: InputMaybe<Scalars['float8']['input']>;
  Loanused?: InputMaybe<Scalars['String']['input']>;
  Loanvalue?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lendernameaddress?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Loanid?: Maybe<Scalars['String']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanused?: Maybe<Scalars['String']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lendernameaddress?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Loanid?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanused?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lendernameaddress?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Loanid?: Maybe<Scalars['String']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanused?: Maybe<Scalars['String']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lendernameaddress?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Loanid?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanused?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lendernameaddress?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Loanid?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanused?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export enum Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lendernameaddress = 'Lendernameaddress',
  /** column name */
  Line = 'Line',
  /** column name */
  Loanid = 'Loanid',
  /** column name */
  Loaninterest = 'Loaninterest',
  /** column name */
  Loanused = 'Loanused',
  /** column name */
  Loanvalue = 'Loanvalue',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lendernameaddress?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Loanid?: InputMaybe<Scalars['String']['input']>;
  Loaninterest?: InputMaybe<Scalars['float8']['input']>;
  Loanused?: InputMaybe<Scalars['String']['input']>;
  Loanvalue?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Loaninterest?: Maybe<Scalars['float8']['output']>;
  Loanvalue?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoan_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Loaninterest?: Maybe<Scalars['Float']['output']>;
  Loanvalue?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoan" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Loaninterest?: InputMaybe<Order_By>;
  Loanvalue?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss>;
};

/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_IndividualReturnLoss". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp = {
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lossid?: InputMaybe<String_Comparison_Exp>;
  Lossmade?: InputMaybe<Float8_Comparison_Exp>;
  Lossoutstanding?: InputMaybe<Float8_Comparison_Exp>;
  Losssetoff?: InputMaybe<Bigint_Comparison_Exp>;
  Lossyear?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Yearleft?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['bigint']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Insert_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['bigint']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_max_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Max_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_min_fields';
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossid?: Maybe<Scalars['String']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Min_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Order_By = {
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossid?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export enum Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Select_Column {
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lossid = 'Lossid',
  /** column name */
  Lossmade = 'Lossmade',
  /** column name */
  Lossoutstanding = 'Lossoutstanding',
  /** column name */
  Losssetoff = 'Losssetoff',
  /** column name */
  Lossyear = 'Lossyear',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Yearleft = 'Yearleft'
}

/** input type for updating data in table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Set_Input = {
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossid?: InputMaybe<Scalars['String']['input']>;
  Lossmade?: InputMaybe<Scalars['float8']['input']>;
  Lossoutstanding?: InputMaybe<Scalars['float8']['input']>;
  Losssetoff?: InputMaybe<Scalars['bigint']['input']>;
  Lossyear?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Yearleft?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossmade?: Maybe<Scalars['float8']['output']>;
  Lossoutstanding?: Maybe<Scalars['float8']['output']>;
  Losssetoff?: Maybe<Scalars['bigint']['output']>;
  Lossyear?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Yearleft?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnLoss_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Lossmade?: Maybe<Scalars['Float']['output']>;
  Lossoutstanding?: Maybe<Scalars['Float']['output']>;
  Losssetoff?: Maybe<Scalars['Float']['output']>;
  Lossyear?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Yearleft?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_IndividualReturnLoss" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Lossmade?: InputMaybe<Order_By>;
  Lossoutstanding?: InputMaybe<Order_By>;
  Losssetoff?: InputMaybe<Order_By>;
  Lossyear?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Yearleft?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Propertyused?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid>;
};

/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_avg_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Avg_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_IndividualReturnRentPaid". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp = {
  Annualrent?: InputMaybe<Float8_Comparison_Exp>;
  Annualrentpaid?: InputMaybe<Float8_Comparison_Exp>;
  Commercialused?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<String_Comparison_Exp>;
  Tenancystartdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Inc_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Insert_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_max_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Max_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_min_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['String']['output']>;
  Tenancystartdate?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Min_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  Tenancystartdate?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export enum Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Select_Column {
  /** column name */
  Annualrent = 'Annualrent',
  /** column name */
  Annualrentpaid = 'Annualrentpaid',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  Tenancystartdate = 'Tenancystartdate',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Set_Input = {
  Annualrent?: InputMaybe<Scalars['float8']['input']>;
  Annualrentpaid?: InputMaybe<Scalars['float8']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['String']['input']>;
  Tenancystartdate?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_stddev_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_stddev_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_stddev_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Stddev_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_sum_fields';
  Annualrent?: Maybe<Scalars['float8']['output']>;
  Annualrentpaid?: Maybe<Scalars['float8']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Sum_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_var_pop_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Pop_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_var_samp_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Var_Samp_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRentPaid_variance_fields';
  Annualrent?: Maybe<Scalars['Float']['output']>;
  Annualrentpaid?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Variance_Order_By = {
  Annualrent?: InputMaybe<Order_By>;
  Annualrentpaid?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent>;
};

/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_avg_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Avg_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_IndividualReturnRent". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp = {
  Alloweddeduction?: InputMaybe<Bigint_Comparison_Exp>;
  Commercialused?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Grossrent?: InputMaybe<Bigint_Comparison_Exp>;
  Landlordname?: InputMaybe<String_Comparison_Exp>;
  Landlordtin?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Netincome?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyaddress?: InputMaybe<String_Comparison_Exp>;
  Propertycost?: InputMaybe<Bigint_Comparison_Exp>;
  Propertyused?: InputMaybe<String_Comparison_Exp>;
  Rentid?: InputMaybe<String_Comparison_Exp>;
  Residentialused?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Telephone?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Inc_Input = {
  Alloweddeduction?: InputMaybe<Scalars['bigint']['input']>;
  Grossrent?: InputMaybe<Scalars['bigint']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['bigint']['input']>;
  Propertycost?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Insert_Input = {
  Alloweddeduction?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grossrent?: InputMaybe<Scalars['bigint']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Propertycost?: InputMaybe<Scalars['bigint']['input']>;
  Propertyused?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_max_fields';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Propertyused?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Max_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Propertyused?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_min_fields';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Commercialused?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordname?: Maybe<Scalars['String']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertyaddress?: Maybe<Scalars['String']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Propertyused?: Maybe<Scalars['String']['output']>;
  Rentid?: Maybe<Scalars['String']['output']>;
  Residentialused?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Min_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Propertyused?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_IndividualReturnRent_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Commercialused?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordname?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertyaddress?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Propertyused?: InputMaybe<Order_By>;
  Rentid?: InputMaybe<Order_By>;
  Residentialused?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export enum Rtcs_Gm_Dev_Gra_IndividualReturnRent_Select_Column {
  /** column name */
  Alloweddeduction = 'Alloweddeduction',
  /** column name */
  Commercialused = 'Commercialused',
  /** column name */
  File = 'File',
  /** column name */
  Grossrent = 'Grossrent',
  /** column name */
  Landlordname = 'Landlordname',
  /** column name */
  Landlordtin = 'Landlordtin',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Netincome = 'Netincome',
  /** column name */
  Propertyaddress = 'Propertyaddress',
  /** column name */
  Propertycost = 'Propertycost',
  /** column name */
  Propertyused = 'Propertyused',
  /** column name */
  Rentid = 'Rentid',
  /** column name */
  Residentialused = 'Residentialused',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Telephone = 'Telephone',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Set_Input = {
  Alloweddeduction?: InputMaybe<Scalars['bigint']['input']>;
  Commercialused?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grossrent?: InputMaybe<Scalars['bigint']['input']>;
  Landlordname?: InputMaybe<Scalars['String']['input']>;
  Landlordtin?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['bigint']['input']>;
  Propertyaddress?: InputMaybe<Scalars['String']['input']>;
  Propertycost?: InputMaybe<Scalars['bigint']['input']>;
  Propertyused?: InputMaybe<Scalars['String']['input']>;
  Rentid?: InputMaybe<Scalars['String']['input']>;
  Residentialused?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Telephone?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_stddev_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_stddev_pop_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Pop_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_stddev_samp_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Stddev_Samp_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_sum_fields';
  Alloweddeduction?: Maybe<Scalars['bigint']['output']>;
  Grossrent?: Maybe<Scalars['bigint']['output']>;
  Landlordtin?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['bigint']['output']>;
  Propertycost?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Telephone?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Sum_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_var_pop_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Pop_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_var_samp_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Var_Samp_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturnRent_variance_fields';
  Alloweddeduction?: Maybe<Scalars['Float']['output']>;
  Grossrent?: Maybe<Scalars['Float']['output']>;
  Landlordtin?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Netincome?: Maybe<Scalars['Float']['output']>;
  Propertycost?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Telephone?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_IndividualReturnRent" */
export type Rtcs_Gm_Dev_Gra_IndividualReturnRent_Variance_Order_By = {
  Alloweddeduction?: InputMaybe<Order_By>;
  Grossrent?: InputMaybe<Order_By>;
  Landlordtin?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Propertycost?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Telephone?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_IndividualReturn>;
};

/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_avg_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Avg_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_IndividualReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp = {
  Accountingmethod?: InputMaybe<String_Comparison_Exp>;
  Allowance?: InputMaybe<Float8_Comparison_Exp>;
  Bonus?: InputMaybe<Float8_Comparison_Exp>;
  Businessactivity?: InputMaybe<String_Comparison_Exp>;
  Businessaddress?: InputMaybe<String_Comparison_Exp>;
  Businessnature?: InputMaybe<String_Comparison_Exp>;
  Capitalallowance?: InputMaybe<Float8_Comparison_Exp>;
  Closingstock?: InputMaybe<Float8_Comparison_Exp>;
  Commercialrent?: InputMaybe<Float8_Comparison_Exp>;
  Commission?: InputMaybe<Float8_Comparison_Exp>;
  Costofsale?: InputMaybe<Float8_Comparison_Exp>;
  Directtaxpaid?: InputMaybe<Float8_Comparison_Exp>;
  Employeraddress?: InputMaybe<String_Comparison_Exp>;
  Employertin?: InputMaybe<Float8_Comparison_Exp>;
  Employertin1?: InputMaybe<String_Comparison_Exp>;
  Employertin2?: InputMaybe<String_Comparison_Exp>;
  Exemptincome?: InputMaybe<Float8_Comparison_Exp>;
  Exporter?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Foreigntaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  Fringebenefit?: InputMaybe<Float8_Comparison_Exp>;
  Fringebenefittax?: InputMaybe<Float8_Comparison_Exp>;
  Grossemolument?: InputMaybe<Float8_Comparison_Exp>;
  Importer?: InputMaybe<Float8_Comparison_Exp>;
  Instalmentpayment?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lossbroughtforward?: InputMaybe<Float8_Comparison_Exp>;
  Netbusinessincome?: InputMaybe<Float8_Comparison_Exp>;
  Openingstock?: InputMaybe<Float8_Comparison_Exp>;
  Other?: InputMaybe<Float8_Comparison_Exp>;
  Otherdeduction?: InputMaybe<Float8_Comparison_Exp>;
  Otherexepense?: InputMaybe<Float8_Comparison_Exp>;
  Otherincome?: InputMaybe<Float8_Comparison_Exp>;
  Overpayment?: InputMaybe<Float8_Comparison_Exp>;
  Overtime?: InputMaybe<Float8_Comparison_Exp>;
  Pension?: InputMaybe<Float8_Comparison_Exp>;
  Positionheld?: InputMaybe<String_Comparison_Exp>;
  Principalbusiness?: InputMaybe<String_Comparison_Exp>;
  Purchase?: InputMaybe<Float8_Comparison_Exp>;
  Returngroup?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Salarywages?: InputMaybe<Float8_Comparison_Exp>;
  Sicexemption?: InputMaybe<Float8_Comparison_Exp>;
  Subtotal?: InputMaybe<Float8_Comparison_Exp>;
  Subtotal1?: InputMaybe<Float8_Comparison_Exp>;
  Tax1stschedule?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Totalnetincome?: InputMaybe<Float8_Comparison_Exp>;
  Turnover?: InputMaybe<Float8_Comparison_Exp>;
  Turnovertax?: InputMaybe<Float8_Comparison_Exp>;
  Unallowwablededuction?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Withholdingtaxcredit?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Inc_Input = {
  Allowance?: InputMaybe<Scalars['float8']['input']>;
  Bonus?: InputMaybe<Scalars['float8']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Commercialrent?: InputMaybe<Scalars['float8']['input']>;
  Commission?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Directtaxpaid?: InputMaybe<Scalars['float8']['input']>;
  Employertin?: InputMaybe<Scalars['float8']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['float8']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossemolument?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['float8']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Netbusinessincome?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Other?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexepense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Overtime?: InputMaybe<Scalars['float8']['input']>;
  Pension?: InputMaybe<Scalars['float8']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['float8']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Salarywages?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Subtotal?: InputMaybe<Scalars['float8']['input']>;
  Subtotal1?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalnetincome?: InputMaybe<Scalars['float8']['input']>;
  Turnover?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowwablededuction?: InputMaybe<Scalars['float8']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Insert_Input = {
  Accountingmethod?: InputMaybe<Scalars['String']['input']>;
  Allowance?: InputMaybe<Scalars['float8']['input']>;
  Bonus?: InputMaybe<Scalars['float8']['input']>;
  Businessactivity?: InputMaybe<Scalars['String']['input']>;
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  Businessnature?: InputMaybe<Scalars['String']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Commercialrent?: InputMaybe<Scalars['float8']['input']>;
  Commission?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Directtaxpaid?: InputMaybe<Scalars['float8']['input']>;
  Employeraddress?: InputMaybe<Scalars['String']['input']>;
  Employertin?: InputMaybe<Scalars['float8']['input']>;
  Employertin1?: InputMaybe<Scalars['String']['input']>;
  Employertin2?: InputMaybe<Scalars['String']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossemolument?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['float8']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Netbusinessincome?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Other?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexepense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Overtime?: InputMaybe<Scalars['float8']['input']>;
  Pension?: InputMaybe<Scalars['float8']['input']>;
  Positionheld?: InputMaybe<Scalars['String']['input']>;
  Principalbusiness?: InputMaybe<Scalars['String']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Salarywages?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Subtotal?: InputMaybe<Scalars['float8']['input']>;
  Subtotal1?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalnetincome?: InputMaybe<Scalars['float8']['input']>;
  Turnover?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowwablededuction?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_max_fields';
  Accountingmethod?: Maybe<Scalars['String']['output']>;
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Businessactivity?: Maybe<Scalars['String']['output']>;
  Businessaddress?: Maybe<Scalars['String']['output']>;
  Businessnature?: Maybe<Scalars['String']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employeraddress?: Maybe<Scalars['String']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Employertin1?: Maybe<Scalars['String']['output']>;
  Employertin2?: Maybe<Scalars['String']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Positionheld?: Maybe<Scalars['String']['output']>;
  Principalbusiness?: Maybe<Scalars['String']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Max_Order_By = {
  Accountingmethod?: InputMaybe<Order_By>;
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Businessactivity?: InputMaybe<Order_By>;
  Businessaddress?: InputMaybe<Order_By>;
  Businessnature?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employeraddress?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Employertin1?: InputMaybe<Order_By>;
  Employertin2?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Positionheld?: InputMaybe<Order_By>;
  Principalbusiness?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_min_fields';
  Accountingmethod?: Maybe<Scalars['String']['output']>;
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Businessactivity?: Maybe<Scalars['String']['output']>;
  Businessaddress?: Maybe<Scalars['String']['output']>;
  Businessnature?: Maybe<Scalars['String']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employeraddress?: Maybe<Scalars['String']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Employertin1?: Maybe<Scalars['String']['output']>;
  Employertin2?: Maybe<Scalars['String']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Positionheld?: Maybe<Scalars['String']['output']>;
  Principalbusiness?: Maybe<Scalars['String']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Min_Order_By = {
  Accountingmethod?: InputMaybe<Order_By>;
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Businessactivity?: InputMaybe<Order_By>;
  Businessaddress?: InputMaybe<Order_By>;
  Businessnature?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employeraddress?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Employertin1?: InputMaybe<Order_By>;
  Employertin2?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Positionheld?: InputMaybe<Order_By>;
  Principalbusiness?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_IndividualReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_IndividualReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Order_By = {
  Accountingmethod?: InputMaybe<Order_By>;
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Businessactivity?: InputMaybe<Order_By>;
  Businessaddress?: InputMaybe<Order_By>;
  Businessnature?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employeraddress?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Employertin1?: InputMaybe<Order_By>;
  Employertin2?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Positionheld?: InputMaybe<Order_By>;
  Principalbusiness?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export enum Rtcs_Gm_Dev_Gra_IndividualReturn_Select_Column {
  /** column name */
  Accountingmethod = 'Accountingmethod',
  /** column name */
  Allowance = 'Allowance',
  /** column name */
  Bonus = 'Bonus',
  /** column name */
  Businessactivity = 'Businessactivity',
  /** column name */
  Businessaddress = 'Businessaddress',
  /** column name */
  Businessnature = 'Businessnature',
  /** column name */
  Capitalallowance = 'Capitalallowance',
  /** column name */
  Closingstock = 'Closingstock',
  /** column name */
  Commercialrent = 'Commercialrent',
  /** column name */
  Commission = 'Commission',
  /** column name */
  Costofsale = 'Costofsale',
  /** column name */
  Directtaxpaid = 'Directtaxpaid',
  /** column name */
  Employeraddress = 'Employeraddress',
  /** column name */
  Employertin = 'Employertin',
  /** column name */
  Employertin1 = 'Employertin1',
  /** column name */
  Employertin2 = 'Employertin2',
  /** column name */
  Exemptincome = 'Exemptincome',
  /** column name */
  Exporter = 'Exporter',
  /** column name */
  File = 'File',
  /** column name */
  Foreigntaxcredit = 'Foreigntaxcredit',
  /** column name */
  Fringebenefit = 'Fringebenefit',
  /** column name */
  Fringebenefittax = 'Fringebenefittax',
  /** column name */
  Grossemolument = 'Grossemolument',
  /** column name */
  Importer = 'Importer',
  /** column name */
  Instalmentpayment = 'Instalmentpayment',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lossbroughtforward = 'Lossbroughtforward',
  /** column name */
  Netbusinessincome = 'Netbusinessincome',
  /** column name */
  Openingstock = 'Openingstock',
  /** column name */
  Other = 'Other',
  /** column name */
  Otherdeduction = 'Otherdeduction',
  /** column name */
  Otherexepense = 'Otherexepense',
  /** column name */
  Otherincome = 'Otherincome',
  /** column name */
  Overpayment = 'Overpayment',
  /** column name */
  Overtime = 'Overtime',
  /** column name */
  Pension = 'Pension',
  /** column name */
  Positionheld = 'Positionheld',
  /** column name */
  Principalbusiness = 'Principalbusiness',
  /** column name */
  Purchase = 'Purchase',
  /** column name */
  Returngroup = 'Returngroup',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Salarywages = 'Salarywages',
  /** column name */
  Sicexemption = 'Sicexemption',
  /** column name */
  Subtotal = 'Subtotal',
  /** column name */
  Subtotal1 = 'Subtotal1',
  /** column name */
  Tax1stschedule = 'Tax1stschedule',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Totalnetincome = 'Totalnetincome',
  /** column name */
  Turnover = 'Turnover',
  /** column name */
  Turnovertax = 'Turnovertax',
  /** column name */
  Unallowwablededuction = 'Unallowwablededuction',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Withholdingtaxcredit = 'Withholdingtaxcredit'
}

/** input type for updating data in table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Set_Input = {
  Accountingmethod?: InputMaybe<Scalars['String']['input']>;
  Allowance?: InputMaybe<Scalars['float8']['input']>;
  Bonus?: InputMaybe<Scalars['float8']['input']>;
  Businessactivity?: InputMaybe<Scalars['String']['input']>;
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  Businessnature?: InputMaybe<Scalars['String']['input']>;
  Capitalallowance?: InputMaybe<Scalars['float8']['input']>;
  Closingstock?: InputMaybe<Scalars['float8']['input']>;
  Commercialrent?: InputMaybe<Scalars['float8']['input']>;
  Commission?: InputMaybe<Scalars['float8']['input']>;
  Costofsale?: InputMaybe<Scalars['float8']['input']>;
  Directtaxpaid?: InputMaybe<Scalars['float8']['input']>;
  Employeraddress?: InputMaybe<Scalars['String']['input']>;
  Employertin?: InputMaybe<Scalars['float8']['input']>;
  Employertin1?: InputMaybe<Scalars['String']['input']>;
  Employertin2?: InputMaybe<Scalars['String']['input']>;
  Exemptincome?: InputMaybe<Scalars['float8']['input']>;
  Exporter?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Foreigntaxcredit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefit?: InputMaybe<Scalars['float8']['input']>;
  Fringebenefittax?: InputMaybe<Scalars['float8']['input']>;
  Grossemolument?: InputMaybe<Scalars['float8']['input']>;
  Importer?: InputMaybe<Scalars['float8']['input']>;
  Instalmentpayment?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lossbroughtforward?: InputMaybe<Scalars['float8']['input']>;
  Netbusinessincome?: InputMaybe<Scalars['float8']['input']>;
  Openingstock?: InputMaybe<Scalars['float8']['input']>;
  Other?: InputMaybe<Scalars['float8']['input']>;
  Otherdeduction?: InputMaybe<Scalars['float8']['input']>;
  Otherexepense?: InputMaybe<Scalars['float8']['input']>;
  Otherincome?: InputMaybe<Scalars['float8']['input']>;
  Overpayment?: InputMaybe<Scalars['float8']['input']>;
  Overtime?: InputMaybe<Scalars['float8']['input']>;
  Pension?: InputMaybe<Scalars['float8']['input']>;
  Positionheld?: InputMaybe<Scalars['String']['input']>;
  Principalbusiness?: InputMaybe<Scalars['String']['input']>;
  Purchase?: InputMaybe<Scalars['float8']['input']>;
  Returngroup?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Salarywages?: InputMaybe<Scalars['float8']['input']>;
  Sicexemption?: InputMaybe<Scalars['float8']['input']>;
  Subtotal?: InputMaybe<Scalars['float8']['input']>;
  Subtotal1?: InputMaybe<Scalars['float8']['input']>;
  Tax1stschedule?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalnetincome?: InputMaybe<Scalars['float8']['input']>;
  Turnover?: InputMaybe<Scalars['float8']['input']>;
  Turnovertax?: InputMaybe<Scalars['float8']['input']>;
  Unallowwablededuction?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withholdingtaxcredit?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_stddev_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_stddev_pop_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Pop_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_stddev_samp_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Stddev_Samp_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_sum_fields';
  Allowance?: Maybe<Scalars['float8']['output']>;
  Bonus?: Maybe<Scalars['float8']['output']>;
  Capitalallowance?: Maybe<Scalars['float8']['output']>;
  Closingstock?: Maybe<Scalars['float8']['output']>;
  Commercialrent?: Maybe<Scalars['float8']['output']>;
  Commission?: Maybe<Scalars['float8']['output']>;
  Costofsale?: Maybe<Scalars['float8']['output']>;
  Directtaxpaid?: Maybe<Scalars['float8']['output']>;
  Employertin?: Maybe<Scalars['float8']['output']>;
  Exemptincome?: Maybe<Scalars['float8']['output']>;
  Exporter?: Maybe<Scalars['float8']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['float8']['output']>;
  Fringebenefit?: Maybe<Scalars['float8']['output']>;
  Fringebenefittax?: Maybe<Scalars['float8']['output']>;
  Grossemolument?: Maybe<Scalars['float8']['output']>;
  Importer?: Maybe<Scalars['float8']['output']>;
  Instalmentpayment?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lossbroughtforward?: Maybe<Scalars['float8']['output']>;
  Netbusinessincome?: Maybe<Scalars['float8']['output']>;
  Openingstock?: Maybe<Scalars['float8']['output']>;
  Other?: Maybe<Scalars['float8']['output']>;
  Otherdeduction?: Maybe<Scalars['float8']['output']>;
  Otherexepense?: Maybe<Scalars['float8']['output']>;
  Otherincome?: Maybe<Scalars['float8']['output']>;
  Overpayment?: Maybe<Scalars['float8']['output']>;
  Overtime?: Maybe<Scalars['float8']['output']>;
  Pension?: Maybe<Scalars['float8']['output']>;
  Purchase?: Maybe<Scalars['float8']['output']>;
  Returngroup?: Maybe<Scalars['float8']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Salarywages?: Maybe<Scalars['float8']['output']>;
  Sicexemption?: Maybe<Scalars['float8']['output']>;
  Subtotal?: Maybe<Scalars['float8']['output']>;
  Subtotal1?: Maybe<Scalars['float8']['output']>;
  Tax1stschedule?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalnetincome?: Maybe<Scalars['float8']['output']>;
  Turnover?: Maybe<Scalars['float8']['output']>;
  Turnovertax?: Maybe<Scalars['float8']['output']>;
  Unallowwablededuction?: Maybe<Scalars['float8']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Sum_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_var_pop_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Pop_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_var_samp_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Var_Samp_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_IndividualReturn_variance_fields';
  Allowance?: Maybe<Scalars['Float']['output']>;
  Bonus?: Maybe<Scalars['Float']['output']>;
  Capitalallowance?: Maybe<Scalars['Float']['output']>;
  Closingstock?: Maybe<Scalars['Float']['output']>;
  Commercialrent?: Maybe<Scalars['Float']['output']>;
  Commission?: Maybe<Scalars['Float']['output']>;
  Costofsale?: Maybe<Scalars['Float']['output']>;
  Directtaxpaid?: Maybe<Scalars['Float']['output']>;
  Employertin?: Maybe<Scalars['Float']['output']>;
  Exemptincome?: Maybe<Scalars['Float']['output']>;
  Exporter?: Maybe<Scalars['Float']['output']>;
  Foreigntaxcredit?: Maybe<Scalars['Float']['output']>;
  Fringebenefit?: Maybe<Scalars['Float']['output']>;
  Fringebenefittax?: Maybe<Scalars['Float']['output']>;
  Grossemolument?: Maybe<Scalars['Float']['output']>;
  Importer?: Maybe<Scalars['Float']['output']>;
  Instalmentpayment?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Lossbroughtforward?: Maybe<Scalars['Float']['output']>;
  Netbusinessincome?: Maybe<Scalars['Float']['output']>;
  Openingstock?: Maybe<Scalars['Float']['output']>;
  Other?: Maybe<Scalars['Float']['output']>;
  Otherdeduction?: Maybe<Scalars['Float']['output']>;
  Otherexepense?: Maybe<Scalars['Float']['output']>;
  Otherincome?: Maybe<Scalars['Float']['output']>;
  Overpayment?: Maybe<Scalars['Float']['output']>;
  Overtime?: Maybe<Scalars['Float']['output']>;
  Pension?: Maybe<Scalars['Float']['output']>;
  Purchase?: Maybe<Scalars['Float']['output']>;
  Returngroup?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Salarywages?: Maybe<Scalars['Float']['output']>;
  Sicexemption?: Maybe<Scalars['Float']['output']>;
  Subtotal?: Maybe<Scalars['Float']['output']>;
  Subtotal1?: Maybe<Scalars['Float']['output']>;
  Tax1stschedule?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalnetincome?: Maybe<Scalars['Float']['output']>;
  Turnover?: Maybe<Scalars['Float']['output']>;
  Turnovertax?: Maybe<Scalars['Float']['output']>;
  Unallowwablededuction?: Maybe<Scalars['Float']['output']>;
  Withholdingtaxcredit?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_IndividualReturn" */
export type Rtcs_Gm_Dev_Gra_IndividualReturn_Variance_Order_By = {
  Allowance?: InputMaybe<Order_By>;
  Bonus?: InputMaybe<Order_By>;
  Capitalallowance?: InputMaybe<Order_By>;
  Closingstock?: InputMaybe<Order_By>;
  Commercialrent?: InputMaybe<Order_By>;
  Commission?: InputMaybe<Order_By>;
  Costofsale?: InputMaybe<Order_By>;
  Directtaxpaid?: InputMaybe<Order_By>;
  Employertin?: InputMaybe<Order_By>;
  Exemptincome?: InputMaybe<Order_By>;
  Exporter?: InputMaybe<Order_By>;
  Foreigntaxcredit?: InputMaybe<Order_By>;
  Fringebenefit?: InputMaybe<Order_By>;
  Fringebenefittax?: InputMaybe<Order_By>;
  Grossemolument?: InputMaybe<Order_By>;
  Importer?: InputMaybe<Order_By>;
  Instalmentpayment?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lossbroughtforward?: InputMaybe<Order_By>;
  Netbusinessincome?: InputMaybe<Order_By>;
  Openingstock?: InputMaybe<Order_By>;
  Other?: InputMaybe<Order_By>;
  Otherdeduction?: InputMaybe<Order_By>;
  Otherexepense?: InputMaybe<Order_By>;
  Otherincome?: InputMaybe<Order_By>;
  Overpayment?: InputMaybe<Order_By>;
  Overtime?: InputMaybe<Order_By>;
  Pension?: InputMaybe<Order_By>;
  Purchase?: InputMaybe<Order_By>;
  Returngroup?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Salarywages?: InputMaybe<Order_By>;
  Sicexemption?: InputMaybe<Order_By>;
  Subtotal?: InputMaybe<Order_By>;
  Subtotal1?: InputMaybe<Order_By>;
  Tax1stschedule?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalnetincome?: InputMaybe<Order_By>;
  Turnover?: InputMaybe<Order_By>;
  Turnovertax?: InputMaybe<Order_By>;
  Unallowwablededuction?: InputMaybe<Order_By>;
  Withholdingtaxcredit?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental';
  Birthdate?: Maybe<Scalars['timestamptz']['output']>;
  Centername?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Empemail?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Paymentdate?: Maybe<Scalars['timestamptz']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Phoneno2?: Maybe<Scalars['String']['output']>;
  Physarea?: Maybe<Scalars['String']['output']>;
  Regtypecode?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_PaymentRental>;
};

/** aggregate fields of "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_PaymentRental_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_PaymentRental_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_PaymentRental". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp = {
  Birthdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Centername?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  Empemail?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Fname?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Lname?: InputMaybe<String_Comparison_Exp>;
  Paidamount?: InputMaybe<Float8_Comparison_Exp>;
  Paymentdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Phoneno?: InputMaybe<String_Comparison_Exp>;
  Phoneno2?: InputMaybe<String_Comparison_Exp>;
  Physarea?: InputMaybe<String_Comparison_Exp>;
  Regtypecode?: InputMaybe<String_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxname?: InputMaybe<String_Comparison_Exp>;
  Taxoffice?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxoffice?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Insert_Input = {
  Birthdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Centername?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Empemail?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Paymentdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Phoneno2?: InputMaybe<Scalars['String']['input']>;
  Physarea?: InputMaybe<Scalars['String']['input']>;
  Regtypecode?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxoffice?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_max_fields';
  Birthdate?: Maybe<Scalars['timestamptz']['output']>;
  Centername?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Empemail?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Paymentdate?: Maybe<Scalars['timestamptz']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Phoneno2?: Maybe<Scalars['String']['output']>;
  Physarea?: Maybe<Scalars['String']['output']>;
  Regtypecode?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Max_Order_By = {
  Birthdate?: InputMaybe<Order_By>;
  Centername?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Empemail?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Paymentdate?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Phoneno2?: InputMaybe<Order_By>;
  Physarea?: InputMaybe<Order_By>;
  Regtypecode?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_min_fields';
  Birthdate?: Maybe<Scalars['timestamptz']['output']>;
  Centername?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Empemail?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Fname?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Lname?: Maybe<Scalars['String']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Paymentdate?: Maybe<Scalars['timestamptz']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Phoneno2?: Maybe<Scalars['String']['output']>;
  Physarea?: Maybe<Scalars['String']['output']>;
  Regtypecode?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxname?: Maybe<Scalars['String']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Min_Order_By = {
  Birthdate?: InputMaybe<Order_By>;
  Centername?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Empemail?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Paymentdate?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Phoneno2?: InputMaybe<Order_By>;
  Physarea?: InputMaybe<Order_By>;
  Regtypecode?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_PaymentRental>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_PaymentRental_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Order_By = {
  Birthdate?: InputMaybe<Order_By>;
  Centername?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Empemail?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Fname?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Lname?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Paymentdate?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Phoneno2?: InputMaybe<Order_By>;
  Physarea?: InputMaybe<Order_By>;
  Regtypecode?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxname?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_PaymentRental" */
export enum Rtcs_Gm_Dev_Gra_PaymentRental_Select_Column {
  /** column name */
  Birthdate = 'Birthdate',
  /** column name */
  Centername = 'Centername',
  /** column name */
  Email = 'Email',
  /** column name */
  Empemail = 'Empemail',
  /** column name */
  File = 'File',
  /** column name */
  Fname = 'Fname',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Lname = 'Lname',
  /** column name */
  Paidamount = 'Paidamount',
  /** column name */
  Paymentdate = 'Paymentdate',
  /** column name */
  Phoneno = 'Phoneno',
  /** column name */
  Phoneno2 = 'Phoneno2',
  /** column name */
  Physarea = 'Physarea',
  /** column name */
  Regtypecode = 'Regtypecode',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxname = 'Taxname',
  /** column name */
  Taxoffice = 'Taxoffice',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Set_Input = {
  Birthdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Centername?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Empemail?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Fname?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Lname?: InputMaybe<Scalars['String']['input']>;
  Paidamount?: InputMaybe<Scalars['float8']['input']>;
  Paymentdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Phoneno2?: InputMaybe<Scalars['String']['input']>;
  Physarea?: InputMaybe<Scalars['String']['input']>;
  Regtypecode?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxname?: InputMaybe<Scalars['String']['input']>;
  Taxoffice?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Paidamount?: Maybe<Scalars['float8']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxoffice?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_PaymentRental_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Paidamount?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxoffice?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_PaymentRental" */
export type Rtcs_Gm_Dev_Gra_PaymentRental_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Paidamount?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxoffice?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation';
  Associateaddress?: Maybe<Scalars['String']['output']>;
  Associateid?: Maybe<Scalars['String']['output']>;
  Associatename?: Maybe<Scalars['String']['output']>;
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Assoctin?: Maybe<Scalars['String']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_RegAssociation>;
};

/** aggregate fields of "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_RegAssociation_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_RegAssociation_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_avg_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Avg_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_RegAssociation". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp = {
  Associateaddress?: InputMaybe<String_Comparison_Exp>;
  Associateid?: InputMaybe<String_Comparison_Exp>;
  Associatename?: InputMaybe<String_Comparison_Exp>;
  Associaterelation?: InputMaybe<Bigint_Comparison_Exp>;
  Assoctin?: InputMaybe<String_Comparison_Exp>;
  Delflag?: InputMaybe<Bigint_Comparison_Exp>;
  Designation?: InputMaybe<String_Comparison_Exp>;
  Din?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Noshare?: InputMaybe<Float8_Comparison_Exp>;
  Profitshare?: InputMaybe<Bigint_Comparison_Exp>;
  Shareheld?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerid?: InputMaybe<Float8_Comparison_Exp>;
  Tin?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Inc_Input = {
  Associaterelation?: InputMaybe<Scalars['bigint']['input']>;
  Delflag?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Noshare?: InputMaybe<Scalars['float8']['input']>;
  Profitshare?: InputMaybe<Scalars['bigint']['input']>;
  Shareheld?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerid?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Insert_Input = {
  Associateaddress?: InputMaybe<Scalars['String']['input']>;
  Associateid?: InputMaybe<Scalars['String']['input']>;
  Associatename?: InputMaybe<Scalars['String']['input']>;
  Associaterelation?: InputMaybe<Scalars['bigint']['input']>;
  Assoctin?: InputMaybe<Scalars['String']['input']>;
  Delflag?: InputMaybe<Scalars['bigint']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Noshare?: InputMaybe<Scalars['float8']['input']>;
  Profitshare?: InputMaybe<Scalars['bigint']['input']>;
  Shareheld?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerid?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_max_fields';
  Associateaddress?: Maybe<Scalars['String']['output']>;
  Associateid?: Maybe<Scalars['String']['output']>;
  Associatename?: Maybe<Scalars['String']['output']>;
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Assoctin?: Maybe<Scalars['String']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Max_Order_By = {
  Associateaddress?: InputMaybe<Order_By>;
  Associateid?: InputMaybe<Order_By>;
  Associatename?: InputMaybe<Order_By>;
  Associaterelation?: InputMaybe<Order_By>;
  Assoctin?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_min_fields';
  Associateaddress?: Maybe<Scalars['String']['output']>;
  Associateid?: Maybe<Scalars['String']['output']>;
  Associatename?: Maybe<Scalars['String']['output']>;
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Assoctin?: Maybe<Scalars['String']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Min_Order_By = {
  Associateaddress?: InputMaybe<Order_By>;
  Associateid?: InputMaybe<Order_By>;
  Associatename?: InputMaybe<Order_By>;
  Associaterelation?: InputMaybe<Order_By>;
  Assoctin?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_RegAssociation>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_RegAssociation_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Order_By = {
  Associateaddress?: InputMaybe<Order_By>;
  Associateid?: InputMaybe<Order_By>;
  Associatename?: InputMaybe<Order_By>;
  Associaterelation?: InputMaybe<Order_By>;
  Assoctin?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_RegAssociation" */
export enum Rtcs_Gm_Dev_Gra_RegAssociation_Select_Column {
  /** column name */
  Associateaddress = 'Associateaddress',
  /** column name */
  Associateid = 'Associateid',
  /** column name */
  Associatename = 'Associatename',
  /** column name */
  Associaterelation = 'Associaterelation',
  /** column name */
  Assoctin = 'Assoctin',
  /** column name */
  Delflag = 'Delflag',
  /** column name */
  Designation = 'Designation',
  /** column name */
  Din = 'Din',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Noshare = 'Noshare',
  /** column name */
  Profitshare = 'Profitshare',
  /** column name */
  Shareheld = 'Shareheld',
  /** column name */
  Taxpayerid = 'Taxpayerid',
  /** column name */
  Tin = 'Tin',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Set_Input = {
  Associateaddress?: InputMaybe<Scalars['String']['input']>;
  Associateid?: InputMaybe<Scalars['String']['input']>;
  Associatename?: InputMaybe<Scalars['String']['input']>;
  Associaterelation?: InputMaybe<Scalars['bigint']['input']>;
  Assoctin?: InputMaybe<Scalars['String']['input']>;
  Delflag?: InputMaybe<Scalars['bigint']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Noshare?: InputMaybe<Scalars['float8']['input']>;
  Profitshare?: InputMaybe<Scalars['bigint']['input']>;
  Shareheld?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerid?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_stddev_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_stddev_pop_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Pop_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_stddev_samp_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Stddev_Samp_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_sum_fields';
  Associaterelation?: Maybe<Scalars['bigint']['output']>;
  Delflag?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Noshare?: Maybe<Scalars['float8']['output']>;
  Profitshare?: Maybe<Scalars['bigint']['output']>;
  Shareheld?: Maybe<Scalars['float8']['output']>;
  Taxpayerid?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Sum_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_var_pop_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Var_Pop_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_var_samp_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Var_Samp_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RegAssociation_variance_fields';
  Associaterelation?: Maybe<Scalars['Float']['output']>;
  Delflag?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Noshare?: Maybe<Scalars['Float']['output']>;
  Profitshare?: Maybe<Scalars['Float']['output']>;
  Shareheld?: Maybe<Scalars['Float']['output']>;
  Taxpayerid?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_RegAssociation" */
export type Rtcs_Gm_Dev_Gra_RegAssociation_Variance_Order_By = {
  Associaterelation?: InputMaybe<Order_By>;
  Delflag?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Noshare?: InputMaybe<Order_By>;
  Profitshare?: InputMaybe<Order_By>;
  Shareheld?: InputMaybe<Order_By>;
  Taxpayerid?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData';
  Address?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Property?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxtype?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_RentalOfficeData>;
};

/** aggregate fields of "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_RentalOfficeData". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Contact?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Location?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  No?: InputMaybe<Float8_Comparison_Exp>;
  Property?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Taxtype?: InputMaybe<String_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  No?: InputMaybe<Scalars['float8']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Contact?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  No?: InputMaybe<Scalars['float8']['input']>;
  Property?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxtype?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Property?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxtype?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Contact?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Property?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxtype?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Property?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxtype?: Maybe<Scalars['String']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Contact?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Property?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxtype?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_RentalOfficeData>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_RentalOfficeData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Order_By = {
  Address?: InputMaybe<Order_By>;
  Contact?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Property?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxtype?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export enum Rtcs_Gm_Dev_Gra_RentalOfficeData_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Contact = 'Contact',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Location = 'Location',
  /** column name */
  Name = 'Name',
  /** column name */
  No = 'No',
  /** column name */
  Property = 'Property',
  /** column name */
  Status = 'Status',
  /** column name */
  Taxtype = 'Taxtype',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Contact?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  No?: InputMaybe<Scalars['float8']['input']>;
  Property?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxtype?: InputMaybe<Scalars['String']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  No?: Maybe<Scalars['float8']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_RentalOfficeData_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  No?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_RentalOfficeData" */
export type Rtcs_Gm_Dev_Gra_RentalOfficeData_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  No?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request = {
  __typename?: 'rtcs_gm_dev_gra_Request';
  Appcapacity?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['bigint']['output']>;
  Approvedby?: Maybe<Scalars['String']['output']>;
  Approveddate?: Maybe<Scalars['timestamptz']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Datereceived?: Maybe<Scalars['timestamptz']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declarantdate?: Maybe<Scalars['timestamptz']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Endperiod?: Maybe<Scalars['timestamptz']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Financialapprovedby?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationduedate?: Maybe<Scalars['timestamptz']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Reason?: Maybe<Scalars['String']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requestclass?: Maybe<Scalars['String']['output']>;
  Requestid?: Maybe<Scalars['String']['output']>;
  Requestreason?: Maybe<Scalars['String']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Startperiod?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Supportingdocument?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_Request_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_Request_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_Request>;
};

/** aggregate fields of "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_Request_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_Request_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_Request_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_Request_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_Request_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_Request_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_Request_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_Request_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_avg_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Avg_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_Request". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_Request_Bool_Exp = {
  Appcapacity?: InputMaybe<String_Comparison_Exp>;
  Approved?: InputMaybe<Bigint_Comparison_Exp>;
  Approvedby?: InputMaybe<String_Comparison_Exp>;
  Approveddate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Automaticapproval?: InputMaybe<Bigint_Comparison_Exp>;
  Datereceived?: InputMaybe<Timestamptz_Comparison_Exp>;
  Declarant?: InputMaybe<String_Comparison_Exp>;
  Declarantdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Designation?: InputMaybe<String_Comparison_Exp>;
  Din?: InputMaybe<String_Comparison_Exp>;
  Endperiod?: InputMaybe<Timestamptz_Comparison_Exp>;
  Entrydate?: InputMaybe<Timestamptz_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Financialapprovedby?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Notapproved?: InputMaybe<Bigint_Comparison_Exp>;
  Obligationduedate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Obligationid?: InputMaybe<Bigint_Comparison_Exp>;
  Officer?: InputMaybe<String_Comparison_Exp>;
  Reason?: InputMaybe<String_Comparison_Exp>;
  Refunableamount?: InputMaybe<Bigint_Comparison_Exp>;
  Requestamount?: InputMaybe<Float8_Comparison_Exp>;
  Requestclass?: InputMaybe<String_Comparison_Exp>;
  Requestid?: InputMaybe<String_Comparison_Exp>;
  Requestreason?: InputMaybe<String_Comparison_Exp>;
  Requesttype?: InputMaybe<Bigint_Comparison_Exp>;
  Startperiod?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Supportingdocument?: InputMaybe<String_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxmonth?: InputMaybe<Bigint_Comparison_Exp>;
  Taxyear?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Userid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Inc_Input = {
  Approved?: InputMaybe<Scalars['bigint']['input']>;
  Automaticapproval?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Notapproved?: InputMaybe<Scalars['bigint']['input']>;
  Obligationid?: InputMaybe<Scalars['bigint']['input']>;
  Refunableamount?: InputMaybe<Scalars['bigint']['input']>;
  Requestamount?: InputMaybe<Scalars['float8']['input']>;
  Requesttype?: InputMaybe<Scalars['bigint']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Insert_Input = {
  Appcapacity?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['bigint']['input']>;
  Approvedby?: InputMaybe<Scalars['String']['input']>;
  Approveddate?: InputMaybe<Scalars['timestamptz']['input']>;
  Automaticapproval?: InputMaybe<Scalars['bigint']['input']>;
  Datereceived?: InputMaybe<Scalars['timestamptz']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declarantdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Endperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Financialapprovedby?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Notapproved?: InputMaybe<Scalars['bigint']['input']>;
  Obligationduedate?: InputMaybe<Scalars['timestamptz']['input']>;
  Obligationid?: InputMaybe<Scalars['bigint']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Reason?: InputMaybe<Scalars['String']['input']>;
  Refunableamount?: InputMaybe<Scalars['bigint']['input']>;
  Requestamount?: InputMaybe<Scalars['float8']['input']>;
  Requestclass?: InputMaybe<Scalars['String']['input']>;
  Requestid?: InputMaybe<Scalars['String']['input']>;
  Requestreason?: InputMaybe<Scalars['String']['input']>;
  Requesttype?: InputMaybe<Scalars['bigint']['input']>;
  Startperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Supportingdocument?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_Request_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_max_fields';
  Appcapacity?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['bigint']['output']>;
  Approvedby?: Maybe<Scalars['String']['output']>;
  Approveddate?: Maybe<Scalars['timestamptz']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Datereceived?: Maybe<Scalars['timestamptz']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declarantdate?: Maybe<Scalars['timestamptz']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Endperiod?: Maybe<Scalars['timestamptz']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Financialapprovedby?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationduedate?: Maybe<Scalars['timestamptz']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Reason?: Maybe<Scalars['String']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requestclass?: Maybe<Scalars['String']['output']>;
  Requestid?: Maybe<Scalars['String']['output']>;
  Requestreason?: Maybe<Scalars['String']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Startperiod?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Supportingdocument?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Max_Order_By = {
  Appcapacity?: InputMaybe<Order_By>;
  Approved?: InputMaybe<Order_By>;
  Approvedby?: InputMaybe<Order_By>;
  Approveddate?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Datereceived?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declarantdate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Endperiod?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Financialapprovedby?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationduedate?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Reason?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requestclass?: InputMaybe<Order_By>;
  Requestid?: InputMaybe<Order_By>;
  Requestreason?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Startperiod?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Supportingdocument?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_Request_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_min_fields';
  Appcapacity?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['bigint']['output']>;
  Approvedby?: Maybe<Scalars['String']['output']>;
  Approveddate?: Maybe<Scalars['timestamptz']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Datereceived?: Maybe<Scalars['timestamptz']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declarantdate?: Maybe<Scalars['timestamptz']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Endperiod?: Maybe<Scalars['timestamptz']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Financialapprovedby?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationduedate?: Maybe<Scalars['timestamptz']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Reason?: Maybe<Scalars['String']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requestclass?: Maybe<Scalars['String']['output']>;
  Requestid?: Maybe<Scalars['String']['output']>;
  Requestreason?: Maybe<Scalars['String']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Startperiod?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Supportingdocument?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Min_Order_By = {
  Appcapacity?: InputMaybe<Order_By>;
  Approved?: InputMaybe<Order_By>;
  Approvedby?: InputMaybe<Order_By>;
  Approveddate?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Datereceived?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declarantdate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Endperiod?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Financialapprovedby?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationduedate?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Reason?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requestclass?: InputMaybe<Order_By>;
  Requestid?: InputMaybe<Order_By>;
  Requestreason?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Startperiod?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Supportingdocument?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_Request_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_Request>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_Request_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Order_By = {
  Appcapacity?: InputMaybe<Order_By>;
  Approved?: InputMaybe<Order_By>;
  Approvedby?: InputMaybe<Order_By>;
  Approveddate?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Datereceived?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declarantdate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Endperiod?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Financialapprovedby?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationduedate?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Reason?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requestclass?: InputMaybe<Order_By>;
  Requestid?: InputMaybe<Order_By>;
  Requestreason?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Startperiod?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Supportingdocument?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_Request" */
export enum Rtcs_Gm_Dev_Gra_Request_Select_Column {
  /** column name */
  Appcapacity = 'Appcapacity',
  /** column name */
  Approved = 'Approved',
  /** column name */
  Approvedby = 'Approvedby',
  /** column name */
  Approveddate = 'Approveddate',
  /** column name */
  Automaticapproval = 'Automaticapproval',
  /** column name */
  Datereceived = 'Datereceived',
  /** column name */
  Declarant = 'Declarant',
  /** column name */
  Declarantdate = 'Declarantdate',
  /** column name */
  Designation = 'Designation',
  /** column name */
  Din = 'Din',
  /** column name */
  Endperiod = 'Endperiod',
  /** column name */
  Entrydate = 'Entrydate',
  /** column name */
  File = 'File',
  /** column name */
  Financialapprovedby = 'Financialapprovedby',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Notapproved = 'Notapproved',
  /** column name */
  Obligationduedate = 'Obligationduedate',
  /** column name */
  Obligationid = 'Obligationid',
  /** column name */
  Officer = 'Officer',
  /** column name */
  Reason = 'Reason',
  /** column name */
  Refunableamount = 'Refunableamount',
  /** column name */
  Requestamount = 'Requestamount',
  /** column name */
  Requestclass = 'Requestclass',
  /** column name */
  Requestid = 'Requestid',
  /** column name */
  Requestreason = 'Requestreason',
  /** column name */
  Requesttype = 'Requesttype',
  /** column name */
  Startperiod = 'Startperiod',
  /** column name */
  Status = 'Status',
  /** column name */
  Supportingdocument = 'Supportingdocument',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxmonth = 'Taxmonth',
  /** column name */
  Taxyear = 'Taxyear',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Userid = 'Userid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Set_Input = {
  Appcapacity?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['bigint']['input']>;
  Approvedby?: InputMaybe<Scalars['String']['input']>;
  Approveddate?: InputMaybe<Scalars['timestamptz']['input']>;
  Automaticapproval?: InputMaybe<Scalars['bigint']['input']>;
  Datereceived?: InputMaybe<Scalars['timestamptz']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declarantdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Endperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Financialapprovedby?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Notapproved?: InputMaybe<Scalars['bigint']['input']>;
  Obligationduedate?: InputMaybe<Scalars['timestamptz']['input']>;
  Obligationid?: InputMaybe<Scalars['bigint']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Reason?: InputMaybe<Scalars['String']['input']>;
  Refunableamount?: InputMaybe<Scalars['bigint']['input']>;
  Requestamount?: InputMaybe<Scalars['float8']['input']>;
  Requestclass?: InputMaybe<Scalars['String']['input']>;
  Requestid?: InputMaybe<Scalars['String']['input']>;
  Requestreason?: InputMaybe<Scalars['String']['input']>;
  Requesttype?: InputMaybe<Scalars['bigint']['input']>;
  Startperiod?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Supportingdocument?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_Request_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_stddev_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Stddev_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_Request_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_stddev_pop_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Stddev_Pop_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_Request_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_stddev_samp_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Stddev_Samp_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_Request_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_sum_fields';
  Approved?: Maybe<Scalars['bigint']['output']>;
  Automaticapproval?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Notapproved?: Maybe<Scalars['bigint']['output']>;
  Obligationid?: Maybe<Scalars['bigint']['output']>;
  Refunableamount?: Maybe<Scalars['bigint']['output']>;
  Requestamount?: Maybe<Scalars['float8']['output']>;
  Requesttype?: Maybe<Scalars['bigint']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Sum_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_Request_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_var_pop_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Var_Pop_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_Request_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_var_samp_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Var_Samp_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_Request_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_Request_variance_fields';
  Approved?: Maybe<Scalars['Float']['output']>;
  Automaticapproval?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Notapproved?: Maybe<Scalars['Float']['output']>;
  Obligationid?: Maybe<Scalars['Float']['output']>;
  Refunableamount?: Maybe<Scalars['Float']['output']>;
  Requestamount?: Maybe<Scalars['Float']['output']>;
  Requesttype?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_Request" */
export type Rtcs_Gm_Dev_Gra_Request_Variance_Order_By = {
  Approved?: InputMaybe<Order_By>;
  Automaticapproval?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Notapproved?: InputMaybe<Order_By>;
  Obligationid?: InputMaybe<Order_By>;
  Refunableamount?: InputMaybe<Order_By>;
  Requestamount?: InputMaybe<Order_By>;
  Requesttype?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError';
  Calculatedvalue?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  Errname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Keyinvalue?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnerrorid?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Timestamp?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_ReturnError>;
};

/** aggregate fields of "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_ReturnError_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_ReturnError_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_ReturnError". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp = {
  Calculatedvalue?: InputMaybe<String_Comparison_Exp>;
  Entrydate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Errname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Keyinvalue?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnerrorid?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Insert_Input = {
  Calculatedvalue?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Errname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Keyinvalue?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnerrorid?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_max_fields';
  Calculatedvalue?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  Errname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Keyinvalue?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnerrorid?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Timestamp?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Max_Order_By = {
  Calculatedvalue?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  Errname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Keyinvalue?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnerrorid?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Timestamp?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_min_fields';
  Calculatedvalue?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['timestamptz']['output']>;
  Errname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Keyinvalue?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnerrorid?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Timestamp?: Maybe<Scalars['timestamptz']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Min_Order_By = {
  Calculatedvalue?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  Errname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Keyinvalue?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnerrorid?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Timestamp?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_ReturnError>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_ReturnError_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Order_By = {
  Calculatedvalue?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  Errname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Keyinvalue?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnerrorid?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Timestamp?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_ReturnError" */
export enum Rtcs_Gm_Dev_Gra_ReturnError_Select_Column {
  /** column name */
  Calculatedvalue = 'Calculatedvalue',
  /** column name */
  Entrydate = 'Entrydate',
  /** column name */
  Errname = 'Errname',
  /** column name */
  File = 'File',
  /** column name */
  Keyinvalue = 'Keyinvalue',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnerrorid = 'Returnerrorid',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Timestamp = 'Timestamp',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Set_Input = {
  Calculatedvalue?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['timestamptz']['input']>;
  Errname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Keyinvalue?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnerrorid?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_ReturnError_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnError_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_ReturnError" */
export type Rtcs_Gm_Dev_Gra_ReturnError_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Assessableamunt?: Maybe<Scalars['String']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Chargeableamount?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declaredate?: Maybe<Scalars['String']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Duedate?: Maybe<Scalars['String']['output']>;
  Enddate?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['String']['output']>;
  ErrorStatus?: Maybe<Scalars['String']['output']>;
  Expenses?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['String']['output']>;
  Nettaxdue?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Returnfiledate?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Specialfilingreason?: Maybe<Scalars['String']['output']>;
  Startdate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxdue?: Maybe<Scalars['String']['output']>;
  Taxliability?: Maybe<Scalars['String']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_ReturnsBase>;
};

/** aggregate fields of "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_avg_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Avg_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_ReturnsBase". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp = {
  Ammendmentid?: InputMaybe<Float8_Comparison_Exp>;
  Assessableamunt?: InputMaybe<String_Comparison_Exp>;
  Audit?: InputMaybe<Bigint_Comparison_Exp>;
  Auditrequest?: InputMaybe<Bigint_Comparison_Exp>;
  Chargeableamount?: InputMaybe<String_Comparison_Exp>;
  Declarant?: InputMaybe<String_Comparison_Exp>;
  Declaredate?: InputMaybe<String_Comparison_Exp>;
  Designation?: InputMaybe<String_Comparison_Exp>;
  Din?: InputMaybe<String_Comparison_Exp>;
  Duedate?: InputMaybe<String_Comparison_Exp>;
  Enddate?: InputMaybe<String_Comparison_Exp>;
  Entrydate?: InputMaybe<String_Comparison_Exp>;
  ErrorStatus?: InputMaybe<String_Comparison_Exp>;
  Expenses?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Filereturn?: InputMaybe<Bigint_Comparison_Exp>;
  Grossincome?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Netincome?: InputMaybe<String_Comparison_Exp>;
  Nettaxdue?: InputMaybe<String_Comparison_Exp>;
  Officer?: InputMaybe<String_Comparison_Exp>;
  Returnfiledate?: InputMaybe<String_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Sector?: InputMaybe<String_Comparison_Exp>;
  Specialfiling?: InputMaybe<Bigint_Comparison_Exp>;
  Specialfilingreason?: InputMaybe<String_Comparison_Exp>;
  Startdate?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Taxcode?: InputMaybe<Bigint_Comparison_Exp>;
  Taxdue?: InputMaybe<String_Comparison_Exp>;
  Taxliability?: InputMaybe<String_Comparison_Exp>;
  Taxmonth?: InputMaybe<Bigint_Comparison_Exp>;
  Taxyear?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Totaldeduction?: InputMaybe<Float8_Comparison_Exp>;
  Totalexemption?: InputMaybe<Float8_Comparison_Exp>;
  Userid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Inc_Input = {
  Ammendmentid?: InputMaybe<Scalars['float8']['input']>;
  Audit?: InputMaybe<Scalars['bigint']['input']>;
  Auditrequest?: InputMaybe<Scalars['bigint']['input']>;
  Filereturn?: InputMaybe<Scalars['bigint']['input']>;
  Grossincome?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Specialfiling?: InputMaybe<Scalars['bigint']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totaldeduction?: InputMaybe<Scalars['float8']['input']>;
  Totalexemption?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Insert_Input = {
  Ammendmentid?: InputMaybe<Scalars['float8']['input']>;
  Assessableamunt?: InputMaybe<Scalars['String']['input']>;
  Audit?: InputMaybe<Scalars['bigint']['input']>;
  Auditrequest?: InputMaybe<Scalars['bigint']['input']>;
  Chargeableamount?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declaredate?: InputMaybe<Scalars['String']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Duedate?: InputMaybe<Scalars['String']['input']>;
  Enddate?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['String']['input']>;
  ErrorStatus?: InputMaybe<Scalars['String']['input']>;
  Expenses?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Filereturn?: InputMaybe<Scalars['bigint']['input']>;
  Grossincome?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['String']['input']>;
  Nettaxdue?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Returnfiledate?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Specialfiling?: InputMaybe<Scalars['bigint']['input']>;
  Specialfilingreason?: InputMaybe<Scalars['String']['input']>;
  Startdate?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxdue?: InputMaybe<Scalars['String']['input']>;
  Taxliability?: InputMaybe<Scalars['String']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totaldeduction?: InputMaybe<Scalars['float8']['input']>;
  Totalexemption?: InputMaybe<Scalars['float8']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_max_fields';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Assessableamunt?: Maybe<Scalars['String']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Chargeableamount?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declaredate?: Maybe<Scalars['String']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Duedate?: Maybe<Scalars['String']['output']>;
  Enddate?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['String']['output']>;
  ErrorStatus?: Maybe<Scalars['String']['output']>;
  Expenses?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['String']['output']>;
  Nettaxdue?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Returnfiledate?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Specialfilingreason?: Maybe<Scalars['String']['output']>;
  Startdate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxdue?: Maybe<Scalars['String']['output']>;
  Taxliability?: Maybe<Scalars['String']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Max_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Assessableamunt?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Chargeableamount?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declaredate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Duedate?: InputMaybe<Order_By>;
  Enddate?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  ErrorStatus?: InputMaybe<Order_By>;
  Expenses?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Nettaxdue?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Returnfiledate?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Specialfilingreason?: InputMaybe<Order_By>;
  Startdate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxdue?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_min_fields';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Assessableamunt?: Maybe<Scalars['String']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Chargeableamount?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  Declaredate?: Maybe<Scalars['String']['output']>;
  Designation?: Maybe<Scalars['String']['output']>;
  Din?: Maybe<Scalars['String']['output']>;
  Duedate?: Maybe<Scalars['String']['output']>;
  Enddate?: Maybe<Scalars['String']['output']>;
  Entrydate?: Maybe<Scalars['String']['output']>;
  ErrorStatus?: Maybe<Scalars['String']['output']>;
  Expenses?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Netincome?: Maybe<Scalars['String']['output']>;
  Nettaxdue?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Returnfiledate?: Maybe<Scalars['String']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Specialfilingreason?: Maybe<Scalars['String']['output']>;
  Startdate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxdue?: Maybe<Scalars['String']['output']>;
  Taxliability?: Maybe<Scalars['String']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
  Userid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Min_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Assessableamunt?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Chargeableamount?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declaredate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Duedate?: InputMaybe<Order_By>;
  Enddate?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  ErrorStatus?: InputMaybe<Order_By>;
  Expenses?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Nettaxdue?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Returnfiledate?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Specialfilingreason?: InputMaybe<Order_By>;
  Startdate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxdue?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_ReturnsBase>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_ReturnsBase_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Assessableamunt?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Chargeableamount?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  Declaredate?: InputMaybe<Order_By>;
  Designation?: InputMaybe<Order_By>;
  Din?: InputMaybe<Order_By>;
  Duedate?: InputMaybe<Order_By>;
  Enddate?: InputMaybe<Order_By>;
  Entrydate?: InputMaybe<Order_By>;
  ErrorStatus?: InputMaybe<Order_By>;
  Expenses?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Netincome?: InputMaybe<Order_By>;
  Nettaxdue?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Returnfiledate?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Specialfilingreason?: InputMaybe<Order_By>;
  Startdate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxdue?: InputMaybe<Order_By>;
  Taxliability?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
  Userid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export enum Rtcs_Gm_Dev_Gra_ReturnsBase_Select_Column {
  /** column name */
  Ammendmentid = 'Ammendmentid',
  /** column name */
  Assessableamunt = 'Assessableamunt',
  /** column name */
  Audit = 'Audit',
  /** column name */
  Auditrequest = 'Auditrequest',
  /** column name */
  Chargeableamount = 'Chargeableamount',
  /** column name */
  Declarant = 'Declarant',
  /** column name */
  Declaredate = 'Declaredate',
  /** column name */
  Designation = 'Designation',
  /** column name */
  Din = 'Din',
  /** column name */
  Duedate = 'Duedate',
  /** column name */
  Enddate = 'Enddate',
  /** column name */
  Entrydate = 'Entrydate',
  /** column name */
  ErrorStatus = 'ErrorStatus',
  /** column name */
  Expenses = 'Expenses',
  /** column name */
  File = 'File',
  /** column name */
  Filereturn = 'Filereturn',
  /** column name */
  Grossincome = 'Grossincome',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Netincome = 'Netincome',
  /** column name */
  Nettaxdue = 'Nettaxdue',
  /** column name */
  Officer = 'Officer',
  /** column name */
  Returnfiledate = 'Returnfiledate',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Sector = 'Sector',
  /** column name */
  Specialfiling = 'Specialfiling',
  /** column name */
  Specialfilingreason = 'Specialfilingreason',
  /** column name */
  Startdate = 'Startdate',
  /** column name */
  Status = 'Status',
  /** column name */
  Taxcode = 'Taxcode',
  /** column name */
  Taxdue = 'Taxdue',
  /** column name */
  Taxliability = 'Taxliability',
  /** column name */
  Taxmonth = 'Taxmonth',
  /** column name */
  Taxyear = 'Taxyear',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Totaldeduction = 'Totaldeduction',
  /** column name */
  Totalexemption = 'Totalexemption',
  /** column name */
  Userid = 'Userid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Set_Input = {
  Ammendmentid?: InputMaybe<Scalars['float8']['input']>;
  Assessableamunt?: InputMaybe<Scalars['String']['input']>;
  Audit?: InputMaybe<Scalars['bigint']['input']>;
  Auditrequest?: InputMaybe<Scalars['bigint']['input']>;
  Chargeableamount?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  Declaredate?: InputMaybe<Scalars['String']['input']>;
  Designation?: InputMaybe<Scalars['String']['input']>;
  Din?: InputMaybe<Scalars['String']['input']>;
  Duedate?: InputMaybe<Scalars['String']['input']>;
  Enddate?: InputMaybe<Scalars['String']['input']>;
  Entrydate?: InputMaybe<Scalars['String']['input']>;
  ErrorStatus?: InputMaybe<Scalars['String']['input']>;
  Expenses?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Filereturn?: InputMaybe<Scalars['bigint']['input']>;
  Grossincome?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Netincome?: InputMaybe<Scalars['String']['input']>;
  Nettaxdue?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Returnfiledate?: InputMaybe<Scalars['String']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Specialfiling?: InputMaybe<Scalars['bigint']['input']>;
  Specialfilingreason?: InputMaybe<Scalars['String']['input']>;
  Startdate?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Taxcode?: InputMaybe<Scalars['bigint']['input']>;
  Taxdue?: InputMaybe<Scalars['String']['input']>;
  Taxliability?: InputMaybe<Scalars['String']['input']>;
  Taxmonth?: InputMaybe<Scalars['bigint']['input']>;
  Taxyear?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Totaldeduction?: InputMaybe<Scalars['float8']['input']>;
  Totalexemption?: InputMaybe<Scalars['float8']['input']>;
  Userid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_stddev_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_stddev_pop_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Pop_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_stddev_samp_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Stddev_Samp_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_sum_fields';
  Ammendmentid?: Maybe<Scalars['float8']['output']>;
  Audit?: Maybe<Scalars['bigint']['output']>;
  Auditrequest?: Maybe<Scalars['bigint']['output']>;
  Filereturn?: Maybe<Scalars['bigint']['output']>;
  Grossincome?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Specialfiling?: Maybe<Scalars['bigint']['output']>;
  Taxcode?: Maybe<Scalars['bigint']['output']>;
  Taxmonth?: Maybe<Scalars['bigint']['output']>;
  Taxyear?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Totaldeduction?: Maybe<Scalars['float8']['output']>;
  Totalexemption?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Sum_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_var_pop_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Pop_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_var_samp_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Var_Samp_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_ReturnsBase_variance_fields';
  Ammendmentid?: Maybe<Scalars['Float']['output']>;
  Audit?: Maybe<Scalars['Float']['output']>;
  Auditrequest?: Maybe<Scalars['Float']['output']>;
  Filereturn?: Maybe<Scalars['Float']['output']>;
  Grossincome?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Specialfiling?: Maybe<Scalars['Float']['output']>;
  Taxcode?: Maybe<Scalars['Float']['output']>;
  Taxmonth?: Maybe<Scalars['Float']['output']>;
  Taxyear?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Totaldeduction?: Maybe<Scalars['Float']['output']>;
  Totalexemption?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_ReturnsBase" */
export type Rtcs_Gm_Dev_Gra_ReturnsBase_Variance_Order_By = {
  Ammendmentid?: InputMaybe<Order_By>;
  Audit?: InputMaybe<Order_By>;
  Auditrequest?: InputMaybe<Order_By>;
  Filereturn?: InputMaybe<Order_By>;
  Grossincome?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Specialfiling?: InputMaybe<Order_By>;
  Taxcode?: InputMaybe<Order_By>;
  Taxmonth?: InputMaybe<Order_By>;
  Taxyear?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Totaldeduction?: InputMaybe<Order_By>;
  Totalexemption?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer';
  AgAddress?: Maybe<Scalars['String']['output']>;
  AgName?: Maybe<Scalars['String']['output']>;
  AgPhoneNo?: Maybe<Scalars['String']['output']>;
  AgTIN?: Maybe<Scalars['String']['output']>;
  AppCapacity?: Maybe<Scalars['String']['output']>;
  ApprovedBy?: Maybe<Scalars['String']['output']>;
  ApprovedDate?: Maybe<Scalars['String']['output']>;
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  BlockName?: Maybe<Scalars['String']['output']>;
  BusStartDate?: Maybe<Scalars['String']['output']>;
  BusinessCode?: Maybe<Scalars['String']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DIN?: Maybe<Scalars['String']['output']>;
  DecCapacity?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  DeclareDate?: Maybe<Scalars['String']['output']>;
  DelDate?: Maybe<Scalars['String']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmpAddress?: Maybe<Scalars['String']['output']>;
  EmpEMail?: Maybe<Scalars['String']['output']>;
  EmpFax?: Maybe<Scalars['String']['output']>;
  EmpMobile?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo1?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo2?: Maybe<Scalars['String']['output']>;
  EmpTIN?: Maybe<Scalars['String']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  EntryDate?: Maybe<Scalars['String']['output']>;
  ExemptAuth?: Maybe<Scalars['String']['output']>;
  FName?: Maybe<Scalars['String']['output']>;
  FaxNo?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  ImportNo?: Maybe<Scalars['String']['output']>;
  IncomeTax?: Maybe<Scalars['String']['output']>;
  LName?: Maybe<Scalars['String']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  LastDocNum?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastchanged?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MFName?: Maybe<Scalars['String']['output']>;
  MLName?: Maybe<Scalars['String']['output']>;
  MName?: Maybe<Scalars['String']['output']>;
  Marital?: Maybe<Scalars['String']['output']>;
  NIN?: Maybe<Scalars['String']['output']>;
  NINIncorpCert?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Others?: Maybe<Scalars['String']['output']>;
  PassportNo?: Maybe<Scalars['String']['output']>;
  PayeeTax?: Maybe<Scalars['String']['output']>;
  PayerType?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  PhoneNo2?: Maybe<Scalars['String']['output']>;
  PhysArea?: Maybe<Scalars['String']['output']>;
  PhysPlotNo?: Maybe<Scalars['String']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PhysStreet?: Maybe<Scalars['String']['output']>;
  PhysTown?: Maybe<Scalars['String']['output']>;
  PostalBox?: Maybe<Scalars['String']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  PostalTown?: Maybe<Scalars['String']['output']>;
  PrevIncFileNo?: Maybe<Scalars['String']['output']>;
  PrevName?: Maybe<Scalars['String']['output']>;
  PrevPayeNo?: Maybe<Scalars['String']['output']>;
  ReMark?: Maybe<Scalars['String']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  RegDate?: Maybe<Scalars['String']['output']>;
  RegStatus?: Maybe<Scalars['String']['output']>;
  RegTypeCode?: Maybe<Scalars['String']['output']>;
  StampDate?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  TINNotApproval?: Maybe<Scalars['String']['output']>;
  TaxPayerID?: Maybe<Scalars['String']['output']>;
  TradingName?: Maybe<Scalars['String']['output']>;
  TurnOver?: Maybe<Scalars['String']['output']>;
  UserID?: Maybe<Scalars['String']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_TaxPayer>;
};

/** aggregate fields of "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_TaxPayer_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_TaxPayer_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_avg_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Avg_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_TaxPayer". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp = {
  AgAddress?: InputMaybe<String_Comparison_Exp>;
  AgName?: InputMaybe<String_Comparison_Exp>;
  AgPhoneNo?: InputMaybe<String_Comparison_Exp>;
  AgTIN?: InputMaybe<String_Comparison_Exp>;
  AppCapacity?: InputMaybe<String_Comparison_Exp>;
  ApprovedBy?: InputMaybe<String_Comparison_Exp>;
  ApprovedDate?: InputMaybe<String_Comparison_Exp>;
  AuditFlag?: InputMaybe<Bigint_Comparison_Exp>;
  BirthDate?: InputMaybe<String_Comparison_Exp>;
  BlockName?: InputMaybe<String_Comparison_Exp>;
  BusStartDate?: InputMaybe<String_Comparison_Exp>;
  BusinessCode?: InputMaybe<String_Comparison_Exp>;
  ChkApproved?: InputMaybe<Bigint_Comparison_Exp>;
  ChkCitizen?: InputMaybe<Bigint_Comparison_Exp>;
  ChkExempt?: InputMaybe<Bigint_Comparison_Exp>;
  ChkIdCard?: InputMaybe<Bigint_Comparison_Exp>;
  ChkNotApproved?: InputMaybe<Bigint_Comparison_Exp>;
  ChkPassPort?: InputMaybe<Bigint_Comparison_Exp>;
  ChkRegist?: InputMaybe<Bigint_Comparison_Exp>;
  ChkcertInCorp?: InputMaybe<Bigint_Comparison_Exp>;
  Country?: InputMaybe<String_Comparison_Exp>;
  CreatedDate?: InputMaybe<String_Comparison_Exp>;
  DIN?: InputMaybe<String_Comparison_Exp>;
  DecCapacity?: InputMaybe<String_Comparison_Exp>;
  Declarant?: InputMaybe<String_Comparison_Exp>;
  DeclareDate?: InputMaybe<String_Comparison_Exp>;
  DelDate?: InputMaybe<String_Comparison_Exp>;
  DelFlag?: InputMaybe<Bigint_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  EmpAddress?: InputMaybe<String_Comparison_Exp>;
  EmpEMail?: InputMaybe<String_Comparison_Exp>;
  EmpFax?: InputMaybe<String_Comparison_Exp>;
  EmpMobile?: InputMaybe<String_Comparison_Exp>;
  EmpPhoneNo1?: InputMaybe<String_Comparison_Exp>;
  EmpPhoneNo2?: InputMaybe<String_Comparison_Exp>;
  EmpTIN?: InputMaybe<String_Comparison_Exp>;
  EmpType?: InputMaybe<Bigint_Comparison_Exp>;
  EndDate?: InputMaybe<String_Comparison_Exp>;
  Enforce?: InputMaybe<Bigint_Comparison_Exp>;
  EntryDate?: InputMaybe<String_Comparison_Exp>;
  ExemptAuth?: InputMaybe<String_Comparison_Exp>;
  FName?: InputMaybe<String_Comparison_Exp>;
  FaxNo?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  ForceReg?: InputMaybe<Bigint_Comparison_Exp>;
  Gender?: InputMaybe<String_Comparison_Exp>;
  ImportNo?: InputMaybe<String_Comparison_Exp>;
  IncomeTax?: InputMaybe<String_Comparison_Exp>;
  LName?: InputMaybe<String_Comparison_Exp>;
  LargTaxPayer?: InputMaybe<Bigint_Comparison_Exp>;
  LargeTaxPayerStatus?: InputMaybe<Bigint_Comparison_Exp>;
  LastDocNum?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lastchanged?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  MFName?: InputMaybe<String_Comparison_Exp>;
  MLName?: InputMaybe<String_Comparison_Exp>;
  MName?: InputMaybe<String_Comparison_Exp>;
  Marital?: InputMaybe<String_Comparison_Exp>;
  NIN?: InputMaybe<String_Comparison_Exp>;
  NINIncorpCert?: InputMaybe<String_Comparison_Exp>;
  Officer?: InputMaybe<String_Comparison_Exp>;
  Others?: InputMaybe<String_Comparison_Exp>;
  PassportNo?: InputMaybe<String_Comparison_Exp>;
  PayeeTax?: InputMaybe<String_Comparison_Exp>;
  PayerType?: InputMaybe<String_Comparison_Exp>;
  PhoneNo?: InputMaybe<String_Comparison_Exp>;
  PhoneNo2?: InputMaybe<String_Comparison_Exp>;
  PhysArea?: InputMaybe<String_Comparison_Exp>;
  PhysPlotNo?: InputMaybe<String_Comparison_Exp>;
  PhysProv?: InputMaybe<Bigint_Comparison_Exp>;
  PhysStreet?: InputMaybe<String_Comparison_Exp>;
  PhysTown?: InputMaybe<String_Comparison_Exp>;
  PostalBox?: InputMaybe<String_Comparison_Exp>;
  PostalProv?: InputMaybe<Bigint_Comparison_Exp>;
  PostalTown?: InputMaybe<String_Comparison_Exp>;
  PrevIncFileNo?: InputMaybe<String_Comparison_Exp>;
  PrevName?: InputMaybe<String_Comparison_Exp>;
  PrevPayeNo?: InputMaybe<String_Comparison_Exp>;
  ReMark?: InputMaybe<String_Comparison_Exp>;
  RefusalCode?: InputMaybe<Float8_Comparison_Exp>;
  RegCenterCode?: InputMaybe<Bigint_Comparison_Exp>;
  RegDate?: InputMaybe<String_Comparison_Exp>;
  RegStatus?: InputMaybe<String_Comparison_Exp>;
  RegTypeCode?: InputMaybe<String_Comparison_Exp>;
  StampDate?: InputMaybe<String_Comparison_Exp>;
  StartDate?: InputMaybe<String_Comparison_Exp>;
  TIN?: InputMaybe<Bigint_Comparison_Exp>;
  TINApproval?: InputMaybe<Bigint_Comparison_Exp>;
  TINCertCount?: InputMaybe<Bigint_Comparison_Exp>;
  TINNotApproval?: InputMaybe<String_Comparison_Exp>;
  TaxPayerID?: InputMaybe<String_Comparison_Exp>;
  TradingName?: InputMaybe<String_Comparison_Exp>;
  TurnOver?: InputMaybe<String_Comparison_Exp>;
  UserID?: InputMaybe<String_Comparison_Exp>;
  VATTurnOver?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>>>;
  chkBirthCert?: InputMaybe<Bigint_Comparison_Exp>;
  chkEmpExpat?: InputMaybe<Bigint_Comparison_Exp>;
  chkEmploy?: InputMaybe<Bigint_Comparison_Exp>;
  chkOtherBranch?: InputMaybe<Bigint_Comparison_Exp>;
  chkSpecialTaxPeriod?: InputMaybe<Bigint_Comparison_Exp>;
  chkTaxExempt?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Inc_Input = {
  AuditFlag?: InputMaybe<Scalars['bigint']['input']>;
  ChkApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkCitizen?: InputMaybe<Scalars['bigint']['input']>;
  ChkExempt?: InputMaybe<Scalars['bigint']['input']>;
  ChkIdCard?: InputMaybe<Scalars['bigint']['input']>;
  ChkNotApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkPassPort?: InputMaybe<Scalars['bigint']['input']>;
  ChkRegist?: InputMaybe<Scalars['bigint']['input']>;
  ChkcertInCorp?: InputMaybe<Scalars['bigint']['input']>;
  DelFlag?: InputMaybe<Scalars['bigint']['input']>;
  EmpType?: InputMaybe<Scalars['bigint']['input']>;
  Enforce?: InputMaybe<Scalars['bigint']['input']>;
  ForceReg?: InputMaybe<Scalars['bigint']['input']>;
  LargTaxPayer?: InputMaybe<Scalars['bigint']['input']>;
  LargeTaxPayerStatus?: InputMaybe<Scalars['bigint']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  PhysProv?: InputMaybe<Scalars['bigint']['input']>;
  PostalProv?: InputMaybe<Scalars['bigint']['input']>;
  RefusalCode?: InputMaybe<Scalars['float8']['input']>;
  RegCenterCode?: InputMaybe<Scalars['bigint']['input']>;
  TIN?: InputMaybe<Scalars['bigint']['input']>;
  TINApproval?: InputMaybe<Scalars['bigint']['input']>;
  TINCertCount?: InputMaybe<Scalars['bigint']['input']>;
  VATTurnOver?: InputMaybe<Scalars['bigint']['input']>;
  chkBirthCert?: InputMaybe<Scalars['bigint']['input']>;
  chkEmpExpat?: InputMaybe<Scalars['bigint']['input']>;
  chkEmploy?: InputMaybe<Scalars['bigint']['input']>;
  chkOtherBranch?: InputMaybe<Scalars['bigint']['input']>;
  chkSpecialTaxPeriod?: InputMaybe<Scalars['bigint']['input']>;
  chkTaxExempt?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Insert_Input = {
  AgAddress?: InputMaybe<Scalars['String']['input']>;
  AgName?: InputMaybe<Scalars['String']['input']>;
  AgPhoneNo?: InputMaybe<Scalars['String']['input']>;
  AgTIN?: InputMaybe<Scalars['String']['input']>;
  AppCapacity?: InputMaybe<Scalars['String']['input']>;
  ApprovedBy?: InputMaybe<Scalars['String']['input']>;
  ApprovedDate?: InputMaybe<Scalars['String']['input']>;
  AuditFlag?: InputMaybe<Scalars['bigint']['input']>;
  BirthDate?: InputMaybe<Scalars['String']['input']>;
  BlockName?: InputMaybe<Scalars['String']['input']>;
  BusStartDate?: InputMaybe<Scalars['String']['input']>;
  BusinessCode?: InputMaybe<Scalars['String']['input']>;
  ChkApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkCitizen?: InputMaybe<Scalars['bigint']['input']>;
  ChkExempt?: InputMaybe<Scalars['bigint']['input']>;
  ChkIdCard?: InputMaybe<Scalars['bigint']['input']>;
  ChkNotApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkPassPort?: InputMaybe<Scalars['bigint']['input']>;
  ChkRegist?: InputMaybe<Scalars['bigint']['input']>;
  ChkcertInCorp?: InputMaybe<Scalars['bigint']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedDate?: InputMaybe<Scalars['String']['input']>;
  DIN?: InputMaybe<Scalars['String']['input']>;
  DecCapacity?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  DeclareDate?: InputMaybe<Scalars['String']['input']>;
  DelDate?: InputMaybe<Scalars['String']['input']>;
  DelFlag?: InputMaybe<Scalars['bigint']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmpAddress?: InputMaybe<Scalars['String']['input']>;
  EmpEMail?: InputMaybe<Scalars['String']['input']>;
  EmpFax?: InputMaybe<Scalars['String']['input']>;
  EmpMobile?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo1?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo2?: InputMaybe<Scalars['String']['input']>;
  EmpTIN?: InputMaybe<Scalars['String']['input']>;
  EmpType?: InputMaybe<Scalars['bigint']['input']>;
  EndDate?: InputMaybe<Scalars['String']['input']>;
  Enforce?: InputMaybe<Scalars['bigint']['input']>;
  EntryDate?: InputMaybe<Scalars['String']['input']>;
  ExemptAuth?: InputMaybe<Scalars['String']['input']>;
  FName?: InputMaybe<Scalars['String']['input']>;
  FaxNo?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  ForceReg?: InputMaybe<Scalars['bigint']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  ImportNo?: InputMaybe<Scalars['String']['input']>;
  IncomeTax?: InputMaybe<Scalars['String']['input']>;
  LName?: InputMaybe<Scalars['String']['input']>;
  LargTaxPayer?: InputMaybe<Scalars['bigint']['input']>;
  LargeTaxPayerStatus?: InputMaybe<Scalars['bigint']['input']>;
  LastDocNum?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastchanged?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MFName?: InputMaybe<Scalars['String']['input']>;
  MLName?: InputMaybe<Scalars['String']['input']>;
  MName?: InputMaybe<Scalars['String']['input']>;
  Marital?: InputMaybe<Scalars['String']['input']>;
  NIN?: InputMaybe<Scalars['String']['input']>;
  NINIncorpCert?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Others?: InputMaybe<Scalars['String']['input']>;
  PassportNo?: InputMaybe<Scalars['String']['input']>;
  PayeeTax?: InputMaybe<Scalars['String']['input']>;
  PayerType?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  PhoneNo2?: InputMaybe<Scalars['String']['input']>;
  PhysArea?: InputMaybe<Scalars['String']['input']>;
  PhysPlotNo?: InputMaybe<Scalars['String']['input']>;
  PhysProv?: InputMaybe<Scalars['bigint']['input']>;
  PhysStreet?: InputMaybe<Scalars['String']['input']>;
  PhysTown?: InputMaybe<Scalars['String']['input']>;
  PostalBox?: InputMaybe<Scalars['String']['input']>;
  PostalProv?: InputMaybe<Scalars['bigint']['input']>;
  PostalTown?: InputMaybe<Scalars['String']['input']>;
  PrevIncFileNo?: InputMaybe<Scalars['String']['input']>;
  PrevName?: InputMaybe<Scalars['String']['input']>;
  PrevPayeNo?: InputMaybe<Scalars['String']['input']>;
  ReMark?: InputMaybe<Scalars['String']['input']>;
  RefusalCode?: InputMaybe<Scalars['float8']['input']>;
  RegCenterCode?: InputMaybe<Scalars['bigint']['input']>;
  RegDate?: InputMaybe<Scalars['String']['input']>;
  RegStatus?: InputMaybe<Scalars['String']['input']>;
  RegTypeCode?: InputMaybe<Scalars['String']['input']>;
  StampDate?: InputMaybe<Scalars['String']['input']>;
  StartDate?: InputMaybe<Scalars['String']['input']>;
  TIN?: InputMaybe<Scalars['bigint']['input']>;
  TINApproval?: InputMaybe<Scalars['bigint']['input']>;
  TINCertCount?: InputMaybe<Scalars['bigint']['input']>;
  TINNotApproval?: InputMaybe<Scalars['String']['input']>;
  TaxPayerID?: InputMaybe<Scalars['String']['input']>;
  TradingName?: InputMaybe<Scalars['String']['input']>;
  TurnOver?: InputMaybe<Scalars['String']['input']>;
  UserID?: InputMaybe<Scalars['String']['input']>;
  VATTurnOver?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  chkBirthCert?: InputMaybe<Scalars['bigint']['input']>;
  chkEmpExpat?: InputMaybe<Scalars['bigint']['input']>;
  chkEmploy?: InputMaybe<Scalars['bigint']['input']>;
  chkOtherBranch?: InputMaybe<Scalars['bigint']['input']>;
  chkSpecialTaxPeriod?: InputMaybe<Scalars['bigint']['input']>;
  chkTaxExempt?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_max_fields';
  AgAddress?: Maybe<Scalars['String']['output']>;
  AgName?: Maybe<Scalars['String']['output']>;
  AgPhoneNo?: Maybe<Scalars['String']['output']>;
  AgTIN?: Maybe<Scalars['String']['output']>;
  AppCapacity?: Maybe<Scalars['String']['output']>;
  ApprovedBy?: Maybe<Scalars['String']['output']>;
  ApprovedDate?: Maybe<Scalars['String']['output']>;
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  BlockName?: Maybe<Scalars['String']['output']>;
  BusStartDate?: Maybe<Scalars['String']['output']>;
  BusinessCode?: Maybe<Scalars['String']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DIN?: Maybe<Scalars['String']['output']>;
  DecCapacity?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  DeclareDate?: Maybe<Scalars['String']['output']>;
  DelDate?: Maybe<Scalars['String']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmpAddress?: Maybe<Scalars['String']['output']>;
  EmpEMail?: Maybe<Scalars['String']['output']>;
  EmpFax?: Maybe<Scalars['String']['output']>;
  EmpMobile?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo1?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo2?: Maybe<Scalars['String']['output']>;
  EmpTIN?: Maybe<Scalars['String']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  EntryDate?: Maybe<Scalars['String']['output']>;
  ExemptAuth?: Maybe<Scalars['String']['output']>;
  FName?: Maybe<Scalars['String']['output']>;
  FaxNo?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  ImportNo?: Maybe<Scalars['String']['output']>;
  IncomeTax?: Maybe<Scalars['String']['output']>;
  LName?: Maybe<Scalars['String']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  LastDocNum?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastchanged?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MFName?: Maybe<Scalars['String']['output']>;
  MLName?: Maybe<Scalars['String']['output']>;
  MName?: Maybe<Scalars['String']['output']>;
  Marital?: Maybe<Scalars['String']['output']>;
  NIN?: Maybe<Scalars['String']['output']>;
  NINIncorpCert?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Others?: Maybe<Scalars['String']['output']>;
  PassportNo?: Maybe<Scalars['String']['output']>;
  PayeeTax?: Maybe<Scalars['String']['output']>;
  PayerType?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  PhoneNo2?: Maybe<Scalars['String']['output']>;
  PhysArea?: Maybe<Scalars['String']['output']>;
  PhysPlotNo?: Maybe<Scalars['String']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PhysStreet?: Maybe<Scalars['String']['output']>;
  PhysTown?: Maybe<Scalars['String']['output']>;
  PostalBox?: Maybe<Scalars['String']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  PostalTown?: Maybe<Scalars['String']['output']>;
  PrevIncFileNo?: Maybe<Scalars['String']['output']>;
  PrevName?: Maybe<Scalars['String']['output']>;
  PrevPayeNo?: Maybe<Scalars['String']['output']>;
  ReMark?: Maybe<Scalars['String']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  RegDate?: Maybe<Scalars['String']['output']>;
  RegStatus?: Maybe<Scalars['String']['output']>;
  RegTypeCode?: Maybe<Scalars['String']['output']>;
  StampDate?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  TINNotApproval?: Maybe<Scalars['String']['output']>;
  TaxPayerID?: Maybe<Scalars['String']['output']>;
  TradingName?: Maybe<Scalars['String']['output']>;
  TurnOver?: Maybe<Scalars['String']['output']>;
  UserID?: Maybe<Scalars['String']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Max_Order_By = {
  AgAddress?: InputMaybe<Order_By>;
  AgName?: InputMaybe<Order_By>;
  AgPhoneNo?: InputMaybe<Order_By>;
  AgTIN?: InputMaybe<Order_By>;
  AppCapacity?: InputMaybe<Order_By>;
  ApprovedBy?: InputMaybe<Order_By>;
  ApprovedDate?: InputMaybe<Order_By>;
  AuditFlag?: InputMaybe<Order_By>;
  BirthDate?: InputMaybe<Order_By>;
  BlockName?: InputMaybe<Order_By>;
  BusStartDate?: InputMaybe<Order_By>;
  BusinessCode?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedDate?: InputMaybe<Order_By>;
  DIN?: InputMaybe<Order_By>;
  DecCapacity?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  DeclareDate?: InputMaybe<Order_By>;
  DelDate?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmpAddress?: InputMaybe<Order_By>;
  EmpEMail?: InputMaybe<Order_By>;
  EmpFax?: InputMaybe<Order_By>;
  EmpMobile?: InputMaybe<Order_By>;
  EmpPhoneNo1?: InputMaybe<Order_By>;
  EmpPhoneNo2?: InputMaybe<Order_By>;
  EmpTIN?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  EntryDate?: InputMaybe<Order_By>;
  ExemptAuth?: InputMaybe<Order_By>;
  FName?: InputMaybe<Order_By>;
  FaxNo?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  ImportNo?: InputMaybe<Order_By>;
  IncomeTax?: InputMaybe<Order_By>;
  LName?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  LastDocNum?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastchanged?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MFName?: InputMaybe<Order_By>;
  MLName?: InputMaybe<Order_By>;
  MName?: InputMaybe<Order_By>;
  Marital?: InputMaybe<Order_By>;
  NIN?: InputMaybe<Order_By>;
  NINIncorpCert?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Others?: InputMaybe<Order_By>;
  PassportNo?: InputMaybe<Order_By>;
  PayeeTax?: InputMaybe<Order_By>;
  PayerType?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  PhoneNo2?: InputMaybe<Order_By>;
  PhysArea?: InputMaybe<Order_By>;
  PhysPlotNo?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PhysStreet?: InputMaybe<Order_By>;
  PhysTown?: InputMaybe<Order_By>;
  PostalBox?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  PostalTown?: InputMaybe<Order_By>;
  PrevIncFileNo?: InputMaybe<Order_By>;
  PrevName?: InputMaybe<Order_By>;
  PrevPayeNo?: InputMaybe<Order_By>;
  ReMark?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  RegDate?: InputMaybe<Order_By>;
  RegStatus?: InputMaybe<Order_By>;
  RegTypeCode?: InputMaybe<Order_By>;
  StampDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  TINNotApproval?: InputMaybe<Order_By>;
  TaxPayerID?: InputMaybe<Order_By>;
  TradingName?: InputMaybe<Order_By>;
  TurnOver?: InputMaybe<Order_By>;
  UserID?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_min_fields';
  AgAddress?: Maybe<Scalars['String']['output']>;
  AgName?: Maybe<Scalars['String']['output']>;
  AgPhoneNo?: Maybe<Scalars['String']['output']>;
  AgTIN?: Maybe<Scalars['String']['output']>;
  AppCapacity?: Maybe<Scalars['String']['output']>;
  ApprovedBy?: Maybe<Scalars['String']['output']>;
  ApprovedDate?: Maybe<Scalars['String']['output']>;
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  BlockName?: Maybe<Scalars['String']['output']>;
  BusStartDate?: Maybe<Scalars['String']['output']>;
  BusinessCode?: Maybe<Scalars['String']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DIN?: Maybe<Scalars['String']['output']>;
  DecCapacity?: Maybe<Scalars['String']['output']>;
  Declarant?: Maybe<Scalars['String']['output']>;
  DeclareDate?: Maybe<Scalars['String']['output']>;
  DelDate?: Maybe<Scalars['String']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmpAddress?: Maybe<Scalars['String']['output']>;
  EmpEMail?: Maybe<Scalars['String']['output']>;
  EmpFax?: Maybe<Scalars['String']['output']>;
  EmpMobile?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo1?: Maybe<Scalars['String']['output']>;
  EmpPhoneNo2?: Maybe<Scalars['String']['output']>;
  EmpTIN?: Maybe<Scalars['String']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  EntryDate?: Maybe<Scalars['String']['output']>;
  ExemptAuth?: Maybe<Scalars['String']['output']>;
  FName?: Maybe<Scalars['String']['output']>;
  FaxNo?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  ImportNo?: Maybe<Scalars['String']['output']>;
  IncomeTax?: Maybe<Scalars['String']['output']>;
  LName?: Maybe<Scalars['String']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  LastDocNum?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastchanged?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MFName?: Maybe<Scalars['String']['output']>;
  MLName?: Maybe<Scalars['String']['output']>;
  MName?: Maybe<Scalars['String']['output']>;
  Marital?: Maybe<Scalars['String']['output']>;
  NIN?: Maybe<Scalars['String']['output']>;
  NINIncorpCert?: Maybe<Scalars['String']['output']>;
  Officer?: Maybe<Scalars['String']['output']>;
  Others?: Maybe<Scalars['String']['output']>;
  PassportNo?: Maybe<Scalars['String']['output']>;
  PayeeTax?: Maybe<Scalars['String']['output']>;
  PayerType?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  PhoneNo2?: Maybe<Scalars['String']['output']>;
  PhysArea?: Maybe<Scalars['String']['output']>;
  PhysPlotNo?: Maybe<Scalars['String']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PhysStreet?: Maybe<Scalars['String']['output']>;
  PhysTown?: Maybe<Scalars['String']['output']>;
  PostalBox?: Maybe<Scalars['String']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  PostalTown?: Maybe<Scalars['String']['output']>;
  PrevIncFileNo?: Maybe<Scalars['String']['output']>;
  PrevName?: Maybe<Scalars['String']['output']>;
  PrevPayeNo?: Maybe<Scalars['String']['output']>;
  ReMark?: Maybe<Scalars['String']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  RegDate?: Maybe<Scalars['String']['output']>;
  RegStatus?: Maybe<Scalars['String']['output']>;
  RegTypeCode?: Maybe<Scalars['String']['output']>;
  StampDate?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  TINNotApproval?: Maybe<Scalars['String']['output']>;
  TaxPayerID?: Maybe<Scalars['String']['output']>;
  TradingName?: Maybe<Scalars['String']['output']>;
  TurnOver?: Maybe<Scalars['String']['output']>;
  UserID?: Maybe<Scalars['String']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Min_Order_By = {
  AgAddress?: InputMaybe<Order_By>;
  AgName?: InputMaybe<Order_By>;
  AgPhoneNo?: InputMaybe<Order_By>;
  AgTIN?: InputMaybe<Order_By>;
  AppCapacity?: InputMaybe<Order_By>;
  ApprovedBy?: InputMaybe<Order_By>;
  ApprovedDate?: InputMaybe<Order_By>;
  AuditFlag?: InputMaybe<Order_By>;
  BirthDate?: InputMaybe<Order_By>;
  BlockName?: InputMaybe<Order_By>;
  BusStartDate?: InputMaybe<Order_By>;
  BusinessCode?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedDate?: InputMaybe<Order_By>;
  DIN?: InputMaybe<Order_By>;
  DecCapacity?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  DeclareDate?: InputMaybe<Order_By>;
  DelDate?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmpAddress?: InputMaybe<Order_By>;
  EmpEMail?: InputMaybe<Order_By>;
  EmpFax?: InputMaybe<Order_By>;
  EmpMobile?: InputMaybe<Order_By>;
  EmpPhoneNo1?: InputMaybe<Order_By>;
  EmpPhoneNo2?: InputMaybe<Order_By>;
  EmpTIN?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  EntryDate?: InputMaybe<Order_By>;
  ExemptAuth?: InputMaybe<Order_By>;
  FName?: InputMaybe<Order_By>;
  FaxNo?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  ImportNo?: InputMaybe<Order_By>;
  IncomeTax?: InputMaybe<Order_By>;
  LName?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  LastDocNum?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastchanged?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MFName?: InputMaybe<Order_By>;
  MLName?: InputMaybe<Order_By>;
  MName?: InputMaybe<Order_By>;
  Marital?: InputMaybe<Order_By>;
  NIN?: InputMaybe<Order_By>;
  NINIncorpCert?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Others?: InputMaybe<Order_By>;
  PassportNo?: InputMaybe<Order_By>;
  PayeeTax?: InputMaybe<Order_By>;
  PayerType?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  PhoneNo2?: InputMaybe<Order_By>;
  PhysArea?: InputMaybe<Order_By>;
  PhysPlotNo?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PhysStreet?: InputMaybe<Order_By>;
  PhysTown?: InputMaybe<Order_By>;
  PostalBox?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  PostalTown?: InputMaybe<Order_By>;
  PrevIncFileNo?: InputMaybe<Order_By>;
  PrevName?: InputMaybe<Order_By>;
  PrevPayeNo?: InputMaybe<Order_By>;
  ReMark?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  RegDate?: InputMaybe<Order_By>;
  RegStatus?: InputMaybe<Order_By>;
  RegTypeCode?: InputMaybe<Order_By>;
  StampDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  TINNotApproval?: InputMaybe<Order_By>;
  TaxPayerID?: InputMaybe<Order_By>;
  TradingName?: InputMaybe<Order_By>;
  TurnOver?: InputMaybe<Order_By>;
  UserID?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_TaxPayer>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_TaxPayer_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Order_By = {
  AgAddress?: InputMaybe<Order_By>;
  AgName?: InputMaybe<Order_By>;
  AgPhoneNo?: InputMaybe<Order_By>;
  AgTIN?: InputMaybe<Order_By>;
  AppCapacity?: InputMaybe<Order_By>;
  ApprovedBy?: InputMaybe<Order_By>;
  ApprovedDate?: InputMaybe<Order_By>;
  AuditFlag?: InputMaybe<Order_By>;
  BirthDate?: InputMaybe<Order_By>;
  BlockName?: InputMaybe<Order_By>;
  BusStartDate?: InputMaybe<Order_By>;
  BusinessCode?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedDate?: InputMaybe<Order_By>;
  DIN?: InputMaybe<Order_By>;
  DecCapacity?: InputMaybe<Order_By>;
  Declarant?: InputMaybe<Order_By>;
  DeclareDate?: InputMaybe<Order_By>;
  DelDate?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmpAddress?: InputMaybe<Order_By>;
  EmpEMail?: InputMaybe<Order_By>;
  EmpFax?: InputMaybe<Order_By>;
  EmpMobile?: InputMaybe<Order_By>;
  EmpPhoneNo1?: InputMaybe<Order_By>;
  EmpPhoneNo2?: InputMaybe<Order_By>;
  EmpTIN?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  EntryDate?: InputMaybe<Order_By>;
  ExemptAuth?: InputMaybe<Order_By>;
  FName?: InputMaybe<Order_By>;
  FaxNo?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  ImportNo?: InputMaybe<Order_By>;
  IncomeTax?: InputMaybe<Order_By>;
  LName?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  LastDocNum?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastchanged?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MFName?: InputMaybe<Order_By>;
  MLName?: InputMaybe<Order_By>;
  MName?: InputMaybe<Order_By>;
  Marital?: InputMaybe<Order_By>;
  NIN?: InputMaybe<Order_By>;
  NINIncorpCert?: InputMaybe<Order_By>;
  Officer?: InputMaybe<Order_By>;
  Others?: InputMaybe<Order_By>;
  PassportNo?: InputMaybe<Order_By>;
  PayeeTax?: InputMaybe<Order_By>;
  PayerType?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  PhoneNo2?: InputMaybe<Order_By>;
  PhysArea?: InputMaybe<Order_By>;
  PhysPlotNo?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PhysStreet?: InputMaybe<Order_By>;
  PhysTown?: InputMaybe<Order_By>;
  PostalBox?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  PostalTown?: InputMaybe<Order_By>;
  PrevIncFileNo?: InputMaybe<Order_By>;
  PrevName?: InputMaybe<Order_By>;
  PrevPayeNo?: InputMaybe<Order_By>;
  ReMark?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  RegDate?: InputMaybe<Order_By>;
  RegStatus?: InputMaybe<Order_By>;
  RegTypeCode?: InputMaybe<Order_By>;
  StampDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  TINNotApproval?: InputMaybe<Order_By>;
  TaxPayerID?: InputMaybe<Order_By>;
  TradingName?: InputMaybe<Order_By>;
  TurnOver?: InputMaybe<Order_By>;
  UserID?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_TaxPayer" */
export enum Rtcs_Gm_Dev_Gra_TaxPayer_Select_Column {
  /** column name */
  AgAddress = 'AgAddress',
  /** column name */
  AgName = 'AgName',
  /** column name */
  AgPhoneNo = 'AgPhoneNo',
  /** column name */
  AgTin = 'AgTIN',
  /** column name */
  AppCapacity = 'AppCapacity',
  /** column name */
  ApprovedBy = 'ApprovedBy',
  /** column name */
  ApprovedDate = 'ApprovedDate',
  /** column name */
  AuditFlag = 'AuditFlag',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  BlockName = 'BlockName',
  /** column name */
  BusStartDate = 'BusStartDate',
  /** column name */
  BusinessCode = 'BusinessCode',
  /** column name */
  ChkApproved = 'ChkApproved',
  /** column name */
  ChkCitizen = 'ChkCitizen',
  /** column name */
  ChkExempt = 'ChkExempt',
  /** column name */
  ChkIdCard = 'ChkIdCard',
  /** column name */
  ChkNotApproved = 'ChkNotApproved',
  /** column name */
  ChkPassPort = 'ChkPassPort',
  /** column name */
  ChkRegist = 'ChkRegist',
  /** column name */
  ChkcertInCorp = 'ChkcertInCorp',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Din = 'DIN',
  /** column name */
  DecCapacity = 'DecCapacity',
  /** column name */
  Declarant = 'Declarant',
  /** column name */
  DeclareDate = 'DeclareDate',
  /** column name */
  DelDate = 'DelDate',
  /** column name */
  DelFlag = 'DelFlag',
  /** column name */
  Email = 'Email',
  /** column name */
  EmpAddress = 'EmpAddress',
  /** column name */
  EmpEMail = 'EmpEMail',
  /** column name */
  EmpFax = 'EmpFax',
  /** column name */
  EmpMobile = 'EmpMobile',
  /** column name */
  EmpPhoneNo1 = 'EmpPhoneNo1',
  /** column name */
  EmpPhoneNo2 = 'EmpPhoneNo2',
  /** column name */
  EmpTin = 'EmpTIN',
  /** column name */
  EmpType = 'EmpType',
  /** column name */
  EndDate = 'EndDate',
  /** column name */
  Enforce = 'Enforce',
  /** column name */
  EntryDate = 'EntryDate',
  /** column name */
  ExemptAuth = 'ExemptAuth',
  /** column name */
  FName = 'FName',
  /** column name */
  FaxNo = 'FaxNo',
  /** column name */
  File = 'File',
  /** column name */
  ForceReg = 'ForceReg',
  /** column name */
  Gender = 'Gender',
  /** column name */
  ImportNo = 'ImportNo',
  /** column name */
  IncomeTax = 'IncomeTax',
  /** column name */
  LName = 'LName',
  /** column name */
  LargTaxPayer = 'LargTaxPayer',
  /** column name */
  LargeTaxPayerStatus = 'LargeTaxPayerStatus',
  /** column name */
  LastDocNum = 'LastDocNum',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lastchanged = 'Lastchanged',
  /** column name */
  Line = 'Line',
  /** column name */
  MfName = 'MFName',
  /** column name */
  MlName = 'MLName',
  /** column name */
  MName = 'MName',
  /** column name */
  Marital = 'Marital',
  /** column name */
  Nin = 'NIN',
  /** column name */
  NinIncorpCert = 'NINIncorpCert',
  /** column name */
  Officer = 'Officer',
  /** column name */
  Others = 'Others',
  /** column name */
  PassportNo = 'PassportNo',
  /** column name */
  PayeeTax = 'PayeeTax',
  /** column name */
  PayerType = 'PayerType',
  /** column name */
  PhoneNo = 'PhoneNo',
  /** column name */
  PhoneNo2 = 'PhoneNo2',
  /** column name */
  PhysArea = 'PhysArea',
  /** column name */
  PhysPlotNo = 'PhysPlotNo',
  /** column name */
  PhysProv = 'PhysProv',
  /** column name */
  PhysStreet = 'PhysStreet',
  /** column name */
  PhysTown = 'PhysTown',
  /** column name */
  PostalBox = 'PostalBox',
  /** column name */
  PostalProv = 'PostalProv',
  /** column name */
  PostalTown = 'PostalTown',
  /** column name */
  PrevIncFileNo = 'PrevIncFileNo',
  /** column name */
  PrevName = 'PrevName',
  /** column name */
  PrevPayeNo = 'PrevPayeNo',
  /** column name */
  ReMark = 'ReMark',
  /** column name */
  RefusalCode = 'RefusalCode',
  /** column name */
  RegCenterCode = 'RegCenterCode',
  /** column name */
  RegDate = 'RegDate',
  /** column name */
  RegStatus = 'RegStatus',
  /** column name */
  RegTypeCode = 'RegTypeCode',
  /** column name */
  StampDate = 'StampDate',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Tin = 'TIN',
  /** column name */
  TinApproval = 'TINApproval',
  /** column name */
  TinCertCount = 'TINCertCount',
  /** column name */
  TinNotApproval = 'TINNotApproval',
  /** column name */
  TaxPayerId = 'TaxPayerID',
  /** column name */
  TradingName = 'TradingName',
  /** column name */
  TurnOver = 'TurnOver',
  /** column name */
  UserId = 'UserID',
  /** column name */
  VatTurnOver = 'VATTurnOver',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  ChkBirthCert = 'chkBirthCert',
  /** column name */
  ChkEmpExpat = 'chkEmpExpat',
  /** column name */
  ChkEmploy = 'chkEmploy',
  /** column name */
  ChkOtherBranch = 'chkOtherBranch',
  /** column name */
  ChkSpecialTaxPeriod = 'chkSpecialTaxPeriod',
  /** column name */
  ChkTaxExempt = 'chkTaxExempt'
}

/** input type for updating data in table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Set_Input = {
  AgAddress?: InputMaybe<Scalars['String']['input']>;
  AgName?: InputMaybe<Scalars['String']['input']>;
  AgPhoneNo?: InputMaybe<Scalars['String']['input']>;
  AgTIN?: InputMaybe<Scalars['String']['input']>;
  AppCapacity?: InputMaybe<Scalars['String']['input']>;
  ApprovedBy?: InputMaybe<Scalars['String']['input']>;
  ApprovedDate?: InputMaybe<Scalars['String']['input']>;
  AuditFlag?: InputMaybe<Scalars['bigint']['input']>;
  BirthDate?: InputMaybe<Scalars['String']['input']>;
  BlockName?: InputMaybe<Scalars['String']['input']>;
  BusStartDate?: InputMaybe<Scalars['String']['input']>;
  BusinessCode?: InputMaybe<Scalars['String']['input']>;
  ChkApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkCitizen?: InputMaybe<Scalars['bigint']['input']>;
  ChkExempt?: InputMaybe<Scalars['bigint']['input']>;
  ChkIdCard?: InputMaybe<Scalars['bigint']['input']>;
  ChkNotApproved?: InputMaybe<Scalars['bigint']['input']>;
  ChkPassPort?: InputMaybe<Scalars['bigint']['input']>;
  ChkRegist?: InputMaybe<Scalars['bigint']['input']>;
  ChkcertInCorp?: InputMaybe<Scalars['bigint']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedDate?: InputMaybe<Scalars['String']['input']>;
  DIN?: InputMaybe<Scalars['String']['input']>;
  DecCapacity?: InputMaybe<Scalars['String']['input']>;
  Declarant?: InputMaybe<Scalars['String']['input']>;
  DeclareDate?: InputMaybe<Scalars['String']['input']>;
  DelDate?: InputMaybe<Scalars['String']['input']>;
  DelFlag?: InputMaybe<Scalars['bigint']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmpAddress?: InputMaybe<Scalars['String']['input']>;
  EmpEMail?: InputMaybe<Scalars['String']['input']>;
  EmpFax?: InputMaybe<Scalars['String']['input']>;
  EmpMobile?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo1?: InputMaybe<Scalars['String']['input']>;
  EmpPhoneNo2?: InputMaybe<Scalars['String']['input']>;
  EmpTIN?: InputMaybe<Scalars['String']['input']>;
  EmpType?: InputMaybe<Scalars['bigint']['input']>;
  EndDate?: InputMaybe<Scalars['String']['input']>;
  Enforce?: InputMaybe<Scalars['bigint']['input']>;
  EntryDate?: InputMaybe<Scalars['String']['input']>;
  ExemptAuth?: InputMaybe<Scalars['String']['input']>;
  FName?: InputMaybe<Scalars['String']['input']>;
  FaxNo?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  ForceReg?: InputMaybe<Scalars['bigint']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  ImportNo?: InputMaybe<Scalars['String']['input']>;
  IncomeTax?: InputMaybe<Scalars['String']['input']>;
  LName?: InputMaybe<Scalars['String']['input']>;
  LargTaxPayer?: InputMaybe<Scalars['bigint']['input']>;
  LargeTaxPayerStatus?: InputMaybe<Scalars['bigint']['input']>;
  LastDocNum?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastchanged?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MFName?: InputMaybe<Scalars['String']['input']>;
  MLName?: InputMaybe<Scalars['String']['input']>;
  MName?: InputMaybe<Scalars['String']['input']>;
  Marital?: InputMaybe<Scalars['String']['input']>;
  NIN?: InputMaybe<Scalars['String']['input']>;
  NINIncorpCert?: InputMaybe<Scalars['String']['input']>;
  Officer?: InputMaybe<Scalars['String']['input']>;
  Others?: InputMaybe<Scalars['String']['input']>;
  PassportNo?: InputMaybe<Scalars['String']['input']>;
  PayeeTax?: InputMaybe<Scalars['String']['input']>;
  PayerType?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  PhoneNo2?: InputMaybe<Scalars['String']['input']>;
  PhysArea?: InputMaybe<Scalars['String']['input']>;
  PhysPlotNo?: InputMaybe<Scalars['String']['input']>;
  PhysProv?: InputMaybe<Scalars['bigint']['input']>;
  PhysStreet?: InputMaybe<Scalars['String']['input']>;
  PhysTown?: InputMaybe<Scalars['String']['input']>;
  PostalBox?: InputMaybe<Scalars['String']['input']>;
  PostalProv?: InputMaybe<Scalars['bigint']['input']>;
  PostalTown?: InputMaybe<Scalars['String']['input']>;
  PrevIncFileNo?: InputMaybe<Scalars['String']['input']>;
  PrevName?: InputMaybe<Scalars['String']['input']>;
  PrevPayeNo?: InputMaybe<Scalars['String']['input']>;
  ReMark?: InputMaybe<Scalars['String']['input']>;
  RefusalCode?: InputMaybe<Scalars['float8']['input']>;
  RegCenterCode?: InputMaybe<Scalars['bigint']['input']>;
  RegDate?: InputMaybe<Scalars['String']['input']>;
  RegStatus?: InputMaybe<Scalars['String']['input']>;
  RegTypeCode?: InputMaybe<Scalars['String']['input']>;
  StampDate?: InputMaybe<Scalars['String']['input']>;
  StartDate?: InputMaybe<Scalars['String']['input']>;
  TIN?: InputMaybe<Scalars['bigint']['input']>;
  TINApproval?: InputMaybe<Scalars['bigint']['input']>;
  TINCertCount?: InputMaybe<Scalars['bigint']['input']>;
  TINNotApproval?: InputMaybe<Scalars['String']['input']>;
  TaxPayerID?: InputMaybe<Scalars['String']['input']>;
  TradingName?: InputMaybe<Scalars['String']['input']>;
  TurnOver?: InputMaybe<Scalars['String']['input']>;
  UserID?: InputMaybe<Scalars['String']['input']>;
  VATTurnOver?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  chkBirthCert?: InputMaybe<Scalars['bigint']['input']>;
  chkEmpExpat?: InputMaybe<Scalars['bigint']['input']>;
  chkEmploy?: InputMaybe<Scalars['bigint']['input']>;
  chkOtherBranch?: InputMaybe<Scalars['bigint']['input']>;
  chkSpecialTaxPeriod?: InputMaybe<Scalars['bigint']['input']>;
  chkTaxExempt?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_stddev_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_stddev_pop_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Pop_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_stddev_samp_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Stddev_Samp_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_sum_fields';
  AuditFlag?: Maybe<Scalars['bigint']['output']>;
  ChkApproved?: Maybe<Scalars['bigint']['output']>;
  ChkCitizen?: Maybe<Scalars['bigint']['output']>;
  ChkExempt?: Maybe<Scalars['bigint']['output']>;
  ChkIdCard?: Maybe<Scalars['bigint']['output']>;
  ChkNotApproved?: Maybe<Scalars['bigint']['output']>;
  ChkPassPort?: Maybe<Scalars['bigint']['output']>;
  ChkRegist?: Maybe<Scalars['bigint']['output']>;
  ChkcertInCorp?: Maybe<Scalars['bigint']['output']>;
  DelFlag?: Maybe<Scalars['bigint']['output']>;
  EmpType?: Maybe<Scalars['bigint']['output']>;
  Enforce?: Maybe<Scalars['bigint']['output']>;
  ForceReg?: Maybe<Scalars['bigint']['output']>;
  LargTaxPayer?: Maybe<Scalars['bigint']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['bigint']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  PhysProv?: Maybe<Scalars['bigint']['output']>;
  PostalProv?: Maybe<Scalars['bigint']['output']>;
  RefusalCode?: Maybe<Scalars['float8']['output']>;
  RegCenterCode?: Maybe<Scalars['bigint']['output']>;
  TIN?: Maybe<Scalars['bigint']['output']>;
  TINApproval?: Maybe<Scalars['bigint']['output']>;
  TINCertCount?: Maybe<Scalars['bigint']['output']>;
  VATTurnOver?: Maybe<Scalars['bigint']['output']>;
  chkBirthCert?: Maybe<Scalars['bigint']['output']>;
  chkEmpExpat?: Maybe<Scalars['bigint']['output']>;
  chkEmploy?: Maybe<Scalars['bigint']['output']>;
  chkOtherBranch?: Maybe<Scalars['bigint']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['bigint']['output']>;
  chkTaxExempt?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Sum_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_var_pop_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Var_Pop_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_var_samp_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Var_Samp_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_TaxPayer_variance_fields';
  AuditFlag?: Maybe<Scalars['Float']['output']>;
  ChkApproved?: Maybe<Scalars['Float']['output']>;
  ChkCitizen?: Maybe<Scalars['Float']['output']>;
  ChkExempt?: Maybe<Scalars['Float']['output']>;
  ChkIdCard?: Maybe<Scalars['Float']['output']>;
  ChkNotApproved?: Maybe<Scalars['Float']['output']>;
  ChkPassPort?: Maybe<Scalars['Float']['output']>;
  ChkRegist?: Maybe<Scalars['Float']['output']>;
  ChkcertInCorp?: Maybe<Scalars['Float']['output']>;
  DelFlag?: Maybe<Scalars['Float']['output']>;
  EmpType?: Maybe<Scalars['Float']['output']>;
  Enforce?: Maybe<Scalars['Float']['output']>;
  ForceReg?: Maybe<Scalars['Float']['output']>;
  LargTaxPayer?: Maybe<Scalars['Float']['output']>;
  LargeTaxPayerStatus?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  PhysProv?: Maybe<Scalars['Float']['output']>;
  PostalProv?: Maybe<Scalars['Float']['output']>;
  RefusalCode?: Maybe<Scalars['Float']['output']>;
  RegCenterCode?: Maybe<Scalars['Float']['output']>;
  TIN?: Maybe<Scalars['Float']['output']>;
  TINApproval?: Maybe<Scalars['Float']['output']>;
  TINCertCount?: Maybe<Scalars['Float']['output']>;
  VATTurnOver?: Maybe<Scalars['Float']['output']>;
  chkBirthCert?: Maybe<Scalars['Float']['output']>;
  chkEmpExpat?: Maybe<Scalars['Float']['output']>;
  chkEmploy?: Maybe<Scalars['Float']['output']>;
  chkOtherBranch?: Maybe<Scalars['Float']['output']>;
  chkSpecialTaxPeriod?: Maybe<Scalars['Float']['output']>;
  chkTaxExempt?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_TaxPayer" */
export type Rtcs_Gm_Dev_Gra_TaxPayer_Variance_Order_By = {
  AuditFlag?: InputMaybe<Order_By>;
  ChkApproved?: InputMaybe<Order_By>;
  ChkCitizen?: InputMaybe<Order_By>;
  ChkExempt?: InputMaybe<Order_By>;
  ChkIdCard?: InputMaybe<Order_By>;
  ChkNotApproved?: InputMaybe<Order_By>;
  ChkPassPort?: InputMaybe<Order_By>;
  ChkRegist?: InputMaybe<Order_By>;
  ChkcertInCorp?: InputMaybe<Order_By>;
  DelFlag?: InputMaybe<Order_By>;
  EmpType?: InputMaybe<Order_By>;
  Enforce?: InputMaybe<Order_By>;
  ForceReg?: InputMaybe<Order_By>;
  LargTaxPayer?: InputMaybe<Order_By>;
  LargeTaxPayerStatus?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  PhysProv?: InputMaybe<Order_By>;
  PostalProv?: InputMaybe<Order_By>;
  RefusalCode?: InputMaybe<Order_By>;
  RegCenterCode?: InputMaybe<Order_By>;
  TIN?: InputMaybe<Order_By>;
  TINApproval?: InputMaybe<Order_By>;
  TINCertCount?: InputMaybe<Order_By>;
  VATTurnOver?: InputMaybe<Order_By>;
  chkBirthCert?: InputMaybe<Order_By>;
  chkEmpExpat?: InputMaybe<Order_By>;
  chkEmploy?: InputMaybe<Order_By>;
  chkOtherBranch?: InputMaybe<Order_By>;
  chkSpecialTaxPeriod?: InputMaybe<Order_By>;
  chkTaxExempt?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_VatReturn>;
};

/** aggregate fields of "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_VatReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_VatReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_avg_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Avg_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_VATReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp = {
  Adjustment?: InputMaybe<Float8_Comparison_Exp>;
  Creditbalance?: InputMaybe<Bigint_Comparison_Exp>;
  Domesticpurchase?: InputMaybe<Float8_Comparison_Exp>;
  Domesticrate?: InputMaybe<Bigint_Comparison_Exp>;
  Domesticvatamount?: InputMaybe<Float8_Comparison_Exp>;
  Exemptedimport?: InputMaybe<Float8_Comparison_Exp>;
  Exemptedsales?: InputMaybe<Float8_Comparison_Exp>;
  Exports?: InputMaybe<Float8_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Import?: InputMaybe<Float8_Comparison_Exp>;
  Importrate?: InputMaybe<Bigint_Comparison_Exp>;
  Importvatamount?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Nilreturn?: InputMaybe<Bigint_Comparison_Exp>;
  Otherzerorated?: InputMaybe<Bigint_Comparison_Exp>;
  Purchasetax?: InputMaybe<Float8_Comparison_Exp>;
  Refundclaim?: InputMaybe<Float8_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Taxablerate?: InputMaybe<Bigint_Comparison_Exp>;
  Taxablesales?: InputMaybe<Float8_Comparison_Exp>;
  Taxablevatamount?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Totalpurchase?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Zeroratedimport?: InputMaybe<Bigint_Comparison_Exp>;
  Zeroratedother?: InputMaybe<Bigint_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Inc_Input = {
  Adjustment?: InputMaybe<Scalars['float8']['input']>;
  Creditbalance?: InputMaybe<Scalars['bigint']['input']>;
  Domesticpurchase?: InputMaybe<Scalars['float8']['input']>;
  Domesticrate?: InputMaybe<Scalars['bigint']['input']>;
  Domesticvatamount?: InputMaybe<Scalars['float8']['input']>;
  Exemptedimport?: InputMaybe<Scalars['float8']['input']>;
  Exemptedsales?: InputMaybe<Scalars['float8']['input']>;
  Exports?: InputMaybe<Scalars['float8']['input']>;
  Import?: InputMaybe<Scalars['float8']['input']>;
  Importrate?: InputMaybe<Scalars['bigint']['input']>;
  Importvatamount?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nilreturn?: InputMaybe<Scalars['bigint']['input']>;
  Otherzerorated?: InputMaybe<Scalars['bigint']['input']>;
  Purchasetax?: InputMaybe<Scalars['float8']['input']>;
  Refundclaim?: InputMaybe<Scalars['float8']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxablerate?: InputMaybe<Scalars['bigint']['input']>;
  Taxablesales?: InputMaybe<Scalars['float8']['input']>;
  Taxablevatamount?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalpurchase?: InputMaybe<Scalars['float8']['input']>;
  Zeroratedimport?: InputMaybe<Scalars['bigint']['input']>;
  Zeroratedother?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Insert_Input = {
  Adjustment?: InputMaybe<Scalars['float8']['input']>;
  Creditbalance?: InputMaybe<Scalars['bigint']['input']>;
  Domesticpurchase?: InputMaybe<Scalars['float8']['input']>;
  Domesticrate?: InputMaybe<Scalars['bigint']['input']>;
  Domesticvatamount?: InputMaybe<Scalars['float8']['input']>;
  Exemptedimport?: InputMaybe<Scalars['float8']['input']>;
  Exemptedsales?: InputMaybe<Scalars['float8']['input']>;
  Exports?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Import?: InputMaybe<Scalars['float8']['input']>;
  Importrate?: InputMaybe<Scalars['bigint']['input']>;
  Importvatamount?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nilreturn?: InputMaybe<Scalars['bigint']['input']>;
  Otherzerorated?: InputMaybe<Scalars['bigint']['input']>;
  Purchasetax?: InputMaybe<Scalars['float8']['input']>;
  Refundclaim?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxablerate?: InputMaybe<Scalars['bigint']['input']>;
  Taxablesales?: InputMaybe<Scalars['float8']['input']>;
  Taxablevatamount?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalpurchase?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Zeroratedimport?: InputMaybe<Scalars['bigint']['input']>;
  Zeroratedother?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_max_fields';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Max_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_min_fields';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Min_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_VatReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_VatReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_VATReturn" */
export enum Rtcs_Gm_Dev_Gra_VatReturn_Select_Column {
  /** column name */
  Adjustment = 'Adjustment',
  /** column name */
  Creditbalance = 'Creditbalance',
  /** column name */
  Domesticpurchase = 'Domesticpurchase',
  /** column name */
  Domesticrate = 'Domesticrate',
  /** column name */
  Domesticvatamount = 'Domesticvatamount',
  /** column name */
  Exemptedimport = 'Exemptedimport',
  /** column name */
  Exemptedsales = 'Exemptedsales',
  /** column name */
  Exports = 'Exports',
  /** column name */
  File = 'File',
  /** column name */
  Import = 'Import',
  /** column name */
  Importrate = 'Importrate',
  /** column name */
  Importvatamount = 'Importvatamount',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Nilreturn = 'Nilreturn',
  /** column name */
  Otherzerorated = 'Otherzerorated',
  /** column name */
  Purchasetax = 'Purchasetax',
  /** column name */
  Refundclaim = 'Refundclaim',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxablerate = 'Taxablerate',
  /** column name */
  Taxablesales = 'Taxablesales',
  /** column name */
  Taxablevatamount = 'Taxablevatamount',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Totalpurchase = 'Totalpurchase',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Zeroratedimport = 'Zeroratedimport',
  /** column name */
  Zeroratedother = 'Zeroratedother'
}

/** input type for updating data in table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Set_Input = {
  Adjustment?: InputMaybe<Scalars['float8']['input']>;
  Creditbalance?: InputMaybe<Scalars['bigint']['input']>;
  Domesticpurchase?: InputMaybe<Scalars['float8']['input']>;
  Domesticrate?: InputMaybe<Scalars['bigint']['input']>;
  Domesticvatamount?: InputMaybe<Scalars['float8']['input']>;
  Exemptedimport?: InputMaybe<Scalars['float8']['input']>;
  Exemptedsales?: InputMaybe<Scalars['float8']['input']>;
  Exports?: InputMaybe<Scalars['float8']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Import?: InputMaybe<Scalars['float8']['input']>;
  Importrate?: InputMaybe<Scalars['bigint']['input']>;
  Importvatamount?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nilreturn?: InputMaybe<Scalars['bigint']['input']>;
  Otherzerorated?: InputMaybe<Scalars['bigint']['input']>;
  Purchasetax?: InputMaybe<Scalars['float8']['input']>;
  Refundclaim?: InputMaybe<Scalars['float8']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxablerate?: InputMaybe<Scalars['bigint']['input']>;
  Taxablesales?: InputMaybe<Scalars['float8']['input']>;
  Taxablevatamount?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Totalpurchase?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Zeroratedimport?: InputMaybe<Scalars['bigint']['input']>;
  Zeroratedother?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_stddev_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_stddev_pop_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Pop_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_stddev_samp_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Stddev_Samp_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_sum_fields';
  Adjustment?: Maybe<Scalars['float8']['output']>;
  Creditbalance?: Maybe<Scalars['bigint']['output']>;
  Domesticpurchase?: Maybe<Scalars['float8']['output']>;
  Domesticrate?: Maybe<Scalars['bigint']['output']>;
  Domesticvatamount?: Maybe<Scalars['float8']['output']>;
  Exemptedimport?: Maybe<Scalars['float8']['output']>;
  Exemptedsales?: Maybe<Scalars['float8']['output']>;
  Exports?: Maybe<Scalars['float8']['output']>;
  Import?: Maybe<Scalars['float8']['output']>;
  Importrate?: Maybe<Scalars['bigint']['output']>;
  Importvatamount?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nilreturn?: Maybe<Scalars['bigint']['output']>;
  Otherzerorated?: Maybe<Scalars['bigint']['output']>;
  Purchasetax?: Maybe<Scalars['float8']['output']>;
  Refundclaim?: Maybe<Scalars['float8']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxablerate?: Maybe<Scalars['bigint']['output']>;
  Taxablesales?: Maybe<Scalars['float8']['output']>;
  Taxablevatamount?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Totalpurchase?: Maybe<Scalars['float8']['output']>;
  Zeroratedimport?: Maybe<Scalars['bigint']['output']>;
  Zeroratedother?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Sum_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_var_pop_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Var_Pop_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_var_samp_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Var_Samp_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_VatReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_VATReturn_variance_fields';
  Adjustment?: Maybe<Scalars['Float']['output']>;
  Creditbalance?: Maybe<Scalars['Float']['output']>;
  Domesticpurchase?: Maybe<Scalars['Float']['output']>;
  Domesticrate?: Maybe<Scalars['Float']['output']>;
  Domesticvatamount?: Maybe<Scalars['Float']['output']>;
  Exemptedimport?: Maybe<Scalars['Float']['output']>;
  Exemptedsales?: Maybe<Scalars['Float']['output']>;
  Exports?: Maybe<Scalars['Float']['output']>;
  Import?: Maybe<Scalars['Float']['output']>;
  Importrate?: Maybe<Scalars['Float']['output']>;
  Importvatamount?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Nilreturn?: Maybe<Scalars['Float']['output']>;
  Otherzerorated?: Maybe<Scalars['Float']['output']>;
  Purchasetax?: Maybe<Scalars['Float']['output']>;
  Refundclaim?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxablerate?: Maybe<Scalars['Float']['output']>;
  Taxablesales?: Maybe<Scalars['Float']['output']>;
  Taxablevatamount?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Totalpurchase?: Maybe<Scalars['Float']['output']>;
  Zeroratedimport?: Maybe<Scalars['Float']['output']>;
  Zeroratedother?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_VATReturn" */
export type Rtcs_Gm_Dev_Gra_VatReturn_Variance_Order_By = {
  Adjustment?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Domesticpurchase?: InputMaybe<Order_By>;
  Domesticrate?: InputMaybe<Order_By>;
  Domesticvatamount?: InputMaybe<Order_By>;
  Exemptedimport?: InputMaybe<Order_By>;
  Exemptedsales?: InputMaybe<Order_By>;
  Exports?: InputMaybe<Order_By>;
  Import?: InputMaybe<Order_By>;
  Importrate?: InputMaybe<Order_By>;
  Importvatamount?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nilreturn?: InputMaybe<Order_By>;
  Otherzerorated?: InputMaybe<Order_By>;
  Purchasetax?: InputMaybe<Order_By>;
  Refundclaim?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxablerate?: InputMaybe<Order_By>;
  Taxablesales?: InputMaybe<Order_By>;
  Taxablevatamount?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Totalpurchase?: InputMaybe<Order_By>;
  Zeroratedimport?: InputMaybe<Order_By>;
  Zeroratedother?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Chequeno?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail';
  Businessaddress?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingid?: Maybe<Scalars['String']['output']>;
  Withholdingname?: Maybe<Scalars['String']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail>;
};

/** aggregate fields of "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_avg_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Avg_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_WithholdingReturnDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp = {
  Businessaddress?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Grosstransaction?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<Bigint_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Bigint_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Bigint_Comparison_Exp>;
  Tin?: InputMaybe<Bigint_Comparison_Exp>;
  Transactiontype?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  Withheldamount?: InputMaybe<Float8_Comparison_Exp>;
  Withholdingid?: InputMaybe<String_Comparison_Exp>;
  Withholdingname?: InputMaybe<String_Comparison_Exp>;
  Withholdingrate?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Inc_Input = {
  Grosstransaction?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Transactiontype?: InputMaybe<Scalars['bigint']['input']>;
  Withheldamount?: InputMaybe<Scalars['float8']['input']>;
  Withholdingrate?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Insert_Input = {
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grosstransaction?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Transactiontype?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withheldamount?: InputMaybe<Scalars['float8']['input']>;
  Withholdingid?: InputMaybe<Scalars['String']['input']>;
  Withholdingname?: InputMaybe<Scalars['String']['input']>;
  Withholdingrate?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_max_fields';
  Businessaddress?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingid?: Maybe<Scalars['String']['output']>;
  Withholdingname?: Maybe<Scalars['String']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Max_Order_By = {
  Businessaddress?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grosstransaction?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingid?: InputMaybe<Order_By>;
  Withholdingname?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_min_fields';
  Businessaddress?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingid?: Maybe<Scalars['String']['output']>;
  Withholdingname?: Maybe<Scalars['String']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Min_Order_By = {
  Businessaddress?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grosstransaction?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingid?: InputMaybe<Order_By>;
  Withholdingname?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Order_By = {
  Businessaddress?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Grosstransaction?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingid?: InputMaybe<Order_By>;
  Withholdingname?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export enum Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Select_Column {
  /** column name */
  Businessaddress = 'Businessaddress',
  /** column name */
  File = 'File',
  /** column name */
  Grosstransaction = 'Grosstransaction',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Tin = 'Tin',
  /** column name */
  Transactiontype = 'Transactiontype',
  /** column name */
  WhoUpdated = 'WhoUpdated',
  /** column name */
  Withheldamount = 'Withheldamount',
  /** column name */
  Withholdingid = 'Withholdingid',
  /** column name */
  Withholdingname = 'Withholdingname',
  /** column name */
  Withholdingrate = 'Withholdingrate'
}

/** input type for updating data in table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Set_Input = {
  Businessaddress?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Grosstransaction?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['bigint']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['bigint']['input']>;
  Tin?: InputMaybe<Scalars['bigint']['input']>;
  Transactiontype?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
  Withheldamount?: InputMaybe<Scalars['float8']['input']>;
  Withholdingid?: InputMaybe<Scalars['String']['input']>;
  Withholdingname?: InputMaybe<Scalars['String']['input']>;
  Withholdingrate?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_stddev_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_stddev_pop_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Pop_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_stddev_samp_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Stddev_Samp_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_sum_fields';
  Grosstransaction?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['bigint']['output']>;
  Taxpayertin?: Maybe<Scalars['bigint']['output']>;
  Tin?: Maybe<Scalars['bigint']['output']>;
  Transactiontype?: Maybe<Scalars['bigint']['output']>;
  Withheldamount?: Maybe<Scalars['float8']['output']>;
  Withholdingrate?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Sum_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_var_pop_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Pop_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_var_samp_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Var_Samp_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturnDetail_variance_fields';
  Grosstransaction?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnid?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Tin?: Maybe<Scalars['Float']['output']>;
  Transactiontype?: Maybe<Scalars['Float']['output']>;
  Withheldamount?: Maybe<Scalars['Float']['output']>;
  Withholdingrate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_WithholdingReturnDetail" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Variance_Order_By = {
  Grosstransaction?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Tin?: InputMaybe<Order_By>;
  Transactiontype?: InputMaybe<Order_By>;
  Withheldamount?: InputMaybe<Order_By>;
  Withholdingrate?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Gra_WithholdingReturn>;
};

/** aggregate fields of "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_avg_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Avg_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.gra_WithholdingReturn". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp = {
  Amountpaid?: InputMaybe<Float8_Comparison_Exp>;
  Chequeno?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Returnid?: InputMaybe<String_Comparison_Exp>;
  Returnsbasetin?: InputMaybe<Float8_Comparison_Exp>;
  Taxpayerfname?: InputMaybe<String_Comparison_Exp>;
  Taxpayerlname?: InputMaybe<String_Comparison_Exp>;
  Taxpayermname?: InputMaybe<String_Comparison_Exp>;
  Taxpayertin?: InputMaybe<Float8_Comparison_Exp>;
  Transactioncount?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Inc_Input = {
  Amountpaid?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Transactioncount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Insert_Input = {
  Amountpaid?: InputMaybe<Scalars['float8']['input']>;
  Chequeno?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Transactioncount?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Max_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_max_fields';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Chequeno?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Max_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Chequeno?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Min_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_min_fields';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Chequeno?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnid?: Maybe<Scalars['String']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayerfname?: Maybe<Scalars['String']['output']>;
  Taxpayerlname?: Maybe<Scalars['String']['output']>;
  Taxpayermname?: Maybe<Scalars['String']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Min_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Chequeno?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Gra_WithholdingReturn>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Gra_WithholdingReturn_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Chequeno?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnid?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayerfname?: InputMaybe<Order_By>;
  Taxpayerlname?: InputMaybe<Order_By>;
  Taxpayermname?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export enum Rtcs_Gm_Dev_Gra_WithholdingReturn_Select_Column {
  /** column name */
  Amountpaid = 'Amountpaid',
  /** column name */
  Chequeno = 'Chequeno',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Returnid = 'Returnid',
  /** column name */
  Returnsbasetin = 'Returnsbasetin',
  /** column name */
  Taxpayerfname = 'Taxpayerfname',
  /** column name */
  Taxpayerlname = 'Taxpayerlname',
  /** column name */
  Taxpayermname = 'Taxpayermname',
  /** column name */
  Taxpayertin = 'Taxpayertin',
  /** column name */
  Transactioncount = 'Transactioncount',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Set_Input = {
  Amountpaid?: InputMaybe<Scalars['float8']['input']>;
  Chequeno?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Returnid?: InputMaybe<Scalars['String']['input']>;
  Returnsbasetin?: InputMaybe<Scalars['float8']['input']>;
  Taxpayerfname?: InputMaybe<Scalars['String']['input']>;
  Taxpayerlname?: InputMaybe<Scalars['String']['input']>;
  Taxpayermname?: InputMaybe<Scalars['String']['input']>;
  Taxpayertin?: InputMaybe<Scalars['float8']['input']>;
  Transactioncount?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_stddev_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_stddev_pop_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Pop_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_stddev_samp_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Stddev_Samp_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_sum_fields';
  Amountpaid?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Returnsbasetin?: Maybe<Scalars['float8']['output']>;
  Taxpayertin?: Maybe<Scalars['float8']['output']>;
  Transactioncount?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Sum_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_var_pop_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Pop_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_var_samp_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Var_Samp_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_gra_WithholdingReturn_variance_fields';
  Amountpaid?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Returnsbasetin?: Maybe<Scalars['Float']['output']>;
  Taxpayertin?: Maybe<Scalars['Float']['output']>;
  Transactioncount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.gra_WithholdingReturn" */
export type Rtcs_Gm_Dev_Gra_WithholdingReturn_Variance_Order_By = {
  Amountpaid?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Returnsbasetin?: InputMaybe<Order_By>;
  Taxpayertin?: InputMaybe<Order_By>;
  Transactioncount?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations';
  Address?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  Businesstype?: Maybe<Scalars['String']['output']>;
  Dateofregistration?: Maybe<Scalars['timestamptz']['output']>;
  Extractcriteria?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Srntin?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations>;
};

/** aggregate fields of "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.groc_MOJMonthlyRegistrations". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Businessname?: InputMaybe<String_Comparison_Exp>;
  Businesstype?: InputMaybe<String_Comparison_Exp>;
  Dateofregistration?: InputMaybe<Timestamptz_Comparison_Exp>;
  Extractcriteria?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Mobile?: InputMaybe<String_Comparison_Exp>;
  Srntin?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  Businesstype?: InputMaybe<Scalars['String']['input']>;
  Dateofregistration?: InputMaybe<Scalars['timestamptz']['input']>;
  Extractcriteria?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Srntin?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Max_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  Businesstype?: Maybe<Scalars['String']['output']>;
  Dateofregistration?: Maybe<Scalars['timestamptz']['output']>;
  Extractcriteria?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Srntin?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  Businesstype?: InputMaybe<Order_By>;
  Dateofregistration?: InputMaybe<Order_By>;
  Extractcriteria?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Srntin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Min_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  Businesstype?: Maybe<Scalars['String']['output']>;
  Dateofregistration?: Maybe<Scalars['timestamptz']['output']>;
  Extractcriteria?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Srntin?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  Businesstype?: InputMaybe<Order_By>;
  Dateofregistration?: InputMaybe<Order_By>;
  Extractcriteria?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Srntin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Order_By = {
  Address?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  Businesstype?: InputMaybe<Order_By>;
  Dateofregistration?: InputMaybe<Order_By>;
  Extractcriteria?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Srntin?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export enum Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Businessname = 'Businessname',
  /** column name */
  Businesstype = 'Businesstype',
  /** column name */
  Dateofregistration = 'Dateofregistration',
  /** column name */
  Extractcriteria = 'Extractcriteria',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Mobile = 'Mobile',
  /** column name */
  Srntin = 'Srntin',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  Businesstype?: InputMaybe<Scalars['String']['input']>;
  Dateofregistration?: InputMaybe<Scalars['timestamptz']['input']>;
  Extractcriteria?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Srntin?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_groc_MOJMonthlyRegistrations_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
export type Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Ownername?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Telephoneno?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_PropertyRatesPayers". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Ownername?: InputMaybe<String_Comparison_Exp>;
  Propertyno?: InputMaybe<String_Comparison_Exp>;
  Telephoneno?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Ownername?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Telephoneno?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Ownername?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Telephoneno?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Ownername?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Telephoneno?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Ownername?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Telephoneno?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Ownername?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Telephoneno?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Ownername?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Telephoneno?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export enum Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Ownername = 'Ownername',
  /** column name */
  Propertyno = 'Propertyno',
  /** column name */
  Telephoneno = 'Telephoneno',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Ownername?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Telephoneno?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesPayers_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_PropertyRatesPayers" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister';
  Address?: Maybe<Scalars['String']['output']>;
  Areaname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Opendate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Subareaname?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Telno?: Maybe<Scalars['String']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_PropertyRatesRegister". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Areaname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Opendate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Owner?: InputMaybe<String_Comparison_Exp>;
  Propertyno?: InputMaybe<String_Comparison_Exp>;
  Propertyvalue?: InputMaybe<Float8_Comparison_Exp>;
  Rate1?: InputMaybe<Float8_Comparison_Exp>;
  Rate2?: InputMaybe<Float8_Comparison_Exp>;
  Ratename?: InputMaybe<String_Comparison_Exp>;
  Rentaltax?: InputMaybe<Bigint_Comparison_Exp>;
  Subareaname?: InputMaybe<String_Comparison_Exp>;
  Tax1?: InputMaybe<Float8_Comparison_Exp>;
  Tax2?: InputMaybe<Float8_Comparison_Exp>;
  Telno?: InputMaybe<String_Comparison_Exp>;
  Totaltax?: InputMaybe<Float8_Comparison_Exp>;
  Value1?: InputMaybe<Bigint_Comparison_Exp>;
  Value2?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Propertyvalue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Rentaltax?: InputMaybe<Scalars['bigint']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  Totaltax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Areaname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Opendate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Propertyvalue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  Rentaltax?: InputMaybe<Scalars['bigint']['input']>;
  Subareaname?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  Telno?: InputMaybe<Scalars['String']['input']>;
  Totaltax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Areaname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Opendate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Subareaname?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Telno?: Maybe<Scalars['String']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Areaname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Opendate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Subareaname?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Telno?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Areaname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Opendate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Propertyno?: Maybe<Scalars['String']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Subareaname?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Telno?: Maybe<Scalars['String']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Areaname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Opendate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Subareaname?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Telno?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Order_By = {
  Address?: InputMaybe<Order_By>;
  Areaname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Opendate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Propertyno?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Subareaname?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Telno?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export enum Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Areaname = 'Areaname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Opendate = 'Opendate',
  /** column name */
  Owner = 'Owner',
  /** column name */
  Propertyno = 'Propertyno',
  /** column name */
  Propertyvalue = 'Propertyvalue',
  /** column name */
  Rate1 = 'Rate1',
  /** column name */
  Rate2 = 'Rate2',
  /** column name */
  Ratename = 'Ratename',
  /** column name */
  Rentaltax = 'Rentaltax',
  /** column name */
  Subareaname = 'Subareaname',
  /** column name */
  Tax1 = 'Tax1',
  /** column name */
  Tax2 = 'Tax2',
  /** column name */
  Telno = 'Telno',
  /** column name */
  Totaltax = 'Totaltax',
  /** column name */
  Value1 = 'Value1',
  /** column name */
  Value2 = 'Value2',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Areaname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Opendate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Propertyno?: InputMaybe<Scalars['String']['input']>;
  Propertyvalue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  Rentaltax?: InputMaybe<Scalars['bigint']['input']>;
  Subareaname?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  Telno?: InputMaybe<Scalars['String']['input']>;
  Totaltax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  Propertyvalue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Rentaltax?: Maybe<Scalars['bigint']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  Totaltax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_PropertyRatesRegister_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  Propertyvalue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  Rentaltax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  Totaltax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_PropertyRatesRegister" */
export type Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  Propertyvalue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Rentaltax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  Totaltax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract';
  Address?: Maybe<Scalars['String']['output']>;
  AreaName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OpenDate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  SubAreaName?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TelNo?: Maybe<Scalars['String']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_RatesDatabaseExtract". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  AreaName?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  OpenDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Owner?: InputMaybe<String_Comparison_Exp>;
  PropertyNo?: InputMaybe<String_Comparison_Exp>;
  PropertyValue?: InputMaybe<Float8_Comparison_Exp>;
  Rate1?: InputMaybe<Float8_Comparison_Exp>;
  Rate2?: InputMaybe<Float8_Comparison_Exp>;
  Ratename?: InputMaybe<String_Comparison_Exp>;
  RentalTax?: InputMaybe<Bigint_Comparison_Exp>;
  SubAreaName?: InputMaybe<String_Comparison_Exp>;
  Tax1?: InputMaybe<Float8_Comparison_Exp>;
  Tax2?: InputMaybe<Float8_Comparison_Exp>;
  TelNo?: InputMaybe<String_Comparison_Exp>;
  TotalTax?: InputMaybe<Float8_Comparison_Exp>;
  Value1?: InputMaybe<Bigint_Comparison_Exp>;
  Value2?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
  PropertyValue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  RentalTax?: InputMaybe<Scalars['bigint']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  TotalTax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AreaName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OpenDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  PropertyValue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  RentalTax?: InputMaybe<Scalars['bigint']['input']>;
  SubAreaName?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  TelNo?: InputMaybe<Scalars['String']['input']>;
  TotalTax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AreaName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OpenDate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  SubAreaName?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TelNo?: Maybe<Scalars['String']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  AreaName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OpenDate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  SubAreaName?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TelNo?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  AreaName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OpenDate?: Maybe<Scalars['timestamptz']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  Ratename?: Maybe<Scalars['String']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  SubAreaName?: Maybe<Scalars['String']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TelNo?: Maybe<Scalars['String']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  AreaName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OpenDate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  SubAreaName?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TelNo?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Order_By = {
  Address?: InputMaybe<Order_By>;
  AreaName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OpenDate?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  Ratename?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  SubAreaName?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TelNo?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export enum Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  AreaName = 'AreaName',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  OpenDate = 'OpenDate',
  /** column name */
  Owner = 'Owner',
  /** column name */
  PropertyNo = 'PropertyNo',
  /** column name */
  PropertyValue = 'PropertyValue',
  /** column name */
  Rate1 = 'Rate1',
  /** column name */
  Rate2 = 'Rate2',
  /** column name */
  Ratename = 'Ratename',
  /** column name */
  RentalTax = 'RentalTax',
  /** column name */
  SubAreaName = 'SubAreaName',
  /** column name */
  Tax1 = 'Tax1',
  /** column name */
  Tax2 = 'Tax2',
  /** column name */
  TelNo = 'TelNo',
  /** column name */
  TotalTax = 'TotalTax',
  /** column name */
  Value1 = 'Value1',
  /** column name */
  Value2 = 'Value2',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  AreaName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OpenDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  PropertyValue?: InputMaybe<Scalars['float8']['input']>;
  Rate1?: InputMaybe<Scalars['float8']['input']>;
  Rate2?: InputMaybe<Scalars['float8']['input']>;
  Ratename?: InputMaybe<Scalars['String']['input']>;
  RentalTax?: InputMaybe<Scalars['bigint']['input']>;
  SubAreaName?: InputMaybe<Scalars['String']['input']>;
  Tax1?: InputMaybe<Scalars['float8']['input']>;
  Tax2?: InputMaybe<Scalars['float8']['input']>;
  TelNo?: InputMaybe<Scalars['String']['input']>;
  TotalTax?: InputMaybe<Scalars['float8']['input']>;
  Value1?: InputMaybe<Scalars['bigint']['input']>;
  Value2?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
  PropertyValue?: Maybe<Scalars['float8']['output']>;
  Rate1?: Maybe<Scalars['float8']['output']>;
  Rate2?: Maybe<Scalars['float8']['output']>;
  RentalTax?: Maybe<Scalars['bigint']['output']>;
  Tax1?: Maybe<Scalars['float8']['output']>;
  Tax2?: Maybe<Scalars['float8']['output']>;
  TotalTax?: Maybe<Scalars['float8']['output']>;
  Value1?: Maybe<Scalars['bigint']['output']>;
  Value2?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesDatabaseExtract_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
  PropertyValue?: Maybe<Scalars['Float']['output']>;
  Rate1?: Maybe<Scalars['Float']['output']>;
  Rate2?: Maybe<Scalars['Float']['output']>;
  RentalTax?: Maybe<Scalars['Float']['output']>;
  Tax1?: Maybe<Scalars['Float']['output']>;
  Tax2?: Maybe<Scalars['Float']['output']>;
  TotalTax?: Maybe<Scalars['Float']['output']>;
  Value1?: Maybe<Scalars['Float']['output']>;
  Value2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
export type Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
  PropertyValue?: InputMaybe<Order_By>;
  Rate1?: InputMaybe<Order_By>;
  Rate2?: InputMaybe<Order_By>;
  RentalTax?: InputMaybe<Order_By>;
  Tax1?: InputMaybe<Order_By>;
  Tax2?: InputMaybe<Order_By>;
  TotalTax?: InputMaybe<Order_By>;
  Value1?: InputMaybe<Order_By>;
  Value2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData';
  Category?: Maybe<Scalars['String']['output']>;
  DataFieldDescription?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_RatesPropertyData". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp = {
  Category?: InputMaybe<String_Comparison_Exp>;
  DataFieldDescription?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  IsAvailable?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Required?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  SourceFieldName?: InputMaybe<String_Comparison_Exp>;
  SourceTableName?: InputMaybe<String_Comparison_Exp>;
  Subject?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Insert_Input = {
  Category?: InputMaybe<Scalars['String']['input']>;
  DataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_max_fields';
  Category?: Maybe<Scalars['String']['output']>;
  DataFieldDescription?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Max_Order_By = {
  Category?: InputMaybe<Order_By>;
  DataFieldDescription?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_min_fields';
  Category?: Maybe<Scalars['String']['output']>;
  DataFieldDescription?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Min_Order_By = {
  Category?: InputMaybe<Order_By>;
  DataFieldDescription?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_RatesPropertyData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Order_By = {
  Category?: InputMaybe<Order_By>;
  DataFieldDescription?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export enum Rtcs_Gm_Dev_Kmc_RatesPropertyData_Select_Column {
  /** column name */
  Category = 'Category',
  /** column name */
  DataFieldDescription = 'DataFieldDescription',
  /** column name */
  File = 'File',
  /** column name */
  IsAvailable = 'IsAvailable',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Required = 'Required',
  /** column name */
  Source = 'Source',
  /** column name */
  SourceFieldName = 'SourceFieldName',
  /** column name */
  SourceTableName = 'SourceTableName',
  /** column name */
  Subject = 'Subject',
  /** column name */
  Type = 'Type',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Set_Input = {
  Category?: InputMaybe<Scalars['String']['input']>;
  DataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesPropertyData_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_RatesPropertyData" */
export type Rtcs_Gm_Dev_Kmc_RatesPropertyData_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OwnerName?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  TelephoneNo?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_RatesReceiptsData". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  OwnerName?: InputMaybe<String_Comparison_Exp>;
  PropertyNo?: InputMaybe<String_Comparison_Exp>;
  TelephoneNo?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OwnerName?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  TelephoneNo?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OwnerName?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  TelephoneNo?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OwnerName?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  TelephoneNo?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  OwnerName?: Maybe<Scalars['String']['output']>;
  PropertyNo?: Maybe<Scalars['String']['output']>;
  TelephoneNo?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OwnerName?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  TelephoneNo?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Order_By = {
  Address?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  OwnerName?: InputMaybe<Order_By>;
  PropertyNo?: InputMaybe<Order_By>;
  TelephoneNo?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export enum Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  OwnerName = 'OwnerName',
  /** column name */
  PropertyNo = 'PropertyNo',
  /** column name */
  TelephoneNo = 'TelephoneNo',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  OwnerName?: InputMaybe<Scalars['String']['input']>;
  PropertyNo?: InputMaybe<Scalars['String']['input']>;
  TelephoneNo?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_RatesReceiptsData_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_RatesReceiptsData" */
export type Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense';
  Address?: Maybe<Scalars['String']['output']>;
  ClassCategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusinessDataFieldDescription?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister';
  Address?: Maybe<Scalars['String']['output']>;
  Classcategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nameofbusinessdatafielddescription?: Maybe<Scalars['String']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_TradeLicenseRegister". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  Classcategory?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Nameofbusinessdatafielddescription?: InputMaybe<String_Comparison_Exp>;
  Phoneno?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  Subject?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Classcategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nameofbusinessdatafielddescription?: InputMaybe<Scalars['String']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Classcategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nameofbusinessdatafielddescription?: Maybe<Scalars['String']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  Classcategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nameofbusinessdatafielddescription?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  Classcategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Nameofbusinessdatafielddescription?: Maybe<Scalars['String']['output']>;
  Phoneno?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  Classcategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nameofbusinessdatafielddescription?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Order_By = {
  Address?: InputMaybe<Order_By>;
  Classcategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Nameofbusinessdatafielddescription?: InputMaybe<Order_By>;
  Phoneno?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export enum Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  Classcategory = 'Classcategory',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Nameofbusinessdatafielddescription = 'Nameofbusinessdatafielddescription',
  /** column name */
  Phoneno = 'Phoneno',
  /** column name */
  Source = 'Source',
  /** column name */
  Subject = 'Subject',
  /** column name */
  Type = 'Type',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Classcategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Nameofbusinessdatafielddescription?: InputMaybe<Scalars['String']['input']>;
  Phoneno?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicenseRegister_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_TradeLicenseRegister" */
export type Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregated selection of "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Kmc_TradeLicense>;
};

/** aggregate fields of "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.kmc_TradeLicense". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  ClassCategory?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  IsAvailable?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  NameOfBusinessDataFieldDescription?: InputMaybe<String_Comparison_Exp>;
  PhoneNo?: InputMaybe<String_Comparison_Exp>;
  Required?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  SourceFieldName?: InputMaybe<String_Comparison_Exp>;
  SourceTableName?: InputMaybe<String_Comparison_Exp>;
  Subject?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  ClassCategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Max_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  ClassCategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusinessDataFieldDescription?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Max_Order_By = {
  Address?: InputMaybe<Order_By>;
  ClassCategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Min_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  ClassCategory?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  IsAvailable?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  NameOfBusinessDataFieldDescription?: Maybe<Scalars['String']['output']>;
  PhoneNo?: Maybe<Scalars['String']['output']>;
  Required?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SourceFieldName?: Maybe<Scalars['String']['output']>;
  SourceTableName?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Min_Order_By = {
  Address?: InputMaybe<Order_By>;
  ClassCategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Kmc_TradeLicense>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Kmc_TradeLicense_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Order_By = {
  Address?: InputMaybe<Order_By>;
  ClassCategory?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  IsAvailable?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Order_By>;
  PhoneNo?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SourceFieldName?: InputMaybe<Order_By>;
  SourceTableName?: InputMaybe<Order_By>;
  Subject?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export enum Rtcs_Gm_Dev_Kmc_TradeLicense_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  ClassCategory = 'ClassCategory',
  /** column name */
  File = 'File',
  /** column name */
  IsAvailable = 'IsAvailable',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  NameOfBusinessDataFieldDescription = 'NameOfBusinessDataFieldDescription',
  /** column name */
  PhoneNo = 'PhoneNo',
  /** column name */
  Required = 'Required',
  /** column name */
  Source = 'Source',
  /** column name */
  SourceFieldName = 'SourceFieldName',
  /** column name */
  SourceTableName = 'SourceTableName',
  /** column name */
  Subject = 'Subject',
  /** column name */
  Type = 'Type',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  ClassCategory?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  IsAvailable?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  NameOfBusinessDataFieldDescription?: InputMaybe<Scalars['String']['input']>;
  PhoneNo?: InputMaybe<Scalars['String']['input']>;
  Required?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SourceFieldName?: InputMaybe<Scalars['String']['input']>;
  SourceTableName?: InputMaybe<Scalars['String']['input']>;
  Subject?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_kmc_TradeLicense_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.kmc_TradeLicense" */
export type Rtcs_Gm_Dev_Kmc_TradeLicense_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers';
  AccountBalance?: Maybe<Scalars['String']['output']>;
  AccountBranch?: Maybe<Scalars['String']['output']>;
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  AccountNoSector?: Maybe<Scalars['String']['output']>;
  AccountRegion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  AddressReference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  CollectionFormType?: Maybe<Scalars['String']['output']>;
  CommercialOffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  ContractAddress?: Maybe<Scalars['String']['output']>;
  ContractReference?: Maybe<Scalars['String']['output']>;
  ContractStatus?: Maybe<Scalars['String']['output']>;
  CreditBalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  DocumentNo?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MeterMake?: Maybe<Scalars['String']['output']>;
  MeterNumber?: Maybe<Scalars['String']['output']>;
  MeterType?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  PoleNumber?: Maybe<Scalars['String']['output']>;
  PremiseType?: Maybe<Scalars['String']['output']>;
  RemoteControl?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  ServiceRegistrationDate?: Maybe<Scalars['String']['output']>;
  ServiceTerminationDate?: Maybe<Scalars['String']['output']>;
  ServicerateType?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SupplyType?: Maybe<Scalars['String']['output']>;
  SupplyZone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Nawec_AllCustomers>;
};

/** aggregate fields of "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_avg_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Avg_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.nawec_AllCustomers". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp = {
  AccountBalance?: InputMaybe<String_Comparison_Exp>;
  AccountBranch?: InputMaybe<String_Comparison_Exp>;
  AccountNo?: InputMaybe<Bigint_Comparison_Exp>;
  AccountNoSector?: InputMaybe<String_Comparison_Exp>;
  AccountRegion?: InputMaybe<String_Comparison_Exp>;
  Activity?: InputMaybe<String_Comparison_Exp>;
  AddressReference?: InputMaybe<String_Comparison_Exp>;
  Amr?: InputMaybe<String_Comparison_Exp>;
  CollectionFormType?: InputMaybe<String_Comparison_Exp>;
  CommercialOffice?: InputMaybe<String_Comparison_Exp>;
  Company?: InputMaybe<String_Comparison_Exp>;
  ContractAddress?: InputMaybe<String_Comparison_Exp>;
  ContractReference?: InputMaybe<String_Comparison_Exp>;
  ContractStatus?: InputMaybe<String_Comparison_Exp>;
  CreditBalance?: InputMaybe<String_Comparison_Exp>;
  Customer?: InputMaybe<String_Comparison_Exp>;
  CustomerType?: InputMaybe<String_Comparison_Exp>;
  DocumentNo?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  GroupCode?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  MeterMake?: InputMaybe<String_Comparison_Exp>;
  MeterNumber?: InputMaybe<String_Comparison_Exp>;
  MeterType?: InputMaybe<String_Comparison_Exp>;
  Multiplier?: InputMaybe<Bigint_Comparison_Exp>;
  Niss?: InputMaybe<String_Comparison_Exp>;
  Phase?: InputMaybe<String_Comparison_Exp>;
  PoleNumber?: InputMaybe<String_Comparison_Exp>;
  PremiseType?: InputMaybe<String_Comparison_Exp>;
  RemoteControl?: InputMaybe<String_Comparison_Exp>;
  Sector?: InputMaybe<String_Comparison_Exp>;
  Segment?: InputMaybe<String_Comparison_Exp>;
  ServiceRegistrationDate?: InputMaybe<String_Comparison_Exp>;
  ServiceTerminationDate?: InputMaybe<String_Comparison_Exp>;
  ServicerateType?: InputMaybe<String_Comparison_Exp>;
  Sms?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  SupplyType?: InputMaybe<String_Comparison_Exp>;
  SupplyZone?: InputMaybe<String_Comparison_Exp>;
  Tariff?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Inc_Input = {
  AccountNo?: InputMaybe<Scalars['bigint']['input']>;
  GroupCode?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Insert_Input = {
  AccountBalance?: InputMaybe<Scalars['String']['input']>;
  AccountBranch?: InputMaybe<Scalars['String']['input']>;
  AccountNo?: InputMaybe<Scalars['bigint']['input']>;
  AccountNoSector?: InputMaybe<Scalars['String']['input']>;
  AccountRegion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  AddressReference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  CollectionFormType?: InputMaybe<Scalars['String']['input']>;
  CommercialOffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  ContractAddress?: InputMaybe<Scalars['String']['input']>;
  ContractReference?: InputMaybe<Scalars['String']['input']>;
  ContractStatus?: InputMaybe<Scalars['String']['input']>;
  CreditBalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  CustomerType?: InputMaybe<Scalars['String']['input']>;
  DocumentNo?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  GroupCode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MeterMake?: InputMaybe<Scalars['String']['input']>;
  MeterNumber?: InputMaybe<Scalars['String']['input']>;
  MeterType?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  PoleNumber?: InputMaybe<Scalars['String']['input']>;
  PremiseType?: InputMaybe<Scalars['String']['input']>;
  RemoteControl?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  ServiceRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  ServiceTerminationDate?: InputMaybe<Scalars['String']['input']>;
  ServicerateType?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SupplyType?: InputMaybe<Scalars['String']['input']>;
  SupplyZone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Max_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_max_fields';
  AccountBalance?: Maybe<Scalars['String']['output']>;
  AccountBranch?: Maybe<Scalars['String']['output']>;
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  AccountNoSector?: Maybe<Scalars['String']['output']>;
  AccountRegion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  AddressReference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  CollectionFormType?: Maybe<Scalars['String']['output']>;
  CommercialOffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  ContractAddress?: Maybe<Scalars['String']['output']>;
  ContractReference?: Maybe<Scalars['String']['output']>;
  ContractStatus?: Maybe<Scalars['String']['output']>;
  CreditBalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  DocumentNo?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MeterMake?: Maybe<Scalars['String']['output']>;
  MeterNumber?: Maybe<Scalars['String']['output']>;
  MeterType?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  PoleNumber?: Maybe<Scalars['String']['output']>;
  PremiseType?: Maybe<Scalars['String']['output']>;
  RemoteControl?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  ServiceRegistrationDate?: Maybe<Scalars['String']['output']>;
  ServiceTerminationDate?: Maybe<Scalars['String']['output']>;
  ServicerateType?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SupplyType?: Maybe<Scalars['String']['output']>;
  SupplyZone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Max_Order_By = {
  AccountBalance?: InputMaybe<Order_By>;
  AccountBranch?: InputMaybe<Order_By>;
  AccountNo?: InputMaybe<Order_By>;
  AccountNoSector?: InputMaybe<Order_By>;
  AccountRegion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  AddressReference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  CollectionFormType?: InputMaybe<Order_By>;
  CommercialOffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  ContractAddress?: InputMaybe<Order_By>;
  ContractReference?: InputMaybe<Order_By>;
  ContractStatus?: InputMaybe<Order_By>;
  CreditBalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  DocumentNo?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MeterMake?: InputMaybe<Order_By>;
  MeterNumber?: InputMaybe<Order_By>;
  MeterType?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  PoleNumber?: InputMaybe<Order_By>;
  PremiseType?: InputMaybe<Order_By>;
  RemoteControl?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  ServiceRegistrationDate?: InputMaybe<Order_By>;
  ServiceTerminationDate?: InputMaybe<Order_By>;
  ServicerateType?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SupplyType?: InputMaybe<Order_By>;
  SupplyZone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Min_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_min_fields';
  AccountBalance?: Maybe<Scalars['String']['output']>;
  AccountBranch?: Maybe<Scalars['String']['output']>;
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  AccountNoSector?: Maybe<Scalars['String']['output']>;
  AccountRegion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  AddressReference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  CollectionFormType?: Maybe<Scalars['String']['output']>;
  CommercialOffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  ContractAddress?: Maybe<Scalars['String']['output']>;
  ContractReference?: Maybe<Scalars['String']['output']>;
  ContractStatus?: Maybe<Scalars['String']['output']>;
  CreditBalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  DocumentNo?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  MeterMake?: Maybe<Scalars['String']['output']>;
  MeterNumber?: Maybe<Scalars['String']['output']>;
  MeterType?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  PoleNumber?: Maybe<Scalars['String']['output']>;
  PremiseType?: Maybe<Scalars['String']['output']>;
  RemoteControl?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  ServiceRegistrationDate?: Maybe<Scalars['String']['output']>;
  ServiceTerminationDate?: Maybe<Scalars['String']['output']>;
  ServicerateType?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  SupplyType?: Maybe<Scalars['String']['output']>;
  SupplyZone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Min_Order_By = {
  AccountBalance?: InputMaybe<Order_By>;
  AccountBranch?: InputMaybe<Order_By>;
  AccountNo?: InputMaybe<Order_By>;
  AccountNoSector?: InputMaybe<Order_By>;
  AccountRegion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  AddressReference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  CollectionFormType?: InputMaybe<Order_By>;
  CommercialOffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  ContractAddress?: InputMaybe<Order_By>;
  ContractReference?: InputMaybe<Order_By>;
  ContractStatus?: InputMaybe<Order_By>;
  CreditBalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  DocumentNo?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MeterMake?: InputMaybe<Order_By>;
  MeterNumber?: InputMaybe<Order_By>;
  MeterType?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  PoleNumber?: InputMaybe<Order_By>;
  PremiseType?: InputMaybe<Order_By>;
  RemoteControl?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  ServiceRegistrationDate?: InputMaybe<Order_By>;
  ServiceTerminationDate?: InputMaybe<Order_By>;
  ServicerateType?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SupplyType?: InputMaybe<Order_By>;
  SupplyZone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Nawec_AllCustomers>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Nawec_AllCustomers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Order_By = {
  AccountBalance?: InputMaybe<Order_By>;
  AccountBranch?: InputMaybe<Order_By>;
  AccountNo?: InputMaybe<Order_By>;
  AccountNoSector?: InputMaybe<Order_By>;
  AccountRegion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  AddressReference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  CollectionFormType?: InputMaybe<Order_By>;
  CommercialOffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  ContractAddress?: InputMaybe<Order_By>;
  ContractReference?: InputMaybe<Order_By>;
  ContractStatus?: InputMaybe<Order_By>;
  CreditBalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  DocumentNo?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  MeterMake?: InputMaybe<Order_By>;
  MeterNumber?: InputMaybe<Order_By>;
  MeterType?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  PoleNumber?: InputMaybe<Order_By>;
  PremiseType?: InputMaybe<Order_By>;
  RemoteControl?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  ServiceRegistrationDate?: InputMaybe<Order_By>;
  ServiceTerminationDate?: InputMaybe<Order_By>;
  ServicerateType?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  SupplyType?: InputMaybe<Order_By>;
  SupplyZone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export enum Rtcs_Gm_Dev_Nawec_AllCustomers_Select_Column {
  /** column name */
  AccountBalance = 'AccountBalance',
  /** column name */
  AccountBranch = 'AccountBranch',
  /** column name */
  AccountNo = 'AccountNo',
  /** column name */
  AccountNoSector = 'AccountNoSector',
  /** column name */
  AccountRegion = 'AccountRegion',
  /** column name */
  Activity = 'Activity',
  /** column name */
  AddressReference = 'AddressReference',
  /** column name */
  Amr = 'Amr',
  /** column name */
  CollectionFormType = 'CollectionFormType',
  /** column name */
  CommercialOffice = 'CommercialOffice',
  /** column name */
  Company = 'Company',
  /** column name */
  ContractAddress = 'ContractAddress',
  /** column name */
  ContractReference = 'ContractReference',
  /** column name */
  ContractStatus = 'ContractStatus',
  /** column name */
  CreditBalance = 'CreditBalance',
  /** column name */
  Customer = 'Customer',
  /** column name */
  CustomerType = 'CustomerType',
  /** column name */
  DocumentNo = 'DocumentNo',
  /** column name */
  Email = 'Email',
  /** column name */
  File = 'File',
  /** column name */
  GroupCode = 'GroupCode',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  MeterMake = 'MeterMake',
  /** column name */
  MeterNumber = 'MeterNumber',
  /** column name */
  MeterType = 'MeterType',
  /** column name */
  Multiplier = 'Multiplier',
  /** column name */
  Niss = 'Niss',
  /** column name */
  Phase = 'Phase',
  /** column name */
  PoleNumber = 'PoleNumber',
  /** column name */
  PremiseType = 'PremiseType',
  /** column name */
  RemoteControl = 'RemoteControl',
  /** column name */
  Sector = 'Sector',
  /** column name */
  Segment = 'Segment',
  /** column name */
  ServiceRegistrationDate = 'ServiceRegistrationDate',
  /** column name */
  ServiceTerminationDate = 'ServiceTerminationDate',
  /** column name */
  ServicerateType = 'ServicerateType',
  /** column name */
  Sms = 'Sms',
  /** column name */
  Source = 'Source',
  /** column name */
  SupplyType = 'SupplyType',
  /** column name */
  SupplyZone = 'SupplyZone',
  /** column name */
  Tariff = 'Tariff',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Set_Input = {
  AccountBalance?: InputMaybe<Scalars['String']['input']>;
  AccountBranch?: InputMaybe<Scalars['String']['input']>;
  AccountNo?: InputMaybe<Scalars['bigint']['input']>;
  AccountNoSector?: InputMaybe<Scalars['String']['input']>;
  AccountRegion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  AddressReference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  CollectionFormType?: InputMaybe<Scalars['String']['input']>;
  CommercialOffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  ContractAddress?: InputMaybe<Scalars['String']['input']>;
  ContractReference?: InputMaybe<Scalars['String']['input']>;
  ContractStatus?: InputMaybe<Scalars['String']['input']>;
  CreditBalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  CustomerType?: InputMaybe<Scalars['String']['input']>;
  DocumentNo?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  GroupCode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  MeterMake?: InputMaybe<Scalars['String']['input']>;
  MeterNumber?: InputMaybe<Scalars['String']['input']>;
  MeterType?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  PoleNumber?: InputMaybe<Scalars['String']['input']>;
  PremiseType?: InputMaybe<Scalars['String']['input']>;
  RemoteControl?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  ServiceRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  ServiceTerminationDate?: InputMaybe<Scalars['String']['input']>;
  ServicerateType?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  SupplyType?: InputMaybe<Scalars['String']['input']>;
  SupplyZone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_stddev_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_stddev_pop_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Pop_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_stddev_samp_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Stddev_Samp_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_sum_fields';
  AccountNo?: Maybe<Scalars['bigint']['output']>;
  GroupCode?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Sum_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_var_pop_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Pop_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_var_samp_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Var_Samp_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_AllCustomers_variance_fields';
  AccountNo?: Maybe<Scalars['Float']['output']>;
  GroupCode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.nawec_AllCustomers" */
export type Rtcs_Gm_Dev_Nawec_AllCustomers_Variance_Order_By = {
  AccountNo?: InputMaybe<Order_By>;
  GroupCode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers';
  Accountbalance?: Maybe<Scalars['String']['output']>;
  Accountbranch?: Maybe<Scalars['String']['output']>;
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Accountnosector?: Maybe<Scalars['String']['output']>;
  Accountregion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  Addressreference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  Collectionformtype?: Maybe<Scalars['String']['output']>;
  Commercialoffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  Contractaddress?: Maybe<Scalars['String']['output']>;
  Contractreference?: Maybe<Scalars['String']['output']>;
  Contractstatus?: Maybe<Scalars['String']['output']>;
  Creditbalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  Customertype?: Maybe<Scalars['String']['output']>;
  Documentno?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Metermake?: Maybe<Scalars['String']['output']>;
  Meternumber?: Maybe<Scalars['String']['output']>;
  Metertype?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  Polenumber?: Maybe<Scalars['String']['output']>;
  Premisetype?: Maybe<Scalars['String']['output']>;
  Remotecontrol?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  Serviceratetype?: Maybe<Scalars['String']['output']>;
  Serviceregistrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Serviceterminationdate?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Supplytype?: Maybe<Scalars['String']['output']>;
  Supplyzone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers>;
};

/** aggregate fields of "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_avg_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Avg_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp = {
  Accountbalance?: InputMaybe<String_Comparison_Exp>;
  Accountbranch?: InputMaybe<String_Comparison_Exp>;
  Accountno?: InputMaybe<Bigint_Comparison_Exp>;
  Accountnosector?: InputMaybe<String_Comparison_Exp>;
  Accountregion?: InputMaybe<String_Comparison_Exp>;
  Activity?: InputMaybe<String_Comparison_Exp>;
  Addressreference?: InputMaybe<String_Comparison_Exp>;
  Amr?: InputMaybe<String_Comparison_Exp>;
  Collectionformtype?: InputMaybe<String_Comparison_Exp>;
  Commercialoffice?: InputMaybe<String_Comparison_Exp>;
  Company?: InputMaybe<String_Comparison_Exp>;
  Contractaddress?: InputMaybe<String_Comparison_Exp>;
  Contractreference?: InputMaybe<String_Comparison_Exp>;
  Contractstatus?: InputMaybe<String_Comparison_Exp>;
  Creditbalance?: InputMaybe<String_Comparison_Exp>;
  Customer?: InputMaybe<String_Comparison_Exp>;
  Customertype?: InputMaybe<String_Comparison_Exp>;
  Documentno?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Groupcode?: InputMaybe<Float8_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Metermake?: InputMaybe<String_Comparison_Exp>;
  Meternumber?: InputMaybe<String_Comparison_Exp>;
  Metertype?: InputMaybe<String_Comparison_Exp>;
  Multiplier?: InputMaybe<Bigint_Comparison_Exp>;
  Niss?: InputMaybe<String_Comparison_Exp>;
  Phase?: InputMaybe<String_Comparison_Exp>;
  Polenumber?: InputMaybe<String_Comparison_Exp>;
  Premisetype?: InputMaybe<String_Comparison_Exp>;
  Remotecontrol?: InputMaybe<String_Comparison_Exp>;
  Sector?: InputMaybe<String_Comparison_Exp>;
  Segment?: InputMaybe<String_Comparison_Exp>;
  Serviceratetype?: InputMaybe<String_Comparison_Exp>;
  Serviceregistrationdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Serviceterminationdate?: InputMaybe<String_Comparison_Exp>;
  Sms?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<String_Comparison_Exp>;
  Supplytype?: InputMaybe<String_Comparison_Exp>;
  Supplyzone?: InputMaybe<String_Comparison_Exp>;
  Tariff?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Inc_Input = {
  Accountno?: InputMaybe<Scalars['bigint']['input']>;
  Groupcode?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Insert_Input = {
  Accountbalance?: InputMaybe<Scalars['String']['input']>;
  Accountbranch?: InputMaybe<Scalars['String']['input']>;
  Accountno?: InputMaybe<Scalars['bigint']['input']>;
  Accountnosector?: InputMaybe<Scalars['String']['input']>;
  Accountregion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  Addressreference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  Collectionformtype?: InputMaybe<Scalars['String']['input']>;
  Commercialoffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  Contractaddress?: InputMaybe<Scalars['String']['input']>;
  Contractreference?: InputMaybe<Scalars['String']['input']>;
  Contractstatus?: InputMaybe<Scalars['String']['input']>;
  Creditbalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  Customertype?: InputMaybe<Scalars['String']['input']>;
  Documentno?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Groupcode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Metermake?: InputMaybe<Scalars['String']['input']>;
  Meternumber?: InputMaybe<Scalars['String']['input']>;
  Metertype?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  Polenumber?: InputMaybe<Scalars['String']['input']>;
  Premisetype?: InputMaybe<Scalars['String']['input']>;
  Remotecontrol?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  Serviceratetype?: InputMaybe<Scalars['String']['input']>;
  Serviceregistrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Serviceterminationdate?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Supplytype?: InputMaybe<Scalars['String']['input']>;
  Supplyzone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Max_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_max_fields';
  Accountbalance?: Maybe<Scalars['String']['output']>;
  Accountbranch?: Maybe<Scalars['String']['output']>;
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Accountnosector?: Maybe<Scalars['String']['output']>;
  Accountregion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  Addressreference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  Collectionformtype?: Maybe<Scalars['String']['output']>;
  Commercialoffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  Contractaddress?: Maybe<Scalars['String']['output']>;
  Contractreference?: Maybe<Scalars['String']['output']>;
  Contractstatus?: Maybe<Scalars['String']['output']>;
  Creditbalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  Customertype?: Maybe<Scalars['String']['output']>;
  Documentno?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Metermake?: Maybe<Scalars['String']['output']>;
  Meternumber?: Maybe<Scalars['String']['output']>;
  Metertype?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  Polenumber?: Maybe<Scalars['String']['output']>;
  Premisetype?: Maybe<Scalars['String']['output']>;
  Remotecontrol?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  Serviceratetype?: Maybe<Scalars['String']['output']>;
  Serviceregistrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Serviceterminationdate?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Supplytype?: Maybe<Scalars['String']['output']>;
  Supplyzone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Max_Order_By = {
  Accountbalance?: InputMaybe<Order_By>;
  Accountbranch?: InputMaybe<Order_By>;
  Accountno?: InputMaybe<Order_By>;
  Accountnosector?: InputMaybe<Order_By>;
  Accountregion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  Addressreference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  Collectionformtype?: InputMaybe<Order_By>;
  Commercialoffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  Contractaddress?: InputMaybe<Order_By>;
  Contractreference?: InputMaybe<Order_By>;
  Contractstatus?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  Customertype?: InputMaybe<Order_By>;
  Documentno?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Metermake?: InputMaybe<Order_By>;
  Meternumber?: InputMaybe<Order_By>;
  Metertype?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  Polenumber?: InputMaybe<Order_By>;
  Premisetype?: InputMaybe<Order_By>;
  Remotecontrol?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  Serviceratetype?: InputMaybe<Order_By>;
  Serviceregistrationdate?: InputMaybe<Order_By>;
  Serviceterminationdate?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Supplytype?: InputMaybe<Order_By>;
  Supplyzone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Min_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_min_fields';
  Accountbalance?: Maybe<Scalars['String']['output']>;
  Accountbranch?: Maybe<Scalars['String']['output']>;
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Accountnosector?: Maybe<Scalars['String']['output']>;
  Accountregion?: Maybe<Scalars['String']['output']>;
  Activity?: Maybe<Scalars['String']['output']>;
  Addressreference?: Maybe<Scalars['String']['output']>;
  Amr?: Maybe<Scalars['String']['output']>;
  Collectionformtype?: Maybe<Scalars['String']['output']>;
  Commercialoffice?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  Contractaddress?: Maybe<Scalars['String']['output']>;
  Contractreference?: Maybe<Scalars['String']['output']>;
  Contractstatus?: Maybe<Scalars['String']['output']>;
  Creditbalance?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<Scalars['String']['output']>;
  Customertype?: Maybe<Scalars['String']['output']>;
  Documentno?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Metermake?: Maybe<Scalars['String']['output']>;
  Meternumber?: Maybe<Scalars['String']['output']>;
  Metertype?: Maybe<Scalars['String']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
  Niss?: Maybe<Scalars['String']['output']>;
  Phase?: Maybe<Scalars['String']['output']>;
  Polenumber?: Maybe<Scalars['String']['output']>;
  Premisetype?: Maybe<Scalars['String']['output']>;
  Remotecontrol?: Maybe<Scalars['String']['output']>;
  Sector?: Maybe<Scalars['String']['output']>;
  Segment?: Maybe<Scalars['String']['output']>;
  Serviceratetype?: Maybe<Scalars['String']['output']>;
  Serviceregistrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Serviceterminationdate?: Maybe<Scalars['String']['output']>;
  Sms?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  Supplytype?: Maybe<Scalars['String']['output']>;
  Supplyzone?: Maybe<Scalars['String']['output']>;
  Tariff?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Min_Order_By = {
  Accountbalance?: InputMaybe<Order_By>;
  Accountbranch?: InputMaybe<Order_By>;
  Accountno?: InputMaybe<Order_By>;
  Accountnosector?: InputMaybe<Order_By>;
  Accountregion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  Addressreference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  Collectionformtype?: InputMaybe<Order_By>;
  Commercialoffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  Contractaddress?: InputMaybe<Order_By>;
  Contractreference?: InputMaybe<Order_By>;
  Contractstatus?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  Customertype?: InputMaybe<Order_By>;
  Documentno?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Metermake?: InputMaybe<Order_By>;
  Meternumber?: InputMaybe<Order_By>;
  Metertype?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  Polenumber?: InputMaybe<Order_By>;
  Premisetype?: InputMaybe<Order_By>;
  Remotecontrol?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  Serviceratetype?: InputMaybe<Order_By>;
  Serviceregistrationdate?: InputMaybe<Order_By>;
  Serviceterminationdate?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Supplytype?: InputMaybe<Order_By>;
  Supplyzone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Order_By = {
  Accountbalance?: InputMaybe<Order_By>;
  Accountbranch?: InputMaybe<Order_By>;
  Accountno?: InputMaybe<Order_By>;
  Accountnosector?: InputMaybe<Order_By>;
  Accountregion?: InputMaybe<Order_By>;
  Activity?: InputMaybe<Order_By>;
  Addressreference?: InputMaybe<Order_By>;
  Amr?: InputMaybe<Order_By>;
  Collectionformtype?: InputMaybe<Order_By>;
  Commercialoffice?: InputMaybe<Order_By>;
  Company?: InputMaybe<Order_By>;
  Contractaddress?: InputMaybe<Order_By>;
  Contractreference?: InputMaybe<Order_By>;
  Contractstatus?: InputMaybe<Order_By>;
  Creditbalance?: InputMaybe<Order_By>;
  Customer?: InputMaybe<Order_By>;
  Customertype?: InputMaybe<Order_By>;
  Documentno?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Metermake?: InputMaybe<Order_By>;
  Meternumber?: InputMaybe<Order_By>;
  Metertype?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
  Niss?: InputMaybe<Order_By>;
  Phase?: InputMaybe<Order_By>;
  Polenumber?: InputMaybe<Order_By>;
  Premisetype?: InputMaybe<Order_By>;
  Remotecontrol?: InputMaybe<Order_By>;
  Sector?: InputMaybe<Order_By>;
  Segment?: InputMaybe<Order_By>;
  Serviceratetype?: InputMaybe<Order_By>;
  Serviceregistrationdate?: InputMaybe<Order_By>;
  Serviceterminationdate?: InputMaybe<Order_By>;
  Sms?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Supplytype?: InputMaybe<Order_By>;
  Supplyzone?: InputMaybe<Order_By>;
  Tariff?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export enum Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Select_Column {
  /** column name */
  Accountbalance = 'Accountbalance',
  /** column name */
  Accountbranch = 'Accountbranch',
  /** column name */
  Accountno = 'Accountno',
  /** column name */
  Accountnosector = 'Accountnosector',
  /** column name */
  Accountregion = 'Accountregion',
  /** column name */
  Activity = 'Activity',
  /** column name */
  Addressreference = 'Addressreference',
  /** column name */
  Amr = 'Amr',
  /** column name */
  Collectionformtype = 'Collectionformtype',
  /** column name */
  Commercialoffice = 'Commercialoffice',
  /** column name */
  Company = 'Company',
  /** column name */
  Contractaddress = 'Contractaddress',
  /** column name */
  Contractreference = 'Contractreference',
  /** column name */
  Contractstatus = 'Contractstatus',
  /** column name */
  Creditbalance = 'Creditbalance',
  /** column name */
  Customer = 'Customer',
  /** column name */
  Customertype = 'Customertype',
  /** column name */
  Documentno = 'Documentno',
  /** column name */
  Email = 'Email',
  /** column name */
  File = 'File',
  /** column name */
  Groupcode = 'Groupcode',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  Metermake = 'Metermake',
  /** column name */
  Meternumber = 'Meternumber',
  /** column name */
  Metertype = 'Metertype',
  /** column name */
  Multiplier = 'Multiplier',
  /** column name */
  Niss = 'Niss',
  /** column name */
  Phase = 'Phase',
  /** column name */
  Polenumber = 'Polenumber',
  /** column name */
  Premisetype = 'Premisetype',
  /** column name */
  Remotecontrol = 'Remotecontrol',
  /** column name */
  Sector = 'Sector',
  /** column name */
  Segment = 'Segment',
  /** column name */
  Serviceratetype = 'Serviceratetype',
  /** column name */
  Serviceregistrationdate = 'Serviceregistrationdate',
  /** column name */
  Serviceterminationdate = 'Serviceterminationdate',
  /** column name */
  Sms = 'Sms',
  /** column name */
  Source = 'Source',
  /** column name */
  Supplytype = 'Supplytype',
  /** column name */
  Supplyzone = 'Supplyzone',
  /** column name */
  Tariff = 'Tariff',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Set_Input = {
  Accountbalance?: InputMaybe<Scalars['String']['input']>;
  Accountbranch?: InputMaybe<Scalars['String']['input']>;
  Accountno?: InputMaybe<Scalars['bigint']['input']>;
  Accountnosector?: InputMaybe<Scalars['String']['input']>;
  Accountregion?: InputMaybe<Scalars['String']['input']>;
  Activity?: InputMaybe<Scalars['String']['input']>;
  Addressreference?: InputMaybe<Scalars['String']['input']>;
  Amr?: InputMaybe<Scalars['String']['input']>;
  Collectionformtype?: InputMaybe<Scalars['String']['input']>;
  Commercialoffice?: InputMaybe<Scalars['String']['input']>;
  Company?: InputMaybe<Scalars['String']['input']>;
  Contractaddress?: InputMaybe<Scalars['String']['input']>;
  Contractreference?: InputMaybe<Scalars['String']['input']>;
  Contractstatus?: InputMaybe<Scalars['String']['input']>;
  Creditbalance?: InputMaybe<Scalars['String']['input']>;
  Customer?: InputMaybe<Scalars['String']['input']>;
  Customertype?: InputMaybe<Scalars['String']['input']>;
  Documentno?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Groupcode?: InputMaybe<Scalars['float8']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Metermake?: InputMaybe<Scalars['String']['input']>;
  Meternumber?: InputMaybe<Scalars['String']['input']>;
  Metertype?: InputMaybe<Scalars['String']['input']>;
  Multiplier?: InputMaybe<Scalars['bigint']['input']>;
  Niss?: InputMaybe<Scalars['String']['input']>;
  Phase?: InputMaybe<Scalars['String']['input']>;
  Polenumber?: InputMaybe<Scalars['String']['input']>;
  Premisetype?: InputMaybe<Scalars['String']['input']>;
  Remotecontrol?: InputMaybe<Scalars['String']['input']>;
  Sector?: InputMaybe<Scalars['String']['input']>;
  Segment?: InputMaybe<Scalars['String']['input']>;
  Serviceratetype?: InputMaybe<Scalars['String']['input']>;
  Serviceregistrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Serviceterminationdate?: InputMaybe<Scalars['String']['input']>;
  Sms?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  Supplytype?: InputMaybe<Scalars['String']['input']>;
  Supplyzone?: InputMaybe<Scalars['String']['input']>;
  Tariff?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_stddev_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_stddev_pop_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Pop_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_stddev_samp_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Stddev_Samp_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_sum_fields';
  Accountno?: Maybe<Scalars['bigint']['output']>;
  Groupcode?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Multiplier?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Sum_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_var_pop_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Pop_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_var_samp_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Var_Samp_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_nawec_WaterAndElectricityCustomers_variance_fields';
  Accountno?: Maybe<Scalars['Float']['output']>;
  Groupcode?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Multiplier?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
export type Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Variance_Order_By = {
  Accountno?: InputMaybe<Order_By>;
  Groupcode?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Multiplier?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations';
  Carrier?: Maybe<Scalars['String']['output']>;
  Dob?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  Idno?: Maybe<Scalars['String']['output']>;
  Idtype?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Registrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations>;
};

/** aggregate fields of "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp = {
  Carrier?: InputMaybe<String_Comparison_Exp>;
  Dob?: InputMaybe<Timestamptz_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  Gender?: InputMaybe<String_Comparison_Exp>;
  Idno?: InputMaybe<String_Comparison_Exp>;
  Idtype?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Lastname?: InputMaybe<String_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Mobile?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Registrationdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Insert_Input = {
  Carrier?: InputMaybe<Scalars['String']['input']>;
  Dob?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  Idno?: InputMaybe<Scalars['String']['input']>;
  Idtype?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Registrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Max_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_max_fields';
  Carrier?: Maybe<Scalars['String']['output']>;
  Dob?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  Idno?: Maybe<Scalars['String']['output']>;
  Idtype?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Registrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Max_Order_By = {
  Carrier?: InputMaybe<Order_By>;
  Dob?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  Idno?: InputMaybe<Order_By>;
  Idtype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Registrationdate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Min_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_min_fields';
  Carrier?: Maybe<Scalars['String']['output']>;
  Dob?: Maybe<Scalars['timestamptz']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<Scalars['String']['output']>;
  Idno?: Maybe<Scalars['String']['output']>;
  Idtype?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Mobile?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Registrationdate?: Maybe<Scalars['timestamptz']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Min_Order_By = {
  Carrier?: InputMaybe<Order_By>;
  Dob?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  Idno?: InputMaybe<Order_By>;
  Idtype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Registrationdate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Order_By = {
  Carrier?: InputMaybe<Order_By>;
  Dob?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  Gender?: InputMaybe<Order_By>;
  Idno?: InputMaybe<Order_By>;
  Idtype?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Lastname?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Mobile?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Registrationdate?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export enum Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Select_Column {
  /** column name */
  Carrier = 'Carrier',
  /** column name */
  Dob = 'Dob',
  /** column name */
  File = 'File',
  /** column name */
  Gender = 'Gender',
  /** column name */
  Idno = 'Idno',
  /** column name */
  Idtype = 'Idtype',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Lastname = 'Lastname',
  /** column name */
  Line = 'Line',
  /** column name */
  Mobile = 'Mobile',
  /** column name */
  Name = 'Name',
  /** column name */
  Registrationdate = 'Registrationdate',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Set_Input = {
  Carrier?: InputMaybe<Scalars['String']['input']>;
  Dob?: InputMaybe<Scalars['timestamptz']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<Scalars['String']['input']>;
  Idno?: InputMaybe<Scalars['String']['input']>;
  Idtype?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Lastname?: InputMaybe<Scalars['String']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Mobile?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Registrationdate?: InputMaybe<Scalars['timestamptz']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
export type Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country = {
  __typename?: 'rtcs_gm_dev_rtcs_Country';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Rtcs_Country>;
};

/** aggregate fields of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Rtcs_Country_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.rtcs_Country". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp = {
  Countrycode?: InputMaybe<String_Comparison_Exp>;
  Countryname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Inc_Input = {
  Line?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Insert_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Max_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_max_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Max_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Min_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_min_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Min_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Rtcs_Country>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Rtcs_Country_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.rtcs_Country" */
export enum Rtcs_Gm_Dev_Rtcs_Country_Select_Column {
  /** column name */
  Countrycode = 'Countrycode',
  /** column name */
  Countryname = 'Countryname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Set_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_sum_fields';
  Line?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  BuildingCentroidGps?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  PlusCode1?: Maybe<Scalars['String']['output']>;
  PlusCode2?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WallMaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
};

/** aggregate fields of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_avg_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.streetview_StreetViewAnalysis". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp = {
  BuildingAreaM2?: InputMaybe<Float8_Comparison_Exp>;
  BuildingCentroidGps?: InputMaybe<String_Comparison_Exp>;
  BuildingType?: InputMaybe<String_Comparison_Exp>;
  BusinessName?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  NumberOfStoreys?: InputMaybe<Bigint_Comparison_Exp>;
  Phone1?: InputMaybe<String_Comparison_Exp>;
  Phone2?: InputMaybe<Float8_Comparison_Exp>;
  PlusCode1?: InputMaybe<String_Comparison_Exp>;
  PlusCode2?: InputMaybe<String_Comparison_Exp>;
  RoofMaterial?: InputMaybe<String_Comparison_Exp>;
  Settlement?: InputMaybe<String_Comparison_Exp>;
  Street?: InputMaybe<String_Comparison_Exp>;
  UUID?: InputMaybe<String_Comparison_Exp>;
  WallMaterial?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Inc_Input = {
  BuildingAreaM2?: InputMaybe<Scalars['float8']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumberOfStoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input = {
  BuildingAreaM2?: InputMaybe<Scalars['float8']['input']>;
  BuildingCentroidGps?: InputMaybe<Scalars['String']['input']>;
  BuildingType?: InputMaybe<Scalars['String']['input']>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumberOfStoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  PlusCode1?: InputMaybe<Scalars['String']['input']>;
  PlusCode2?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WallMaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_max_fields';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  BuildingCentroidGps?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  PlusCode1?: Maybe<Scalars['String']['output']>;
  PlusCode2?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WallMaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  BuildingCentroidGps?: InputMaybe<Order_By>;
  BuildingType?: InputMaybe<Order_By>;
  BusinessName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  PlusCode1?: InputMaybe<Order_By>;
  PlusCode2?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WallMaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_min_fields';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  BuildingCentroidGps?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  PlusCode1?: Maybe<Scalars['String']['output']>;
  PlusCode2?: Maybe<Scalars['String']['output']>;
  RoofMaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  UUID?: Maybe<Scalars['String']['output']>;
  WallMaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  BuildingCentroidGps?: InputMaybe<Order_By>;
  BuildingType?: InputMaybe<Order_By>;
  BusinessName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  PlusCode1?: InputMaybe<Order_By>;
  PlusCode2?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WallMaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  BuildingCentroidGps?: InputMaybe<Order_By>;
  BuildingType?: InputMaybe<Order_By>;
  BusinessName?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  PlusCode1?: InputMaybe<Order_By>;
  PlusCode2?: InputMaybe<Order_By>;
  RoofMaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  UUID?: InputMaybe<Order_By>;
  WallMaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export enum Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column {
  /** column name */
  BuildingAreaM2 = 'BuildingAreaM2',
  /** column name */
  BuildingCentroidGps = 'BuildingCentroidGps',
  /** column name */
  BuildingType = 'BuildingType',
  /** column name */
  BusinessName = 'BusinessName',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line = 'Line',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  NumberOfStoreys = 'NumberOfStoreys',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  PlusCode1 = 'PlusCode1',
  /** column name */
  PlusCode2 = 'PlusCode2',
  /** column name */
  RoofMaterial = 'RoofMaterial',
  /** column name */
  Settlement = 'Settlement',
  /** column name */
  Street = 'Street',
  /** column name */
  Uuid = 'UUID',
  /** column name */
  WallMaterial = 'WallMaterial',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Set_Input = {
  BuildingAreaM2?: InputMaybe<Scalars['float8']['input']>;
  BuildingCentroidGps?: InputMaybe<Scalars['String']['input']>;
  BuildingType?: InputMaybe<Scalars['String']['input']>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  NumberOfStoreys?: InputMaybe<Scalars['bigint']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  PlusCode1?: InputMaybe<Scalars['String']['input']>;
  PlusCode2?: InputMaybe<Scalars['String']['input']>;
  RoofMaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  UUID?: InputMaybe<Scalars['String']['input']>;
  WallMaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_stddev_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_stddev_pop_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_stddev_samp_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_sum_fields';
  BuildingAreaM2?: Maybe<Scalars['float8']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  NumberOfStoreys?: Maybe<Scalars['bigint']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_var_pop_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_var_samp_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_variance_fields';
  BuildingAreaM2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  NumberOfStoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Order_By = {
  BuildingAreaM2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  NumberOfStoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "rtcs_gm_dev.FnSYS_Users" which returns "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** execute function "rtcs_gm_dev.FnSYS_Users" and query aggregates on result of table type "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View" which returns "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_Fnafrica360view_Africa360View: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View" and query aggregates on result of table type "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregate: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnafrica360view_Africa360View_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates" which returns "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_Fnbcc_RegRates: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates" and query aggregates on result of table type "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregate: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnbcc_RegRates_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_RegRates_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister" which returns "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister" and query aggregates on result of table type "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnbcc_TradeLicenseRegister_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey" which returns "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey" and query aggregates on result of table type "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus" which returns "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus" and query aggregates on result of table type "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngbos_2024PopulationAndHousingCensus_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations" which returns "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations" and query aggregates on result of table type "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax" which returns "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregate: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CommericalResidentialTax_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn" which returns "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_Fngra_CorporateReturn: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector" which returns "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnDirector_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption" which returns "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnExemption_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss" which returns "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnLoss_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership" which returns "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPartnership_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent" which returns "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnPropertyRent_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived" which returns "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentReceived_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved" which returns "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturnRentRecieved_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturnRentRecieved_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_CorporateReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn" which returns "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_Fngra_IndividualReturn: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption" which returns "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnExemption_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan" which returns "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoan_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoan_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss" which returns "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnLoss_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnLoss_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent" which returns "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_Fngra_IndividualReturnRent: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid" which returns "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRentPaid_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRentPaid_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturnRent_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturnRent_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_IndividualReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental" which returns "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_Fngra_PaymentRental: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregate: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_PaymentRental_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_PaymentRental_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation" which returns "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_Fngra_RegAssociation: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregate: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_RegAssociation_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RegAssociation_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData" which returns "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_Fngra_RentalOfficeData: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregate: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_RentalOfficeData_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_RentalOfficeData_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_Request" which returns "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_Fngra_Request: Array<Rtcs_Gm_Dev_Ph_Gra_Request>;
  /** execute function "rtcs_gm_dev.Fngra_Request" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_Fngra_Request_aggregate: Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_Request_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_Request_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_Request_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_Request_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_Request_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError" which returns "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_Fngra_ReturnError: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_Fngra_ReturnError_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnError_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnError_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase" which returns "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_Fngra_ReturnsBase: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_ReturnsBase_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_ReturnsBase_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer" which returns "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_Fngra_TaxPayer: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregate: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_TaxPayer_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_TaxPayer_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn" which returns "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_Fngra_VATReturn: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_Fngra_VATReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_VATReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_VATReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn" which returns "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_Fngra_WithholdingReturn: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail" which returns "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturnDetail_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturnDetail_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn" and query aggregates on result of table type "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngra_WithholdingReturn_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngra_WithholdingReturn_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations" which returns "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations" and query aggregates on result of table type "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fngroc_MOJMonthlyRegistrations_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers" which returns "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesPayers_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesPayers_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister" which returns "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_PropertyRatesRegister_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_PropertyRatesRegister_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract" which returns "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesDatabaseExtract_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesDatabaseExtract_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData" which returns "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesPropertyData_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesPropertyData_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData" which returns "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_RatesReceiptsData_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_RatesReceiptsData_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense" which returns "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_Fnkmc_TradeLicense: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister" which returns "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicenseRegister_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicenseRegister_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense" and query aggregates on result of table type "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnkmc_TradeLicense_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnkmc_TradeLicense_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers" which returns "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_Fnnawec_AllCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers" and query aggregates on result of table type "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnnawec_AllCustomers_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_AllCustomers_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers" which returns "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers" and query aggregates on result of table type "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnnawec_WaterAndElectricityCustomers_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnnawec_WaterAndElectricityCustomers_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations" which returns "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations" and query aggregates on result of table type "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" which returns "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" and query aggregates on result of table type "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" which returns "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" and query aggregates on result of table type "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_PH_africa360view_Africa360View: Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_africa360view_Africa360View" */
  rtcs_gm_dev_PH_africa360view_Africa360View_aggregate: Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_PH_bcc_RegRates: Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_bcc_RegRates" */
  rtcs_gm_dev_PH_bcc_RegRates_aggregate: Rtcs_Gm_Dev_Ph_Bcc_RegRates_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_bcc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_bcc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_bcc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey: Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus: Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus_aggregate: Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations: Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_PH_gra_CommericalResidentialTax: Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CommericalResidentialTax" */
  rtcs_gm_dev_PH_gra_CommericalResidentialTax_aggregate: Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_PH_gra_CorporateReturn: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_PH_gra_CorporateReturnDirector: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnDirector" */
  rtcs_gm_dev_PH_gra_CorporateReturnDirector_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_PH_gra_CorporateReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnExemption" */
  rtcs_gm_dev_PH_gra_CorporateReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_PH_gra_CorporateReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnLoss" */
  rtcs_gm_dev_PH_gra_CorporateReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_PH_gra_CorporateReturnPartnership: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPartnership" */
  rtcs_gm_dev_PH_gra_CorporateReturnPartnership_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentReceived: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentReceived_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved: Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_PH_gra_CorporateReturnRentRecieved_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_CorporateReturn" */
  rtcs_gm_dev_PH_gra_CorporateReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_PH_gra_IndividualReturn: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_PH_gra_IndividualReturnExemption: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnExemption" */
  rtcs_gm_dev_PH_gra_IndividualReturnExemption_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoan: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoan" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoan_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoss: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnLoss" */
  rtcs_gm_dev_PH_gra_IndividualReturnLoss_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_PH_gra_IndividualReturnRent: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_PH_gra_IndividualReturnRentPaid: Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_PH_gra_IndividualReturnRentPaid_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturnRent" */
  rtcs_gm_dev_PH_gra_IndividualReturnRent_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_IndividualReturn" */
  rtcs_gm_dev_PH_gra_IndividualReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_PH_gra_PaymentRental: Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_PaymentRental" */
  rtcs_gm_dev_PH_gra_PaymentRental_aggregate: Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_PH_gra_RegAssociation: Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_RegAssociation" */
  rtcs_gm_dev_PH_gra_RegAssociation_aggregate: Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_PH_gra_RentalOfficeData: Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_RentalOfficeData" */
  rtcs_gm_dev_PH_gra_RentalOfficeData_aggregate: Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_PH_gra_Request: Array<Rtcs_Gm_Dev_Ph_Gra_Request>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_Request" */
  rtcs_gm_dev_PH_gra_Request_aggregate: Rtcs_Gm_Dev_Ph_Gra_Request_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_PH_gra_ReturnError: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_ReturnError" */
  rtcs_gm_dev_PH_gra_ReturnError_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnError_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_PH_gra_ReturnsBase: Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_ReturnsBase" */
  rtcs_gm_dev_PH_gra_ReturnsBase_aggregate: Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_PH_gra_TaxPayer: Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_TaxPayer" */
  rtcs_gm_dev_PH_gra_TaxPayer_aggregate: Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_PH_gra_VATReturn: Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_VATReturn" */
  rtcs_gm_dev_PH_gra_VATReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_VatReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_PH_gra_WithholdingReturn: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn>;
  /** fetch data from the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_PH_gra_WithholdingReturnDetail: Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_WithholdingReturnDetail" */
  rtcs_gm_dev_PH_gra_WithholdingReturnDetail_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_gra_WithholdingReturn" */
  rtcs_gm_dev_PH_gra_WithholdingReturn_aggregate: Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations: Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_PH_kmc_PropertyRatesPayers: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesPayers" */
  rtcs_gm_dev_PH_kmc_PropertyRatesPayers_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_PH_kmc_PropertyRatesRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_PropertyRatesRegister" */
  rtcs_gm_dev_PH_kmc_PropertyRatesRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_PH_kmc_RatesDatabaseExtract: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_PH_kmc_RatesDatabaseExtract_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_PH_kmc_RatesPropertyData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_RatesPropertyData" */
  rtcs_gm_dev_PH_kmc_RatesPropertyData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_PH_kmc_RatesReceiptsData: Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_RatesReceiptsData" */
  rtcs_gm_dev_PH_kmc_RatesReceiptsData_aggregate: Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_PH_kmc_TradeLicense: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense>;
  /** fetch data from the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_kmc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_TradeLicenseRegister" */
  rtcs_gm_dev_PH_kmc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_kmc_TradeLicense" */
  rtcs_gm_dev_PH_kmc_TradeLicense_aggregate: Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_PH_nawec_AllCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_nawec_AllCustomers" */
  rtcs_gm_dev_PH_nawec_AllCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers: Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_PH_nawec_WaterAndElectricityCustomers_aggregate: Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations: Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations_aggregate: Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users: Array<Rtcs_Gm_Dev_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users_aggregate: Rtcs_Gm_Dev_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.africa360view_Africa360View" */
  rtcs_gm_dev_africa360view_Africa360View: Array<Rtcs_Gm_Dev_Africa360view_Africa360View>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.africa360view_Africa360View" */
  rtcs_gm_dev_africa360view_Africa360View_aggregate: Rtcs_Gm_Dev_Africa360view_Africa360View_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.bcc_RegRates" */
  rtcs_gm_dev_bcc_RegRates: Array<Rtcs_Gm_Dev_Bcc_RegRates>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.bcc_RegRates" */
  rtcs_gm_dev_bcc_RegRates_aggregate: Rtcs_Gm_Dev_Bcc_RegRates_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  rtcs_gm_dev_bcc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.bcc_TradeLicenseRegister" */
  rtcs_gm_dev_bcc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey: Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gbos_2022CommercialRentalRatesSurvey" */
  rtcs_gm_dev_gbos_2022CommercialRentalRatesSurvey_aggregate: Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_gbos_2024PopulationAndHousingCensus: Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gbos_2024PopulationAndHousingCensus" */
  rtcs_gm_dev_gbos_2024PopulationAndHousingCensus_aggregate: Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations: Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gid_NationalIDAndResidentialPermitRegistrations" */
  rtcs_gm_dev_gid_NationalIDAndResidentialPermitRegistrations_aggregate: Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  rtcs_gm_dev_gra_CommericalResidentialTax: Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CommericalResidentialTax" */
  rtcs_gm_dev_gra_CommericalResidentialTax_aggregate: Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturn" */
  rtcs_gm_dev_gra_CorporateReturn: Array<Rtcs_Gm_Dev_Gra_CorporateReturn>;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  rtcs_gm_dev_gra_CorporateReturnDirector: Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnDirector" */
  rtcs_gm_dev_gra_CorporateReturnDirector_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  rtcs_gm_dev_gra_CorporateReturnExemption: Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnExemption" */
  rtcs_gm_dev_gra_CorporateReturnExemption_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  rtcs_gm_dev_gra_CorporateReturnLoss: Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnLoss" */
  rtcs_gm_dev_gra_CorporateReturnLoss_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  rtcs_gm_dev_gra_CorporateReturnPartnership: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnPartnership" */
  rtcs_gm_dev_gra_CorporateReturnPartnership_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_gra_CorporateReturnPropertyRent: Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnPropertyRent" */
  rtcs_gm_dev_gra_CorporateReturnPropertyRent_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_gra_CorporateReturnRentReceived: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnRentReceived" */
  rtcs_gm_dev_gra_CorporateReturnRentReceived_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_gra_CorporateReturnRentRecieved: Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturnRentRecieved" */
  rtcs_gm_dev_gra_CorporateReturnRentRecieved_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_CorporateReturn" */
  rtcs_gm_dev_gra_CorporateReturn_aggregate: Rtcs_Gm_Dev_Gra_CorporateReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturn" */
  rtcs_gm_dev_gra_IndividualReturn: Array<Rtcs_Gm_Dev_Gra_IndividualReturn>;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  rtcs_gm_dev_gra_IndividualReturnExemption: Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnExemption" */
  rtcs_gm_dev_gra_IndividualReturnExemption_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  rtcs_gm_dev_gra_IndividualReturnLoan: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnLoan" */
  rtcs_gm_dev_gra_IndividualReturnLoan_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  rtcs_gm_dev_gra_IndividualReturnLoss: Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnLoss" */
  rtcs_gm_dev_gra_IndividualReturnLoss_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  rtcs_gm_dev_gra_IndividualReturnRent: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent>;
  /** fetch data from the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_gra_IndividualReturnRentPaid: Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnRentPaid" */
  rtcs_gm_dev_gra_IndividualReturnRentPaid_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturnRent" */
  rtcs_gm_dev_gra_IndividualReturnRent_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturnRent_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_IndividualReturn" */
  rtcs_gm_dev_gra_IndividualReturn_aggregate: Rtcs_Gm_Dev_Gra_IndividualReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_PaymentRental" */
  rtcs_gm_dev_gra_PaymentRental: Array<Rtcs_Gm_Dev_Gra_PaymentRental>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_PaymentRental" */
  rtcs_gm_dev_gra_PaymentRental_aggregate: Rtcs_Gm_Dev_Gra_PaymentRental_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_RegAssociation" */
  rtcs_gm_dev_gra_RegAssociation: Array<Rtcs_Gm_Dev_Gra_RegAssociation>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_RegAssociation" */
  rtcs_gm_dev_gra_RegAssociation_aggregate: Rtcs_Gm_Dev_Gra_RegAssociation_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  rtcs_gm_dev_gra_RentalOfficeData: Array<Rtcs_Gm_Dev_Gra_RentalOfficeData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_RentalOfficeData" */
  rtcs_gm_dev_gra_RentalOfficeData_aggregate: Rtcs_Gm_Dev_Gra_RentalOfficeData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_Request" */
  rtcs_gm_dev_gra_Request: Array<Rtcs_Gm_Dev_Gra_Request>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_Request" */
  rtcs_gm_dev_gra_Request_aggregate: Rtcs_Gm_Dev_Gra_Request_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_ReturnError" */
  rtcs_gm_dev_gra_ReturnError: Array<Rtcs_Gm_Dev_Gra_ReturnError>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_ReturnError" */
  rtcs_gm_dev_gra_ReturnError_aggregate: Rtcs_Gm_Dev_Gra_ReturnError_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_ReturnsBase" */
  rtcs_gm_dev_gra_ReturnsBase: Array<Rtcs_Gm_Dev_Gra_ReturnsBase>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_ReturnsBase" */
  rtcs_gm_dev_gra_ReturnsBase_aggregate: Rtcs_Gm_Dev_Gra_ReturnsBase_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_TaxPayer" */
  rtcs_gm_dev_gra_TaxPayer: Array<Rtcs_Gm_Dev_Gra_TaxPayer>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_TaxPayer" */
  rtcs_gm_dev_gra_TaxPayer_aggregate: Rtcs_Gm_Dev_Gra_TaxPayer_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_VATReturn" */
  rtcs_gm_dev_gra_VATReturn: Array<Rtcs_Gm_Dev_Gra_VatReturn>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_VATReturn" */
  rtcs_gm_dev_gra_VATReturn_aggregate: Rtcs_Gm_Dev_Gra_VatReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  rtcs_gm_dev_gra_WithholdingReturn: Array<Rtcs_Gm_Dev_Gra_WithholdingReturn>;
  /** fetch data from the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  rtcs_gm_dev_gra_WithholdingReturnDetail: Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_WithholdingReturnDetail" */
  rtcs_gm_dev_gra_WithholdingReturnDetail_aggregate: Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.gra_WithholdingReturn" */
  rtcs_gm_dev_gra_WithholdingReturn_aggregate: Rtcs_Gm_Dev_Gra_WithholdingReturn_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_groc_MOJMonthlyRegistrations: Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.groc_MOJMonthlyRegistrations" */
  rtcs_gm_dev_groc_MOJMonthlyRegistrations_aggregate: Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  rtcs_gm_dev_kmc_PropertyRatesPayers: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_PropertyRatesPayers" */
  rtcs_gm_dev_kmc_PropertyRatesPayers_aggregate: Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  rtcs_gm_dev_kmc_PropertyRatesRegister: Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_PropertyRatesRegister" */
  rtcs_gm_dev_kmc_PropertyRatesRegister_aggregate: Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_kmc_RatesDatabaseExtract: Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_RatesDatabaseExtract" */
  rtcs_gm_dev_kmc_RatesDatabaseExtract_aggregate: Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  rtcs_gm_dev_kmc_RatesPropertyData: Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_RatesPropertyData" */
  rtcs_gm_dev_kmc_RatesPropertyData_aggregate: Rtcs_Gm_Dev_Kmc_RatesPropertyData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  rtcs_gm_dev_kmc_RatesReceiptsData: Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_RatesReceiptsData" */
  rtcs_gm_dev_kmc_RatesReceiptsData_aggregate: Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.kmc_TradeLicense" */
  rtcs_gm_dev_kmc_TradeLicense: Array<Rtcs_Gm_Dev_Kmc_TradeLicense>;
  /** fetch data from the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  rtcs_gm_dev_kmc_TradeLicenseRegister: Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_TradeLicenseRegister" */
  rtcs_gm_dev_kmc_TradeLicenseRegister_aggregate: Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.kmc_TradeLicense" */
  rtcs_gm_dev_kmc_TradeLicense_aggregate: Rtcs_Gm_Dev_Kmc_TradeLicense_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.nawec_AllCustomers" */
  rtcs_gm_dev_nawec_AllCustomers: Array<Rtcs_Gm_Dev_Nawec_AllCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.nawec_AllCustomers" */
  rtcs_gm_dev_nawec_AllCustomers_aggregate: Rtcs_Gm_Dev_Nawec_AllCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_nawec_WaterAndElectricityCustomers: Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.nawec_WaterAndElectricityCustomers" */
  rtcs_gm_dev_nawec_WaterAndElectricityCustomers_aggregate: Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations: Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.pura_MobileCarrierSubscriberRegistrations" */
  rtcs_gm_dev_pura_MobileCarrierSubscriberRegistrations_aggregate: Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country: Array<Rtcs_Gm_Dev_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country_aggregate: Rtcs_Gm_Dev_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_UsersArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnafrica360view_Africa360ViewArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnafrica360view_Africa360View_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_RegRatesArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_RegRates_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_RegRates_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_RegRates_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_RegRates_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegisterArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnbcc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurveyArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2022CommercialRentalRatesSurvey_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensusArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngbos_2024PopulationAndHousingCensus_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrationsArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngid_NationalIdAndResidentialPermitRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTaxArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CommericalResidentialTax_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirectorArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnDirector_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemptionArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnLossArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnershipArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPartnership_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRentArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnPropertyRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceivedArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentReceived_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecievedArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturnRentRecieved_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_CorporateReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemptionArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnExemption_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoanArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoan_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLossArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnLoss_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaidArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRentPaid_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturnRent_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_IndividualReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_PaymentRentalArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_PaymentRental_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_PaymentRental_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_PaymentRental_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_PaymentRental_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RegAssociationArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RegAssociation_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RegAssociation_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RegAssociation_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RegAssociation_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RentalOfficeDataArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_RentalOfficeData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_RequestArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_Request_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_Request_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_Request_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_Request_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_Request_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_Request_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnErrorArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnError_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnError_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnError_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnError_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnsBaseArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnsBase_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnsBase_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnsBase_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_ReturnsBase_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_TaxPayerArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_TaxPayer_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_TaxPayer_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_TaxPayer_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_TaxPayer_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_VatReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_VatReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_VatReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_VatReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_VatReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetailArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturnDetail_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngra_WithholdingReturn_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrationsArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fngroc_MojMonthlyRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayersArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesPayers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegisterArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_PropertyRatesRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtractArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesDatabaseExtract_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyDataArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesPropertyData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsDataArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_RatesReceiptsData_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegisterArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicenseRegister_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnkmc_TradeLicense_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_AllCustomersArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_AllCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomersArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnnawec_WaterAndElectricityCustomers_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrationsArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnpura_MobileCarrierSubscriberRegistrations_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_CountryArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysisArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Africa360view_Africa360ViewArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Africa360view_Africa360View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Africa360view_Africa360View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Bcc_RegRatesArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Bcc_RegRates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_RegRates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurveyArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensusArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CommericalResidentialTaxArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnDirectorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnershipArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceivedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecievedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_CorporateReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLoanArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaidArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_IndividualReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_PaymentRentalArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_PaymentRental_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_PaymentRental_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_RegAssociationArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_RegAssociation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RegAssociation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_RentalOfficeDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_RentalOfficeData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_RentalOfficeData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_RequestArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_ReturnErrorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_ReturnError_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnError_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_ReturnsBaseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_ReturnsBase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_ReturnsBase_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_TaxPayerArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_TaxPayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_TaxPayer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_VatReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_VatReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_VatReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetailArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Gra_WithholdingReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Gra_WithholdingReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtractArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_RatesPropertyDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesPropertyData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_RatesReceiptsDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicenseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Kmc_TradeLicense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Kmc_TradeLicense_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Nawec_AllCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Nawec_AllCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_AllCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Africa360view_Africa360ViewArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Africa360view_Africa360View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Africa360view_Africa360View_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Africa360view_Africa360View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_ResultArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_Result_Txt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Bcc_RegRatesArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Bcc_RegRates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_RegRates_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_RegRates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Bcc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Bcc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Bcc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurveyArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2022CommercialRentalRatesSurvey_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensusArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gbos_2024PopulationAndHousingCensus_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gid_NationalIdAndResidentialPermitRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CommericalResidentialTaxArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CommericalResidentialTax_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CommericalResidentialTax_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnDirectorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnDirector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnDirector_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnPartnershipArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnPartnership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPartnership_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnPropertyRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnPropertyRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnRentReceivedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnRentReceived_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentReceived_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnRentRecievedArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturnRentRecieved_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_CorporateReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_CorporateReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_CorporateReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnExemptionArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnExemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnLoanArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnLoan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnLossArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnLoss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnLoss_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnRentArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnRentPaidArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnRentPaid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRentPaid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturnRent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturnRent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_IndividualReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_IndividualReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_IndividualReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_PaymentRentalArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_PaymentRental_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_PaymentRental_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_PaymentRental_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_RegAssociationArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_RegAssociation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RegAssociation_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RegAssociation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_RentalOfficeDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_RentalOfficeData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_RentalOfficeData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_RentalOfficeData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_RequestArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_Request_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_ReturnErrorArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_ReturnError_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnError_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnError_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_ReturnsBaseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_ReturnsBase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_ReturnsBase_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_ReturnsBase_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_TaxPayerArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_TaxPayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_TaxPayer_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_TaxPayer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_VatReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_VatReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_VatReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_VatReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_WithholdingReturnArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_WithholdingReturnDetailArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_WithholdingReturnDetail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturnDetail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Gra_WithholdingReturn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Gra_WithholdingReturn_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Gra_WithholdingReturn_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Groc_MojMonthlyRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Groc_MojMonthlyRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Groc_MojMonthlyRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_PropertyRatesPayersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_PropertyRatesPayers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesPayers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_PropertyRatesRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_PropertyRatesRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_PropertyRatesRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_RatesDatabaseExtractArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_RatesDatabaseExtract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesDatabaseExtract_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_RatesPropertyDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_RatesPropertyData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesPropertyData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_RatesReceiptsDataArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_RatesReceiptsData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_RatesReceiptsData_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_TradeLicenseArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_TradeLicenseRegisterArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_TradeLicenseRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicenseRegister_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Kmc_TradeLicense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Kmc_TradeLicense_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Kmc_TradeLicense_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Nawec_AllCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Nawec_AllCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_AllCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_AllCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Nawec_WaterAndElectricityCustomersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Nawec_WaterAndElectricityCustomers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Pura_MobileCarrierSubscriberRegistrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type Africa360ViewFragment = { __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null };

export type Africa360ViewQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type Africa360ViewQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnafrica360view_Africa360View: Array<{ __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null }> };

export type PaginatedAfrica360ViewQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type PaginatedAfrica360ViewQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnafrica360view_Africa360View: Array<{ __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null }>, rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type Africa360ViewFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type Africa360ViewFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnafrica360view_Africa360View: Array<{ __typename?: 'rtcs_gm_dev_PH_africa360view_Africa360View', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null }> };

export type TradeLicenseRegisterFragment = { __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister', Address?: string | null, Arrears?: string | null, Balance?: string | null, Certificateno?: string | null, Currentyear?: string | null, Id?: string | null, Invdate?: any | null, Invid?: string | null, Invno?: string | null, Licencetype?: string | null, Name?: string | null, Receiptdetails?: string | null, Totalinvoiceamount?: string | null, Totalreceipttodate?: any | null, Wrno?: string | null, Year?: string | null, Uuid?: string | null };

export type GetTradeLicenseRegisterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetTradeLicenseRegisterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnbcc_TradeLicenseRegister: Array<{ __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister', Address?: string | null, Arrears?: string | null, Balance?: string | null, Certificateno?: string | null, Currentyear?: string | null, Id?: string | null, Invdate?: any | null, Invid?: string | null, Invno?: string | null, Licencetype?: string | null, Name?: string | null, Receiptdetails?: string | null, Totalinvoiceamount?: string | null, Totalreceipttodate?: any | null, Wrno?: string | null, Year?: string | null, Uuid?: string | null }> };

export type GetPaginatedTradeLicenseRegisterQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedTradeLicenseRegisterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnbcc_TradeLicenseRegister: Array<{ __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister', Address?: string | null, Arrears?: string | null, Balance?: string | null, Certificateno?: string | null, Currentyear?: string | null, Id?: string | null, Invdate?: any | null, Invid?: string | null, Invno?: string | null, Licencetype?: string | null, Name?: string | null, Receiptdetails?: string | null, Totalinvoiceamount?: string | null, Totalreceipttodate?: any | null, Wrno?: string | null, Year?: string | null, Uuid?: string | null }>, rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type TradeLicenseRegisterFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type TradeLicenseRegisterFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnbcc_TradeLicenseRegister: Array<{ __typename?: 'rtcs_gm_dev_PH_bcc_TradeLicenseRegister', Address?: string | null, Arrears?: string | null, Balance?: string | null, Certificateno?: string | null, Currentyear?: string | null, Id?: string | null, Invdate?: any | null, Invid?: string | null, Invno?: string | null, Licencetype?: string | null, Name?: string | null, Receiptdetails?: string | null, Totalinvoiceamount?: string | null, Totalreceipttodate?: any | null, Wrno?: string | null, Year?: string | null, Uuid?: string | null }> };

export type RegRatesFragment = { __typename?: 'rtcs_gm_dev_PH_bcc_RegRates', Address?: string | null, Amountdue?: any | null, Arrears1?: string | null, Arrears2?: any | null, Arrears3?: string | null, Balance?: string | null, Contactno?: string | null, Current1?: any | null, Current2?: any | null, Current3?: string | null, Currentowner?: string | null, Date?: any | null, Demandnoteno?: string | null, Detailsofpayment1?: string | null, Detailsofpayment2?: string | null, Detailsofpayment3?: string | null, Lot?: string | null, Particulars?: string | null, Plandate?: any | null, Plandetails?: string | null, Rem?: string | null, Sm?: string | null, Total1?: any | null, Total2?: string | null, Total3?: string | null, Totalpaytodate1?: any | null, Totalpaytodate2?: any | null, Totalpaytodate3?: any | null, Uuid?: string | null, Wr?: string | null, Year?: any | null };

export type GetRegRatesQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetRegRatesQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnbcc_RegRates: Array<{ __typename?: 'rtcs_gm_dev_PH_bcc_RegRates', Address?: string | null, Amountdue?: any | null, Arrears1?: string | null, Arrears2?: any | null, Arrears3?: string | null, Balance?: string | null, Contactno?: string | null, Current1?: any | null, Current2?: any | null, Current3?: string | null, Currentowner?: string | null, Date?: any | null, Demandnoteno?: string | null, Detailsofpayment1?: string | null, Detailsofpayment2?: string | null, Detailsofpayment3?: string | null, Lot?: string | null, Particulars?: string | null, Plandate?: any | null, Plandetails?: string | null, Rem?: string | null, Sm?: string | null, Total1?: any | null, Total2?: string | null, Total3?: string | null, Totalpaytodate1?: any | null, Totalpaytodate2?: any | null, Totalpaytodate3?: any | null, Uuid?: string | null, Wr?: string | null, Year?: any | null }> };

export type GetPaginatedRegRatesQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedRegRatesQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnbcc_RegRates: Array<{ __typename?: 'rtcs_gm_dev_PH_bcc_RegRates', Address?: string | null, Amountdue?: any | null, Arrears1?: string | null, Arrears2?: any | null, Arrears3?: string | null, Balance?: string | null, Contactno?: string | null, Current1?: any | null, Current2?: any | null, Current3?: string | null, Currentowner?: string | null, Date?: any | null, Demandnoteno?: string | null, Detailsofpayment1?: string | null, Detailsofpayment2?: string | null, Detailsofpayment3?: string | null, Lot?: string | null, Particulars?: string | null, Plandate?: any | null, Plandetails?: string | null, Rem?: string | null, Sm?: string | null, Total1?: any | null, Total2?: string | null, Total3?: string | null, Totalpaytodate1?: any | null, Totalpaytodate2?: any | null, Totalpaytodate3?: any | null, Uuid?: string | null, Wr?: string | null, Year?: any | null }>, rtcs_gm_dev_Fnbcc_RegRates_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type RegRatesFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type RegRatesFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnbcc_RegRates: Array<{ __typename?: 'rtcs_gm_dev_PH_bcc_RegRates', Address?: string | null, Amountdue?: any | null, Arrears1?: string | null, Arrears2?: any | null, Arrears3?: string | null, Balance?: string | null, Contactno?: string | null, Current1?: any | null, Current2?: any | null, Current3?: string | null, Currentowner?: string | null, Date?: any | null, Demandnoteno?: string | null, Detailsofpayment1?: string | null, Detailsofpayment2?: string | null, Detailsofpayment3?: string | null, Lot?: string | null, Particulars?: string | null, Plandate?: any | null, Plandetails?: string | null, Rem?: string | null, Sm?: string | null, Total1?: any | null, Total2?: string | null, Total3?: string | null, Totalpaytodate1?: any | null, Totalpaytodate2?: any | null, Totalpaytodate3?: any | null, Uuid?: string | null, Wr?: string | null, Year?: any | null }> };

export type Gbos2022CommercialRentalRatesSurveyFragment = { __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey', Address?: string | null, AddressOfLandlordagency?: string | null, AddressOfLandlordagent?: string | null, AmountPaid?: string | null, AmountPaidPerPeriod?: any | null, AnyMore?: string | null, BusinessActivitytype?: string | null, BusinessTelephone?: any | null, ContactOfLandlordagent?: any | null, ContactPersonsTel?: any | null, CurrencyUnit?: string | null, DurationInMonths?: any | null, FrequencyOfPayment?: string | null, IndividualId?: any | null, Lga?: string | null, NameOfBusiness?: string | null, NameOfLandlordagent?: string | null, NameOfLandlordagentForOtherBusinessPropertyElsewhere?: string | null, OtherBusinessPropertyRentedElsewhere?: any | null, OtherBusinessPropertyRentedElsewhereStatus?: string | null, OtherSpecifiedFrequencyPaymentArrangement?: string | null, OwnerOccupiedEstimatedValueOfRent?: string | null, PaymentFrequency?: string | null, PositionOfRespondentcontactPerson?: string | null, PropertyOwneradministrator?: string | null, RentPeriodArrangement?: string | null, SecondContact?: any | null, SecondTelOfContactPerson?: any | null, TelOfLandlordagent?: any | null, ThirdContact?: any | null, TinOfLandlordagent?: any | null, Uuid?: string | null };

export type GetGbos2022CommercialRentalRatesSurveyQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGbos2022CommercialRentalRatesSurveyQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey: Array<{ __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey', Address?: string | null, AddressOfLandlordagency?: string | null, AddressOfLandlordagent?: string | null, AmountPaid?: string | null, AmountPaidPerPeriod?: any | null, AnyMore?: string | null, BusinessActivitytype?: string | null, BusinessTelephone?: any | null, ContactOfLandlordagent?: any | null, ContactPersonsTel?: any | null, CurrencyUnit?: string | null, DurationInMonths?: any | null, FrequencyOfPayment?: string | null, IndividualId?: any | null, Lga?: string | null, NameOfBusiness?: string | null, NameOfLandlordagent?: string | null, NameOfLandlordagentForOtherBusinessPropertyElsewhere?: string | null, OtherBusinessPropertyRentedElsewhere?: any | null, OtherBusinessPropertyRentedElsewhereStatus?: string | null, OtherSpecifiedFrequencyPaymentArrangement?: string | null, OwnerOccupiedEstimatedValueOfRent?: string | null, PaymentFrequency?: string | null, PositionOfRespondentcontactPerson?: string | null, PropertyOwneradministrator?: string | null, RentPeriodArrangement?: string | null, SecondContact?: any | null, SecondTelOfContactPerson?: any | null, TelOfLandlordagent?: any | null, ThirdContact?: any | null, TinOfLandlordagent?: any | null, Uuid?: string | null }> };

export type GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGbos2022CommercialRentalRatesSurveyQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey: Array<{ __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey', Address?: string | null, AddressOfLandlordagency?: string | null, AddressOfLandlordagent?: string | null, AmountPaid?: string | null, AmountPaidPerPeriod?: any | null, AnyMore?: string | null, BusinessActivitytype?: string | null, BusinessTelephone?: any | null, ContactOfLandlordagent?: any | null, ContactPersonsTel?: any | null, CurrencyUnit?: string | null, DurationInMonths?: any | null, FrequencyOfPayment?: string | null, IndividualId?: any | null, Lga?: string | null, NameOfBusiness?: string | null, NameOfLandlordagent?: string | null, NameOfLandlordagentForOtherBusinessPropertyElsewhere?: string | null, OtherBusinessPropertyRentedElsewhere?: any | null, OtherBusinessPropertyRentedElsewhereStatus?: string | null, OtherSpecifiedFrequencyPaymentArrangement?: string | null, OwnerOccupiedEstimatedValueOfRent?: string | null, PaymentFrequency?: string | null, PositionOfRespondentcontactPerson?: string | null, PropertyOwneradministrator?: string | null, RentPeriodArrangement?: string | null, SecondContact?: any | null, SecondTelOfContactPerson?: any | null, TelOfLandlordagent?: any | null, ThirdContact?: any | null, TinOfLandlordagent?: any | null, Uuid?: string | null }>, rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGbos2022CommercialRentalRatesSurveyFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey: Array<{ __typename?: 'rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey', Address?: string | null, AddressOfLandlordagency?: string | null, AddressOfLandlordagent?: string | null, AmountPaid?: string | null, AmountPaidPerPeriod?: any | null, AnyMore?: string | null, BusinessActivitytype?: string | null, BusinessTelephone?: any | null, ContactOfLandlordagent?: any | null, ContactPersonsTel?: any | null, CurrencyUnit?: string | null, DurationInMonths?: any | null, FrequencyOfPayment?: string | null, IndividualId?: any | null, Lga?: string | null, NameOfBusiness?: string | null, NameOfLandlordagent?: string | null, NameOfLandlordagentForOtherBusinessPropertyElsewhere?: string | null, OtherBusinessPropertyRentedElsewhere?: any | null, OtherBusinessPropertyRentedElsewhereStatus?: string | null, OtherSpecifiedFrequencyPaymentArrangement?: string | null, OwnerOccupiedEstimatedValueOfRent?: string | null, PaymentFrequency?: string | null, PositionOfRespondentcontactPerson?: string | null, PropertyOwneradministrator?: string | null, RentPeriodArrangement?: string | null, SecondContact?: any | null, SecondTelOfContactPerson?: any | null, TelOfLandlordagent?: any | null, ThirdContact?: any | null, TinOfLandlordagent?: any | null, Uuid?: string | null }> };

export type Gbos2024PopulationAndHousingCensusFragment = { __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus', Compoundowner?: string | null, CookingFuel?: string | null, Districtname?: string | null, FeelEviction?: string | null, FloorMaterial?: string | null, FreqSolidWasteCollection?: string | null, HEaCode?: any | null, HHeadPhone?: any | null, HHhHeadName?: string | null, HHhNumber?: any | null, HStrNumber?: any | null, Hdistrict?: any | null, Headofcompound?: string | null, Hlga?: any | null, Hsettlement?: string | null, Hward?: any | null, Latitude?: any | null, Lganame?: string | null, LiqWasteFromEconomic?: string | null, LiqWasteFromHhchores?: string | null, LiqWasteFromHumanConve?: string | null, Longitude?: any | null, NumOfFemale?: any | null, NumOfMale?: any | null, OccupancyStatus?: string | null, PayCompoundRate?: string | null, PlaceForBathing?: string | null, PlaceForCooking?: string | null, RoofMaterial?: string | null, SolidWasteDisposal?: string | null, SourceDrinkingWater?: string | null, SourceLighting?: string | null, SuitableForResidence?: string | null, TitleDeed?: string | null, ToiletFacility?: string | null, TotalNum?: any | null, Uuid?: string | null, WallsMaterial?: string | null, Wardname?: string | null };

export type GetGbos2024PopulationAndHousingCensusQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGbos2024PopulationAndHousingCensusQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus: Array<{ __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus', Compoundowner?: string | null, CookingFuel?: string | null, Districtname?: string | null, FeelEviction?: string | null, FloorMaterial?: string | null, FreqSolidWasteCollection?: string | null, HEaCode?: any | null, HHeadPhone?: any | null, HHhHeadName?: string | null, HHhNumber?: any | null, HStrNumber?: any | null, Hdistrict?: any | null, Headofcompound?: string | null, Hlga?: any | null, Hsettlement?: string | null, Hward?: any | null, Latitude?: any | null, Lganame?: string | null, LiqWasteFromEconomic?: string | null, LiqWasteFromHhchores?: string | null, LiqWasteFromHumanConve?: string | null, Longitude?: any | null, NumOfFemale?: any | null, NumOfMale?: any | null, OccupancyStatus?: string | null, PayCompoundRate?: string | null, PlaceForBathing?: string | null, PlaceForCooking?: string | null, RoofMaterial?: string | null, SolidWasteDisposal?: string | null, SourceDrinkingWater?: string | null, SourceLighting?: string | null, SuitableForResidence?: string | null, TitleDeed?: string | null, ToiletFacility?: string | null, TotalNum?: any | null, Uuid?: string | null, WallsMaterial?: string | null, Wardname?: string | null }> };

export type GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGbos2024PopulationAndHousingCensusQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus: Array<{ __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus', Compoundowner?: string | null, CookingFuel?: string | null, Districtname?: string | null, FeelEviction?: string | null, FloorMaterial?: string | null, FreqSolidWasteCollection?: string | null, HEaCode?: any | null, HHeadPhone?: any | null, HHhHeadName?: string | null, HHhNumber?: any | null, HStrNumber?: any | null, Hdistrict?: any | null, Headofcompound?: string | null, Hlga?: any | null, Hsettlement?: string | null, Hward?: any | null, Latitude?: any | null, Lganame?: string | null, LiqWasteFromEconomic?: string | null, LiqWasteFromHhchores?: string | null, LiqWasteFromHumanConve?: string | null, Longitude?: any | null, NumOfFemale?: any | null, NumOfMale?: any | null, OccupancyStatus?: string | null, PayCompoundRate?: string | null, PlaceForBathing?: string | null, PlaceForCooking?: string | null, RoofMaterial?: string | null, SolidWasteDisposal?: string | null, SourceDrinkingWater?: string | null, SourceLighting?: string | null, SuitableForResidence?: string | null, TitleDeed?: string | null, ToiletFacility?: string | null, TotalNum?: any | null, Uuid?: string | null, WallsMaterial?: string | null, Wardname?: string | null }>, rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GetGbos2024PopulationAndHousingCensusFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGbos2024PopulationAndHousingCensusFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus: Array<{ __typename?: 'rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus', Compoundowner?: string | null, CookingFuel?: string | null, Districtname?: string | null, FeelEviction?: string | null, FloorMaterial?: string | null, FreqSolidWasteCollection?: string | null, HEaCode?: any | null, HHeadPhone?: any | null, HHhHeadName?: string | null, HHhNumber?: any | null, HStrNumber?: any | null, Hdistrict?: any | null, Headofcompound?: string | null, Hlga?: any | null, Hsettlement?: string | null, Hward?: any | null, Latitude?: any | null, Lganame?: string | null, LiqWasteFromEconomic?: string | null, LiqWasteFromHhchores?: string | null, LiqWasteFromHumanConve?: string | null, Longitude?: any | null, NumOfFemale?: any | null, NumOfMale?: any | null, OccupancyStatus?: string | null, PayCompoundRate?: string | null, PlaceForBathing?: string | null, PlaceForCooking?: string | null, RoofMaterial?: string | null, SolidWasteDisposal?: string | null, SourceDrinkingWater?: string | null, SourceLighting?: string | null, SuitableForResidence?: string | null, TitleDeed?: string | null, ToiletFacility?: string | null, TotalNum?: any | null, Uuid?: string | null, WallsMaterial?: string | null, Wardname?: string | null }> };

export type NationalIdAndResidentialPermitRegistrationsFragment = { __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations', Address?: string | null, Cardnumbers?: any | null, Dateofbirth?: string | null, Driverlicencereceiptnumber?: string | null, Expirydate?: any | null, Fathersfirstname?: string | null, Fatherslastname?: string | null, Firstname?: string | null, Height?: any | null, Issuedate?: any | null, Lastname?: string | null, Mothersfirstname?: string | null, Motherslastname?: string | null, Nin?: string | null, Placeofbirth?: string | null, Proffesion?: string | null, Region?: string | null };

export type NationalIdAndResidentialPermitRegistrationsQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type NationalIdAndResidentialPermitRegistrationsQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations', Address?: string | null, Cardnumbers?: any | null, Dateofbirth?: string | null, Driverlicencereceiptnumber?: string | null, Expirydate?: any | null, Fathersfirstname?: string | null, Fatherslastname?: string | null, Firstname?: string | null, Height?: any | null, Issuedate?: any | null, Lastname?: string | null, Mothersfirstname?: string | null, Motherslastname?: string | null, Nin?: string | null, Placeofbirth?: string | null, Proffesion?: string | null, Region?: string | null }> };

export type NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type NationalIdAndResidentialPermitRegistrationsPaginatedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations', Address?: string | null, Cardnumbers?: any | null, Dateofbirth?: string | null, Driverlicencereceiptnumber?: string | null, Expirydate?: any | null, Fathersfirstname?: string | null, Fatherslastname?: string | null, Firstname?: string | null, Height?: any | null, Issuedate?: any | null, Lastname?: string | null, Mothersfirstname?: string | null, Motherslastname?: string | null, Nin?: string | null, Placeofbirth?: string | null, Proffesion?: string | null, Region?: string | null }>, rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type NationalIdAndResidentialPermitRegistrationsFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type NationalIdAndResidentialPermitRegistrationsFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations', Address?: string | null, Cardnumbers?: any | null, Dateofbirth?: string | null, Driverlicencereceiptnumber?: string | null, Expirydate?: any | null, Fathersfirstname?: string | null, Fatherslastname?: string | null, Firstname?: string | null, Height?: any | null, Issuedate?: any | null, Lastname?: string | null, Mothersfirstname?: string | null, Motherslastname?: string | null, Nin?: string | null, Placeofbirth?: string | null, Proffesion?: string | null, Region?: string | null }> };

export type GraCommercialResidentialTaxFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax', Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Taxcode?: any | null, Taxmonth?: any | null, Taxname?: string | null, Taxyear?: any | null, Tin?: any | null, Uuid?: string | null };

export type GetGraCommercialResidentialTaxQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraCommercialResidentialTaxQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CommericalResidentialTax: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax', Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Taxcode?: any | null, Taxmonth?: any | null, Taxname?: string | null, Taxyear?: any | null, Tin?: any | null, Uuid?: string | null }> };

export type GetPaginatedGraCommercialResidentialTaxQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraCommercialResidentialTaxQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CommericalResidentialTax: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax', Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Taxcode?: any | null, Taxmonth?: any | null, Taxname?: string | null, Taxyear?: any | null, Tin?: any | null, Uuid?: string | null }>, rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraCommercialResidentialTaxFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraCommercialResidentialTaxFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CommericalResidentialTax: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CommericalResidentialTax', Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Taxcode?: any | null, Taxmonth?: any | null, Taxname?: string | null, Taxyear?: any | null, Tin?: any | null, Uuid?: string | null }> };

export type CorporateReturnExemptionFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption', Amount?: any | null, Basis?: any | null, Exemptionid?: string | null, Incometype?: any | null, LastUpdated?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Returnid?: any | null, Taxliability?: any | null, Taxrate?: any | null };

export type CorporateReturnExemptionQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type CorporateReturnExemptionQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnExemption: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption', Amount?: any | null, Basis?: any | null, Exemptionid?: string | null, Incometype?: any | null, LastUpdated?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Returnid?: any | null, Taxliability?: any | null, Taxrate?: any | null }> };

export type CorporateReturnExemptionPaginatedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type CorporateReturnExemptionPaginatedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnExemption: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption', Amount?: any | null, Basis?: any | null, Exemptionid?: string | null, Incometype?: any | null, LastUpdated?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Returnid?: any | null, Taxliability?: any | null, Taxrate?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type CorporateReturnExemptionFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type CorporateReturnExemptionFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnExemption: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnExemption', Amount?: any | null, Basis?: any | null, Exemptionid?: string | null, Incometype?: any | null, LastUpdated?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Returnid?: any | null, Taxliability?: any | null, Taxrate?: any | null }> };

export type CorporateReturnFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn', Accural?: any | null, Additionalallowance?: any | null, Allowancebroughtforward?: any | null, Allowanceutilised?: any | null, Businessincome?: any | null, Capitalallowance?: any | null, Cashbank?: any | null, Closingstock?: any | null, Costofsale?: any | null, Creditor?: any | null, Debtor?: any | null, Depreciation?: any | null, Exemptincome?: any | null, Exporter?: any | null, Fixedasset?: any | null, Foreignexchangeloss?: any | null, Foreigntaxcredit?: any | null, Fringebenefittax?: any | null, Grossprofit?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Interestexpense?: any | null, LastUpdated?: any | null, Line?: any | null, Longtermliability?: any | null, Lossbroughtforward?: any | null, Openingstock?: any | null, Operatingexpense?: any | null, Otherasset?: any | null, Othercurrentasset?: any | null, Othercurrentliability?: any | null, Otherdeduction?: any | null, Otherexpense?: any | null, Otherincome?: any | null, Otherliability?: any | null, Overpayment?: any | null, Propertyincome?: any | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Salarywage?: any | null, Sharehholderfund?: any | null, Sicexemption?: any | null, Stock?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Tax1stschedule?: any | null, Totalasset?: any | null, Totalliability?: any | null, Turnovertax?: any | null, Unallowablededuction?: any | null, Unutilisedallowance?: any | null, Withholdingtaxcredit?: any | null, Yearallowance?: any | null };

export type CorporateReturnQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type CorporateReturnQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturn: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn', Accural?: any | null, Additionalallowance?: any | null, Allowancebroughtforward?: any | null, Allowanceutilised?: any | null, Businessincome?: any | null, Capitalallowance?: any | null, Cashbank?: any | null, Closingstock?: any | null, Costofsale?: any | null, Creditor?: any | null, Debtor?: any | null, Depreciation?: any | null, Exemptincome?: any | null, Exporter?: any | null, Fixedasset?: any | null, Foreignexchangeloss?: any | null, Foreigntaxcredit?: any | null, Fringebenefittax?: any | null, Grossprofit?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Interestexpense?: any | null, LastUpdated?: any | null, Line?: any | null, Longtermliability?: any | null, Lossbroughtforward?: any | null, Openingstock?: any | null, Operatingexpense?: any | null, Otherasset?: any | null, Othercurrentasset?: any | null, Othercurrentliability?: any | null, Otherdeduction?: any | null, Otherexpense?: any | null, Otherincome?: any | null, Otherliability?: any | null, Overpayment?: any | null, Propertyincome?: any | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Salarywage?: any | null, Sharehholderfund?: any | null, Sicexemption?: any | null, Stock?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Tax1stschedule?: any | null, Totalasset?: any | null, Totalliability?: any | null, Turnovertax?: any | null, Unallowablededuction?: any | null, Unutilisedallowance?: any | null, Withholdingtaxcredit?: any | null, Yearallowance?: any | null }> };

export type CorporateReturnPaginatedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type CorporateReturnPaginatedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturn: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn', Accural?: any | null, Additionalallowance?: any | null, Allowancebroughtforward?: any | null, Allowanceutilised?: any | null, Businessincome?: any | null, Capitalallowance?: any | null, Cashbank?: any | null, Closingstock?: any | null, Costofsale?: any | null, Creditor?: any | null, Debtor?: any | null, Depreciation?: any | null, Exemptincome?: any | null, Exporter?: any | null, Fixedasset?: any | null, Foreignexchangeloss?: any | null, Foreigntaxcredit?: any | null, Fringebenefittax?: any | null, Grossprofit?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Interestexpense?: any | null, LastUpdated?: any | null, Line?: any | null, Longtermliability?: any | null, Lossbroughtforward?: any | null, Openingstock?: any | null, Operatingexpense?: any | null, Otherasset?: any | null, Othercurrentasset?: any | null, Othercurrentliability?: any | null, Otherdeduction?: any | null, Otherexpense?: any | null, Otherincome?: any | null, Otherliability?: any | null, Overpayment?: any | null, Propertyincome?: any | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Salarywage?: any | null, Sharehholderfund?: any | null, Sicexemption?: any | null, Stock?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Tax1stschedule?: any | null, Totalasset?: any | null, Totalliability?: any | null, Turnovertax?: any | null, Unallowablededuction?: any | null, Unutilisedallowance?: any | null, Withholdingtaxcredit?: any | null, Yearallowance?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type CorporateReturnFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type CorporateReturnFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturn: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturn', Accural?: any | null, Additionalallowance?: any | null, Allowancebroughtforward?: any | null, Allowanceutilised?: any | null, Businessincome?: any | null, Capitalallowance?: any | null, Cashbank?: any | null, Closingstock?: any | null, Costofsale?: any | null, Creditor?: any | null, Debtor?: any | null, Depreciation?: any | null, Exemptincome?: any | null, Exporter?: any | null, Fixedasset?: any | null, Foreignexchangeloss?: any | null, Foreigntaxcredit?: any | null, Fringebenefittax?: any | null, Grossprofit?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Interestexpense?: any | null, LastUpdated?: any | null, Line?: any | null, Longtermliability?: any | null, Lossbroughtforward?: any | null, Openingstock?: any | null, Operatingexpense?: any | null, Otherasset?: any | null, Othercurrentasset?: any | null, Othercurrentliability?: any | null, Otherdeduction?: any | null, Otherexpense?: any | null, Otherincome?: any | null, Otherliability?: any | null, Overpayment?: any | null, Propertyincome?: any | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Salarywage?: any | null, Sharehholderfund?: any | null, Sicexemption?: any | null, Stock?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null, Tax1stschedule?: any | null, Totalasset?: any | null, Totalliability?: any | null, Turnovertax?: any | null, Unallowablededuction?: any | null, Unutilisedallowance?: any | null, Withholdingtaxcredit?: any | null, Yearallowance?: any | null }> };

export type CorporateReturnDirectorFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector', Directorfee?: any | null, Directorname?: string | null, Directorremunerationid?: string | null, Otherallowance?: any | null, Returnid?: any | null, Salary?: any | null, Total?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Directortin?: any | null };

export type CorporateReturnDirectorQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type CorporateReturnDirectorQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnDirector: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector', Directorfee?: any | null, Directorname?: string | null, Directorremunerationid?: string | null, Otherallowance?: any | null, Returnid?: any | null, Salary?: any | null, Total?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Directortin?: any | null }> };

export type CorporateReturnDirectorPaginatedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type CorporateReturnDirectorPaginatedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnDirector: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector', Directorfee?: any | null, Directorname?: string | null, Directorremunerationid?: string | null, Otherallowance?: any | null, Returnid?: any | null, Salary?: any | null, Total?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Directortin?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type CorporateReturnDirectorFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type CorporateReturnDirectorFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnDirector: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnDirector', Directorfee?: any | null, Directorname?: string | null, Directorremunerationid?: string | null, Otherallowance?: any | null, Returnid?: any | null, Salary?: any | null, Total?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Directortin?: any | null }> };

export type GraCorporateReturnLossFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss', Lossid?: string | null, Lossmade?: any | null, Lossoutstanding?: any | null, Losssetoff?: any | null, Lossyear?: any | null, Returnid?: any | null, Yearleft?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null };

export type GetGraCorporateReturnLossQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraCorporateReturnLossQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnLoss: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss', Lossid?: string | null, Lossmade?: any | null, Lossoutstanding?: any | null, Losssetoff?: any | null, Lossyear?: any | null, Returnid?: any | null, Yearleft?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }> };

export type GetPaginatedGraCorporateReturnLossQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraCorporateReturnLossQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnLoss: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss', Lossid?: string | null, Lossmade?: any | null, Lossoutstanding?: any | null, Losssetoff?: any | null, Lossyear?: any | null, Returnid?: any | null, Yearleft?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraCorporateReturnLossFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraCorporateReturnLossFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnLoss: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnLoss', Lossid?: string | null, Lossmade?: any | null, Lossoutstanding?: any | null, Losssetoff?: any | null, Lossyear?: any | null, Returnid?: any | null, Yearleft?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }> };

export type GraCorporateReturnPartnershipFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership', Interest?: any | null, Partnername?: string | null, Partnershipid?: string | null, Partnerstatus?: string | null, Returnid?: any | null, Salary?: any | null, Sharebalance?: any | null, Shareratio?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null };

export type GetGraCorporateReturnPartnershipQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraCorporateReturnPartnershipQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnPartnership: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership', Interest?: any | null, Partnername?: string | null, Partnershipid?: string | null, Partnerstatus?: string | null, Returnid?: any | null, Salary?: any | null, Sharebalance?: any | null, Shareratio?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }> };

export type GetPaginatedGraCorporateReturnPartnershipQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraCorporateReturnPartnershipQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnPartnership: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership', Interest?: any | null, Partnername?: string | null, Partnershipid?: string | null, Partnerstatus?: string | null, Returnid?: any | null, Salary?: any | null, Sharebalance?: any | null, Shareratio?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraCorporateReturnPartnershipFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraCorporateReturnPartnershipFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnPartnership: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPartnership', Interest?: any | null, Partnername?: string | null, Partnershipid?: string | null, Partnerstatus?: string | null, Returnid?: any | null, Salary?: any | null, Sharebalance?: any | null, Shareratio?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }> };

export type GraCorporateReturnPropertyRentFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: string | null, Landlordname?: string | null, Landlordtin?: string | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: string | null, Returnid?: any | null, Telephone?: string | null, Tenancystartdate?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null };

export type GetGraCorporateReturnPropertyRentQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraCorporateReturnPropertyRentQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnPropertyRent: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: string | null, Landlordname?: string | null, Landlordtin?: string | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: string | null, Returnid?: any | null, Telephone?: string | null, Tenancystartdate?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }> };

export type GetPaginatedGraCorporateReturnPropertyRentQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraCorporateReturnPropertyRentQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnPropertyRent: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: string | null, Landlordname?: string | null, Landlordtin?: string | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: string | null, Returnid?: any | null, Telephone?: string | null, Tenancystartdate?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraCorporateReturnPropertyRentFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraCorporateReturnPropertyRentFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnPropertyRent: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: string | null, Landlordname?: string | null, Landlordtin?: string | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: string | null, Returnid?: any | null, Telephone?: string | null, Tenancystartdate?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null, Tin?: any | null }> };

export type GraCorporateReturnRentReceivedFragment = { __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: any | null, Landlordname?: string | null, Landlordtin?: any | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: any | null, Returnid?: any | null, Telephone?: string | null, Returnsbasetin?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null };

export type GetGraCorporateReturnRentReceivedQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraCorporateReturnRentReceivedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnRentReceived: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: any | null, Landlordname?: string | null, Landlordtin?: any | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: any | null, Returnid?: any | null, Telephone?: string | null, Returnsbasetin?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null }> };

export type GetPaginatedGraCorporateReturnRentReceivedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraCorporateReturnRentReceivedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnRentReceived: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: any | null, Landlordname?: string | null, Landlordtin?: any | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: any | null, Returnid?: any | null, Telephone?: string | null, Returnsbasetin?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null }>, rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraCorporateReturnRentReceivedFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraCorporateReturnRentReceivedFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_CorporateReturnRentReceived: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_CorporateReturnRentReceived', Annualrent?: any | null, Annualrentpaid?: any | null, Commercialused?: any | null, Landlordname?: string | null, Landlordtin?: any | null, Propertyaddress?: string | null, Rentid?: string | null, Residentialused?: any | null, Returnid?: any | null, Telephone?: string | null, Returnsbasetin?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayertin?: any | null }> };

export type GraIndividualReturnExemptionFragment = { __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption', Amount?: any | null, Basis?: string | null, Exemptionid?: string | null, Incometype?: any | null, Returnid?: any | null, Returnsbasetin?: any | null, Taxliability?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Taxrate?: any | null };

export type GetGraIndividualReturnExemptionQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraIndividualReturnExemptionQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_IndividualReturnExemption: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption', Amount?: any | null, Basis?: string | null, Exemptionid?: string | null, Incometype?: any | null, Returnid?: any | null, Returnsbasetin?: any | null, Taxliability?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Taxrate?: any | null }> };

export type GetPaginatedGraIndividualReturnExemptionQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraIndividualReturnExemptionQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_IndividualReturnExemption: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption', Amount?: any | null, Basis?: string | null, Exemptionid?: string | null, Incometype?: any | null, Returnid?: any | null, Returnsbasetin?: any | null, Taxliability?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Taxrate?: any | null }>, rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraIndividualReturnExemptionFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraIndividualReturnExemptionFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_IndividualReturnExemption: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturnExemption', Amount?: any | null, Basis?: string | null, Exemptionid?: string | null, Incometype?: any | null, Returnid?: any | null, Returnsbasetin?: any | null, Taxliability?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Taxrate?: any | null }> };

export type GraIndividualReturnFragment = { __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn', Accountingmethod?: string | null, Allowance?: any | null, Bonus?: any | null, Businessactivity?: string | null, Businessaddress?: string | null, Businessnature?: string | null, Capitalallowance?: any | null, Closingstock?: any | null, Commercialrent?: any | null, Commission?: any | null, Costofsale?: any | null, Directtaxpaid?: any | null, Employeraddress?: string | null, Employertin?: any | null, Employertin1?: string | null, Employertin2?: string | null, Exemptincome?: any | null, Exporter?: any | null, Foreigntaxcredit?: any | null, Fringebenefit?: any | null, Fringebenefittax?: any | null, Grossemolument?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Lossbroughtforward?: any | null, Netbusinessincome?: any | null, Openingstock?: any | null, Other?: any | null, Otherdeduction?: any | null, Otherexepense?: any | null, Otherincome?: any | null, Overpayment?: any | null, Overtime?: any | null, Pension?: any | null, Positionheld?: string | null, Principalbusiness?: string | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Returnsbasetin?: any | null, Salarywages?: any | null, Sicexemption?: any | null, Subtotal?: any | null, Subtotal1?: any | null, Tax1stschedule?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Totalnetincome?: any | null, Turnover?: any | null, Turnovertax?: any | null, Unallowwablededuction?: any | null, Withholdingtaxcredit?: any | null };

export type GetGraIndividualReturnQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetGraIndividualReturnQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_IndividualReturn: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn', Accountingmethod?: string | null, Allowance?: any | null, Bonus?: any | null, Businessactivity?: string | null, Businessaddress?: string | null, Businessnature?: string | null, Capitalallowance?: any | null, Closingstock?: any | null, Commercialrent?: any | null, Commission?: any | null, Costofsale?: any | null, Directtaxpaid?: any | null, Employeraddress?: string | null, Employertin?: any | null, Employertin1?: string | null, Employertin2?: string | null, Exemptincome?: any | null, Exporter?: any | null, Foreigntaxcredit?: any | null, Fringebenefit?: any | null, Fringebenefittax?: any | null, Grossemolument?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Lossbroughtforward?: any | null, Netbusinessincome?: any | null, Openingstock?: any | null, Other?: any | null, Otherdeduction?: any | null, Otherexepense?: any | null, Otherincome?: any | null, Overpayment?: any | null, Overtime?: any | null, Pension?: any | null, Positionheld?: string | null, Principalbusiness?: string | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Returnsbasetin?: any | null, Salarywages?: any | null, Sicexemption?: any | null, Subtotal?: any | null, Subtotal1?: any | null, Tax1stschedule?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Totalnetincome?: any | null, Turnover?: any | null, Turnovertax?: any | null, Unallowwablededuction?: any | null, Withholdingtaxcredit?: any | null }> };

export type GetPaginatedGraIndividualReturnQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedGraIndividualReturnQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_IndividualReturn: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn', Accountingmethod?: string | null, Allowance?: any | null, Bonus?: any | null, Businessactivity?: string | null, Businessaddress?: string | null, Businessnature?: string | null, Capitalallowance?: any | null, Closingstock?: any | null, Commercialrent?: any | null, Commission?: any | null, Costofsale?: any | null, Directtaxpaid?: any | null, Employeraddress?: string | null, Employertin?: any | null, Employertin1?: string | null, Employertin2?: string | null, Exemptincome?: any | null, Exporter?: any | null, Foreigntaxcredit?: any | null, Fringebenefit?: any | null, Fringebenefittax?: any | null, Grossemolument?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Lossbroughtforward?: any | null, Netbusinessincome?: any | null, Openingstock?: any | null, Other?: any | null, Otherdeduction?: any | null, Otherexepense?: any | null, Otherincome?: any | null, Overpayment?: any | null, Overtime?: any | null, Pension?: any | null, Positionheld?: string | null, Principalbusiness?: string | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Returnsbasetin?: any | null, Salarywages?: any | null, Sicexemption?: any | null, Subtotal?: any | null, Subtotal1?: any | null, Tax1stschedule?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Totalnetincome?: any | null, Turnover?: any | null, Turnovertax?: any | null, Unallowwablededuction?: any | null, Withholdingtaxcredit?: any | null }>, rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type GraIndividualReturnFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GraIndividualReturnFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_IndividualReturn: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_IndividualReturn', Accountingmethod?: string | null, Allowance?: any | null, Bonus?: any | null, Businessactivity?: string | null, Businessaddress?: string | null, Businessnature?: string | null, Capitalallowance?: any | null, Closingstock?: any | null, Commercialrent?: any | null, Commission?: any | null, Costofsale?: any | null, Directtaxpaid?: any | null, Employeraddress?: string | null, Employertin?: any | null, Employertin1?: string | null, Employertin2?: string | null, Exemptincome?: any | null, Exporter?: any | null, Foreigntaxcredit?: any | null, Fringebenefit?: any | null, Fringebenefittax?: any | null, Grossemolument?: any | null, Importer?: any | null, Instalmentpayment?: any | null, Lossbroughtforward?: any | null, Netbusinessincome?: any | null, Openingstock?: any | null, Other?: any | null, Otherdeduction?: any | null, Otherexepense?: any | null, Otherincome?: any | null, Overpayment?: any | null, Overtime?: any | null, Pension?: any | null, Positionheld?: string | null, Principalbusiness?: string | null, Purchase?: any | null, Returngroup?: any | null, Returnid?: string | null, Returnsbasetin?: any | null, Salarywages?: any | null, Sicexemption?: any | null, Subtotal?: any | null, Subtotal1?: any | null, Tax1stschedule?: any | null, Taxpayerfname?: string | null, Taxpayerlname?: string | null, Taxpayermname?: string | null, Taxpayertin?: any | null, Totalnetincome?: any | null, Turnover?: any | null, Turnovertax?: any | null, Unallowwablededuction?: any | null, Withholdingtaxcredit?: any | null }> };

export type PaymentRentalFragment = { __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental', Birthdate?: any | null, Centername?: string | null, Email?: string | null, Empemail?: string | null, Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Paymentdate?: any | null, Phoneno?: string | null, Phoneno2?: string | null, Physarea?: string | null, Regtypecode?: string | null, Taxcode?: any | null, Taxname?: string | null, Taxoffice?: any | null, Tin?: any | null };

export type PaymentRentalQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type PaymentRentalQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_PaymentRental: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental', Birthdate?: any | null, Centername?: string | null, Email?: string | null, Empemail?: string | null, Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Paymentdate?: any | null, Phoneno?: string | null, Phoneno2?: string | null, Physarea?: string | null, Regtypecode?: string | null, Taxcode?: any | null, Taxname?: string | null, Taxoffice?: any | null, Tin?: any | null }> };

export type PaymentRentalPaginatedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type PaymentRentalPaginatedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_PaymentRental: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental', Birthdate?: any | null, Centername?: string | null, Email?: string | null, Empemail?: string | null, Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Paymentdate?: any | null, Phoneno?: string | null, Phoneno2?: string | null, Physarea?: string | null, Regtypecode?: string | null, Taxcode?: any | null, Taxname?: string | null, Taxoffice?: any | null, Tin?: any | null }>, rtcs_gm_dev_Fngra_PaymentRental_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type PaymentRentalFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type PaymentRentalFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngra_PaymentRental: Array<{ __typename?: 'rtcs_gm_dev_PH_gra_PaymentRental', Birthdate?: any | null, Centername?: string | null, Email?: string | null, Empemail?: string | null, Fname?: string | null, Lname?: string | null, Paidamount?: any | null, Paymentdate?: any | null, Phoneno?: string | null, Phoneno2?: string | null, Physarea?: string | null, Regtypecode?: string | null, Taxcode?: any | null, Taxname?: string | null, Taxoffice?: any | null, Tin?: any | null }> };

export type MojMonthlyRegistrationsFragment = { __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations', Address?: string | null, Businessname?: string | null, Businesstype?: string | null, Dateofregistration?: any | null, Extractcriteria?: string | null, Mobile?: string | null, Srntin?: string | null };

export type GetMojMonthlyRegistrationsQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type GetMojMonthlyRegistrationsQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations', Address?: string | null, Businessname?: string | null, Businesstype?: string | null, Dateofregistration?: any | null, Extractcriteria?: string | null, Mobile?: string | null, Srntin?: string | null }> };

export type GetPaginatedMojMonthlyRegistrationsQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type GetPaginatedMojMonthlyRegistrationsQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations', Address?: string | null, Businessname?: string | null, Businesstype?: string | null, Dateofregistration?: any | null, Extractcriteria?: string | null, Mobile?: string | null, Srntin?: string | null }>, rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type MojMonthlyRegistrationsFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type MojMonthlyRegistrationsFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations', Address?: string | null, Businessname?: string | null, Businesstype?: string | null, Dateofregistration?: any | null, Extractcriteria?: string | null, Mobile?: string | null, Srntin?: string | null }> };

export type MobileCarrierSubscriberRegistrationsFragment = { __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations', Carrier?: string | null, Dob?: any | null, Gender?: string | null, Idno?: string | null, Idtype?: string | null, Lastname?: string | null, Mobile?: string | null, Name?: string | null, Registrationdate?: any | null };

export type MobileCarrierSubscriberRegistrationsQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type MobileCarrierSubscriberRegistrationsQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations', Carrier?: string | null, Dob?: any | null, Gender?: string | null, Idno?: string | null, Idtype?: string | null, Lastname?: string | null, Mobile?: string | null, Name?: string | null, Registrationdate?: any | null }> };

export type MobileCarrierSubscriberRegistrationsPaginatedQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type MobileCarrierSubscriberRegistrationsPaginatedQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations', Carrier?: string | null, Dob?: any | null, Gender?: string | null, Idno?: string | null, Idtype?: string | null, Lastname?: string | null, Mobile?: string | null, Name?: string | null, Registrationdate?: any | null }>, rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type MobileCarrierSubscriberRegistrationsFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type MobileCarrierSubscriberRegistrationsFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations: Array<{ __typename?: 'rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations', Carrier?: string | null, Dob?: any | null, Gender?: string | null, Idno?: string | null, Idtype?: string | null, Lastname?: string | null, Mobile?: string | null, Name?: string | null, Registrationdate?: any | null }> };

export const Africa360ViewFragmentDoc = gql`
    fragment Africa360View on rtcs_gm_dev_PH_africa360view_Africa360View {
  Buildingaream2
  Buildingcentroidgps
  Buildingtype
  Businessname
  Latitude
  Longitude
  Numberofstoreys
  Phone1
  Phone2
  Pluscode1
  Pluscode2
  Roofmaterial
  Settlement
  Street
  Uuid
  Wallmaterial
}
    `;
export const TradeLicenseRegisterFragmentDoc = gql`
    fragment TradeLicenseRegister on rtcs_gm_dev_PH_bcc_TradeLicenseRegister {
  Address
  Arrears
  Balance
  Certificateno
  Currentyear
  Id
  Invdate
  Invid
  Invno
  Licencetype
  Name
  Receiptdetails
  Totalinvoiceamount
  Totalreceipttodate
  Wrno
  Year
  Uuid
}
    `;
export const RegRatesFragmentDoc = gql`
    fragment RegRates on rtcs_gm_dev_PH_bcc_RegRates {
  Address
  Amountdue
  Arrears1
  Arrears2
  Arrears3
  Balance
  Contactno
  Current1
  Current2
  Current3
  Currentowner
  Date
  Demandnoteno
  Detailsofpayment1
  Detailsofpayment2
  Detailsofpayment3
  Lot
  Particulars
  Plandate
  Plandetails
  Rem
  Sm
  Total1
  Total2
  Total3
  Totalpaytodate1
  Totalpaytodate2
  Totalpaytodate3
  Uuid
  Wr
  Year
}
    `;
export const Gbos2022CommercialRentalRatesSurveyFragmentDoc = gql`
    fragment Gbos2022CommercialRentalRatesSurvey on rtcs_gm_dev_PH_gbos_2022CommercialRentalRatesSurvey {
  Address
  AddressOfLandlordagency
  AddressOfLandlordagent
  AmountPaid
  AmountPaidPerPeriod
  AnyMore
  BusinessActivitytype
  BusinessTelephone
  ContactOfLandlordagent
  ContactPersonsTel
  CurrencyUnit
  DurationInMonths
  FrequencyOfPayment
  IndividualId
  Lga
  NameOfBusiness
  NameOfLandlordagent
  NameOfLandlordagentForOtherBusinessPropertyElsewhere
  OtherBusinessPropertyRentedElsewhere
  OtherBusinessPropertyRentedElsewhereStatus
  OtherSpecifiedFrequencyPaymentArrangement
  OwnerOccupiedEstimatedValueOfRent
  PaymentFrequency
  PositionOfRespondentcontactPerson
  PropertyOwneradministrator
  RentPeriodArrangement
  SecondContact
  SecondTelOfContactPerson
  TelOfLandlordagent
  ThirdContact
  TinOfLandlordagent
  Uuid
}
    `;
export const Gbos2024PopulationAndHousingCensusFragmentDoc = gql`
    fragment Gbos2024PopulationAndHousingCensus on rtcs_gm_dev_PH_gbos_2024PopulationAndHousingCensus {
  Compoundowner
  CookingFuel
  Districtname
  FeelEviction
  FloorMaterial
  FreqSolidWasteCollection
  HEaCode
  HHeadPhone
  HHhHeadName
  HHhNumber
  HStrNumber
  Hdistrict
  Headofcompound
  Hlga
  Hsettlement
  Hward
  Latitude
  Lganame
  LiqWasteFromEconomic
  LiqWasteFromHhchores
  LiqWasteFromHumanConve
  Longitude
  NumOfFemale
  NumOfMale
  OccupancyStatus
  PayCompoundRate
  PlaceForBathing
  PlaceForCooking
  RoofMaterial
  SolidWasteDisposal
  SourceDrinkingWater
  SourceLighting
  SuitableForResidence
  TitleDeed
  ToiletFacility
  TotalNum
  Uuid
  WallsMaterial
  Wardname
}
    `;
export const NationalIdAndResidentialPermitRegistrationsFragmentDoc = gql`
    fragment NationalIDAndResidentialPermitRegistrations on rtcs_gm_dev_PH_gid_NationalIDAndResidentialPermitRegistrations {
  Address
  Cardnumbers
  Dateofbirth
  Driverlicencereceiptnumber
  Expirydate
  Fathersfirstname
  Fatherslastname
  Firstname
  Height
  Issuedate
  Lastname
  Mothersfirstname
  Motherslastname
  Nin
  Placeofbirth
  Proffesion
  Region
}
    `;
export const GraCommercialResidentialTaxFragmentDoc = gql`
    fragment GraCommercialResidentialTax on rtcs_gm_dev_PH_gra_CommericalResidentialTax {
  Fname
  Lname
  Paidamount
  Taxcode
  Taxmonth
  Taxname
  Taxyear
  Tin
  Uuid
}
    `;
export const CorporateReturnExemptionFragmentDoc = gql`
    fragment CorporateReturnExemption on rtcs_gm_dev_PH_gra_CorporateReturnExemption {
  Amount
  Basis
  Exemptionid
  Incometype
  LastUpdated
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
  Tin
  Returnid
  Taxliability
  Taxrate
}
    `;
export const CorporateReturnFragmentDoc = gql`
    fragment CorporateReturn on rtcs_gm_dev_PH_gra_CorporateReturn {
  Accural
  Additionalallowance
  Allowancebroughtforward
  Allowanceutilised
  Businessincome
  Capitalallowance
  Cashbank
  Closingstock
  Costofsale
  Creditor
  Debtor
  Depreciation
  Exemptincome
  Exporter
  Fixedasset
  Foreignexchangeloss
  Foreigntaxcredit
  Fringebenefittax
  Grossprofit
  Importer
  Instalmentpayment
  Interestexpense
  LastUpdated
  Line
  Longtermliability
  Lossbroughtforward
  Openingstock
  Operatingexpense
  Otherasset
  Othercurrentasset
  Othercurrentliability
  Otherdeduction
  Otherexpense
  Otherincome
  Otherliability
  Overpayment
  Propertyincome
  Purchase
  Returngroup
  Returnid
  Salarywage
  Sharehholderfund
  Sicexemption
  Stock
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
  Tin
  Tax1stschedule
  Totalasset
  Totalliability
  Turnovertax
  Unallowablededuction
  Unutilisedallowance
  Withholdingtaxcredit
  Yearallowance
}
    `;
export const CorporateReturnDirectorFragmentDoc = gql`
    fragment CorporateReturnDirector on rtcs_gm_dev_PH_gra_CorporateReturnDirector {
  Directorfee
  Directorname
  Directorremunerationid
  Otherallowance
  Returnid
  Salary
  Total
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
  Directortin
}
    `;
export const GraCorporateReturnLossFragmentDoc = gql`
    fragment GraCorporateReturnLoss on rtcs_gm_dev_PH_gra_CorporateReturnLoss {
  Lossid
  Lossmade
  Lossoutstanding
  Losssetoff
  Lossyear
  Returnid
  Yearleft
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
  Tin
}
    `;
export const GraCorporateReturnPartnershipFragmentDoc = gql`
    fragment GraCorporateReturnPartnership on rtcs_gm_dev_PH_gra_CorporateReturnPartnership {
  Interest
  Partnername
  Partnershipid
  Partnerstatus
  Returnid
  Salary
  Sharebalance
  Shareratio
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
  Tin
}
    `;
export const GraCorporateReturnPropertyRentFragmentDoc = gql`
    fragment GraCorporateReturnPropertyRent on rtcs_gm_dev_PH_gra_CorporateReturnPropertyRent {
  Annualrent
  Annualrentpaid
  Commercialused
  Landlordname
  Landlordtin
  Propertyaddress
  Rentid
  Residentialused
  Returnid
  Telephone
  Tenancystartdate
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
  Tin
}
    `;
export const GraCorporateReturnRentReceivedFragmentDoc = gql`
    fragment GraCorporateReturnRentReceived on rtcs_gm_dev_PH_gra_CorporateReturnRentReceived {
  Annualrent
  Annualrentpaid
  Commercialused
  Landlordname
  Landlordtin
  Propertyaddress
  Rentid
  Residentialused
  Returnid
  Telephone
  Returnsbasetin
  Taxpayerfname
  Taxpayerlname
  Taxpayertin
}
    `;
export const GraIndividualReturnExemptionFragmentDoc = gql`
    fragment GraIndividualReturnExemption on rtcs_gm_dev_PH_gra_IndividualReturnExemption {
  Amount
  Basis
  Exemptionid
  Incometype
  Returnid
  Returnsbasetin
  Taxliability
  Taxpayerfname
  Taxpayerlname
  Taxpayermname
  Taxpayertin
  Taxrate
}
    `;
export const GraIndividualReturnFragmentDoc = gql`
    fragment GraIndividualReturn on rtcs_gm_dev_PH_gra_IndividualReturn {
  Accountingmethod
  Allowance
  Bonus
  Businessactivity
  Businessaddress
  Businessnature
  Capitalallowance
  Closingstock
  Commercialrent
  Commission
  Costofsale
  Directtaxpaid
  Employeraddress
  Employertin
  Employertin1
  Employertin2
  Exemptincome
  Exporter
  Foreigntaxcredit
  Fringebenefit
  Fringebenefittax
  Grossemolument
  Importer
  Instalmentpayment
  Lossbroughtforward
  Netbusinessincome
  Openingstock
  Other
  Otherdeduction
  Otherexepense
  Otherincome
  Overpayment
  Overtime
  Pension
  Positionheld
  Principalbusiness
  Purchase
  Returngroup
  Returnid
  Returnsbasetin
  Salarywages
  Sicexemption
  Subtotal
  Subtotal1
  Tax1stschedule
  Taxpayerfname
  Taxpayerlname
  Taxpayermname
  Taxpayertin
  Totalnetincome
  Turnover
  Turnovertax
  Unallowwablededuction
  Withholdingtaxcredit
}
    `;
export const PaymentRentalFragmentDoc = gql`
    fragment PaymentRental on rtcs_gm_dev_PH_gra_PaymentRental {
  Birthdate
  Centername
  Email
  Empemail
  Fname
  Lname
  Paidamount
  Paymentdate
  Phoneno
  Phoneno2
  Physarea
  Regtypecode
  Taxcode
  Taxname
  Taxoffice
  Tin
}
    `;
export const MojMonthlyRegistrationsFragmentDoc = gql`
    fragment MOJMonthlyRegistrations on rtcs_gm_dev_PH_groc_MOJMonthlyRegistrations {
  Address
  Businessname
  Businesstype
  Dateofregistration
  Extractcriteria
  Mobile
  Srntin
}
    `;
export const MobileCarrierSubscriberRegistrationsFragmentDoc = gql`
    fragment MobileCarrierSubscriberRegistrations on rtcs_gm_dev_PH_pura_MobileCarrierSubscriberRegistrations {
  Carrier
  Dob
  Gender
  Idno
  Idtype
  Lastname
  Mobile
  Name
  Registrationdate
}
    `;
export const Africa360ViewDocument = gql`
    query Africa360View($params: String!) {
  rtcs_gm_dev_Fnafrica360view_Africa360View(args: {params: $params}) {
    ...Africa360View
  }
}
    ${Africa360ViewFragmentDoc}`;

/**
 * __useAfrica360ViewQuery__
 *
 * To run a query within a React component, call `useAfrica360ViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAfrica360ViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAfrica360ViewQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAfrica360ViewQuery(baseOptions: Apollo.QueryHookOptions<Africa360ViewQuery, Africa360ViewQueryVariables> & ({ variables: Africa360ViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Africa360ViewQuery, Africa360ViewQueryVariables>(Africa360ViewDocument, options);
      }
export function useAfrica360ViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Africa360ViewQuery, Africa360ViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Africa360ViewQuery, Africa360ViewQueryVariables>(Africa360ViewDocument, options);
        }
export function useAfrica360ViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Africa360ViewQuery, Africa360ViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Africa360ViewQuery, Africa360ViewQueryVariables>(Africa360ViewDocument, options);
        }
export type Africa360ViewQueryHookResult = ReturnType<typeof useAfrica360ViewQuery>;
export type Africa360ViewLazyQueryHookResult = ReturnType<typeof useAfrica360ViewLazyQuery>;
export type Africa360ViewSuspenseQueryHookResult = ReturnType<typeof useAfrica360ViewSuspenseQuery>;
export type Africa360ViewQueryResult = Apollo.QueryResult<Africa360ViewQuery, Africa360ViewQueryVariables>;
export const PaginatedAfrica360ViewDocument = gql`
    query paginatedAfrica360View($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fnafrica360view_Africa360View(args: {params: $params}) {
    ...Africa360View
  }
  rtcs_gm_dev_Fnafrica360view_Africa360View_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${Africa360ViewFragmentDoc}`;

/**
 * __usePaginatedAfrica360ViewQuery__
 *
 * To run a query within a React component, call `usePaginatedAfrica360ViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAfrica360ViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAfrica360ViewQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function usePaginatedAfrica360ViewQuery(baseOptions: Apollo.QueryHookOptions<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables> & ({ variables: PaginatedAfrica360ViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables>(PaginatedAfrica360ViewDocument, options);
      }
export function usePaginatedAfrica360ViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables>(PaginatedAfrica360ViewDocument, options);
        }
export function usePaginatedAfrica360ViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables>(PaginatedAfrica360ViewDocument, options);
        }
export type PaginatedAfrica360ViewQueryHookResult = ReturnType<typeof usePaginatedAfrica360ViewQuery>;
export type PaginatedAfrica360ViewLazyQueryHookResult = ReturnType<typeof usePaginatedAfrica360ViewLazyQuery>;
export type PaginatedAfrica360ViewSuspenseQueryHookResult = ReturnType<typeof usePaginatedAfrica360ViewSuspenseQuery>;
export type PaginatedAfrica360ViewQueryResult = Apollo.QueryResult<PaginatedAfrica360ViewQuery, PaginatedAfrica360ViewQueryVariables>;
export const Africa360ViewFilterDocument = gql`
    query Africa360ViewFilter($params: String!) {
  rtcs_gm_dev_Fnafrica360view_Africa360View(args: {params: $params}) {
    ...Africa360View
  }
}
    ${Africa360ViewFragmentDoc}`;

/**
 * __useAfrica360ViewFilterQuery__
 *
 * To run a query within a React component, call `useAfrica360ViewFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAfrica360ViewFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAfrica360ViewFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAfrica360ViewFilterQuery(baseOptions: Apollo.QueryHookOptions<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables> & ({ variables: Africa360ViewFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables>(Africa360ViewFilterDocument, options);
      }
export function useAfrica360ViewFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables>(Africa360ViewFilterDocument, options);
        }
export function useAfrica360ViewFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables>(Africa360ViewFilterDocument, options);
        }
export type Africa360ViewFilterQueryHookResult = ReturnType<typeof useAfrica360ViewFilterQuery>;
export type Africa360ViewFilterLazyQueryHookResult = ReturnType<typeof useAfrica360ViewFilterLazyQuery>;
export type Africa360ViewFilterSuspenseQueryHookResult = ReturnType<typeof useAfrica360ViewFilterSuspenseQuery>;
export type Africa360ViewFilterQueryResult = Apollo.QueryResult<Africa360ViewFilterQuery, Africa360ViewFilterQueryVariables>;
export const GetTradeLicenseRegisterDocument = gql`
    query GetTradeLicenseRegister($params: String!) {
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister(args: {params: $params}) {
    ...TradeLicenseRegister
  }
}
    ${TradeLicenseRegisterFragmentDoc}`;

/**
 * __useGetTradeLicenseRegisterQuery__
 *
 * To run a query within a React component, call `useGetTradeLicenseRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeLicenseRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeLicenseRegisterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetTradeLicenseRegisterQuery(baseOptions: Apollo.QueryHookOptions<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables> & ({ variables: GetTradeLicenseRegisterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables>(GetTradeLicenseRegisterDocument, options);
      }
export function useGetTradeLicenseRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables>(GetTradeLicenseRegisterDocument, options);
        }
export function useGetTradeLicenseRegisterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables>(GetTradeLicenseRegisterDocument, options);
        }
export type GetTradeLicenseRegisterQueryHookResult = ReturnType<typeof useGetTradeLicenseRegisterQuery>;
export type GetTradeLicenseRegisterLazyQueryHookResult = ReturnType<typeof useGetTradeLicenseRegisterLazyQuery>;
export type GetTradeLicenseRegisterSuspenseQueryHookResult = ReturnType<typeof useGetTradeLicenseRegisterSuspenseQuery>;
export type GetTradeLicenseRegisterQueryResult = Apollo.QueryResult<GetTradeLicenseRegisterQuery, GetTradeLicenseRegisterQueryVariables>;
export const GetPaginatedTradeLicenseRegisterDocument = gql`
    query GetPaginatedTradeLicenseRegister($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister(args: {params: $params}) {
    ...TradeLicenseRegister
  }
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${TradeLicenseRegisterFragmentDoc}`;

/**
 * __useGetPaginatedTradeLicenseRegisterQuery__
 *
 * To run a query within a React component, call `useGetPaginatedTradeLicenseRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedTradeLicenseRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedTradeLicenseRegisterQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedTradeLicenseRegisterQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables> & ({ variables: GetPaginatedTradeLicenseRegisterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables>(GetPaginatedTradeLicenseRegisterDocument, options);
      }
export function useGetPaginatedTradeLicenseRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables>(GetPaginatedTradeLicenseRegisterDocument, options);
        }
export function useGetPaginatedTradeLicenseRegisterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables>(GetPaginatedTradeLicenseRegisterDocument, options);
        }
export type GetPaginatedTradeLicenseRegisterQueryHookResult = ReturnType<typeof useGetPaginatedTradeLicenseRegisterQuery>;
export type GetPaginatedTradeLicenseRegisterLazyQueryHookResult = ReturnType<typeof useGetPaginatedTradeLicenseRegisterLazyQuery>;
export type GetPaginatedTradeLicenseRegisterSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedTradeLicenseRegisterSuspenseQuery>;
export type GetPaginatedTradeLicenseRegisterQueryResult = Apollo.QueryResult<GetPaginatedTradeLicenseRegisterQuery, GetPaginatedTradeLicenseRegisterQueryVariables>;
export const TradeLicenseRegisterFilterDocument = gql`
    query TradeLicenseRegisterFilter($params: String!) {
  rtcs_gm_dev_Fnbcc_TradeLicenseRegister(args: {params: $params}) {
    ...TradeLicenseRegister
  }
}
    ${TradeLicenseRegisterFragmentDoc}`;

/**
 * __useTradeLicenseRegisterFilterQuery__
 *
 * To run a query within a React component, call `useTradeLicenseRegisterFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeLicenseRegisterFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeLicenseRegisterFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useTradeLicenseRegisterFilterQuery(baseOptions: Apollo.QueryHookOptions<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables> & ({ variables: TradeLicenseRegisterFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables>(TradeLicenseRegisterFilterDocument, options);
      }
export function useTradeLicenseRegisterFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables>(TradeLicenseRegisterFilterDocument, options);
        }
export function useTradeLicenseRegisterFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables>(TradeLicenseRegisterFilterDocument, options);
        }
export type TradeLicenseRegisterFilterQueryHookResult = ReturnType<typeof useTradeLicenseRegisterFilterQuery>;
export type TradeLicenseRegisterFilterLazyQueryHookResult = ReturnType<typeof useTradeLicenseRegisterFilterLazyQuery>;
export type TradeLicenseRegisterFilterSuspenseQueryHookResult = ReturnType<typeof useTradeLicenseRegisterFilterSuspenseQuery>;
export type TradeLicenseRegisterFilterQueryResult = Apollo.QueryResult<TradeLicenseRegisterFilterQuery, TradeLicenseRegisterFilterQueryVariables>;
export const GetRegRatesDocument = gql`
    query GetRegRates($params: String!) {
  rtcs_gm_dev_Fnbcc_RegRates(args: {params: $params}) {
    ...RegRates
  }
}
    ${RegRatesFragmentDoc}`;

/**
 * __useGetRegRatesQuery__
 *
 * To run a query within a React component, call `useGetRegRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegRatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetRegRatesQuery(baseOptions: Apollo.QueryHookOptions<GetRegRatesQuery, GetRegRatesQueryVariables> & ({ variables: GetRegRatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegRatesQuery, GetRegRatesQueryVariables>(GetRegRatesDocument, options);
      }
export function useGetRegRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegRatesQuery, GetRegRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegRatesQuery, GetRegRatesQueryVariables>(GetRegRatesDocument, options);
        }
export function useGetRegRatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRegRatesQuery, GetRegRatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegRatesQuery, GetRegRatesQueryVariables>(GetRegRatesDocument, options);
        }
export type GetRegRatesQueryHookResult = ReturnType<typeof useGetRegRatesQuery>;
export type GetRegRatesLazyQueryHookResult = ReturnType<typeof useGetRegRatesLazyQuery>;
export type GetRegRatesSuspenseQueryHookResult = ReturnType<typeof useGetRegRatesSuspenseQuery>;
export type GetRegRatesQueryResult = Apollo.QueryResult<GetRegRatesQuery, GetRegRatesQueryVariables>;
export const GetPaginatedRegRatesDocument = gql`
    query GetPaginatedRegRates($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fnbcc_RegRates(args: {params: $params}) {
    ...RegRates
  }
  rtcs_gm_dev_Fnbcc_RegRates_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${RegRatesFragmentDoc}`;

/**
 * __useGetPaginatedRegRatesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegRatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRegRatesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables> & ({ variables: GetPaginatedRegRatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables>(GetPaginatedRegRatesDocument, options);
      }
export function useGetPaginatedRegRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables>(GetPaginatedRegRatesDocument, options);
        }
export function useGetPaginatedRegRatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables>(GetPaginatedRegRatesDocument, options);
        }
export type GetPaginatedRegRatesQueryHookResult = ReturnType<typeof useGetPaginatedRegRatesQuery>;
export type GetPaginatedRegRatesLazyQueryHookResult = ReturnType<typeof useGetPaginatedRegRatesLazyQuery>;
export type GetPaginatedRegRatesSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedRegRatesSuspenseQuery>;
export type GetPaginatedRegRatesQueryResult = Apollo.QueryResult<GetPaginatedRegRatesQuery, GetPaginatedRegRatesQueryVariables>;
export const RegRatesFilterDocument = gql`
    query RegRatesFilter($params: String!) {
  rtcs_gm_dev_Fnbcc_RegRates(args: {params: $params}) {
    ...RegRates
  }
}
    ${RegRatesFragmentDoc}`;

/**
 * __useRegRatesFilterQuery__
 *
 * To run a query within a React component, call `useRegRatesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegRatesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegRatesFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegRatesFilterQuery(baseOptions: Apollo.QueryHookOptions<RegRatesFilterQuery, RegRatesFilterQueryVariables> & ({ variables: RegRatesFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegRatesFilterQuery, RegRatesFilterQueryVariables>(RegRatesFilterDocument, options);
      }
export function useRegRatesFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegRatesFilterQuery, RegRatesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegRatesFilterQuery, RegRatesFilterQueryVariables>(RegRatesFilterDocument, options);
        }
export function useRegRatesFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RegRatesFilterQuery, RegRatesFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RegRatesFilterQuery, RegRatesFilterQueryVariables>(RegRatesFilterDocument, options);
        }
export type RegRatesFilterQueryHookResult = ReturnType<typeof useRegRatesFilterQuery>;
export type RegRatesFilterLazyQueryHookResult = ReturnType<typeof useRegRatesFilterLazyQuery>;
export type RegRatesFilterSuspenseQueryHookResult = ReturnType<typeof useRegRatesFilterSuspenseQuery>;
export type RegRatesFilterQueryResult = Apollo.QueryResult<RegRatesFilterQuery, RegRatesFilterQueryVariables>;
export const GetGbos2022CommercialRentalRatesSurveyDocument = gql`
    query GetGbos2022CommercialRentalRatesSurvey($params: String!) {
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey(args: {params: $params}) {
    ...Gbos2022CommercialRentalRatesSurvey
  }
}
    ${Gbos2022CommercialRentalRatesSurveyFragmentDoc}`;

/**
 * __useGetGbos2022CommercialRentalRatesSurveyQuery__
 *
 * To run a query within a React component, call `useGetGbos2022CommercialRentalRatesSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGbos2022CommercialRentalRatesSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGbos2022CommercialRentalRatesSurveyQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGbos2022CommercialRentalRatesSurveyQuery(baseOptions: Apollo.QueryHookOptions<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables> & ({ variables: GetGbos2022CommercialRentalRatesSurveyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables>(GetGbos2022CommercialRentalRatesSurveyDocument, options);
      }
export function useGetGbos2022CommercialRentalRatesSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables>(GetGbos2022CommercialRentalRatesSurveyDocument, options);
        }
export function useGetGbos2022CommercialRentalRatesSurveySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables>(GetGbos2022CommercialRentalRatesSurveyDocument, options);
        }
export type GetGbos2022CommercialRentalRatesSurveyQueryHookResult = ReturnType<typeof useGetGbos2022CommercialRentalRatesSurveyQuery>;
export type GetGbos2022CommercialRentalRatesSurveyLazyQueryHookResult = ReturnType<typeof useGetGbos2022CommercialRentalRatesSurveyLazyQuery>;
export type GetGbos2022CommercialRentalRatesSurveySuspenseQueryHookResult = ReturnType<typeof useGetGbos2022CommercialRentalRatesSurveySuspenseQuery>;
export type GetGbos2022CommercialRentalRatesSurveyQueryResult = Apollo.QueryResult<GetGbos2022CommercialRentalRatesSurveyQuery, GetGbos2022CommercialRentalRatesSurveyQueryVariables>;
export const GetPaginatedGbos2022CommercialRentalRatesSurveyDocument = gql`
    query GetPaginatedGbos2022CommercialRentalRatesSurvey($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey(args: {params: $params}) {
    ...Gbos2022CommercialRentalRatesSurvey
  }
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${Gbos2022CommercialRentalRatesSurveyFragmentDoc}`;

/**
 * __useGetPaginatedGbos2022CommercialRentalRatesSurveyQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGbos2022CommercialRentalRatesSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGbos2022CommercialRentalRatesSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGbos2022CommercialRentalRatesSurveyQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGbos2022CommercialRentalRatesSurveyQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables> & ({ variables: GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables>(GetPaginatedGbos2022CommercialRentalRatesSurveyDocument, options);
      }
export function useGetPaginatedGbos2022CommercialRentalRatesSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables>(GetPaginatedGbos2022CommercialRentalRatesSurveyDocument, options);
        }
export function useGetPaginatedGbos2022CommercialRentalRatesSurveySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables>(GetPaginatedGbos2022CommercialRentalRatesSurveyDocument, options);
        }
export type GetPaginatedGbos2022CommercialRentalRatesSurveyQueryHookResult = ReturnType<typeof useGetPaginatedGbos2022CommercialRentalRatesSurveyQuery>;
export type GetPaginatedGbos2022CommercialRentalRatesSurveyLazyQueryHookResult = ReturnType<typeof useGetPaginatedGbos2022CommercialRentalRatesSurveyLazyQuery>;
export type GetPaginatedGbos2022CommercialRentalRatesSurveySuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGbos2022CommercialRentalRatesSurveySuspenseQuery>;
export type GetPaginatedGbos2022CommercialRentalRatesSurveyQueryResult = Apollo.QueryResult<GetPaginatedGbos2022CommercialRentalRatesSurveyQuery, GetPaginatedGbos2022CommercialRentalRatesSurveyQueryVariables>;
export const GetGbos2022CommercialRentalRatesSurveyFilterDocument = gql`
    query GetGbos2022CommercialRentalRatesSurveyFilter($params: String!) {
  rtcs_gm_dev_Fngbos_2022CommercialRentalRatesSurvey(args: {params: $params}) {
    ...Gbos2022CommercialRentalRatesSurvey
  }
}
    ${Gbos2022CommercialRentalRatesSurveyFragmentDoc}`;

/**
 * __useGetGbos2022CommercialRentalRatesSurveyFilterQuery__
 *
 * To run a query within a React component, call `useGetGbos2022CommercialRentalRatesSurveyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGbos2022CommercialRentalRatesSurveyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGbos2022CommercialRentalRatesSurveyFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGbos2022CommercialRentalRatesSurveyFilterQuery(baseOptions: Apollo.QueryHookOptions<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables> & ({ variables: GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables>(GetGbos2022CommercialRentalRatesSurveyFilterDocument, options);
      }
export function useGetGbos2022CommercialRentalRatesSurveyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables>(GetGbos2022CommercialRentalRatesSurveyFilterDocument, options);
        }
export function useGetGbos2022CommercialRentalRatesSurveyFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables>(GetGbos2022CommercialRentalRatesSurveyFilterDocument, options);
        }
export type GetGbos2022CommercialRentalRatesSurveyFilterQueryHookResult = ReturnType<typeof useGetGbos2022CommercialRentalRatesSurveyFilterQuery>;
export type GetGbos2022CommercialRentalRatesSurveyFilterLazyQueryHookResult = ReturnType<typeof useGetGbos2022CommercialRentalRatesSurveyFilterLazyQuery>;
export type GetGbos2022CommercialRentalRatesSurveyFilterSuspenseQueryHookResult = ReturnType<typeof useGetGbos2022CommercialRentalRatesSurveyFilterSuspenseQuery>;
export type GetGbos2022CommercialRentalRatesSurveyFilterQueryResult = Apollo.QueryResult<GetGbos2022CommercialRentalRatesSurveyFilterQuery, GetGbos2022CommercialRentalRatesSurveyFilterQueryVariables>;
export const GetGbos2024PopulationAndHousingCensusDocument = gql`
    query GetGbos2024PopulationAndHousingCensus($params: String!) {
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus(args: {params: $params}) {
    ...Gbos2024PopulationAndHousingCensus
  }
}
    ${Gbos2024PopulationAndHousingCensusFragmentDoc}`;

/**
 * __useGetGbos2024PopulationAndHousingCensusQuery__
 *
 * To run a query within a React component, call `useGetGbos2024PopulationAndHousingCensusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGbos2024PopulationAndHousingCensusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGbos2024PopulationAndHousingCensusQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGbos2024PopulationAndHousingCensusQuery(baseOptions: Apollo.QueryHookOptions<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables> & ({ variables: GetGbos2024PopulationAndHousingCensusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables>(GetGbos2024PopulationAndHousingCensusDocument, options);
      }
export function useGetGbos2024PopulationAndHousingCensusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables>(GetGbos2024PopulationAndHousingCensusDocument, options);
        }
export function useGetGbos2024PopulationAndHousingCensusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables>(GetGbos2024PopulationAndHousingCensusDocument, options);
        }
export type GetGbos2024PopulationAndHousingCensusQueryHookResult = ReturnType<typeof useGetGbos2024PopulationAndHousingCensusQuery>;
export type GetGbos2024PopulationAndHousingCensusLazyQueryHookResult = ReturnType<typeof useGetGbos2024PopulationAndHousingCensusLazyQuery>;
export type GetGbos2024PopulationAndHousingCensusSuspenseQueryHookResult = ReturnType<typeof useGetGbos2024PopulationAndHousingCensusSuspenseQuery>;
export type GetGbos2024PopulationAndHousingCensusQueryResult = Apollo.QueryResult<GetGbos2024PopulationAndHousingCensusQuery, GetGbos2024PopulationAndHousingCensusQueryVariables>;
export const GetPaginatedGbos2024PopulationAndHousingCensusDocument = gql`
    query GetPaginatedGbos2024PopulationAndHousingCensus($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus(args: {params: $params}) {
    ...Gbos2024PopulationAndHousingCensus
  }
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${Gbos2024PopulationAndHousingCensusFragmentDoc}`;

/**
 * __useGetPaginatedGbos2024PopulationAndHousingCensusQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGbos2024PopulationAndHousingCensusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGbos2024PopulationAndHousingCensusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGbos2024PopulationAndHousingCensusQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGbos2024PopulationAndHousingCensusQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables> & ({ variables: GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables>(GetPaginatedGbos2024PopulationAndHousingCensusDocument, options);
      }
export function useGetPaginatedGbos2024PopulationAndHousingCensusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables>(GetPaginatedGbos2024PopulationAndHousingCensusDocument, options);
        }
export function useGetPaginatedGbos2024PopulationAndHousingCensusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables>(GetPaginatedGbos2024PopulationAndHousingCensusDocument, options);
        }
export type GetPaginatedGbos2024PopulationAndHousingCensusQueryHookResult = ReturnType<typeof useGetPaginatedGbos2024PopulationAndHousingCensusQuery>;
export type GetPaginatedGbos2024PopulationAndHousingCensusLazyQueryHookResult = ReturnType<typeof useGetPaginatedGbos2024PopulationAndHousingCensusLazyQuery>;
export type GetPaginatedGbos2024PopulationAndHousingCensusSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGbos2024PopulationAndHousingCensusSuspenseQuery>;
export type GetPaginatedGbos2024PopulationAndHousingCensusQueryResult = Apollo.QueryResult<GetPaginatedGbos2024PopulationAndHousingCensusQuery, GetPaginatedGbos2024PopulationAndHousingCensusQueryVariables>;
export const GetGbos2024PopulationAndHousingCensusFilterDocument = gql`
    query GetGbos2024PopulationAndHousingCensusFilter($params: String!) {
  rtcs_gm_dev_Fngbos_2024PopulationAndHousingCensus(args: {params: $params}) {
    ...Gbos2024PopulationAndHousingCensus
  }
}
    ${Gbos2024PopulationAndHousingCensusFragmentDoc}`;

/**
 * __useGetGbos2024PopulationAndHousingCensusFilterQuery__
 *
 * To run a query within a React component, call `useGetGbos2024PopulationAndHousingCensusFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGbos2024PopulationAndHousingCensusFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGbos2024PopulationAndHousingCensusFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGbos2024PopulationAndHousingCensusFilterQuery(baseOptions: Apollo.QueryHookOptions<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables> & ({ variables: GetGbos2024PopulationAndHousingCensusFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables>(GetGbos2024PopulationAndHousingCensusFilterDocument, options);
      }
export function useGetGbos2024PopulationAndHousingCensusFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables>(GetGbos2024PopulationAndHousingCensusFilterDocument, options);
        }
export function useGetGbos2024PopulationAndHousingCensusFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables>(GetGbos2024PopulationAndHousingCensusFilterDocument, options);
        }
export type GetGbos2024PopulationAndHousingCensusFilterQueryHookResult = ReturnType<typeof useGetGbos2024PopulationAndHousingCensusFilterQuery>;
export type GetGbos2024PopulationAndHousingCensusFilterLazyQueryHookResult = ReturnType<typeof useGetGbos2024PopulationAndHousingCensusFilterLazyQuery>;
export type GetGbos2024PopulationAndHousingCensusFilterSuspenseQueryHookResult = ReturnType<typeof useGetGbos2024PopulationAndHousingCensusFilterSuspenseQuery>;
export type GetGbos2024PopulationAndHousingCensusFilterQueryResult = Apollo.QueryResult<GetGbos2024PopulationAndHousingCensusFilterQuery, GetGbos2024PopulationAndHousingCensusFilterQueryVariables>;
export const NationalIdAndResidentialPermitRegistrationsDocument = gql`
    query NationalIDAndResidentialPermitRegistrations($params: String!) {
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations(
    args: {params: $params}
  ) {
    ...NationalIDAndResidentialPermitRegistrations
  }
}
    ${NationalIdAndResidentialPermitRegistrationsFragmentDoc}`;

/**
 * __useNationalIdAndResidentialPermitRegistrationsQuery__
 *
 * To run a query within a React component, call `useNationalIdAndResidentialPermitRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalIdAndResidentialPermitRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalIdAndResidentialPermitRegistrationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNationalIdAndResidentialPermitRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables> & ({ variables: NationalIdAndResidentialPermitRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables>(NationalIdAndResidentialPermitRegistrationsDocument, options);
      }
export function useNationalIdAndResidentialPermitRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables>(NationalIdAndResidentialPermitRegistrationsDocument, options);
        }
export function useNationalIdAndResidentialPermitRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables>(NationalIdAndResidentialPermitRegistrationsDocument, options);
        }
export type NationalIdAndResidentialPermitRegistrationsQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsQuery>;
export type NationalIdAndResidentialPermitRegistrationsLazyQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsLazyQuery>;
export type NationalIdAndResidentialPermitRegistrationsSuspenseQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsSuspenseQuery>;
export type NationalIdAndResidentialPermitRegistrationsQueryResult = Apollo.QueryResult<NationalIdAndResidentialPermitRegistrationsQuery, NationalIdAndResidentialPermitRegistrationsQueryVariables>;
export const NationalIdAndResidentialPermitRegistrationsPaginatedDocument = gql`
    query NationalIDAndResidentialPermitRegistrationsPaginated($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations(
    args: {params: $params}
  ) {
    ...NationalIDAndResidentialPermitRegistrations
  }
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${NationalIdAndResidentialPermitRegistrationsFragmentDoc}`;

/**
 * __useNationalIdAndResidentialPermitRegistrationsPaginatedQuery__
 *
 * To run a query within a React component, call `useNationalIdAndResidentialPermitRegistrationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalIdAndResidentialPermitRegistrationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalIdAndResidentialPermitRegistrationsPaginatedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useNationalIdAndResidentialPermitRegistrationsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables> & ({ variables: NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables>(NationalIdAndResidentialPermitRegistrationsPaginatedDocument, options);
      }
export function useNationalIdAndResidentialPermitRegistrationsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables>(NationalIdAndResidentialPermitRegistrationsPaginatedDocument, options);
        }
export function useNationalIdAndResidentialPermitRegistrationsPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables>(NationalIdAndResidentialPermitRegistrationsPaginatedDocument, options);
        }
export type NationalIdAndResidentialPermitRegistrationsPaginatedQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsPaginatedQuery>;
export type NationalIdAndResidentialPermitRegistrationsPaginatedLazyQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsPaginatedLazyQuery>;
export type NationalIdAndResidentialPermitRegistrationsPaginatedSuspenseQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsPaginatedSuspenseQuery>;
export type NationalIdAndResidentialPermitRegistrationsPaginatedQueryResult = Apollo.QueryResult<NationalIdAndResidentialPermitRegistrationsPaginatedQuery, NationalIdAndResidentialPermitRegistrationsPaginatedQueryVariables>;
export const NationalIdAndResidentialPermitRegistrationsFilterDocument = gql`
    query NationalIDAndResidentialPermitRegistrationsFilter($params: String!) {
  rtcs_gm_dev_Fngid_NationalIDAndResidentialPermitRegistrations(
    args: {params: $params}
  ) {
    ...NationalIDAndResidentialPermitRegistrations
  }
}
    ${NationalIdAndResidentialPermitRegistrationsFragmentDoc}`;

/**
 * __useNationalIdAndResidentialPermitRegistrationsFilterQuery__
 *
 * To run a query within a React component, call `useNationalIdAndResidentialPermitRegistrationsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalIdAndResidentialPermitRegistrationsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalIdAndResidentialPermitRegistrationsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNationalIdAndResidentialPermitRegistrationsFilterQuery(baseOptions: Apollo.QueryHookOptions<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables> & ({ variables: NationalIdAndResidentialPermitRegistrationsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables>(NationalIdAndResidentialPermitRegistrationsFilterDocument, options);
      }
export function useNationalIdAndResidentialPermitRegistrationsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables>(NationalIdAndResidentialPermitRegistrationsFilterDocument, options);
        }
export function useNationalIdAndResidentialPermitRegistrationsFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables>(NationalIdAndResidentialPermitRegistrationsFilterDocument, options);
        }
export type NationalIdAndResidentialPermitRegistrationsFilterQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsFilterQuery>;
export type NationalIdAndResidentialPermitRegistrationsFilterLazyQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsFilterLazyQuery>;
export type NationalIdAndResidentialPermitRegistrationsFilterSuspenseQueryHookResult = ReturnType<typeof useNationalIdAndResidentialPermitRegistrationsFilterSuspenseQuery>;
export type NationalIdAndResidentialPermitRegistrationsFilterQueryResult = Apollo.QueryResult<NationalIdAndResidentialPermitRegistrationsFilterQuery, NationalIdAndResidentialPermitRegistrationsFilterQueryVariables>;
export const GetGraCommercialResidentialTaxDocument = gql`
    query GetGraCommercialResidentialTax($params: String!) {
  rtcs_gm_dev_Fngra_CommericalResidentialTax(args: {params: $params}) {
    ...GraCommercialResidentialTax
  }
}
    ${GraCommercialResidentialTaxFragmentDoc}`;

/**
 * __useGetGraCommercialResidentialTaxQuery__
 *
 * To run a query within a React component, call `useGetGraCommercialResidentialTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraCommercialResidentialTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraCommercialResidentialTaxQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraCommercialResidentialTaxQuery(baseOptions: Apollo.QueryHookOptions<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables> & ({ variables: GetGraCommercialResidentialTaxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables>(GetGraCommercialResidentialTaxDocument, options);
      }
export function useGetGraCommercialResidentialTaxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables>(GetGraCommercialResidentialTaxDocument, options);
        }
export function useGetGraCommercialResidentialTaxSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables>(GetGraCommercialResidentialTaxDocument, options);
        }
export type GetGraCommercialResidentialTaxQueryHookResult = ReturnType<typeof useGetGraCommercialResidentialTaxQuery>;
export type GetGraCommercialResidentialTaxLazyQueryHookResult = ReturnType<typeof useGetGraCommercialResidentialTaxLazyQuery>;
export type GetGraCommercialResidentialTaxSuspenseQueryHookResult = ReturnType<typeof useGetGraCommercialResidentialTaxSuspenseQuery>;
export type GetGraCommercialResidentialTaxQueryResult = Apollo.QueryResult<GetGraCommercialResidentialTaxQuery, GetGraCommercialResidentialTaxQueryVariables>;
export const GetPaginatedGraCommercialResidentialTaxDocument = gql`
    query GetPaginatedGraCommercialResidentialTax($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CommericalResidentialTax(args: {params: $params}) {
    ...GraCommercialResidentialTax
  }
  rtcs_gm_dev_Fngra_CommericalResidentialTax_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraCommercialResidentialTaxFragmentDoc}`;

/**
 * __useGetPaginatedGraCommercialResidentialTaxQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraCommercialResidentialTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraCommercialResidentialTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraCommercialResidentialTaxQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraCommercialResidentialTaxQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables> & ({ variables: GetPaginatedGraCommercialResidentialTaxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables>(GetPaginatedGraCommercialResidentialTaxDocument, options);
      }
export function useGetPaginatedGraCommercialResidentialTaxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables>(GetPaginatedGraCommercialResidentialTaxDocument, options);
        }
export function useGetPaginatedGraCommercialResidentialTaxSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables>(GetPaginatedGraCommercialResidentialTaxDocument, options);
        }
export type GetPaginatedGraCommercialResidentialTaxQueryHookResult = ReturnType<typeof useGetPaginatedGraCommercialResidentialTaxQuery>;
export type GetPaginatedGraCommercialResidentialTaxLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraCommercialResidentialTaxLazyQuery>;
export type GetPaginatedGraCommercialResidentialTaxSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraCommercialResidentialTaxSuspenseQuery>;
export type GetPaginatedGraCommercialResidentialTaxQueryResult = Apollo.QueryResult<GetPaginatedGraCommercialResidentialTaxQuery, GetPaginatedGraCommercialResidentialTaxQueryVariables>;
export const GraCommercialResidentialTaxFilterDocument = gql`
    query GraCommercialResidentialTaxFilter($params: String!) {
  rtcs_gm_dev_Fngra_CommericalResidentialTax(args: {params: $params}) {
    ...GraCommercialResidentialTax
  }
}
    ${GraCommercialResidentialTaxFragmentDoc}`;

/**
 * __useGraCommercialResidentialTaxFilterQuery__
 *
 * To run a query within a React component, call `useGraCommercialResidentialTaxFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraCommercialResidentialTaxFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraCommercialResidentialTaxFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraCommercialResidentialTaxFilterQuery(baseOptions: Apollo.QueryHookOptions<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables> & ({ variables: GraCommercialResidentialTaxFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables>(GraCommercialResidentialTaxFilterDocument, options);
      }
export function useGraCommercialResidentialTaxFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables>(GraCommercialResidentialTaxFilterDocument, options);
        }
export function useGraCommercialResidentialTaxFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables>(GraCommercialResidentialTaxFilterDocument, options);
        }
export type GraCommercialResidentialTaxFilterQueryHookResult = ReturnType<typeof useGraCommercialResidentialTaxFilterQuery>;
export type GraCommercialResidentialTaxFilterLazyQueryHookResult = ReturnType<typeof useGraCommercialResidentialTaxFilterLazyQuery>;
export type GraCommercialResidentialTaxFilterSuspenseQueryHookResult = ReturnType<typeof useGraCommercialResidentialTaxFilterSuspenseQuery>;
export type GraCommercialResidentialTaxFilterQueryResult = Apollo.QueryResult<GraCommercialResidentialTaxFilterQuery, GraCommercialResidentialTaxFilterQueryVariables>;
export const CorporateReturnExemptionDocument = gql`
    query CorporateReturnExemption($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnExemption(args: {params: $params}) {
    ...CorporateReturnExemption
  }
}
    ${CorporateReturnExemptionFragmentDoc}`;

/**
 * __useCorporateReturnExemptionQuery__
 *
 * To run a query within a React component, call `useCorporateReturnExemptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnExemptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnExemptionQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCorporateReturnExemptionQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables> & ({ variables: CorporateReturnExemptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables>(CorporateReturnExemptionDocument, options);
      }
export function useCorporateReturnExemptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables>(CorporateReturnExemptionDocument, options);
        }
export function useCorporateReturnExemptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables>(CorporateReturnExemptionDocument, options);
        }
export type CorporateReturnExemptionQueryHookResult = ReturnType<typeof useCorporateReturnExemptionQuery>;
export type CorporateReturnExemptionLazyQueryHookResult = ReturnType<typeof useCorporateReturnExemptionLazyQuery>;
export type CorporateReturnExemptionSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnExemptionSuspenseQuery>;
export type CorporateReturnExemptionQueryResult = Apollo.QueryResult<CorporateReturnExemptionQuery, CorporateReturnExemptionQueryVariables>;
export const CorporateReturnExemptionPaginatedDocument = gql`
    query CorporateReturnExemptionPaginated($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnExemption(args: {params: $params}) {
    ...CorporateReturnExemption
  }
  rtcs_gm_dev_Fngra_CorporateReturnExemption_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${CorporateReturnExemptionFragmentDoc}`;

/**
 * __useCorporateReturnExemptionPaginatedQuery__
 *
 * To run a query within a React component, call `useCorporateReturnExemptionPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnExemptionPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnExemptionPaginatedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useCorporateReturnExemptionPaginatedQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables> & ({ variables: CorporateReturnExemptionPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables>(CorporateReturnExemptionPaginatedDocument, options);
      }
export function useCorporateReturnExemptionPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables>(CorporateReturnExemptionPaginatedDocument, options);
        }
export function useCorporateReturnExemptionPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables>(CorporateReturnExemptionPaginatedDocument, options);
        }
export type CorporateReturnExemptionPaginatedQueryHookResult = ReturnType<typeof useCorporateReturnExemptionPaginatedQuery>;
export type CorporateReturnExemptionPaginatedLazyQueryHookResult = ReturnType<typeof useCorporateReturnExemptionPaginatedLazyQuery>;
export type CorporateReturnExemptionPaginatedSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnExemptionPaginatedSuspenseQuery>;
export type CorporateReturnExemptionPaginatedQueryResult = Apollo.QueryResult<CorporateReturnExemptionPaginatedQuery, CorporateReturnExemptionPaginatedQueryVariables>;
export const CorporateReturnExemptionFilterDocument = gql`
    query CorporateReturnExemptionFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnExemption(args: {params: $params}) {
    ...CorporateReturnExemption
  }
}
    ${CorporateReturnExemptionFragmentDoc}`;

/**
 * __useCorporateReturnExemptionFilterQuery__
 *
 * To run a query within a React component, call `useCorporateReturnExemptionFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnExemptionFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnExemptionFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCorporateReturnExemptionFilterQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables> & ({ variables: CorporateReturnExemptionFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables>(CorporateReturnExemptionFilterDocument, options);
      }
export function useCorporateReturnExemptionFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables>(CorporateReturnExemptionFilterDocument, options);
        }
export function useCorporateReturnExemptionFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables>(CorporateReturnExemptionFilterDocument, options);
        }
export type CorporateReturnExemptionFilterQueryHookResult = ReturnType<typeof useCorporateReturnExemptionFilterQuery>;
export type CorporateReturnExemptionFilterLazyQueryHookResult = ReturnType<typeof useCorporateReturnExemptionFilterLazyQuery>;
export type CorporateReturnExemptionFilterSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnExemptionFilterSuspenseQuery>;
export type CorporateReturnExemptionFilterQueryResult = Apollo.QueryResult<CorporateReturnExemptionFilterQuery, CorporateReturnExemptionFilterQueryVariables>;
export const CorporateReturnDocument = gql`
    query CorporateReturn($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturn(args: {params: $params}) {
    ...CorporateReturn
  }
}
    ${CorporateReturnFragmentDoc}`;

/**
 * __useCorporateReturnQuery__
 *
 * To run a query within a React component, call `useCorporateReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCorporateReturnQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnQuery, CorporateReturnQueryVariables> & ({ variables: CorporateReturnQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnQuery, CorporateReturnQueryVariables>(CorporateReturnDocument, options);
      }
export function useCorporateReturnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnQuery, CorporateReturnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnQuery, CorporateReturnQueryVariables>(CorporateReturnDocument, options);
        }
export function useCorporateReturnSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnQuery, CorporateReturnQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnQuery, CorporateReturnQueryVariables>(CorporateReturnDocument, options);
        }
export type CorporateReturnQueryHookResult = ReturnType<typeof useCorporateReturnQuery>;
export type CorporateReturnLazyQueryHookResult = ReturnType<typeof useCorporateReturnLazyQuery>;
export type CorporateReturnSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnSuspenseQuery>;
export type CorporateReturnQueryResult = Apollo.QueryResult<CorporateReturnQuery, CorporateReturnQueryVariables>;
export const CorporateReturnPaginatedDocument = gql`
    query CorporateReturnPaginated($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturn(args: {params: $params}) {
    ...CorporateReturn
  }
  rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${CorporateReturnFragmentDoc}`;

/**
 * __useCorporateReturnPaginatedQuery__
 *
 * To run a query within a React component, call `useCorporateReturnPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnPaginatedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useCorporateReturnPaginatedQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables> & ({ variables: CorporateReturnPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables>(CorporateReturnPaginatedDocument, options);
      }
export function useCorporateReturnPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables>(CorporateReturnPaginatedDocument, options);
        }
export function useCorporateReturnPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables>(CorporateReturnPaginatedDocument, options);
        }
export type CorporateReturnPaginatedQueryHookResult = ReturnType<typeof useCorporateReturnPaginatedQuery>;
export type CorporateReturnPaginatedLazyQueryHookResult = ReturnType<typeof useCorporateReturnPaginatedLazyQuery>;
export type CorporateReturnPaginatedSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnPaginatedSuspenseQuery>;
export type CorporateReturnPaginatedQueryResult = Apollo.QueryResult<CorporateReturnPaginatedQuery, CorporateReturnPaginatedQueryVariables>;
export const CorporateReturnFilterDocument = gql`
    query CorporateReturnFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturn(args: {params: $params}) {
    ...CorporateReturn
  }
}
    ${CorporateReturnFragmentDoc}`;

/**
 * __useCorporateReturnFilterQuery__
 *
 * To run a query within a React component, call `useCorporateReturnFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCorporateReturnFilterQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables> & ({ variables: CorporateReturnFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables>(CorporateReturnFilterDocument, options);
      }
export function useCorporateReturnFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables>(CorporateReturnFilterDocument, options);
        }
export function useCorporateReturnFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables>(CorporateReturnFilterDocument, options);
        }
export type CorporateReturnFilterQueryHookResult = ReturnType<typeof useCorporateReturnFilterQuery>;
export type CorporateReturnFilterLazyQueryHookResult = ReturnType<typeof useCorporateReturnFilterLazyQuery>;
export type CorporateReturnFilterSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnFilterSuspenseQuery>;
export type CorporateReturnFilterQueryResult = Apollo.QueryResult<CorporateReturnFilterQuery, CorporateReturnFilterQueryVariables>;
export const CorporateReturnDirectorDocument = gql`
    query CorporateReturnDirector($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnDirector(args: {params: $params}) {
    ...CorporateReturnDirector
  }
}
    ${CorporateReturnDirectorFragmentDoc}`;

/**
 * __useCorporateReturnDirectorQuery__
 *
 * To run a query within a React component, call `useCorporateReturnDirectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnDirectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnDirectorQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCorporateReturnDirectorQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables> & ({ variables: CorporateReturnDirectorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables>(CorporateReturnDirectorDocument, options);
      }
export function useCorporateReturnDirectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables>(CorporateReturnDirectorDocument, options);
        }
export function useCorporateReturnDirectorSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables>(CorporateReturnDirectorDocument, options);
        }
export type CorporateReturnDirectorQueryHookResult = ReturnType<typeof useCorporateReturnDirectorQuery>;
export type CorporateReturnDirectorLazyQueryHookResult = ReturnType<typeof useCorporateReturnDirectorLazyQuery>;
export type CorporateReturnDirectorSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnDirectorSuspenseQuery>;
export type CorporateReturnDirectorQueryResult = Apollo.QueryResult<CorporateReturnDirectorQuery, CorporateReturnDirectorQueryVariables>;
export const CorporateReturnDirectorPaginatedDocument = gql`
    query CorporateReturnDirectorPaginated($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnDirector(args: {params: $params}) {
    ...CorporateReturnDirector
  }
  rtcs_gm_dev_Fngra_CorporateReturnDirector_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${CorporateReturnDirectorFragmentDoc}`;

/**
 * __useCorporateReturnDirectorPaginatedQuery__
 *
 * To run a query within a React component, call `useCorporateReturnDirectorPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnDirectorPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnDirectorPaginatedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useCorporateReturnDirectorPaginatedQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables> & ({ variables: CorporateReturnDirectorPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables>(CorporateReturnDirectorPaginatedDocument, options);
      }
export function useCorporateReturnDirectorPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables>(CorporateReturnDirectorPaginatedDocument, options);
        }
export function useCorporateReturnDirectorPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables>(CorporateReturnDirectorPaginatedDocument, options);
        }
export type CorporateReturnDirectorPaginatedQueryHookResult = ReturnType<typeof useCorporateReturnDirectorPaginatedQuery>;
export type CorporateReturnDirectorPaginatedLazyQueryHookResult = ReturnType<typeof useCorporateReturnDirectorPaginatedLazyQuery>;
export type CorporateReturnDirectorPaginatedSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnDirectorPaginatedSuspenseQuery>;
export type CorporateReturnDirectorPaginatedQueryResult = Apollo.QueryResult<CorporateReturnDirectorPaginatedQuery, CorporateReturnDirectorPaginatedQueryVariables>;
export const CorporateReturnDirectorFilterDocument = gql`
    query CorporateReturnDirectorFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnDirector(args: {params: $params}) {
    ...CorporateReturnDirector
  }
}
    ${CorporateReturnDirectorFragmentDoc}`;

/**
 * __useCorporateReturnDirectorFilterQuery__
 *
 * To run a query within a React component, call `useCorporateReturnDirectorFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateReturnDirectorFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateReturnDirectorFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCorporateReturnDirectorFilterQuery(baseOptions: Apollo.QueryHookOptions<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables> & ({ variables: CorporateReturnDirectorFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables>(CorporateReturnDirectorFilterDocument, options);
      }
export function useCorporateReturnDirectorFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables>(CorporateReturnDirectorFilterDocument, options);
        }
export function useCorporateReturnDirectorFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables>(CorporateReturnDirectorFilterDocument, options);
        }
export type CorporateReturnDirectorFilterQueryHookResult = ReturnType<typeof useCorporateReturnDirectorFilterQuery>;
export type CorporateReturnDirectorFilterLazyQueryHookResult = ReturnType<typeof useCorporateReturnDirectorFilterLazyQuery>;
export type CorporateReturnDirectorFilterSuspenseQueryHookResult = ReturnType<typeof useCorporateReturnDirectorFilterSuspenseQuery>;
export type CorporateReturnDirectorFilterQueryResult = Apollo.QueryResult<CorporateReturnDirectorFilterQuery, CorporateReturnDirectorFilterQueryVariables>;
export const GetGraCorporateReturnLossDocument = gql`
    query GetGraCorporateReturnLoss($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnLoss(args: {params: $params}) {
    ...GraCorporateReturnLoss
  }
}
    ${GraCorporateReturnLossFragmentDoc}`;

/**
 * __useGetGraCorporateReturnLossQuery__
 *
 * To run a query within a React component, call `useGetGraCorporateReturnLossQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraCorporateReturnLossQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraCorporateReturnLossQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraCorporateReturnLossQuery(baseOptions: Apollo.QueryHookOptions<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables> & ({ variables: GetGraCorporateReturnLossQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables>(GetGraCorporateReturnLossDocument, options);
      }
export function useGetGraCorporateReturnLossLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables>(GetGraCorporateReturnLossDocument, options);
        }
export function useGetGraCorporateReturnLossSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables>(GetGraCorporateReturnLossDocument, options);
        }
export type GetGraCorporateReturnLossQueryHookResult = ReturnType<typeof useGetGraCorporateReturnLossQuery>;
export type GetGraCorporateReturnLossLazyQueryHookResult = ReturnType<typeof useGetGraCorporateReturnLossLazyQuery>;
export type GetGraCorporateReturnLossSuspenseQueryHookResult = ReturnType<typeof useGetGraCorporateReturnLossSuspenseQuery>;
export type GetGraCorporateReturnLossQueryResult = Apollo.QueryResult<GetGraCorporateReturnLossQuery, GetGraCorporateReturnLossQueryVariables>;
export const GetPaginatedGraCorporateReturnLossDocument = gql`
    query GetPaginatedGraCorporateReturnLoss($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnLoss(args: {params: $params}) {
    ...GraCorporateReturnLoss
  }
  rtcs_gm_dev_Fngra_CorporateReturnLoss_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraCorporateReturnLossFragmentDoc}`;

/**
 * __useGetPaginatedGraCorporateReturnLossQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraCorporateReturnLossQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraCorporateReturnLossQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraCorporateReturnLossQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraCorporateReturnLossQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables> & ({ variables: GetPaginatedGraCorporateReturnLossQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables>(GetPaginatedGraCorporateReturnLossDocument, options);
      }
export function useGetPaginatedGraCorporateReturnLossLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables>(GetPaginatedGraCorporateReturnLossDocument, options);
        }
export function useGetPaginatedGraCorporateReturnLossSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables>(GetPaginatedGraCorporateReturnLossDocument, options);
        }
export type GetPaginatedGraCorporateReturnLossQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnLossQuery>;
export type GetPaginatedGraCorporateReturnLossLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnLossLazyQuery>;
export type GetPaginatedGraCorporateReturnLossSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnLossSuspenseQuery>;
export type GetPaginatedGraCorporateReturnLossQueryResult = Apollo.QueryResult<GetPaginatedGraCorporateReturnLossQuery, GetPaginatedGraCorporateReturnLossQueryVariables>;
export const GraCorporateReturnLossFilterDocument = gql`
    query GraCorporateReturnLossFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnLoss(args: {params: $params}) {
    ...GraCorporateReturnLoss
  }
}
    ${GraCorporateReturnLossFragmentDoc}`;

/**
 * __useGraCorporateReturnLossFilterQuery__
 *
 * To run a query within a React component, call `useGraCorporateReturnLossFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraCorporateReturnLossFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraCorporateReturnLossFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraCorporateReturnLossFilterQuery(baseOptions: Apollo.QueryHookOptions<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables> & ({ variables: GraCorporateReturnLossFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables>(GraCorporateReturnLossFilterDocument, options);
      }
export function useGraCorporateReturnLossFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables>(GraCorporateReturnLossFilterDocument, options);
        }
export function useGraCorporateReturnLossFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables>(GraCorporateReturnLossFilterDocument, options);
        }
export type GraCorporateReturnLossFilterQueryHookResult = ReturnType<typeof useGraCorporateReturnLossFilterQuery>;
export type GraCorporateReturnLossFilterLazyQueryHookResult = ReturnType<typeof useGraCorporateReturnLossFilterLazyQuery>;
export type GraCorporateReturnLossFilterSuspenseQueryHookResult = ReturnType<typeof useGraCorporateReturnLossFilterSuspenseQuery>;
export type GraCorporateReturnLossFilterQueryResult = Apollo.QueryResult<GraCorporateReturnLossFilterQuery, GraCorporateReturnLossFilterQueryVariables>;
export const GetGraCorporateReturnPartnershipDocument = gql`
    query GetGraCorporateReturnPartnership($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnPartnership(args: {params: $params}) {
    ...GraCorporateReturnPartnership
  }
}
    ${GraCorporateReturnPartnershipFragmentDoc}`;

/**
 * __useGetGraCorporateReturnPartnershipQuery__
 *
 * To run a query within a React component, call `useGetGraCorporateReturnPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraCorporateReturnPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraCorporateReturnPartnershipQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraCorporateReturnPartnershipQuery(baseOptions: Apollo.QueryHookOptions<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables> & ({ variables: GetGraCorporateReturnPartnershipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables>(GetGraCorporateReturnPartnershipDocument, options);
      }
export function useGetGraCorporateReturnPartnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables>(GetGraCorporateReturnPartnershipDocument, options);
        }
export function useGetGraCorporateReturnPartnershipSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables>(GetGraCorporateReturnPartnershipDocument, options);
        }
export type GetGraCorporateReturnPartnershipQueryHookResult = ReturnType<typeof useGetGraCorporateReturnPartnershipQuery>;
export type GetGraCorporateReturnPartnershipLazyQueryHookResult = ReturnType<typeof useGetGraCorporateReturnPartnershipLazyQuery>;
export type GetGraCorporateReturnPartnershipSuspenseQueryHookResult = ReturnType<typeof useGetGraCorporateReturnPartnershipSuspenseQuery>;
export type GetGraCorporateReturnPartnershipQueryResult = Apollo.QueryResult<GetGraCorporateReturnPartnershipQuery, GetGraCorporateReturnPartnershipQueryVariables>;
export const GetPaginatedGraCorporateReturnPartnershipDocument = gql`
    query GetPaginatedGraCorporateReturnPartnership($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnPartnership(args: {params: $params}) {
    ...GraCorporateReturnPartnership
  }
  rtcs_gm_dev_Fngra_CorporateReturnPartnership_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraCorporateReturnPartnershipFragmentDoc}`;

/**
 * __useGetPaginatedGraCorporateReturnPartnershipQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraCorporateReturnPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraCorporateReturnPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraCorporateReturnPartnershipQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraCorporateReturnPartnershipQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables> & ({ variables: GetPaginatedGraCorporateReturnPartnershipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables>(GetPaginatedGraCorporateReturnPartnershipDocument, options);
      }
export function useGetPaginatedGraCorporateReturnPartnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables>(GetPaginatedGraCorporateReturnPartnershipDocument, options);
        }
export function useGetPaginatedGraCorporateReturnPartnershipSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables>(GetPaginatedGraCorporateReturnPartnershipDocument, options);
        }
export type GetPaginatedGraCorporateReturnPartnershipQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnPartnershipQuery>;
export type GetPaginatedGraCorporateReturnPartnershipLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnPartnershipLazyQuery>;
export type GetPaginatedGraCorporateReturnPartnershipSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnPartnershipSuspenseQuery>;
export type GetPaginatedGraCorporateReturnPartnershipQueryResult = Apollo.QueryResult<GetPaginatedGraCorporateReturnPartnershipQuery, GetPaginatedGraCorporateReturnPartnershipQueryVariables>;
export const GraCorporateReturnPartnershipFilterDocument = gql`
    query GraCorporateReturnPartnershipFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnPartnership(args: {params: $params}) {
    ...GraCorporateReturnPartnership
  }
}
    ${GraCorporateReturnPartnershipFragmentDoc}`;

/**
 * __useGraCorporateReturnPartnershipFilterQuery__
 *
 * To run a query within a React component, call `useGraCorporateReturnPartnershipFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraCorporateReturnPartnershipFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraCorporateReturnPartnershipFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraCorporateReturnPartnershipFilterQuery(baseOptions: Apollo.QueryHookOptions<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables> & ({ variables: GraCorporateReturnPartnershipFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables>(GraCorporateReturnPartnershipFilterDocument, options);
      }
export function useGraCorporateReturnPartnershipFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables>(GraCorporateReturnPartnershipFilterDocument, options);
        }
export function useGraCorporateReturnPartnershipFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables>(GraCorporateReturnPartnershipFilterDocument, options);
        }
export type GraCorporateReturnPartnershipFilterQueryHookResult = ReturnType<typeof useGraCorporateReturnPartnershipFilterQuery>;
export type GraCorporateReturnPartnershipFilterLazyQueryHookResult = ReturnType<typeof useGraCorporateReturnPartnershipFilterLazyQuery>;
export type GraCorporateReturnPartnershipFilterSuspenseQueryHookResult = ReturnType<typeof useGraCorporateReturnPartnershipFilterSuspenseQuery>;
export type GraCorporateReturnPartnershipFilterQueryResult = Apollo.QueryResult<GraCorporateReturnPartnershipFilterQuery, GraCorporateReturnPartnershipFilterQueryVariables>;
export const GetGraCorporateReturnPropertyRentDocument = gql`
    query GetGraCorporateReturnPropertyRent($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent(args: {params: $params}) {
    ...GraCorporateReturnPropertyRent
  }
}
    ${GraCorporateReturnPropertyRentFragmentDoc}`;

/**
 * __useGetGraCorporateReturnPropertyRentQuery__
 *
 * To run a query within a React component, call `useGetGraCorporateReturnPropertyRentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraCorporateReturnPropertyRentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraCorporateReturnPropertyRentQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraCorporateReturnPropertyRentQuery(baseOptions: Apollo.QueryHookOptions<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables> & ({ variables: GetGraCorporateReturnPropertyRentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables>(GetGraCorporateReturnPropertyRentDocument, options);
      }
export function useGetGraCorporateReturnPropertyRentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables>(GetGraCorporateReturnPropertyRentDocument, options);
        }
export function useGetGraCorporateReturnPropertyRentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables>(GetGraCorporateReturnPropertyRentDocument, options);
        }
export type GetGraCorporateReturnPropertyRentQueryHookResult = ReturnType<typeof useGetGraCorporateReturnPropertyRentQuery>;
export type GetGraCorporateReturnPropertyRentLazyQueryHookResult = ReturnType<typeof useGetGraCorporateReturnPropertyRentLazyQuery>;
export type GetGraCorporateReturnPropertyRentSuspenseQueryHookResult = ReturnType<typeof useGetGraCorporateReturnPropertyRentSuspenseQuery>;
export type GetGraCorporateReturnPropertyRentQueryResult = Apollo.QueryResult<GetGraCorporateReturnPropertyRentQuery, GetGraCorporateReturnPropertyRentQueryVariables>;
export const GetPaginatedGraCorporateReturnPropertyRentDocument = gql`
    query GetPaginatedGraCorporateReturnPropertyRent($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent(args: {params: $params}) {
    ...GraCorporateReturnPropertyRent
  }
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraCorporateReturnPropertyRentFragmentDoc}`;

/**
 * __useGetPaginatedGraCorporateReturnPropertyRentQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraCorporateReturnPropertyRentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraCorporateReturnPropertyRentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraCorporateReturnPropertyRentQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraCorporateReturnPropertyRentQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables> & ({ variables: GetPaginatedGraCorporateReturnPropertyRentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables>(GetPaginatedGraCorporateReturnPropertyRentDocument, options);
      }
export function useGetPaginatedGraCorporateReturnPropertyRentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables>(GetPaginatedGraCorporateReturnPropertyRentDocument, options);
        }
export function useGetPaginatedGraCorporateReturnPropertyRentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables>(GetPaginatedGraCorporateReturnPropertyRentDocument, options);
        }
export type GetPaginatedGraCorporateReturnPropertyRentQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnPropertyRentQuery>;
export type GetPaginatedGraCorporateReturnPropertyRentLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnPropertyRentLazyQuery>;
export type GetPaginatedGraCorporateReturnPropertyRentSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnPropertyRentSuspenseQuery>;
export type GetPaginatedGraCorporateReturnPropertyRentQueryResult = Apollo.QueryResult<GetPaginatedGraCorporateReturnPropertyRentQuery, GetPaginatedGraCorporateReturnPropertyRentQueryVariables>;
export const GraCorporateReturnPropertyRentFilterDocument = gql`
    query GraCorporateReturnPropertyRentFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnPropertyRent(args: {params: $params}) {
    ...GraCorporateReturnPropertyRent
  }
}
    ${GraCorporateReturnPropertyRentFragmentDoc}`;

/**
 * __useGraCorporateReturnPropertyRentFilterQuery__
 *
 * To run a query within a React component, call `useGraCorporateReturnPropertyRentFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraCorporateReturnPropertyRentFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraCorporateReturnPropertyRentFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraCorporateReturnPropertyRentFilterQuery(baseOptions: Apollo.QueryHookOptions<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables> & ({ variables: GraCorporateReturnPropertyRentFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables>(GraCorporateReturnPropertyRentFilterDocument, options);
      }
export function useGraCorporateReturnPropertyRentFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables>(GraCorporateReturnPropertyRentFilterDocument, options);
        }
export function useGraCorporateReturnPropertyRentFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables>(GraCorporateReturnPropertyRentFilterDocument, options);
        }
export type GraCorporateReturnPropertyRentFilterQueryHookResult = ReturnType<typeof useGraCorporateReturnPropertyRentFilterQuery>;
export type GraCorporateReturnPropertyRentFilterLazyQueryHookResult = ReturnType<typeof useGraCorporateReturnPropertyRentFilterLazyQuery>;
export type GraCorporateReturnPropertyRentFilterSuspenseQueryHookResult = ReturnType<typeof useGraCorporateReturnPropertyRentFilterSuspenseQuery>;
export type GraCorporateReturnPropertyRentFilterQueryResult = Apollo.QueryResult<GraCorporateReturnPropertyRentFilterQuery, GraCorporateReturnPropertyRentFilterQueryVariables>;
export const GetGraCorporateReturnRentReceivedDocument = gql`
    query GetGraCorporateReturnRentReceived($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived(args: {params: $params}) {
    ...GraCorporateReturnRentReceived
  }
}
    ${GraCorporateReturnRentReceivedFragmentDoc}`;

/**
 * __useGetGraCorporateReturnRentReceivedQuery__
 *
 * To run a query within a React component, call `useGetGraCorporateReturnRentReceivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraCorporateReturnRentReceivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraCorporateReturnRentReceivedQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraCorporateReturnRentReceivedQuery(baseOptions: Apollo.QueryHookOptions<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables> & ({ variables: GetGraCorporateReturnRentReceivedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables>(GetGraCorporateReturnRentReceivedDocument, options);
      }
export function useGetGraCorporateReturnRentReceivedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables>(GetGraCorporateReturnRentReceivedDocument, options);
        }
export function useGetGraCorporateReturnRentReceivedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables>(GetGraCorporateReturnRentReceivedDocument, options);
        }
export type GetGraCorporateReturnRentReceivedQueryHookResult = ReturnType<typeof useGetGraCorporateReturnRentReceivedQuery>;
export type GetGraCorporateReturnRentReceivedLazyQueryHookResult = ReturnType<typeof useGetGraCorporateReturnRentReceivedLazyQuery>;
export type GetGraCorporateReturnRentReceivedSuspenseQueryHookResult = ReturnType<typeof useGetGraCorporateReturnRentReceivedSuspenseQuery>;
export type GetGraCorporateReturnRentReceivedQueryResult = Apollo.QueryResult<GetGraCorporateReturnRentReceivedQuery, GetGraCorporateReturnRentReceivedQueryVariables>;
export const GetPaginatedGraCorporateReturnRentReceivedDocument = gql`
    query GetPaginatedGraCorporateReturnRentReceived($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived(args: {params: $params}) {
    ...GraCorporateReturnRentReceived
  }
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraCorporateReturnRentReceivedFragmentDoc}`;

/**
 * __useGetPaginatedGraCorporateReturnRentReceivedQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraCorporateReturnRentReceivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraCorporateReturnRentReceivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraCorporateReturnRentReceivedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraCorporateReturnRentReceivedQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables> & ({ variables: GetPaginatedGraCorporateReturnRentReceivedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables>(GetPaginatedGraCorporateReturnRentReceivedDocument, options);
      }
export function useGetPaginatedGraCorporateReturnRentReceivedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables>(GetPaginatedGraCorporateReturnRentReceivedDocument, options);
        }
export function useGetPaginatedGraCorporateReturnRentReceivedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables>(GetPaginatedGraCorporateReturnRentReceivedDocument, options);
        }
export type GetPaginatedGraCorporateReturnRentReceivedQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnRentReceivedQuery>;
export type GetPaginatedGraCorporateReturnRentReceivedLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnRentReceivedLazyQuery>;
export type GetPaginatedGraCorporateReturnRentReceivedSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraCorporateReturnRentReceivedSuspenseQuery>;
export type GetPaginatedGraCorporateReturnRentReceivedQueryResult = Apollo.QueryResult<GetPaginatedGraCorporateReturnRentReceivedQuery, GetPaginatedGraCorporateReturnRentReceivedQueryVariables>;
export const GraCorporateReturnRentReceivedFilterDocument = gql`
    query GraCorporateReturnRentReceivedFilter($params: String!) {
  rtcs_gm_dev_Fngra_CorporateReturnRentReceived(args: {params: $params}) {
    ...GraCorporateReturnRentReceived
  }
}
    ${GraCorporateReturnRentReceivedFragmentDoc}`;

/**
 * __useGraCorporateReturnRentReceivedFilterQuery__
 *
 * To run a query within a React component, call `useGraCorporateReturnRentReceivedFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraCorporateReturnRentReceivedFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraCorporateReturnRentReceivedFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraCorporateReturnRentReceivedFilterQuery(baseOptions: Apollo.QueryHookOptions<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables> & ({ variables: GraCorporateReturnRentReceivedFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables>(GraCorporateReturnRentReceivedFilterDocument, options);
      }
export function useGraCorporateReturnRentReceivedFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables>(GraCorporateReturnRentReceivedFilterDocument, options);
        }
export function useGraCorporateReturnRentReceivedFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables>(GraCorporateReturnRentReceivedFilterDocument, options);
        }
export type GraCorporateReturnRentReceivedFilterQueryHookResult = ReturnType<typeof useGraCorporateReturnRentReceivedFilterQuery>;
export type GraCorporateReturnRentReceivedFilterLazyQueryHookResult = ReturnType<typeof useGraCorporateReturnRentReceivedFilterLazyQuery>;
export type GraCorporateReturnRentReceivedFilterSuspenseQueryHookResult = ReturnType<typeof useGraCorporateReturnRentReceivedFilterSuspenseQuery>;
export type GraCorporateReturnRentReceivedFilterQueryResult = Apollo.QueryResult<GraCorporateReturnRentReceivedFilterQuery, GraCorporateReturnRentReceivedFilterQueryVariables>;
export const GetGraIndividualReturnExemptionDocument = gql`
    query GetGraIndividualReturnExemption($params: String!) {
  rtcs_gm_dev_Fngra_IndividualReturnExemption(args: {params: $params}) {
    ...GraIndividualReturnExemption
  }
}
    ${GraIndividualReturnExemptionFragmentDoc}`;

/**
 * __useGetGraIndividualReturnExemptionQuery__
 *
 * To run a query within a React component, call `useGetGraIndividualReturnExemptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraIndividualReturnExemptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraIndividualReturnExemptionQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraIndividualReturnExemptionQuery(baseOptions: Apollo.QueryHookOptions<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables> & ({ variables: GetGraIndividualReturnExemptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables>(GetGraIndividualReturnExemptionDocument, options);
      }
export function useGetGraIndividualReturnExemptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables>(GetGraIndividualReturnExemptionDocument, options);
        }
export function useGetGraIndividualReturnExemptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables>(GetGraIndividualReturnExemptionDocument, options);
        }
export type GetGraIndividualReturnExemptionQueryHookResult = ReturnType<typeof useGetGraIndividualReturnExemptionQuery>;
export type GetGraIndividualReturnExemptionLazyQueryHookResult = ReturnType<typeof useGetGraIndividualReturnExemptionLazyQuery>;
export type GetGraIndividualReturnExemptionSuspenseQueryHookResult = ReturnType<typeof useGetGraIndividualReturnExemptionSuspenseQuery>;
export type GetGraIndividualReturnExemptionQueryResult = Apollo.QueryResult<GetGraIndividualReturnExemptionQuery, GetGraIndividualReturnExemptionQueryVariables>;
export const GetPaginatedGraIndividualReturnExemptionDocument = gql`
    query GetPaginatedGraIndividualReturnExemption($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_IndividualReturnExemption(args: {params: $params}) {
    ...GraIndividualReturnExemption
  }
  rtcs_gm_dev_Fngra_IndividualReturnExemption_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraIndividualReturnExemptionFragmentDoc}`;

/**
 * __useGetPaginatedGraIndividualReturnExemptionQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraIndividualReturnExemptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraIndividualReturnExemptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraIndividualReturnExemptionQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraIndividualReturnExemptionQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables> & ({ variables: GetPaginatedGraIndividualReturnExemptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables>(GetPaginatedGraIndividualReturnExemptionDocument, options);
      }
export function useGetPaginatedGraIndividualReturnExemptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables>(GetPaginatedGraIndividualReturnExemptionDocument, options);
        }
export function useGetPaginatedGraIndividualReturnExemptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables>(GetPaginatedGraIndividualReturnExemptionDocument, options);
        }
export type GetPaginatedGraIndividualReturnExemptionQueryHookResult = ReturnType<typeof useGetPaginatedGraIndividualReturnExemptionQuery>;
export type GetPaginatedGraIndividualReturnExemptionLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraIndividualReturnExemptionLazyQuery>;
export type GetPaginatedGraIndividualReturnExemptionSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraIndividualReturnExemptionSuspenseQuery>;
export type GetPaginatedGraIndividualReturnExemptionQueryResult = Apollo.QueryResult<GetPaginatedGraIndividualReturnExemptionQuery, GetPaginatedGraIndividualReturnExemptionQueryVariables>;
export const GraIndividualReturnExemptionFilterDocument = gql`
    query GraIndividualReturnExemptionFilter($params: String!) {
  rtcs_gm_dev_Fngra_IndividualReturnExemption(args: {params: $params}) {
    ...GraIndividualReturnExemption
  }
}
    ${GraIndividualReturnExemptionFragmentDoc}`;

/**
 * __useGraIndividualReturnExemptionFilterQuery__
 *
 * To run a query within a React component, call `useGraIndividualReturnExemptionFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraIndividualReturnExemptionFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraIndividualReturnExemptionFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraIndividualReturnExemptionFilterQuery(baseOptions: Apollo.QueryHookOptions<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables> & ({ variables: GraIndividualReturnExemptionFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables>(GraIndividualReturnExemptionFilterDocument, options);
      }
export function useGraIndividualReturnExemptionFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables>(GraIndividualReturnExemptionFilterDocument, options);
        }
export function useGraIndividualReturnExemptionFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables>(GraIndividualReturnExemptionFilterDocument, options);
        }
export type GraIndividualReturnExemptionFilterQueryHookResult = ReturnType<typeof useGraIndividualReturnExemptionFilterQuery>;
export type GraIndividualReturnExemptionFilterLazyQueryHookResult = ReturnType<typeof useGraIndividualReturnExemptionFilterLazyQuery>;
export type GraIndividualReturnExemptionFilterSuspenseQueryHookResult = ReturnType<typeof useGraIndividualReturnExemptionFilterSuspenseQuery>;
export type GraIndividualReturnExemptionFilterQueryResult = Apollo.QueryResult<GraIndividualReturnExemptionFilterQuery, GraIndividualReturnExemptionFilterQueryVariables>;
export const GetGraIndividualReturnDocument = gql`
    query GetGraIndividualReturn($params: String!) {
  rtcs_gm_dev_Fngra_IndividualReturn(args: {params: $params}) {
    ...GraIndividualReturn
  }
}
    ${GraIndividualReturnFragmentDoc}`;

/**
 * __useGetGraIndividualReturnQuery__
 *
 * To run a query within a React component, call `useGetGraIndividualReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraIndividualReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraIndividualReturnQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGraIndividualReturnQuery(baseOptions: Apollo.QueryHookOptions<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables> & ({ variables: GetGraIndividualReturnQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables>(GetGraIndividualReturnDocument, options);
      }
export function useGetGraIndividualReturnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables>(GetGraIndividualReturnDocument, options);
        }
export function useGetGraIndividualReturnSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables>(GetGraIndividualReturnDocument, options);
        }
export type GetGraIndividualReturnQueryHookResult = ReturnType<typeof useGetGraIndividualReturnQuery>;
export type GetGraIndividualReturnLazyQueryHookResult = ReturnType<typeof useGetGraIndividualReturnLazyQuery>;
export type GetGraIndividualReturnSuspenseQueryHookResult = ReturnType<typeof useGetGraIndividualReturnSuspenseQuery>;
export type GetGraIndividualReturnQueryResult = Apollo.QueryResult<GetGraIndividualReturnQuery, GetGraIndividualReturnQueryVariables>;
export const GetPaginatedGraIndividualReturnDocument = gql`
    query GetPaginatedGraIndividualReturn($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_IndividualReturn(args: {params: $params}) {
    ...GraIndividualReturn
  }
  rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${GraIndividualReturnFragmentDoc}`;

/**
 * __useGetPaginatedGraIndividualReturnQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGraIndividualReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGraIndividualReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGraIndividualReturnQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedGraIndividualReturnQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables> & ({ variables: GetPaginatedGraIndividualReturnQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables>(GetPaginatedGraIndividualReturnDocument, options);
      }
export function useGetPaginatedGraIndividualReturnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables>(GetPaginatedGraIndividualReturnDocument, options);
        }
export function useGetPaginatedGraIndividualReturnSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables>(GetPaginatedGraIndividualReturnDocument, options);
        }
export type GetPaginatedGraIndividualReturnQueryHookResult = ReturnType<typeof useGetPaginatedGraIndividualReturnQuery>;
export type GetPaginatedGraIndividualReturnLazyQueryHookResult = ReturnType<typeof useGetPaginatedGraIndividualReturnLazyQuery>;
export type GetPaginatedGraIndividualReturnSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedGraIndividualReturnSuspenseQuery>;
export type GetPaginatedGraIndividualReturnQueryResult = Apollo.QueryResult<GetPaginatedGraIndividualReturnQuery, GetPaginatedGraIndividualReturnQueryVariables>;
export const GraIndividualReturnFilterDocument = gql`
    query GraIndividualReturnFilter($params: String!) {
  rtcs_gm_dev_Fngra_IndividualReturn(args: {params: $params}) {
    ...GraIndividualReturn
  }
}
    ${GraIndividualReturnFragmentDoc}`;

/**
 * __useGraIndividualReturnFilterQuery__
 *
 * To run a query within a React component, call `useGraIndividualReturnFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraIndividualReturnFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraIndividualReturnFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraIndividualReturnFilterQuery(baseOptions: Apollo.QueryHookOptions<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables> & ({ variables: GraIndividualReturnFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables>(GraIndividualReturnFilterDocument, options);
      }
export function useGraIndividualReturnFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables>(GraIndividualReturnFilterDocument, options);
        }
export function useGraIndividualReturnFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables>(GraIndividualReturnFilterDocument, options);
        }
export type GraIndividualReturnFilterQueryHookResult = ReturnType<typeof useGraIndividualReturnFilterQuery>;
export type GraIndividualReturnFilterLazyQueryHookResult = ReturnType<typeof useGraIndividualReturnFilterLazyQuery>;
export type GraIndividualReturnFilterSuspenseQueryHookResult = ReturnType<typeof useGraIndividualReturnFilterSuspenseQuery>;
export type GraIndividualReturnFilterQueryResult = Apollo.QueryResult<GraIndividualReturnFilterQuery, GraIndividualReturnFilterQueryVariables>;
export const PaymentRentalDocument = gql`
    query PaymentRental($params: String!) {
  rtcs_gm_dev_Fngra_PaymentRental(args: {params: $params}) {
    ...PaymentRental
  }
}
    ${PaymentRentalFragmentDoc}`;

/**
 * __usePaymentRentalQuery__
 *
 * To run a query within a React component, call `usePaymentRentalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRentalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRentalQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePaymentRentalQuery(baseOptions: Apollo.QueryHookOptions<PaymentRentalQuery, PaymentRentalQueryVariables> & ({ variables: PaymentRentalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentRentalQuery, PaymentRentalQueryVariables>(PaymentRentalDocument, options);
      }
export function usePaymentRentalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentRentalQuery, PaymentRentalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentRentalQuery, PaymentRentalQueryVariables>(PaymentRentalDocument, options);
        }
export function usePaymentRentalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentRentalQuery, PaymentRentalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentRentalQuery, PaymentRentalQueryVariables>(PaymentRentalDocument, options);
        }
export type PaymentRentalQueryHookResult = ReturnType<typeof usePaymentRentalQuery>;
export type PaymentRentalLazyQueryHookResult = ReturnType<typeof usePaymentRentalLazyQuery>;
export type PaymentRentalSuspenseQueryHookResult = ReturnType<typeof usePaymentRentalSuspenseQuery>;
export type PaymentRentalQueryResult = Apollo.QueryResult<PaymentRentalQuery, PaymentRentalQueryVariables>;
export const PaymentRentalPaginatedDocument = gql`
    query PaymentRentalPaginated($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngra_PaymentRental(args: {params: $params}) {
    ...PaymentRental
  }
  rtcs_gm_dev_Fngra_PaymentRental_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${PaymentRentalFragmentDoc}`;

/**
 * __usePaymentRentalPaginatedQuery__
 *
 * To run a query within a React component, call `usePaymentRentalPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRentalPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRentalPaginatedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function usePaymentRentalPaginatedQuery(baseOptions: Apollo.QueryHookOptions<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables> & ({ variables: PaymentRentalPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables>(PaymentRentalPaginatedDocument, options);
      }
export function usePaymentRentalPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables>(PaymentRentalPaginatedDocument, options);
        }
export function usePaymentRentalPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables>(PaymentRentalPaginatedDocument, options);
        }
export type PaymentRentalPaginatedQueryHookResult = ReturnType<typeof usePaymentRentalPaginatedQuery>;
export type PaymentRentalPaginatedLazyQueryHookResult = ReturnType<typeof usePaymentRentalPaginatedLazyQuery>;
export type PaymentRentalPaginatedSuspenseQueryHookResult = ReturnType<typeof usePaymentRentalPaginatedSuspenseQuery>;
export type PaymentRentalPaginatedQueryResult = Apollo.QueryResult<PaymentRentalPaginatedQuery, PaymentRentalPaginatedQueryVariables>;
export const PaymentRentalFilterDocument = gql`
    query PaymentRentalFilter($params: String!) {
  rtcs_gm_dev_Fngra_PaymentRental(args: {params: $params}) {
    ...PaymentRental
  }
}
    ${PaymentRentalFragmentDoc}`;

/**
 * __usePaymentRentalFilterQuery__
 *
 * To run a query within a React component, call `usePaymentRentalFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRentalFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRentalFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePaymentRentalFilterQuery(baseOptions: Apollo.QueryHookOptions<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables> & ({ variables: PaymentRentalFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables>(PaymentRentalFilterDocument, options);
      }
export function usePaymentRentalFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables>(PaymentRentalFilterDocument, options);
        }
export function usePaymentRentalFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables>(PaymentRentalFilterDocument, options);
        }
export type PaymentRentalFilterQueryHookResult = ReturnType<typeof usePaymentRentalFilterQuery>;
export type PaymentRentalFilterLazyQueryHookResult = ReturnType<typeof usePaymentRentalFilterLazyQuery>;
export type PaymentRentalFilterSuspenseQueryHookResult = ReturnType<typeof usePaymentRentalFilterSuspenseQuery>;
export type PaymentRentalFilterQueryResult = Apollo.QueryResult<PaymentRentalFilterQuery, PaymentRentalFilterQueryVariables>;
export const GetMojMonthlyRegistrationsDocument = gql`
    query GetMOJMonthlyRegistrations($params: String!) {
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations(args: {params: $params}) {
    ...MOJMonthlyRegistrations
  }
}
    ${MojMonthlyRegistrationsFragmentDoc}`;

/**
 * __useGetMojMonthlyRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetMojMonthlyRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMojMonthlyRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMojMonthlyRegistrationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetMojMonthlyRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables> & ({ variables: GetMojMonthlyRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables>(GetMojMonthlyRegistrationsDocument, options);
      }
export function useGetMojMonthlyRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables>(GetMojMonthlyRegistrationsDocument, options);
        }
export function useGetMojMonthlyRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables>(GetMojMonthlyRegistrationsDocument, options);
        }
export type GetMojMonthlyRegistrationsQueryHookResult = ReturnType<typeof useGetMojMonthlyRegistrationsQuery>;
export type GetMojMonthlyRegistrationsLazyQueryHookResult = ReturnType<typeof useGetMojMonthlyRegistrationsLazyQuery>;
export type GetMojMonthlyRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetMojMonthlyRegistrationsSuspenseQuery>;
export type GetMojMonthlyRegistrationsQueryResult = Apollo.QueryResult<GetMojMonthlyRegistrationsQuery, GetMojMonthlyRegistrationsQueryVariables>;
export const GetPaginatedMojMonthlyRegistrationsDocument = gql`
    query GetPaginatedMOJMonthlyRegistrations($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations(args: {params: $params}) {
    ...MOJMonthlyRegistrations
  }
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${MojMonthlyRegistrationsFragmentDoc}`;

/**
 * __useGetPaginatedMojMonthlyRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedMojMonthlyRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedMojMonthlyRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedMojMonthlyRegistrationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedMojMonthlyRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables> & ({ variables: GetPaginatedMojMonthlyRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables>(GetPaginatedMojMonthlyRegistrationsDocument, options);
      }
export function useGetPaginatedMojMonthlyRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables>(GetPaginatedMojMonthlyRegistrationsDocument, options);
        }
export function useGetPaginatedMojMonthlyRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables>(GetPaginatedMojMonthlyRegistrationsDocument, options);
        }
export type GetPaginatedMojMonthlyRegistrationsQueryHookResult = ReturnType<typeof useGetPaginatedMojMonthlyRegistrationsQuery>;
export type GetPaginatedMojMonthlyRegistrationsLazyQueryHookResult = ReturnType<typeof useGetPaginatedMojMonthlyRegistrationsLazyQuery>;
export type GetPaginatedMojMonthlyRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedMojMonthlyRegistrationsSuspenseQuery>;
export type GetPaginatedMojMonthlyRegistrationsQueryResult = Apollo.QueryResult<GetPaginatedMojMonthlyRegistrationsQuery, GetPaginatedMojMonthlyRegistrationsQueryVariables>;
export const MojMonthlyRegistrationsFilterDocument = gql`
    query MOJMonthlyRegistrationsFilter($params: String!) {
  rtcs_gm_dev_Fngroc_MOJMonthlyRegistrations(args: {params: $params}) {
    ...MOJMonthlyRegistrations
  }
}
    ${MojMonthlyRegistrationsFragmentDoc}`;

/**
 * __useMojMonthlyRegistrationsFilterQuery__
 *
 * To run a query within a React component, call `useMojMonthlyRegistrationsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMojMonthlyRegistrationsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMojMonthlyRegistrationsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMojMonthlyRegistrationsFilterQuery(baseOptions: Apollo.QueryHookOptions<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables> & ({ variables: MojMonthlyRegistrationsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables>(MojMonthlyRegistrationsFilterDocument, options);
      }
export function useMojMonthlyRegistrationsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables>(MojMonthlyRegistrationsFilterDocument, options);
        }
export function useMojMonthlyRegistrationsFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables>(MojMonthlyRegistrationsFilterDocument, options);
        }
export type MojMonthlyRegistrationsFilterQueryHookResult = ReturnType<typeof useMojMonthlyRegistrationsFilterQuery>;
export type MojMonthlyRegistrationsFilterLazyQueryHookResult = ReturnType<typeof useMojMonthlyRegistrationsFilterLazyQuery>;
export type MojMonthlyRegistrationsFilterSuspenseQueryHookResult = ReturnType<typeof useMojMonthlyRegistrationsFilterSuspenseQuery>;
export type MojMonthlyRegistrationsFilterQueryResult = Apollo.QueryResult<MojMonthlyRegistrationsFilterQuery, MojMonthlyRegistrationsFilterQueryVariables>;
export const MobileCarrierSubscriberRegistrationsDocument = gql`
    query MobileCarrierSubscriberRegistrations($params: String!) {
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations(args: {params: $params}) {
    ...MobileCarrierSubscriberRegistrations
  }
}
    ${MobileCarrierSubscriberRegistrationsFragmentDoc}`;

/**
 * __useMobileCarrierSubscriberRegistrationsQuery__
 *
 * To run a query within a React component, call `useMobileCarrierSubscriberRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileCarrierSubscriberRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileCarrierSubscriberRegistrationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMobileCarrierSubscriberRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables> & ({ variables: MobileCarrierSubscriberRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables>(MobileCarrierSubscriberRegistrationsDocument, options);
      }
export function useMobileCarrierSubscriberRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables>(MobileCarrierSubscriberRegistrationsDocument, options);
        }
export function useMobileCarrierSubscriberRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables>(MobileCarrierSubscriberRegistrationsDocument, options);
        }
export type MobileCarrierSubscriberRegistrationsQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsQuery>;
export type MobileCarrierSubscriberRegistrationsLazyQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsLazyQuery>;
export type MobileCarrierSubscriberRegistrationsSuspenseQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsSuspenseQuery>;
export type MobileCarrierSubscriberRegistrationsQueryResult = Apollo.QueryResult<MobileCarrierSubscriberRegistrationsQuery, MobileCarrierSubscriberRegistrationsQueryVariables>;
export const MobileCarrierSubscriberRegistrationsPaginatedDocument = gql`
    query MobileCarrierSubscriberRegistrationsPaginated($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations(args: {params: $params}) {
    ...MobileCarrierSubscriberRegistrations
  }
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${MobileCarrierSubscriberRegistrationsFragmentDoc}`;

/**
 * __useMobileCarrierSubscriberRegistrationsPaginatedQuery__
 *
 * To run a query within a React component, call `useMobileCarrierSubscriberRegistrationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileCarrierSubscriberRegistrationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileCarrierSubscriberRegistrationsPaginatedQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useMobileCarrierSubscriberRegistrationsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables> & ({ variables: MobileCarrierSubscriberRegistrationsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables>(MobileCarrierSubscriberRegistrationsPaginatedDocument, options);
      }
export function useMobileCarrierSubscriberRegistrationsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables>(MobileCarrierSubscriberRegistrationsPaginatedDocument, options);
        }
export function useMobileCarrierSubscriberRegistrationsPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables>(MobileCarrierSubscriberRegistrationsPaginatedDocument, options);
        }
export type MobileCarrierSubscriberRegistrationsPaginatedQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsPaginatedQuery>;
export type MobileCarrierSubscriberRegistrationsPaginatedLazyQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsPaginatedLazyQuery>;
export type MobileCarrierSubscriberRegistrationsPaginatedSuspenseQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsPaginatedSuspenseQuery>;
export type MobileCarrierSubscriberRegistrationsPaginatedQueryResult = Apollo.QueryResult<MobileCarrierSubscriberRegistrationsPaginatedQuery, MobileCarrierSubscriberRegistrationsPaginatedQueryVariables>;
export const MobileCarrierSubscriberRegistrationsFilterDocument = gql`
    query MobileCarrierSubscriberRegistrationsFilter($params: String!) {
  rtcs_gm_dev_Fnpura_MobileCarrierSubscriberRegistrations(args: {params: $params}) {
    ...MobileCarrierSubscriberRegistrations
  }
}
    ${MobileCarrierSubscriberRegistrationsFragmentDoc}`;

/**
 * __useMobileCarrierSubscriberRegistrationsFilterQuery__
 *
 * To run a query within a React component, call `useMobileCarrierSubscriberRegistrationsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileCarrierSubscriberRegistrationsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileCarrierSubscriberRegistrationsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMobileCarrierSubscriberRegistrationsFilterQuery(baseOptions: Apollo.QueryHookOptions<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables> & ({ variables: MobileCarrierSubscriberRegistrationsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables>(MobileCarrierSubscriberRegistrationsFilterDocument, options);
      }
export function useMobileCarrierSubscriberRegistrationsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables>(MobileCarrierSubscriberRegistrationsFilterDocument, options);
        }
export function useMobileCarrierSubscriberRegistrationsFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables>(MobileCarrierSubscriberRegistrationsFilterDocument, options);
        }
export type MobileCarrierSubscriberRegistrationsFilterQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsFilterQuery>;
export type MobileCarrierSubscriberRegistrationsFilterLazyQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsFilterLazyQuery>;
export type MobileCarrierSubscriberRegistrationsFilterSuspenseQueryHookResult = ReturnType<typeof useMobileCarrierSubscriberRegistrationsFilterSuspenseQuery>;
export type MobileCarrierSubscriberRegistrationsFilterQueryResult = Apollo.QueryResult<MobileCarrierSubscriberRegistrationsFilterQuery, MobileCarrierSubscriberRegistrationsFilterQueryVariables>;