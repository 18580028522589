import React from "react";

// Components
import { TradeLicenseRegisterTable } from "pages/reports/bcc/trade-license-register-table/trade-license-register-table.component";
import { PropertyRatesTable } from "pages/reports/bcc/property-rates-table/property-rates-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface BccTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

const BccTab: React.FC<BccTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      propertyRates: false,
    });
    const reportSections = [
      {
        title: "Banjul City Council",
        accordions: [
          {
            content: (
              <TradeLicenseRegisterTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                onLoaded={renderTable.propertyRates}
                persistenceId="77ed43f1-3c55-4e34-b8ea-905144e032c2"
              />
            ),
            subtitle: "Trade License Register",
          },
          {
            shouldRenderBody: tablesLoaded.propertyRates,
            content: (
              <PropertyRatesTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="7ae40f40-7bd2-40ab-aa1e-91a585da3a53"
              />
            ),
            subtitle: "Property Rates",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);

export default BccTab;
