// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { Gbos2022CommercialRentalRatesSurveyTable } from "../2022-commercial-rental-rates-survey-table/2022-commercial-rental-rates-survey-table.component";

// Assets
import SC from "./2022-commercial-rental-rates-survey.styles";

const Gbos2022CommercialRentalRatesSurvey: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <Gbos2022CommercialRentalRatesSurveyTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default Gbos2022CommercialRentalRatesSurvey;
